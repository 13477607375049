import { GET_CALENDAR } from 'shared/state/types';
import { request } from 'shared/auth';

function getCalendarRequested() {
  return {
    type: GET_CALENDAR.REQUEST,
  };
}

function getCalendarFailed(error) {
  return {
    type: GET_CALENDAR.FAILURE,
    error: error,
  };
}

function getCalendarSuccess(data) {
  return {
    type: GET_CALENDAR.SUCCESS,
    calendarData: data,
  };
}

export function getCalendar(spaceId, month, year) {    
  return dispatch => {
    dispatch(getCalendarRequested());
    return request().get(`/spaces/${spaceId}/eventTimeslots?month=${month}&year=${year}`)
      .then(response =>{
          if(response.status === 200){
            dispatch(getCalendarSuccess(response.data.data))
            return response.data.data
          }
          else if(response.status === 400){
            dispatch(getCalendarFailed("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(getCalendarFailed("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(getCalendarFailed("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(getCalendarFailed("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(getCalendarFailed("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(getCalendarFailed("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(getCalendarFailed("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(getCalendarFailed("The server was unavailable."));
          }
          else{
            dispatch(getCalendarFailed("The server was unavailable."));
          }
        }
      )
      .catch(errors => {
          if(errors.response && errors.response.data && errors.response.data.errors){
            dispatch(getCalendarFailed(errors.response.data.errors));
          }
          else{
            dispatch(getCalendarFailed('Unable to access api'));
          }
        throw errors;
      });
  };
}

import React from 'react';
import {Grid,Button} from '@material-ui/core';
import { Link } from "react-router-dom";
import '../assets/myVenue.scss';
function VenuePlanExpiredMessage(props) {

  const openVenuePlanOnClickOfLink = (e,venueId) => {
    props.openVenuePlanOnClickOfLink(e,venueId);
  }

    return (
        <Grid
            md={12}
            xs={12}
            sm={12}
            xl={12}
            lg={12}
            className="venue-plan"
           >
            <Grid className="expired-msg" >
              <p> { props.expiredVenues[0].name } is inactive : &nbsp;<Button variant="contained" onClick={(e)=>{openVenuePlanOnClickOfLink(e, props.expiredVenues[0].id)}} className="subscrib-btn" >RESUBSCRIBE</Button></p>
            </Grid> 
            { props.userData && 
            <Grid className="email-verification" >
              <p>Email Verification sent to <b>{ props.userData.email }</b>. Check status in <Link to="/editProfile">profile settings</Link></p>
            </Grid>
            }
        </Grid>
    );
}

export default VenuePlanExpiredMessage;

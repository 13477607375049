import { Record } from 'immutable';

import {
  GET_VENUE_SUGG_REQUEST,
  GET_VENUE_SUGG_FAILURE,
  GET_VENUE_SUGG_SUCCESS,
} from 'shared/state/types';

class State extends Record({
  suggestions:{},
  error:''
  
}) {}



export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_VENUE_SUGG_REQUEST:
      return state.merge({
        error:''
      });
    case GET_VENUE_SUGG_FAILURE:
      return state.merge({
        suggestions:{},
        error: action.error


      });
    case GET_VENUE_SUGG_SUCCESS:
      return state.merge({
        suggestions:action.venueSuggestions,
        error:'Success'
      });

    default:
      return state;
  }
}


import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators,compose} from 'redux';

import VenueFooter from '../../../shared/footer/venueFooter';
//import ContactUs from '../components/contactUs';
//import { contactUs } from 'data/cms/actions';

import { Grid ,Icon} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
//import '../assets/styles/contactUs.scss';
import { getKeys} from '../../../data/master/actions'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  icon: {
    margin: theme.spacing.unit * 2,
    color:"#007E33"
  },
}); 

class Contact extends Component {

  state = {captachKey:null};
  UNSAFE_componentWillMount() {
    this.props.getKeys('captcha_key').then((response) => {
      this.setState({captachKey:response});
    });
  }
  handleSubmit = (values) => {
    let formData = ''
    if(values.contact){
      formData =`name=${values.name}&contact=${values.contact}&email=${values.email}&message=${values.message}`
    }
    else{
      formData =`name=${values.name}&email=${values.email}&message=${values.message}`
    }
    this.props.contactUs(formData)
  }
  
  render() {
    const { classes } = this.props;
    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .required('*Required'),
      email: Yup.string()
        .email().required('*Required'),
      contact: Yup.string(),
      message: Yup.string()
        .required('*Required'),
      captcha: Yup.string()
        .required('*Required')
    });
    const initialValues = {
      name: '',
      email: '',
      contact: '',
      message: '',
      captcha:''
    }
    return (
      <div className="venue_page">  
        <div class="thankyou-page container">
            {/*<img className="check_circle---material" src={require('../../../../src/venue/assets/images/green-circle-tick.png')} alt="Space Complete" width="80px"/>  */}
            <Icon className={classes.icon}  style={{ fontSize: 80 }}>check_circle</Icon> 
             <p>Thank you for contacting us</p>
        </div>
        <VenueFooter className="fh-container" />
      </div>

    );
  }
}
const enhance = compose(withStyles(styles), withRouter, connect(null, dispatch => bindActionCreators({getKeys}, dispatch)));
export default enhance(Contact);

import { request } from 'shared/auth';
import { FORGOT_PASSWORD } from 'shared/state/types';

function forgotPasswordRequest() {
    return {
        type: FORGOT_PASSWORD.REQUEST
    };
}
function forgotPasswordSuccess(data) {
    return {
        type: FORGOT_PASSWORD.SUCCESS,
        message: data,
    };
}
function forgotPasswordFailure() {
    return {
        type: FORGOT_PASSWORD.FAILURE
    };
}

export function forgotPassword(params) {
    return dispatch => {
        dispatch(forgotPasswordRequest());
        return request()
            .post('/password_reset/create', params)
            .then(response => {
                dispatch(forgotPasswordSuccess(response.data.message));
            })
            .catch(error => {
                dispatch(forgotPasswordFailure());
            })
    };
}

import {SPACEVIDEOEDIT} from 'shared/state/types';
import {request} from 'shared/auth';

function onRequested() {
    return {type: SPACEVIDEOEDIT.REQUEST};
}

function onFailed(error) {
    return {type: SPACEVIDEOEDIT.FAILURE, error: error};
}

function onSuccess(data) {
    return {type: SPACEVIDEOEDIT.SUCCESS, payload: data};
}

export function spaceVideoUpdate(params,id,videoId,type='SPACE') {
    let url = `spaces/${id}/videos/${videoId}`;
    if(type!='SPACE'){
       url = `venues/${id}/videos/${videoId}`;
    }
    return dispatch => {
        dispatch(onRequested());
        return request()
            .put(url, params)
            .then(response => dispatch(onSuccess(response.data.data)))
            .catch(errors => {
                dispatch(onFailed(errors.response.data.errors));
                throw errors;
            });
    };
}

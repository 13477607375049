import { Record } from 'immutable';

import {
  ADD_SPACE_IMAGE,
  GET_SPACE,
  DELETE_SPACE_IMAGE,
  ADD_SPACE,
  UPDATE_SPACE,
  UPDATE_SPACE_NAME,
  SPACE_DOCUMENT_UPLOAD,
  SPACE_DOCUMENT_DELETE,
  ADD_SPACE_AVAILABILITY,
  GET_SPACE_AVAILABILITY,
  DELETE_SPACE_AVAILABILITY,
  GET_CALENDAR,
  SPACE_HOLIDAY_RATE,
  GET_SPACE_HOLIDAY_RATE,
  DELETE_SPACE_HOLIDAY_RATE,
  SPACEVIDEOADD,
  SPACEVIDEOEDIT,
  SPACEVIDEODELETE
} from 'shared/state/types';

class State extends Record({
  spaceData: {},
  requested: false,
  requesting: false,
  uploaded: false,
  uploading: false,
  error: '',
  success: '',
  warning:'',
  deleted:false,
  deleting: false,
  sending: false,
  sended: false,
  updated: false,
  updating: false,
  spaceError: {},
  spaceAvailabilityData: [],
  successDocument:'',
  calendar: '',
  calendarData: [],
  spaceHolidayRateData:[]
}) { }


export default function reducer(state = new State(), action) {
  console.log(action.calendarData,">>>>calendarData")
  switch (action.type) {
    case ADD_SPACE.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        success: '',
        error: ''
      });
    case ADD_SPACE.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        success: 'Space has been successfully added.',
        spaceData: action.spaceData,
        error: ''
      });
    case ADD_SPACE.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        // spaceData: {},
        spaceError: action.error
      });
    case UPDATE_SPACE.REQUEST:
      return state.merge({
        updating: true,
        updated: false,
        success: '',
        error: ''
      });
    case UPDATE_SPACE.SUCCESS:
      return state.merge({
        updating: false,
        updated: true,
        success: 'Data has been saved successfully.',
        spaceData: action.spaceData,
        error: ''
      });
    case UPDATE_SPACE.FAILURE:
      return state.merge({
        updating: false,
        updated: false,
        success: '',
        // spaceData: {},
        spaceError: action.error
      });
    case UPDATE_SPACE_NAME.REQUEST:
      return state.merge({
        updating: true,
        updated: false,
        success: '',
        error: ''
      });
    case UPDATE_SPACE_NAME.SUCCESS:
      return state.merge({
        updating: false,
        updated: true,
        success: 'Space has been successfully updated.',
        spaceData: action.spaceData,
        error: ''
      });
    case UPDATE_SPACE_NAME.FAILURE:
      return state.merge({
        updating: false,
        updated: false,
        success: '',
        // spaceData: {},
        error: action.error
      });
    case GET_SPACE.REQUEST:
      return state.merge({
        requesting: true,
        requested: false,
        // success: '',
        error: ''
      });
    case GET_SPACE.SUCCESS:
      return state.merge({
        requesting: false,
        requested: true,
        // success: 'Space has been found.',
        spaceData: action.spaceData,
        error: ''
      });
    case GET_SPACE.FAILURE:
      return state.merge({
        requesting: false,
        requested: false,
        error: action.error
      });
    case ADD_SPACE_IMAGE.REQUEST:
      return state.merge({
        uploading: true,
        uploaded: false,
        error: '',
        success: ''
      });
    case ADD_SPACE_IMAGE.SUCCESS:
      return state.merge({
        uploading: false,
        uploaded: true,
        success: 'Image has been successfully uploaded',
        error: ''
      });
    case ADD_SPACE_IMAGE.FAILURE:
      return state.merge({
        uploading: false,
        uploaded: false,
        error: action.error
      });
    case DELETE_SPACE_IMAGE.SUCCESS:
      return state.merge({
        deleting: false,
        deleted: true,
        success: 'Image has been successfully deleted',
        warning: 'Image has been successfully deleted',
        error: ''
      });
    case DELETE_SPACE_IMAGE.FAILURE:
      return state.merge({
        deleting: false,
        deleted: false,
        warning: '',
        error: "Image could not be deleted."
      });
    case SPACE_DOCUMENT_UPLOAD.REQUEST:
      return state.merge({
        requesting: true,
        requested: false,
        success: '',
        successDocument:'',
        error: ''
      });
    case SPACE_DOCUMENT_UPLOAD.SUCCESS:
      return state.merge({
        requesting: false,
        requested: true,
        successDocument:'',
        success: 'Document uploaded successfully.',
        error: ''
      });
    case SPACE_DOCUMENT_UPLOAD.FAILURE:
      return state.merge({
        updating: false,
        updated: false,
        successDocument:'',
        error: 'Document could not be uploaded.',
      });
    case SPACE_DOCUMENT_DELETE.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        success: '',
        successDocument:'',
        error: ''
      });
    case SPACE_DOCUMENT_DELETE.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        successDocument: 'Document deleted successfully.',
        error: '',
        success:''
      });
    case SPACE_DOCUMENT_DELETE.FAILURE:
      return state.merge({
        sending: false,
        sended: true,
        successDocument:'',
        success:'',
        error: 'Document could not be deleted.',
      });
    case ADD_SPACE_AVAILABILITY.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        success: '',
        error: ''
      });
    case ADD_SPACE_AVAILABILITY.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        success: action.message,
        error: ''
      });
    case ADD_SPACE_AVAILABILITY.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        spaceError: action.error
      });
    case GET_SPACE_AVAILABILITY.REQUEST:
      return state.merge({
        requesting: true,
        requested: false,
        error: ''
      });
    case GET_SPACE_AVAILABILITY.SUCCESS:
      return state.merge({
        requesting: false,
        requested: true,
        spaceAvailabilityData: action.spaceAvailabilityData.data,
        error: ''
      });
    case GET_SPACE_AVAILABILITY.FAILURE:
      return state.merge({
        requesting: false,
        requested: false,
        error: action.error
      });
    case DELETE_SPACE_AVAILABILITY.REQUEST:
      return state.merge({
        deleting: false,
        deleted: false,
        success: '',
        warning: '',
        error: ''
      });
    case DELETE_SPACE_AVAILABILITY.SUCCESS:
      return state.merge({
        deleting: false,
        deleted: true,
        success: action.message,
        error: ''
      });
    case DELETE_SPACE_AVAILABILITY.FAILURE:
      return state.merge({
        deleting: false,
        deleted: false,
        warning: '',
        error: "Resource could not be deleted."
      });
    case GET_CALENDAR.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        // success: '',
        error: ''
      });
    case GET_CALENDAR.SUCCESS:
      console.log(action.calendarData,">>>>>>>>>>calendarData2")
      return state.merge({
        sending: false,
        sended: true,
        // success: 'Space has been successfully added.',
        calendarData: action.calendarData,
        error: ''
      });
    case GET_CALENDAR.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        // success: '',
        // spaceData: {},
        error: action.error
      });
      case SPACE_HOLIDAY_RATE.REQUEST:
        return state.merge({
          sending: true,
          sended: false,
          success: '',
          error: ''
        });
      case SPACE_HOLIDAY_RATE.SUCCESS:
        return state.merge({
          sending: false,
          sended: true,
          success: 'Holiday rates have been successfully added.',
          spaceHolidayRateData: action.spaceHolidayRateData,
          error: ''
        });
      case SPACE_HOLIDAY_RATE.FAILURE:
        return state.merge({
          sending: false,
          sended: false,
          success: '',
          spaceError: action.error
        });
        case GET_SPACE_HOLIDAY_RATE.REQUEST:
      return state.merge({
        requesting: true,
        requested: false,
        error: ''
      });
    case GET_SPACE_HOLIDAY_RATE.SUCCESS:
      return state.merge({
        requesting: false,
        requested: true,
        spaceHolidayRateData: action.spaceHolidayRateData,
        error: ''
      });
    case GET_SPACE_HOLIDAY_RATE.FAILURE:
      return state.merge({
        requesting: false,
        requested: false,
        error: action.error
      });
    case DELETE_SPACE_HOLIDAY_RATE.REQUEST:
      return state.merge({
        deleting: false,
        deleted: false,
        success: '',
        warning: '',
        error: ''
      });
    case DELETE_SPACE_HOLIDAY_RATE.SUCCESS:
      return state.merge({
        deleting: false,
        deleted: true,
        success: action.message,
        error: ''
      });
    case DELETE_SPACE_HOLIDAY_RATE.FAILURE:
      return state.merge({
        deleting: false,
        deleted: false,
        warning: '',
        error: "Resource could not be deleted."
      });
      case SPACEVIDEOADD.REQUEST:
        return state.merge({
          requesting: true,
          requested: false,
          success: '',
          error: ''
        });
      case SPACEVIDEOADD.SUCCESS:
        return state.merge({
          requesting: false,
          requested: true,
          success: 'Link added successfully.',
          error: ''
        });
      case SPACEVIDEOADD.FAILURE:
        return state.merge({
          updating: false,
          updated: false,
          error: 'Link could not be added.',
        });
        case SPACEVIDEOEDIT.REQUEST:
          return state.merge({
            requesting: true,
            requested: false,
            success: '',
            error: ''
          });
        case SPACEVIDEOEDIT.SUCCESS:
          return state.merge({
            requesting: false,
            requested: true,
            success: 'Link updated successfully.',
            error: ''
          });
        case SPACEVIDEOEDIT.FAILURE:
          return state.merge({
            updating: false,
            updated: false,
            error: 'Link could not be updated.',
          });
        case SPACEVIDEODELETE.REQUEST:
          return state.merge({
            deleting: false,
            deleted: false,
            success: '',
            warning: '',
            error: ''
          });
        case SPACEVIDEODELETE.SUCCESS:
          return state.merge({
            deleting: false,
            deleted: true,
            success: 'Link deleted successfully.',
            error: ''
          });
        case SPACEVIDEODELETE.FAILURE:
          return state.merge({
            deleting: false,
            deleted: false,
            warning: '',
            error: "Resource could not be deleted."
          });
    default:
      return state;
  }
}


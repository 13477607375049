import axios from 'axios';
import { GET_FEATURE_REQUEST, GET_FEATURE_SUCCESS, GET_FEATURE_FAILURE } from 'shared/state/types';
function featureRequested() {
  return {
    type: GET_FEATURE_REQUEST
  };
}

function featureSuccess(data) {
  return {
    type: GET_FEATURE_SUCCESS,
    featureData: data,
  };
}

function featureFailure(error) {
  return {
    type: GET_FEATURE_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }


  return axios.create({
    baseURL,
    headers,
  })
};

export function featureTypes() {

    return dispatch => {
      dispatch(featureRequested());
        return request().get(`feature_types`)
        .then(response =>
          dispatch (featureSuccess(response.data),)
        )
        .catch(errors => {
          dispatch(featureFailure(errors));
  
          throw errors;
        });
      

    
 };
}

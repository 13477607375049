// import axios from 'axios';
import { request } from 'shared/auth';

import { GET_CATEGORY_REQUEST,GET_CATEGORY_FAILURE,GET_CATEGORY_SUCCESS } from 'shared/state/types';

function categoryRequested() {
  return {
    type: GET_CATEGORY_REQUEST
  };
}

function categorySuccess(data) {

  return {
    type: GET_CATEGORY_SUCCESS,
    categoryData: data,
  };
}


function categoryFailure(error) {
  return {
    type: GET_CATEGORY_FAILURE,
    error:error,
  };
}



export function addCategory(params) {
    
    return dispatch => {
      dispatch(categoryRequested());
     
      return request().get(`venue_types`)
      .then(response =>
        {dispatch (categorySuccess(response.data),
              )
                    return response.data}
      )
      .catch(errors => {
        dispatch(categoryFailure(errors.response));

        throw errors;
      });
      


    
 };
}

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';


import { Grid, Button } from '@material-ui/core';

import './assets/styles/spaceComplete.scss';

class AdditionalVenue extends Component {
  render() {
    return (
      <div>
        <Grid container className="space-complete">
          <Grid xs={12} item>
            <Grid container className="mask">

              <Grid item md={9} xs={12} sm={9} xl={9} lg={3}>
              </Grid>

              <Grid item md={9} xs={12} sm={9} xl={9} lg={6} className="venueCompleteContent">

                <img className="check_circle---material" src={require('./assets/images/green-circle-tick.png')} alt="Space Complete" />

                <h2 className="title">Additional Venue</h2>

                <p className="thankYouText">
                    We are thrilled you’d like to add another venue to your profile.  Once you complete the setup process for this venue and you are ready to publish, you will be prompted to select your preferred pricing tear.
                </p>
                <div className="link-conatiner">
                    <Link to='/venuePlan'>Pricing Options</Link>
                </div>
                <Button variant="outlined" color="secondary" className="button-bg" component={Link} to='/addVenue'>
                  <span className="button-text">Add Venue</span>
                </Button>
              </Grid>

              <Grid item md={9} xs={12} sm={9} xl={9} lg={3}>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default (withRouter(AdditionalVenue));


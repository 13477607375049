import axios from 'axios';
import {FIND_GOOGLE_CALENDAR} from 'shared/state/types';
import {loadProgressBar} from 'axios-progress-bar'

function onRequested() {
    return {type: FIND_GOOGLE_CALENDAR.REQUEST};
}

function onSuccess(data) {
    return {type: FIND_GOOGLE_CALENDAR.SUCCESS, tokenData: data};
}

function onFailure(error) {
    return {type: FIND_GOOGLE_CALENDAR.FAILURE, error: error};
}

const request = () => {
    const headers = {};
    if (localStorage.getItem('msaccesstoken')) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('msaccesstoken');
    }
    const axiosInstance = axios.create({headers})
    loadProgressBar({
        showSpinner: true
    }, axiosInstance)
    return axiosInstance;
};

export function getMicrosoftCalnderList() {

    return dispatch => {
        dispatch(onRequested());
        return request()
            .get(`https://graph.microsoft.com/v1.0/me/calendars`)
            .then(response => {
                dispatch(onSuccess(response.data),)
                return response.data;
            })
            .catch(errors => {
                if (errors.response && errors.response.data.message) {
                    dispatch(onFailure(errors.response.data.message));
                } else {
                    dispatch(onFailure("Not able to find this calendar list"));
                }
                throw errors;
            });
    };
}

import axios from 'axios';
import { GET_VENUE_REQUEST, GET_VENUE_SUCCESS, GET_VENUE_FAILURE } from 'shared/state/types';
// import Cookies from 'js-cookie';
import { loadProgressBar } from 'axios-progress-bar'

function venueRequested() {
  return {
    type: GET_VENUE_REQUEST
  };
}

function venueSuccess(data) {
  return {
    type: GET_VENUE_SUCCESS,
    venueDetails: data,
  };
}

function venueFailure(error) {
  return {
    type: GET_VENUE_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');
  }
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function venueProfile(params,photoCount) {

    return dispatch => {
      dispatch(venueRequested());
      if(photoCount===1)
      {
      if(localStorage.getItem('fhtoken')!=null){
        return request().get(`planner/venue/profile/${params}?photoCount=${photoCount}`)
        .then(response =>
          dispatch (venueSuccess(response.data),
         )
        )
        .catch(errors => {
          if(errors.response && errors.response.data.message){
            dispatch(venueFailure(errors.response.data.message));
          }
          else{
            // window.location.href = './'
            dispatch(venueFailure("Not able to find this space"));
          }
  
          throw errors;
        });
      }
      else
      {
      return request().get(`venue/profile/${params}?photoCount=${photoCount}`)
      .then(response =>
        dispatch (venueSuccess(response.data),
        )
      )
      .catch(errors => {
        if(errors.response && errors.response.data.message){
          dispatch(venueFailure(errors.response.data.message));
        }
        else{
          // window.location.href = './'
          dispatch(venueFailure("Not able to find this space"));
        }

        throw errors;
      });
      }
    }
    else{
      if(localStorage.getItem('fhtoken')!=null){
        return request().get(`planner/venue/profile/${params}?photoCount=${photoCount}`)
        .then(response =>
          dispatch (venueSuccess(response.data),
         )
        )
        .catch(errors => {
          if(errors.response && errors.response.data.message){
            dispatch(venueFailure(errors.response.data.message));
          }
          else{
            // window.location.href = './'
            dispatch(venueFailure("Not able to find this space"));
          }
  
          throw errors;
        });
      }
      else
      {
      return request().get(`venue/profile/${params}?photoCount=${photoCount}`)
      .then(response =>
        dispatch (venueSuccess(response.data),
        )
      )
      .catch(errors => {
        if(errors.response && errors.response.data.message){
          dispatch(venueFailure(errors.response.data.message));
        }
        else{
          // window.location.href = './'
          dispatch(venueFailure("Not able to find this space"));
        }

        throw errors;
      });
      }
    
    }

    
 };
}

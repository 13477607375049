import { CONTACT_IMAGE_UPLOAD } from 'shared/state/types';
import { request } from 'shared/auth';

function contactImageUploadRequested() {
  return {
    type: CONTACT_IMAGE_UPLOAD.REQUEST,
  };
}

function contactImageUploadFailed(error) {
  return {
    type: CONTACT_IMAGE_UPLOAD.FAILURE,
    error:error,
  };
}

function contactImageUploadSuccess(data) {
  return {
    type: CONTACT_IMAGE_UPLOAD.SUCCESS,
    payload: data,
  };
}

export function contactImageUpload(params,id) {
  return dispatch => {
    dispatch(contactImageUploadRequested());

    return request().post(`venues/${id}/contactImage`, params)
      .then(response =>{
          dispatch(contactImageUploadSuccess(response.data.data))
          return response.data.data
        }
      )
      .catch(errors => {
        dispatch(contactImageUploadFailed(errors.response.data.errors));
        throw errors;
      });
  };
}

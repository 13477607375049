import {request} from 'shared/auth';
import {RESENDOTP} from 'shared/state/types';

function onRequest() {
    return {type: RESENDOTP.REQUEST};
}

function onSuccess(data) {
    return {type: RESENDOTP.SUCCESS, payload: data};
}

function onFailure(data) {
    return {type: RESENDOTP.FAILURE, payload: data};
}

export function resendOTP(params) {
    return dispatch => {
        dispatch(onRequest());
        return request()
            .post('/auth/resend-otp', params)
            .then(response => {
                dispatch(onSuccess(response.data.message));
                return response.data
            })
            .catch(errors => {
                dispatch(onFailure(errors.response.data.message));
                throw errors;
            })
    };
}

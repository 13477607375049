import { Record } from 'immutable';

import {
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_FAILURE,
  GET_USER_INFO_SUCCESS,
  GET_VENUE_LIST_REQUEST,
  GET_VENUE_LIST_FAILURE,
  GET_VENUE_LIST_SUCCESS,
} from 'shared/state/types';

class State extends Record({
  userInfo:{},
  venueList:{},
  error:''
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_USER_INFO_REQUEST:
      return state.merge({
        error:''
      });
    case GET_USER_INFO_FAILURE:
      return state.merge({
        userInfo:{},
        error: action.error


      });
    case GET_USER_INFO_SUCCESS:
      return state.merge({
        userInfo:action.userInfo,
        error:'Success'
      });
      case GET_VENUE_LIST_REQUEST:
      return state.merge({
        error:''
      });
    case GET_VENUE_LIST_FAILURE:
      return state.merge({
        venueList:{},
        error: action.error


      });
    case GET_VENUE_LIST_SUCCESS:
      return state.merge({
        venueList:action.venueList,
        error:'Success'
      });

    default:
      return state;
  }
}


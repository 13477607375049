import React from 'react';

import { Grid } from '@material-ui/core';

import CustomizedGoogleMap from '../../shared/components/customizedGoogleMap';
import '../assets/styles/spaceProfile.scss';
import VenueDetailsImage from './venueDetailsImage';

function TopImageComponent(props) {
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={8} xl={8} lg={8}>

                <VenueDetailsImage
                    venueData={props.venueData}
                    venueDetails={props.venueDetails}
                    errorCrop={props.errorCrop}
                    name={props.name}
                    okButton={props.okButton}
                    cancelButton={props.cancelButton}
                    imageFileData={props.imageFileData}
                    imageUrl={props.imageUrl}
                    crop={props.crop}
                    zoom={props.zoom}
                    aspect={props.aspect}
                    rotation={props.rotation}
                    onRotationChange={props.onRotationChange}
                    onCropChange={props.onCropChange}
                    onCropComplete={props.onCropComplete}
                    onZoomChange={props.onZoomChange}
                    cropImage={props.cropImage}
                    spaceId={props.spaceId ? props.spaceId : null}
                    error={props.error}
                    spaceImage={props.spaceImage}
                    images={props.images}
                    editImage={props.editImage}
                    formikProps={props.formikProps}
                    imageFile={props.imageFile}
                    imageUploaded={props.imageUploaded}
                    imageUploading={props.imageUploading}
                    handleImageUpload={props.handleImageUpload}
                    handleImageChange={props.handleImageChange}
                    openImageUploadForm={props.openImageUploadForm}
                    handleEditPhotosClick={props.handleEditPhotosClick}
                    updateImagesOrder={props.updateImagesOrder}
                    updateName={props.updateName}
                    updateWebsite={props.updateWebsite}
                    isEditVenueImage={true}
                    deleteImage={props.deleteImage}
                    venueImageError={props.venueImageError}
                    imageFileName={props.imageFileName} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={4} lg={4}>
                <CustomizedGoogleMap
                    markers={props.markers}
                    isMarkerShown
                    height="394px"
                    venue={true}
                // latitude={props.latitude}
                // longitude={props.longitude}
                />
            </Grid>
        </Grid>

    );
}
export default TopImageComponent;

import React from 'react';

import { Grid } from '@material-ui/core';

import VenueTypeCard from '../../shared/components/VenueTypeCard.js';


function FeatureVenues(props) {
    const venueObject = props.findFeatureVenueData.map(function(data, idx) {
        return(
                <VenueTypeCard key={idx} event={data.event} image={data.image} linkTo={`/browseVenue/${data.event}/type`} enableLink/>
        );
    });

    return (
        <Grid container className="find_venues fh-container">
            <Grid xs={12} item>
                <h1 className="find_venue_heading">What type of event are you planning?</h1>
            </Grid>
            <Grid xs={12} item>
                <Grid container justify="center" spacing={40}>
                    {venueObject}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default FeatureVenues;
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import "./assets/style/placeHold.scss";
import "./assets/style/venuePublishPopup.scss";
import CustomizedButton from '../components/customizedButton';
import { object } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";

const styles = theme => ({
    iconImage:{
        height:"24px",
        width:"24px !important",
        marginLeft:"26px",
        '&:hover': {
          backgroundColor:'#d80e2a'
        }
      },
root: {
margin: 0,
},
closeButton: {
position: 'absolute',
top: '8px',
color: '#9e9e9e',
right: '8px',
},
});

withStyles(styles)(props => {
const { children, classes, onClose } = props;
return (
<MuiDialogTitle disableTypography className={classes.root}>
<Typography variant="h6">{children}</Typography>
{onClose ? (
<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
<CloseIcon />
</IconButton>
) : null}
</MuiDialogTitle>
);
});

const DialogContent = withStyles(theme => ({
root: {
},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
root: {
},
}))(MuiDialogActions);

class VenuePublishPopup extends React.Component {
state = {
open: false,
};

handleClickOpen = () => {
this.setState({
open: true,
});
};

handleCancelClick = () => {
  this.setState({open:false})
};

handleClose = () => {
  this.setState({open:false})
};
helpCenter = () => {
  window.open("https://www.first-hold.com/help/articles?keyword=sync",'_blank')
};
redirect = () =>{
  window.open("https://chrome.google.com/webstore/detail/first-hold/gbgllbpnnhldcnpkbfmiiikkbggjknke");
}
render() {
    // const { classes } = props;
return (
<div>
<Button class="red-button btn-right btn-publish" onClick={this.handleClickOpen}>
  Publish
</Button> 
<Dialog 
className="success_modal_container view-listing-popup"
aria-labelledby="customized-dialog-title"
open={this.state.open} >
<DialogActions className="dialogActions">
<span
className="material-icons close closeButton"
onClick={this.handleClose}
>
close
</span>
</DialogActions>
<DialogContent className="dialog-content">
<Grid container className="modalBox_content">
<Grid item xs={12}>
<Grid container className="">
<Grid item xs={12} sm={12}>
<div className="header">Ready to showcase this space?</div>
</Grid>
<Grid item xs={12} sm={12}>
    <div className="venue-publish-popup-box">

      <p>
        {/* Don’t forget! */}
        Remember to sync and double check your calendar before publishing.
        {/* Block your Calendar before publishing your profile.  Simply click  */}
        {/* <p style={{color: '#ee2738',display:'inline-block'}}> Sync Google Calendar</p> and follow the steps.  It’s Easy.  You can keep your Calendar updated and receive notifications <span className="publishText">by adding the </span><p style={{color: '#ee2738',display:'inline-block'}}>Extension</p>.</p> */}
        <br></br>
        <br />
        Need help syncing your calendar?  Visit our Help Center
        for a step-by-step guide.
        <br></br>
        <br />
        For additional support, reach out to us<br/>
        <a href="mailto:hello@first-hold.com">hello@first-hold.com</a>
      </p>
      <div class="group-buttons">
          <Grid item xs={12} className="banner_button" container justify="center">
                 <CustomizedButton label="Publish" onClickEvent={this.props.handlePublish} type="submit" />
          </Grid>
          {/* <Grid item xs={12} className="banner_button" container justify="center">
                <CustomizedButton onClickEvent={this.props.syncCalendar} label="Sync Google Calendar" type="submit" />
          </Grid> */}
          <Grid item xs={12} className="banner_button" container justify="center">
                <CustomizedButton onClickEvent={this.helpCenter} label="Help Center" type="submit" />
          </Grid>
          {/* <Grid item xs={12} className="banner_button" container justify="center">
          <Button style={{background:"linear-gradient(to bottom, #f1435e, #ee2738)"}} onClick={this.redirect} className="publishHide"> Add Extension </Button>
        </Grid> */}
          {/* <Grid item xs={12} className="cancel_button" container justify="center">
              <div className="white-button-div">
                  <Button variant="outline-danger" className="white-button-small" onClick={this.handleCancelClick}>
                                  Cancel
                  </Button>
              </div>
          </Grid> */}
      </div>
    </div>
</Grid>

</Grid>
</Grid>

</Grid>

</DialogContent>


</Dialog>

</div>
);
}
}

VenuePublishPopup.propTypes = {
classes: object.isRequired,
};

const mapStateToProps = state => {
return {

};
};

const enhance = compose(
withRouter,
connect(mapStateToProps,dispatch =>
bindActionCreators(
{

},
dispatch
)
)
);
export default enhance(VenuePublishPopup);


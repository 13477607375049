import React from 'react';
import moment from 'moment';
import { object } from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import '../assets/styles/landing-page.scss';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ContactUs from 'CMS/locationContactPopup/container/locationContactPopup';



function Footer(props) {
  let searchData = encodeURIComponent("Napa & Sonoma")
  const [open, setopen] = React.useState(false);
  const DialogContent = withStyles(theme => ({
    root: {
    },
  }))(MuiDialogContent);
  const handleContactPopup = () => {
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };
  return (
    <div>
      <Grid container className="box-wrapper location">
        <Grid className="location-block" item xs={12} sm={6} md={6} lg={6} xl={6}>
          <div className="heading-text block">
            <h1 className="heading-text">Locations</h1>
          </div>
          <Link to="/browseVenue?address=Silicon Valley">Silicon Valley, CA</Link>
          <Link to="/browseVenue?address=San Francisco">San Francisco, CA</Link>
          <Link to="/browseVenue?address=East Bay">East Bay, CA</Link>
          <Link to={`/browseVenue?address=${searchData}`}>North Bay, CA</Link>
          <Link to={`/browseVenue?address=${searchData}`}>Napa & Sonoma, CA</Link>
          <Link to={`/browseVenue?address=${searchData}`}>Wine Country, CA</Link>
        </Grid>
        <Grid className="location-block" item xs={12} sm={6} md={6} lg={6} xl={6}>
          <div className="heading-text block">
            <h1 className="heading-text">Popular Searches</h1>
          </div>
          <Link to={`/browseVenue?address=${searchData}`}>Winery</Link>
          <Link to="/browseVenue/meeting/type">Good for meetings</Link>
          <Link to="/browseVenue?address=San Francisco">Unique event spaces</Link>
          <Link></Link>
          <Link></Link>
          <Link></Link>
        </Grid>
      </Grid>
        <div className="contact search-btn started" >
            <Button
            className=""
            variant="outline-danger"
            onClick={handleContactPopup}>
             Contact Us
            </Button>
        </div>
      <Dialog className="contact_modal_container contact_popup"
        aria-labelledby="customized-dialog-title"
        open={open}>

        <DialogActions className="dialogActions">
          <span className="material-icons close closeButton" onClick={handleClose}>close</span>
        </DialogActions>
        <DialogContent className="dialog-content">
          <ContactUs />
        </DialogContent>
      </Dialog>
    </div>
  );
}

Footer.propTypes = {
  classes: object.isRequired,
};

export default Footer;

import { Record } from 'immutable';

import {
  GET_FEATURE_REQUEST,
  GET_FEATURE_FAILURE,
  GET_FEATURE_SUCCESS,
} from 'shared/state/types';

class State extends Record({
  featureList:{},
  error:''
  
}) {}


export default function reducer(state = new State(), action) {

  switch (action.type) {
    case GET_FEATURE_REQUEST:
      return state.merge({
        error:''
      });
    case GET_FEATURE_FAILURE:
      return state.merge({
        featureList:{},
        error: action.error


      });
    case GET_FEATURE_SUCCESS:
      return state.merge({
        featureList:action.featureData,
        error:'Success'
      });


    default:
      return state;
  }
}


import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import CustomMenuItem from './customMenuItem';

import { IconButton, Icon } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import NotificationViewAll from '../../shared/components/notificationViewAll';
import {getNotifications} from 'data/master/actions';
import Badge from '@material-ui/core/Badge';
import './assets/style/NotificationPopup.scss';
import { userDetails } from "shared/venueList/actions";
const styles = theme => ({

  root: {
    display: 'flex',
    float: 'left'
  },
  notificationsIcon: {
    padding: '0px 0px 0px 0px'
  },
  MuiPaper: {
    width: '400px',
    boxShadow: 'none',
    position: 'absolute',
    top: '0',
    left: '-392px',

  },
  MuiMenuItem: {
    height: 'auto',
    lineHeight: '0',
    padding: '5px 15px',
    borderBottom: '0',

    hover: {
      background: '#fff',
    }
  },
  redIcon:{
    color:'#ee2738'
  },
  badgeIcon:{
    color:'#ffffff',
    background:'#ee2738'
  }

});

class MenuListComposition extends React.Component {
  state = {
    open: false,
    openNotification: false,
    notifications: [],
    loading: true,
    notificationCount:0
  };
  UNSAFE_componentWillMount() {
    
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.notifications !== nextProps.notifications && Object.keys(nextProps.notifications).length !== 0){
      this.setState({notifications: nextProps.notifications.data})
    }
    if(nextProps.userData && nextProps.userData.data && nextProps.userData.data.notification_count && this.state.notificationCount != nextProps.userData.data.notification_count){
      this.setState({notificationCount:nextProps.userData.data.notification_count});
    }
    
  }
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
    if(!this.state.open && this.state.notifications.length === 0){
      this.props.getNotifications(1).then((response) => {
        this.setState({ loading: false,notificationCount:0 });
        this.props.userDetails();
      });
    }
  };

  handleClose = (event) => {
    // if (this.anchorEl.contains(event.target)) {
    //   return;
    // }

    this.setState({ open: false });
  };

  openNotification = () =>{
    this.setState({openNotification:true})
  };
  handleSubmit = (values) => {
  }
notclose=()=>{
  //this.setState({open:true});
}
  render() {
    const { classes } = this.props;
    const { open } = this.state;
    const notificationObject = this.state.notifications ?  this.state.notifications.map((data) => {
      return (
        <div onClick={()=>this.notclose()}>
          {data.resource_type === 13 || data.resource_type === 25 || data.resource_type === 22 ?
            <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}  eventName={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.name} eventDate={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.start_date} spaceName={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.name} venueName={data.resource && data.resource.space && data.resource.space.venue  &&  data.resource.space.venue.name} createdAt={data.created_at} spaceImage={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.photo && data.resource.event_timeslot.space.photo.length > 0  && data.resource.event_timeslot.space.photo[0].path} resourceId={data.resource && data.resource.id} holdDuration={data.resource && data.resource.hold_duration} expaired={data.resource && data.resource.expired_on_diff} eventType={data.resource && data.resource.hold_type}  />
            : data.resource_type === 23 || data.resource_type === 26 ?
            <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}  eventName={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.name} eventDate={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.start_date} spaceName={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.name} venueName={data.resource && data.resource.space && data.resource.space.venue  &&  data.resource.space.venue.name} createdAt={data.created_at} spaceImage={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.photo && data.resource.event_timeslot.space.photo.length > 0  && data.resource.event_timeslot.space.photo[0].path} price={data.resource.price} resourceId={data.resource && data.resource.id}  holdDuration={data.resource && data.resource.hold_duration} expaired={data.resource && data.resource.expired_on_diff} eventType={data.resource && data.resource.hold_type}/>
            : data.resource_type === 14 || data.resource_type === 24 ?
            <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}  eventName={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.event_planner ? `${data.resource.event_timeslot.event.event_planner.first_name} ${data.resource.event_timeslot.event.event_planner.last_name}` : ''} eventDate={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.start_date} spaceName={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.name} venueName={data.resource && data.resource.space && data.resource.space.venue  &&  data.resource.space.venue.name} createdAt={data.created_at} spaceImage={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.event_planner && data.resource.event_timeslot.event.event_planner.profilePhoto && data.resource.event_timeslot.event.event_planner.profilePhoto.path} price={data.resource.price} resourceId={data.resource && data.resource.id}  holdDuration={data.resource && data.resource.hold_duration} expaired={data.resource && data.resource.expired_on_diff} eventType={data.resource && data.resource.hold_type} />
            : data.resource_type === 16 || data.resource_type === 17 ?
            <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}  eventName={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.event_planner ? `${data.resource.event_timeslot.event.event_planner.first_name} ${data.resource.event_timeslot.event.event_planner.last_name}` : ''} eventDate={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.start_date} spaceName={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.name} venueName={data.resource && data.resource.space && data.resource.space.venue  &&  data.resource.space.venue.name} createdAt={data.created_at} spaceImage={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.event_planner && data.resource.event_timeslot.event.event_planner.profilePhoto && data.resource.event_timeslot.event.event_planner.profilePhoto.path} price={data.resource.price} holdBook={this.holdBookApi} updateEventTimeSlot={this.updateEventTimeSlotApi} resourceId={data.resource && data.resource.id} eventTimeSlotId={data.resource && data.resource.event_timeslot_id} status={data.resource ? data.resource.status : ''}  holdDuration={data.resource && data.resource.hold_duration} expaired={data.resource && data.resource.expired_on_diff} eventType={data.resource && data.resource.hold_type} />
            : data.resource_type === 32 || data.resource_type === 33 || data.resource_type === 34 || data.resource_type === 35 ?
            <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}    createdAt={data.created_at} link={data.link} />
            : <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}  eventName={data.resource.event && data.resource.event.name} eventDate={data.resource.event && data.resource.event.start_date} spaceName={data.resource.space && data.resource.space.name} venueName={data.resource && data.resource.space && data.resource.space.venue  &&  data.resource.space.venue.name} createdAt={data.created_at} spaceImage={data.resource.space && data.resource.space.photo && data.resource.space.photo.length > 0  && data.resource.space.photo[0].path} resourceId={data.resource && data.resource.id}  holdDuration={data.resource && data.resource.hold_duration} expaired={data.resource && data.resource.expired_on_diff} eventType={data.resource && data.resource.hold_type}/>
          }        
        </div>
      )
    })
    : null

    return (
      <div className={classes.root} onClick={()=>this.notclose()}>
        <IconButton className={this.state.open?classes.redIcon:""}  title="Notification" buttonRef={node => {
          this.anchorEl = node;
        }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}>
          { this.state.notificationCount > 0?
            <Badge badgeContent={this.state.notificationCount} color="error" >
              <Icon>notifications</Icon>
            </Badge>
            :
            <Icon>notifications</Icon>
          }
        </IconButton>
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper classes={{ root: classes.MuiPaper }} className="main_section" >
                {/* <ClickAwayListener onClickAway={this.handleClose}> */}
                  <Grid className="save_button" item xs={12}>
                      {/* <Grid container justify='center'>
                          <Grid item>
                            <Button 
                                  classes={{
                                              root: classes.root,
                                              disabled: classes.disabled,
                                          }} 
                                  onClick={(e)=>{
                                    this.openNotification()
                                  }}> View all
                            </Button>
                            {this.state.openNotification?
                              <NotificationViewAll/>
                              :null
                            }
                          </Grid>
                      </Grid> */}
                  </Grid>
                  {
                    this.state.loading ?
                    <div className="no-data-loader" onClick={()=>this.notclose()}>
                      Loading...
                    </div>
                    :
                    (this.state.notifications.length > 0) ?
                      <div className="notification-box">
                        {notificationObject}
                        <Button><a href="/notificationViewAll">View All</a></Button>
                      </div>
                    :
                    <div className="no-data-loader">No Data Found</div>
                }
                {/* </ClickAwayListener> */}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}
MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    notifications: state.data.master.notifications,
    userData: state.data.usersVenue.userInfo
  };
};

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    dispatch => bindActionCreators({
      getNotifications,
      userDetails
    }, dispatch),
  ),
)
export default enhance(MenuListComposition);

import { Record } from 'immutable';

import {
  EDIT_VENUE_REQUEST,
  EDIT_VENUE_FAILURE,
  EDIT_VENUE_SUCCESS,
} from 'shared/state/types';

class State extends Record({
  error:'',
  success:'',
  sended:false,
  sending:false
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case EDIT_VENUE_REQUEST:
      return state.merge({
        error:'',
        success:'',
        sending:true,
        sended:false
      });
    case EDIT_VENUE_FAILURE:
      return state.merge({
        error: action.error,
        success:'',
        sended:false,
        sending:false


      });
    case EDIT_VENUE_SUCCESS:
      return state.merge({
        success:'Success',
        sended:true,
        sending:false,
        error:''
      });

    default:
      return state;
  }
}


import React from 'react';

import { Grid } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
// import "react-alice-carousel/lib/scss/alice-carousel.scss";
// packages/web/src/home/assets/styles/featuredVenue.scss
import './../assets/styles/featuredVenue.scss';
import VenueCardWithFav from '../../shared/components/VenueCardWithFav.js';

function FeatureVenues(props) {
    // const responsive = {
    //   0: { items: 1 },
    //   // 500:{ items: 2.55 },
    //   700:{ items:1.5 },
    //   1000: { items: 4 }
    //   // 1080: { items: 5 }
    // }

    // const state = {
    //   galleryItems: [1, 2, 3].map((i) => <h2 key={i}>{i}</h2>),
    // }
   
    const responsive = {
      0: { items: 1 },
      767: { items: 2.65 },
      1024: { items: 3.65 },
    }

    // const onSlideChange=(e)=>{
     
    // }
   
    // const onSlideChanged=(e)=>{
     
    // }
  
    const venueObject = props.featureVenueData && props.featureVenueData.map(function(data, idx) {
        return(
          <VenueCardWithFav height='122px' width='200px' imageSize='small' addressColor="#1397e1" idx={idx} image={data.image} favourite={data.favourite} venueAddress={data.venueAddress} venueName={data.venueName} category={data.category} enableLink linkTo={"/venue-profile/" + data.id}/>
        );
    });

    return (
        <Grid container className="feature_venues fh-container featured_venue_slider">
          <Grid xs={12} item>
            <h1 className="featured_venue_heading">Browse Real, Available Event Venues</h1>
          </Grid>
          <Grid xs={12} item className="featured_venue_carousel">
            {/* <AliceCarousel
              items={venueObject}
              responsive={responsive}
              autoPlayInterval={4000}
              autoPlay={true}
              fadeOutAnimation={true}
              mouseDragEnabled={true}
              stopAutoPlayOnHover={true}
              buttonsDisabled={true}
              dotsDisabled={true}
              infinite={true}
            /> */}
            <AliceCarousel
              items={venueObject}
              responsive={responsive}
              autoPlayInterval={3000}
              autoPlayDirection="lft"
              autoPlay={true}
              fadeOutAnimation={true}
              touchTrackingEnabled={true}
              mouseTrackingEnabled={true}
              mouseDragEnabled={true}
              playButtonEnabled={false}
              disableAutoPlayOnAction={true}
              // onSlideChange={onSlideChange}
              // onSlideChanged={onSlideChanged}
              buttonsDisabled={true}
              dotsDisabled={true}
            />
          </Grid>
        </Grid>
    );
}

export default (FeatureVenues);

import React from 'react';
import "../assets/styles/buttonsHeader.scss";
import { Grid,Button } from '@material-ui/core';
import '../assets/styles/buttonsHeader.scss';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { object, func } from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { updateSpace } from 'data/spaces/actions';
import VenuePublishPopup from '../../shared/components/venuePublishPopup';
import ErrorList from '../../shared/components/errorList';
import FinishPopup from '../../shared/components/finishPopup';
import PublishPopup from '../../shared/components/publishPopup';
import UnPublishPopup from '../../shared/components/unPublishPopup';
import { getPlans } from 'data/plan/actions';
import { saveExit } from '../../shared/saveExit/actions';

const styles = theme => ({
    icon: {
        marginLeft: '8px',
        // paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        marginLeft: '8px',
        // paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '100px'
    },
    root: {
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    typography: {
        width: '237px',
        height: '120px',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        fontFamily: 'Raleway'
    }
});
class ButtonsHeader extends React.Component {

    constructor(props) {
      super(props);

      this.handleFinishClick = this.handleFinishClick.bind(this);
    }
    state = {
        unPublishPopup:false,
        publishPopup:false,
        errorOpen:false,
        buttonDisabledPublish:true,
        finish_submit:'Finish & Submit',
        waiting_approval:'Waiting for approval',
        publish:'Publish',
        unpublish:'Unpublish',
        offline:'Offline',
        online:'Online',
        message:'Offline until first hold approved',
        approved:this.props.venueDetails.approved,
        published:this.props.venueDetails.published,
        spacePublished:this.props.spaceDetails && this.props.spaceDetails.published,

        isToggleOpen: false,
        anchorEl: null,
        buttonDisabled:true,
        messageQus:"",
        saveDisable:false,
        errorMsg:'',
        errorLists:[],
        venuePublished:this.props.venueDetails.published,
        venueApproved:this.props.venueDetails.approved,
        buttonColor:false


    }

    componentWillMount(){

        let token=false;
        let error=false;
        let time_token=false;

        let image=true;
        let errorList=[];
        let tokenVenue=false;
        if(this.state.approved!==-1){
            if(this.props.name==='spaceEdit'){

                 this.setState({published:this.props.spaceDetails.published})

                    //let msg="";
                    if((this.props.spaceDetails.description===''||
                     this.props.spaceDetails.description===null)

                     || this.props.spaceDetails.photo_count < 3 ||
                     this.props.spaceDetails.arrangements.length === 0
                     || this.props.spaceDetails.timeslots.length===0)
                     {
                        if(this.props.spaceDetails.description===''||
                        this.props.spaceDetails.description===null)
                        {
                            errorList.push("Space Description");
                        }
                        if(this.props.spaceDetails.photo &&this.props.spaceDetails.photo_count<3){
                            errorList.push("Space Photos");

                        }
                        if(this.props.spaceDetails.arrangements && this.props.spaceDetails.arrangements.length === 0){
                            errorList.push("Space Arrangements");
                        }
                        if(this.props.spaceDetails.timeslots && this.props.spaceDetails.timeslots.length===0){
                            errorList.push("Space Timeslot");

                        }
                        this.setState({errorLists:errorList})

                        this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Spaces doesn't have minimum criteria."})            
                     }
                    else if(this.props.spaceDetails.timeslots.length!==0){
                        for(let j=0;j<this.props.spaceDetails.timeslots.length;j++){
                            if(this.props.spaceDetails.timeslots[j].event_types.length===0){
                                errorList.push("Space Event Type");
                                error=true;
                                this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Spaces doesn't have minimum criteria."})            
                                break;

                            }

                        }
                        if(error===false){
                            this.setState({messageQus:'',buttonDisabledPublish:false,buttonDisabled:false})            

                        }
                   else{
                    this.setState({errorLists:errorList})
                   }
                    }
                         
                         
                 else{

                    this.setState({messageQus:'',buttonDisabledPublish:false,buttonDisabled:false})            
            
                    }
                        
    
                   
                  
                        
                    
                }
                else{

                    this.setState({approved:this.props.venueDetails.approved,published:this.props.venueDetails.published})
                   // let msg="";
                    if(this.props.venueDetails.description===''|| this.props.venueDetails.description===null){
                        errorList.push("Description");
                        this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Venue description must not be empty."})            
                    

                    }
                    if(this.props.venueDetails.profilePhoto===null)
                        {
                            errorList.push("Photo");

                            this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Profile photo must not be empty."})
                   

                        }
                    if(this.props.venueDetails.spaces.length===0){
                        errorList.push("Venue should have atleast on space.");

                        this.setState({errorLists:errorList,buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Venue should have atleast one space."})
                    }
                    else if(this.props.venueDetails.spaces.length > 0){
                        for(let i=0;i<this.props.venueDetails.spaces.length;i++){

                                if(this.props.venueDetails.spaces[i].arrangements!==0
                                || this.props.venueDetails.spaces[i].timeslots.length!==0){
                                    time_token=false;
                                    break;
        
                                }
                                else
                                {
                                    time_token=true;

                                }


                             if(this.props.venueDetails.spaces[i].timeslots.length!==0){

                                    for(let j=0;j<this.props.venueDetails.spaces[i].timeslots.length;j++){

                                        if(this.props.venueDetails.spaces[i].timeslots[j].event_types.length!==0){
                                            token=false;
                                            break;
                                        }
                                        else{                                           
                                            token=true;
                                    }
                                   
                                    }
                                }
                        }
                        // for(let k=0;k<this.props.venueDetails.spaces.length;k++){

                        //     if(this.props.venueDetails.spaces[k].photo.length>2){
                        //         image=true;
                        //         break;
                        //     }
                        //     else{
                        //         image=false;

                        //     }
                        
                        // }
                        for(let k=0;k<this.props.venueDetails.spaces.length;k++){

                            if(this.props.venueDetails.spaces[k].photo_count>2){
                                image=true;
                                break;
                            }
                            else{
                                image=false;

                            }
                        
                        }

                        if(time_token===false && token ===false && image===true){
                            this.setState({buttonDisabledPublish:false,buttonDisabled:false})            

                        }
                        else{ 
                                if(token===true){
                                    errorList.push("Space Event Type");

                                }
                                 if(image===false){
                                    errorList.push("Space Photos");

                                }
                                if(time_token===true){
                                    errorList.push("Space Timeslots/Arrangements");

                                }
                                this.setState({errorLists:errorList,buttonDisabledPublish:true,buttonDisabled:true,messageQus:error})            

                            
                        }
                    }
                    else{
                           this.setState({messageQus:'',buttonDisabledPublish:false,buttonDisabled:false})            
                       }
                   
                  
                        
                    
                }
        }
        else{
            this.setState({approved:this.props.venueDetails.approved,published:this.props.venueDetails.published})
              //let msg="";
              if(this.props.venueDetails.description===''|| this.props.venueDetails.description===null){
                  errorList.push("Description");
                  tokenVenue=true;

                  this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Venue description must not be empty."})            
               }
                  if(this.props.venueDetails.profilePhoto===null)
                  {
                      tokenVenue=true;
                    errorList.push("Photo");
                      this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Profile photo must not be empty."})
                  }
                  if(this.props.venueDetails.attractions===null){
                    errorList.push("Top 5 List");
                    tokenVenue=true;

                    this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"5 points"})
                  }
                  if(this.props.venueDetails.features.length===0){
                    errorList.push("Features");
                    tokenVenue=true;

                    this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Features"})
                  }
        
                if(this.props.venueDetails.spaces.length===0){
                    errorList.push("Venue should have atleast one space.");
                    tokenVenue=true;

                  this.setState({errorLists:errorList,buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Venue should have atleast one space."})

                 }
                else if(this.props.venueDetails.spaces.length > 0 && tokenVenue==false){
                    for(let i=0;i<this.props.venueDetails.spaces.length;i++){
                        if(this.props.venueDetails.spaces[i].arrangements===0
                        || this.props.venueDetails.spaces[i].timeslots.length===0){
                            errorList.push("Space Timeslots/Arrangements");
                            token=true;
                            break;
                
    
    
                        }
                        
                        else if(this.props.venueDetails.spaces[i].timeslots.length!==0){
                            for(let j=0;j<this.props.venueDetails.spaces[i].timeslots.length;j++){
                                if(this.props.venueDetails.spaces[i].timeslots[j].event_types.length===0){
                                    token=true;
                                    errorList.push("Space Event Type");
                                    break;
                                }
                           
                            }
                        }
                }
                //   for(let k=0;k<this.props.venueDetails.spaces.length;k++){
                //       if(this.props.venueDetails.spaces[k].photo.length < 3){
                //           image=false;
                //           errorList.push("Space Photos");
                //           break;
                //       }
                //       else{
                //           image=true;
                //       }

                //   }
                for(let k=0;k<this.props.venueDetails.spaces.length;k++){
                    if(this.props.venueDetails.spaces[k].photo_count < 3){
                        image=false;
                        errorList.push("Space Photos");
                        break;
                    }
                    else{
                        image=true;
                    }

                }
                  for(let k=0;k<this.props.venueDetails.spaces.length;k++){
                    if(this.props.venueDetails.spaces[k].description === ''){
                        token=true;
                        errorList.push("Space Description");
                        break;
                    }

                }
                  if(token===false && image===true){
                      this.setState({buttonDisabledPublish:false,buttonDisabled:false})            

                  }
                  else{ 
 
                          this.setState({errorLists:errorList,buttonDisabledPublish:true,buttonDisabled:true,messageQus:error})            

                  }

                 }
          
                 this.setState({errorLists:errorList})            

              
          }

        this.props.getPlans()
        setTimeout(() => {
            this.setState({buttonColor:true})
        }, 3000); 
    }
  
    UNSAFE_componentWillReceiveProps(nextProps){
        let token=false;
        let error=false;
        let time_token=false;
        let image=true;
        let errorList=[];
        let planExists = false
        let tokenVenue=false;
        let plan=[]
        if(this.props.planList !== nextProps.planList && nextProps.planList.length > 0){
            if(this.props.match.params.spaceId)
            {
            plan = nextProps.planList.find(p => p.venue_id === parseInt(this.props.venueId))
            }
            if(this.props.match.params.venueId)
            {
            plan = nextProps.planList.find(p => p.venue_id === parseInt(this.props.match.params.venueId))
            }
            if(plan !== undefined&&planExists===false)
            {
            const currentDate = new Date(); 
            const oneDay = 24 * 60 * 60 * 1000; 
            const tierExpiredAt= plan.tier_expired_at; 
            // const trialTierExpiredAt= plan.trail_tier_expired_at;
            const tierExpiredDate = tierExpiredAt !== null ? new Date(tierExpiredAt) : '';
            const tierExpiredDiff = currentDate <= tierExpiredDate && plan.tier_expired_at !== null ? Math.round(Math.abs((currentDate - tierExpiredDate) / oneDay)) : '';
            if(tierExpiredDiff !== 0 && planExists === false){
            if(plan.enabled===1){
            planExists = true
            localStorage.setItem('plan_id',plan.id) 
            localStorage.setItem('venue_plan_id',plan.id) 
            }
            }
            else{
            localStorage.removeItem('venue_plan_id')
            localStorage.removeItem('plan_id')
            } 
            }
            // nextProps.planList.map((plan,index)=>{
            // const currentDate = new Date(); 
            // const oneDay = 24 * 60 * 60 * 1000; 
            // const tierExpiredAt= plan.tier_expired_at; 
            // // const trialTierExpiredAt= plan.trail_tier_expired_at;
            // const tierExpiredDate = tierExpiredAt !== null ? new Date(tierExpiredAt) : '';
            // const tierExpiredDiff = currentDate <= tierExpiredDate && plan.tier_expired_at !== null ? Math.round(Math.abs((currentDate - tierExpiredDate) / oneDay)) : '';
            // if(tierExpiredDiff !== 0 && planExists === false){
            // alert(tierExpiredDiff)
            // planExists = true
            // localStorage.setItem('plan_id',plan.id) 
            // }
            // else{
            // localStorage.removeItem('venue_plan_id')
            // localStorage.removeItem('plan_id')
            // }
            // })
            // if(this.props.match.params.spaceId&&planExists)
            // {
            // plan = nextProps.planList.find(p => p.venue_id === parseInt(this.props.venueId))
            // }
            // if(this.props.match.params.venueId)
            // {
            // plan = nextProps.planList.find(p => p.venue_id === parseInt(this.props.match.params.venueId))
            // }
            // if(plan !== undefined&&planExists===true){
            // alert(",m")
            // localStorage.setItem('venue_plan_id',plan.id)
            // }
            else
            {
            nextProps.planList.map((plan,index)=>{
            const currentDate = new Date(); 
            const oneDay = 24 * 60 * 60 * 1000; 
            const trialTierExpiredAt= plan.trail_tier_expired_at;
            const trialTierExpiredDate = trialTierExpiredAt ? new Date(trialTierExpiredAt) : '';
            const trialTierExpiredDiff = currentDate <= trialTierExpiredDate && trialTierExpiredAt ? Math.round(Math.abs((currentDate - trialTierExpiredDate) / oneDay)) : 0;
            if(trialTierExpiredDiff !== 0 && planExists === false){
            if(plan.enabled===1){
            planExists = true
            localStorage.setItem('plan_id',plan.id) 
            }
            }
            return true;
            })
            if(plan === undefined && planExists === false){
            localStorage.removeItem('venue_plan_id')
            localStorage.removeItem('plan_id')
            }
            }
            }
        if(this.state.approved!==-1){
            if(this.props.name==='spaceEdit'){

                 this.setState({published:nextProps.spaceDetails.published})

                    //let msg="";
                    if((nextProps.spaceDetails.description===''||
                    nextProps.spaceDetails.description===null)

                     || nextProps.spaceDetails.photo_count < 3 ||
                     nextProps.spaceDetails.arrangements.length === 0
                     || nextProps.spaceDetails.timeslots.length===0)
                     {
                        if(nextProps.spaceDetails.description===''||
                        nextProps.spaceDetails.description===null)
                        {
                            errorList.push("Space Description");
                        }
                        if(nextProps.spaceDetails.photo_count < 3){
                            errorList.push("Space Photos");

                        }
                        if(nextProps.spaceDetails.arrangements.length === 0){
                            errorList.push("Space Arrangements");
                        }
                        if(nextProps.spaceDetails.timeslots.length===0){
                            errorList.push("Space Timeslot");

                        }
                        this.setState({errorLists:errorList})

                        this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Spaces doesn't have minimum criteria."})            
                     }
                    else if(nextProps.spaceDetails.timeslots.length!==0){
                        for(let j=0;j<nextProps.spaceDetails.timeslots.length;j++){
                            if(nextProps.spaceDetails.timeslots[j].event_types.length===0){
                                errorList.push("Space Event Type");
                                error=true;
                                this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Spaces doesn't have minimum criteria."})            
                                break;

                            }

                        }
                        if(error===false){
                            this.setState({messageQus:'',buttonDisabledPublish:false,buttonDisabled:false})            

                        }
                   else{
                    this.setState({errorLists:errorList})
                   }
                    }
                         
                         
                 else{

                    this.setState({messageQus:'',buttonDisabledPublish:false,buttonDisabled:false})            
            
                    }
                        
    
                   
                  
                        
                    
                }
                else{

                  this.setState({approved:nextProps.venueDetails.approved,published:nextProps.venueDetails.published})
                    //let msg="";
                    if(nextProps.venueDetails.description===''|| nextProps.venueDetails.description===null){
                        errorList.push("Description");
                        this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Venue description must not be empty."})            
                    

                    }
                    if(nextProps.venueDetails.profilePhoto===null)
                        {
                            errorList.push("Photo");

                            this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Profile photo must not be empty."})
                   

                        }
                    if(nextProps.venueDetails.spaces.length===0){
                        errorList.push("Venue should have atleast on space.");

                        this.setState({errorLists:errorList,buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Venue should have atleast one space."})
                    }
                    else if(nextProps.venueDetails.spaces.length > 0){
                        for(let i=0;i<nextProps.venueDetails.spaces.length;i++){

                                if(nextProps.venueDetails.spaces[i].arrangements!==0
                                || nextProps.venueDetails.spaces[i].timeslots.length!==0){
                                    time_token=false;
                                    break;
        
                                }
                                else
                                {
                                    time_token=true;

                                }


                             if(nextProps.venueDetails.spaces[i].timeslots.length!==0){

                                    for(let j=0;j<nextProps.venueDetails.spaces[i].timeslots.length;j++){

                                        if(nextProps.venueDetails.spaces[i].timeslots[j].event_types.length!==0){
                                            token=false;
                                            break;
                                        }
                                        else{                                           
                                            token=true;
                                    }
                                   
                                    }
                                }
                        }
                        // for(let k=0;k<nextProps.venueDetails.spaces.length;k++){

                        //     if(nextProps.venueDetails.spaces[k].photo.length>2){
                        //         image=true;
                        //         break;
                        //     }
                        //     else{
                        //         image=false;

                        //     }
                        
                        // }
                        for(let k=0;k<nextProps.venueDetails.spaces.length;k++){

                            if(nextProps.venueDetails.spaces[k].photo_count>2){
                                image=true;
                                break;
                            }
                            else{
                                image=false;

                            }
                        
                        }

                        if(time_token===false && token ===false && image===true){
                            this.setState({buttonDisabledPublish:false,buttonDisabled:false})            

                        }
                        else{ 
                                if(token===true){
                                    errorList.push("Space Event Type");

                                }
                                 if(image===false){
                                    errorList.push("Space Photos");

                                }
                                if(time_token===true){
                                    errorList.push("Space Timeslots/Arrangements");

                                }
                                this.setState({errorLists:errorList,buttonDisabledPublish:true,buttonDisabled:true,messageQus:error})            

                            
                        }
                    }
                    else{

                           this.setState({messageQus:'',buttonDisabledPublish:false,buttonDisabled:false})            
                       }
                   
                  
                        
                    
                }
        }
        else{
            this.setState({approved:nextProps.venueDetails.approved,published:nextProps.venueDetails.published})
              //let msg="";
              if(nextProps.venueDetails.description===''|| nextProps.venueDetails.description===null){
                  errorList.push("Description");
                  tokenVenue=true;

                  this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Venue description must not be empty."})            
               }
                  if(nextProps.venueDetails.profilePhoto===null)
                  {
                    tokenVenue=true;

                    errorList.push("Photo");
                      this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Profile photo must not be empty."})
                  }
                  if(nextProps.venueDetails.attractions===null){
                    errorList.push("Top 5 List");
                    tokenVenue=true;

                    this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"5 points"})
                  }
                  if(nextProps.venueDetails.features.length===0){
                    errorList.push("Features");
                    tokenVenue=true;

                    this.setState({buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Features"})
                  }
            
                
                if(nextProps.venueDetails.spaces.length===0){
                    tokenVenue=true;

                    errorList.push("Venue should have atleast one space.");

                  this.setState({errorLists:errorList,buttonDisabledPublish:true,buttonDisabled:true,messageQus:"Venue should have atleast one space."})

                 }
                else if(nextProps.venueDetails.spaces.length > 0  && tokenVenue==false){
                    for(let i=0;i<nextProps.venueDetails.spaces.length;i++){
                        if(nextProps.venueDetails.spaces[i].arrangements===0
                        || nextProps.venueDetails.spaces[i].timeslots.length===0){
                            errorList.push("Space Timeslots/Arrangements");
                            token=true;
                            break;
                
    
    
                        }
                        
                        else if(nextProps.venueDetails.spaces[i].timeslots.length!==0){
                            for(let j=0;j<nextProps.venueDetails.spaces[i].timeslots.length;j++){
                                if(nextProps.venueDetails.spaces[i].timeslots[j].event_types.length===0){
                                    token=true;
                                    errorList.push("Space Event Type");
                                    break;
                                }
                           
                            }
                        }
                }
                //   for(let k=0;k<nextProps.venueDetails.spaces.length;k++){
                //       if(nextProps.venueDetails.spaces[k].photo.length < 3){
                //           image=false;
                //           errorList.push("Space Photos");
                //           break;
                //       }
                //       else{
                //           image=true;
                //       }

                //   }
                for(let k=0;k<nextProps.venueDetails.spaces.length;k++){
                    if(nextProps.venueDetails.spaces[k].photo_count < 3){
                        image=false;
                        errorList.push("Space Photos");
                        break;
                    }
                    else{
                        image=true;
                    }

                }
                  for(let k=0;k<this.props.venueDetails.spaces.length;k++){
                    if(this.props.venueDetails.spaces[k].description === null){
                        token=true;
                        errorList.push("Space Description");
                        break;
                    }

                }
                  if(token===false && image===true){
                      this.setState({buttonDisabledPublish:false,buttonDisabled:false})            

                  }
                  else{ 
                            
                          this.setState({errorLists:errorList,buttonDisabledPublish:true,buttonDisabled:true,messageQus:error})            

                  }
                 }
          
                 this.setState({errorLists:errorList})            
     
              
          }
    }
errorClose=()=>{
    this.setState({errorOpen:false})
}
errorOpenFun=(list)=>{
    this.setState({errorOpen:true,errorLists:list})

}
    handleFinishClick(disable) {
        let json={};
        if(this.state.finish_submit=='Finish & Submit'){
            this.setState({saveExitToken:'saveExit'})
        }
        if(disable==='disable')
            {
                this.setState({errorOpen:true})
            }
        else{
        if(this.state.approved===-1 || this.state.approved===2)
        {
                json={
                    "approved":0
                }
                this.setState({approved:0})
                if(this.props.name!=='spaceEdit'){
                    this.setState({venueApproved:0})
                }

        }
        else if(this.state.approved===1 && this.state.published===0)
        {
            json={
                "published":1
            }
            this.setState({published:1})
            if(this.props.name!=='spaceEdit'){
                this.setState({venuePublished:1})
            }


        }
        else if(this.state.approved===1 && this.state.published===1)
        {
            json={
                "published":0
            }
            this.setState({published:0})
            if(this.props.name!=='spaceEdit'){
                this.setState({venuePublished:0})
            }

        }
        if(this.props.name!=='spaceEdit')
        {
            let venue=this.props.venueDetails.spaces;
            for(let i=0;i<venue.length;i++){
                let token=false;
            
                if(venue[i].photo_count>2
                && venue[i].timeslots.length>0
                && venue[i].arrangements>0){
                    for(let j=0;j<venue[i].timeslots.length;j++){
                            if(venue[i].timeslots[j].event_types.length>0){
                                token=true;                                
                            }
                        }
                        if(token===true)
                        {
                            this.props.updateSpace(venue[i].id,{
                                "published":1
                            },'spaceEditpub').then(() => {
                            })

                            this.props.publishUnpublish(this.props.venueId,json);

                        }
                }
            }
        }
        else{
            let p_token=false;
            if(this.state.venuePublished===1 && json.published===0){
                for(let i=0;i<this.props.venueDetails.spaces.length;i++){
                    if(parseInt(this.props.spaceId) !== parseInt(this.props.venueDetails.spaces[i].id) && this.props.venueDetails.spaces[i].published===1){
                       
                        p_token=true;
                        break;
                    }
                    }
                    if(p_token===false)
                    {

                        this.setState({published:1,unPublishPopup:true,space_json:json})

              
                       

                    }
                    else{
                        this.props.publishUnpublish(this.props.spaceId,json);

                    }
            }
            else{
                this.props.publishUnpublish(this.props.spaceId,json);

            }
                  
              
    
          
        }
    }
    }


    openPopupPublish=()=>{
        this.setState({publishPopup:true});
    }

    publishClose=()=>{
        this.setState({publishPopup:false})
    }
    openUnPopupPublish=()=>{
        this.setState({unPublishPopup:true});
    }

    unPublishClose=()=>{
        this.setState({unPublishPopup:false})
    }
    yes_unpublish=()=>{
      let v_json={published:0}
           this.setState({unPublishPopup:false})
        //    this.setState({venuePublished:0,unPublishPopup:true,published:0})
        //      this.props.saveExit(this.props.venueId,v_json).then(() => {
        //          })
             this.props.publishUnpublish(this.props.spaceId,this.state.space_json);
                this.setState({unPublishPopup:false,space_json:''})

    }
 

    openPopupFinish=()=>{
        this.setState({finishPopup:true});
    }
      
finishClose=()=>{
    this.setState({finishPopup:false})
}
    handleHelpIconClick = (event) => {
        this.setState({
          anchorEl: event.currentTarget,
          isToggleOpen: true
        })
      }
      handleCloseHelpIcon = () => {
        this.setState({
          anchorEl: null,
          isToggleOpen: false
        });
      }

    render() {

        //const { classes } = this.props;
        //const open = Boolean(this.state.anchorEl);

        return (
            <Grid container className="buttons-header">

                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                {this.props.name === 'spaceEdit' ?
             <span className="title" style={{width:'50% !important'}}>{this.props.spaceDetails.name}</span>
        
             :
             <span className="title" style={{width:'50% !important'}}>{this.props.venueDetails.name}</span>

    
            }
            
            {this.state.errorOpen===true?
              <ErrorList saveExit={this.state.saveExitToken} errorLists={this.state.errorLists} errorClose={this.errorClose} openError={this.state.errorOpen} venueId={this.props.venueId} name={this.props.name}/>
              :null}
          {this.state.finishPopup===true?
              <FinishPopup handleFinishClick={this.handleFinishClick} finishClose={this.finishClose} openFinish={this.state.finishPopup}/>
              :null}
                    {this.state.publishPopup===true?
              <PublishPopup handleFinishClick={this.handleFinishClick} publishClose={this.publishClose} openPublish={this.state.publishPopup}/>
              :null}
            {this.state.unPublishPopup===true?
              <UnPublishPopup yes_unpublish={this.yes_unpublish} handleFinishClick={this.handleFinishClick} publishClose={this.unPublishClose} openPublish={this.state.unPublishPopup}/>
              :null}
                    
                    <div className="buttons-group">
                        <span className="small-grey-text">

                        
                    {
                         this.state.approved === 2
                         ?
                         <p style={{display:'inline-block',color:'red'}}>Rejected</p>

                         :
                         this.state.venuePublished === 1 && this.state.approved===1 ?
                           this.state.online :
                             this.state.approved===1 ?
                             this.state.offline :
                            this.state.approved===-1 || this.state.approved===0 ?
                          this.state.message : null }
                      </span>
                       
                        <Button id="saveExit" disabled={this.state.saveDisable} class="red-button btn-left" 
                        onClick={()=>this.props.handleSave(this.errorOpenFun)}>
                        Save & Exit</Button>
                        {this.state.approved===0 && this.props.name!=='spaceEdit' ?
                        <p style={{display:'inline-block'}}>Waiting for approval</p> 
                        : 
                        // this.state.approved == 2 && this.props.name!=='spaceEdit' ?
                        //  <p style={{display:'inline-block',color:'red'}}>Rejected</p>
                        // :
                        (this.state.approved===-1 || this.state.approved===2) && this.props.name!=='spaceEdit' 
                        ?
                        this.state.buttonDisabled === false ?
                        <Button class='red-button btn-publish' 
                        onClick={this.openPopupFinish}>
                        {this.state.finish_submit}
                        </Button>
                          :
                        <Grid className="btn-right" style={{}}>
                          <Button class='grey-button' 
                          onClick={()=>this.handleFinishClick("disable")}>
                        {this.state.finish_submit}
                          </Button>
                        </Grid>
                        : 
                        this.state.buttonColor===true?
                        (this.state.approved===1 && this.state.published===0) || ((localStorage.getItem('plan_id') === null && localStorage.getItem('venue_plan_id') === null))
                        ?
                        (this.state.buttonDisabledPublish || ((localStorage.getItem('plan_id') === null && localStorage.getItem('venue_plan_id') === null)))?

                        this.props.name!=='spaceEdit'?
                        <Grid className="btn-right" style={{}}>
                        <Button class='grey-button' 
                        onClick={()=>this.handleFinishClick('disable')}>
                        {this.state.publish}
                          </Button>
                      </Grid>                        
                      :
                      this.state.venuePublished===1?

                      <Grid className="btn-right" style={{}}>
                      <Button class='grey-button' 
                      onClick={()=>this.handleFinishClick('disable')}>
                      {this.state.publish}
                        </Button>
                    </Grid>     
                    :null                   
                      :
                      this.props.name!=='spaceEdit'?

                        <Button class='red-button btn-right btn-publish'
                        onClick={this.props.name!=='spaceEdit'?this.openPopupPublish:this.handleFinishClick}>

                        {this.state.publish}
                        </Button>
                        :
                        this.state.venuePublished===1?
                        <VenuePublishPopup syncCalendar={this.props.syncCalendar} handlePublish={this.handleFinishClick}/>

                        :
                        null
                        :
                       this.state.approved===1 && this.state.published===1 && this.state.venuePublished===1
                       ?
                
                       <Button class='red-button'
                       onClick={this.handleFinishClick}>
                       {this.state.unpublish}
                        </Button> : 
                            null 
                            :null 
                            
                    }
                        <p style={{color:'red',fontSize:'10px'}}>{this.state.errorMsg}</p>
                        </div>
                </Grid>
            </Grid>

        );
    }
}

ButtonsHeader.propTypes = {
    classes: object.isRequired,
    updateSpace:func.isRequired,
    saveExit: func.isRequired


  };
  const mapStateToProps = state => {
    return {
        featureData: state.data.featureTypes.featureList,
        planList : state.data.plan.planList
    };
  };
  
  const enhance = compose(
    withStyles(styles),
    withRouter,
    connect(mapStateToProps,dispatch =>
      bindActionCreators(
        {
            updateSpace,
            getPlans,
            saveExit
        },
        dispatch
      )
    )
  );
  export default enhance(ButtonsHeader);

import React from 'react';
import { object } from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    // width: '121px',
    // height: '36px',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    borderRadius: '6px',
    background: 'linear-gradient(to bottom, #f1435e, #ee2738)',
    fontFamily: 'Raleway',
    padding: '0px',

    '&$disabled': {
      background: '#9aa7af',
      color: 'white',
      boxShadow: 'none',
    },
    '&:hover': {
      background: "#d80e2a"
    }
  },
  disabled: {
  },
});


function CustomizedButton(props) {
  const { classes } = props;

  return (
    <Button 
    id={props.id?props.id:''}
    style={{marginTop:props.marginTop?props.marginTop:null,marginBottom:props.marginBottom?props.marginBottom:null, display: props.visibility==='hide'?'none':'',height:props.height ? props.height :'36px', width:props.width ? props.width : '152px'}} 
    type={props.type}
      onClick={props.onClickEvent}
      disabled={props.disabled?props.disabled:null}
      classes={{
        root: classes.root,
        disabled: classes.disabled,
      }}
    >
      {props.label}
    </Button>
  );
}

CustomizedButton.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(CustomizedButton);

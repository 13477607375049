import { DELETE_SPACE_IMAGE } from 'shared/state/types';
import { request } from 'shared/auth';

function deleteSpaceImageRequested() {
  return {
    type: DELETE_SPACE_IMAGE.REQUEST,
  };
}

function deleteSpaceImageFailed(error) {
  return {
    type: DELETE_SPACE_IMAGE.FAILURE,
    error:error,
  };
}

function deleteSpaceImageSuccess() {
  return {
    type: DELETE_SPACE_IMAGE.SUCCESS,
  };
}

export function deleteSpaceImage(spaceId,photoId) {
  return dispatch => {
    dispatch(deleteSpaceImageRequested());

    return request().delete(`spaces/${spaceId}/photos/${photoId}`)
      .then(response =>{
        if(response.status === 204){
          dispatch(deleteSpaceImageSuccess())
          return response
        }
        else{
          dispatch(deleteSpaceImageFailed())
        }
      }
      )
      .catch(errors => {
        dispatch(deleteSpaceImageFailed());
        throw errors;
      });
  };
}

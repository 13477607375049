import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid, Select, MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { countryCode } from '../../../../constant';
import OtpInput from 'react-otp-input';
import MaskedInput from 'react-text-mask';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';;

const LoginSchema = Yup
    .object()
    .shape({
        mobile_no: Yup
            .string()
            .required('*Mobile No. is required'),
        ext: Yup
            .string()
            .required('*Country code is required')
    });

function MobileLogin(props) {
    const { classes } = props;
    return (
        <div>
            {props.step === 1 && <Grid container md={12} lg={12} xl={12} className="login-container">
                <Formik
                    initialValues={props.initialValues}
                    validationSchema={LoginSchema}
                    onSubmit={props.onLogin}>
                    {({ isSubmitting, values, setFieldValue, handleChange }) => (
                        <Form >
                            <Typography gutterBottom className="heading-1">
                                {props.venueName} uses FIRST-HOLD’s venue availability platform to securely manage your account
                                information.
                            </Typography>
                            <Typography gutterBottom className="heading-2">
                                Please enter your mobile phone number to verify or create an account:
                            </Typography>
                            <Grid container md={12} xs={12} sm={12} lg={12} xl={12}>
                                <Grid md={12} xs={12} sm={12} lg={12} xl={12}>
                                    <InputLabel>Mobile No. *</InputLabel>
                                </Grid>
                                <Grid md={2} xs={2} sm={2} lg={2} xl={2} className="select-box">
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        className="phone-ext"
                                        onChange={handleChange}
                                        name="ext"
                                        value={values.ext}>
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {countryCode.map(res => {
                                            return <MenuItem value={res.code}>{res.name}</MenuItem>
                                        })
                                        }
                                    </Select>
                                    <div className="error"><ErrorMessage name="ext" /></div>
                                </Grid>
                                <Grid md={10} xs={10} sm={10} xl={10} lg={10} >
                                    <FormControl fullWidth>
                                        <Field
                                            className="inputField"
                                            align="center"
                                            name="mobile_no"
                                            render={({ field }) => (<MaskedInput
                                                className="inputField"
                                                {...field}
                                                mask={[
                                                    '(',
                                                    /[1-9]/,
                                                    /\d/,
                                                    /\d/,
                                                    ')',
                                                    ' ',
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    '-',
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/
                                                ]}
                                                id="phone"
                                                placeholder="(XXX)-XXX-XXXX"
                                                type="text" />)} />
                                        <div className="error"><ErrorMessage name="mobile_no" /></div>
                                    </FormControl>
                                </Grid>
                                <Grid md={12} xs={12} sm={12} lg={12} xl={12} className="button-box">
                                    <FormControl fullWidth>
                                        <Button variant="contained" type="submit" className="sign-btn" color="primary">
                                            Continue
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Typography gutterBottom className="bottom-heading">
                                {props.venueName} and FIRST-HOLD will use this number to confirm and manage your inquiry.
                            </Typography>
                            <Typography gutterBottom className="bottom-link">
                                <Link onClick={() => props.onChangeView('emailLogin')}>Use Email Instead</Link>
                            </Typography>
                        </Form>
                    )}
                </Formik>
            </Grid>
            }

            {props.step === 2 && <Grid container md={12} lg={12} xl={12} className="otp-container">
                <Typography gutterBottom className="heading-2">
                    Check your mobile phone!
                </Typography>
                <Typography gutterBottom className="heading-1">
                    We sent a 6-digit confirmation code to {props.initialValues.mobile_no}. It expires shortly, so enter it soon.
                </Typography>
                <Grid item md={12} lg={12} xl={12}>
                    <OtpInput
                        value={props.otp}
                        onChange={(e) => props.handleChange(e)}
                        numInputs={6}
                        isDisabled={props.otp && props.otp.length == 6
                            ? true
                            : false}
                        containerStyle="otp-input-box"
                        inputStyle="otp-input" />
                </Grid>
                <Typography gutterBottom className="bottom-link">
                    <Link onClick={() => props.onHelp()}>Need Help?</Link>
                </Typography>
            </Grid>
            }

            {props.step === 3 && <Grid container md={12} lg={12} xl={12} className="help-container">
                <Typography gutterBottom className="heading-2">
                    How can we help?
                </Typography>
                <Typography gutterBottom className="bottom-link">
                    <Link onClick={() => props.onResendOTP(1)}>Resend Code</Link>
                </Typography>
                <Typography gutterBottom className="bottom-link">
                    <Link onClick={() => props.onChangeView('emailLogin')}>Sign in with Email & Password</Link>
                </Typography>
                <Typography gutterBottom className="bottom-link">
                    <Link onClick={() => props.onChangeView('emailSingup')}>Create account with Email Instead</Link>
                </Typography>
            </Grid>
            }
        </div>
    );
}

MobileLogin.propTypes = {
    classes: PropTypes.object.isRequired
}

export default (MobileLogin);
import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {Grid,Button,Checkbox,FormControlLabel } from '@material-ui/core';
import { createPlan, updatePlan, stripeApi } from 'data/plan/actions';
import { userDetails } from "shared/venueList/actions";
import '../assets/styles/paymentconfirmation.scss';
import CouponDialogs from './couponPopup';

import {CardElement, ElementsConsumer} from '@stripe/react-stripe-js';
// import {
//     Select,
//     MenuItem
//   } from '@material-ui/core';

import '../assets/styles/payment.scss';

class CheckoutForm extends React.Component { 

     handleAgreeCheck=(e)=>{
        this.setState({paymentAgreeCheck:!this.state.paymentAgreeCheck})
       }
    state = {
        paymentAgreeCheck:false
      };
  render() {
    const {stripe, stateValue} = this.props;
    const disableButton=parseInt(stateValue.planTier) === 1?this.state.paymentAgreeCheck:true
    return (
    <div className="payment">
        <div className="payment-heading">Payment Information</div>
      <form onSubmit={(event)=>{this.props.handleSubmit(event,this.props.stripe,this.props.elements,this.state.paymentAgreeCheck)}}>
      <div className="credit-card">
            <span className="circle">
                <em className="small-circle"></em>
            </span>
            <p>Credit Card</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg>
      </div>
      <CouponDialogs planTier={(stateValue.planTier)} appliedCoupon={stateValue.appliedCoupon} applyCoupon={this.props.applyCoupon} removeCoupon={this.props.removeCoupon}  />    
      <div className="tier-info">
                {parseInt(stateValue.planTier) === 2 ?
                    <div className="plan-information">
                    You have selected Tier Two, our <span>Annual Plan</span>.  Your credit card will be charged $1,188 per year
                              for this plan.<br/> <span>$600 savings with annual plan!</span>
                    </div>
                :parseInt(stateValue.planTier) === 1 ?
                <div className="plan-information">
                    You have selected Tier One, our <span>Monthly Plan</span>.  Your credit card will be charged $149 per month 
                    for this plan.

                </div>
                : <div className="plan-information">
                    You have selected Tier Three, our <span>Two-Year Plan</span> it card will be charged $2,136 every two years for this plan. <br/> <span>$1440 savings with two-year plan!</span>
                </div>
            }
            <div class="checkbox-text-alignment">
            <br/>
                <FormControlLabel
                    className="checkboxLabel"
                    control={
                        <Checkbox
                            value={this.state.paymentAgreeCheck}
                            onChange={(event) => this.handleAgreeCheck(event)}
                            checked={this.state.paymentAgreeCheck === true ? true : false}
                            // required={parseInt(stateValue.planTier) === 1?true:false}
                        />
                    }
                    label={
                        <span className="text-color">
                            I understand that I’m agreeing to a subscription. It will renew at the price and frequency listed until it is <span style={{ color: 'red' }}>canceled</span>.
                        </span>
                    }
                />
                {/* {
                 parseInt(stateValue.planTier) === 1&&this.state.paymentAgreeCheck===false&& 
                <div>*Required</div>
                } */}
            </div>
        </div>
        {
        (Object.keys(stateValue.appliedCoupon).length > 0) && 
        <div className="coupon-applied">
        {
         <p dangerouslySetInnerHTML={{__html: stateValue.appliedCoupon.message}}></p>
        }
        </div>
      } 
        <div className="inputlabel">
          <label for='name'>Card Holder Name</label>
            <input type='text' name='name' placeholder="Card Holder Name"></input>
        </div>
        <CardElement />
        <div className="payment-process-text">
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>
             <p>Secure payment processed by <span>stripe</span></p>
        </div>
      <div className="pay-btn">
      <button className="pay-now" type="submit" disabled={!(stripe&&disableButton)}>
          Pay Now
        </button>
        <button className="cancel" type="submit">
          <a href="/myVenue">Cancel</a>
        </button>
      </div>
      </form>
    </div>
    );
  }
}

class Payment extends React.Component {
    state = {
        planType: localStorage.getItem('plan_type'),
        planId: localStorage.getItem('plan_id'),
        planTier: localStorage.getItem('plan_tier'),
        venueId: localStorage.getItem('venue_id'),
        paymentDone: false,
        stripeData: null,
        appliedCoupon:{},
        planData: null
    }
  
    removeCoupon = ()=>{
        this.setState({appliedCoupon:{}});
    }

    applyCoupon = (couponDetail) =>{
        this.setState({appliedCoupon:couponDetail});
    }
    handleSubmit = async (event, stripe, elements,paymentAgreeCheck) => {
    event.preventDefault();
    // const {stripe, elements} = this.props;
    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    // type: 'card',
    // card: elements.getElement(CardElement),
    // });
    //var card = elements.create('card');
    var thisElm = this;
    stripe.createToken(elements.getElement(CardElement)).then(function(result) {
        if (result.error) {
            // Inform the customer that there was an error.
             this.props.stripeApi(result.error.message)
            
        } else {
            // Send the token to your server.
            const token = {
                tokenId: result,
                cardId:result
            }
            const tokenJson= JSON.stringify(token)
            let formData = ''
            // if (this.state.planType === 'trial') {
            //     if(this.state.venueId && !this.state.planId){
            //         formData = `days=30&trail_tier=${this.state.planTier}&venue_id=${this.state.venueId}`
            //     }
            //     else{
            //         formData = `days=30&trail_tier=${this.state.planTier}`
            //     }
                
            // }
            // else{
            let days = parseInt(thisElm.state.planTier) === 1 ? 30 : parseInt(thisElm.state.planTier) === 2 ? 365 : 730
            if(thisElm.state.venueId){
                formData = `days=${days}&tier=${thisElm.state.planTier}&venue_id=${thisElm.state.venueId}&token=${tokenJson}&paymentAgreeCheck=${paymentAgreeCheck}`
                if(Object.keys(thisElm.state.appliedCoupon).length > 0 && 'id' in thisElm.state.appliedCoupon){
                    formData +=`&coupon_id=${thisElm.state.appliedCoupon.id}`  
                }
            }
            else{
                formData = `days=${days}&tier=${thisElm.state.planTier}&token=${tokenJson}`
                if(Object.keys(thisElm.state.appliedCoupon).length > 0 && 'id' in thisElm.state.appliedCoupon){
                    formData +=`&coupon_id=${thisElm.state.appliedCoupon.id}`  
                }
            }
            // }
            // if (this.state.planId) {
            //     this.props.updatePlan(this.state.planId, formData, "show").then((res) => {
            //         localStorage.removeItem('plan_id')
            //         localStorage.removeItem('plan_type')
            //         localStorage.removeItem('plan_tier')
            //         this.props.history.push('./myVenue')
            //     })
            // }
            // else {
                thisElm.props.createPlan(formData)
                    .then((res) => {
                        thisElm.props.userDetails()
                            .then((response) => {
                                thisElm.setState({paymentDone:true, stripeData:result, planData:res})
                                //thisElm.props.history.push('./myVenue')
                                if(thisElm.state.venueId){
                                    localStorage.removeItem('venue_id')
                                    localStorage.removeItem('plan_id')
                                    localStorage.removeItem('plan_type')
                                    localStorage.removeItem('plan_tier')
                                }
                            })
                    })
            // }
    
        }
    });
    
    // if(paymentMethod !== undefined){
    // const token = {
    //                 tokenId: paymentMethod,
    //                 cardId: paymentMethod.id
    //             }
    //             const tokenJson= JSON.stringify(token)
    //             let formData = ''
    //             // if (this.state.planType === 'trial') {
    //             //     if(this.state.venueId && !this.state.planId){
    //             //         formData = `days=30&trail_tier=${this.state.planTier}&venue_id=${this.state.venueId}`
    //             //     }
    //             //     else{
    //             //         formData = `days=30&trail_tier=${this.state.planTier}`
    //             //     }
                    
    //             // }
    //             // else{
    //             let days = parseInt(this.state.planTier) === 1 ? 30 : parseInt(this.state.planTier) === 2 ? 365 : 730
    //                 if(this.state.venueId){
    //                     formData = `days=${days}&tier=${this.state.planTier}&venue_id=${this.state.venueId}&token=${tokenJson}`
    //                 }
    //                 else{
    //                     formData = `days=${days}&tier=${this.state.planTier}&token=${tokenJson}`
    //                 }
    //             // }
    //             // if (this.state.planId) {
    //             //     this.props.updatePlan(this.state.planId, formData, "show").then((res) => {
    //             //         localStorage.removeItem('plan_id')
    //             //         localStorage.removeItem('plan_type')
    //             //         localStorage.removeItem('plan_tier')
    //             //         this.props.history.push('./myVenue')
    //             //     })
    //             // }
    //             // else {
    //                 this.props.createPlan(formData)
    //                     .then((res) => {
    //                         this.props.userDetails()
    //                             .then((response) => {
    //                                 this.props.history.push('./myVenue')
    //                                 if(this.state.venueId){
    //                                     localStorage.removeItem('venue_id')
    //                                     // localStorage.removeItem('plan_id')
    //                                     // localStorage.removeItem('plan_type')
    //                                     localStorage.removeItem('plan_tier')
    //                                 }
    //                             })
    //                     })
    //             // }
        
    // }
    // else{
    // if(error !== undefined){
    //     if(error.message){
    //         this.props.stripeApi(error.message)
    //     }
    // }
    // else{
    //     this.props.stripeApi("We were unable to process your credit card payment")
    // }
    // }
    };
    render() {
      return (
        <div>
            {this.state.paymentDone==true ? (
                <div className="venue_page paymentconfirmation">
                <Grid xs={12} item className="paymentimage" style={{ textAlign: 'center' }}>
                    <img src={require('../assets/images/LogoWithName.png')}
                        width='120' justify='center'
                        alt="Venue" />
                </Grid>
                <Grid>
                    <p className="title">Payment Confirmation</p>
                </Grid>
                <Grid xs={12} item className="midContainer-payment">
                    <Grid container className="right" justify="right">
                        <h3>We're stronger together</h3>
                        <p>Thank you.</p>
                    </Grid>
                    <Grid style={{ display: 'block' }}>
                        <div className="annual-left">
                        {parseInt(this.state.planTier) === 2 ?
                                <p>Annual Plan</p>
                            :parseInt(this.state.planTier) === 1 ?
                                <p>Monthly Plan</p>
                            : <p>Two-Year Plan</p>
                        }
                        </div>
                        <div className="annual-right">
                            {parseInt(this.state.planTier) === 2 ?
                                    <p>total <span>${this.state.planData.amount}</span></p>
                                :parseInt(this.state.planTier) === 1 ?
                                    <p>total <span>${this.state.planData.amount} </span></p>
                                : <p>total <span>${this.state.planData.amount}</span></p>
                            }
                            
                        </div>
                    </Grid>
                    <div className="payment-method">
                        <p className="one" >Payment Method</p>
                        <p className="two" >Credit card</p>
                        <p className="three" >{this.state.stripeData.token.card.brand}: **** &nbsp; **** &nbsp; **** &nbsp; {this.state.stripeData.token.card.last4}</p>
                    </div>
                </Grid>
            </div>
            ) : (
                <div>
                    {/* <div className="payment-heading">Payment Information</div> */}
                    <ElementsConsumer>
                        {({stripe, elements}) => (
                        <CheckoutForm stripe={stripe} elements={elements} applyCoupon={this.applyCoupon} removeCoupon={this.removeCoupon} handleSubmit={this.handleSubmit} stateValue={this.state}/>
                        )}
                    </ElementsConsumer>
                </div>
            )}
            
           
            
        </div>
      );
    }
}


const enhance = compose(
    withRouter,
    connect(null,
        dispatch => bindActionCreators({
            createPlan,
            updatePlan,
            userDetails,
            stripeApi
        }, dispatch),
    )
);
export default enhance(Payment);

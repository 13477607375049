import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { AppBar, Toolbar, IconButton, MenuItem, Menu, Icon,Grid } from '@material-ui/core';
import '../assets/styles/widget.scss';
import { venueProfile } from "shared/venue/actions";
import { object, func } from "prop-types";
import RightSideDropMenu from 'shared/components/RightSideDropMenu';
import { withStyles } from '@material-ui/core/styles';
import { userDetails } from "shared/venueList/actions";

const logo = require("../../shared/components/assets/images/default_venue.png");
const per = require("../../shared/components/assets/images/user-default.png");

const styles = theme => ({

  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
    boxShadow: 'none',
  },
  signupDiv: {
    width: '100%',

  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    transition: 'none',
    paddingLeft: '45px',
    paddingRight: '8px',
    fontSize: '11px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  appbar: {
    backgroundColor: '#ffffff',
    color: '#36434b',
    boxShadow: 'none'
  },

  selected: {
    borderBottom: '2px #ee2738 solid !important',
    backgroundColor: '#ffffff !important'
  },
  image: {
    width: '32.5px',
    height: '32.5px',
    borderRadius: '50px',
    border: '1px black solid'
  },
  profileImageIcon: {
    padding: '0px 0px 0px 20px'
  },
  notificationsIcon: {
    padding: '0px 0px 0px 0px'
  },
  MuiMenuItem: {
    height: 'auto',
    lineHeight: '0.5',
  }
});
class WidgetTypeTwo extends Component {

  state={
    callAPI:'',
    list:[],
    mobileMoreAnchorEl: null,

  }
  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };
  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };
  UNSAFE_componentWillMount() {
        
    this.props.userDetails().then((res) => {
    })
    this.props.venueProfile(this.props.match.params.venueId).then(() => {
      this.setState({callAPI:"calling",list:this.props.venue.spaces})
          })

  };


  render() {
    // const { list } = this.props;
    const { classes } = this.props;
    const { mobileMoreAnchorEl } = this.state;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >

        {(parseInt(localStorage.getItem('type')) === 1) ?
        (localStorage.getItem('fhtoken') === null)?
        <MenuItem component={Link} to="/" onClick={e => {
          this.handleMobileMenuClose();
          this.openListSpaceLink()
        }}><p>List Your Space</p></MenuItem>
        :
        <MenuItem component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
          <p>My Venues</p>
        </MenuItem>
        :
        <MenuItem component={Link} to="/myEvents" onClick={this.handleMobileMenuClose}><p>My Events</p></MenuItem>
        }
        <MenuItem  component={Link} to="/logout"  onClick={this.handleMobileMenuClose}>
            <p>Logout</p>
        </MenuItem>
      </Menu>
    )

    return (
      <div className="my_favourites" style={{paddingTop:'65px'}}>

<div className={`${classes.signupDiv} event-header`}>
        <AppBar className={classes.appbar}>
          <Toolbar className="header-div widget-header">
            <Grid item xs={12}>
              <Grid container alignContent="center" className="topEventNav Notify">
                <Grid item md={5} xs={10} sm={5} xl={6} lg={6}>
                  <Grid container justify="center" className="logo_and_search" style={{display:'block',paddingTop:'10px'}}>
                    {/* <Grid item xs={2} sm={3} md={2} lg={2} xl={2}>
                      <div className="toplogoDiv">
                        <Link to='/'>
                          <img className={classes.image} src={require('../assets/images/logo.png')} alt="FIRST-HOLD" />
                        </Link>
                      </div>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <div className="topSearchDiv">
                        <div className="headesearch_container" style={{marginTop:'0',marginLeft:'20px'}}>
                      

                      </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>


                <Grid item md={7} xs={2} sm={7} xl={6} lg={6}>
                  <div className={classes.sectionDesktop}>
                    <div className="topEventNav" style={{justifyContent:'flex-end'}}>
                 
              
                    </div>
                    <IconButton color="inherit">
                    {localStorage.getItem('fhtoken')!==null ?
                    this.props.userData.data && this.props.userData.data.profilePhoto ?
                                      <RightSideDropMenu profilePhoto={this.props.userData.data && this.props.userData.data.profilePhoto.path}/> 
                                      :                                      
                                       <RightSideDropMenu profilePhoto={per}/> 

                        :null
                                    }
                    </IconButton>
                  </div>
                  <div className={classes.sectionMobile}>
                    <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                      <Icon>more_vert</Icon>
                    </IconButton>
                  </div>
                </Grid>
              
    
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}

      </div>
        <Grid container className="edit_profile_component">
          <Grid className="headings mb-10" item xs={12}>
              <h4 className="form_name">{this.props.venue.name}</h4>
              <p className="sub-heading" >Choose your space</p>
          </Grid>
            {this.state.callAPI===''?
            <Grid item xs={12}>
            <center><h4>Loading...</h4></center>
            </Grid>
            :
            this.state.list ?
            this.state.list.length > 0 ?
          <Grid container justify="center" className="">
          {this.state.list.map((data,idx)=>{
            
            return(
          <Grid key={idx} className="similar_venue_card">
            <img onClick={()=>this.props.history.push('/calendar/'+data.id)} className="venue_image" style={{objectFit:'cover',width:'420px', height: '210px'}} src={data.photo.length > 0 ? data.photo[0].path : logo } alt="Venue" />
            <div className="detail-box">
            <p className="space-name">{data.name}</p>
            <p className="venue-name">{this.props.venue.name}</p>
            </div>
          </Grid>

            )
          })
        }
        </Grid>

          :
          <Grid item xs={12}>
            <center><h4>No data found.</h4></center>
          </Grid>
      :
      <Grid item xs={12}>
      <center><h4>No data found.</h4></center>
    </Grid>    
}
    
        </Grid>
      </div>
    );
  }
}

WidgetTypeTwo.propTypes = {
  classes: object.isRequired,
  venueProfile: func.isRequired

};

const mapStateToProps = state => {
  return {
    venue: state.data.venue.venueDetails,
    userData: state.data.usersVenue.userInfo,

  };
};
const enhance = compose(
  withRouter,
  withStyles(styles),

  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        venueProfile,
        userDetails
      },
      dispatch
    )
  )
);
export default enhance(WidgetTypeTwo);

import React, { Component } from 'react';
import PDFViewer from 'pdf-viewer-reactjs'
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import CustomizedButton from './customizedButton';

class pdfReader extends Component {


  state={
    url:''
  }
  componentWillMount(){
    let url=this.props.location.search.split('?url=');
      

    setTimeout(function(){ 
      this.setState({url:url[1]})
    }.bind(this), 5000)

  }
  // componentWillReceiveProps(nextProps){
  //   let url=nextProps.location.search.split('?url=');
  //   this.setState({url:url[1]})
  // }


  download=(url)=>{
    window.location.href = url;

  }

  render() {

    return (
      <div className="add_venue pdf-viewer fh-container">
    
    <div>
    
      <div style={{paddingLeft:'30px',paddingBottom:'20px'}}>
      <CustomizedButton label="Download" onClickEvent={()=>this.download(this.state.url)} disabled={false} />
    </div>
    {this.state.url!==''?

        <PDFViewer
            document={{
                url:this.state.url
            }}
      />  :      
      <p style={{fontSize:'30px'}}>Loading</p>}
      </div>

         </div>
          
    );
  }
}

const enhance = compose(
    withRouter,

  );
  export default enhance(pdfReader);
  


import { Record } from 'immutable';

import {
  GET_FOLLOW_DATE_REQUEST,
  GET_FOLLOW_DATE_FAILURE,
  GET_FOLLOW_DATE_SUCCESS,

} from 'shared/state/types';

class State extends Record({
  followDateDetails:{},
  error:'',
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_FOLLOW_DATE_REQUEST:
      return state.merge({
        error:''

      });
    case GET_FOLLOW_DATE_FAILURE:
      return state.merge({
        followDateDetails:{},
        error: action.error


      });
    case GET_FOLLOW_DATE_SUCCESS:
      return state.merge({
        followDateDetails:action.followList,
        error:'Success'
      });

      
    default:
      return state;
  }
}


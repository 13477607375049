import React from 'react';
import { object } from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import { withStyles } from '@material-ui/core/styles';
import { Grid, Icon } from '@material-ui/core';


import './assets/style/VenueCardWithFav.scss';

const styles = theme => ({
  like_icon: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  iconImage: {
    height: "16px",
    width: "16px !important"
  },
  favIcon: {
    fontSize: '24px',
    textShadow: '0px 0px #dfd8d8',
    color: '#ffffff',
    margin :'10px',
    position : 'absolute' ,
    right: '40px'

    
  },
  activeFav: {
    fontSize: '24px',
    textShadow: '0px 0px #ff0000',
    color: '#FF0000',
    margin :'10px'
  }
});

class SpaceCard extends React.Component {
  // constructor(props, context) {
  //   super(props, context);
  // }


  saveData=()=>{
    this.props.saveVenue(this.props.data,this.props.linkTo);
  }


  getDistanceFromLatLonInKm = (lat1,lon1,lat2,lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    d=d* 0.621371;//distance in miles

    return d;
  };

  deg2rad = (deg) => {
    return deg * (Math.PI/180)
  };
  
  

  render() {
    // const { classes } = this.props;
    return (
      <div className="venueCardWithFav">

        {this.props.enableLink ?

          <Link onClick={()=>this.saveData()} key={this.props.idx} params={this.props.event}>
            <div key={this.props.idx} className="venue">
              {/* <Grid className="overlay_icon" container justify="flex-end" style={{ width: this.props.width }}>
            
              </Grid> */}
              
              <img className="venue_image" style={{ height: this.props.height, width: this.props.width }} src={this.props.image} alt="Venue" />
              <Grid container className="venue_content">

                <Grid item xs={12} className="venue_name">
                  {this.props.venueName}
                </Grid>
                <Grid item xs={12} className="venue_address" style={{ color: this.props.addressColor }}>
                  {this.props.venueAddress}
                </Grid>
                {this.props.venueCity && localStorage.getItem('lat') && localStorage.getItem('long') ? (
                  <Grid item xs={12} className="venue_city">
                    <Icon className="place_icon">
                      place
                    </Icon>
                    {Math.floor(this.getDistanceFromLatLonInKm(localStorage.getItem('lat'), localStorage.getItem('long'), this.props.venueLat, this.props.venueLong))}
                  </Grid>) : ('')
                }
              </Grid>
              {this.props.isInputAddress &&
                <Grid item xs={12} className="input_address">
                  <Icon className="place_icon">
                    place
                  </Icon>
                  Input address
                </Grid>
              }
            </div>
          </Link>
          :
          <div key={this.props.idx} className="venue">
            {/* <Grid className="overlay_icon" container justify="flex-end" style={{ width: this.props.width }}>
              <Icon className={this.props.favourite === true ? classes.activeFav : classes.favIcon}>
                {this.props.favourite === true ? 'favorite' : 'favorite_border'}
              </Icon>
            </Grid> */}
            <img className="venue_image" style={{ height: this.props.height, width: this.props.width }} src={this.props.image} alt="Venue" />
            <Grid container className="venue_content">
              <Grid item xs={12} className="venue_name">
                {this.props.venueName}
              </Grid>
              <Grid item xs={12} className="venue_address" style={{ color: this.props.addressColor }}>
                {this.props.venueAddress}
              </Grid>
              {this.props.venueCity ? (<Grid item xs={12} className="venue_city">
                {this.props.venueCity}</Grid>) : ('')}
            </Grid>
            {this.props.isInputAddress &&
              <Grid item xs={12} className="input_address">
                <Icon className="place_icon">
                  place
                      </Icon>
                Input address
                  </Grid>
            }
          </div>
        }
      </div>
    );
  }
}

SpaceCard.propTypes = {
  classes: object.isRequired,
};

const mapStateToProps = state => {
  return {

  };
};

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    dispatch =>
      bindActionCreators(
        {
       
        },
        dispatch
      )
  )
);
export default enhance(SpaceCard);

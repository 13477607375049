import { SUBSCRIBE } from 'shared/state/types';
import { request } from 'shared/auth';

function onRequested() {
  return {
    type:SUBSCRIBE.REQUEST,
  };
}

function onFailed(message) { 
  return {
    type:SUBSCRIBE.FAILURE,
    error:message,
  };
}

function onSuccess(message) {
  return {
    type:SUBSCRIBE.SUCCESS,
    success: message,
  };
}

export function Subscribe(params) {
  return dispatch => {
    dispatch(onRequested());
    return request().post(`/subscribe`,params)
      .then(response =>{
          dispatch(onSuccess(response.data.message));
          return response.data;
        }
      )
      .catch(errors => {     
        dispatch(onFailed(errors.response.data.message));
        throw errors;
      });
  };
}

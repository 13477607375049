import axios from 'axios';
import { FIND_GOOGLE_CALENDAR } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar';

function onRequested() {
    return { type: FIND_GOOGLE_CALENDAR.REQUEST };
}

function onSuccess(data) {
    return { type: FIND_GOOGLE_CALENDAR.SUCCESS, tokenData: data };
}

function onFailure(error) {
    return { type: FIND_GOOGLE_CALENDAR.FAILURE, error: error };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
    const headers = {};
    if (localStorage.getItem('fhtoken')) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');
    }
    const axiosInstance = axios.create({ baseURL, headers })
    loadProgressBar({ showSpinner: true }, axiosInstance);
    return axiosInstance;
};

export function getBlogs(id,key) {
    if(id == ''){
    return dispatch => {
        dispatch(onRequested());
        return request().get(`blogs`)
            .then(response => {
                dispatch(onSuccess(response.data));
                return response.data;
            })
            .catch(errors => {
                if (errors.response && errors.response.data.message) {
                    dispatch(onFailure(errors.response.data.message));
                } else {
                    dispatch(onFailure("Not able to find this location Blog list"));
                }
                throw errors;
            });
    };
}
else if(id!='' && key=='blogDetail'){
    return dispatch => {
        dispatch(onRequested());
        return request().get(`blogs?slug=${id}`)
            .then(response => {
                dispatch(onSuccess(response.data));
                return response.data;
            })
            .catch(errors => {
                if (errors.response && errors.response.data.message) {
                    dispatch(onFailure(errors.response.data.message));
                } else {
                    dispatch(onFailure("Not able to find this Blog"));
                }
                throw errors;
            });
    }; 
}
else if(id!='' && key=='category'){
    return dispatch => {
        dispatch(onRequested());
        return request().get(`blogs?category=${id}`)
            .then(response => {
                dispatch(onSuccess(response.data));
                return response.data;
            })
            .catch(errors => {
                if (errors.response && errors.response.data.message) {
                    dispatch(onFailure(errors.response.data.message));
                } else {
                    dispatch(onFailure("Not able to find this Blog"));
                }
                throw errors;
            });
    }; 
}
}


import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import './assets/style/monFri.scss';



function MondayFriday(props) {
    return (
        <div className="monTextDiv monfriText">
            <List>
                <ListItem>
              <ListItemText primary={props.day} secondary={props.dataValue} />
                          </ListItem>
            </List>
        </div>
    );
}



export default MondayFriday;

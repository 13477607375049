import { ADD_SPACE } from 'shared/state/types';
import { request } from 'shared/auth';

function addSpaceRequested() {
  return {
    type: ADD_SPACE.REQUEST,
  };
}

function addSpaceFailed(error) {
  return {
    type: ADD_SPACE.FAILURE,
    error:error,
  };
}

function addSpaceSuccess(data) {
  return {
    type: ADD_SPACE.SUCCESS,
    spaceData: data,
  };
}

export function addSpace(venueId, params) {
  return dispatch => {
    dispatch(addSpaceRequested());

    return request().post(`venues/${venueId}/spaces`, params)
      .then(response =>{
          if(response.status === 201){
            dispatch(addSpaceSuccess(response.data.data))
            return response.data.data
          }
          else if(response.status === 400){
            dispatch(addSpaceFailed("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(addSpaceFailed("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(addSpaceFailed("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(addSpaceFailed("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(addSpaceFailed("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(addSpaceFailed("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(addSpaceFailed("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(addSpaceFailed("The server was unavailable."));
          }
          else{
            dispatch(addSpaceFailed("The server was unavailable."));
          }
        }
      )
      .catch(errors => {
        dispatch(addSpaceFailed(errors.response.data.errors));
        throw errors;
      });
  };
}

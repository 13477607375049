import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {getArticles} from '../../../src/data/venues/actions/getArticles';
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { object, func } from "prop-types";
import { getArticlesWithKeyword } from '../../../src/data/venues/actions/getArticlesWithKeyword';
import { useLocation } from 'react-router';
import { getCategory } from '../../../src/data/venues/actions/getCategory';
import "../assets/styles/helpSearch.scss";
import {
    Icon,
    MenuItem,
    Menu,
    Button
} from '@material-ui/core';

function search (props) {

        const [anchorEl, setAnchorEl] = React.useState(null);
        const [article, setarticle] = React.useState([]);
        const [count, setcount] = React.useState({});
        const [keyword, setkeyword] = React.useState();
        const [category, setcategory] = React.useState();
        const queryParams = new URLSearchParams(window.location.search);
        const [show , setshow] = React.useState("true");
        const handleClose = () => {
          setAnchorEl(null);
        };
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        React.useEffect(() => {
         setkeyword(queryParams.get('keyword') || queryParams.get('tag'))
         props.getArticlesWithKeyword(queryParams.get('keyword')|| queryParams.get('tag')).then((result) => {
                      
                    if ('articles' in result && Array.isArray(result.articles)) {
                        setarticle(result.articles);
                        setcount(result.meta.pagination);
                    }
                    setshow("false");
                })
                props.getCategory().then((result) => {
                    
                    if ('categories' in result && Array.isArray(result.categories)) {
                          setcategory(result.categories);
                        };
                   })          
           },[]);
           
        return (
            <div className="help-ui-wrapper">
                <section class="header-section">
                    <div class="header-body">
                        <div class="header-wrap">
                            <a href="/help" class="company-logo">
                                <img class="large" src={require('./../assets/images/LogoWithText.png')} alt="Logo" />
                            </a>
                            <div class="overlay" id="overlay">
                                <nav class="overlay-menu">
                                    <ul class="header-nav-view">
                                    <li><Link to="/help/articles?keyword=venues" target="_blank">Venues</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=caterers" target="_blank">Caterers</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=cvb" target="_blank">CVBs</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=planners" target="_blank">Planners</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=partners" target="_blank">Partnering with FIRST-HOLD</Link></li>
                                    </ul>
                                    <div class="mobile-view-nav">
                                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                            <Icon>more_vert</Icon>
                                        </Button>
                                        <Menu  keepMounted  anchorEl={anchorEl}  onClose={handleClose}  open={Boolean(anchorEl)}>
                                            <MenuItem onClick={handleClose}>Venues</MenuItem>
                                            <MenuItem onClick={handleClose}>Caterers</MenuItem>
                                            <MenuItem onClick={handleClose}>CVBs</MenuItem>
                                            <MenuItem onClick={handleClose}>Planners</MenuItem>
                                            <MenuItem onClick={handleClose}>Partnering with FIRST-HOLD</MenuItem>
                                        </Menu>
                                    </div>
                                </nav>
                            </div>   
                        </div>
                        <div class="article-search">
                            <div class="article-search-container">
                                <form action="/help/articles" method="get" autocomplete="off">
                                    <span class="icon-search">
                                        <img  src={require('../assets/images/search.svg')} alt="Search" />
                                    </span>
                                    <input placeholder="Search for answers..." type="search" name="keyword" />
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            <section class="breadcrums">
                <ol class="breadcrums-items">
                  <li><a href="/help">Home</a></li>
                  <li>Search results</li>
                </ol>
              </section>
              {show=="false"? (article.length>0? 
            <div class="page-body">
                <div class="page-container"> 
                     <section class="articles article-overview-list">
                        {category  && count.totalCount && <h2>{count.totalCount} articles <span> {queryParams.get('tag')==null? 'found' : 'tagged'}</span> for "{keyword}":</h2> }
                        <ul class="article-list">
                        {category && article && article.map(({ id, title,articles_count,updated_at,description,category_id,slug}) => (
                               <li class="article-list-item">
                               <Link to={`/help/article/${slug}?article_id=${id}`}  className="footer_links" >
                                    <h3>{title}</h3>
                                    <p>{description}</p>
                                    <div class="article-list-item-footer">
                                    {category && category.map(({ title,id}) => (
                                        <div class="search-in">      
                                            { id== category_id &&
                                              <div>
                                                 <span>Published in:</span>
                                                 <span class="link">{title}</span>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                    </div>
                                </Link>
                            </li>
                        ))}
                        </ul> 
                    </section>
                </div>
            </div>:<div class="page-container"><section class="articles-result"><h2>We found no articles for {keyword}</h2></section><br/><Link to="/help"class="btn">Return home</Link></div>)
           :null}
        </div>
        );
    }
    const mapStateToProps = state => {
        return {
          userData: state.data.usersVenue.userInfo
        };
      }
      
      const enhance = compose(
        withRouter,
        connect(mapStateToProps, dispatch =>
          bindActionCreators(
            {
             
              getArticles,
              getArticlesWithKeyword,
              getCategory
              
            },
            dispatch
          )
        )
      );   
      export default enhance(withRouter(search));



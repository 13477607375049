import { object, string, number, boolean } from 'yup';

import BasicInfo from './views/basicInfo';
import TypeInfo from './views/typeInfo';
import PersonalInfo from './views/personalInfo';
import CompanyInfo from './views/companyInfo';

const phoneRegExp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/  /* eslint-disable-line */
export default [
  {
    id: 'basics',
    component: BasicInfo,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: object().shape({
      email: string().email().required("*Email is required").max(100, '*Email should not be greater than 100 characters'),
      password: string().min(8).required("*Password is required").max(100, '*Password should not be greater than 100 characters'),
    }),
  },
  {
    id: 'type',
    component: TypeInfo,
    initialValues: {
      user_type: 0,
    },
    validationSchema: object().shape({
      user_type: number().required("*User Type is required"),
    }),
  },
  {
    id: 'personal',
    component: PersonalInfo,
    initialValues: {
      first_name: '',
      last_name: '',
      phone_number: '',
      extension: '',
      display_number: true,
      image: undefined
    },
    validationSchema: object().shape({
      first_name: string().required("*First Name is required").matches(/^[ A-Za-z_@./()&-]*$/,"*Invalid First Name").max(100, '*First Name should not be greater than 100 characters'),
      last_name: string().required("*Last Name is required").matches(/^[ A-Za-z_@./()&-]*$/,"*Invalid Last Name").max(100, '*Last Name should not be greater than 100 characters'),
      phone_number: string().matches(phoneRegExp, 'Phone number is not valid').required("*Phone Number is required").min(4, '*Phone Number should not be less than than 4 digit').max(20, '*Phone number should not be greater than 20 digit'),
      image: string(),
      extension:string().matches(/^[0-9]*$/, '*Extension is not valid').max(8, '*Should not be greater than 8 digit')
    }),
  },
  {
    id: 'company',
    component: CompanyInfo,
    initialValues: {
      company_name: '',
      line_1: '',
      line_2: '',
      city: '',
      state: '',
      zipcode:'',
      job_title: '',
      job_title_planner:'',
      agree: false,
      newsletter:true,
      special_offer: false
    },
    validationSchema: object().shape({
      // company_name: string().required('*Company Name is required').max(100, '*Company Name should not be greater than 100 characters'),
      company_name: string().max(100, '*Company Name should not be greater than 100 characters'),
      line_1: string().required("*Line 1 is required").max(255, '*Line1 should not be greater than 255 characters'),
      line_2: string().max(255, '*Line2 should not be greater than 255 characters'),
      city: string().required("*City  is required").max(255, '*City should not be greater than 255 characters'),
      state: string().required("*State  is required"),
      zipcode: string().required("*Zip Code  is required").matches(/(^[a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d$)|(^\d{5}(-\d{4})?$)/,"*Zip Code is not valid").max(16, '*Zip Code should not be greater than 16 characters'),
      job_title: string().required('*Job Title is required').max(150, '*Job Title should not be greater than 150 characters'),
      job_title_planner: string().max(150, '*Job Title should not be greater than 150 characters'),
      agree: boolean().required("The terms and conditions must be selected.")
      .oneOf([true], "The terms and conditions must be selected."),
     
    }),
  },
];

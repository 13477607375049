import React from 'react';

import { Grid } from '@material-ui/core';

import VenueTypeCard from '../../shared/components/VenueTypeCard';

function RightPanel(props) {
    const venueObject = props.venueType.map(function (data, idx) {
        return (
            
            <VenueTypeCard key={idx} idx={idx} onClick={(e)=>{props.venueAccordingToType(data.event,data.type,data.id)}} event={data.event} image={data.image} height="145px" width="256px" linkTo={`/browseVenue/${data.event}/${data.type}`} enableLink={true} />
        );
    });

    return (

        <div className="browse_venue_right_panel">
            <h4 className="pick_a_category">Pick a category</h4>
            <Grid className="venues" container>
                {venueObject}
            </Grid>
        </div>
    );
}


export default RightPanel;

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BasicInfo from '../components/basicInfo';
import PersonalInfo from '../components/personalInfo';
import CompanyInfo from '../components/companyInfo';
import ChangePassword from '../components/changePassword';
import TripleseatInfo from '../components/tripleseat';
import { getUser, getStates, updateUser, changePassword } from 'data/person/actions';
import { deleteResource,deleteUploadImage } from 'data/master/actions';
import { userDetails } from "shared/venueList/actions";

import { deleteVenueImage } from 'data/venues/actions';
import { logout } from 'shared/auth/actions';
import { getUploadPostUrl, uploadtoS3} from 'data/master/actions';
import EventTempleInfo from '../components/eventTemple';

import * as Yup from 'yup';

import '../assets/styles/editProfile.scss';

const phoneRegExp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/ /* eslint-disable-line */
const FILE_SIZE = 1024 * 1024 * 3;
const logo = require("../../shared/components/assets/images/user-default.png");

class editProfile extends Component {
    state = {
        saveData:false,
        error:'',
        currentEmail: '',
        newEmail: '',
        firstName: '',
        lastName: '',
        image: '',
        phoneNumber: '',
        extension: '',
        displayMyNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        jobTitle: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        companyName: '',
        userType: '',
        states: [],
        phoneVerification: null,
        emailVerfication: null,
        profilePhotoId: '',
        id: '',
        passwordTypeCurrentPass:'password', 
        passwordTypeNewPass:'password',
        key: '',
        secret: '',
        public_key:'',
        tripleseat_status:false,
        event_temple_org_id:'',
        event_temple_consumer_key:'',
        event_temple_status:false,
        etOn:0,
        tsOn:0
    }

    UNSAFE_componentWillMount() {
        this.props.getUser()
        this.props.getStates()
        localStorage.removeItem('personalInfoImage');
        // localStorage.removeItem('profilePhoto');
        this.setState({profilePhotoId:''})
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({saveData:false})
        if (this.props.states !== nextProps.states && Object.keys(nextProps.states).length > 0) {
            let states = []
            nextProps.states !== [] && nextProps.states.map(function (data, idx) {
                states.push(data.name)
                return states;
            });
            this.setState({ states: states })
        }
        if (this.props.userData !== nextProps.userData && Object.keys(nextProps.userData).length !== 0) {
            const userData = nextProps.userData
            if(nextProps.userData.profilePhoto){
                localStorage.setItem('profilePhoto',nextProps.userData.profilePhoto.path);
                localStorage.setItem('personalInfoImage',nextProps.userData.profilePhoto.path);
            }
            else{
                localStorage.setItem('profilePhoto',logo) ;

                localStorage.removeItem('personalInfoImage');
            }
            this.setState({
                currentEmail: userData.email ? userData.email: '',
                firstName: userData.first_name ? userData.first_name :'',
                lastName: userData.last_name ? userData.last_name : '',
                // image: userData.profilePhoto ? userData.profilePhoto.path : '',
                profilePhotoId: userData.profilePhoto ? userData.profilePhoto.id : '',
                phoneNumber: userData.phone_number ? userData.phone_number :'',
                extension: userData.phone_ext ? userData.phone_ext :'',
                displayMyNumber: userData.display_phone_number === 1 ? true : false,
                addressLine1: userData.address.line_1 ? userData.address.line_1 : '',
                addressLine2: userData.address.line_2  ? userData.address.line_2 : '',
                city: userData.address.city ? userData.address.city : '',
                state: userData.address.state ? userData.address.state : '',
                zipCode: userData.address.zip ? userData.address.zip : '',
                jobTitle: userData.job_title ? userData.job_title : '',
                companyName: userData.company_name ? userData.company_name : '',
                userType: userData.type ? userData.type : '',
                phoneVerification: userData.phone_number_verified_at ? userData.phone_number_verified_at : '',
                emailVerfication: userData.email_verified_at ? userData.email_verified_at : '',
                id: userData.id,
                isEmailBlank:userData.email?false:true,
                isPasswordSet:userData.is_password_blank,
                tripleseat_status:userData.tripleseat_status==1?true:false,
                key: userData.tripleseat_credential && userData.tripleseat_credential.key ? userData.tripleseat_credential.key : '',
                secret: userData.tripleseat_credential && userData.tripleseat_credential.secret  ? userData.tripleseat_credential.secret : '',
                public_key: userData.tripleseat_credential && userData.tripleseat_credential.public_key  ? userData.tripleseat_credential.public_key : '',
                event_temple_status:userData.event_temple_status==1?true:false,
                event_temple_org_id: userData.event_temple_credential && userData.event_temple_credential.org_id ? userData.event_temple_credential.org_id : '',
                event_temple_consumer_key: userData.event_temple_credential && userData.event_temple_credential.key  ? userData.event_temple_credential.key : '',
                tsOn:userData.event_temple_status==1?1:0,
                etOn:userData.tripleseat_status==1?1:0,

            })
        }

    }

    changeCurrentPasswordFieldType = () =>{
        if(this.state.passwordTypeCurrentPass === 'password'){
            this.setState({passwordTypeCurrentPass: 'text'})
        }
        else{
            this.setState({passwordTypeCurrentPass: 'password'})
        }
    }
    changeNewPasswordFieldType = () =>{
        if(this.state.passwordTypeNewPass === 'password'){
            this.setState({passwordTypeNewPass: 'text'})
        }
        else{
            this.setState({passwordTypeNewPass: 'password'})
        }
    }


    deleteImage = () =>{
        this.props.deleteResource(this.state.profilePhotoId).then((response) => {
           this.props.getUser()
           this.props.userDetails()
        })
    }
    handleUpdate = (formData) => {
        this.props.updateUser(formData)
            .then((response) => {
                localStorage.removeItem('personalInfoImage');
                this.props.getUser()
                this.props.userDetails()
            })
    }

    buildFormData = (formData, data, parentKey) => {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;
            formData.append(parentKey, value);

        }
        return formData;
        
    }

    handleSubmitPersonalInfo = (values, actions) => {
    
        // if(this.state.saveData==true){
            this.setState({error:""})
            let formData;
            formData = new FormData();
            let obj;
            if(values.extension){
                obj = {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    phone_number: values.phoneNumber,
                    phone_ext: values.extension,
                    display_phone_number: values.displayMyNumber === true ? 1 : 0,
                };
            }
            else{
                obj = {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    phone_number: values.phoneNumber,
                    display_phone_number: values.displayMyNumber === true ? 1 : 0,
                };
            }
            if(values.image){
                if(this.state.profilePhotoId){
        
                    if (!values.image && values.image.name.match(/.(jpg|jpeg)$/i)) {
                        
                        this.setState({error:"*Unsupported Format only jpg and jpeg images will be accepted"});
                    }
                    else if(values.image.size >= FILE_SIZE){
                        this.setState({error:"*File size should not be greater than 3MB."});
    
                    }
                    else{
                        this.props.deleteUploadImage(this.state.profilePhotoId).then((response) => {
                        this.props.getUploadPostUrl(1,values.image).then((res) => {
                            this.props.uploadtoS3(res.url, values.image).then( ()=>{
                                formData.append('photo',res.keyName)
                                localStorage.setItem('profilePhoto',res.url);
    
                                this.buildFormData(formData, obj)
                                this.handleUpdate(formData)
                            })
                        })
    
                        })
                    }
    
                        // formData.append('file',values.image)
                 
                }
                else{
                    if (!values.image && values.image.name.match(/.(jpg|jpeg)$/i)) {
                        this.setState({error:"*Unsupported Format only jpg and jpeg images will be accepted"});
                   }
                   else if(values.image.size >= FILE_SIZE){
                       this.setState({error:"*File size should not be greater than 3MB."});
                   }
                   else{
                    this.props.getUploadPostUrl(1,values.image).then((res) => {
                        this.props.uploadtoS3(res.url, values.image).then( ()=>{
                            formData.append('photo',res.keyName)
                            this.buildFormData(formData, obj)
                            this.handleUpdate(formData)
                        })
                      })
                    }
                    // formData.append('file',values.image)
                }
            }
            else{
    
                this.buildFormData(formData, obj)
                this.handleUpdate(formData)
            }
            actions.setFieldValue('image','')
        // }
   
      
    }
    handleSubmitCompanyInfo = (values, actions) => {
        const zipcode = values.zipCode.toUpperCase()
        let formData;
        formData = new FormData();
        let obj;
        if(values.addressLine2.length > 0)
        {
            if(parseInt(localStorage.getItem('type'))===1){
                obj = {
                    job_title: values.jobTitle,
                    company_address: {
                    line_1: values.addressLine1,
                    line_2: values.addressLine2,
                    city: values.city,
                    state: values.state,
                    country: 'US',
                    zip: zipcode
                    },
                    address: {
                    line_1: values.addressLine1,
                    line_2: values.addressLine2,
                    city: values.city,
                    state: values.state,
                    country: 'US',
                    zip: zipcode
                }
            }
            }
            else{
                if(values.companyName){
                    obj = {
                        company_name: values.companyName,
                        company_address: {
                            line_1: values.addressLine1,
                            line_2: values.addressLine2,
                            city: values.city,
                            state: values.state,
                            country: 'US',
                            zip: values.zipCode
                            },
                            address: {
                            line_1: values.addressLine1,
                            line_2: values.addressLine2,
                            city: values.city,
                            state: values.state,
                            country: 'US',
                            zip: values.zipCode
                        }
                    }
                }
                else{
                    obj = {
                        company_address: {
                            line_1: values.addressLine1,
                            line_2: values.addressLine2,
                            city: values.city,
                            state: values.state,
                            country: 'US',
                            zip: values.zipCode
                            },
                            address: {
                            line_1: values.addressLine1,
                            line_2: values.addressLine2,
                            city: values.city,
                            state: values.state,
                            country: 'US',
                            zip: values.zipCode
                        }
                    }
                }
            }
        }
        else{
            if(parseInt(localStorage.getItem('type'))===1){
                obj = {
                    job_title: values.jobTitle,
                    company_address: {
                        line_1: values.addressLine1,
                        city: values.city,
                        state: values.state,
                        country: 'US',
                        zip: zipcode
                        },
                        address: {
                        line_1: values.addressLine1,
                        city: values.city,
                        state: values.state,
                        country: 'US',
                        zip: zipcode
                }
            }
            }
            else{
                if(values.companyName){
                    obj = {
                        company_name: values.companyName,
                        company_address: {
                            line_1: values.addressLine1,
                            city: values.city,
                            state: values.state,
                            country: 'US',
                            zip: values.zipCode
                            },
                            address: {
                            line_1: values.addressLine1,
                            city: values.city,
                            state: values.state,
                            country: 'US',
                            zip: values.zipCode
                        }
                    }
                }
                else{
                    obj = {
                        company_address: {
                            line_1: values.addressLine1,
                            city: values.city,
                            state: values.state,
                            country: 'US',
                            zip: values.zipCode
                            },
                            address: {
                            line_1: values.addressLine1,
                            city: values.city,
                            state: values.state,
                            country: 'US',
                            zip: values.zipCode
                        }
                    }
                }
            }
        }

        this.buildFormData(formData, obj)
        this.handleUpdate(formData)

        // if(values.personal.image){
        //   formData.append('file',values.personal.image)
        // }
    }
    saveClick = () =>{
        this.setState({saveData:true});
    }
    handleSubmitChangePassword = (values, actions) => {
        let obj;
        obj ={
            current_password: values.currentPassword,
            password: values.newPassword,
            password_confirmation: values.newPassword
          }

        this.props.changePassword(obj).then((response) => {
            this.props.logout()
        })
    }
    handleSubmitBasicInfo = (values, actions) => {
        let formData;
        formData = new FormData();
        let obj;
        obj = {
            email:values.newEmail
        };

        this.buildFormData(formData, obj)
        this.handleUpdate(formData)
    }

    handleSubmitTripleseatInfo = (values, actions) => {
        let formData;
        formData = new FormData();
        let obj;     
        obj = {
            tripleseat_status:this.state.tripleseat_status?1:0,
            tripleseat_credential:{
                key:values.key,
                secret:values.secret,
                public_key:values.public_key
            }
        };
        console.log('asdfas',obj);
        this.buildFormData(formData, obj)
        this.handleUpdate(formData)
    }
    handleSubmitEventTempleInfo = (values, actions) => {
        let formData;
        formData = new FormData();
        let obj;     
        obj = {
            event_temple_status:this.state.event_temple_status?1:0,
            event_temple_credential:{
                event_temple_org_id:values.event_temple_org_id,
                event_temple_consumer_key:values.event_temple_consumer_key
            }
        };
        this.buildFormData(formData, obj)
        this.handleUpdate(formData)
    }

    handleTStatus = (status)=>{
        this.setState({tripleseat_status:status});
    }
    handleETStatus = (status)=>{
        this.setState({event_temple_status:status});
    }

  
   
    render() {
        const personalInfoInitialValues = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            image: this.state.image,
            phoneNumber: this.state.phoneNumber,
            extension: this.state.extension,
            displayMyNumber: this.state.displayMyNumber,
            phoneVerification:this.state.phoneVerification
        }
        const basicInfoInitialValues = {
            currentEmail: this.state.currentEmail,
            newEmail: this.state.newEmail,
            isEmailBlank:this.state.isEmailBlank,
            emailVerfication:this.state.emailVerfication
        }
        const companyInfoInitialValues = {
            companyName: this.state.companyName,
            addressLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            city: this.state.city,
            state: this.state.state,
            zipCode: this.state.zipCode,
            jobTitle: this.state.jobTitle
        }
        const changePasswordInitialValues = {
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword,
            isPasswordSet:this.state.isPasswordSet            
        }
        const tripleseatCredentialValues = {
            key: this.state.key,
            secret: this.state.secret,
            public_key : this.state.public_key,
            tripleseat_status:this.state.tripleseat_status
        }
        const EventTempleCredentialValues = {
            event_temple_org_id: this.state.event_temple_org_id,
            event_temple_consumer_key : this.state.event_temple_consumer_key,
            event_temple_status_status:this.state.event_temple_status
        }


        const BasicInfoValidation = Yup.object().shape({
            isEmailBlank: Yup.boolean(),
            currentEmail: Yup.string("Current Email should be a string").email("*Invalid Email").max(100, '*Email should not be greater than 100 characters').when("isEmailBlank", {
                is: false,
                then: Yup.string().required("*Email is required")
            }),
            newEmail: Yup.string("New Email should be a string").email("*Invalid Email").required("*Email is required").max(100, '*Email should not be greater than 100 characters'),
        });
        const PersonalInfoValidation = Yup.object().shape({
            firstName: Yup.string("FIrst Name should be a string").required("*First Name is required").matches(/^[ A-Za-z_@./()&-]*$/,"*Invalid First Name").max(100, '*First Name should not be greater than 100 characters'),
            lastName: Yup.string("Last Name should be a string").required("*Last Name is required").matches(/^[ A-Za-z_@./()&-]*$/,"*Invalid in Last Name").max(100, '*Last Name should not be greater than 100 characters'),
            phoneNumber: Yup.string("Phone Number should be a string").matches(phoneRegExp, 'Phone number is not valid').required("*Phone Number is required").min(4, '*Phone Number should not be less than than 4 digit').max(20, '*Phone number should not be greater than 20 digit'),
            extension: Yup.string("Extension should be a string").matches(/^[0-9]*$/, '*Extension is not valid').max(8, '*Should not be greater than 8 digit')
        });
        const CompanyInfoValidation = Yup.object().shape({
            companyName: parseInt(this.state.userType) === 0 ? Yup.string("Company Name should be a string").max(100, '*Company Name should not be greater than 100 characters'):Yup.string(),
            addressLine1: Yup.string("Address Line1 should be string").required("*Line 1 is required").max(255, '*Line1 should not be greater than 255 characters'),
            addressLine2: Yup.string("Address Line2 should be string").max(255, '*Line2 should not be greater than 255 characters'),
            city: Yup.string("City should be a string").required("*City  is required").max(255, '*City should not be greater than 255 characters'),
            state: Yup.string("State should be a string").required("*State  is required").max(255, '*State should not be greater than 255 characters'),
            zipCode: Yup.string("Zip Code should be a string").required("*Zip Code  is required").max(16, '*Zip Code should not be greater than 16 characters').matches(/(^[a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d$)|(^\d{5}(-\d{4})?$)/,"*Zip Code is not valid"),
            jobTitle: parseInt(this.state.userType) === 1 ? Yup.string("Job Title should be string").required('*Job Title is required').max(150, '*Job Title should not be greater than 150 characters'):Yup.string(),
        });
       
        const ChangePasswordValidation = Yup.object().shape({
            currentPassword: this.state.isPasswordSet?Yup.string("Password should be a string").min(8,'*Password should not be less than 8 characters').required("*Password is required").max(100, '*Password should not be greater than 100 characters'):Yup.string("Current Password should be a string").min(8,'*Current Password should not be less than 8 characters').required("*Current Password is required").max(100, '*Current Password should not be greater than 100 characters'),
            newPassword: this.state.isPasswordSet?Yup.string()
            .oneOf([Yup.ref('currentPassword'), null], 'Confirm Passwords must match'):Yup.string("New Password should be a string").min(8,'*New Password should not be less than 8 characters').required("*New Password is required").max(100, '*New Password should not be greater than 100 characters'),
        });

        const tripleseatCredentialValidation = Yup.object().shape({
            key   : Yup.string("Tripleseat Key should be a string").required("*Tripleseat Key is required"),
            secret: Yup.string("Tripleseat Secret should be a string").required("*Tripleseat Secret is required"),
            public_key : Yup.string("Tripleseat Public Key should be a string").required("*Tripleseat Public Key is required")
        });
        const EventTempleCredentialValidation = Yup.object().shape({
            event_temple_org_id   : Yup.string("Event Temple Organization ID should be a string").required("*Event Temple Organization ID is required"),
            event_temple_consumer_key : Yup.string("Event Temple Consumer Key should be a string").required("*Event Temple Key is required")
        });
       
        return (
            <div className="edit_profile fh-container">
                <div className="add_venue_address_container">
                    <div className="personal_info">
                        <PersonalInfo saveClick={this.saveClick} error={this.state.error} initialValues={personalInfoInitialValues} validationSchema={PersonalInfoValidation} handleSubmit={this.handleSubmitPersonalInfo} deleteImage={this.deleteImage} profilePhotoId={this.state.profilePhotoId}/>
                    </div>
                    <div className="company_info">
                        <CompanyInfo initialValues={companyInfoInitialValues} validationSchema={CompanyInfoValidation} userType={this.state.userType} states={this.state.states} handleSubmit={this.handleSubmitCompanyInfo} />
                    </div>
                    <div className="change_password">
                        <ChangePassword initialValues={changePasswordInitialValues} validationSchema={ChangePasswordValidation} handleSubmit={this.handleSubmitChangePassword} changeCurrentPasswordFieldType={this.changeCurrentPasswordFieldType} changeNewPasswordFieldType={this.changeNewPasswordFieldType} passwordTypeCurrentPass={this.state.passwordTypeCurrentPass} passwordTypeNewPass={this.state.passwordTypeNewPass}/>
                    </div>
                    <div className="basic_info">
                        <BasicInfo initialValues={basicInfoInitialValues} validationSchema={BasicInfoValidation} handleSubmit={this.handleSubmitBasicInfo} />
                    </div>
                    {
                        parseInt(localStorage.getItem('type'))===1 &&
                        <div className="basic_info">
                            <TripleseatInfo tripleseat_status={this.state.tripleseat_status} handleTStatus={this.handleTStatus} initialValues={tripleseatCredentialValues} validationSchema={tripleseatCredentialValidation} handleSubmit={this.handleSubmitTripleseatInfo} event_temple_status={this.state.tsOn}/>
                        </div>
                    }
                     {
                        parseInt(localStorage.getItem('type'))===1 &&
                        <div className="basic_info" >
                            <EventTempleInfo  event_temple_status={this.state.event_temple_status} handleETStatus={this.handleETStatus} initialValues={EventTempleCredentialValues} validationSchema={EventTempleCredentialValidation} handleSubmit={this.handleSubmitEventTempleInfo} tripleseat_status={this.state.etOn}/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.data.auth.userData,
        states: state.data.auth.states
    };
};

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        getUser,
        userDetails,
        getStates,
        updateUser,
        changePassword,
        logout,
        deleteVenueImage,
        deleteResource,
        deleteUploadImage,
        getUploadPostUrl,
        uploadtoS3
    }, dispatch),
)(withRouter(editProfile));


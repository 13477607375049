import axios from 'axios';
import { GET_EVENT_TYPE_REQUEST, GET_EVENT_TYPE_SUCCESS, GET_EVENT_TYPE_FAILURE } from 'shared/state/types';
function eventTypeRequested() {
  return {
    type: GET_EVENT_TYPE_REQUEST
  };
}

function eventTypeSuccess(data) {
  return {
    type: GET_EVENT_TYPE_SUCCESS,
    eventTypeDetails: data,
  };
}

function eventTypeFailure(error) {
  return {
    type: GET_EVENT_TYPE_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};


  return axios.create({
    baseURL,
    headers,
  })
};

export function eventType(params) {
    return dispatch => {
      dispatch(eventTypeRequested());
        return request().get(`event_types`)
        .then(response =>
          dispatch (eventTypeSuccess(response.data), ))
        .catch(errors => {
          dispatch(eventTypeFailure(errors.response));
          throw errors;
        });
    
   

    
 };
}

import EntityState from 'data/EntityState';
import { FETCH_ME } from 'shared/state/types';
import Person from './model';

export default function (state = new EntityState(), action) {
  switch (action.type) {
    case FETCH_ME.SUCCESS:
      return state.merge({
        // items: state.items.merge(action.payload.map(person => [person.id, new Person(person)])),
        // items: state.items.set([action.payload.id, new Person(action.payload)]),
        // items: state.items.setIn(action.payload.id, [action.payload.id, new Person(action.payload)]),
        items: state.items.set(action.payload.id, new Person(action.payload)),
      });
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import CustomCheckBox from '../../../shared/components/CustomCheckBox';
import SpaceRatesNestedForm from './spaceRatesNestedForm';
// import CustomSelectWithScroll from '../../../shared/components/CustomSelectWithScroll';

import { Grid, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FieldArray, ErrorMessage, } from 'formik';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import '../assets/styles/addSpace.scss';
import HolidayOverrides from './holidayOverrides';
import AddRentalRate from './addRentalRate';

const styles = theme => ({
    icon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    paper: {
        width: '237px',
        height: 'auto',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        fontFamily: 'Raleway',
        backgroundColor: '#ffffff'
      },
      
    root:{
        boxShadow: '0px 9px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    typography: {
        [theme.breakpoints.down('sm')]: {
            height: '357px',
            width: '237px',
          },
          [theme.breakpoints.up('md')]: {
            // height: '200px',
            width: '430px',
          },
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1'
    },
    currentDateText:{
        marginTop:'8px',
        fontWeight:'600'
    }
});

function AddSpaceRates(props) {
    const { classes } = props;
    const [anchorEl, setAnchorEl]  = React.useState(null);
    const [vholiday, setVholiday]  = React.useState(false);
    const [aholiday, setAholiday]  = React.useState(false);

    const handleClick = event => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleViewHoliday = () =>{
        setVholiday(false);
    }

    const handleAddHoliday = () =>{
        setAholiday(false);
    }
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const eventTypes = (arrayHelpers) => {
        return props.formikProps.values.eventTypesGroup.map(function (data, idx) {
            return (<div>
                <div className="space_availability_checkboxes">
                    <CustomCheckBox name={data.name} label={data.name+`s`} arrayHelpers={arrayHelpers} handleChange={props.formikProps.handleChange} setFieldValue={props.formikProps.setFieldValue} checkboxGroup={props.formikProps.values.spaceRatesGroup} eventArray={props.formikProps.values.eventSpaceRatesArray} />
                    {
                    data.name === 'Business Event' ?
                        <div className="checkbox_instructions">Corporate & Association Meetings or Events, Networking Receptions, etc.                        </div>
                    : data.name === 'Social Event' ?
                        <div className="checkbox_instructions"> Rehearsal Dinners, Reunions, Fundraisers, etc.                        </div>
                    : data.name === 'Wedding'?
                        <div className="checkbox_instructions"> Wedding Ceremonies and Receptions.                        </div>
                    :''
                }
                </div>
                <div className="nested_form">
                    <SpaceRatesNestedForm idx={idx} 
                    fixedRentalAvailabilitySortedDays={props.fixedRentalAvailabilitySortedDays} 
                    flexibleRentalAvailabilitySortedDays={props.flexibleRentalAvailabilitySortedDays} 
                    deleteEvent={props.deleteEvent} 
                    eventName={data.name} 
                    eventId={data.id} 
                    values={props.formikProps.values} 
                    handleBlur={props.formikProps.handleBlur} 
                    setFieldValue={props.formikProps.setFieldValue} 
                    handleChange={props.formikProps.handleChange} 
                    openForm={props.formikProps.values.spaceRatesGroup.indexOf(data.name) > -1 ? true : false} 
                    step4Error={props.step4Error} 
                    anchorElFixedAddOn={props.anchorElFixedAddOn} 
                    isFixedAddonToggleOpen={props.isFixedAddonToggleOpen} 
                    fixedEventPopper={props.fixedEventPopper} 
                    flexibleEventPopper={props.flexibleEventPopper} 
                    errors={props.errors}/>
                </div>
            </div>);
        });
    };
    return (
        <div className="add_space_rates_container">
            <Grid container className="add_space_rates">
                <Grid className="space_name_container" item xs={12}>
                    <div className="space_name"><span className="upper_case">{props.venueName}</span> - <span className="capitalise">{props.spaceName}</span></div>
                </Grid>
                <Grid item xs={12}>
                    <h3 className="space_rates_heading">Event type and pricing</h3>
                    {/* <Grid container xs={12} className="holiday_rates" justify="flex-end" >
                        <Grid item xs={3} >
                            <h5>Holiday Rates?</h5>  
                            <Button variant="outlined" size="small" className="override-button" onClick={() => setAholiday(true)}>
                            Add rental rate override
                            </Button>   
                            { aholiday &&
                            <AddRentalRate open={aholiday} close={handleAddHoliday} formikProps={props.formikProps} flexibleRentalAvailabilitySortedDays={props.flexibleRentalAvailabilitySortedDays}  fixedRentalAvailabilitySortedDays={props.fixedRentalAvailabilitySortedDays}   />                            
                            }
                            <div class="view-overrides" onClick={() => setVholiday(true)}>View Overrides</div>
                            {
                                vholiday && <HolidayOverrides open={vholiday} close={handleViewHoliday} formikProps={props.formikProps} flexibleRentalAvailabilitySortedDays={props.flexibleRentalAvailabilitySortedDays}  fixedRentalAvailabilitySortedDays={props.fixedRentalAvailabilitySortedDays}   />
                            }
                        </Grid>
                    </Grid> */}


                    <Grid container className="add_space_rates_form">
                        <FieldArray
                            name="spaceRatesGroup"
                            render={arrayHelpers => (
                                <Grid item xs={12} className="space_availability_heading">                                   
                                    <Grid container>
                                        <Grid item className="ques-mark">What types of events can be held here?
                                        <Grid className="help_icon link_hint">
                                            <Button aria-describedby={id} variant="contained" color="primary"  >
                                                <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon} onClick={handleClick} >
                                                        help_outline
                                                </Icon>     
                                                <Icon className={classes.icon} onClick={e=>{ window.open('https://firsthold.groovehq.com/help/event-space-pricing','_blank'); }} >
                                                 info_outline
                                                </Icon>                                          
                                            </Button>
                                          
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                                }}
                                            >
                                                <Typography className={classes.typography}>Check <b>ALL</b> boxes that apply.  If you charge different rates for different types of events, no problem, enter different rates in each event type.  If your rates don’t change, then simply enter the same rate in each category.Planners will not see variations in rental rates.
                                                <p className={classes.currentDateText}> Important: All pricing is shown as estimated.  Complete proposals and contracts are to be provided by the venue.</p>                                             
                                                </Typography>
                                            </Popover>
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                    <div className="error"><ErrorMessage name="spaceRatesGroup" /></div>
                                    {eventTypes(arrayHelpers)}
                                </Grid>
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    );
}

AddSpaceRates.propTypes = {
    classes: PropTypes.object.isRequired,
};

const enhance = withStyles(styles)

export default enhance(AddSpaceRates);

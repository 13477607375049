import axios from 'axios';
import {FIND_GOOGLE_TOKEN} from 'shared/state/types';
import {loadProgressBar} from 'axios-progress-bar'

function onRequested() {
    return {type: FIND_GOOGLE_TOKEN.REQUEST};
}

function onSuccess(data) {
    return {type: FIND_GOOGLE_TOKEN.SUCCESS, tokenData: data};
}

function onFailure(error) {
    return {type: FIND_GOOGLE_TOKEN.FAILURE, error: error};
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
    const headers = {};
    if (localStorage.getItem('fhtoken')) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');
    }
    const axiosInstance = axios.create({baseURL, headers})
    loadProgressBar({
        showSpinner: true
    }, axiosInstance)
    return axiosInstance;
};

export function getMicrosoftToken() {

    return dispatch => {
        dispatch(onRequested());
        return request()
            .get(`sso/microsoft/accessToken`)
            .then(response => {
                dispatch(onSuccess(response.data),)
                return response.data;
            })
            .catch(errors => {
                if (errors.response && errors.response.data.message) {
                    dispatch(onFailure(errors.response.data.message));
                } else {
                    dispatch(onFailure("Not able to find this token"));
                }
                throw errors;
            });
    };
}

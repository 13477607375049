import React from 'react';
import { Grid } from '@material-ui/core';
// import Typography from '@material-ui/core/Typography';
import '../assets/styles/helpAndFaq.scss';
// import PlaceHold from 'shared/components/placeHold';


import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        width: '100%',
    },

    mainHeading:{
        fontWeight:'bold',
        fontSize:'18px'
    },

    heading: {
        // fontWeight: theme.typography.fontWeightRegular,
        // color:'#1397e1',
        fontWeight:'600'
    },

    section: {
       paddingTop:'15px'
    },
});

function SimpleExpansionPanel(props) {
    const { classes } = props;
    const faqObject = props.faqData && props.faqData.map(function(data, idx) {
        return(
            <div className={classes.section}>
                <p className={classes.heading}>{data.title}</p>
                <p className={classes.para}>{data.description}</p>
            </div>
        );
    });
    return (

        <Grid container className="venue-available">
            <Grid xs={12} item>
                <Grid container>
                    <Grid item md={12} xs={11} sm={12} xl={12} lg={12} className="avilableDescriptionDiv">
                        <div className={classes.root}>
                            <h1 className={classes.mainHeading}>FAQ'S</h1>
                            {faqObject}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

SimpleExpansionPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);


import React from 'react';
import '../assets/styles/landing-page.scss';
import { Grid } from '@material-ui/core';
import { Link } from "react-router-dom";
import "react-alice-carousel/lib/alice-carousel.css";
import Footer from 'explore-landing-page/component/footer';
import Footers from '../../landingPage/components/footer';;

function explore(props) {
    return (
        <div className="">
            <div className="container explore-ui-wrapper">
            <Grid container className="topspace">                                        
                    <Grid className="banner-wrapper" item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className="search-wrapper">
                            <div className="heading-text main event">
                                <h1>Events</h1>
                                <p>Explore interesting venues.</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div className="text-center-image event-main">
                            {/* <img className="" src={require('../assets/images/home-image-4.png')} alt="FIRST-HOLD"/> */}
                            <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                        </div>
                    </Grid>
                </Grid>
                {/* new block start */}
                <div className="location-address-wrapper box-wrapper">
                    <div className="location-address border-line">
                        <div className="heading-text block">
                            <h1 className="heading-text">Locations</h1>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/events-bay-area">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/location-image-1.png')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Bay Area event, wedding & meeting venues</h1>
                                    <p>Check availability of venues in the San Francisco Bay Area</p>
                                </div>
                            </Link>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/sonoma-valley">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/Sonoma-Valley-Explore.jpg')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Sonoma Valley event, wedding & meeting venues</h1>
                                    <p>Check availability of venues in the Sonoma Valley</p>
                                </div>
                            </Link>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/silicon-valley">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/Silicon Valley - Explore.jpg')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Silicon Valley event, wedding & meeting venues</h1>
                                    <p>Check availability of venues in the Silicon Valley</p>
                                </div>
                            </Link>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/napa-valley">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/Napa-Valley-Explore.jpg')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Napa Valley event, wedding & meeting venues</h1>
                                    <p>Check availability of venues in the Napa Valley</p>
                                </div>
                            </Link>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/sonoma-county">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/Sonoma-County-Explore.jpg')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Sonoma County event, wedding & meeting venues</h1>
                                    <p>Check availability of venues in Sonoma County</p>
                                </div>
                            </Link>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/san-francisco">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/San-Francisco-Explore.jpg')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>San Francisco event, wedding & meeting venues</h1>
                                    <p>Check availability of venues in San Francisco</p>
                                </div>
                            </Link>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/wine-country">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/Wine-Country-Explore.jpg')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Wine Country event, wedding & meeting venues</h1>
                                    <p>Check availability of venues in the Wine Country</p>
                                </div>
                            </Link>
                        </div>
                       
                        
                        <div className="image-wrapper">
                            <Link to="/sonoma">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/Sonoma-Explore.jpg')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Sonoma event, wedding & meeting venues</h1>
                                    <p>Check availability of venues in Sonoma</p>
                                </div>
                            </Link>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/napa">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/Napa-Explore.jpg')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Napa event, wedding & meeting venues</h1>
                                    <p>Check availability of venues in Napa</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* Event Type Block */}
                    <div className="location-address border-line">
                        <div className="heading-text block">
                            <h1 className="heading-text">Event Type</h1>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/corporate-events">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/location-image-2.png')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Find a Venue for Your Corporate Event</h1>
                                    <p>Discover great corporate event venues</p>
                                </div>
                            </Link>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/weddings">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/location-image-3.png')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Find a Venue for Your Wedding</h1>
                                    <p>Discover great wedding venues.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/meetings">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/location-image-4.png')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Find a Venue for Your Meeting</h1>
                                    <p>Discover great meeting venues</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* Venue Type Block */}
                    <div className="location-address border-line">
                        <div className="heading-text block">
                            <h1 className="heading-text">Venue Type</h1>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/venues-unique-event-space">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/location-image-5.png')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Top Unique Event Spaces</h1>
                                    <p>Discover unique spaces for your next meeting, event, or wedding.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="image-wrapper">
                            <Link to="/venues-winery">
                                <div className="image-block">
                                    <img className="" src={require('../assets/images/location-image-6.png')} alt="FIRST-HOLD" />
                                </div>
                                <div className="image-text-block">
                                    <h1>Top Winery Event Venues</h1>
                                    <p>Discover unique wineries for your next meeting, event, or wedding.</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <Footer />
           </div>
           <Footers />
        </div>
    );
}
export default explore;

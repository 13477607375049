import { Record } from 'immutable';

import {
  GET_UPDATE_EVENT_REQUEST,
  GET_UPDATE_EVENT_FAILURE,
  GET_UPDATE_EVENT_SUCCESS,
} from 'shared/state/types';

class State extends Record({
  updateEventData:"",
  error:'',
  sending:'',
  sended:'',
  success:'',
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_UPDATE_EVENT_REQUEST:
      return state.merge({
        updateEventData:{},
        error:'',
        success:'',
        sending:true,
        sended:false
      });
    case GET_UPDATE_EVENT_FAILURE:
      return state.merge({
        updateEventData:{},
        error: action.error,
        sending:false,
        success:'',
        sended:false


      });
    case GET_UPDATE_EVENT_SUCCESS:
      return state.merge({
        updateEventData:'',
        success:'Event updated successfully',
        error:'',
        sending:false,
        sended:true
      });
    default:
      return state;
  }
}


import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import './assets/style/ModalBox.scss';


const styles = theme => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    color: '#9e9e9e',
    right: '8px',
  },
});
withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
  },
}))(MuiDialogActions);

class ModalBox extends React.Component {
  state = {
    open: true,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };
  handleClosepopup = () => {
    this.props.stopLoader();
    this.setState({ open: false });

  };

  handleClose = () => {
    this.props.stopLoader();
    this.setState({ open: false });
    this.props.onClickNo(this.props.id);
  };

  handleClickYes = () => {
    this.props.stopLoader();
    this.handleClosepopup();
    this.props.onClickYes();
  };

  render() {

    return (
      <div>
        {/* <Button variant="outlined" color="secondary" onClick={this.handleClickOpen}>
          Open dialog
        </Button> */}
        <Dialog className="success_modal_container"
          // onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open} >
                <DialogActions className="dialogActions">
                    <span
                    className="material-icons close closeButton"
                    onClick={this.handleClosepopup}
                    >
                    close
                    </span>
                </DialogActions>
          <DialogContent className="dialog-content" style={{minHeight:'auto'}} >
            <Grid container className="modalBox_content">
              <Grid item xs={12}>
                <Grid container className="boxMatter">
                  <Grid item xs={12}>&nbsp;</Grid>
                  <Grid item xs={12}>
                    <div className="dont_single">HMMMM…</div>
                  </Grid>
                  <Grid item xs={12}>&nbsp;</Grid>
                  <Grid item md={12} sm={12}>
                    <div className="dont_p">
                      {/* The event details you entered fall outside the parameters of this venue, you may want to check the   */}
                    {/* {this.props.error=="Space seating & cpacity  does not match to your event guest count." ? " Guess Count"
 : this.props.error=="Space timeslot availability does not match to your event timings." ? " Event Times"
: this.props.error=="This event space is already booked for 06/01/2020, please consider if for another date."?"dates":" availability "} */}
                        {this.props.error}
                    <br />
                    <br />
                    Would you like to create a new event or edit this one?
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <DialogActions className="action-center padding-left">
                          <Button className="button-bg"  onClick={this.handleClickYes}>
                            New
                          </Button>
                      </DialogActions>
                      </Grid>
                      <Grid item xs={6}>
                        <DialogActions className="action-center padding-right">
                          <Button className="button-bg"  onClick={this.handleClose}>
                            Edit
                          </Button>
                      </DialogActions>
                      </Grid>
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>&nbsp;</Grid>

            </Grid>

          </DialogContent>



        </Dialog>


      </div>
    );
  }
}

export default ModalBox;

import React, {Component} from "react";
import CustomizedCheckBox from "../../../shared/components/CustomizedCheckbox";
import CustomSelectWithScroll from "../../../shared/components/CustomSelectWithScroll";
import "../assets/styles/search.scss";

import {Formik, Form} from "formik";
import PropTypes from "prop-types";
import {Grid, Icon, Button} from "@material-ui/core";
import {Redirect} from "react-router-dom";
import {getSpace} from "../../../../src/data/spaces/actions";
import {userDetails,venueList} from "../../../shared/venueList/actions";
import {getMicrosoftCalendaEvents} from "../../../../src/data/venues/actions/getMicrosoftCalendaEvents";
import {getVenue} from "../../../../src/data/venues/actions";
import {saveGoogleCalendaEvents} from '../../../../src/data/venues/actions/saveGoogleCalendaEvents';
import {getMicrosoftToken} from '../../../../src/data/venues/actions/getMicrosoftToken';
import {getHoldBookedSpaceEvents } from "../../../../src/data/venues/actions";
import VenueSpaces  from "../../../../src/venueOwner/components/venueSpaces";
import SyncCheckBox from "../../../shared/components/SyncCheckbox";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import moment from "moment";
let fhwithArray = [];
let fhwithOutArray = [];
let optionsHold = [];
let formData = {};
let sendWithArray =[];
let sendWithOutArray =[];
let secondScreenDropArr = [];
let checkSelect=[];
let holdBookedEevents = [];
var gsDayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

class Search extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleValeChange = this.handleValeChange.bind(this);
  }
  state = {
    spaceId: "",
    vanueId: "",
    eventsShow: 1,
    eventItems: "",
    eventMaxHold: "",
    eventNext: 1,
    satetHolds: "",
    holdVal: [],
    holdValWithOut: [],
    selectedOption: "",
    eventWithArry: [],
    eventWithOutArry: [],
    venueArr:[],
    loading:true,
    venuenSpaces:false,
    selectedSpaces:[],
    selectedSpaceIndex:0,
    selectedSpaceType:1   
  };
  componentWillMount() {
    fhwithArray = []
    fhwithOutArray = []
    sendWithArray = []
    sendWithOutArray = []
    secondScreenDropArr = []
    optionsHold = []
    let t=this;
    holdBookedEevents = [];
    const calendarId = decodeURIComponent(this.props.match.params.calendarId);
    const spaceId    = this.props.match.params.spaceId.toString();
    this.props.venueList().then(() => {}).catch(error =>{});    
    this.props
      .getSpace(this.props.match.params.spaceId)
      .then(res => {
        if (res.ms_calendar_id) {
            this.setState({
              vanueId: res.venue_id
            }); 
            
          this.props.
          getHoldBookedSpaceEvents(this.props.match.params.spaceId).then(hbEvents=>{   
            
            hbEvents.data.map(function (arr) {               
              holdBookedEevents.push({title:arr.event.name,date:arr.date,start_time:arr.event.start_time});
            }); 
            
            this.props
            .getMicrosoftToken()
            .then(response => {
                this.props
                .getMicrosoftCalendaEvents(calendarId)
                .then(eventsResponse => {                 
                    if (eventsResponse && eventsResponse.value.length>0) {
                    let stateArray = [];
                    for (let i = 0; i < eventsResponse.value.length; i++) {
                        let dbToken=false;
                        for(let j=0;j < res.event_timeslots.length; j++){
                        if(eventsResponse.value[i].subject==res.event_timeslots[j].event.name){
                            dbToken=true;
                            break;
                        }
                        
                        }
                        if(dbToken==false){
                        stateArray.push(eventsResponse.value[i]);
                        }
                    }                       
                    secondScreenDropArr.push(stateArray);
                    let itemsArray = [];
                    
                    itemsArray.push(stateArray);
                    this.setState({
                        eventItems: itemsArray
                    });

                    if (this.state.eventItems.length >0) {

                        this.setState({
                        eventsShow: 2
                        });
                        this.props
                        .getVenue(this.state.vanueId)
                        .then(response => {
                            
                            this.setState({venueArr: response});

                            this.setState({
                            eventMaxHold: response.data.max_hold_limit
                            });
                            if (this.state.eventMaxHold) {
                            optionsHold.push({
                                id: "booked",
                                name: "Booked"
                            });
                            optionsHold.push({
                                id: "blocked",
                                name: "Blocked"
                            });
                            for (let i = 1; i <= this.state.eventMaxHold; i++) {
                                let opLabel = this.ordinal_suffix_of(i) + " Hold";
                                optionsHold.push({
                                id: opLabel,
                                name: opLabel
                                });
                            }                                                            
                            this.setState({
                                satetHolds: optionsHold
                            });
                            }
                            let eventstatus = "";
                            let status = [];
                            let statusWihOut = [];
                            this.state.eventItems.map(function (data) {

                            data.map(function (eventArr) {

                                if (eventArr.subject !== undefined) {

                                let summaryData = eventArr.subject.toUpperCase();

                                eventstatus = "";
                                if (optionsHold.length > 0) {                                

                                    optionsHold.map(function (data2) {

                                    if (
                                        summaryData.includes(
                                        data2.name.toUpperCase()
                                        ) === true
                                    ) {
                                        eventstatus = data2.id;
                                    }

                                    return eventstatus;
                                    });
                                }
                                }

                                if (eventstatus.toString() !== "") {
                                let date = "";
                                let time = ""; let sTime = ""; let eTime =""; let sDate =""; let eDate="";
                                let startDate = eventArr.start.dateTime && !eventArr.isAllDay?new Date(moment.utc(eventArr.start.dateTime).local()):new Date(moment.utc(eventArr.start.dateTime).local());
                                let endDate = eventArr.end.dateTime && !eventArr.isAllDay?new Date(moment.utc(eventArr.end.dateTime).local()):new Date(moment.utc(eventArr.end.dateTime).local().subtract(1, "days"));

                                
                                let dateFormat = 'MM/DD/YY';
                                if(moment(moment(startDate).format(dateFormat),dateFormat,true).isValid()===true)
                                {
                                    if(calendarId.includes('holiday')){
                                    startDate.setDate(startDate.getDate() + 1);

                                    }
                                    sDate = `${moment(startDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                    sTime =  eventArr.start.dateTime?`${moment(startDate, 'hh:mm A', true).format("hh:mm a")}`:'';

                                    if(sTime && sTime[0].toString()==='0')
                                    {
                                    sTime=sTime.replace('0','');

                                    }
                                    sTime=sTime.replace('m','.m.');
                                    if(sDate[0] && sDate[0].toString()==='0'){
                                    sDate=sDate.replace('0','');
                                    }
                                    sDate=sDate.replace('/0','/')

                                }
                                if(moment(moment(endDate).format(dateFormat),dateFormat,true).isValid()===true)
                                {

                                    eDate = `${moment(endDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                    eTime =  eventArr.end.dateTime?`${moment(endDate, 'hh:mm A', true).format("hh:mm a")}`:'';

                                if(eTime && eTime[0].toString()==='0'){
                                    eTime=eTime.replace('0','');

                                }
                                    eTime=eTime.replace('m','.m.');
                                    if(eDate[0] && eDate[0].toString()==='0'){
                                    eDate=eDate.replace('0','');
                                    }
                                    eDate=eDate.replace('/0','/')

                                }
                                if (sDate === eDate) {
                                    var d = new Date(sDate);
                                    var dayName = gsDayNames[d.getDay()];
                                    date = dayName + " " +sDate;
                                } else {
                                    date = sDate + " - " + eDate;   
                                }
                                if (sTime === eTime && !eventArr.isAllDay) {
                                    time = sTime;
                                } else if(!eventArr.isAllDay){
                                    time = sTime + " - " + eTime;
                                }
                                let title='';
                                if(eventArr.subject==='')
                                {
                                    title = eventArr.description;
                                }
                                else
                                {
                                    title = eventArr.subject;
                                }

                                if(title && title.toString()!=='' && date && date.toString()!=='')
                                {

                                    let recurrence='';                                     
                                    if(eventArr.recurrence){
                                      recurrence = eventArr.recurrence;
                                    }
                                    else{
                                      recurrence='';
                                    }

                                    let eSDate = `${moment(startDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                                    let searchObj = holdBookedEevents.find(event => (event.title === eventArr.subject &&  event.date === eSDate ));
                                    if(searchObj==undefined){  
                                    sendWithArray.push(eventArr);
                                    status.push(eventstatus);                                  
                                    fhwithArray.push({
                                        id:eventArr.id,
                                        recurrence:recurrence,
                                        reason: eventArr.subject,
                                        date: date,
                                        time: time,
                                        status: eventstatus,
                                        spaces:[spaceId]
                                    });
                                    }
                                }

                                } 
                                else {

                                
                                let date = "";
                                let time = ""; let sTime = ""; let eTime =""; let sDate =""; let eDate="";
                                let startDate = eventArr.start.dateTime && !eventArr.isAllDay?new Date(moment.utc(eventArr.start.dateTime).local()):new Date(moment.utc(eventArr.start.dateTime).local());
                                let endDate   = eventArr.end.dateTime && !eventArr.isAllDay?new Date(moment.utc(eventArr.end.dateTime).local()):new Date(moment.utc(eventArr.end.dateTime).local().subtract(1, "days"));
                                let dateFormat = 'MM/DD/YY';

                            

                                if(moment(moment(startDate).format(dateFormat),dateFormat,true).isValid()===true)
                                {
                                    if(calendarId.includes('holiday')){
                                    startDate.setDate(startDate.getDate() + 1);

                                    }
                                    sDate = `${moment(startDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                    sTime =  eventArr.start.dateTime?`${moment(startDate, 'hh:mm A', true).format("hh:mm a")}`:'';

                                    if(sTime && sTime[0].toString()==='0')
                                    {

                                    sTime=sTime && sTime.replace('0','');
                                    }
                                    sTime=sTime && sTime.replace('m','.m.');

                                    if(sDate && sDate[0].toString()==='0'){
                                    sDate=sDate.replace('0','');
                                    }

                                    sDate=sDate.replace('/0','/')


                                }
                                if(moment(moment(endDate).format(dateFormat),dateFormat,true).isValid()===true)
                                {

                                    eDate = `${moment(endDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                    eTime =  eventArr.end.dateTime?`${moment(endDate, 'hh:mm A', true).format("hh:mm a")}`:'';

                                    if(eTime && eTime[0].toString()==='0')
                                    {
                                    eTime=eTime.replace('0','');

                                    }

                                    eTime=eTime && eTime.replace('m','.m.');
                                    if(eDate && eDate[0].toString()==='0'){
                                    eDate=eDate.replace('0','');
                                    }
                                    eDate=eDate.replace('/0','/')

                                }
                    
                                if (sDate === eDate) {
                                    d = new Date(sDate);
                                    dayName = gsDayNames[d.getDay()];
                                
                                    date = dayName + " " +sDate;
                                } else {
                                    date = sDate + " - " + eDate;                                      
                                }
                                if (sTime === eTime && !eventArr.isAllDay) {
                                    time = sTime;
                                } else if(!eventArr.isAllDay) {
                                    time = sTime + " - " + eTime;
                                }
                        
                                let title='';
                                if(eventArr.subject==='')
                                {
                                    title = eventArr.description;
                                }
                                else
                                {
                                    title = eventArr.subject;
                                }
                                let recurrence='';                                   
                                if(eventArr.recurrence){
                                    recurrence = eventArr.recurrence;
                                }
                                else{
                                  recurrence='';
                                }

                                if(title && title.toString()!=='' && date && date.toString()!=='')
                                {
                                    let eSDate = `${moment(startDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                                    let searchObj = holdBookedEevents.find(event => (event.title === title &&  event.date === eSDate ));
                                    if(searchObj==undefined){
                                    statusWihOut.push(eventstatus);
                                    sendWithOutArray.push(eventArr);
                                    fhwithOutArray.push({
                                        id:eventArr.id,
                                        recurrence:recurrence,
                                        reason: title,
                                        date: date,
                                        time: time,
                                        status: eventstatus,
                                        spaces: [spaceId]
                                    });
                                    }
                                }                                
                                }                                  
                                return fhwithArray;
                            });
                            return fhwithArray;
                            });
                            this.setState({ holdVal: status });
                            this.setState({ holdValWithOut: statusWihOut });
                            this.setState({
                            loading:false
                            })
                            this.setState({
                            eventWithArry: fhwithArray
                            });
                            this.setState({
                            eventWithOutArry: fhwithOutArray
                            });


                            if (this.state.eventsShow === 2) {
                            let fhWithArrLength = this.state.eventWithArry.length;
                            if (
                                fhWithArrLength === 0 &&
                                parseInt(this.state.eventNext) === 1
                            ) {
                                this.setState({
                                eventNext: 2
                                });
                            }
                            }
                        })
                        .catch(error => 
                            {

                            }
                            );
                    }
                    } else {
                    this.setState({
                        eventNext: 3
                    });
                    this.setState({
                        eventsShow: 2
                    });
                    
                    }

                })
                .catch(error => {

                });
            })
            .catch(error => {

            });

          }).catch(error => {

          });   
                        
        }
        else{
          this.setState({eventsShow: 2, eventNext:3});
        }
      })
      .catch(error => this.setState({eventsShow: 2, eventNext:3,loading:false}));
     
  }
  handleValeChange = (event,index) => {
    const newSatate = this.state.holdVal;
    newSatate[index] = event.target.value;
    this.setState({ holdVal: newSatate });
      
  };
  handleValeWithOutChange = (event,index) => {
    const newSatate = this.state.holdValWithOut;
    newSatate[index] = event.target.value;
    this.setState({ holdValWithOut: newSatate });
      
  };

  setRedirect = () => {
    this.setState({
      redirect: true
    });
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={'/spaceProfileEdit/'+this.props.match.params.spaceId} />
     
    }
  };
  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }
  handleClick = () => {
    this.setState({ eventNext: 2 });
  };
  handleNextDiv = () => {
    if (document.getElementById("firstDivNextBtn")) {
      document.getElementById("firstDivNextBtn").click();
    }
  };
  handleSubmit = (values, actions) => {
        let saveEvntArr = [];
        const calendarId=this.props.match.params.calendarId;          
        localStorage.removeItem('googleCalendarVal');
          if (sendWithArray.length > 0) {
            let holdRank = '1';
            let holdType = '';
            let sTime = '';
            let eTime = '';
            let sDate = '';
            let eDate = '';
            let stateHoldVal = this.state.holdVal;
            let t=this;
            sendWithArray.map(function (data, index) {
              if (stateHoldVal[index] !== '') {
                sTime = data.start.dateTime && !data.isAllDay?`${moment.utc(data.start.dateTime).local().format("HH:mm:ss")}`:'-';
                eTime = data.end.dateTime && !data.isAllDay?`${moment.utc(data.end.dateTime).local().format("HH:mm:ss")}`:'-';
                sDate = data.start.dateTime && !data.isAllDay?`${moment.utc(data.start.dateTime).local().format("YYYY-MM-DD")}`:`${moment.utc(data.start.dateTime).local().format("YYYY-MM-DD")}`;
                eDate = data.end.dateTime && !data.isAllDay?`${moment.utc(data.end.dateTime).local().format("YYYY-MM-DD")}`:`${moment.utc(data.end.dateTime).local().subtract(1, "days").format("YYYY-MM-DD")}`;

                if (data.subject && data.subject.toUpperCase().includes('hold'.toUpperCase()) === true) {
                  
                  let holdData = stateHoldVal[index].replace(/(\d+)(st|nd|rd|th)/, "$1");
                    let getStr1 = holdData.split(' ');
                    if (parseInt(getStr1[0]) === 1) {
                      holdRank = 1;
                    }
                    if (parseInt(getStr1[0]) === 2) {
                      holdRank = 2;
                    }
                    if (parseInt(getStr1[0]) === 3) {
                      holdRank = 3;
                    }
                    if (parseInt(getStr1[0]) === 4) {
                      holdRank = 4;
                    }
                    if (parseInt(getStr1[0]) === 5) {
                      holdRank = 5;
                    }
                    if (parseInt(getStr1[0]) === 6) {
                      holdRank = 6;
                    }
                    if (parseInt(getStr1[0]) === 7) {
                      holdRank = 7;
                    }

                  holdType = 1;
                }
                if (data.subject && data.subject.toUpperCase().includes('challenge'.toUpperCase()) === true) {
                  let challengeStep = '';
                  challengeStep = stateHoldVal[index];
                  let getStr = challengeStep.split('_hc');
                  holdRank = getStr[0].replace(/(\d+)(st|nd|rd|th)/, "$1");
                  holdType = 2;
                }
                if (data.subject && data.subject.toUpperCase().includes('booked'.toUpperCase()) === true) {
                  if (stateHoldVal[index] === 'booked') {
                    holdRank = 3;
                  }
                  holdType = 3;
                }
                if (data.subject && data.subject.toUpperCase().includes('Block'.toUpperCase()) === true) {
                  if (stateHoldVal[index] === 'blocked') {
                    holdRank = 4;
                  }
                  holdType = 4;
                }
                let title = '';
                if (data.subject && data.subject === '') {
                  title = data.description;
                } else {
                  title = data.subject;
                }              

                let titleName = '';
                if (title !== '' && title !== undefined) {
                  titleName = title;
                } else {
                  titleName = '--';
                }

                let recurrence='';
                if(calendarId.includes('holiday')){
                  recurrence=["RRULE:FREQ=YEARLY"];
                }
                if(checkSelect.length>0){
                  for(let i=0;i<checkSelect.length;i++){
                    if(data.id===checkSelect[i].id){
                      if(calendarId.includes('holiday')){
                        recurrence=["RRULE:FREQ=YEARLY"];
                      }
                      else{
                        if(checkSelect[i].rec){
                          recurrence=checkSelect[i].rec;
                        }
                      }
                      break;
                    }
                  }
                }
                saveEvntArr.push({
                  recurrence:recurrence,
                  name: titleName,
                  city: '--',
                  state: '--',
                  start_date: sDate,
                  start_time: sTime,
                  end_date: eDate,
                  end_time: eTime,
                  hold_rank: holdRank,
                  hold_type: holdType,
                  space_id:fhwithArray[index].spaces.join(',')
                })
              }
              return saveEvntArr;
            });

          }
          
          if (saveEvntArr.length > 0) {
            formData = {
              "data"        : saveEvntArr,
              "space_id"    : this.props.match.params.spaceId,
              "sync"        : "1",
              "is_microsoft": "1"
            };

            this.props.saveGoogleCalendaEvents(formData)
              .then(res => {
                this.handleClick();
              })
              .catch(error => {})

          } else {
            this.handleClick();
          }

      }


  handleSave = async (values, actions) => {
    let saveEvntArr =[];
    let t=this;
    const calendarId=this.props.match.params.calendarId;  
      localStorage.removeItem('googleCalendarVal');
      if (sendWithOutArray.length > 0) {
        let holdRank = '1';
        let holdType = '';
        let sTime = '';
        let eTime = '';
        let sDate = '';
        let eDate = '';
        let stateholdValWithOut = this.state.holdValWithOut;
        sendWithOutArray.map(function (data, index) {
          if (stateholdValWithOut[index] !== '') {
            sTime = data.start.dateTime && !data.isAllDay?`${moment.utc(data.start.dateTime).local().format("HH:mm:ss")}`:'-';
            eTime = data.end.dateTime && !data.isAllDay?`${moment.utc(data.end.dateTime).local().format("HH:mm:ss")}`:'-';
            sDate = data.start.dateTime && !data.isAllDay?`${moment.utc(data.start.dateTime).local().format("YYYY-MM-DD")}`:`${moment.utc(data.start.dateTime).local().format("YYYY-MM-DD")}`;
            eDate = data.end.dateTime && !data.isAllDay?`${moment.utc(data.end.dateTime).local().format("YYYY-MM-DD")}`:`${moment.utc(data.end.dateTime).local().subtract(1, "days").format("YYYY-MM-DD")}`;

            if (stateholdValWithOut[index] && stateholdValWithOut[index].toUpperCase().includes('hold'.toUpperCase()) === true) {
              let holdData = stateholdValWithOut[index].replace(/(\d+)(st|nd|rd|th)/, "$1");
              let getStr1 = holdData.split(' ');

              if (parseInt(getStr1[0]) === 1) {
                holdRank = 1;
              }
              
              if (parseInt(getStr1[0]) === 2) {
                holdRank = 2;
              }
              
              if (parseInt(getStr1[0]) === 3) {
                holdRank = 3;
              }
              if (parseInt(getStr1[0]) === 4) {
                holdRank = 4;
              }
              if (parseInt(getStr1[0]) === 5) {
                holdRank = 5;
              }
              if (parseInt(getStr1[0]) === 6) {
                holdRank = 6;
              }
              if (parseInt(getStr1[0]) === 7) {
                holdRank = 7;
              }
              
              holdType = 1;
            }
            if (stateholdValWithOut[index] && stateholdValWithOut[index].toUpperCase().includes('challenge'.toUpperCase()) === true) {
              let challengeStep = '';
              challengeStep = stateholdValWithOut[index];
              let getStr = challengeStep.split('_hc');
              if(getStr[0].replace(/(\d+)(st|nd|rd|th)/, "$1"))
              {
                holdRank = getStr[0].replace(/(\d+)(st|nd|rd|th)/, "$1");
              }
              
              holdType = 2;
            }
            if (stateholdValWithOut[index] && stateholdValWithOut[index].toUpperCase().includes('booked'.toUpperCase()) === true) {
              if (stateholdValWithOut[index] === 'booked') {
                holdRank = 3;
              }
              
              holdType = 3;
            }
            if (stateholdValWithOut[index] && stateholdValWithOut[index].toUpperCase().includes('blocked'.toUpperCase()) === true) {
              if (stateholdValWithOut[index] === 'blocked') {
                holdRank = 4;
              }
              
              holdType = 4;
            }
            let title ='';
                if(data.subject && data.subject==='')
                {
                  title = data.description;
                }else
                {
                  title = data.subject;
                }

                let hRank1 = '';
                if(holdRank==='')
                {
                  hRank1 =0;
                }
                else
                {
                  hRank1 =holdRank;
                }
                
                let titleName='';
                if(title && title!=='' && title!==undefined)
                {
                  titleName = title;
                }
                else
                {
                  titleName = '--';
                }
                let recurrence='';
                if(calendarId.includes('holiday')){
                  recurrence=["RRULE:FREQ=YEARLY"];
                }
                if(checkSelect.length>0){
                  for(let i=0;i<checkSelect.length;i++){
                    if(data.id===checkSelect[i].id){
                      if(calendarId.includes('holiday')){
                        recurrence=["RRULE:FREQ=YEARLY"];
                      }
                      else{
                        if(checkSelect[i].rec){
                          recurrence=checkSelect[i].rec;

                        }
                        
                      }
                      break;
                    }
                  }
                }
                saveEvntArr.push({
                  recurrence:recurrence,
                  name: titleName,
                  city: '--',
                  state: '--',
                  start_date: sDate,
                  start_time: sTime,
                  end_date: eDate,
                  end_time: eTime,
                  hold_rank: hRank1,
                  hold_type: holdType,
                  space_id:fhwithOutArray[index].spaces.join(',')
                })
              
          }
          return saveEvntArr;
        });

      }
     
      if (saveEvntArr.length > 0) {
        formData = {
          "data"            : saveEvntArr,
          "space_id"        : this.props.match.params.spaceId,
          "sync"            : "1",
          "is_microsoft"    : "1"
        };
        this.props.saveGoogleCalendaEvents(formData)
          .then(res => {
            this.props.history.push('/spaceProfileEdit/' + this.props.match.params.spaceId)
          })
          .catch(error => {})
      } else {
        this.props.history.push('/spaceProfileEdit/' + this.props.match.params.spaceId)
      }  
  }

  onSelectChange=(e,r,t,value)=>{
       if(!this.props.match.params.calendarId.includes(`holiday`)){
        this.setState({disabled:true})
        if(e.target.value.toString()==='' || e.target.value.toString()==='0' ||  e.target.value.toString()==='false') { 
            let recurrence = value.recurrence;  
            if(recurrence)   {      
              if('pattern' in recurrence && 'range' in recurrence){              
                if(recurrence.pattern.type == "absoluteYearly"){
                  if(recurrence.range.type=='endDate'){
                    checkSelect.push({id:t,rec:['RRULE:FREQ=YEARLY;UNTIL='+(recurrence.range.endDate.replaceAll('-',''))]});                  
                  }else{
                    checkSelect.push({id:t,rec:['RRULE:FREQ=YEARLY']});
                  }  
                }else if(recurrence.pattern.type == "weekly"){
                  if(recurrence.range.type=='endDate'){
                    checkSelect.push({id:t,rec:['RRULE:FREQ=WEEKLY;UNTIL='+(recurrence.range.endDate.replaceAll('-',''))]});
                  }else if(recurrence.range.numberOfOccurrences > 0){                 
                    checkSelect.push({id:t,rec:['RRULE:FREQ=WEEKLY;COUNT='+recurrence.range.numberOfOccurrences]});
                  }else{
                    checkSelect.push({id:t,rec:['RRULE:FREQ=WEEKLY']});
                  } 
                }else if(recurrence.pattern.type == "daily"){
                  if(recurrence.range.type=='endDate'){
                    checkSelect.push({id:t,rec:['RRULE:FREQ=DAILY;UNTIL='+(recurrence.range.endDate.replaceAll('-',''))]});
                  }else if(recurrence.range.numberOfOccurrences > 0){                 
                    checkSelect.push({id:t,rec:['RRULE:FREQ=DAILY;COUNT='+recurrence.range.numberOfOccurrences]});
                  }else{
                    checkSelect.push({id:t,rec:['RRULE:FREQ=DAILY']});
                  } 
                }else if(recurrence.pattern.type == "absoluteMonthly"){
                  if(recurrence.range.type=='endDate'){
                    checkSelect.push({id:t,rec:['RRULE:FREQ=MONTHLY;UNTIL='+(recurrence.range.endDate.replaceAll('-',''))]});
                  }else if(recurrence.range.numberOfOccurrences > 0){                 
                    checkSelect.push({id:t,rec:['RRULE:FREQ=MONTHLY;COUNT='+recurrence.range.numberOfOccurrences]});
                  }else{
                    checkSelect.push({id:t,rec:['RRULE:FREQ=MONTHLY']});
                  } 
                }
              }
            }
            this.setState({disabled:false}) 
        }
        else{
          this.setState({disabled:false})
          checkSelect=checkSelect.filter(data => data.id!==t);
        }
    }

    console.log('sss',checkSelect)
  }

  onMultipleSpace = (e,type,index) =>{
    let sSpaces = [];
    if(type == 1){
      sSpaces = this.state.eventWithArry[index].spaces;
    }
    if(type == 2){
      sSpaces = this.state.eventWithOutArry[index].spaces;
    }
    this.setState({venuenSpaces:true,selectedSpaceIndex:index,selectedSpaceType:type,selectedSpaces:sSpaces});
  }

  onModalClose = ()=>{
    this.setState({venuenSpaces:false,selectedSpaces:[]});
  }

  onSpaceApplied = (value) =>{
    if(this.state.selectedSpaceType == 1){
      let withArray = this.state.eventWithArry;
      withArray[this.state.selectedSpaceIndex].spaces = value;
      this.setState({eventWithArry:withArray});
    }
    if(this.state.selectedSpaceType == 2){
      let withOutArray = this.state.eventWithOutArry;
      withOutArray[this.state.selectedSpaceIndex].spaces = value;
      this.setState({eventWithOutArry:withOutArray});
    }
    this.setState({venuenSpaces:false,selectedSpaces:[]});
  }

  render() {    
    return (
      <div>
              {this.state.eventsShow === 1 ? (
                <div className="home_page">
                  <div className="search_page">
                    <Icon className="icon1">search</Icon>
                    <h1>Searching for holds…</h1>
                    <p>
                      Please standby while FIRST-HOLD searches for any existing
                      holds in your calendar. We will assign a hold status to
                      any holds we find.{" "}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="home_page syncing_calendar">
                  <div className="search_page"></div>
                  <Grid container>  
                  {this.state.eventNext === 3 ? (
                    <Grid container>
                     <Grid container className="main-form">
                        <Grid container className="main-form">
                          <Grid item md={8} lg={8} xl={8}>
                            <p>
                              No event found in this calendar.
                            </p>                            
                          </Grid>  
                          <Grid item md={8} lg={8} xl={8}>
                          {this.renderRedirect()}
                          <button disabled={this.state.disabled} onClick={this.setRedirect}>Next</button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) :(
                  <Grid container>
                    {this.state.eventNext === 1 ? (
                      <Grid container className="main-form">
                          < Formik
                          initialValues = {
                            {
                              eventhold: []
                            }
                          }
                          onSubmit = {
                              (values, actions) => {
                                this.handleSubmit(values, actions);

                              }
                            } >
                            {
                              ({
                                isSubmitting,
                                values,
                                handleBlur,
                                handleChange,
                                handleValeChange,
                                setFieldValue
                              }) => ( 
                                <Form>
                  {this.state.eventWithArry.length>0?

            
                        <Grid container className="main-form">
                          <Grid item md={8} lg={8} xl={8}>                           
                            <p>We found {this.state.eventWithArry.length} {this.state.eventWithArry.length > 1?'events':'event'} with a FIRST-HOLD Status.</p>
                            <p class="second-line-text">They have been assigned a status on your FIRST-HOLD calendar.</p>
                            <p class="third-line-text">Review and makes edit below.</p>
                          </Grid>
                          <Grid item md={12} lg={12} xl={12}>
                            <h3>FIRST-HOLD Status</h3>

                          </Grid>
                          {this.state.eventWithArry.map((value, index) => {
                            
                            return (
                              <Grid container className="data-table">
                              <Grid
                                  item
                                  md={3}
                                  lg={3}
                                  xl={3}
                                  className="vertical_align_date"
                                >
                                  <h5>{value.date}</h5>
                                  <p className="small">{value.time}</p>
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  lg={3}
                                  xl={3}
                                  className="vertical_align_reason"
                                >
                                  <h6>{value.reason}</h6>
                                </Grid>
                                <Grid item md={6} lg={6} xl={6}>
                                  <CustomSelectWithScroll
                                    setFieldValue={setFieldValue}
                                    value={this.state.holdVal[index]}
                                    placeholder="Select"
                                    options={this.state.satetHolds}
                                    handleChange={e => {
                                      handleChange(e);
                                      this.handleValeChange(e,index);
                                    }}
                                    handleBlur={e => {
                                      handleBlur(e);
                                      this.handleValeChange(e,index);
                                    }}
                                   name= {`eventhold_.${index}`}
                                    label="Select" key={index}
                                  />
                                  <CustomizedCheckBox
                                      
                                       name={value.id}                                            
                                       onChange={this.onSelectChange}
                                       handleChange={handleChange}
                                       values={value}
                                       disabled={this.state.disabled} 
                                    label="Apply status to all repeat instances of this event"
                                  />
                                   <SyncCheckBox                                      
                                      name={"space-"+value.id}   
                                      onChange={this.onMultipleSpace}  
                                      handleChange={handleChange}
                                      values={value.spaces}
                                      type={1}
                                      index={index}
                                      label={value.spaces.length > 1 ? 'Applied to '+value.spaces.length+' spaces': 'Apply status to more spaces'}
                                    />
                                </Grid>
                              </Grid>
                            );
                          })}
                          <Grid item md={8} lg={8} xl={8}>
                            <Button type="submit"
                              variant="outline-danger"
                              id="firstDivNextBtn"
                              class="red-button float-left"
                              disabled={this.state.disabled}>
                              Next
                            </Button>
                          </Grid>
                        </Grid>
                        :
                        this.state.loading==false && this.state.eventWithArry.length==0?
                        <div>No Events Found</div>:
                        <div>Loading...</div>}

                        </Form>

                      )}
                      </Formik>
                      </Grid>
                    ) : (
                      <Grid container className = "main-form" >
                      <Formik
                            initialValues={{
                            eventholdWithOut: []
                            }}
                            onSubmit={(values, actions) => {
                            this.handleSave(values, actions);

                            }}
                            >
                            {({
                            isSubmitting,
                            values,
                            handleBlur,
                            handleChange,
                            handleValeChange,
                            setFieldValue
                            }) => (
                            <Form>
                   {this.state.eventWithOutArry.length>0?

                        <Grid container className="main-form">
                            <Grid item md={8} lg={8} xl={8}>
                            <p>
                              The following events were not assigned a hold
                              status. You can manually assign them a status now,
                              if applicable, before proceeding.
                            </p>                                
                            </Grid>
                            <Grid item md={12} lg={12} xl={12}>
                              <h3>FIRST-HOLD Status</h3>
                            </Grid>

                            {this.state.eventWithOutArry.map((value2, index2) => {
                              return (
                                <Grid container className="data-table">
                                    <Grid
                                      item
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      className="vertical_align_date_holiday"
                                    >
                                      <h5>{value2.date}</h5>
                                      <p className="small">{value2.time}</p>
                                    </Grid>
                                    <Grid
                                      item
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      className="vertical_align_reason"
                                    >
                                      <h6>{value2.reason}</h6>
                                    </Grid>
                                    <Grid item md={6} lg={6} xl={6}>
                                          <CustomSelectWithScroll
                                            value={this.state.holdValWithOut[index2]}
                                            setFieldValue={setFieldValue}
                                            placeholder="Select"
                                            options={this.state.satetHolds}
                                            handleChange={e => {
                                              handleChange(e);
                                              this.handleValeWithOutChange(e,index2);
                                            }}
                                            handleBlur={e => {
                                              handleBlur(e);
                                              this.handleValeChange(e,index2);
                                            }}
                                            name= {`eventholdWithOut_.${index2}`}
                                            label="Select" key={index2}
                                          />

                                          <CustomizedCheckBox 
                                            name={value2.id}
                                            onChange={this.onSelectChange}
                                            handleChange={handleChange}
                                            values={value2}
                                            disabled={this.state.disabled} 
                                            label="Apply status to all repeat instances of this event"
                                          />
                                            <SyncCheckBox                                      
                                            name={"space-"+value2.id} 
                                            onChange={this.onMultipleSpace}  
                                            type={2}
                                            index={index2}
                                            handleChange={handleChange}
                                            values={value2.spaces}
                                            label={value2.spaces.length > 1 ? 'Applied to '+value2.spaces.length+' spaces': 'Apply status to more spaces'}
                                          />
                                      </Grid>
                                </Grid>
                              );
                            })
                            }
                            <Grid item md={8} lg={8} xl={8}>
                            <Button type="submit"
                                variant="outline-danger"
                                id="firstDivNextBtn"
                                class="red-button float-left"
                                disabled={this.state.disabled}>
                                Next
                              </Button>
                            </Grid>
                            
                        </Grid>
                        :
                        this.state.loading==false && this.state.eventWithOutArry.length==0?
                        <div>No Events Found</div>:
                        <div>Loading...</div>}
                        </Form>
                            )}
                          </Formik>
                      </Grid>
                    )}
                    
                  </Grid>
                  )}

                  </Grid>
                  
                </div>
              )}

              {
                this.state.venuenSpaces? <VenueSpaces open={this.state.venuenSpaces} spaceId={this.props.match.params.spaceId} selectedSpaces={this.state.selectedSpaces} onSpaceApplied={this.onSpaceApplied}   venues={this.props.list} onClose={this.onModalClose}  /> : null
              }
            
      </div>
    );
  }
}
Search.propTypes = {
  classes: PropTypes.object.isRequired,
  venueList: PropTypes.func.isRequired
};
const mapStateToProps = state => {
  return {
    list: state.data.usersVenue.venueList,
  };
};

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    dispatch =>
      bindActionCreators(
        {
          userDetails,
          getMicrosoftToken,
          getSpace,
          getVenue,
          getMicrosoftCalendaEvents,
          saveGoogleCalendaEvents,
          getHoldBookedSpaceEvents,
          venueList
        },
        dispatch
      )
  )
);
export default enhance(Search);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import MainHeader from '../shared/headers/container/mainHeader.js';
import Layout from 'layout';
import { bootstrap } from 'shared/auth/actions';
import { getStateAuth } from 'shared/auth/selectors';
import { getKeys} from 'data/master/actions';
import  CustomizedSnackbar from './components/customizedSnackbar'

import {loadStripe} from '@stripe/stripe-js';
import { Helmet } from "react-helmet";
// import {Elements, StripeProvider} from 'react-stripe-elements';
import { Elements } from '@stripe/react-stripe-js';
import ReactGA from 'react-ga';
import '../index.css';
import '../global.scss';
import '../index.css';


class App extends Component {

  static propTypes = {
    bootstrap: PropTypes.func,
    auth: PropTypes.object,
  };

  UNSAFE_componentWillMount() {
    this.props.getKeys().then((response) => {
      localStorage.setItem('stripe_key',response)
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);      
      if(this.props.location && decodeURIComponent(this.props.location.search).includes('venuePlan') && localStorage.getItem('type')!='1'){
        let redirectUrl = decodeURIComponent(this.props.location.search).split('?redirect=');        
        localStorage.setItem('redirect_path',redirectUrl[1]);
      }
      console.log = console.warn = console.error = () => {};
    })
    
    let arr=[];
      let token=false;   
      let urlValue=this.props.location.search;    
      if(urlValue.includes('widget')){
        let arr=urlValue.split('&');
        for(let i=0;i<arr.length;i++){
          if(arr[i].includes('widget')){
          token=true;
          let widget=arr[i].split('=');
          localStorage.setItem("widget",widget[1]);
          break;
          }
        }
      }
      if(token==false){
        localStorage.removeItem('widget');
      }
  }

 

  render() {
    const stripePromise = localStorage.getItem('stripe_key') && loadStripe(localStorage.getItem('stripe_key'));
    const { auth } = this.props;
    return (
      <div className="App">
       <Helmet>
          <title>Unique Venues For Events And Meetings | FIRST-HOLD</title>
          <meta name="description" content="Looking for unique venues for events or meetings? FIRST-HOLD has a wide selection of unique venues that will make your event or meeting unforgettable."></meta>
          <meta name="robots" content="index"></meta>
          <link rel="canonical" href={`${window.location.href}`} />
          <meta property="og:title" content="Unique Venues For Events And Meetings | FIRST-HOLD" />
          <meta property="og:description" content="Looking for unique venues for events or meetings? FIRST-HOLD has a wide selection of unique venues that will make your event or meeting unforgettable." />
          <meta property="og:url" content="https://www.first-hold.com/" />
          <meta property="og:image" content="https://www.first-hold.com/static/media/LogoWithText.6a00fa2d.png" />
          <meta property="og:type" content="website" />
          <meta name="publisher" content="FIRST-HOLD" />
        </Helmet>
        <Layout>
          <MainHeader />
          <div className="Content">
          <Elements stripe={stripePromise}>
            {React.cloneElement(this.props.children, { authData: auth })}
          </Elements>
            <CustomizedSnackbar />
          </div>
        </Layout>
      </div>
    );
  }
}


const enhance = compose(
  withRouter,
  connect(
    state => ({
      auth: getStateAuth(state),
      stripeKey: state.data.master.stripeKey,
    }),
    dispatch => bindActionCreators({
      bootstrap,
      getKeys
    }, dispatch),
  )
)

export default enhance(App);

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { object } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import "./assets/style/placeHold.scss";




const DialogContent = withStyles(theme => ({
  root: {
    paddingBottom:0
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
  },
}))(MuiDialogActions);

class ErrorPopup extends React.Component {
  state = {
    open: this.props.openError,
  };


  handleClose = () => {
    this.props.errorClose();
    this.setState({ open: false })
  };

  render() {

    return (
      <div className="h">


        <Dialog

          className="success_modal_container hold_settings forgot-something-popup"
          aria-labelledby="customized-dialog-title"
          open={this.state.open} >
          <DialogActions className="dialogActions">
            <span
              className="material-icons close closeButton"
              onClick={this.handleClose}
            >
              close
            </span>
          </DialogActions>
          <DialogContent className="dialog-content">
            {this.props.message?(<Grid container className="modalBox_content">
              <Grid item xs={12}>
                <p className='dialouge_text'>{this.props.message}</p>
              </Grid>
            </Grid>):this.props.EventTemple?
            (<Grid container className="modalBox_content">
              <Grid item xs={12}>
                <p className='dialouge_heading'>Event Temple Credentials Needed</p>
                <p className='dialouge_text'>Scroll down to <b>Set-up Event Temple</b>  and enter required Event Temple credentials.</p>
                <a href='https://firsthold.groovehq.com/help/how-do-i-sync-first-hold-with-tripleseat' className='blue-text' target='_blank' >Have Questions?</a>
              </Grid>
            </Grid>):
          (<Grid container className="modalBox_content">
          <Grid item xs={12}>
            <p className='dialouge_heading'>Tripleseat Credentials Needed</p>
            <p className='dialouge_text'>Scroll down to <b>Set-up Tripleseat</b>  and enter required Tripleseat credentials.</p>
            <a href='https://firsthold.groovehq.com/help/how-do-i-sync-first-hold-with-tripleseat' className='blue-text' target='_blank' >Have Questions?</a>
          </Grid>
        </Grid>)
            }

          </DialogContent>


        </Dialog>

      </div>
    );
  }
}

ErrorPopup.propTypes = {
  classes: object.isRequired,
};

const mapStateToProps = state => {
  return {

  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {

      },
      dispatch
    )
  )
);
export default enhance(ErrorPopup);


import { VENUE_TYPES } from 'shared/state/types';
import { request } from 'shared/auth';

function venueTypesRequested() {
  return {
    type:VENUE_TYPES.REQUEST,
  };
}

function venueTypesFailed(error) {
  return {
    type:VENUE_TYPES.FAILURE,
    error:error,
  };
}

function venueTypesSuccess(data) {
  return {
    type:VENUE_TYPES.SUCCESS,
    venueTypeList: data,
  };
}

export function venueTypes() {
  return dispatch => {
    dispatch(venueTypesRequested());

    return request().get('/venue_types')
      .then(response =>{
          dispatch(venueTypesSuccess(response.data.data))
          return response.data.data;
        }
      )
      .catch(errors => {
        dispatch(venueTypesFailed(errors.response.data.message));
        throw errors;
      });
  };
}

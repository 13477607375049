import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { compose } from 'redux';

import CustomCheckBox from '../../../shared/components/CustomCheckBox';
import PricingCategorySelect from './pricingCategorySelect';
import CurrencyInput from '../../../shared/components/CurrencyInput';

import { Grid, Hidden, IconButton, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { FieldArray, ErrorMessage, Field } from 'formik';

import '../assets/styles/addSpace.scss';
// import { NONAME } from 'dns';
let timeSlotId
const styles = theme => ({
    root: {
        border: 'none',
        fontFamily: 'Raleway',
        padding: '33px 12px 12px 12px',

        '&$disabled': {
            background: '#ffffff',
            boxShadow: 'none',
        },
        '&:hover': {
            background: '#ffffff',
            border: 'none',
        }
    },
    disabled: {
    },
    icon: {
        fontSize: '16px',
        '&$disabled': {
            color: '#ee2738'
        },
        paddingTop: '1px',
        color: '#b0b0b0'
    },
    addIcon: {
        fontSize: '16px',
        '&$enabled':{
            color:'#ee2738',
        },
        '&$disabled': {
            color: '#b0b0b0'
        },
    },

    inactiveIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px',
        '&$hover': {
            color: '#1397e1',
        },
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    helpIcon:{
        borderRadius: '6px'
    },
    // icon: {
    //     paddingTop: '1px',
    //     color: '#b0b0b0',
    //     fontSize: '15px'
    // },
    // activeIcon: {
    //     marginLeft: '8px',
    //     paddingTop: '1px',
    //     color: '#1397e1',
    //     fontSize: '15px'
    // },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    Popover:{
        boxShadow:'none'
    },
    typography: {
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1'
    },
    paper: {
        width: '237px',
        height: 'auto',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        boxShadow:'none',
        fontFamily: 'Raleway',
        backgroundColor: '#ffffff'
      }
});

const names = [
    'Venue Rental',
    'Food & Bev Minimum',
    'Venue Rental + Food & Bev Minimum',
    'Per Person'
];

const handleAddEvent = (spaceData,eventName, arrayHelpers, rentalType, arrayName, rentalTypeArray, fieldName, setFieldValue, eventId, updateField, timeSlots) =>{
    const startTimeVal = moment(rentalTypeArray.startTime, "h:mm A").format("HH:mm:ss")
    const endTimeVal = moment(rentalTypeArray.endTime, "h:mm A").format("HH:mm:ss")
    let daysArray = []
    if(rentalTypeArray.monday){
        daysArray.push('monday')
    }
    if(rentalTypeArray.tuesday){
        daysArray.push('tuesday')
    }
    if(rentalTypeArray.wednesday){
        daysArray.push('wednesday')
    }
    if(rentalTypeArray.thursday){
        daysArray.push('thursday')
    }
    if(rentalTypeArray.friday){
        daysArray.push('friday')
    }
    if(rentalTypeArray.saturday){
        daysArray.push('saturday')
    }
    if(rentalTypeArray.sunday){
        daysArray.push('sunday')
    }
    // spaceData && spaceData.timeslots && spaceData.timeslots.map((obj, index) => {
    timeSlots.map((obj, index) =>{
        if (obj.startTime.toString() === rentalTypeArray.startTime.toString() && obj.endTime.toString() === rentalTypeArray.endTime.toString() && rentalTypeArray.days.sort().join(',')=== obj.days.sort().join(',')) {
            timeSlotId = obj.id
        }
        return timeSlotId;
    })
    var arrayLen = arrayName.filter(function (eventSpaceRatesArray) {
        return eventSpaceRatesArray.pricingTypeName === '' &&
        eventSpaceRatesArray.pricingType === 0 
    }).length
    const seqNo = arrayName.length + 1;
    
    arrayHelpers.push({ seqNo:seqNo,id:'', flatRate: '',additionalFlatRate:'', hasAddFlatRate:false, hasAddPricingTypeName:false, additionalRate: '', days: daysArray ,eventName:eventName,eventId: eventId, additionalCost: false, sunday: rentalTypeArray.sunday ? true : false, monday: rentalTypeArray.monday ? true : false, tuesday: rentalTypeArray.tuesday ? true : false, wednesday: rentalTypeArray.wednesday ? true : false, thursday: rentalTypeArray.thursday ? true : false, friday: rentalTypeArray.friday ? true : false, saturday: rentalTypeArray.saturday ? true : false, pricingTypeName: '',pricingType: 0, rentalType: rentalType, idx:arrayLen + 1, startTime: rentalTypeArray.startTime, endTime: rentalTypeArray.endTime, timeSlotId: timeSlotId, additionalPricingTypeName: '',fnbRate:'', minGuest:'',up:'no'})
    setFieldValue(fieldName,'' )
    setFieldValue(updateField, 'yes')
}

function EventPricing(props) {
const { classes } = props;
const [anchorEl, setAnchorEl] = React.useState(null);

const handleClick = event => {
  setAnchorEl(anchorEl ? null : event.currentTarget);
};

const handleClose = () => {
    setAnchorEl(null);
};

const handleChangeAdditionalFlatRate = (e, index) => {
    if(e.target.value.length > 0){
        props.setFieldValue(`eventSpaceRatesArray.${index}.hasAddFlatRate`, true)
    }
    else{
        props.setFieldValue(`eventSpaceRatesArray.${index}.hasAddFlatRate`, false)
    }
}

const handleChangeAdditionalPricingTypeName = (e,index) => {
    if(e.target.value.length > 0){
        props.setFieldValue(`eventSpaceRatesArray.${index}.hasAddPricingTypeName`, true)
    }
    else{
        props.setFieldValue(`eventSpaceRatesArray.${index}.hasAddPricingTypeName`, false)
    }
}
const open = Boolean(anchorEl);
const id = open ? 'simple-popper' : undefined;
    // const { anchorElFixedAddOn } = props;
    // const openFixedAddOn = Boolean(anchorElFixedAddOn);
    var sortedEventArray = props.values.eventSpaceRatesArray  
    sortedEventArray.sort(function(a, b) { 
    return a.idx- b.idx;
    })
    function getArrayLength (rentalType,startTime,endTime, days, id) {
        var eventArray = []
        props.values.eventSpaceRatesArray.forEach(function(o){
            if (o.eventName === props.eventName && o.rentalType === rentalType && o.startTime === startTime && o.endTime === endTime && o.timeSlotId === id && days.sort().join(',')=== o.days.sort().join(',') ) {
                eventArray.push(o)
            }} );
        const arrayLength = eventArray.length
        return arrayLength
    }
    return (
        <div>
            {(props.openForm && props.values && props.values.fixedValue && props.values.fixedRentalAvailability) &&
                props.values.fixedRentalAvailability.map((fixedRental, index) => (
                  
                    <Grid container className="formula" key={fixedRental.id}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Grid container justifyContent="flex-start">
                                <Grid item className="formula_heading">
                                    {props.eventName}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Grid container className="time_alignment">
                                <Grid item className="time_slot">Time Slot:</Grid>
                                <Grid item className="time"><span className="space_availability_type">Fixed</span> { moment(fixedRental.startTime, "hh:mm").format("hh:mm A").replace(/^0+/, '')}-{moment(fixedRental.endTime, "hh:mm").format("hh:mm A").replace(/^0+/, '')} {props.fixedRentalAvailabilitySortedDays[index]}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="add_space_rates_form" container>
                                <Grid item></Grid>
                            </Grid>
                        </Grid>
                        <FieldArray
                            name="eventSpaceRatesArray"
                            render={arrayHelpers => (
                                <div className="rate_form_container">
                                    <div className="pricing_form">
                                        {props.values.eventSpaceRatesArray &&(props.values.eventSpaceRatesArray.length === 0 || !props.values.eventSpaceRatesArray.find(o => o.rentalType === 'fixed' && o.startTime === fixedRental.startTime && o.endTime === fixedRental.endTime && o.eventName === props.eventName && fixedRental.days.sort().join(',')=== o.days.sort().join(','))) &&
                                            <div>
                                                <Grid container className="pricing_category">
                                                    <Grid className="pricing_select" item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                        <PricingCategorySelect
                                                            placeholder="Select"
                                                            names={names}
                                                            name={`pricingCategory[${index}][0]`}
                                                            value=''
                                                            handleChange={props.handleChange}
                                                            handleBlur={props.handleBlur}
                                                            arrayHelpers={arrayHelpers}
                                                            allValues={props.values}
                                                            arrayValue={props.values.eventSpaceRatesArray}
                                                            rentalType="fixed"
                                                            startTime={fixedRental.startTime}
                                                            endTime={fixedRental.endTime}
                                                            setFieldValue={props.setFieldValue}
                                                            eventName={props.eventName}
                                                            eventId={props.eventId}
                                                            days={fixedRental.days}
                                                            timeSlots={props.values.fixedRentalAvailability}
                                                        />
                                                        <div className="error">{props.step4Error[0][0]}</div>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        }
                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === 'Venue Rental' && o.rentalType === 'fixed' && o.startTime === fixedRental.startTime && o.endTime === fixedRental.endTime && o.eventName === props.eventName && fixedRental.days.sort().join(',')=== o.days.sort().join(',') && fixedRental.id === o.timeSlotId) &&
                                            <div className="fixedCorporateFlat">
                                                <Grid container className="pricing_category">
                                                    <Grid className="pricing_select" item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                        <PricingCategorySelect
                                                            placeholder="Select"
                                                            names={names}
                                                            name={`pricingCategories.fixed[0]`}
                                                            value={props.values.pricingCategories.fixed[0]}
                                                            // handleChange={props.handleChange}
                                                            handleBlur={props.handleBlur}
                                                            arrayHelpers={arrayHelpers}
                                                            allValues={props.values}
                                                            arrayValue={props.values.eventSpaceRatesArray}
                                                            setFieldValue={props.setFieldValue}
                                                            rentalType="fixed"
                                                            arrayName="eventSpaceRatesArray"
                                                            startTime={fixedRental.startTime}
                                                            endTime={fixedRental.endTime}
                                                            eventName={props.eventName}
                                                            eventId={props.eventId}
                                                            days={fixedRental.days}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                </Grid>
                                                {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                    sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                        eventSpaceRatesArray.rentalType === 'fixed' && eventSpaceRatesArray.pricingTypeName === 'Venue Rental' && eventSpaceRatesArray.startTime === fixedRental.startTime && eventSpaceRatesArray.endTime === fixedRental.endTime && eventSpaceRatesArray.eventName === props.eventName && fixedRental.id === eventSpaceRatesArray.timeSlotId && fixedRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') &&
                                                        <Grid container className="nested_form_container position-relative" key={index}>
                                                            
                                                            <Hidden xsDown={true}>
                                                                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Hidden smUp={true}>
                                                                <Grid item xs={12}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item xs={2}>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Grid className="rate_container" item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                        <div className="input_label">Venue Rental</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.flatRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                />
                                                                            )}

                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.flatRate`} /></div>
                                                                    </Grid>
                                                                    {fixedRental.additionalCost &&
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                        <div className="input_label">Additional Hourly Rate</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.additionalRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Additional Hourly Rate"
                                                                                    type="text" 
                                                                                />
                                                                            )}

                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalRate`} /></div>
                                                                        <div className="field_hint"> Price per hour over minimum</div>
                                                                    </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                )}
                                            </div>
                                        }
                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === 'Food & Bev Minimum' && o.rentalType === 'fixed' && o.startTime === fixedRental.startTime && o.endTime === fixedRental.endTime && o.eventName === props.eventName && fixedRental.days.sort().join(',')=== o.days.sort().join(',') && fixedRental.id === o.timeSlotId) &&
                                            <div className="fixedCorporateFoodAndBev">
                                                <Grid container className="pricing_category pricing_type_top_padding">
                                                    <Grid className="pricing_select" item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                        <PricingCategorySelect
                                                            placeholder="Select"
                                                            names={names}
                                                            name={`pricingCategories.fixed[1]`}
                                                            value={props.values.pricingCategories.fixed[1]}
                                                            handleBlur={props.handleBlur}
                                                            arrayHelpers={arrayHelpers}
                                                            allValues={props.values}
                                                            arrayValue={props.values.eventSpaceRatesArray}
                                                            rentalType="fixed"
                                                            arrayName="eventSpaceRatesArray"
                                                            setFieldValue={props.setFieldValue}
                                                            startTime={fixedRental.startTime}
                                                            endTime={fixedRental.endTime}
                                                            eventName={props.eventName}
                                                            eventId={props.eventId}
                                                            days={fixedRental.days}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                </Grid>
                                                {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                    sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                        eventSpaceRatesArray.rentalType === 'fixed' && eventSpaceRatesArray.pricingTypeName === 'Food & Bev Minimum' && eventSpaceRatesArray.startTime === fixedRental.startTime && eventSpaceRatesArray.endTime === fixedRental.endTime && eventSpaceRatesArray.eventName === props.eventName && fixedRental.id === eventSpaceRatesArray.timeSlotId && fixedRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') &&
                                                        <Grid container className="nested_form_container position-relative" key={index}>
                                                            
                                                            <Hidden xsDown={true}>
                                                                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Hidden smUp={true}>
                                                                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item xs={2}>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Grid className="rate_container" item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                        <div className="input_label">Minimum Rate</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.flatRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                />
                                                                            )}
                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.flatRate`} /></div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                )}
                                            </div>
                                        }
                                        
                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === 'Venue Rental + Food & Bev Minimum' && o.rentalType === 'fixed' && o.startTime === fixedRental.startTime && o.endTime === fixedRental.endTime && o.eventName === props.eventName && fixedRental.days.sort().join(',')=== o.days.sort().join(',') && fixedRental.id === o.timeSlotId) &&
                                            <div className="fixedCorporateFlatAndFoodBev">
                                                <Grid container className="pricing_category pricing_type_top_padding">
                                                    <Grid className="pricing_select " item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === 'Venue Rental + Food & Bev Minimum' && o.rentalType === 'fixed'  && o.startTime === fixedRental.startTime && o.endTime === fixedRental.endTime && o.eventName === props.eventName && fixedRental.days.sort().join(',')=== o.days.sort().join(',')) &&
                                                            <PricingCategorySelect
                                                                placeholder="Select"
                                                                names={names}
                                                                name={`pricingCategories.fixed[2]`}
                                                                value={props.values.pricingCategories.fixed[2]}
                                                                handleBlur={props.handleBlur}
                                                                arrayHelpers={arrayHelpers}
                                                                allValues={props.values}
                                                                arrayValue={props.values.eventSpaceRatesArray}
                                                                rentalType="fixed"
                                                                arrayName="eventSpaceRatesArray"
                                                                setFieldValue={props.setFieldValue}
                                                                startTime={fixedRental.startTime}
                                                                endTime={fixedRental.endTime}
                                                                eventName={props.eventName}
                                                                eventId={props.eventId}
                                                                days={fixedRental.days}
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                </Grid>
                                                {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                    sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                        eventSpaceRatesArray.rentalType === 'fixed' && eventSpaceRatesArray.pricingTypeName === 'Venue Rental + Food & Bev Minimum' && eventSpaceRatesArray.startTime === fixedRental.startTime && eventSpaceRatesArray.endTime === fixedRental.endTime && eventSpaceRatesArray.eventName === props.eventName && fixedRental.id === eventSpaceRatesArray.timeSlotId && fixedRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') &&
                                                        <Grid container className="nested_form_container position-relative" key={index}>
                                                            
                                                            <Hidden xsDown={true}>
                                                                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Hidden smUp={true}>
                                                                <Grid item xs={12}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item xs={2}>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Grid className="venueRentalFields" item xs={12}>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                                        <div className="input_label">Venue Rental</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.flatRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                />
                                                                            )}

                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.flatRate`} /></div>
                                                                    </Grid>
                                                                    {fixedRental.additionalCost &&
                                                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                                            <div className="input_label">Additional Hourly Rate</div>
                                                                            <Field 
                                                                                className="input_field"
                                                                                name={`eventSpaceRatesArray.${index}.additionalRate`}
                                                                                render={({ field }) => (
                                                                                    <CurrencyInput
                                                                                        {...field}
                                                                                        placeholder="Additional Hourly Rate"
                                                                                        type="text" 
                                                                                    />
                                                                                )}

                                                                            />
                                                                            <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalRate`} /></div>
                                                                            <div className="field_hint"> Price per hour over minimum</div>
                                                                        </Grid>
                                                                    }
                                                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                                        <div className="input_label">F&B Minimum</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.fnbRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                />
                                                                            )}

                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.flatRate`} /></div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                )}
                                            </div>
                                        }
                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.rentalType === 'fixed' && o.pricingTypeName === 'Per Person' && o.startTime === fixedRental.startTime && o.endTime === fixedRental.endTime && o.eventName === props.eventName && fixedRental.days.sort().join(',')=== o.days.sort().join(',') && fixedRental.id === o.timeSlotId) &&
                                            <div className="fixedCorporatePerPerson">
                                                <Grid container className="pricing_category pricing_type_top_padding">
                                                    <Grid className="pricing_select" item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                        <PricingCategorySelect
                                                            placeholder="Select"
                                                            names={names}
                                                            name={`pricingCategories.fixed[3]`}
                                                            value={props.values.pricingCategories.fixed[3]}
                                                            handleBlur={props.handleBlur}
                                                            arrayHelpers={arrayHelpers}
                                                            allValues={props.values}
                                                            arrayValue={props.values.eventSpaceRatesArray}
                                                            rentalType="fixed"
                                                            arrayName="eventSpaceRatesArray"
                                                            setFieldValue={props.setFieldValue}
                                                            startTime={fixedRental.startTime}
                                                            endTime={fixedRental.endTime}
                                                            eventName={props.eventName}
                                                            eventId={props.eventId}
                                                            days={fixedRental.days}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                </Grid>
                                                {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                    sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                        eventSpaceRatesArray.rentalType === 'fixed' && eventSpaceRatesArray.pricingTypeName === 'Per Person' && eventSpaceRatesArray.startTime === fixedRental.startTime && eventSpaceRatesArray.endTime === fixedRental.endTime && eventSpaceRatesArray.eventName === props.eventName && fixedRental.id === eventSpaceRatesArray.timeSlotId && fixedRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') &&
                                                        <Grid container className="nested_form_container position-relative" key={index}>
                                                            
                                                            <Hidden xsDown={true}>
                                                                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Hidden smUp={true}>
                                                                <Grid item xs={12}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item xs={2}>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={10}>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Grid className="rate_container" item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                        <div className="input_label">Per Person</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.flatRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                />
                                                                            )}

                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.flatRate`} /></div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                        <div className="input_label">Minimum (Maximum based on Max Capacity)</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.minGuest`}
                                                                            type="text"
                                                                            placeholder="Guests"
                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.minGuest`} /></div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                )}
                                            </div>
                                        }
                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === '' && o.pricingType === 0 && o.rentalType === 'fixed' && o.startTime === fixedRental.startTime && o.endTime === fixedRental.endTime && o.eventName === props.eventName && o.pricingType !== '' && o.up === 'yes' && fixedRental.days.sort().join(',')=== o.days.sort().join(',') && o.timeSlotId === fixedRental.id) &&
                                            <div className="fixedCorporateFlat">
                                                {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                    sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                        eventSpaceRatesArray.rentalType === 'fixed' && eventSpaceRatesArray.pricingTypeName === '' && eventSpaceRatesArray.pricingType === 0 && eventSpaceRatesArray.startTime === fixedRental.startTime && eventSpaceRatesArray.endTime === fixedRental.endTime && eventSpaceRatesArray.eventName === props.eventName && eventSpaceRatesArray.up === 'yes' && fixedRental.id === eventSpaceRatesArray.timeSlotId && fixedRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') && 
                                                        <Grid container className="nested_form_container position-relative" key={index}>
                                                            <span className="material-icons close add_space_close closeButton" onClick={e => { props.deleteEvent && props.deleteEvent(eventSpaceRatesArray.seqNo, props.values, props.setFieldValue, props.eventName) }}>close</span>
                                                            <Grid className="rate_container" item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                        <Grid container>
                                                                        <Grid item className="ques-mark">
                                                                            <div className="input_label add_service">
                                                                                Additional Required Services (Optional)
                                                                            </div>
                                                    
                                                                            <Grid className="help_icon">
                                                                                <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                                                                    <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                                                            help_outline
                                                                                    </Icon>
                                                                                </Button>
                                                                                <Popover classes={{ root: classes.helpIcon }}
                                                                                    id={id}
                                                                                    open={open}
                                                                                    anchorEl={anchorEl}
                                                                                    onClose={handleClose}
                                                                                    anchorOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'center',
                                                                                    }}
                                                                                    transformOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'center',
                                                                                    }}
                                                                                >
                                                                                    <Typography className={classes.typography}> Example:  Audio Visual Minimum, Security Required, etc.</Typography>
                                                                                </Popover>
                                                                            </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.additionalPricingTypeName`}
                                                                            placeholder="Price Type Name" 
                                                                            type="text" 
                                                                            onChange={(e)=>{
                                                                                props.handleChange(e)
                                                                                handleChangeAdditionalPricingTypeName(e,index)
                                                                            }}
                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalPricingTypeName`} /></div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                        <Grid container className="optional">
                                                                            <Grid item>
                                                                                <div className="input_label add_service">
                                                                                    {/* Optional */} &nbsp;
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.additionalFlatRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                    onChange={(e)=>{
                                                                                        props.handleChange(e)
                                                                                        handleChangeAdditionalFlatRate(e,index)
                                                                                    }}
                                                                                />
                                                                            )}

                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalFlatRate`} /></div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                )}
                                            </div>
                                        }
                                    </div>
                                    {props.openForm && getArrayLength('fixed',fixedRental.startTime ,fixedRental.endTime, fixedRental.days, fixedRental.id) < 5 && props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === '' && o.pricingType === 0 && o.rentalType === 'fixed' && o.startTime === fixedRental.startTime && o.endTime === fixedRental.endTime && o.eventName === props.eventName && o.pricingType !== '' && o.up === 'no' && fixedRental.days.sort().join(',')=== o.days.sort().join(',') && o.timeSlotId === fixedRental.id) &&
                                        <Grid container className="add_variable">
                                            {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                    eventSpaceRatesArray.rentalType === 'fixed' && eventSpaceRatesArray.pricingTypeName === '' && eventSpaceRatesArray.pricingType === 0 && eventSpaceRatesArray.startTime === fixedRental.startTime && eventSpaceRatesArray.endTime === fixedRental.endTime && eventSpaceRatesArray.eventName === props.eventName  && eventSpaceRatesArray.up === 'no' && fixedRental.id === eventSpaceRatesArray.timeSlotId && fixedRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') &&
                                                        <>
                                                            <Grid item xs={12} sm={2} md={1} lg={1} xl={1} className="add_icon_container" >
                                                            {getArrayLength('fixed', fixedRental.startTime, fixedRental.endTime, fixedRental.days, fixedRental.id) < 4 &&
                                                                <IconButton classes={{ root: classes.root }}
                                                                    disabled={props.values.eventSpaceRatesArray[index].additionalPricingTypeName === ''}
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    onClick={e => {handleAddEvent(props.spaceData,props.eventName, arrayHelpers,'fixed',props.values.eventSpaceRatesArray, fixedRental,'anotherPricingVariable.fixedCorporate', props.setFieldValue, props.eventId,`eventSpaceRatesArray.${index}.up`, props.values.fixedRentalAvailability)}}    
                                                                >
                                                                    <Icon className={classes.addIcon}>add</Icon>
                                                              
                                                                </IconButton>
                                                            }    
                                                            </Grid>
                                                            <Grid item xs={12} sm={5} md={3} lg={3} xl={3}>
                                                                <Grid container>
                                                                    <Grid item className="ques-mark">
                                                                        <div className="input_label add_service">
                                                                            Additional Required Services (Optional)
                                                                        </div>
                                                                    
                                                                    <Grid className="help_icon">
                                                                        <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                                                            <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                                                    help_outline
                                                                            </Icon>
                                                                        </Button>
                                                                        <Popover classes={{ root: classes.helpIcon }}
                                                                            id={id}
                                                                            open={open}
                                                                            anchorEl={anchorEl}
                                                                            onClose={handleClose}
                                                                            anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                            }}
                                                                            transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                            }}
                                                                        >
                                                                            <Typography className={classes.typography}> Example:  Audio Visual Minimum, Security Required, etc.</Typography>
                                                                        </Popover>
                                                                    </Grid>
                                                                    </Grid>
                                                                </Grid>      
                                                                <Field 
                                                                    className="input_field"
                                                                    name={`eventSpaceRatesArray.${index}.additionalPricingTypeName`}
                                                                    placeholder="Another Variable" 
                                                                    type="text" 
                                                                    onChange={(e)=>{
                                                                        props.handleChange(e)
                                                                        handleChangeAdditionalPricingTypeName(e,index)
                                                                    }}
                                                                />
                                                                <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalPricingTypeName`} /></div>                       
                                                            </Grid>
                                                            <Grid className="additionalPricing" item xs={10} sm={5} md={3} lg={3} xl={3}>   
                                                                <Grid container className="optional">
                                                                    <Grid item>
                                                                        <div className="input_label add_service">
                                                                            {/* Optional */} &nbsp;
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>     
                                                                <Field 
                                                                    className="input_field"
                                                                    name={`eventSpaceRatesArray.${index}.additionalFlatRate`}
                                                                    render={({ field }) => (
                                                                        <CurrencyInput
                                                                            {...field}
                                                                            placeholder="Amount"
                                                                            type="text" 
                                                                            onChange={(e)=>{
                                                                                props.handleChange(e)
                                                                                handleChangeAdditionalFlatRate(e, index)
                                                                            }}
                                                                        />
                                                                    )}

                                                                />
                                                                <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalFlatRate`} /></div>
                                                                   
                                        </Grid>
                                                        </>
                                                ))
                                            )}
                                        </Grid>
                                    }
                                </div>
                            )}
                        />
                    </Grid>
                ))    
            }
            {(props.openForm && props.values && props.values.flexibleRental && props.values.flexibleRentalAvailability) &&
                props.values.flexibleRentalAvailability.map((flexibleRental, index) => (
                    <Grid container className="formula" key={index}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Grid container justifyContent="flex-start">
                                <Grid item className="formula_heading">
                                {props.eventName}:
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Grid container className="time_alignment">
                                <Grid item className="time_slot">Time Slot:</Grid>
                                <Grid item className="time"><span className="space_availability_type">Flexible</span> { moment(flexibleRental.startTime, "hh:mm").format("hh:mm A").replace(/^0+/, '')}-{moment(flexibleRental.endTime, "hh:mm").format("hh:mm A ").replace(/^0+/, '')}{props.flexibleRentalAvailabilitySortedDays[index]}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="add_space_rates_form" container>
                                <Grid item></Grid>
                            </Grid>
                        </Grid>
                        <FieldArray
                            name="eventSpaceRatesArray"
                            render={arrayHelpers => (
                                <div className="rate_form_container">
                                    <div className="pricing_form">
                                        {props.values.eventSpaceRatesArray && !props.values.eventSpaceRatesArray.find(o => o.rentalType === 'flexible' && o.startTime === flexibleRental.startTime && o.endTime === flexibleRental.endTime && o.eventName === props.eventName && flexibleRental.days.sort().join(',')=== o.days.sort().join(',')) &&
                                            <div>
                                                <Grid container className="pricing_category">
                                                    <Grid className="pricing_select" item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                        <PricingCategorySelect
                                                            placeholder="Select"
                                                            names={names}
                                                            name={`pricingCategory[${index}][1]`}
                                                            value=''
                                                            handleChange={props.handleChange}
                                                            handleBlur={props.handleBlur}
                                                            arrayHelpers={arrayHelpers}
                                                            allValues={props.values}
                                                            arrayValue={props.values.eventSpaceRatesArray}
                                                            rentalType="flexible"
                                                            startTime={flexibleRental.startTime}
                                                            endTime={flexibleRental.endTime}
                                                            setFieldValue={props.setFieldValue}
                                                            eventName={props.eventName}
                                                            eventId={props.eventId}
                                                            days={flexibleRental.days}
                                                            timeSlots={props.values.flexibleRentalAvailability}
                                                        />
                                                        <div className="error">{props.step4Error[props.idx][1]}</div>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        }
                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === 'Venue Rental' && o.rentalType === 'flexible' && o.startTime === flexibleRental.startTime && o.endTime === flexibleRental.endTime && o.eventName === props.eventName && flexibleRental.days.sort().join(',')=== o.days.sort().join(',') && flexibleRental.id === o.timeSlotId) &&
                                            <div className="flexibleCorporateFlat">
                                                <Grid container className="pricing_category">
                                                    <Grid className="pricing_select" item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                        <PricingCategorySelect
                                                            placeholder="Select"
                                                            names={names}
                                                            name={`pricingCategories.flexible[0]`}
                                                            value={props.values.pricingCategories.flexible[0]}
                                                            handleBlur={props.handleBlur}
                                                            arrayHelpers={arrayHelpers}
                                                            allValues={props.values}
                                                            arrayValue={props.values.eventSpaceRatesArray}
                                                            setFieldValue={props.setFieldValue}
                                                            rentalType="flexible"
                                                            arrayName="eventSpaceRatesArray"
                                                            startTime={flexibleRental.startTime}
                                                            endTime={flexibleRental.endTime}
                                                            eventName={props.eventName}
                                                            eventId={props.eventId}
                                                            days={flexibleRental.days}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                </Grid>
                                                {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                    sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                        eventSpaceRatesArray.rentalType === 'flexible' && eventSpaceRatesArray.pricingTypeName === 'Venue Rental' && eventSpaceRatesArray.startTime === flexibleRental.startTime && eventSpaceRatesArray.endTime === flexibleRental.endTime && eventSpaceRatesArray.eventName === props.eventName && flexibleRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') && flexibleRental.id === eventSpaceRatesArray.timeSlotId &&
                                                        <Grid container className="nested_form_container position-relative" key={index}>
                                                            
                                                            <Hidden xsDown={true}>
                                                                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Hidden smUp={true}>
                                                                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item xs={2}>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Grid className="rate_container" item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                        <div className="input_label">Venue Rental</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.flatRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                />
                                                                            )}

                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.flatRate`} /></div>
                                                                    </Grid>
                                                                    {flexibleRental.additionalCost &&
                                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                            <div className="input_label">Additional Hourly Rate</div>
                                                                            <Field 
                                                                                className="input_field"
                                                                                name={`eventSpaceRatesArray.${index}.additionalRate`}
                                                                                render={({ field }) => (
                                                                                    <CurrencyInput
                                                                                        {...field}
                                                                                        placeholder="Additional Hourly Rate"
                                                                                        type="text" 
                                                                                    />
                                                                                )}

                                                                            />
                                                                            <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalRate`} /></div>
                                                                            <div className="field_hint"> Price per hour over minimum</div>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                )}
                                            </div>
                                        }
                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === 'Food & Bev Minimum' && o.rentalType === 'flexible' &&  o.startTime === flexibleRental.startTime && o.endTime === flexibleRental.endTime && o.eventName === props.eventName && flexibleRental.days.sort().join(',')=== o.days.sort().join(',') && flexibleRental.id === o.timeSlotId) &&
                                            <div className="flexible_corporate_foodandbev">
                                                <Grid container className="pricing_category pricing_type_top_padding">
                                                    <Grid className="pricing_select" item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                        <PricingCategorySelect
                                                            placeholder="Select"
                                                            names={names}
                                                            name={`pricingCategories.flexible[1]`}
                                                            value={props.values.pricingCategories.flexible[1]}
                                                            handleBlur={props.handleBlur}
                                                            arrayHelpers={arrayHelpers}
                                                            allValues={props.values}
                                                            arrayValue={props.values.eventSpaceRatesArray}
                                                            rentalType="flexible"
                                                            setFieldValue={props.setFieldValue}
                                                            arrayName="eventSpaceRatesArray"
                                                            startTime={flexibleRental.startTime}
                                                            endTime={flexibleRental.endTime}
                                                            eventName={props.eventName}
                                                            eventId={props.eventId}
                                                            days={flexibleRental.days}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                </Grid>
                                                {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                    sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                        eventSpaceRatesArray.rentalType === 'flexible' && eventSpaceRatesArray.pricingTypeName === 'Food & Bev Minimum' && eventSpaceRatesArray.startTime === flexibleRental.startTime && eventSpaceRatesArray.endTime === flexibleRental.endTime && eventSpaceRatesArray.eventName === props.eventName && flexibleRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') && flexibleRental.id === eventSpaceRatesArray.timeSlotId &&
                                                        <Grid container className="nested_form_container position-relative" key={index}>
                                                            
                                                            <Hidden xsDown={true}>
                                                                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Hidden smUp={true}>
                                                                <Grid item xs={12}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item xs={2}>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Grid className="rate_container" item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                        <div className="input_label">Minimum Rate</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.flatRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                />
                                                                            )}

                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.flatRate`} /></div>
                                                                    </Grid>
                                                                    {flexibleRental.additionalCost &&
                                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                            <div className="input_label">Additional Hourly Rate</div>
                                                                            <Field 
                                                                                className="input_field"
                                                                                name={`eventSpaceRatesArray.${index}.additionalRate`}
                                                                                render={({ field }) => (
                                                                                    <CurrencyInput
                                                                                        {...field}
                                                                                        placeholder="Additional Hourly Rate"
                                                                                        type="text" 
                                                                                    />
                                                                                )}

                                                                            />
                                                                            <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalRate`} /></div>
                                                                            <div className="field_hint"> Price per hour over minimum</div>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                )}
                                            </div>
                                        }
                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === 'Venue Rental + Food & Bev Minimum' && o.rentalType === 'flexible' && o.startTime === flexibleRental.startTime && o.endTime === flexibleRental.endTime && o.eventName === props.eventName && flexibleRental.days.sort().join(',')=== o.days.sort().join(',') && flexibleRental.id === o.timeSlotId) &&
                                            <div className="fixed_corporate_flatandfoodbev">
                                                <Grid container className="pricing_category pricing_type_top_padding">
                                                    <Grid className="pricing_select " item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === 'Venue Rental + Food & Bev Minimum' && o.rentalType === 'flexible'&& o.startTime === flexibleRental.startTime && o.endTime === flexibleRental.endTime && o.eventName === props.eventName && flexibleRental.days.sort().join(',')=== o.days.sort().join(',') && flexibleRental.id === o.timeSlotId) &&
                                                            <PricingCategorySelect
                                                                placeholder="Select"
                                                                names={names}
                                                                name={`pricingCategories.flexible[2]`}
                                                                value={props.values.pricingCategories.flexible[2]}
                                                                handleBlur={props.handleBlur}
                                                                arrayHelpers={arrayHelpers}
                                                                allValues={props.values}
                                                                arrayValue={props.values.eventSpaceRatesArray}
                                                                rentalType="flexible"
                                                                arrayName="eventSpaceRatesArray"
                                                                setFieldValue={props.setFieldValue}
                                                                startTime={flexibleRental.startTime}
                                                                endTime={flexibleRental.endTime}
                                                                eventName={props.eventName}
                                                                eventId={props.eventId}
                                                                days={flexibleRental.days}
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                </Grid>
                                                {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                    sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                        eventSpaceRatesArray.rentalType === 'flexible' && eventSpaceRatesArray.pricingTypeName === 'Venue Rental + Food & Bev Minimum' && eventSpaceRatesArray.startTime === flexibleRental.startTime && eventSpaceRatesArray.endTime === flexibleRental.endTime && eventSpaceRatesArray.eventName === props.eventName && flexibleRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') && flexibleRental.id === eventSpaceRatesArray.timeSlotId &&
                                                        <Grid container className="nested_form_container position-relative" key={index}>
                                                            
                                                            <Hidden xsDown={true}>
                                                                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Hidden smUp={true}>
                                                                <Grid item xs={12}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item xs={2}>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Grid className="venueRentalFields" item xs={12}>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                                        <div className="input_label">Venue Rental</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.flatRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                />
                                                                            )}

                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.flatRate`} /></div>
                                                                    </Grid>
                                                                    {flexibleRental.additionalCost &&
                                                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                                            <div className="input_label">Additional Hourly Rate</div>
                                                                            <Field 
                                                                                className="input_field"
                                                                                name={`eventSpaceRatesArray.${index}.additionalRate`}
                                                                                render={({ field }) => (
                                                                                    <CurrencyInput
                                                                                        {...field}
                                                                                        placeholder="Additional Hourly Rate"
                                                                                        type="text" 
                                                                                    />
                                                                                )}

                                                                            />
                                                                            <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalRate`} /></div>
                                                                            <div className="field_hint"> Price per hour over minimum</div>
                                                                        </Grid>
                                                                    }
                                                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                                        <div className="input_label">F&B Minimum</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.fnbRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                />
                                                                            )}

                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                )}
                                            </div>
                                        }
                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.rentalType === 'flexible' && o.pricingTypeName === 'Per Person' && o.startTime === flexibleRental.startTime && o.endTime === flexibleRental.endTime && o.eventName === props.eventName && flexibleRental.days.sort().join(',')=== o.days.sort().join(',') && flexibleRental.id === o.timeSlotId) &&
                                            <div className="flexible_corporate_perperson">
                                                <Grid container className="pricing_category pricing_type_top_padding">
                                                    <Grid className="pricing_select" item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                        <PricingCategorySelect
                                                            placeholder="Select"
                                                            names={names}
                                                            name={`pricingCategories.flexible[3]`}
                                                            value={props.values.pricingCategories.flexible[3]}
                                                            handleBlur={props.handleBlur}
                                                            arrayHelpers={arrayHelpers}
                                                            allValues={props.values}
                                                            arrayValue={props.values.eventSpaceRatesArray}
                                                            rentalType="flexible"
                                                            arrayName="eventSpaceRatesArray"
                                                            setFieldValue={props.setFieldValue}
                                                            startTime={flexibleRental.startTime}
                                                            endTime={flexibleRental.endTime}
                                                            eventName={props.eventName}
                                                            eventId={props.eventId}
                                                            days={flexibleRental.days}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                    </Grid>
                                                </Grid>
                                                {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                    sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                        eventSpaceRatesArray.rentalType === 'flexible' && eventSpaceRatesArray.pricingTypeName === 'Per Person' && eventSpaceRatesArray.startTime === flexibleRental.startTime && eventSpaceRatesArray.endTime === flexibleRental.endTime && eventSpaceRatesArray.eventName === props.eventName && flexibleRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') && flexibleRental.id === eventSpaceRatesArray.timeSlotId &&
                                                        <Grid container className="nested_form_container position-relative" key={index}>
                                                            
                                                            <Hidden xsDown={true}>
                                                                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Hidden smUp={true}>
                                                                <Grid item xs={12}>
                                                                    <FieldArray
                                                                        name={`eventSpaceRatesArray.${index}.days`}
                                                                        render={arrayHelpers => (
                                                                            <Grid container spacing={8}>
                                                                                <Grid item xs={2}>
                                                                                    <div className="form_index">
                                                                                        {eventSpaceRatesArray.idx}
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={8}>
                                                                                    <div className="select_days">
                                                                                        Selected Days:
                                                                                        </div>
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='monday' label="Mon" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='tuesday' label="Tues" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='wednesday' label="Weds" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='thursday' label="Thurs" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='friday' label="Fri" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='saturday' label="Sat" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                                <Grid item xs={4} className="days">
                                                                                    <CustomCheckBox disabled={true} name='sunday' label="Sun" arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={eventSpaceRatesArray.days} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    />
                                                                    <div className="error days_error"><ErrorMessage name={`eventSpaceRatesArray.${index}.days`} /></div>
                                                                </Grid>
                                                            </Hidden>
                                                            <Grid className="rate_container" item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                        <div className="input_label">Per Person</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.flatRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                />
                                                                            )}

                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.flatRate`} /></div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                        <div className="input_label"> Minimum (Maximum based on Max Capacity)</div>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.minGuest`}
                                                                            type="text"
                                                                            placeholder="Guests"
                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.minGuest`} /></div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                )}
                                            </div>
                                        }
                                        {props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === '' && o.pricingType === 0 && o.rentalType === 'flexible' && o.startTime === flexibleRental.startTime && o.endTime === flexibleRental.endTime && o.eventName === props.eventName && o.up === 'yes' && flexibleRental.days.sort().join(',')=== o.days.sort().join(',') && flexibleRental.id === o.timeSlotId) &&
                                            <div className="fixedCorporateFlat">
                                                {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                    sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                        eventSpaceRatesArray.rentalType === 'flexible' && eventSpaceRatesArray.pricingTypeName === '' && eventSpaceRatesArray.pricingType === 0 && eventSpaceRatesArray.startTime === flexibleRental.startTime && eventSpaceRatesArray.endTime === flexibleRental.endTime && eventSpaceRatesArray.eventName === props.eventName && eventSpaceRatesArray.up === 'yes' && flexibleRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') && flexibleRental.id === eventSpaceRatesArray.timeSlotId &&
                                                        <Grid container className="nested_form_container position-relative" key={index}>
                                                            <span className="material-icons close add_space_close closeButton" onClick={e => { props.deleteEvent && props.deleteEvent(eventSpaceRatesArray.seqNo, props.values, props.setFieldValue, props.eventName) }}>close</span>
                                                            <Grid className="rate_container" item xs={12} sm={10} md={8} lg={8} xl={8}>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <Grid container>
                                                                        <Grid item className="ques-mark">
                                                                            <div className="input_label add_service">
                                                                                Additional Required Services (Optional)
                                                                            </div>
                                                    
                                                                            <Grid className="help_icon">
                                                                                <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                                                                    <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                                                            help_outline
                                                                                    </Icon>
                                                                                </Button>
                                                                                <Popover classes={{ root: classes.helpIcon }}
                                                                                    id={id}
                                                                                    open={open}
                                                                                    anchorEl={anchorEl}
                                                                                    onClose={handleClose}
                                                                                    anchorOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'center',
                                                                                    }}
                                                                                    transformOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'center',
                                                                                    }}
                                                                                >
                                                                                    <Typography className={classes.typography}> Example:  Audio Visual Minimum, Security Required, etc.</Typography>
                                                                                </Popover>
                                                                            </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.additionalPricingTypeName`}
                                                                            placeholder="Price Type Name" 
                                                                            type="text" 
                                                                            onChange={(e)=>{
                                                                                props.handleChange(e)
                                                                                handleChangeAdditionalPricingTypeName(e,index)
                                                                            }}
                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalPricingTypeName`} /></div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <Grid container className="optional">
                                                                        <Grid item>
                                                                            <div className="input_label add_service">
                                                                                {/* Optional */} &nbsp;
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                        <Field 
                                                                            className="input_field"
                                                                            name={`eventSpaceRatesArray.${index}.additionalFlatRate`}
                                                                            render={({ field }) => (
                                                                                <CurrencyInput
                                                                                    {...field}
                                                                                    placeholder="Amount" 
                                                                                    type="text" 
                                                                                    onChange={(e)=>{
                                                                                        props.handleChange(e)
                                                                                        handleChangeAdditionalFlatRate(e,index)
                                                                                    }}
                                                                                />
                                                                            )}

                                                                        />
                                                                        <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalFlatRate`} /></div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                )}
                                            </div>
                                        }
                                    </div>
                                    {props.openForm && getArrayLength('flexible',flexibleRental.startTime ,flexibleRental.endTime, flexibleRental.days, flexibleRental.id) < 5 && props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length >= 1 && props.values.eventSpaceRatesArray.find(o => o.pricingTypeName === '' && o.pricingType === 0 && o.rentalType === 'flexible' && o.startTime === flexibleRental.startTime && o.endTime === flexibleRental.endTime && o.eventName === props.eventName && o.up === 'no' && flexibleRental.days.sort().join(',')=== o.days.sort().join(',') && flexibleRental.id === o.timeSlotId) &&
                                        <Grid container className="add_variable">
                                             {(props.values.eventSpaceRatesArray && props.values.eventSpaceRatesArray.length > 0 && props.openForm) && (
                                                    sortedEventArray.map((eventSpaceRatesArray, index) => (
                                                        eventSpaceRatesArray.rentalType === 'flexible' && eventSpaceRatesArray.pricingTypeName === '' && eventSpaceRatesArray.pricingType === 0 && eventSpaceRatesArray.startTime === flexibleRental.startTime && eventSpaceRatesArray.endTime === flexibleRental.endTime && eventSpaceRatesArray.eventName === props.eventName &&  eventSpaceRatesArray.up === 'no' && flexibleRental.days.sort().join(',')=== eventSpaceRatesArray.days.sort().join(',') && flexibleRental.id === eventSpaceRatesArray.timeSlotId && 
                                                        <>
                                                            <Grid item xs={12} sm={2} md={1} lg={1} xl={1} className="add_icon_container" >
                                                                {getArrayLength('flexible',flexibleRental.startTime ,flexibleRental.endTime, flexibleRental.days, flexibleRental.id) < 4 &&
                                                                    <IconButton classes={{ root: classes.root }}
                                                                    disabled={props.values.eventSpaceRatesArray[index].additionalPricingTypeName === ''}
                                                                        variant="outlined"
                                                                        color="secondary"
                                                                        onClick={e => {handleAddEvent(props.spaceData,props.eventName, arrayHelpers,'flexible',props.values.eventSpaceRatesArray, flexibleRental,'anotherPricingVariable.fixedCorporate', props.setFieldValue, props.eventId,`eventSpaceRatesArray.${index}.up`, props.values.flexibleRentalAvailability)}}
                                                                    >
                                                                        <Icon className={classes.addIcon}>add</Icon>
                                                                    
                                                                    </IconButton>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12} sm={5} md={3} lg={3} xl={3}>
                                                                <Grid container>
                                                                    <Grid item className="ques-mark">
                                                                        <div className="input_label add_service">
                                                                            Additional Required Services (Optional)
                                                                        </div>
                                                                        <Grid className="help_icon">
                                                                            <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                                                            <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                                                    help_outline
                                                                            </Icon>
                                                                        </Button>
                                                                        <Popover classes={{ root: classes.helpIcon }}
                                                                            id={id}
                                                                            open={open}
                                                                            anchorEl={anchorEl}
                                                                            onClose={handleClose}
                                                                            anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                            }}
                                                                            transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                            }}
                                                                        >
                                                                            <Typography className={classes.typography}> Example:  Audio Visual Minimum, Security Required, etc.</Typography>
                                                                        </Popover>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Field 
                                                                    className="input_field"
                                                                    name={`eventSpaceRatesArray.${index}.additionalPricingTypeName`}
                                                                    placeholder="Another Variable"
                                                                    type="text" 
                                                                    onChange={(e)=>{
                                                                        props.handleChange(e)
                                                                        handleChangeAdditionalPricingTypeName(e,index)
                                                                    }}
                                                                />
                                                                <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalPricingTypeName`} /></div>
                                                                
                                                            </Grid>
                                                            <Grid className="additionalPricing" item xs={10} sm={5} md={3} lg={3} xl={3}>   
                                                                <Grid container className="optional">
                                                                    <Grid item>
                                                                        <div className="input_label add_service">
                                                                        {/* Optional */} &nbsp;
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>       
                                                                <Field 
                                                                    className="input_field"
                                                                    name={`eventSpaceRatesArray.${index}.additionalFlatRate`}
                                                                    render={({ field }) => (
                                                                        <CurrencyInput
                                                                            {...field}
                                                                            placeholder="Amount" 
                                                                            type="text" 
                                                                            onChange={(e)=>{
                                                                                props.handleChange(e)
                                                                                handleChangeAdditionalFlatRate(e,index)
                                                                            }}
                                                                        />
                                                                    )}

                                                                />
                                                                <div className="error"><ErrorMessage name={`eventSpaceRatesArray.${index}.additionalFlatRate`} /></div>                   
                                                            </Grid>
                                                        </>
                                                    ))
                                             )}
                                        </Grid>
                                    }
                                </div>
                            )}
                        />
                    </Grid>
                ))    
            }
        </div>
    );
}


const mapStateToProps = state => {
    return {
        spaceData: state.data.spaces.spaceData
    };
};

EventPricing.propTypes = {
    classes: object.isRequired,
};

const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        null
    ),
)

export default enhance(EventPricing);

import React from 'react';
import '../assets/style/press.scss';
import { Grid } from '@material-ui/core';
import "react-alice-carousel/lib/alice-carousel.css";
import Footers from '../../landingPage/components/footer';;

function press(props) {
    return (
        <div className="press-page-ui">
            <div className="press-banner-images">
                <p>In the news</p>
            </div>
            <div className="press-description-section container">
                <h3>Press</h3>
                <p class="blue-text"><span className="black-bold">FIRST-HOLD is the</span> leading event venue live availability technology solution.  
                   <br/> Connecting people planning events to venues with available dates.</p>
                <p className="light-text">For media questions </p>
                <div className="search-btn">
                    <a href="mailto: hello@first-hold.com">Contact</a>
                </div>
            </div>
            <div className="press-release-section">
               <div className="container">
                    <h3>Press Releases</h3>
                    <a href="/press-san-francisco-launch">FIRST-HOLD Launches Event Venue Live Availability Platform in San Francisco Bay Area  <span class="light-text">—March 31, 2023</span></a>
                    <br/>
                    <a href="/event-temple-to-revolutionize-the-events-industry">FIRST-HOLD partners with industry leader 
                    Event Temple to revolutionize the events industry<span class="light-text">—October 09, 2023</span></a>
               </div>
            </div>
           <Footers />
        </div>
    );
}
export default press;

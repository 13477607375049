import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import './assets/style/ModalBox.scss';


const styles = theme => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    color: '#9e9e9e',
    right: '8px',
  },
});

withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
  },
}))(MuiDialogActions);

class ExtensionPopup extends React.Component {
  state = {
    open: true,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
    // window.location.reload();
    //localStorage.setItem("extensionPopup",true);
   // this.props.close();
    if(this.props.closeExtrnlPopup)
    {
      localStorage.setItem("extensionCalenderPopup",true);
      this.props.closeExtrnlPopup();
    window.location.href=window.location.href;
    }
    else if(this.props.close)
    {
      localStorage.setItem("extensionPopup",true);
      this.props.close(); 
      window.location.href=window.location.href;

    }
  };
  
  redirect = () =>{
    if(this.props.closeExtrnlPopup)
{
this.props.closeExtrnlPopup();
}
if(this.props.close){
this.props.close();
}
    this.setState({ open: false });
    window.open("https://chrome.google.com/webstore/detail/first-hold/gbgllbpnnhldcnpkbfmiiikkbggjknke");
    }
  render() {

    return (
      <div>
        {/* <Button variant="outlined" color="secondary" onClick={this.handleClickOpen}>
          Open dialog
        </Button> */}
        <Dialog className="success_modal_container dont-miss-a sync-popup"
          // onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open} >
         <DialogActions className="dialogActions"><span className="material-icons close closeButton" onClick={this.handleClose}>close</span></DialogActions>

          <DialogContent className="dialog-content sign-up1">
            <Grid container className="modalBox_content">
              <Grid item xs={12}>
                <img alt='' className="modalimg" src="https://d31ogwdhz811go.cloudfront.net/uploads/defaultImage/5d6535c5232ff373404c90e2730fffee.jpg" />
              </Grid>
              <Grid item xs={12}>
                <Grid container className="boxMatter">
                  <Grid item xs={12}>&nbsp;</Grid>
                  <Grid item xs={12}>
                    <div className="dont_single">SYNC YOUR CALENDAR WITH FIRST-HOLD</div>
                  </Grid>
                  <Grid item xs={12}>&nbsp;</Grid>
                  <Grid item md={12} sm={12}>
                    <div className="dont_p centerClass">We need your approval before we can sync with your calendar.  
                        <br /><br />Download our Extension. 
                        <br />It’s Fast, Easy and FREE
                    </div>
                    <div className="dont_p centerClass">You can also manage your holds and see notifications in your browser window!</div>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <DialogActions className="action-center disp-none-sm">
                      <Button onClick={this.redirect} className="button-bg">
                        Download Extension
                          </Button>
                    </DialogActions>
                  </Grid>
               
                      <Grid item xs={12} md={12} sm={12} className="centerWord">
                      <span className="noThank_btn" onClick={this.handleClose} >Cancel</span>
                      </Grid>


                </Grid>
              </Grid>

              <Grid item xs={12}>&nbsp;</Grid>

            </Grid>

          </DialogContent>



        </Dialog>


      </div>
    );
  }
}

export default ExtensionPopup;

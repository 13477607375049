import React, {useRef} from 'react';
import moment from "moment";
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DayPicker, {DateUtils} from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {Grid} from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Select, MenuItem, Input } from '@material-ui/core';
import {object} from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router-dom";
import '../assets/styles/addRentalRate.scss';
import '../../../shared/components/assets/style/CustomCheckBox.scss';
import {ErrorMessage, Field, Formik} from 'formik';
import * as Yup from 'yup';
import CurrencyInput from '../../../shared/components/CurrencyInput';
import {addHolidayRate} from "../../../data/spaces/actions/addHolidayRate";
const styles = theme => ({
    centerAlignDialogActions: {
        justifyContent: 'center'
    },
    actionButton: {
        width: '25%',
        height: '42px',
        margin: '4px',
        borderRadius: '24px'
    },
    fieldset: {
        margin: '8px',
        width: '100%'
    },
    legend: {
        color: '#000000 !important',
        fontWeight: '600',
        width: '100%'
    },
    root: {
        fontSize: '15px',
        lineHeight: '1.07',
        textAlign: 'left',
        color: '#202020',
        textTransform: 'capitalize',
        '&:hover': {
            background: "#f7f7f7"
        },
        '&:focused': {
            background: "#f7f7f7"
        }
    },
    selectContainer: {
        borderRadius: '6px',
        border: 'solid 1px #e1e1e1',
        background: '#f7f7f7 !important',
        height: '36px',
        width: '96%',
        '&:hover': {
            backgroundColor: "#f7f7f7 !important"
        },
        '&:focused': {
            backgroundColor: "#f7f7f7 !important"
        }
    },
    select: {
        '&:hover': {
            backgroundColor: "#f7f7f7 !important"
        },
        '&:focus': {
            backgroundColor: "#f7f7f7 !important"
        }
    },
    placeholder: {
        fontFamily: 'Raleway',
        fontSize: '15px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.07',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#b0b0b0'
    },
    options: {
        fontFamily: 'Raleway',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#ee2738',
        borderBottom: '1px solid #f7f7f7',
        backgroundColor: '#ffffff',
    }
});
const ValidationSchema = Yup
    .object()
    .shape({
        event_type: Yup
            .array()
            .min(1, '*Required'),
        time_slots: Yup
            .array()
            .min(1, '*Required'),
        base_amount: Yup
            .string()
            .required('*Required'),
        food_item: Yup.string("*F&B Minimum must be a string"),
        min_guest: Yup.string().matches(/^[ 0-9]*$/,"Min guest must be a number").max(6,"Min Guest should be less than 1000000")
    });
class AddRentalRate extends React.Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this
            .handleDayClick
            .bind(this);
        this.state = {
            selectedDay: [],
            open: props.open,
            formOpen: false,
            calError: false,
            from:undefined,
            to : undefined
        };
    }

    handleDayClick(day, {selected}) {         
        let range = DateUtils.addDayToRange(day, this.state); 
        if(range.from && !range.to){
            range.to = range.from;  
        }  
        if (!range.from && !range.to) {
            this.setState({formOpen: false,calError: true});
        }
        this.setState(range);
                  
    }

    handleClose = () => {
        this.setState({open: false})
        this.props.close();
    };

    handleSubmit = (values, actions) => {
      
        let params = {};
        params.space_id         = this.props.match.params.spaceId;
        params.start_date       = moment(this.state.from).format('YYYY-MM-DD');
        params.end_date         = moment(this.state.to).format('YYYY-MM-DD');
        params.event_type_id    = values.event_type;
        params.timeslot_id      = values.time_slots  ;
        params.pricing_type_id  = values.pricing_type_id;
        params.base_amount      = values.base_amount.replace( /^\D+/g, '');
        params.food_item        = values.pricing_type_id==3?values.food_item.replace( /^\D+/g, ''):'';
        params.min_guest        = values.pricing_type_id==4?values.min_guest:'';        
        this.props.addHolidayRate(params).then(response=>{
            this.handleClose();
        });
    }

    onCalendarSelect = () => {
        if (this.state.from && this.state.to) {
            this.setState({formOpen: true, calError: false});
        } else  {
            this.setState({calError: true});
        }
    }

    render() {
        const {classes} = this.props;
        const sortOrder = this.props.formikProps.values.fixedValue
            ? this.props.fixedRentalAvailabilitySortedDays
            : this.props.flexibleRentalAvailabilitySortedDays;
        let weekOfDays = [];
        let timeSlotWeekDays = [];
        if (this.props.formikProps.values.fixedValue) {
            this
                .props
                .formikProps
                .values
                .fixedRentalAvailability
                .map(function (data, idx) {
                    timeSlotWeekDays = timeSlotWeekDays.concat(data.days);
                });
        }
        if (this.props.formikProps.values.flexibleRental) {
            this
                .props
                .formikProps
                .values
                .flexibleRentalAvailability
                .map(function (data, idx) {
                    timeSlotWeekDays = timeSlotWeekDays.concat(data.days);
                });
        }[
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday"
        ].forEach((element, index) => {
            if (!timeSlotWeekDays.includes(element)) {
                weekOfDays.push(index);
            } else if (timeSlotWeekDays.includes(element) && weekOfDays.includes(index)) {
                let ind = weekOfDays.indexOf(index);
                if (ind > -1) {
                    weekOfDays.splice(ind, 1);
                }
            }
        })

        const initialValues = {
            event_type: [],
            time_slots: [],
            base_amount: "",         
            food_item:"",
            min_guest:"",
            pricing_type_id:1
        }

        const names = [
            'Venue Rental',
            'Food & Bev Minimum',
            'Venue Rental + Food & Bev Minimum',
            'Per Person'
        ];

        const { from, to } = this.state;
        const modifiers = { start: from, end: to };

        return (
            <div>               
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Select the date(s) you want to assign a different rental rate</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <DayPicker
                                        //selectedDays={this.state.selectedDay}
                                        selectedDays={[from, { from, to }]}
                                        fromMonth={new Date()}
                                        modifiers={modifiers}
                                        disabledDays={[
                                        {
                                            before: new Date()
                                        }, {
                                            daysOfWeek: weekOfDays
                                        }
                                    ]}
                                        onDayClick={this.handleDayClick}/> 
                                        {(!this.state.form && !this.state.to && this.state.calError == true) && <div className="error days_error">Please select atleast one date.</div>
}
                                </Grid>
                                {this.state.formOpen && <Grid container xs={12} className="holiday-rate-container">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={ValidationSchema}
                                        onSubmit={(values, actions) => {
                                        this.handleSubmit(values, actions)
                                    }}
                                        render={props => (
                                        <form id='calendarForm' onSubmit={props.handleSubmit}>
                                            <Grid item xs={12}>
                                                <FormLabel className={classes.legend} component="legend">What type of event will this rate apply?</FormLabel>
                                                <FormGroup row className="checkbox-container">
                                                    {this
                                                        .props
                                                        .formikProps
                                                        .values
                                                        .eventTypesGroup
                                                        .map(function (data, idx) {
                                                            return (
                                                                <div className="custom_checkbox">
                                                                <label className={'blue_text_container'}>
                                                                    <Field
                                                                        type="checkbox"
                                                                        checked={props
                                                                        .values
                                                                        .event_type
                                                                        .includes(data.id.toString())
                                                                        ? true
                                                                        : false}
                                                                        name="event_type"
                                                                        value={data.id}/>
                                                                    &nbsp;{data.name}
                                                                    <span className={'checkmark'}></span>
                                                                </label>
                                                                </div>
                                                            )
                                                        })}
                                                    <div className="error days_error"><ErrorMessage name={`event_type`}/></div>
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormLabel className={classes.legend} component="legend">Select Time Slots</FormLabel>
                                                <FormGroup row className="checkbox-container">
                                                    {this.props.formikProps.values.fixedValue && this
                                                        .props
                                                        .formikProps
                                                        .values
                                                        .fixedRentalAvailability
                                                        .map(function (data, idx) {
                                                            timeSlotWeekDays = timeSlotWeekDays.concat(data.days);
                                                            return (
                                                                <div className="custom_checkbox">
                                                                <label className={'blue_text_container'}>
                                                                    <Field
                                                                        type="checkbox"
                                                                        name="time_slots"
                                                                        checked={props
                                                                        .values
                                                                        .time_slots
                                                                        .includes(data.id.toString())
                                                                        ? true
                                                                        : false}
                                                                        value={data.id}/>
                                                                    &nbsp; {moment(data.startTime, "hh:mm")
                                                                        .format("hh:mm A")
                                                                        .replace(/^0+/, '') + '-' + moment(data.endTime, "hh:mm")
                                                                        .format("hh:mm A")
                                                                        .replace(/^0+/, '') + ' ' + sortOrder[idx]}
                                                                <span className={'checkmark'}></span>
                                                                </label>
                                                                </div>
                                                            )
                                                        })}
                                                    {this.props.formikProps.values.flexibleRental && this
                                                        .props
                                                        .formikProps
                                                        .values
                                                        .flexibleRentalAvailability
                                                        .map(function (data, idx) {
                                                            return (
                                                                <div className="custom_checkbox">
                                                                <label className={'blue_text_container'}>
                                                                    <Field
                                                                        type="checkbox"
                                                                        name="time_slots"
                                                                        checked={props
                                                                        .values
                                                                        .time_slots
                                                                        .includes(data.id.toString())
                                                                        ? true
                                                                        : false}
                                                                        value={data.id}/>
                                                                    &nbsp;{moment(data.startTime, "hh:mm")
                                                                        .format("hh:mm A")
                                                                        .replace(/^0+/, '') + '-' + moment(data.endTime, "hh:mm")
                                                                        .format("hh:mm A")
                                                                        .replace(/^0+/, '') + ' ' + sortOrder[idx]}
                                                                <span className={'checkmark'}></span>
                                                                </label>
                                                                </div>
                                                            )
                                                        })}
                                                    <div className="error days_error"><ErrorMessage name={`time_slots`}/></div>
                                                </FormGroup>
                                            </Grid>
                                            <Grid container xs={12}>
                                                <FormLabel className={classes.legend} component="legend">What rate would you like to offer?</FormLabel>

                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <div className="input_label">Select Rental Rate</div>
                                                    <Select
                                                    className={classes.selectContainer}
                                                    classes={{ root: classes.root, select: classes.select }}
                                                    displayEmpty
                                                    onBlur={props.handleBlur}
                                                    onChange={e => { props.setFieldValue('pricing_type_id', e.target.value); }}
                                                    name={`pricing_type_id`}
                                                    value={props.values.pricing_type_id}
                                                    input={<Input id="select-multiple-placeholder" />}
                                                    >
                                                    <MenuItem disabled value="">
                                                        <em className={classes.placeholder}>{'Select'}</em>
                                                    </MenuItem>
                                                    {names.map((name,idx) => (
                                                        <MenuItem classes={{ root: classes.options }} key={parseInt(idx+1)} value={parseInt(idx+1)}>
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                    </Select>
                                                    <div className="error days_error"><ErrorMessage name={`venue_rental`}/></div>
                                                </Grid>

                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <div className="input_label">{ (props.values.pricing_type_id==1 || props.values.pricing_type_id==3) ?'Venue Rental':(props.values.pricing_type_id==2?'Minimum Rate': props.values.pricing_type_id==4?'Per Person':'Venue Rental')} </div>
                                                    <Field
                                                        className="input_field"
                                                        name={`base_amount`}
                                                        render={({field}) => (<CurrencyInput {...field} placeholder="Amount" type="text"/>)}/>
                                                    <div className="error days_error"><ErrorMessage name={`base_amount`}/></div>
                                                </Grid>
                                                { props.values.pricing_type_id==3 && 
                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <div className="input_label">Food & Bev Minimum</div>
                                                    <Field
                                                        className="input_field"
                                                        name={`food_item`}
                                                        render={({field}) => (<CurrencyInput {...field} placeholder="Amount" type="text"/>)}/>
                                                    <div className="error days_error"><ErrorMessage name={`food_item`}/></div>
                                                </Grid>
                                                }
                                                { props.values.pricing_type_id==4 && 
                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <div className="input_label">Minimum (Maximum based on Max Capacity)</div>
                                                    <Field
                                                        className="input_field"
                                                        name={`min_guest`}
                                                        type="text"
                                                        placeholder="Guests"
                                                        />
                                                    <div className="error days_error"><ErrorMessage name={`min_guest`}/></div>
                                                </Grid>
                                                }
                                              
                                            </Grid>                                          
                                        </form>
                                    )}/>
                                </Grid>
}
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        classes={{
                        root: classes.centerAlignDialogActions
                    }}>
                        <Button
                            classes={{
                            root: classes.actionButton
                        }}
                            variant="outlined"
                            size="medium"
                            color="primary"
                            onClick={this.handleClose}>
                            Cancel
                        </Button>
                        {
                        this.state.formOpen==false? 
                        <Button
                            classes={{
                            root: classes.actionButton
                        }}
                            size="medium"
                            onClick={this.onCalendarSelect}
                            color="primary">
                            Apply
                        </Button>
                        :
                        <Button
                            classes={{
                            root: classes.actionButton
                            }}
                            form='calendarForm'
                            size="medium"
                            type="submit"
                            color="primary">
                            Apply
                        </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
AddRentalRate.propTypes = {
    classes: object.isRequired
};
const mapStateToProps = state => {
    return {};
};
const enhance = compose(withRouter, withStyles(styles), connect(mapStateToProps, dispatch => bindActionCreators({addHolidayRate}, dispatch)));
export default enhance(AddRentalRate);

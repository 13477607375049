// import axios from 'axios';
import { request } from 'shared/auth';

import { GET_CITY_REQUEST, GET_CITY_FAILURE, GET_CITY_SUCCESS } from 'shared/state/types';

function cityRequested() {
  return {
    type: GET_CITY_REQUEST
  };
}

function citySuccess(data) {

  return {
    type: GET_CITY_SUCCESS,
    cityDetails: data,
  };
}


function cityFailure(error) {
  return {
    type: GET_CITY_FAILURE,
    error:error,
  };
}



export function city(params) {
    return dispatch => {
      dispatch(cityRequested());
     
      return request().get(`venue/cities`)
      .then(response =>
        {dispatch (citySuccess(response.data),

        )
      return response.data}
      )
      .catch(errors => {

        dispatch(cityFailure(errors.response));

        throw errors;
      });
      


    
 };
}

import { CREATE_PLAN } from 'shared/state/types';
import { request } from 'shared/auth';

function createPlanRequested() {
  return {
    type: CREATE_PLAN.REQUEST,
  };
}

function createPlanFailed(error) {
  return {
    type: CREATE_PLAN.FAILURE,
    error:error,
  };
}

function createPlanSuccess(data) {
  return {
    type: CREATE_PLAN.SUCCESS,
    payload: data,
  };
}

export function createPlan(params) {
  return dispatch => {
    dispatch(createPlanRequested());

    return request().post(`user_plans?${params}`)
      .then(response =>{
          dispatch(createPlanSuccess(response.data.data))
          return response.data.data;
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(createPlanFailed(errors.response.data.message));
        }
        else{
          dispatch(createPlanFailed('Not able to access api'));
        }
        throw errors;
      });
  };
}

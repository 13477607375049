import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'shared/state/store';
// import PropTypes from 'prop-types';
import { compose } from 'redux';
import ProtectedRoute from './protectedRoute';
import App from 'shared/app';
import ScrollToTop from 'shared/components/ScrollToTop'
import { userIsAuthenticated, userIsNotAuthenticated } from 'shared/auth';
import Register from 'auth/register';
import Notifications from 'notifications';
import GoogleRegister from 'auth/register/indexGoogle';
import microsoftRegister from 'auth/register/indexMicrosoft';
import mobileSignUp from 'auth/register/mobileSignUp';
import emailSignUp from 'auth/register/emailSignUp';
import Login from 'auth/login';
import Logout from 'auth/logout';
import SSOCallback from 'auth/callback';
import Foobar from 'foobar';
import FourOhFour from '404';
import ProfileInfoConfirm from 'auth/register/views/profileInfoConfirm';
import VenueProfileEdit from 'venue/venueProfileEdit';
import AutoLogin from 'venue/autoLogin';

import SpaceProfileEdit from 'venue/spaceProfileEdit';
import MyVenue from 'venueList/components/myVenue';
import VenuePlan from 'venueOwner/components/venuePlan';
import Payment from 'venueOwner/components/payment';
import PaymentConfirmation from 'venueOwner/components/paymentConfirmation';
import Home from 'home';
import confirmPhone from './auth/confirmPhone';
import confirmEmail from './auth/confirmEmail';
import BrowseVenue from 'browseVenue/container/browseVenue';
import ListYourSpaces from 'listYourSpaces/containers/listYourSpaces'
import VenueProfile from 'venueProfile/container/venueProfile';
import VenueComplete from 'venue/venueComplete';
import SpaceComplete from 'venue/spaceComplete';
import AdditionalVenue from 'venue/additionalVenue';
import { AddVenue, AddSpace } from 'venue';
import { searchForStatus, syncingCalendar, googleCalendar, searchForMSStatus } from 'venueOwner';
import ContactUs from './CMS/contactUs/container/contactUs';
import AboutUs from './CMS/aboutUs/container/aboutUs';
import pdfReader from 'shared/components/pdfReader';
import FirstHoldAgreement from './CMS/fhAgreement/container/fhAgreement';
import VenueAgreement from './CMS/venueAgreement/container/venueAgreement';
import Policy from './CMS/policy/container/policy';
import HelpAndFaq from './CMS/faq/container/helpAndFaq';
import WebCalendar from 'venue/WebCalendar';
import MyEvents from 'myEvents/container/myEvents';
import MyFavourites from 'myFavourites/container/myFavourites';
import MyHold from 'myHold/container/myHold';
import MyTerms from 'content/container/terms';
import ForgotPassword from './auth/forgot/container/forgotPassword';
import ResetPassword from './auth/forgot/container/resetPassword';
import EditProfile from 'editProfile';
import UnAuthorised from './auth/unAuthorised';
import NotificationViewAll from 'shared/components/notificationViewAll';

import WidgetTypeTwo from 'widgetTypeTwo/container/widgetTypeTwo';
import SeperateCalendar from 'shared/components/widgetPages/calendar';
import VenuePlanForList from 'listYourSpaces/containers/planListForSpace';
import LandingPage  from 'landingPage/containers/index';
import Calendly from 'calendly/containers/calendly';
import CreatePassword from 'auth/createPassword';
import WebMicrosoftCalendar from 'venue/WebMicrosoftCalendar';
import TSLocations from 'venue/TSLocations';
import Blog from './home/containers/blog';
import Landingpagenew from './explore-landing-page/component/landing-page';
import Explore from './explore-landing-page/component/landing-page-2';
import Meetingvenue  from './explore-landing-page/component/meetingvenue';
import Eventspace from './explore-landing-page/component/eventspace';
import Corporateevent from './explore-landing-page/component/corporate-event';
import Wedding from './explore-landing-page/component/wedding';
import Wineries from './explore-landing-page/component/wineries';

import HelpHome from './helpHome/components/helpHome';
import HelpSearch from './helpHome/components/helpSearch';
import HelpAbout from './helpHome/components/helpAbout';
import HelpArticles from './helpHome/components/helpArticles';
import Press from './press/component/press';
import PressLaunch from './press/component/press-launch';
import SonomaValley from './explore-landing-page/component/SonomaValley';
import Napa from './explore-landing-page/component/Napa';
import ETLocations from 'venue/ETLocations';
import NapaValley from './explore-landing-page/component/napaValley';
import Sonoma from './explore-landing-page/component/sonoma';
import SonomaCounty from './explore-landing-page/component/sonomaCounty';
import SanFrancisco from './explore-landing-page/component/sanFrancisco';
import WineCountry from './explore-landing-page/component/wineCountry';
import SiliconValley from './explore-landing-page/component/siliconValley';
import Blogpage from './blog/component/blog';
import BlogDetail from './blog/component/blogDetail';
import ThankyouPage from './CMS/thankyouPage/container/thankyouPage';
import Membership from './membership/components/membership';
import PressReleaseV1 from './press/component/pressReleaseV1';
import TrendingEvent from './blog/component/trending-event';
import corporateevent from './blog/component/corporate-event';
import eventvenuewine from './blog/component/event-venue-wine';
import minnagallery from './blog/component/minna-gallery-private';
import persanfrancisco from './blog/component/pier-san-francisco';
import resilientfuture from './blog/component/resilient-future';

const plannerId = '0';
const ownerId = '1';
class Routes extends React.Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <App>
          <ScrollToTop>
            <Switch>
              
              <ProtectedRoute path="/register" component={Register} />
              <ProtectedRoute path="/home/signUp" component={Register} />
              <Route path="/googleregister" component={GoogleRegister} />
              <Route path="/microsoftregister" component={microsoftRegister} />
              <Route path="/register-with-mobile/:venueId?" component={mobileSignUp} />
              <Route path="/register-with-email/:venueId?" component={emailSignUp} />
              <Route path="/login" component={Login} />
              <Route path="/createPassword" component={CreatePassword} />
              <Route path="/home/login" component={Login} />
              <Route exact path="/addVenue" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(AddVenue):userIsAuthenticated(UnAuthorised)} />
              <Route exact path="/editVenue/:venueId/:step?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(AddVenue):userIsAuthenticated(UnAuthorised)} />
              <Route exact path="/addSpace/:venueId/:step?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(AddSpace):userIsAuthenticated(UnAuthorised)} />
              <Route exact path="/editSpace/:spaceId/:step?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(AddSpace):userIsAuthenticated(UnAuthorised)} />
              <Route exact path="/venueProfile/:venueId?" component={VenueProfile} />
              <Route exact path="/venueProfile/:venueId" component={VenueProfile} />
              <Route exact path="/venueProfile/:venueId/space/:spaceId?" component={VenueProfile} />
              <Route exact path="/contactUs" component={ContactUs} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route exact path="/aboutUs" component={AboutUs} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/pdfReader" component={pdfReader} />
              <Route path="/payNow" component={userIsAuthenticated(Payment)} />
              {/* <Route path="/aboutUs" component={PaymentConfirmation} /> */}
              <Route exact path="/fhAgreement" component={FirstHoldAgreement} />
              <Route exact path="/venueAgreement" component={VenueAgreement} />
              <Route exact path="/policies" component={Policy} />
              <Route exact path="/faq" component={HelpAndFaq} />
              <Route exact path="/help-and-faqs" component={HelpAndFaq} />
              <Route path="/venueProfileEdit/:venueId" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(VenueProfileEdit) : userIsAuthenticated(UnAuthorised)} />
              <Route path="/autoLogin" component={AutoLogin} />
              <Route path="/spaceProfileEdit/:spaceId" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(SpaceProfileEdit) : userIsAuthenticated(UnAuthorised)} />
              <Route path="/logout" component={userIsAuthenticated(Logout)} />
              <Route path="/forgotPassword" component={userIsNotAuthenticated(ForgotPassword)} />
              <Route exact path="/resetPassword/:token" component={ResetPassword} />
              <Route path="/sso/callback" component={userIsNotAuthenticated(SSOCallback)} />
              <Route path="/foobar" component={userIsAuthenticated(Foobar)} />
              <Route path="/profileInfoConfirm" component={ProfileInfoConfirm} />
              <Route path="/confirmPhone" component={confirmPhone} />
              <Route path="/viewNotifications" component={userIsAuthenticated(Notifications)} />
              <Route path="/editProfile" component={userIsAuthenticated(EditProfile)} />
              <Route path="/confirmEmail" component={confirmEmail} />
              <Route path="/notificationViewAll" component={userIsAuthenticated(NotificationViewAll)} />
              <Route exact path="/browseVenue" component={BrowseVenue} />
              <Route exact path="/listYourSpaces" component={ListYourSpaces} />
              <Route exact path="/venuePlanList" component={VenuePlan} />
              <Route exact path="/schedule" component={Calendly} />

              <Route exact path="/browseVenue/:venueType?" component={BrowseVenue} />
              <Route exact path="/venueComplete/:id" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(VenueComplete) : userIsAuthenticated(UnAuthorised)} />
              <Route exact path="/venuePlanList" component={VenuePlan} />
              <Route exact path="/schedule" component={Calendly} />
              <Route exact path="/browseVenue/:venueType?/:category?" component={BrowseVenue} />
              <Route path="/spaceComplete/:spaceId?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(SpaceComplete) : userIsAuthenticated(UnAuthorised)} />
              <Route path="/additionalVenue" component={AdditionalVenue} />
              <Route exact path="/myVenue" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(MyVenue) : userIsAuthenticated(UnAuthorised)} />
              <Route path="/myEvents" component={localStorage.getItem('type') === plannerId || this.props.type === plannerId ? userIsAuthenticated(MyEvents) : userIsAuthenticated(UnAuthorised)} />
              <Route path="/myFavourites" component={localStorage.getItem('type') === plannerId || this.props.type === plannerId ? userIsAuthenticated(MyFavourites) : userIsAuthenticated(UnAuthorised)} />
              <Route path="/widgetTypeTwo/:venueId" component={WidgetTypeTwo} />
              <Route path="/calendar/:spaceId" component={SeperateCalendar} />
              <Route path="/myHold" component={userIsAuthenticated(MyHold)} />
              <Route path="/terms" component={MyTerms} />
              <Route path="/for-event-venue" component={MyTerms} />
              <Route path="/for-event-planner" component={MyTerms} />
              <Route path="/privacy-policy" component={MyTerms} />
              {/* <Route path="/notificationViewAll" component={userIsAuthenticated(NotificationViewAll)} /> */}
              <Route exact path="/venuePlan" component={VenuePlan} component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(VenuePlan) :userIsAuthenticated(UnAuthorised)} />
              <Route exact path="/venuePlan/:venueId/:planId?" component={VenuePlan} component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(VenuePlan) :userIsAuthenticated(UnAuthorised)} />
              <Route path="/venuePlan/:venueId?" component={VenuePlan} component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(VenuePlan) :userIsAuthenticated(UnAuthorised)} />
              <Route path="/googleCalendar/:id?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(googleCalendar) :userIsAuthenticated(UnAuthorised)} />
              <Route path="/webCalendar/:id?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(WebCalendar) :userIsAuthenticated(UnAuthorised)} />
              <Route path="/syncingCalendar" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(syncingCalendar) : userIsAuthenticated(UnAuthorised)} />
              <Route path="/searchForStatus/:spaceId/:calendarId?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(searchForStatus) : userIsAuthenticated(UnAuthorised)} />           
              <Route path="/webMicrosoftCalendar/:id?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(WebMicrosoftCalendar) :userIsAuthenticated(UnAuthorised)} />
              <Route path="/mircrosoftEvents/:spaceId/:calendarId?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(searchForMSStatus) : userIsAuthenticated(UnAuthorised)} /> 
              <Route exact path="/planners" component={Home} />
              <Route exact path="/home/:param_1/:param_2?" component={LandingPage} />
              <Route exact path="/home/:param_1" component={LandingPage} />
              <Route exact path="/" component={LandingPage} />
              <Route path="/tslocations/:id?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(TSLocations) :userIsAuthenticated(UnAuthorised)} />
              <Route exact path="/help" component={HelpHome} />
              <Route exact path="/help/search/:title?" component={HelpArticles} />
              <Route exact path="/help/article/:title?" component={HelpAbout} />
              <Route exact path="/help/articles" component={HelpSearch} />
              {/*<Route exact path="/blog" component={Blog} />*/}
              <Route  path="/events-bay-area" component={Landingpagenew} />
              <Route  path="/explore" component={Explore} />
              <Route  path="/meetings" component={Meetingvenue} />
              <Route  path="/venues-unique-event-space" component={Eventspace} />
              <Route  path="/corporate-events" component={Corporateevent} />
              <Route  path="/weddings" component={Wedding} />
              <Route  path="/venues-winery" component={Wineries} />
              <Route  path="/press" component={Press} />
              <Route  path="/press-san-francisco-launch" component={PressLaunch} />
              <Route  path="/sonoma-valley" component={SonomaValley} />
              <Route  path="/napa" component={Napa} />
              <Route  path="/napa-valley" component={NapaValley} />
              <Route  path="/sonoma" component={Sonoma} />
              <Route  exact path="/blog/top-10-trending-event-themes-of-2024" component={TrendingEvent} />
              <Route  exact path="/blog/event-venue-in-the-wine-country" component={eventvenuewine} />
              <Route  exact path="/blog/best-corporate-event-venues-in-san-francisco" component={corporateevent} />
              <Route  exact path="/blog/111-minna-gallery-private-events-things-to-do-in-san-francisco" component={minnagallery} />
              <Route  exact path="/blog/pier-27-san-francisco-all-you-need-to-know-before-you-go" component={persanfrancisco} />
              <Route  exact path="/blog/a-resilient-future-for-events-in-san-francisco" component={resilientfuture} />
              <Route  path="/sonoma-county" component={SonomaCounty} />
              <Route  path="/san-francisco" component={SanFrancisco} />
              <Route  path="/wine-country" component={WineCountry} />
              <Route  path="/silicon-valley" component={SiliconValley} />
              <Route  exact path="/blog" component={Blogpage} />
              <Route  exact path="/blog/detail/:blog_id?" component={BlogDetail} />
              <Route path="/etspaces/:id?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(ETLocations) :userIsAuthenticated(UnAuthorised)} />
              <Route  exact path="/blog" component={Blogpage} />
              <Route  exact path="/blog/:blog_id?" component={BlogDetail} />
              <Route  exact path="/blogs/event-venue/:category?" component={Blogpage} />
              <Route  exact path="/blogs/weddings/:category?" component={Blogpage} />
              <Route  exact path="/blogs/corporate-events/:category?" component={Blogpage} />
              <Route path="/etspaces/:id?" component={localStorage.getItem('type') === ownerId || this.props.type === ownerId ? userIsAuthenticated(ETLocations) :userIsAuthenticated(UnAuthorised)} />
              <Route exact path="/thankyou" component={ThankyouPage} />
              <Route exact path="/membership" component={userIsAuthenticated(Membership)} />
              <Route exact path="/event-temple-to-revolutionize-the-events-industry" component={PressReleaseV1} />
              <Route exact path="/venue-profile/:venueId?" component={VenueProfile} />
              <Route exact path="/venue-profile/:venueId" component={VenueProfile} />
              <Route exact path="/venue-profile/:venueId/space/:spaceId?" component={VenueProfile} />
              <Route path="/" component={FourOhFour} />
              
            </Switch>
          </ScrollToTop>
        </App>
      </ConnectedRouter>
    )
  }
}



const mapStateToProps = state => {
  return {
    emailExistsMessage: state.data.auth.emailExistsMessage
  };
};

const enhance = compose(
  connect(mapStateToProps)
)
export default enhance(Routes);

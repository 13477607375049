// import axios from 'axios';
import { request } from 'shared/auth';

import { GET_ARRANGEMENTS_REQUEST, GET_ARRANGEMENTS_FAILURE, GET_ARRANGEMENTS_SUCCESS } from 'shared/state/types';

function arrangementsRequested() {
  return {
    type: GET_ARRANGEMENTS_REQUEST
  };
}

function arrangementsSuccess(data) {

  return {
    type: GET_ARRANGEMENTS_SUCCESS,
    arragementDetails: data,
  };
}


function arrangementsFailure(error) {
  return {
    type: GET_ARRANGEMENTS_FAILURE,
    error:error,
  };
}



export function seatingCategory(params) {
    return dispatch => {
      dispatch(arrangementsRequested());
     
      return request().get(`arrangements`)
      .then(response =>
        {dispatch (arrangementsSuccess(response.data),

        )
      return response.data}
      )
      .catch(errors => {

        dispatch(arrangementsFailure(errors.response));

        throw errors;
      });
      


    
 };
}

import React, { Component } from 'react';
import { object, func } from "prop-types";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import VenueManagerHeader from './venueManagerHeader.js';
import VenuePlanExpiredMessage from './VenuePlanExpiredMessage.js';
import { userDetails, venueList } from "shared/venueList/actions";
import { Grid, Icon, Popper, Typography, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import CustomizedButton from '../../shared/components/customizedButton';
// import SettingsIcon from '@material-ui/icons/Settings';
// import ModalBoxSetting  from "../../shared/components/ModalBoxSetting";
import { getPlans } from 'data/plan/actions';
import moment from "moment";

import '../assets/myVenue.scss';
const logo = require("../assets/default_venue.png");
const profileLogo=require("../assets/user-default.png");
const styles = theme => ({
  icon: {
    marginLeft: '8px',
    paddingTop: '1px',
    color: '#b0b0b0',
    fontSize: '15px'
  },
  activeIcon: {
    marginLeft: '8px',
    paddingTop: '1px',
    color: '#1397e1',
    fontSize: '15px'
  },
  popper: {
    paddingLeft: '15px',
    paddingTop: '100px'
  },
  root: {
    boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
    borderRadius: '6px'
  },
  typography: {
    width: '237px',
    height: '120px',
    fontSize: '14px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    padding: '19px',
    color: '#1397e1',
    fontFamily: 'Raleway'
  }
});
class MyVenue extends Component {
  state = {
    isToggleOpen: false,
    anchorEl: null,
    tier: null,
    tierExpiredAt: null,
    trialTierExpiredAt: null,
    planExists: false,
    diffDate : 0,
    max_hold:'',
    hold_duration:'',
    tierType: '',
    loading:false
  }

  UNSAFE_componentWillMount() {
    localStorage.removeItem('plan_id')
    localStorage.removeItem('venue_plan_id')
    this.props.userDetails().then((response) => {
      localStorage.setItem('profilePhoto',response.userInfo.data.profilePhoto ? response.userInfo.data.profilePhoto.path : profileLogo);
    }).catch(error =>
        {});
    this.props.venueList().then(() => {
    
    }).catch(error =>
        {

        });
    this.props.getPlans().then(() => {
    this.setState({loading:true})
    }).catch(error =>
        {

        });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let planExists = false;
    let trialPlanExists = false;

    if (this.props.userData !== nextProps.userData && nextProps.userData.data && nextProps.userData.data.plan && Object.keys(nextProps.userData).length !== 0) {
      this.setState({ planExists: true, tier: nextProps.userData.data.plan.tier, tierExpiredAt: nextProps.userData.data.plan.tier_expired_at, trialTierExpiredAt: nextProps.userData.data.plan.trail_tier_expired_at })
    }
    if(this.props.planList !== nextProps.planList && nextProps.planList.length > 0){
      // nextProps.planList.map((plan,index)=>{
      //   const currentDate = new Date(); 
      //   const oneDay = 24 * 60 * 60 * 1000;    
      //   const tierExpiredAt= plan.tier_expired_at; 
      //   const trialTierExpiredAt= plan.trail_tier_expired_at;
      //   const tierExpiredDate = tierExpiredAt !== null ? new Date(tierExpiredAt) : '';
      //   const tierExpiredDiff = currentDate <= tierExpiredDate && tierExpiredAt !== null ? Math.round(Math.abs((currentDate - tierExpiredDate) / oneDay)) : 0;
      //   const trialTierExpiredDate = trialTierExpiredAt ? new Date(trialTierExpiredAt) : '';
      //   // const trialTierExpiredDiff = currentDate <= trialTierExpiredDate && trialTierExpiredAt ? Math.round(Math.abs((currentDate - trialTierExpiredDate) / oneDay)) : '';
      //   if(tierExpiredDiff !== 0 && planExists === false){
      //     planExists = true
      //     localStorage.setItem('plan_id',plan.id)  
      //     localStorage.setItem('plan_type', 'paid')
      //     this.setState({diffDate: tierExpiredDiff, tierType: 'paid', tier: plan.tier})
      //   }
      // })
      nextProps.planList.map((plan,index)=>{
        const currentDate = moment(); //new Date(); 
        const oneDay = 24 * 60 * 60 * 1000;   
        // const tierExpiredAt= plan.tier_expired_at; 
        const trialTierExpiredAt= plan.trail_tier_expired_at;
        // const tierExpiredDate = tierExpiredAt !== null ? new Date(tierExpiredAt) : '';
        // const tierExpiredDiff = currentDate <= tierExpiredDate && tierExpiredAt !== null ? Math.round(Math.abs((currentDate - tierExpiredDate) / oneDay)) : '';
        const trialTierExpiredDate = trialTierExpiredAt ? new Date(trialTierExpiredAt) : '';
        const trialTierExpiredDiff = currentDate <= trialTierExpiredDate && trialTierExpiredAt ? Math.round(Math.abs((currentDate - trialTierExpiredDate) / oneDay)) : 0;
        // if(trialTierExpiredDiff !== 0 && planExists === false){
          if(plan.enabled===1){
          planExists = true;
        
          // localStorage.setItem('plan_id',plan.id)  
          localStorage.setItem('plan_type','trial')
        }
        if(trialTierExpiredDiff !== 0 && trialPlanExists === false){
          trialPlanExists=true;
          this.setState({diffDate: trialTierExpiredDiff, tierType: 'trial', tier: plan.tier})

        }


        return plan;
      })
    }
  }

  handleHelpIconClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      isToggleOpen: true
    })
  }

  handleCloseHelpIcon = () => {
    this.setState({
      anchorEl: null,
      isToggleOpen: false
    });
  }

  // openVenuePlan = (planType) => {
  //   if(planType === 'trial'){
  //     localStorage.setItem('plan_type','trial');
  //     this.props.history.push('/venuePlan')
  //   }
  //   else{
  //     localStorage.setItem('plan_type','paid');
  //     this.props.history.push('/venuePlan')
  //   }
  // }

  openVenuePlanOnClickOfLink = (e, venueId) => {
    // localStorage.setItem('plan_type','paid');
    // if(id !== ''){
    //   localStorage.setItem('plan_id',id)
    //   localStorage.removeItem('venue_id')
    // }
    // else{
    //   localStorage.setItem('venue_id',venueId)
    //   localStorage.removeItem('plan_id')
    // }
    this.props.history.push(`/venuePlan/${venueId}`)
  }
  settingClick=(data)=>{
    this.setState({venueID:data.id,max_hold:data.max_hold_limit,hold_duration:data.hold_duration});
  }

  openLink=()=>{
    // let planId = null
    // if(this.props.planList.length > 0){
    //   this.props.planList.map((plan,index)=>{
    //     if(plan.venue_id === null && planId === null){
    //       planId = plan.id
    //     }
    //     // if(planId === null){
    //     //   planId = plan.id
    //     // }
    //     if(planId !== null){
    //       this.props.history.push('/addVenue')
    //       localStorage.setItem('plan_id',planId)
    //       // localStorage.setItem('plan_id',3)
    //     }
    //     else{
    //       this.props.history.push('/additionalVenue')
    //     }
    //   })
    // }
    // else{
    //   this.props.history.push('/additionalVenue')
    // }
    this.props.history.push('/addVenue')
  }

  render() {
    const { classes }  = this.props;
    const open         = Boolean(this.state.anchorEl);
    const venueList    = this.props.list;
    const oneDay       = 24 * 60 * 60 * 1000;
    const currentDate  = new Date();
    let expiredVenue   = [];
    // const tierExpiredDate = this.state.tierExpiredAt !== null ? new Date(this.state.tierExpiredAt) : ''
    // const tierExpiredDiff = currentDate <= tierExpiredDate && this.state.tierExpiredAt !== null ? Math.round(Math.abs((currentDate - tierExpiredDate) / oneDay)) : '';
    // const trialTierExpiredDate = this.state.trialTierExpiredAt ? new Date(this.state.trialTierExpiredAt) : ''
    // const trialTierExpiredDiff = currentDate <= trialTierExpiredDate && this.state.trialTierExpiredAt ? Math.round(Math.abs((currentDate - trialTierExpiredDate) / oneDay)) : '';
    
    const List = venueList.data && this.props.planList && venueList.data.map((data, idx) => {
      let published='';
      let plan = this.props.planList.find(p => p.venue_id === data.id);
      // const planId = plan !== undefined ? plan.id : ''
      // const tier = plan !== undefined ? plan.tier : ''
      const tierExpiredDate = plan === undefined ? '' : plan.tier_expired_at !== null ? new Date(plan.tier_expired_at) : ''
      const tierExpiredDiff = currentDate <= tierExpiredDate && plan.tier_expired_at !== null ? Math.round(Math.abs((currentDate - tierExpiredDate) / oneDay)) : '';
      const trialTierExpiredDate = plan === undefined ? '' : plan.trail_tier_expired_at ? new Date(plan.trail_tier_expired_at) : ''
      const trialTierExpiredDiff = currentDate <= trialTierExpiredDate && plan.trail_tier_expired_at ? Math.round(Math.abs((currentDate - trialTierExpiredDate) / oneDay)) : '';
      const diffDate= trialTierExpiredDiff !== '' ? trialTierExpiredDiff : tierExpiredDiff
      // let status='';
      if(parseInt(data.approved)===parseInt('1') && parseInt(data.published)===parseInt('1')){
        published="Live";
      }
      else if(parseInt(data.approved)===parseInt('1') && parseInt(data.published)===parseInt('0')){
        published='Ready to publish'
      }
      else if(parseInt(data.approved)===parseInt('2'))
      {
        published="Rejected";
      }
      else if(parseInt(data.approved)===parseInt('0')){
        published="Under Review";
      }
      else if(parseInt(data.approved)===parseInt('-1')){
          published="Draft";
      }
      else if(parseInt(data.approved)===parseInt('1') && parseInt(data.published)===parseInt('0'))
      {
        published="Not yet published";
      }
      else if(parseInt(data.approved)===parseInt('1') && parseInt(data.published)==='1')
      {
        published="Publish";
      }

      console.log('trialTierExpiredDiff',trialTierExpiredDiff);

      /*if(this.state.diffDate == 0 ){
        if(plan !== undefined && diffDate!==""){
          expiredVenue.push({id:data.id,name:data.name}); 
        }else if(plan === undefined){
          expiredVenue.push({id:data.id,name:data.name});  
        } 
      }*/




      return (
        this.props.venueList && this.props.planList &&
        <Grid md={12} xs={12} sm={12} xl={12} lg={12} container justify="center" className="venue-container">
      
       
          <div className="my-venues">
            <div className="venue-image">
              <img className="image"
                src={data.profilePhoto && data.profilePhoto.path ?
                  data.profilePhoto.path : logo}
                alt="FIRST-HOLD"
                height="60"
                width="60"
              />
            </div>
            <div className="venue-details">
              <div className="details">
                <div className="title">
                  {data.name}
                </div>
                <p>
                  {data.address && data.address.line_1},{data.address && data.address.line_2} <br />
                  {data.address && data.address.city}, {data.address && data.address.state_abbr}
                </p>
              </div>
            </div>
            <div className="plan-details">
                {/* {
                  plan !== undefined ?
                    tier === null ?
                    diffDate !== '' ?
                      <div className="plantext">
                        You have {diffDate} days left to try FIRST-HOLD for free. <a onClick={(e)=>{this.openVenuePlanOnClickOfLink(planId, data.id)}} className="red-text">Set up a paid account.</a>
                      </div>
                      :
                      <div className="plantext">
                        <a onClick={(e)=>{this.openVenuePlanOnClickOfLink('',data.id)}} className="red-text">Set up a paid account.</a>
                      </div>
                    
                      :
                      diffDate !== '' ?
                        <div className="plantext">
                          You have {diffDate} days left for{tier === 1 ? 'Tier One plan' : tier === 2 ? 'Tier Two plan' : tier === 3 ? 'Tier Three plan' : ''}.  <a onClick={(e)=>{this.openVenuePlanOnClickOfLink(planId, data.id)}} className="red-text">{tier !== 3 ?'Upgrade your plan' : 'Update your plan'}</a>
                        </div>
                        :
                        null
                    : 
                      <div className="plantext">
                        <a onClick={(e)=>{this.openVenuePlanOnClickOfLink('', data.id)}} className="red-text">Set up a paid account.</a>
                      </div>
                } */}
                {this.state.loading===true?
                  plan !== undefined ?
                  diffDate > 0 
                  ? null
                  :
                    <div className="plantext">
                      Your plan has been expired. <a onClick={(e)=>{this.openVenuePlanOnClickOfLink(e,data.id)}} className="red-text">Set up a paid account.</a>
                    </div>
                    : 
                    this.state.diffDate > 0 ?
                    <div className="plantext">
                       You have {this.state.diffDate} days left to try FIRST-HOLD for free. <a onClick={(e)=>{this.openVenuePlanOnClickOfLink(e,data.id)}} className="red-text">Set up a paid account.</a>
                    </div>
                    :
                    <div className="plantext">                       
                        <a onClick={(e)=>{this.openVenuePlanOnClickOfLink(e, data.id)}} className="red-text">Set up a paid account.</a>
                    </div>:null
                }
            </div>
            
            
            <div className="add-space-div">
              <div className="add-space-button-div">
                {data.spaces && data.spaces.length > 9 ?
                  <Grid>    
                   <p style={published==='Publish'?{color:'Green',fontWeight:'bold'}:null} class="published">{published}</p>
                  <div className="white-btn-div">
                    <p style={{ backgroundColor: '#ccc', color: 'white' }} className="white-button" href="#"><span>+ Add Space</span> <Grid className="help_icon" style={{ display: "inline-block" }}>
                    <Grid onMouseOver={this.handleHelpIconClick} onMouseOut={this.handleCloseHelpIcon}>
                      <Icon style={{ color: 'white' }} className={this.state.isToggleOpen ? classes.activeIcon : classes.icon}>
                        help_outline
                      </Icon>
                    </Grid>
                  </Grid>
                  </p>
                  </div>
                  </Grid>

                  :
             <div>              
               
               <p style={published==='Live'?{color:'Green',fontWeight:'bold'}:null} class="published">{published}</p>
              {published!=='Live' && published!=='Ready to publish'?
              <a className="white-button" href={"/addSpace/" + data.id}><span>+ Add Space</span></a>:null}

                    </div>

                }
              </div>
              <div className="edit-button-div">
                <a href={"/venueProfileEdit/" + data.id} className="material-icons create edit-button">edit</a>

              </div>

            </div>
          </div>
          {data.spaces && data.spaces.length !== 0 ?
            <div className="edit-profile-section">

              {data.spaces && data.spaces.map(function (space_data, idx) {
        
                return (

                  <div className="subsection">
                    <div className="buyout-image">
                      <img className="image"
                        src={space_data.photo && space_data.photo[0] && space_data.photo[0].path ?
                          space_data.photo[0].path : logo}
                        alt="FIRST-HOLD"
                        height="60"
                        width="60"
                      />
                    </div>
                    <div className="venue-details">
                      <div className="subsection-text">
                        {space_data.name.length < 30 ? space_data.name : `${space_data.name.substring(0,30)}...`}
                      </div>
                    </div>
                    {/* {alert(space_data.published)} */}
                    <div className="add-space-div" style={{width:'30%'}}>
                    <a className="white-button" href={"/spaceProfileEdit/" + space_data.id}><span>Edit Profile</span></a>
                    {data.approved=='1' && data.published=='1'
                    ?
                    
                    <p class="published" style={{display:'block' , paddingRight: '8px' ,
                    paddingTop: '5px'}}>
                    {space_data.published!==1?"Unpublished":''}

                
                    
                    
                     </p>
                     :null}
                      
                    </div>
                  </div>


                )
              })}
              {data.spaces && data.spaces.length % 2 !== 0 ?
                <div className="subsection-empty">

                </div> : null}

            </div>
            : null}




        </Grid>

      )

    });





    return (

      <Grid container className="venue_manager_venue_add fh-container">
        <Popper className={classes.popper} open={open} anchorEl={this.state.anchorEl} placement="right-end">
          <Paper classes={{ root: classes.root }}>
            <Typography className={classes.typography}>Maximum number of event spaces reached.</Typography>
          </Paper>
        </Popper>
        <VenueManagerHeader planExists={this.state.planExists} tierType={this.state.tierType} diffDate={this.state.diffDate} tier={this.state.tier} jobTitle={this.props.userData.data && this.props.userData.data.job_title} managerName={this.props.userData.data && this.props.userData.data.first_name + ' ' + this.props.userData.data.last_name} imageSrc={this.props.userData.data && this.props.userData.data.profilePhoto && this.props.userData.data.profilePhoto.path} openVenuePlanOnClickOfLink={this.openVenuePlanOnClickOfLink}/>

        { (expiredVenue && expiredVenue.length > 0 ) &&
          <VenuePlanExpiredMessage openVenuePlanOnClickOfLink={this.openVenuePlanOnClickOfLink} expiredVenues={expiredVenue} userData={this.props.userData.data}  />
        }

        {/* <VenueManagerHeader jobTitle={this.props.userData.data && this.props.userData.data.job_title} managerName={this.props.userData.data && this.props.userData.data.first_name + ' ' + this.props.userData.data.last_name} imageSrc={this.props.userData.data && this.props.userData.data.profilePhoto && this.props.userData.data.profilePhoto.path} />  */}
        {
        //   this.state.planExists ?
        //   tierExpiredDiff === '' && trialTierExpiredDiff === '' ?
        //   <Grid md={12} xs={12} sm={12} xl={12} lg={12} className="grey-div">
        //     <Grid md={12} xs={12} sm={12} xl={12} lg={12} container justify="center" className="venue-container">
        //       <div className="my-venues">
        //         {/* createEvent section */}
        //         <div className="create-event-header">
        //           {/* <div class="create-event-text">Select An Event For This Hold</div>
        // <div className="add-space-div">
        //     <a  className="white-button"><span>Create New Event</span></a>
        // </div> */}
        //           <div class="default">
        //             <div>
        //               <div>
        //                 <div class="red-text-content">Select a plan
        //                   <p class="sm-text-event">Takes about a minute.</p>
        //                 </div>
        //                 <div>
        //                   <Grid className="button_alignment" container justify= "center">
        //                       <Grid item className="button_padding">
        //                         <CustomizedButton onClickEvent={(e)=>{this.openVenuePlan('paid')}} label="Upgrade" />
        //                       </Grid>
        //                   </Grid>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </Grid>
        //   </Grid>
        //   :
        //   <Grid md={12} xs={12} sm={12} xl={12} lg={12} className="grey-div">
        //     <Grid md={12} xs={12} sm={12} xl={12} lg={12} className="title-header">
        //       <span className="my-venues-title">My Venues</span>
        //       <a className="red-button" href="/addVenue">
        //         <span>+ Add Venue</span>
        //       </a>
        //     </Grid>
        //     {List ?
        //       List.length > 0 ?
        //         List
        //         :
        //         <Grid md={12} xs={12} sm={12} xl={12} lg={12} container justify="center" className="venue-container">
        //           <div className="my-venues">
        //             {/* createEvent section */}
        //             <div className="create-event-header">
        //               {/* <div class="create-event-text">Select An Event For This Hold</div>
        // <div className="add-space-div">
        //     <a  className="white-button"><span>Create New Event</span></a>
        // </div> */}
        //               <div class="default">

        //                 <div class="uploader-content">
        //                   <div class="uploader">
        //                     <div class="red-text-content">Create Your First Venue
        //                   <p class="sm-text-event">Takes about a minute.</p>
        //                     </div>
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         </Grid>
        //       :
        //       ''
        //     }
        //   </Grid>

        //   :
        //   <Grid md={12} xs={12} sm={12} xl={12} lg={12} className="grey-div">
        //     <Grid md={12} xs={12} sm={12} xl={12} lg={12} container justify="center" className="venue-container">
        //       <div className="my-venues">
        //         {/* createEvent section */}
        //         <div className="create-event-header">
        //           {/* <div class="create-event-text">Select An Event For This Hold</div>
        // <div className="add-space-div">
        //     <a  className="white-button"><span>Create New Event</span></a>
        // </div> */}
        //           <div class="default">
        //             <div>
        //               <div>
        //                 <div class="red-text-content">Select a plan
        //                   <p class="sm-text-event">Takes about a minute.</p>
        //                 </div>
        //                 <div>
        //                   <Grid className="button_alignment" container justify= "center">
        //                       <Grid item className="button_padding">
        //                         {/* <Link to="/venuePlan"> */}
        //                           {/* <Button variant="contained" color="primary" className={classes.button} onClick={(e)=>{this.openVenuePlan('paid')}}>
        //                             Upgrade
        //                           </Button> */}
        //                           <CustomizedButton onClickEvent={(e)=>{this.openVenuePlan('paid')}} label="Upgrade" />
        //                         {/* </Link> */}
        //                       </Grid>
        //                       <Grid item className="button_padding">
        //                         {/* <Link to="/venuePlan"> */}
        //                           {/* <Button variant="contained" color="primary" className={classes.button} onClick={(e)=>{this.openVenuePlan('trial')}}>
        //                             Start Trial
        //                           </Button> */}
        //                            <CustomizedButton onClickEvent={(e)=>{this.openVenuePlan('trial')}} label="Start Trial" />
        //                         {/* </Link> */}
        //                       </Grid>
        //                   </Grid>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </Grid>
        //   </Grid>
        }
          <Grid md={12} xs={12} sm={12} xl={12} lg={12} className="grey-div">
            <Grid md={12} xs={12} sm={12} xl={12} lg={12} className="title-header">
              <span className="my-venues-title">My Venues</span>
              {/* {venueList.data && venueList.data.length > 0 && this.props.planList ?
                <a className="red-button" onClick={(e)=>{this.openLink(e)}}>
                  <span>+ Add Venue</span>
                </a>
                :
                <a className="red-button" onClick={(e)=>{this.openLink(e)}}>
                  <span>+ Add Venue</span>
                </a>
              } */}
              <p className="red-button" onClick={(e)=>{this.openLink(e)}}>
                  <span>+ Add Venue</span>
              </p>
            </Grid>
            {List ?
              List.length > 0 ?
                List
                :
                <Grid md={12} xs={12} sm={12} xl={12} lg={12} container justify="center" className="venue-container">
                  <div className="my-venues">
                    {/* createEvent section */}
                    <div className="create-event-header">
                      {/* <div class="create-event-text">Select An Event For This Hold</div>
        <div className="add-space-div">
            <a  className="white-button"><span>Create New Event</span></a>
        </div> */}
                      <div class="default">

                        <div class="uploader-content">
                          <div class="uploader">
                            <div class="red-text-content">Create Your First Venue
                          <p class="sm-text-event">Takes about a minute.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              :
              ''
            }
          </Grid>
      </Grid>

    );
  }
}


MyVenue.propTypes = {
  classes: object.isRequired,
  userDetails: func.isRequired,
  venueList: func.isRequired

};


const mapStateToProps = state => {

  return {
    userData: state.data.usersVenue.userInfo,
    list: state.data.usersVenue.venueList,
    planList : state.data.plan.planList
  };
};
const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        userDetails,
        venueList,
        getPlans
      },
      dispatch
    )
  )
);
export default enhance(MyVenue);

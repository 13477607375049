import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StepProgressBar from '../../shared/components/StepProgressBar';
import AddVenueName from '../components/addVenueName';
import AddVenueAddress from '../components/addVenueAddress';
import AddVenueContact from '../components/addVenueContact';
import AddVenuePhoto from '../components/addVenuePhoto';
import getCroppedImg from '../../cropImage';
import { getStates } from 'data/person/actions';
import { addVenue, venueTypes, getVenue, editVenue, venueImageUpload, deleteVenueImage, getAirport, contactImageUpload } from 'data/venues/actions';
import { getUploadPostUrl, uploadtoS3 } from 'data/master/actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import '../assets/styles/addVenue.scss';
import Geocode from "react-geocode";
import { deleteResource } from 'data/master/actions';
import { city } from '../../shared/city/actions';
import { userDetails } from "../../shared/venueList/actions";

let venueImageError = '';
let submissionType = '';

const FILE_SIZE = 1024 * 1024 * 3;

const phoneRegExp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/  /* eslint-disable-line */

class AddVenue extends Component {
  state = {
    cropped_contact_image:'',
    profilePhotoId: '',
    rotation: 0,
    cropping: false,
    cropDone: false,
    cropData: '',
    step: 1,
    imageUploaded: false,
    imageUploading: false,
    imageFile: '',
    imageFileName: '',
    isToggleOpen: false,
    formCompleted: false,
    anchorEl: null,
    imageSrc: this.imageFile,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 7 / 3,
    croppedAreaPixels: null,
    croppedImage: null,
    venueName: '',
    venueType: '',
    venueCode: '',
    address1: '',
    cityName: '',
    zipcode: '',
    address2: '',
    state: '',
    airport: '',
    lat: '37.7749',
    lng: '-122.4194',
    contactEmail: '',
    contactJobTitle: '',
    contactPhoneNumber: '',
    contactName: '',
    venueDescription: '',
    venueImage: undefined,
    venueImageFile: {},
    venueImageFileData: '',
    venueId: '',
    imageUrl: '',
    imageCheck: this.props.match.params.venueId ? true : false,
    airportSuggestionList: [],
    isAirportSelected: false,
    isCitySelected: false,

    contactImageData: {},
    citySuggestionList:[],
    thumbnailData:''
  }

  UNSAFE_componentWillMount() {
    
    this.props.city().then((response) => {

    })
    this.props.userDetails().then((res) => {
    })
    if (this.props.match.params.venueId) {
      const venueId = this.props.match.params.venueId
      this.setState({ venueId: this.props.match.params.venueId })
      this.props.getVenue(venueId)
    }
    // this.props.getPlans()
    this.props.getAirport()
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API);

    // set response language. Defaults to english.
    Geocode.setLanguage("en");

    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("es");

    // Enable or disable logs. Its optional.
    Geocode.enableDebug();




    if (this.props.match.params.step !== undefined) {
      this.setState({ step: parseInt(this.props.match.params.step) })
    }
    this.props.venueTypes()
      .then((response) => {
        this.setState({ formCompleted: true })
      })
      .catch(error => {})
    this.props.getStates();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    const airportList = []
    const city=[];
    if (this.props.venueDetails !== nextProps.venueDetails && Object.keys(nextProps.venueDetails).length !== 0) {

      const venueDetails = nextProps.venueDetails
      this.setState({
        cropDone: true,
        venueName: venueDetails.name,
        venueCode: venueDetails.code,
        venueType: venueDetails.type,
        address1: venueDetails.address.line_1,
        address2: venueDetails.address.line_2,
        state: venueDetails.address.state,
        zipcode: venueDetails.address.zip,
        cityName: venueDetails.address.city,
        lat: venueDetails.address.geo_lat,
        lng: venueDetails.address.geo_lng,
        profilePhotoId:venueDetails.contacts[0].profile_image ? venueDetails.contacts[0].profile_image.path : '',
        contact_id:venueDetails.contacts[0].profile_image ? venueDetails.contacts[0].profile_image.id:'',

        //contactName: venueDetails.contacts.name,
        //contactEmail: venueDetails.contacts.email,
        //contactPhoneNumber: venueDetails.contacts.phone_number,
        //contactJobTitle: venueDetails.contacts.job_title,
        venueDescription: venueDetails.description,
        airport: venueDetails.address.airport ? venueDetails.address.airport : '',
        isAirportSelected: venueDetails.address.airport ? true : false,
        isCitySelected: venueDetails.address.city ? true : false

      })
      venueDetails.contacts.map((contact, index) => {
        this.setState({
          contactName: contact.name,
          contactEmail: contact.email,
          contactPhoneNumber: contact.phone_number,
          contactJobTitle: contact.job_title
        })
        return contact;

      })
      if (venueDetails.profilePhoto) {
        const image_url = process.env.REACT_APP_MEDIA_URL + venueDetails.profilePhoto.path
        this.setState({ imageUrl: image_url, imageUploaded: true })
      }
    }
    // if (this.props.planList !== nextProps.planList && Object.keys(nextProps.planList).length !== 0) {
    //   nextProps.planList.map((plan, index) => {
    //     let planId = null
    //     if (planId === null && plan.venue_id === null) {
    //       planId = plan.id
    //     }
    //     if (planId !== null) {
    //       localStorage.setItem('plan_id', planId)
    //     }
    //   })
    // }
    if (this.props.airportList !== nextProps.airportList && Object.keys(nextProps.airportList).length !== 0) {
      nextProps.airportList.map((airport, index) => {
        airportList.push({ key: airport.name, id: airport.id })
        return airportList;
      })
      this.setState({ airportSuggestionList: airportList })
    }
    if (this.props.cityList !== nextProps.cityList && Object.keys(nextProps.cityList).length !== 0) {

      if(nextProps.cityList && nextProps.cityList.data.length > 0){

    nextProps.cityList.data.map((c, index) => {

      city.push({ key: c.name, id: index + 1 })
      return city;
    })

    this.setState({ citySuggestionList: city })
   }
    
    }
  }

  //for image cropper
  onCropChange = crop => {
    this.setState({ crop })

  }

  onAirportSelect = (val) => {
    const airport = this.state.airportSuggestionList.find(a => a.key === val);
    if(airport !== undefined){
      this.setState({isAirportSelected: true})
    }
    else{
      this.setState({isAirportSelected: false})
    }
  }
  onCitySelect = (val) => {
    const city = this.state.citySuggestionList.find(a => a.key === val);
    if(city !== undefined){
      this.setState({isCitySelected: true})
    }
    else{
      this.setState({isCitySelected: false})
    }
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels })
    // this.showCroppedImage()
  }

  okButton = async () => {
    try {
      this.setState({ cropping: true })

      const image = this.state.imageFile ? this.state.imageFile : this.state.imageUrl
      const croppedImage = await getCroppedImg(
        image,
        this.state.croppedAreaPixels,
        this.state.rotation
      )
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var ctx1 = c.getContext("2d");
      var d = document.getElementById("myCanvas");
      var img = document.getElementById("scream");
      var prop = this;
      img.src = croppedImage;

      img.onload = () => {
        c.width = img.width;
        c.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height);
        c.toBlob(function (blob) {
          var f = new File([blob], "sample.jpg", { type: "application/octet-stream" });
          prop.setState({ cropData: f });
          prop.setState({ cropDone: true, imageUrl: image, imageFile: croppedImage, cropping: false });

        }, "image/jpeg", 0.75)
        d.width = 408;
        d.height = 200;
        //ctx1.drawImage(img, 0, 0, 335, 150, 0, 0, 335, 150);
        ctx1.drawImage(img, 0, 0, img.width, img.height, 0, 0, 408, 200);
        d.toBlob(function (blob) {
          var g = new File([blob], "sample1.jpg", { type: "application/octet-stream" });
          prop.setState({ thumbnailData: g });
          //prop.setState({ cropDone: true, imageUrl: image, imageFile: croppedImage, cropping: false });

        }, "image/jpeg", 0.75)

      }


      this.setState({ croppedImage: croppedImage, cropping: false })


    } catch (e) {
      
    }
  }

  cancelButton = (setFieldValue) => {
    if (this.state.imageUrl === '') {
      setFieldValue('venueImage', '')
    }
    this.setState({
      imageFileName: '',
      cropping: false,
      cropDone: true,
      imageUrl: this.state.imageUrl,
      imageFile: '',
      imageUploading: false,
      imageUploaded: this.state.imageUrl !== '' ? true : false
    });

  }
  onZoomChange = event => {
    this.setState({ zoom: event.currentTarget.value })
  }
  onRotationChange = event => {
    this.setState({ rotation: event.currentTarget.value })
  }
  handleHelpIconClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      isToggleOpen: true
    })
  }
  handleCloseHelpIcon = () => {
    this.setState({
      anchorEl: null,
      isToggleOpen: false
    });
  }
  handleSubmit = (values, actions) => {
    var imageData = new FormData();
    // this.setState({ venueName: values.venueName })
    if (values.venueImage) {
      imageData.append("name", this.state.venueImageFile.name);
      //imageData.append("photo", this.state.venueImageFile);
    }
    actions.setSubmitting(false);
    if (submissionType === 'next') {
      if(this.state.step === 2 && this.state.isAirportSelected === true && this.state.isCitySelected === true){
        this.incrementStep()
      }
      else if(this.state.step !== 2) {
        this.incrementStep()
      }
    }
    
    if (submissionType === 'save') {
      if (this.state.cropping === false) {


        if (values.venueDescription && (values.venueImage || this.state.imageUrl) && venueImageError === '') {
          let formData;
          if (values.address2 && values.address2 !== '') {
            formData = {
              imageCheck: values.imageCheck === false ? 0 : 1,
              type: values.venueType,
              name: values.venueName,
              code: values.venueCode,
              description: values.venueDescription,
              address: {
                line_1: values.address1,
                line_2: values.address2,
                city: values.cityName,
                state: values.state,
                country: 'US',
                zip: values.zipcode,
                geo_lat: this.state.lat,
                geo_lng: this.state.lng,
                airport: values.airport
              },
              contacts: [{
                name: values.contactName,
                email: values.contactEmail,
                phone_number: values.contactPhoneNumber,
                job_title: values.contactJobTitle
              }
              ]
            }
          }
          else {
            formData = {
              imageCheck: values.imageCheck === false ? 0 : 1,
              type: values.venueType,
              name: values.venueName,
              code: values.venueCode,
              description: values.venueDescription,
              address: {
                line_1: values.address1,
                city: values.cityName,
                state: values.state,
                country: 'US',
                zip: values.zipcode,
                geo_lat: this.state.lat,
                geo_lng: this.state.lng,
                airport: values.airport
              },
              contacts: [{
                name: values.contactName,
                email: values.contactEmail,
                phone_number: values.contactPhoneNumber,
                job_title: values.contactJobTitle
              }
              ]
            }
          }
          if (this.state.venueId !== '') {
            const venue_id = this.state.venueId
            this.props.editVenue(formData, venue_id).then((response) => {
              if(Object.keys(this.state.contactImageData).length !== 0 && values.agree===false){
                this.props.getUploadPostUrl(1, this.state.contactImageData).then((res_contact) => {
                
                  var contactimageData = new FormData();
              
                  contactimageData.append("photo", res_contact.keyName);
  
                  this.props.uploadtoS3(res_contact.url, this.state.cropped_contact_image).then(() => {
                  this.props.contactImageUpload(contactimageData, response.id);
  
                  })
  
                })
              }
         

                if (this.state.cropData !== '') {
                  if (values.venueImage) {
                    if (this.state.venueId && this.props.venueDetails.profilePhoto) {
                      this.props.deleteVenueImage(this.state.venueId, this.props.venueDetails.profilePhoto.id)
                    }
                    this.props.getUploadPostUrl(1, this.state.venueImageFile.name).then((res) => {
                      imageData.append("photo", res.keyName);
                      this.props.uploadtoS3(res.url, this.state.cropData).then(() => {
                        this.props.getUploadPostUrl(1, this.state.venueImageFile.name).then((res1) => {
                        imageData.append("thumbnail", res1.keyName);
                        this.props.uploadtoS3(res1.url, this.state.thumbnailData).then(() => {  
                        this.props.venueImageUpload(imageData, response.id)
                          .then((res) => {
                            this.props.editVenue({ profile_photo_id: res.id }, venue_id)
                              .then((response) => {

                                this.props.history.push(`/venueProfileEdit/${response.id}`)
                      })
                    })
                  })
                })
              })

                    })
                  }
                  else {
                    this.props.history.push(`/venueProfileEdit/${response.id}`)
                  }
                }
                else {
                  this.props.history.push(`/venueProfileEdit/${response.id}`)

                }
              })
              .catch(error => {})
          }
          else {
            this.props.addVenue(formData, submissionType)
              .then((response) => {
                // formData = `venue_id=${response.id}`
                // const planId = localStorage.getItem('plan_id') ? localStorage.getItem('plan_id') : ''
                // if(planId !== ''){
                //   this.props.updatePlan(planId, formData, "hide").then((repo) => {
                //     localStorage.removeItem('plan_id')
                //   })
                // }
                this.props.getUploadPostUrl(1, this.state.venueImageFile.name).then((res) => {
                  imageData.append("photo", res.keyName);

                  this.props.uploadtoS3(res.url, this.state.cropData).then(() => {
                    this.props.getUploadPostUrl(1, this.state.venueImageFile.name).then((res1) => {
                      imageData.append("thumbnail", res1.keyName);
                      this.props.uploadtoS3(res1.url, this.state.thumbnailData).then(() => {
                    this.props.venueImageUpload(imageData, response.id)
                      .then((res) => {
                        this.props.editVenue({ profile_photo_id: res.id }, response.id)
                          .then((response) => {

                            if(Object.keys(this.state.contactImageData).length !== 0 && values.agree===false){

                              this.props.getUploadPostUrl(1, this.state.contactImageData).then((res_contact) => {
                            
                              var contactimageData = new FormData();
                          
                              contactimageData.append("photo", res_contact.keyName);

                              this.props.uploadtoS3(res_contact.url, this.state.cropped_contact_image).then(() => {
                                this.props.contactImageUpload(contactimageData, response.id)

                              })

                            })
                          }
                            this.props.history.push(`/venueComplete/${response.id}`)
                  })
                })
              })
            })
          })
        })

              })
              .catch(error => {})
          }
        }

      }
    }
  };

  incrementStep = () => {

    if (this.state.step === 1) {
      this.setState({ step: this.state.step + 1 })
    }
    else if (this.state.step === 2) {
      // Get latidude & longitude from address.
      let address_tofeatch = this.state.address1 + ' ' + this.state.address2 + ' ' + this.state.cityName + ' ' + this.state.state + 'US';
      Geocode.fromAddress(address_tofeatch).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          this.setState({ lat: lat.toString(), lng: lng.toString() });
        },
        error => {

        }
      );
      this.setState({ step: this.state.step + 1 })
    }
    else if (this.state.step === 3) {
      this.setState({ step: this.state.step + 1 })
    }
    else {
      this.setState({ step: 4 })
    }
  }
  decrementStep = () => {
    this.setState({ step: this.state.step - 1 })
  }
  changeSubmission = (type) => {
    submissionType = type
  }
  handleImageChange = (event, setSubmitting) => {
    if (event.target.files[0].name.match(/.(jpg|jpeg)$/i) && (event.target.files[0].size <= FILE_SIZE)) {
      var reader = new FileReader();
      var thisObj = this;
      reader.onload = function () {
        var dataURL = reader.result.split(',');
        // var output = document.getElementById('output');
        // output.src = dataURL;
        thisObj.setState({
          venueImageFileData: dataURL[1]
        });
      };
      reader.readAsDataURL(event.target.files[0])
      this.setState({
        cropDone: false,
        cropping: true,
        imageFile: URL.createObjectURL(event.target.files[0]),
        imageUploading: false,
        imageFileName: event.target.files[0].name,
        imageUploaded: true,
        venueImageFile: event.target.files[0]

      });
      venueImageError = ''
    }
    if (!event.target.files[0].name.match(/.(jpg|jpeg)$/i)) {
      venueImageError = '*Unsupported Format';
      setSubmitting(true)
    }
    else if (event.target.files[0].size >= FILE_SIZE) {
      venueImageError = '*File size should not be greater than 3MB.';
      setSubmitting(true)
    }
  }
  handleImageUpload = () => {
    var link = document.getElementById('image');
    link.click();
  }

  onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  saveClick = (value) =>{
    this.setState({saveData:true,cropped_contact_image:value});
  }
  contactImageDataFunc = (value) =>{
  
    this.setState({contactImageData:value})
  }
  deleteImage = () =>{
    this.props.deleteResource(this.state.contact_id);
    this.setState({profilePhotoId:''})
  }
  onClick=(e,setFieldValue)=>{

if(e.target.value=='true'){
  setFieldValue('contactEmail',this.state.contactEmail)
  setFieldValue('contactJobTitle',this.state.contactJobTitle);
  setFieldValue('contactPhoneNumber',this.state.contactPhoneNumber);
  setFieldValue('contactName',this.state.contactName);
     
}
else{
  setFieldValue('contactEmail',this.props.userData.data.email)
  setFieldValue('contactJobTitle',this.props.userData.data.job_title);
  setFieldValue('contactPhoneNumber',this.props.userData.data.phone_number);
  setFieldValue('contactName',this.props.userData.data.first_name+" "+this.props.userData.data.last_name);
       
     
}

  }
  render() {
    const initialValues = {
      agree: false,
      contact_image:this.state.profilePhotoId ? this.state.profilePhotoId : this.state.venueName ? this.props.userData.data && this.props.userData.data.profilePhoto && this.props.userData.data.profilePhoto.path : '',
      imageCheck: this.state.imageCheck,
      venueName: this.state.venueName,
      venueType: this.state.venueType,
      venueCode: this.state.venueCode,
      address1: this.state.address1,
      cityName: this.state.cityName,
      zipcode: this.state.zipcode,
      address2: this.state.address2 ? this.state.address2 : '',
      lat: this.state.lat,
      lng: this.state.lng,
      state: this.state.state,
      contactEmail: this.state.contactEmail,
      contactJobTitle: this.state.contactJobTitle,
      contactPhoneNumber: this.state.contactPhoneNumber,
      contactName: this.state.contactName,
      venueDescription: this.state.venueDescription,
      venueImage: this.state.venueImage,
      airport: this.state.airport
    }
    const Step1ValidationSchema = Yup.object().shape({
      venueName: Yup.string()
        .required('*Required')
        .max(30, "Venue name should not be more than 30 characters"),
      venueType: Yup.string()
        .required('*Required'),
      venueCode: Yup.string()
        .required('*Required')
        .matches(/^[a-zA-Z]{4}$/, 'Must be exactly 4 letters')
    });
    const Step2ValidationSchema = Yup.object().shape({
      address1: Yup.string()
        .required('*Required')
        .max(255, "Address1 should not be more than 255 characters"),
      address2: Yup.string()
        .max(255, "Address1 should not be more than 255 characters"),
      cityName: Yup.string()
        .required('*Required')
        .max(255, "City should not be more than 255 characters"),
      zipcode: Yup.string()
        .required('*Required')
        .matches(/(^[a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d$)|(^\d{5}(-\d{4})?$)/,"*Zip Code is not valid")
        .max(16, "Zip Code should not be more than 16 characters"),
      state: Yup.string()
        .required('*Required')
        .max(255, "State should not be more than 255 characters"),
      airport: Yup.string()
        .required('*Required')
        .max(255, "Airport should not be more than 255 characters"),
    });
    const Step3ValidationSchema = Yup.object().shape({
      contactName: Yup.string()
        .required('*Required')
        .max(150, "Name should not be more than 150 characters"),
      contactEmail: Yup.string()
        .email('*Contact Email must be a valid email')
        .required('*Required')
        .max(100, "Email should not be more than 100 characters"),
      contactPhoneNumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('*Required')
        .max(150, "Phone Number should not be more than 150 characters"),
      contactJobTitle: Yup.string()
        .required('*Required')
        .max(150, "Job title should not be more than 150 characters"),
    });
    const Step4ValidationSchema = Yup.object().shape({
      venueDescription: Yup.string()
        .required('*Required')
        .max(1500, "Description should not be more than 1500 characters"),
      venueImage: (this.state.venueId === '' || this.state.imageUrl === '') && Yup
        .mixed()
        .required("A file is required"),
      imageCheck: Yup.boolean().oneOf([true], "*This must be checked.")
    });
    return (
      <div className="add_venue fh-container">

        <img id="scream" style={{ display: 'none' }} alt="The Scream" crossOrigin="anonymous" />
        <canvas style={{ display: 'none' }} id="myCanvas"></canvas>
        <Formik
          initialValues={initialValues}
          enableReinitialize={this.state.venueId ? true : false}
          validationSchema={this.state.step === 1 ? Step1ValidationSchema : this.state.step === 2 ? Step2ValidationSchema : this.state.step === 3 ? Step3ValidationSchema : Step4ValidationSchema}
          onSubmit={(values, actions) => {
            this.handleSubmit(values, actions)
          }}
          render={props => (
            <form
              // onKeyDown={this.onKeyDown} 
              onSubmit={props.handleSubmit}>
              <StepProgressBar changeSubmission={this.changeSubmission} handleSubmit={props.handleSubmit} formName={this.state.venueId ? "Edit Venue" : "Add Venue"} step={this.state.step} incrementStep={this.incrementStep} decrementStep={this.decrementStep} />
              {this.state.step === 1 ?
                <AddVenueName formikProps={props} venueTypeList={this.props.venueTypeList} />
                : this.state.step === 2 ?
                  <AddVenueAddress onCitySelect={this.onCitySelect} 
                  isCitySelected={this.state.isCitySelected} citySuggestionList={this.state.citySuggestionList} states={this.props.states} onAirportSelect={this.onAirportSelect} isAirportSelected={this.state.isAirportSelected} airportSuggestionList={this.state.airportSuggestionList} anchorEl={this.state.anchorEl} handleCloseHelpIcon={this.handleCloseHelpIcon} formikProps={props} venueName={this.state.venueName} handleHelpIconClick={this.handleHelpIconClick} isToggleOpen={this.state.isToggleOpen} />
                  : this.state.step === 3 ?
                
                    <AddVenueContact 
                    onClick={this.onClick}
                    contactImageData={this.contactImageDataFunc} 
                    saveClick={this.saveClick} 
                    error={this.state.error} 
                    deleteImage={this.deleteImage} 
                    profilePhotoId={this.state.profilePhotoId} 
                    venueName={this.state.venueName} 
                    formikProps={props}/>
                  
                    :
                    <AddVenuePhoto
                      cropping={this.state.cropping}
                      cropDone={this.state.cropDone}
                      okButton={this.okButton}
                      cancelButton={this.cancelButton}
                      crop={this.state.crop}
                      zoom={this.state.zoom}
                      aspect={this.state.aspect}
                      onRotationChange={this.onRotationChange}
                      rotation={this.state.rotation}
                      onCropChange={this.onCropChange}
                      onZoomChange={this.onZoomChange}
                      onCropComplete={this.onCropComplete}
                      croppedImage={this.state.croppedImage}
                      setSubmitting={props.setSubmitting}
                      setFieldValue={props.setFieldValue}
                      venueImageError={venueImageError}
                      venueName={this.state.venueName}
                      handleChange={props.handleChange}
                      imageFile={this.state.imageFile}
                      imageFileName={this.state.imageFileName}
                      imageUploaded={this.state.imageUploaded}
                      imageUploading={this.state.imageUploading}
                      handleImageUpload={this.handleImageUpload}
                      handleImageChange={this.handleImageChange}
                      imageUrl={this.state.imageUrl}
                      formikProps={props}
                    />


              }
            </form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    states: state.data.auth.states,
    venueTypeList: state.data.venues.venueTypeList,
    venueDetails: state.data.venues.venueDetails,
    // planList: state.data.plan.planList,
    airportList: state.data.venues.airportList,
    cityList: state.data.city.cityList,
    userData: state.data.usersVenue.userInfo



  };
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    addVenue,
    venueTypes,
    getStates,
    getVenue,
    editVenue,
    venueImageUpload,
    contactImageUpload,
    deleteResource,
    deleteVenueImage,
    getUploadPostUrl,
    uploadtoS3,
    // updatePlan,
    // getPlans,
    getAirport,
    city,
    userDetails
  }, dispatch),
)(withRouter(AddVenue));

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './../assets/style/placeHold.scss';
import DialogActions from '@material-ui/core/DialogActions';
import { func } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { eventList } from "../../placeHolds/actions";
import { followDateData } from "../../followDateData/actions";
import { followAPI } from "../../followAPI/actions";
import { unFollowAPI } from "../../unFollowAPI/actions";
import { followUnfollowSuccess } from "../../followUnfollowSuccess/actions";
import FollowPopup from "shared/components/followPopup";
import CustomizedButton from 'shared/components/customizedButton';
import SeperateContactInfo from './seperateContactInfo';

import moment from "moment";

let datematch=[];
let eventArray=[];
// let datemat=false;
//provide dates between start date to end date
var getDaysArray = function(start, end) {
  for(var arr=[],dt=start; dt<=end; dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
  }
  
  return arr;
};
const styles = theme => ({
 
  root: {
    margin: 0,
    overflow:'visible',
  },
  paperWidthSm:{
  margin:'20px',
  overflow:'visible',
  },
 
  paper: {
    margin:'20px',
    overflow:'visible',
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
      fontWeight: '500',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.46px',
      color: 'red'
    }
  },
  button: {
    width: "110px",
    height: "36px",
    borderRadius: "6px",
    border: "solid 1px #e1e1e1",
    background: "#ffffff",
    fontFamily: "Raleway",
    padding: "0px",
    fontSize: "13px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    color: "#ee2738",
    [theme.breakpoints.down('xs')]: {
      borderRadius: '6px',
      // border: 'solid 1px red'
    }
  }
});

const DialogContent = withStyles(theme => ({
  root: {
  },
}))(MuiDialogContent);

class SeperateFollowDate extends React.Component {
  // constructor(props, context) {
  //   super(props, context);
  // }

  state = {
    callAPI:'',
    name:'',
    dateFollow:'',
    open: false,
    showForm: false,
    eventsPresent: false,
    eventsSelected: false,
    eventsChecked: false,
    buttonDisabled: true,
    radioSelectedValue: '',
    eventArray:[],
    followPopup:false,
    openFollowDate:false,
    };

  handleClose = () => {
    this.setState({ open: false,eventsPresent: true});
  };
  handleClosecontact = () => {
    this.setState({ open: false,eventsPresent: true});

  };
  handleClickOpen = () => {
    if(!localStorage.getItem('fhtoken') || parseInt(localStorage.getItem('type'))!==0 || parseInt(localStorage.getItem("type")) === 1){
      this.props.openLoginDailog();
    }else{
      this.setState({ open: true, galleryOpened: true });
    }
  };
  unFollowClick = (date) => {
    let dates=date.split('to');
    let updatedEventArray = this.state.eventArray;

    for(let j=0;j<updatedEventArray.length;j++)
    {
      if(date===updatedEventArray[j].date)
      {
        updatedEventArray[j].btn="Follow";
        this.setState({eventArray:updatedEventArray});
      }
    }

    if(dates.length > 1){

      var daylist = getDaysArray(new Date(moment(dates[0]).format("YYYY/MM/DD")),new Date(moment(dates[1]).format("YYYY/MM/DD")));
      daylist.map((v)=>v.toISOString().slice(0,10)).join("");
      for(let i=0;i<daylist.length;i++){

        for(let j=0;j<datematch.length;j++){

          if((moment(daylist[i]).format("YYYY-MM-DD"))===datematch[j].follow_date){

            this.props.unFollowAPI(datematch[j].id).then(() => {

            })

            if(j===datematch.length-1){
              this.props.followUnfollowSuccess();
            }
    
          }
        
        }
      }

      
    }
    else{



      for(let j=0;j<datematch.length;j++){
        if(moment(dates[0]).format("YYYY-MM-DD")===datematch[j].follow_date){
          this.props.unFollowAPI(datematch[j].id).then(() => {

          })
          for(let i=0;i<updatedEventArray.length;i++)
          {
            if(moment(dates[0]).format("YYYY-MM-DD")===updatedEventArray[i].date)
            {
              updatedEventArray[j].btn="Follow";
              this.setState({eventArray:updatedEventArray});
            }
          }
          
          this.props.followUnfollowSuccess();

        }
      }

    }

  }
  handleClosefollow=()=>{
    this.setState({openFollowDate:false});

  }
  followClick = (date,name,id) => {

    this.setState({openFollowDate:true,dateFollow:date,name:name});
    let dates=date.split('to');
    let updatedEventArray = this.state.eventArray;

 
    if(dates.length > 1){
      
      var daylist = getDaysArray(new Date(moment(dates[0]).format("YYYY/MM/DD")),new Date(moment(dates[1]).format("YYYY/MM/DD")));
      daylist.map((v)=>v.toISOString().slice(0,10)).join("");
      for(let i=0;i<daylist.length;i++){
        
        let json={
          follow_date:moment(daylist[i]).format("YYYY-MM-DD"),
          space_id:this.props.spaceId,
          event_id:id
        }
   
      
    
          this.props.followAPI(json).then(() => {

          })
        if(dates.length===i)
          {
            this.props.followUnfollowSuccess();
          }
      }
      for(let j=0;j<updatedEventArray.length;j++)
      {
        if(date===updatedEventArray[j].date)
        {
          updatedEventArray[j].btn="Unfollow";
          this.setState({eventArray:updatedEventArray});
        }
      }
    }
    else{
      let updatedEventArray = this.state.eventArray;
   
      for(let i=0;i<this.state.eventArray.length;i++)
      {
        if(moment(updatedEventArray[i].date).format('YYYY-MM-DD')===moment(date).format("YYYY-MM-DD"))
        {
          updatedEventArray[i].btn="Unfollow";
          this.setState({eventArray:updatedEventArray});
    
        }
      }
      let json={
        follow_date:moment(date).format("YYYY-MM-DD"),
        space_id:this.props.spaceId,
        event_id:id
      }
      this.props.followAPI(json).then(() => {
  
      })
      this.props.followUnfollowSuccess();
    }

  }
  componentWillMount() {
if(this.props.location.search==='?followDate=true'){
  localStorage.removeItem('redirect_path') 

  this.setState({open:true});
}
    this.setState({openFollowDate:false})


  }
  UNSAFE_componentWillReceiveProps(nextProps){
    this.setState({openFollowDate:false})

    if(this.props.newEvent !== nextProps.newEvent || this.props.followData !== nextProps.followData){   
      eventArray=[];
      this.setState({eventArray:[]})

      this.setState({eventsPresent:true}) 
        if(nextProps.followData.length>0){
          datematch=[];
          let spaceId=this.props.spaceId;
          this.props.followDateData({futureEvent:1}).then(() => {
            this.setState({callAPI:'calling'});
            // datemat=true;
      
            if(this.props.dateList.data) {
              this.props.dateList.data.map(function(data,idx){
                if(parseInt(spaceId)===parseInt(data.space_id))
                {
                  let obj={};
                  obj.id=data.id;
                  obj.follow_date=data.follow_date;
                  datematch.push(obj);
                }
                return datematch;
              })
            }
            
        nextProps.followData.map(function(data, idx) {
          if(data.id!=='dummyid'){

        let obj = {};
        // let start_date;
        // let end_date;
        obj.id = data.id;
        obj.name = data.name;
        obj.date=data.date;
        if(data.date.includes('to')){
          // let date=data.date.split('to');
          // start_date=date[0];
          // end_date=date[1];
        }
        else
        {
          // start_date=data.date;
          // end_date=data.date;
        }
        var daylist = getDaysArray(new Date(moment(data.start_date_follow).format("MM/DD/YYYY")),new Date(moment(data.end_date_follow).format("MM/DD/YYYY")));
        daylist.map((v)=>v.toISOString().slice(0,10)).join("");
        var un=0;
        for(let j=0;j<daylist.length;j++){
        if(datematch.length>0){
        for(let i=0;i<datematch.length;i++){

        if(moment(daylist[j]).format("YYYY-MM-DD")===datematch[i].follow_date){
        un=un+1;
        }
        }
        }
        }
        if(un===daylist.length){
        obj.btn="Unfollow";
        }
        else{
        obj.btn="Follow"
        }
        eventArray.push(obj);
      }
      return eventArray;
      })
      
        this.setState({eventArray:eventArray})

      })
        }
      
    }
    else if(this.props.newEvent!==nextProps.newEvent){
      eventArray=[];

      this.setState({eventsPresent:true}) 
        if(nextProps.followData.length>0){
          datematch=[];
          let spaceId=this.props.spaceId;
          this.props.followDateData({futureEvent:1}).then(() => {
            this.setState({callAPI:'calling'});
            // datemat=true;
      
            if(this.props.dateList.data) {
              this.props.dateList.data.map(function(data,idx){
                if(parseInt(spaceId)===parseInt(data.space_id))
                {
                  let obj={};
                  obj.id=data.id;
                  obj.follow_date=data.follow_date;
                  datematch.push(obj);
                }
                return datematch;
              })
            }
            
        nextProps.followData.map(function(data, idx) {
          if(data.id!=='dummyid'){

        let obj = {};
        // let start_date;
        // let end_date;
        obj.id = data.id;
        obj.name = data.name;
        obj.date=data.date;
        if(data.date.includes('to')){
          // let date=data.date.split('to');
          // start_date=date[0];
          // end_date=date[1];
        }
        else
        {
          // start_date=data.date;
          // end_date=data.date;
        }
        var daylist = getDaysArray(new Date(moment(data.start_date_follow).format("MM/DD/YYYY")),new Date(moment(data.end_date_follow).format("MM/DD/YYYY")));
        daylist.map((v)=>v.toISOString().slice(0,10)).join("");
        var un=0;
        for(let j=0;j<daylist.length;j++){
        if(datematch.length>0){
        for(let i=0;i<datematch.length;i++){

        if(moment(daylist[j]).format("YYYY-MM-DD")===datematch[i].follow_date){
        un=un+1;
        }
        }
        }
        }
        if(un===daylist.length){
        obj.btn="Unfollow";
        }
        else{
        obj.btn="Follow"
        }
        eventArray.push(obj);
      }
      return eventArray;
      })
      
        this.setState({eventArray:eventArray})

      })
        }
      
    }
  }

  handleLoginClick = () =>{
    localStorage.setItem('widgetLogin',true);
    localStorage.setItem('widget_path',this.props.location.pathname+"?followDate=true")
    this.props.history.push('/login')
  }

  handleSignUpClick = () =>{
    localStorage.setItem('widgetLogin',true);
    localStorage.setItem('widget_path',this.props.location.pathname+"?followDate=true")
    this.props.history.push('/register')
  }

  render() {
    const { classes } = this.props;
    // const eventsPresent = this.state.eventsPresent;
    return (

      <div className="place-hold">

        <Button variant="outlined" onClick={this.handleClickOpen} classes={{
          label: classes.label,
          root: classes.button
        }}>
        {this.props.label}

        </Button>

        <Dialog className="success_modal_container follow-date-popup"
          aria-labelledby="customized-dialog-title"
          open={this.state.open}>
          {parseInt(localStorage.getItem('type'))===0
          ?

          this.state.callAPI==='calling'?
          <div
          style={{lineHeight:'35px',fontWeight:'normal',fontSize:'15px'}}>
           <DialogActions className="dialogActions">
              <span
                className="material-icons close closeButton"
                onClick={this.handleClose}
              >
                close
              </span>
           </DialogActions>
            {
              parseInt(this.props.calendar_enabled)===1|| parseInt(this.props.calendar_enabled)===0?
               <div>
          <DialogActions className="dialogActions">
            <span className="material-icons close closeButton" onClick={this.handleClose}>close</span>
          </DialogActions>

          <DialogContent className="dialog-content">
            <Grid container className="modalBox_content">
            {this.state.openFollowDate===true ?
            <FollowPopup 
            name={this.state.name}
            date={this.state.dateFollow}
            handleClosefollow={this.handleClosefollow}/>:null}
              <Grid item xs={12} className="main_place_hold_section">
                  {
                    
                      this.state.eventsPresent && this.state.eventArray.length>0?
                    (
                      <div className="events">
                          <DialogActions className="dialogActions">
                              <span
                                className="material-icons close closeButton"
                                onClick={this.handleClose}
                              >
                                close
                              </span>
                          </DialogActions>
                        <div className="header">
                          <div className="header-text">
                          Follow a Date for Which Event?
                          </div>
                         
                        </div>
                        <div className="events-content" id="eventsContent">
                        {this.state.eventArray.map(data => {

                          return(
                          <div className="eventDiv">
                            <div className="detailsDiv">
                              <div className="large-text">
                                {data.name}
                                <p className="blue-text">
                                 {data.date}
                                </p>
                              </div>

                              <div className="actionDiv">
                                {/* <p style={{fontSize:'5px'}}>{data.btn}</p> */}
                              {data.btn==='Unfollow' ?

                              <Button
                              style={{width:'150px',padding:'7px'}}
                                  variant="outline-danger"
                                  class="white-button"
                                  onClick={()=>this.unFollowClick(data.date)}
                                >
                                  {data.btn}
                                </Button>
                              :
                              
                              <Button
                              variant="outline-danger"
                              className= "red-button"
                              onClick={()=>this.followClick(data.date,data.name,data.id)}
                            >
                                  {data.btn}
                              </Button> 
                              }
                              </div>
                            </div>
                          </div>
                          )

                        })}

                    
                        </div>
                      </div>
                    )
                      :
                      (
                        <div className="default">
                          <div className="header">
                            <div className="header-text">
                            To Follow a Date                              
                            </div>
                           
                          </div>
                          {/* <div className="uploader-content">
                            <div className="uploader">
                              <p className="red-text-content">No Event Created</p>
                            </div>
                          </div> */}
                          <div className="form-content">

                              <center>Please Log-in as a Planner</center>
                                  <div className="need_to_login_btn">
                                    <CustomizedButton onClickEvent={()=>this.handleSignUpClick()} label="Sign Up" type="submit" />
                                    <CustomizedButton onClickEvent={()=>this.handleLoginClick()} label="Sign In" type="submit" />
                                  </div>
                                  </div>
                          </div>
                      )
                    

                
              }

              </Grid>
            </Grid>
          </DialogContent>
              </div>
              :
           null
       }
            </div>
            :

              <div style={{textAlign:'center'}}>  
            <div className="default">
                <DialogActions className="dialogActions">
                  <span
                    className="material-icons close closeButton"
                    onClick={this.handleClose}
                  >
                    close
                  </span>
              </DialogActions>
          <div className="header">
            <div className="header-text">
            Follow a Date for Which Event?
            </div>
            </div>
            <div style={{padding:'20px'}}>
                Loading...
          </div>
  
            </div> 
            </div>
          
          : 
          
          <div className="default follow-up-signin">
            <DialogActions className="dialogActions">
            <span
              className="material-icons close closeButton"
              onClick={this.handleClose}
            >
              close
            </span>
      
          </DialogActions>
          <div className="header">
            <div className="header-text">
            To Follow a Date                              
            </div>
           
          </div>
          {/* <div className="uploader-content">
            <div className="uploader">
              <p className="red-text-content">No Event Created</p>
            </div>
          </div> */}
          <div className="form-content">

              <center>Please Log-in as a Planner</center>
                  <div className="need_to_login_btn">
                    <CustomizedButton onClickEvent={()=>this.handleSignUpClick()} label="Sign Up" type="submit" />
                    <CustomizedButton onClickEvent={()=>this.handleLoginClick()} label="Sign In" type="submit" />
                  </div>
                  </div>
          </div>
           }
      
        </Dialog>
      </div>
    );
  }
}

SeperateFollowDate.propTypes = {
  classes: PropTypes.object.isRequired,
  followDateData: func.isRequired,
  eventList:func.isRequired,
  followAPI:func.isRequired,
  unFollowAPI:func.isRequired,
  followUnfollowSuccess:func.isRequired

};
const mapStateToProps = state => {
  return {
    dateList: state.data.followDateData.followDateDetails,
    eventData: state.data.eventList.eventDetails,
    
  };
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        followDateData,
        eventList,
        followAPI,
        unFollowAPI,
        followUnfollowSuccess

      },
      dispatch
    )
  )
);
export default enhance(SeperateFollowDate);

import React, { Component } from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Icon, Avatar, Typography } from '@material-ui/core';
import { Field } from 'formik';
import './assets/style/ImageUpload.scss';
import getCroppedImg from '../../cropImage';
import Cropper from 'react-easy-crop'
import CustomizedButton from '../../shared/components/customizedButton';

const FILE_SIZE = 1024 * 1024 * 3;

const styles = theme => ({
  bigAvatar: {
    width: 100,
    height: 100,
  }
});
// var error='';

class ImageUpload extends Component {
  UNSAFE_componentWillMount() {

    if(localStorage.getItem('personalInfoImage'))
    {
      this.setState({
        cropDone:true
      })
    }

  }
  state = {
    imageFile: '',
    filled: true,
    isImagePresent: false,
    error:'',
    rotation:0,
    cropping:false,
    cropDone:false,
    cropData:'',
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1 / 1,
    croppedAreaPixels: null,
    croppedImage: null,
  }
  //for image cropper
  onCropChange = crop => {
    this.setState({ crop })

  }


  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels })
    // this.showCroppedImage()
  }

  okButton = async () => {
  
    try {
      this.setState({cropping:true})
      if(this.props.name==='contact_image'){
        this.props.setFieldValue("contact_image", '');
  
      }
      else{
        this.props.setFieldValue("image", '');
        localStorage.setItem('personalInfoImage', '');
  
      }

      const image = this.state.imageFile ? this.state.imageFile : this.state.imageUrl
      const croppedImage = await getCroppedImg(
        image,
        this.state.croppedAreaPixels,
        this.state.rotation
      )
  var c = document.getElementById("myCanvas");
  var ctx = c.getContext("2d");
  var img = document.getElementById("scream");
  var prop=this;
  img.src=croppedImage;
  if(this.props.name!=='contact_image'){

  localStorage.setItem('personalInfoImage', croppedImage);
  }
   img.onload=()=>{
    c.width='100';
    c.height='100';
    ctx.drawImage(img, 0, 0, 100,100);
     c.toBlob(function(blob) {
      var f = new File([blob], "sample.jpg", {type: "application/octet-stream"});
     prop.setState({cropData:f});
     prop.setState({cropDone:true,imageUrl : image,imageFile:croppedImage,cropping:false});
     if(prop.props.name==='contact_image'){
      prop.props.setFieldValue("contact_image", f);
      if(prop.props.saveClick)
      {
  
        prop.props.saveClick(f)
      }
  
     }
     else
     {
      prop.props.setFieldValue("image", f);

     }
     

    }, "image/jpeg", 0.75)

  }

    if(this.props.saveClick)
    {

      this.props.saveClick()
    }

      this.setState({ croppedImage: croppedImage,cropping:false })
      
    } catch (e) {
    }
  }

 cancelButton=(setFieldValue)=>{

  if(this.props.name==='contact_image'){
    if(this.props.profilePhotoId){
      setFieldValue('contact_image',this.props.profilePhotoId)

    }
    else
    {
      setFieldValue('contact_image','')

    }
    
  }

  else if(this.state.imageUrl===''){
    setFieldValue('image','')

  }

  this.setState({imageFileName:'',
  cropping:false,
  cropDone:true,
  imageUrl :'',
  imageFile:'',
  imageUploading:false,
  imageUploaded:this.state.imageUrl!==''?true:false});

}

onZoomChange = event => {
  this.setState({ zoom: event.currentTarget.value })
}
onRotationChange = event => {
  this.setState({ rotation: event.currentTarget.value })
}

  handleImageUpload = (e) => {
    var link;
    if(this.props.name==='contact_image'){
      link = document.getElementById('contact_image');

    }
    else
    {
      link = document.getElementById('image');

    }
    link.click();
  }

  onImageChange = (event) => {
    let img=event.currentTarget.files[0];
    // localStorage.setItem('personalInfoImage', '');
    if(this.props.name==='contact_image'){
      this.props.setFieldValue("contact_image", '');

    }
    else{
      this.props.setFieldValue("image", '');

    }
if(img){
  if(this.props.contactImageData){
      
    this.props.contactImageData(img.name);

  }
  if (!img.name.match(/.(jpg|jpeg)$/i)) {

    this.setState({error:"*Unsupported Format only jpg and jpeg images will be accepted"});
   
   }
   else if(img.size >= FILE_SIZE){
     this.setState({error:"*File size should not be greater than 3MB."});
   
   }
   else
   {
  this.setState({error:'',cropDone:false,
  cropping:true,imageFile:URL.createObjectURL(event.target.files[0])});

   }
}

  }
  render() {
    const { classes } = this.props;
    return (
      <div class="upload-block">
<img id="scream" style={{display:'none'}} alt="The Scream" crossOrigin="anonymous"/>
<canvas style={{display:'none'}} id="myCanvas"></canvas>
      <div className="uploadPhoto" onClick={this.state.cropping!==true?this.handleImageUpload:null}>

        <div className="user-icon">
        {this.props.name==='contact_image' ? 

                  ((this.props.value) && this.state.cropping===false) || this.state.imageFile ? 
                  <Avatar alt="User Image" src={this.state.imageFile? this.state.imageFile: this.props.value?this.props.value:''} className={classes.bigAvatar} />
                  
                    :
          
                    <Icon className="icon">person</Icon>
                
                  :
                  ((localStorage.getItem('personalInfoImage') || this.props.value) && this.state.cropping===false) ? 
          <Avatar alt="User Image" src={localStorage.getItem('personalInfoImage') ? localStorage.getItem('personalInfoImage') : this.props.value} className={classes.bigAvatar} />
          
            :

            <Icon className="icon">person</Icon>
          
        }

        </div>
{this.state.cropping===true?
  <div>
  <Cropper
                          image={this.state.imageFile? this.state.imageFile : this.state.imageUrl}
                          crop={this.state.crop}
                          zoom={this.state.zoom}
                          aspect={this.state.aspect}
                          rotation={this.state.rotation}
                          // onRotationChange={this.setRotation}
                          onCropChange={this.onCropChange}
                          onCropComplete={this.onCropComplete}
                          // onZoomChange={this.onZoomChange}
                      />
                      
  </div>
                      :
       <div>
        <div className="upload-image" >
          <Icon>
            <img className="image" src={require('./assets/images/upload.png')} alt="upload" />
          </Icon>
          <Typography>Upload<br /> Photo</Typography>
        
        </div>
     
        <div>
        <Field className="image_upload_file" name="upload_image" type="file" id={this.props.name==='contact_image'?"contact_image":"image"} accept="image/png, image/jpeg"
        onChange={e => {
          this.onImageChange(e)
        }} />

        </div>
        </div>

}
      </div>
      {this.state.cropping===true?
                        <div className="error" style={this.props.name==='contact_image'?{position:"initial"}:null}>*You need to crop this photo by clicking crop button below.</div>
                    :null}


{this.state.cropping ?  
                        <div className="crop-block">
                            <div className="field_div wid-50">
                                <div className="slider-container">
                                    <label className="container">
                                        Slide to Zoom photo
                                    </label>
                                    <br />
                                    <input
                                    style={{position:'absolute',padding:'5px'}}
                                    type="range"
                                    min="1"
                                    max="20"
                                    defaultValue={this.state.zoom}
                                    className="slider"
                                    id="myRange"
                                    step="1"
                                    // className="full-width"
                                    onChange={this.onZoomChange}
                                    />
                                </div>
                            </div>
                            <div className="field_div wid-50">
                            <div className="slider-container">
                                <label className="container">
                                    Slide to Rotate photo
                                </label>
                                <br />
                                <input type="range"
                                  min="0"
                                  max="360"
                                  style={{position:'absolute',padding:'5px'}}

                                  defaultValue={this.state.rotation}
                                  className="slider"
                                  id="myRotate"
                                  step="1"
                                  // className="full-width"
                                  onChange={this.onRotationChange}
                                />
                            </div>
                        </div>
                       
                 
                        {/* <div>
                          <img
                            src={props.croppedImage}
                            alt="Cropped"
                            className="max-full-width"
                          />
                        </div> */}
                     
     
                     <CustomizedButton  display="inline-block"  label="Crop" onClickEvent={()=>this.okButton(this.okButton)}/>
                              &nbsp;&nbsp;<CustomizedButton display="inline-block" label="Cancel" onClickEvent={()=>this.cancelButton(this.props.setFieldValue)}/>
                                
                                             <br />
                        <br />
                        </div>

                        :null }
                          <div style={{color:'red',fontSize:"10px"}}>{this.state.error}</div>

     

      </div>
    );
  }
}

ImageUpload.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(ImageUpload);

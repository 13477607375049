import { Component } from 'react';
import { compose } from "redux";



class Blog extends Component {

  componentDidMount(){
  window.location.href = 'https://first-hold.squarespace.com/'
  }
  render() {
    return null;
}
}
const enhance = compose(

  );  
export default enhance(Blog);


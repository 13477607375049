import { Record } from 'immutable';

import {
  GET_EVENT_TYPES,
  GET_ARRANGEMENTS,
  GET_NOTIFICATIONS,
  DELETE_RESOURCE,
  GET_UPLOAD_POST_URL,
  UPLOAD_TO_S3,
  DELETE_IMAGE_UPLOAD,
  LIST_YOUR_SPACE,
  GET_KEYS
} from 'shared/state/types';

class State extends Record({
  eventTypeList: {},
  requesting: false,
  requested: false,
  arrangements: {},
  deleted:false,
  deleting: false,
  error: '',
  success: '',
  notifications: {},
  homeBannerActiveSlide: 0,
  stripeKey:''
}) { }


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_EVENT_TYPES.REQUEST:
      return state.merge({
        requesting: true,
        requested: false,
        success: '',
        error: ''
      });
    case GET_EVENT_TYPES.SUCCESS:
      return state.merge({
        requesting: false,
        requested: true,
        eventTypeList: action.eventTypeList,
        error: ''
      });
    case GET_EVENT_TYPES.FAILURE:
      return state.merge({
        requesting: false,
        requested: false,
        success: '',
        eventTypeList: {},
        error: action.error
      });
    case GET_ARRANGEMENTS.REQUEST:
      return state.merge({LIST_YOUR_SPACE,
        requesting: true,
        requested: false,
        error: ''
      });
    case GET_ARRANGEMENTS.SUCCESS:
      return state.merge({
        requesting: false,
        requested: true,
        arrangements: action.arrangements,
        error: ''
      });
    case GET_ARRANGEMENTS.FAILURE:
      return state.merge({
        requesting: false,
        requested: false,
        arrangements: {},
        error: action.error
      });
    case GET_NOTIFICATIONS.REQUEST:
      return state.merge({
        requesting: true,
        requested: false,
        error: ''
      });
    case GET_NOTIFICATIONS.SUCCESS:
      return state.merge({
        requesting: false,
        requested: true,
        notifications: action.data,
        error: ''
      });
    case GET_NOTIFICATIONS.FAILURE:
      return state.merge({
        requesting: false,
        requested: false,
        notifications: {},
      });
    case DELETE_RESOURCE.REQUEST:
      return state.merge({
        deleting: true,
        deleted: false,
        error: ''
      });
    case DELETE_RESOURCE.SUCCESS:
      return state.merge({
        deleting: false,
        deleted: true,
        success: 'Image has been deleted successfully',
        error: ''
      });
    case DELETE_RESOURCE.FAILURE:
      return state.merge({
        deleting: false,
        deleted: false,
        arrangements: {},
        error: action.error
      });


      case GET_UPLOAD_POST_URL.REQUEST:
      return state.merge({
        success: '',
        error: ''
      });
    case GET_UPLOAD_POST_URL.SUCCESS:
      return state.merge({
        success: '',
        error: ''
      });
    case GET_UPLOAD_POST_URL.FAILURE:
      return state.merge({
        error: action.error,
        success:''
      });
   
      case UPLOAD_TO_S3.REQUEST:
      return state.merge({
        success: '',
        error: ''
      });
    case UPLOAD_TO_S3.SUCCESS:
      return state.merge({
        success: '',
        error: ''
      });
    case UPLOAD_TO_S3.FAILURE:
      return state.merge({
        error: action.error,
        success:''
      });
      case DELETE_IMAGE_UPLOAD.REQUEST:
      return state.merge({
        success: '',
        error: ''
      });
    case DELETE_IMAGE_UPLOAD.SUCCESS:
      return state.merge({
        success: '',
        error: ''
      });
    case DELETE_IMAGE_UPLOAD.FAILURE:
      return state.merge({
        error: action.error,
        success:''
      });
    case LIST_YOUR_SPACE:
      return state.merge({
        homeBannerActiveSlide: 1
      });
    case GET_KEYS.REQUEST:
      return state.merge({
        requesting: true,
        requested: false,
        success: '',
        error: ''
      });
    case GET_KEYS.SUCCESS:
      return state.merge({
        requesting: false,
        requested: true,
        stripeKey: action.data.data.stripe_key,
        error: ''
      });
    case GET_KEYS.FAILURE:
      return state.merge({
        requesting: false,
        requested: false,
        success: '',
        error: action.error
      });
      
    default:
      return state;
  }
}

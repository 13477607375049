import { SPACE_DOCUMENT_DELETE } from 'shared/state/types';
import { request } from 'shared/auth';

function spaceDocumentDeleteRequested() {
  return {
    type: SPACE_DOCUMENT_DELETE.REQUEST,
  };
}

function spaceDocumentDeleteFailed(error) {
  return {
    type: SPACE_DOCUMENT_DELETE.FAILURE,
    error:error,
  };
}

function spaceDocumentDeleteSuccess(data) {
  return {
    type: SPACE_DOCUMENT_DELETE.SUCCESS,
    payload: data,
  };
}

export function spaceDocumentDelete(spaceId,documentId) {
  return dispatch => {
    dispatch(spaceDocumentDeleteRequested());
    return request().delete(`spaces/${spaceId}/documents/${documentId}`)
      .then(response =>
        dispatch(spaceDocumentDeleteSuccess(response.data.data))
      )
      .catch(errors => {
        dispatch(spaceDocumentDeleteFailed(errors.response.data.errors));
        throw errors;
      });
  };
}

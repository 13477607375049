import { Record } from 'immutable';

import {
    GET_SUCCESS_ERROR_SUCCESS
  } from 'shared/state/types';

class State extends Record({
  success:'',
  error:''
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_SUCCESS_ERROR_SUCCESS:
      return state.merge({
        error:'',
        success:'Success'

      });


      
    default:
      return state;
  }
}


import { ADD_SPACE_AVAILABILITY } from 'shared/state/types';
import { request } from 'shared/auth';

function addSpaceAvailabilityRequested() {
  return {
    type: ADD_SPACE_AVAILABILITY.REQUEST,
  };
}

function addSpaceAvailabilityFailed(error) {
  return {
    type: ADD_SPACE_AVAILABILITY.FAILURE,
    error:error,
  };
}

function addSpaceAvailabilitySuccess(data) {
  return {
    type: ADD_SPACE_AVAILABILITY.SUCCESS,
    message: data,
  };
}

export function addSpaceAvailability(params) {
  return dispatch => {
    dispatch(addSpaceAvailabilityRequested());

    return request().post('/space_availables', params)
      .then(response =>{
          dispatch(addSpaceAvailabilitySuccess(response.data.message))
        }
      )
      .catch(errors => {
        if(errors.response && errors.response.data && errors.response.data.errors){
          dispatch(addSpaceAvailabilityFailed(errors.response.data.errors));
        }
        else{
          dispatch(addSpaceAvailability('Unable to access api'));
        }
        throw errors;
      });
  };
}

import {request} from 'shared/auth';
import {SAVE_GOOGLE_CALENDAR_EVENTS} from 'shared/state/types';

function onRequested() {
    return {type: SAVE_GOOGLE_CALENDAR_EVENTS.REQUEST};
}

function onSuccess(data) {
    return {type: SAVE_GOOGLE_CALENDAR_EVENTS.SUCCESS, data: data};
}

function onFailure(error) {
    return {type: SAVE_GOOGLE_CALENDAR_EVENTS.FAILURE, error: error};
}

export function updateGoogleCalendarEvents(params) {

    return dispatch => {
        dispatch(onRequested());
        return request()
            .post(`event_timeslots/bulk/update`, params)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.data.length === 0) {
                        dispatch(onSuccess(response.data.message.message))
                        return response.data.message.message;
                    } else {
                        dispatch(onSuccess(response.data.data))
                        return response.data.data;
                    }

                } else if (response.status === 400) {
                    dispatch(onFailure("The request was invalid."));
                } else if (response.status === 401) {
                    dispatch(onFailure("The request did not include an authentication token or the authentication token " +
                            "was expired."));
                } else if (response.status === 403) {
                    dispatch(onFailure("The client did not have permission to access the requested resource."));
                } else if (response.status === 404) {
                    dispatch(onFailure("The requested resource was not found."));
                } else if (response.status === 405) {
                    dispatch(onFailure("The HTTP method in the request was not supported by the resource."));
                } else if (response.status === 409) {
                    dispatch(onFailure("The request could not be completed due to a conflict. "));
                } else if (response.status === 500) {
                    dispatch(onSuccess("The request was not completed due to an internal error on the server side."));
                } else if (response.status === 503) {
                    dispatch(onFailure("The server was unavailable."));
                } else {
                    dispatch(onFailure("The server was unavailable."));
                }
                dispatch(onSuccess(response.data),)
                return response.data;
            })
            .catch(errors => {
                if (errors && errors) {
                    dispatch(onFailure(errors.toString()));
                } else {
                    dispatch(onFailure("Something went wrong."));
                }
                throw errors;

            });

    };
}

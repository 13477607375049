// Store all the action type constants here
/**
 * @typedef {Object} ReduxRequest
 * @property {string} REQUEST Fetch call requested
 * @property {string} SUCCESS Fetch call successful
 * @property {string} FAILURE Fetch call failed
 */
const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

/**
 * DO NOT EDIT THIS FUNC
 * @param {string} base the request base name i.e. LOAD_ACCOUNTS
 * @return {ReduxRequest}
 */
export function createAsyncTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}

//Favorite
export const CREATE_EVENT = createAsyncTypes('CREATE_EVENT');

//Favorite
export const MARK_FAV = createAsyncTypes('MARK_FAV');
export const DELETE_FAV = createAsyncTypes('DELETE_FAV');
export const GET_FAVOURITES_REQUEST='GET_FAVOURITES_REQUEST';
export const GET_FAVOURITES_FAILURE='GET_FAVOURITES_FAILURE';
export const GET_FAVOURITES_SUCCESS='GET_FAVOURITES_SUCCESS';

// Auth
export const LOGIN = 'LOGIN';
export const RECEIVE_AUTH = 'RECEIVE_AUTH';
export const SSO_AUTHENTICATE = createAsyncTypes('SSO_AUTHENTICATE');

export const LOGGING_IN = 'LOGGING_IN';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGING_IN_FAILED = 'LOGGING_IN_FAILED';

//venue details
export const GET_VENUE_REQUEST='GET_VENUE_REQUEST';
export const GET_VENUE_SUCCESS='GET_VENUE_SUCCESS';
export const GET_VENUE_FAILURE='GET_VENUE_FAILURE';
export const GET_ARTICLE_REQUEST='GET_ARTICLE_REQUEST';

//space details 
export const GET_SPACE_PROFILE_REQUEST='GET_SPACE_PROFILE_REQUEST';
export const GET_SPACE_PROFILE_SUCCESS='GET_SPACE_PROFILE_SUCCESS';
export const GET_SPACE_PROFILE_FAILURE='GET_SPACE_PROFILE_FAILURE';


//user info
export const GET_USER_INFO_REQUEST='GET_USER_INFO_REQUEST';
export const GET_USER_INFO_FAILURE='GET_USER_INFO_FAILURE';
export const GET_USER_INFO_SUCCESS='GET_USER_INFO_SUCCESS';

//venue list
export const GET_VENUE_LIST_REQUEST='GET_VENUE_LIST_REQUEST';
export const GET_VENUE_LIST_FAILURE='GET_VENUE_LIST_FAILURE';
export const GET_VENUE_LIST_SUCCESS='GET_VENUE_LIST_SUCCESS';

//event details
export const GET_EVENT_REQUEST='GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS='GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE='GET_EVENT_FAILURE';


//event details
export const GET_CREATE_EVENT_REQUEST='GET_CREATE_EVENT_REQUEST';
export const GET_CREATE_EVENT_SUCCESS='GET_CREATE_EVENT_SUCCESS';
export const GET_CREATE_EVENT_FAILURE='GET_CREATE_EVENT_FAILURE';

//googleKey
export const GET_GOOGLE_KEY_REQUEST='GET_GOOGLE_KEY_REQUEST';
export const GET_GOOGLE_KEY_SUCCESS='GET_GOOGLE_KEY_SUCCESS';
export const GET_GOOGLE_KEY_FAILURE='GET_GOOGLE_KEY_FAILURE';


//event type details
export const GET_EVENT_TYPE_REQUEST='GET_EVENT_TYPE_REQUEST';
export const GET_EVENT_TYPE_SUCCESS='GET_EVENT_TYPE_SUCCESS';
export const GET_EVENT_TYPE_FAILURE='GET_EVENT_TYPE_FAILURE';

//hold event details
export const GET_HOLD_EVENT_REQUEST='GET_HOLD_EVENT_REQUEST';
export const GET_HOLD_EVENT_SUCCESS='GET_HOLD_EVENT_SUCCESS';
export const GET_HOLD_EVENT_FAILURE='GET_HOLD_EVENT_FAILURE';


//DELETE event details
export const GET_DELETE_REQUEST='GET_DELETE_REQUEST';
export const GET_DELETE_SUCCESS='GET_DELETE_SUCCESS';
export const GET_DELETE_FAILURE='GET_DELETE_FAILURE';

//Book event details
export const GET_BOOK_EVENT_REQUEST='GET_BOOK_EVENT_REQUEST';
export const GET_BOOK_EVENT_SUCCESS='GET_BOOK_EVENT_SUCCESS';
export const GET_BOOK_EVENT_FAILURE='GET_BOOK_EVENT_FAILURE';

//update event DATA
export const GET_UPDATE_EVENT_DETAILS_REQUEST='GET_UPDATE_EVENT_DETAILS_REQUEST';
export const GET_UPDATE_EVENT_DETAILS_SUCCESS='GET_UPDATE_EVENT_DETAILS_SUCCESS';
export const GET_UPDATE_EVENT_DETAILS_FAILURE='GET_UPDATE_EVENT_DETAILS_FAILURE';


//update event details
export const GET_UPDATE_EVENT_REQUEST='GET_UPDATE_EVENT_REQUEST';
export const GET_UPDATE_EVENT_SUCCESS='GET_UPDATE_EVENT_SUCCESS';
export const GET_UPDATE_EVENT_FAILURE='GET_UPDATE_EVENT_FAILURE';

//delete event details
export const GET_DELETE_EVENT_REQUEST='GET_DELETE_EVENT_REQUEST';
export const GET_DELETE_EVENT_SUCCESS='GET_DELETE_EVENT_SUCCESS';
export const GET_DELETE_EVENT_FAILURE='GET_DELETE_EVENT_FAILURE';


//menu suggestion
export const GET_VENUE_SUGG_REQUEST='GET_VENUE_SUGG_REQUEST';
export const GET_VENUE_SUGG_SUCCESS='GET_VENUE_SUGG_SUCCESS';
export const GET_VENUE_SUGG_FAILURE='GET_VENUE_SUGG_FAILURE';

//feature type in edit venue
export const GET_FEATURE_REQUEST='GET_FEATURE_REQUEST';
export const GET_FEATURE_FAILURE='GET_FEATURE_FAILURE';
export const GET_FEATURE_SUCCESS='GET_FEATURE_SUCCESS';

//city
export const CITY_REQUEST='CITY_REQUEST';
export const CITY_FAILURE='CITY_FAILURE';
export const CITY_SUCCESS='CITY_SUCCESS';


//arrangements in browse venue
export const GET_ARRANGEMENTS_REQUEST='GET_ARRANGEMENTS_REQUEST';
export const GET_ARRANGEMENTS_FAILURE='GET_ARRANGEMENTS_FAILURE';
export const GET_ARRANGEMENTS_SUCCESS='GET_ARRANGEMENTS_SUCCESS';

export const LOGOUT = 'LOGOUT';
// Persons
export const REGISTER_USER = createAsyncTypes('REGISTER_USER');
export const EMAIL_EXISTS = createAsyncTypes('EMAIL_EXISTS');
export const FETCH_ME = createAsyncTypes('FETCH_ME');
export const SEND_CONFIRMATION = createAsyncTypes('SEND_CONFIRMATION');
export const UPDATE_USER = createAsyncTypes('UPDATE_USER');
export const GET_USER = createAsyncTypes('GET_USER');
export const VERIFY_CONFIRMATION = createAsyncTypes('VERIFY_CONFIRMATION');
export const IMAGE_UPLOAD = createAsyncTypes('IMAGE_UPLOAD');
export const CHANGE_PASSWORD = createAsyncTypes('CHANGE_PASSWORD');

//browse venue
export const GET_BROWSE_VENUE_REQUEST='GET_BROWSE_VENUE_REQUEST';
export const GET_BROWSE_VENUE_FAILURE='GET_BROWSE_VENUE_FAILURE';
export const GET_BROWSE_VENUE_SUCCESS='GET_BROWSE_VENUE_SUCCESS';

//category in browse venue
export const GET_CATEGORY_REQUEST='GET_CATEGORY_REQUEST';
export const GET_CATEGORY_FAILURE='GET_CATEGORY_FAILURE';
export const GET_CATEGORY_SUCCESS='GET_CATEGORY_SUCCESS';

//contact info
export const POST_CONTACT_REQUEST='POST_CONTACT_REQUEST';
export const POST_CONTACT_FAILURE='POST_CONTACT_FAILURE';
export const POST_CONTACT_SUCCESS='POST_CONTACT_SUCCESS';

//edit venue details
export const EDIT_VENUE_REQUEST='EDIT_VENUE_REQUEST';
export const EDIT_VENUE_FAILURE='EDIT_VENUE_FAILURE';
export const EDIT_VENUE_SUCCESS='EDIT_VENUE_SUCCESS';

//google Url info
export const POST_GOOGLE_SIGNUP_REQUEST='POST_GOOGLE_SIGNUP_REQUEST';
export const POST_GOOGLE_SIGNUP_FAILURE='POST_GOOGLE_SIGNUP_FAILURE';
export const POST_GOOGLE_SIGNUP_SUCCESS='POST_GOOGLE_SIGNUP_SUCCESS';

//google callback data
export const POST_GOOGLE_CALLBACK_REQUEST='POST_GOOGLE_CALLBACK_REQUEST';
export const POST_GOOGLE_CALLBACK_FAILURE='POST_GOOGLE_CALLBACK_FAILURE';
export const POST_GOOGLE_CALLBACK_SUCCESS='POST_GOOGLE_CALLBACK_SUCCESS';

//follow date list details
export const GET_FOLLOW_DATE_REQUEST='GET_FOLLOW_DATE_REQUEST';
export const GET_FOLLOW_DATE_SUCCESS='GET_FOLLOW_DATE_SUCCESS';
export const GET_FOLLOW_DATE_FAILURE='GET_FOLLOW_DATE_FAILURE';


//follow date create details
export const GET_CREATE_FOLLOW_DATE_REQUEST='GET_CREATE_FOLLOW_DATE_REQUEST';
export const GET_CREATE_FOLLOW_DATE_SUCCESS='GET_CREATE_FOLLOW_DATE_SUCCESS';
export const GET_CREATE_FOLLOW_DATE_FAILURE='GET_CREATE_FOLLOW_DATE_FAILURE';

//unfollow date create details
export const GET_DELETE_FOLLOW_DATE_REQUEST='GET_DELETE_FOLLOW_DATE_REQUEST';
export const GET_DELETE_FOLLOW_DATE_SUCCESS='GET_DELETE_FOLLOW_DATE_SUCCESS';
export const GET_DELETE_FOLLOW_DATE_FAILURE='GET_DELETE_FOLLOW_DATE_FAILURE';

//home page venue
export const GET_HOME_PAGE_REQUEST='GET_HOME_PAGE_REQUEST';
export const GET_HOME_PAGE_SUCCESS='GET_HOME_PAGE_SUCCESS';
export const GET_HOME_PAGE_FAILURE='GET_HOME_PAGE_FAILURE';

//for success message of follow or unfollow
export const GET_SUCCESS_ERROR_SUCCESS='GET_SUCCESS_ERROR_SUCCESS';

//Forgot Password
export const FORGOT_PASSWORD = createAsyncTypes('FORGOT_PASSWORD');

//Find Token
export const FIND_TOKEN = createAsyncTypes('FIND_TOKEN');

//Reset Password
export const RESET_PASSWORD = createAsyncTypes('RESET_PASSWORD');

//Add Space
export const ADD_SPACE = createAsyncTypes('ADD_SPACE');

//Add Space Timeslot
export const UPDATE_SPACE = createAsyncTypes('UPDATE_SPACE');

//Get Event Types
export const GET_EVENT_TYPES = createAsyncTypes('GET_EVENT_TYPES');

//Update Space Name
export const UPDATE_SPACE_NAME = createAsyncTypes('UPDATE_SPACE_NAME');

//Get Space
export const GET_SPACE = createAsyncTypes('GET_SPACE')

//Get Arrangements
export const GET_ARRANGEMENTS = createAsyncTypes('GET_ARRANGEMENTS')

//Enable signup Button
export const ENABLESIGNUPBUTTON = 'ENABLESIGNUPBUTTON';

//GET STATES
export const GET_STATES = createAsyncTypes('GET_STATES');

//Get Venue
export const GET_VENUE = createAsyncTypes('GET_VENUE');

//Add Venue
export const ADD_VENUE = createAsyncTypes('ADD_VENUE');

//Edit Venue
export const EDIT_VENUE = createAsyncTypes('EDIT_VENUE');

//Venue Types
export const VENUE_TYPES = createAsyncTypes('VENUE_TYPES');

//Add Space Image
export const ADD_SPACE_IMAGE = createAsyncTypes('ADD_SPACE_IMAGE');

//Delete Space Image
export const DELETE_SPACE_IMAGE = createAsyncTypes('DELETE_SPACE_IMAGE');

//Venue Image Upload
export const VENUE_IMAGE_UPLOAD = createAsyncTypes('VENUE_IMAGE_UPLOAD');

//contact image upload
export const CONTACT_IMAGE_UPLOAD = createAsyncTypes('CONTACT_IMAGE_UPLOAD');

//Delete Venue Image
export const DELETE_VENUE_IMAGE = createAsyncTypes('DELETE_VENUE_IMAGE');

//Venue Document Upload
export const VENUE_DOCUMENT_UPLOAD = createAsyncTypes('VENUE_DOCUMENT_UPLOAD');

//Venue Document Delete
export const VENUE_DOCUMENT_DELETE = createAsyncTypes('VENUE_DOCUMENT_DELETE');

//Space Document Upload
export const SPACE_DOCUMENT_UPLOAD = createAsyncTypes('SPACE_DOCUMENT_UPLOAD');

//Space Document Delete
export const SPACE_DOCUMENT_DELETE = createAsyncTypes('SPACE_DOCUMENT_DELETE');

//Get Notifications
export const GET_NOTIFICATIONS = createAsyncTypes('GET_NOTIFICATIONS');

//Delete Resource
export const DELETE_RESOURCE = createAsyncTypes('DELETE_RESOURCE');

//image delete upload
export const DELETE_IMAGE_UPLOAD = createAsyncTypes('DELETE_IMAGE_UPLOAD');


//Find Google Token
export const FIND_GOOGLE_TOKEN = createAsyncTypes('FIND_GOOGLE_TOKEN');
//Google Token info
export const FIND_GOOGLE_TOKEN_REQUEST='FIND_GOOGLE_TOKEN_REQUEST';
export const FIND_GOOGLE_TOKEN_FAILURE='FIND_GOOGLE_TOKEN_FAILURE';
export const FIND_GOOGLE_TOKEN_SUCCESS='FIND_GOOGLE_TOKEN_SUCCESS';

//Find Google Calendar
export const FIND_GOOGLE_CALENDAR = createAsyncTypes('FIND_GOOGLE_CALENDAR');
//Google Calendar List
export const FIND_GOOGLE_CALENDAR_REQUEST='FIND_GOOGLE_CALENDAR_REQUEST';
export const FIND_GOOGLE_CALENDAR_FAILURE='FIND_GOOGLE_CALENDAR_FAILURE';
export const FIND_GOOGLE_CALENDAR_SUCCESS='FIND_GOOGLE_CALENDAR_SUCCESS';

//page data
export const GET_PAGE_REQUEST='GET_PAGE_REQUEST';
export const GET_PAGE_FAILURE='GET_PAGE_FAILURE';
export const GET_PAGE_SUCCESS='GET_PAGE_SUCCESS';

//city data
export const GET_CITY_REQUEST='GET_CITY_REQUEST';
export const GET_CITY_FAILURE='GET_CITY_FAILURE';
export const GET_CITY_SUCCESS='GET_CITY_SUCCESS';

//single event
export const FIND_GOOGLE_CALENDAR_EVENTS_SINGLE = createAsyncTypes('FIND_GOOGLE_CALENDAR_EVENTS_SINGLE');

//Find Google Calendar  Events
export const FIND_GOOGLE_CALENDAR_EVENTS = createAsyncTypes('FIND_GOOGLE_CALENDAR_EVENTS');

//Save Google Calendar  Events
export const SAVE_GOOGLE_CALENDAR_EVENTS = createAsyncTypes('SAVE_GOOGLE_CALENDAR_EVENTS');

//Get upload post url
export const GET_UPLOAD_POST_URL=createAsyncTypes('GET_UPLOAD_POST_URL');

//Get upload post url
export const UPLOAD_TO_S3=createAsyncTypes('UPLOAD_TO_S3');

//Contact
export const CONTACT_US = createAsyncTypes('CONTACT_US');

//Contact
export const FAQ = createAsyncTypes('FAQ');

//Create plan
export const CREATE_PLAN = createAsyncTypes('CREATE_PLAN');

//Update plan
export const UPDATE_PLAN = createAsyncTypes('UPDATE_PLAN');

//List Your Space
export const LIST_YOUR_SPACE = 'LIST_YOUR_SPACE';

//Add Space Availability
export const ADD_SPACE_AVAILABILITY = createAsyncTypes('ADD_SPACE_AVAILABILITY');

//Get Space Availability
export const GET_SPACE_AVAILABILITY = createAsyncTypes('GET_SPACE_AVAILABILITY');

//Delete Space Availability
export const DELETE_SPACE_AVAILABILITY = createAsyncTypes('DELETE_SPACE_AVAILABILITY');


//Get airport
export const GET_AIRPORT = createAsyncTypes('GET_AIRPORT');

//Get plan
export const GET_PLAN = createAsyncTypes('GET_PLAN');

//Get all emails
export const GET_ALL_EMAILS = createAsyncTypes('GET_ALL_EMAILS');

//Get keys
export const GET_KEYS = createAsyncTypes('GET_KEYS');

//Get stripe
export const STRIPE_API = createAsyncTypes('STRIPE_API');

//Get Holds
export const GET_HOLDS = createAsyncTypes('GET_HOLDS');

//Get Holds
export const AUTO_LOGIN = createAsyncTypes('AUTO_LOGIN');

//Get Calendar
export const GET_CALENDAR = createAsyncTypes('GET_CALENDAR');

//Get Planner Calendar Data
export const GET_PLANNER_CALENDAR_DATA = createAsyncTypes('GET_PLANNER_CALENDAR_DATA');
//delete event details
export const UNBLOCK_EVENT_REQUEST='UNBLOCK_EVENT_REQUEST';
export const UNBLOCK_EVENT_SUCCESS='UNBLOCK_EVENT_SUCCESS';
export const UNBLOCK_EVENT_FAILURE='UNBLOCK_EVENT_FAILURE';
// Space Holiday Rates
export const SPACE_HOLIDAY_RATE = createAsyncTypes('SPACE_HOLIDAY_RATE');
export const GET_SPACE_HOLIDAY_RATE = createAsyncTypes('GET_SPACE_HOLIDAY_RATE');
export const DELETE_SPACE_HOLIDAY_RATE = createAsyncTypes('DELETE_SPACE_HOLIDAY_RATE');

//Get Planner Profile Image
export const GET_PLANNER_PROFILE = createAsyncTypes('GET_PLANNER_PROFILE');
//Venue Image Upload
export const UPDATE_IMAGE_PHOTOGRAPHER = createAsyncTypes('UPDATE_IMAGE_PHOTOGRAPHER');

//Get Planner Calendar Data
export const SUBSCRIBE = createAsyncTypes('SUBSCRIBE');
//Login with mobile
export const LOGIN_MOBILE = createAsyncTypes('LOGIN_MOBILE');
export const RESENDOTP = createAsyncTypes('RESENDOTP');
// Apply coupon code
export const COUPON_CODE = createAsyncTypes('COUPON_CODE');

//Edit Document 
export const EDIT_DOCUMENT = createAsyncTypes('EDIT_DOCUMENT');
export const SPACEVIDEOADD = createAsyncTypes('SPACEVIDEOADD');
export const SPACEVIDEOEDIT = createAsyncTypes('SPACEVIDEOEDIT');
export const SPACEVIDEODELETE = createAsyncTypes('SPACEVIDEODELETE');
export const GET_ARTICLE = createAsyncTypes('GET_ARTICLE');
export const UNSUBSCRIBE_PLAN = createAsyncTypes('UNSUBSCRIBE_PLAN');


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import CustomMenuItem from './customMenuItem';
import {getNotifications} from 'data/master/actions';
import './assets/style/NotificationPopup.scss';

import classNames from 'classnames';
import { Grid, Icon, Button, withStyles } from '@material-ui/core';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    // color: '#d81c2c',
    fontSize: '17px'
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
    // color: '#d81c2c',
    fontSize: '17px'
  },
});

class NotificationViewAll extends Component {
  state = {
    openNotification: false,
    notifications: [],
    loading: true,
    page: 1,
    loadMore: true
  };


  UNSAFE_componentWillMount(){
    this.props.getNotifications(this.state.page).then((response) => {
      this.setState({ loading: false })
      if(response.links && response.links.next === null){
        this.setState({loadMore: false})
      }
      else{
        this.setState({loadMore: true})
      }
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.notifications !== nextProps.notifications && Object.keys(nextProps.notifications).length !== 0){
      this.setState({notifications: nextProps.notifications.data , loading:false})
    }
  }
  
  handleClickBack = () =>{
    const decrPage = this.state.page - 1
    // if(!this.state.open && this.state.notifications.length === 0){
      this.props.getNotifications(decrPage).then((response) => {
        this.setState({ loading: false })
        if(response.links && response.links.next === null){
          this.setState({loadMore: false})
          this.setState({ page: decrPage });
        }
        else{
          this.setState({loadMore: true})
          this.setState({ page: decrPage });
        }
      })
    // }
  }
  handleClickNext = () =>{
    const incrPage = this.state.page + 1
    // if(!this.state.open && this.state.notifications.length === 0){
      this.setState({ loading: false })
      this.props.getNotifications(incrPage).then((response) => {
        if(response.links && response.links.next === null){
          this.setState({loadMore: false})
          this.setState({ page: incrPage });
        }
        else{
          this.setState({loadMore: true})
          this.setState({ page: incrPage });
        }
      })
    // }
  }
  render() {
    const { classes } = this.props;
    const notificationObject = this.state.notifications ?  this.state.notifications.map((data) => {
      return (
          <div>
            {data.resource_type === 13 || data.resource_type === 25 || data.resource_type === 22 ?
              <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}  eventName={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.name} eventDate={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.start_date} spaceName={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.name} venueName={data.resource && data.resource.space && data.resource.space.venue  &&  data.resource.space.venue.name} createdAt={data.created_at} spaceImage={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.photo && data.resource.event_timeslot.space.photo.length > 0  && data.resource.event_timeslot.space.photo[0].path} resourceId={data.resource && data.resource.id} holdDuration={data.resource && data.resource.hold_duration} expaired={data.resource && data.resource.expired_on_diff} eventType={data.resource && data.resource.hold_type}/>
              : data.resource_type === 23 || data.resource_type === 26 ?
              <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}  eventName={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.name} eventDate={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.start_date} spaceName={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.name} venueName={data.resource && data.resource.space && data.resource.space.venue  &&  data.resource.space.venue.name} createdAt={data.created_at} spaceImage={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.photo && data.resource.event_timeslot.space.photo.length > 0  && data.resource.event_timeslot.space.photo[0].path} price={data.resource.price} resourceId={data.resource && data.resource.id} holdDuration={data.resource && data.resource.hold_duration} expaired={data.resource && data.resource.expired_on_diff} eventType={data.resource && data.resource.hold_type} />
              : data.resource_type === 14 || data.resource_type === 24 ?
              <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}  eventName={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.event_planner ? `${data.resource.event_timeslot.event.event_planner.first_name} ${data.resource.event_timeslot.event.event_planner.last_name}` : ''} eventDate={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.start_date} spaceName={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.name} venueName={data.resource && data.resource.space && data.resource.space.venue  &&  data.resource.space.venue.name} createdAt={data.created_at} spaceImage={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.event_planner && data.resource.event_timeslot.event.event_planner.profilePhoto && data.resource.event_timeslot.event.event_planner.profilePhoto.path} price={data.resource.price} resourceId={data.resource && data.resource.id} holdDuration={data.resource && data.resource.hold_duration} expaired={data.resource && data.resource.expired_on_diff} eventType={data.resource && data.resource.hold_type} />
              : data.resource_type === 16 || data.resource_type === 17 ?
              <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}  eventName={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.event_planner ? `${data.resource.event_timeslot.event.event_planner.first_name} ${data.resource.event_timeslot.event.event_planner.last_name}` : ''} eventDate={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.start_date} spaceName={data.resource.event_timeslot && data.resource.event_timeslot.space && data.resource.event_timeslot.space.name} venueName={data.resource && data.resource.space && data.resource.space.venue  &&  data.resource.space.venue.name} createdAt={data.created_at} spaceImage={data.resource.event_timeslot && data.resource.event_timeslot.event && data.resource.event_timeslot.event.event_planner && data.resource.event_timeslot.event.event_planner.profilePhoto && data.resource.event_timeslot.event.event_planner.profilePhoto.path} price={data.resource.price} holdBook={this.holdBookApi} updateEventTimeSlot={this.updateEventTimeSlotApi} resourceId={data.resource && data.resource.id} eventTimeSlotId={data.resource && data.resource.event_timeslot_id} status={data.resource ? data.resource.status : ''} holdDuration={data.resource && data.resource.hold_duration} expaired={data.resource && data.resource.expired_on_diff} eventType={data.resource && data.resource.hold_type}/>
              : data.resource_type === 32 || data.resource_type === 33 || data.resource_type === 34 || data.resource_type === 35 ?
                <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}    createdAt={data.created_at} link={data.link} />
              : <CustomMenuItem handleClose={this.handleClose} resourceType={data.resource_type} readStatus={data.read_status} subject={data.subject}  eventName={data.resource.event && data.resource.event.name} eventDate={data.resource.event && data.resource.event.start_date} spaceName={data.resource.space && data.resource.space.name} venueName={data.resource && data.resource.space && data.resource.space.venue  &&  data.resource.space.venue.name} createdAt={data.created_at} spaceImage={data.resource.space && data.resource.space.photo && data.resource.space.photo.length > 0  && data.resource.space.photo[0].path} resourceId={data.resource && data.resource.id} holdDuration={data.resource && data.resource.hold_duration} expaired={data.resource && data.resource.expired_on_diff} eventType={data.resource && data.resource.hold_type} />
            }        
          </div>
        )
      })
      : null
    return (
      
      <div className="view-all-page">
        {this.state.loading === true ? 
        <div className="loading">
          Loading...
        </div>
        :
           <div> {notificationObject} </div>
             }     
            <Grid container>
              <Grid item xs={1} sm={2} md={4} lg={4} xl={4}></Grid>
              <Grid item xs={10} sm={8} md={4} lg={4} xl={4}>
                <Grid container>
                  <Grid xs={6}>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <Button size="small" className={classes.margin} onClick={this.handleClickBack} disabled={this.state.page === 1}>
                          <Icon className={classNames(classes.leftIcon, classes.iconSmall)}>arrow_back</Icon>
                          Back
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6}>
                    <Grid container justify="flex-start">
                      <Grid item>
                        <Button size="small" disabled={!this.state.loadMore} className={classes.margin} onClick={this.handleClickNext}>
                          Next
                          <Icon className={classNames(classes.rightIcon, classes.iconSmall)}>arrow_forward</Icon>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} sm={2} md={4} lg={4} xl={4}></Grid>
            </Grid>
        </div>

    );
  }
}

NotificationViewAll.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    notifications: state.data.master.notifications
  };
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(
    mapStateToProps,
    dispatch => bindActionCreators({
      getNotifications
    }, dispatch),
  ),
)

export default enhance(NotificationViewAll);

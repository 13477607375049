import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import VenueFooter from '../../../shared/footer/venueFooter';
import FHAgreement from '../components/fhAgreement';
import '../assets/styles/aboutUs.scss';
import { object, func } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { pageDetails } from "shared/pageDetails/actions";

class fhAgreement extends Component {
  componentWillMount() {
    this.props.pageDetails(3).then(() => {

      })
    }
  render() {
    return (
      <div className="venue_page">
        <Grid xs={12} item>
        </Grid>
        <Grid xs={12} item className="midContainer">
          <Grid container justify="center">
            <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
              <FHAgreement pageData={this.props.data}/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
          <Grid container justify="center">
            <VenueFooter />
          </Grid>
        </Grid>
      </div>

    );
  }
}


fhAgreement.propTypes = {
  classes: object.isRequired,
  pageDetails: func.isRequired


};

const mapStateToProps = state => {

  return {
  data:state.data.pageDetails.content
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        pageDetails
      },
      dispatch
    )
  )
);
export default enhance(fhAgreement);


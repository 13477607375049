import React from 'react';

import { Field } from 'formik';

import './assets/style/CustomCheckBox.scss';

function CustomizedCheckBox(props) {
    return (
        <div style={{marginTop:props.marginTop?props.marginTop:null}} className="custom_checkbox">
            <label className={(props.value) ? 'blue_text_container' : 'container' }>{props.label}
          {props.onChange?
          <Field 
          type="checkbox"
            disabled={props.disabled?props.disabled:false}
              name={props.name} 
              checked={props.value} 
              onChange={(e) => {
                 props.onChange(e , props.setFieldValue, props.name,props.values);
                 props.handleChange(e);

                }}/>
                :
                      
                <Field 
                type="checkbox"
                    name={props.name} 
                    checked={props.value} 
              />
        }
      
                <span className="checkmark"></span>
            </label>
        </div>
    );
}


export default CustomizedCheckBox;

import { request } from 'shared/auth';
import { LOGIN } from 'shared/state/types';

function loginSuccess(data) {
  return {
    type: LOGIN,
    payload: data,
  };
}

export function login(params) {
 return dispatch => {
   return request()
     .post('/auth/login', params)
     .then(response => {dispatch(loginSuccess(response.data.data));
    return response.data.data})
    .catch(error => {
      
    })
 };
}

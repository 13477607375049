import { FETCH_ME } from 'shared/state/types';
import { requestForUnauthorised } from 'shared/auth';

function fetchMeRequested() {
  return {
    type: FETCH_ME.REQUEST,
  };
}

function fetchMeFailed(error) {
  return {
    type: FETCH_ME.FAILURE,
    error,
  };
}

function fetchMeSuccess(data) {
  return {
    type: FETCH_ME.SUCCESS,
    payload: data,
  };
}

export function fetchMe() {
  return dispatch => {
    dispatch(fetchMeRequested());

    return requestForUnauthorised().get('/people/me')
      .then(response => dispatch(fetchMeSuccess(response.data.data)))
      .catch(error => {
        dispatch(fetchMeFailed(error));

        throw error;
      })
  };
}

import axios from 'axios';
import { GET_VENUE_LIST_REQUEST, GET_VENUE_LIST_SUCCESS, GET_VENUE_LIST_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'
function venueRequested() {
  return {
    type: GET_VENUE_LIST_REQUEST
  };
}

function venueSuccess(data) {
  return {
    type: GET_VENUE_LIST_SUCCESS,
    venueList: data,
  };
}

function venueFailure(error) {
  return {
    type: GET_VENUE_LIST_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function venueList() {

    return dispatch => {
      dispatch(venueRequested());
        return request().get(`venues`)
        .then(response =>
          dispatch (venueSuccess(response.data),)
        )
        .catch(errors => {
          dispatch(venueFailure(errors.response));
  
          throw errors;
        });
      

    
 };
}

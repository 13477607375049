import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

// import PlaceHold from 'shared/components/placeHold';
import CustomInput from '../../../shared/components/CustomInput';

import { Field, ErrorMessage } from 'formik';
import '../assets/styles/contactUs.scss';
import ReCAPTCHA from "react-google-recaptcha";
function VenueProfileComponent(props) {
 
    const onChange =(value)=>{
        props.formikProps.setFieldValue('captcha',value);
    }
    return (
        <Grid container className="contact_us">
            <Grid xs={12} item>
                <Grid container>
                    <Grid item md={12} xs={12} sm={12} xl={12} lg={12} className="avilableDescriptionDiv">
                        <div className="avilableDescription">
                            <h1>Contact Us</h1>
                            <hr className="divider"></hr>
                            <Grid container>
                                <Grid item md={4} xs={12} sm={4} xl={4} lg={4} className="avilableDescriptionDiv">
                                    <h3>Address</h3>
                                    <Typography color="textSecondary" className="matter aboutMatter">

                                    </Typography>
                                </Grid>
                                <Grid item md={8} xs={12} sm={8} xl={8} lg={8} className="avilableDescriptionDiv">
                                    <Grid container>
                                        <Grid item xs={4} md={2}>
                                            <label>Name : </label>
                                        </Grid>
                                        <Grid item className="bottomSpacing" xs={8} md={10}>
                                            <CustomInput type="text" name="name" placeholder="Name" />
                                        </Grid>

                                        <Grid item xs={4} md={2}>
                                            <label>E-Mail : </label>
                                        </Grid>
                                        <Grid item className="bottomSpacing" xs={8} md={10}>
                                            <CustomInput type="email" name="email" placeholder="Email" />
                                        </Grid>

                                        <Grid item xs={4} md={2}>
                                            <label>Contact No. : </label>
                                        </Grid>
                                        <Grid item className="bottomSpacing" xs={8} md={10}>
                                            <CustomInput type="phone" name="contact" placeholder="(000)-000-0000" />
                                        </Grid>
                                        <Grid item xs={4} md={2}>
                                            <label>Message : </label>
                                        </Grid>
                                        <Grid item className="bottomSpacing" xs={8} md={10}>
                                            <Field component="textarea" name="message" placeholder="Message" />
                                            <div className="error"><ErrorMessage name="message" /></div>
                                        </Grid>
                                        <Grid item xs={4} md={2}>
                                           
                                        </Grid>
                                        { props.captachKey && 
                                            <Grid item className="bottomSpacing" xs={8} md={10}>
                                                <ReCAPTCHA
                                                    sitekey={props.captachKey}
                                                    onChange={onChange}
                                                />
                                                <div className="error"><ErrorMessage name="captcha" /></div>
                                            </Grid>
                                        }
                                        <Grid item xs={4} md={2}>
                                           
                                        </Grid>
                                        <Grid item xs={10}>
                                            <button className="button" type="submit">Send</button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default VenueProfileComponent;

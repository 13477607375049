import React from 'react';
import PropTypes from 'prop-types';

import CustomInput from '../../shared/components/CustomInput';
import CustomSelectWithScroll from '../../shared/components/CustomSelectWithScroll';

import { ErrorMessage } from 'formik';
import Autocomplete from "react-autocomplete";
import { Grid, Hidden, Icon,  Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import '../assets/styles/addVenue.scss';

const styles = theme => ({
    icon: {
        marginLeft: '0px',
        paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    root: {
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    paper: {
        width: '237px',
        height: '120px',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        fontFamily: 'Raleway',
        backgroundColor: '#ffffff'
    }
});

function AddVenueAddress(props) {
    const { classes } = props;
    let states = []
    props.states !== [] && props.states.map(function (data, idx) {
        states.push(data.name)
        return states;
    });
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
      }  
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div className="add_venue_address_container">
            <Grid container className="add_venue_address">
                <Grid className="learning_hall_container" item xs={12}>
                    <h4 className="learning_hall">{props.formikProps.values.venueName}</h4>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Hidden xsDown={true}>
                            <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                        </Hidden>
                        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                            <Grid container className="add_venue_address_form" onMouseOut={props.handleCloseHelpIcon} >
                                <Grid  item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container>
                                        <Grid item className="what_is_address">What is the address for this venue?</Grid>
                                        <Grid className="help_icon">
                                            {/* <Grid onMouseOver={props.handleHelpIconClick} onClick={props.handleHelpIconClick} onMouseOut={props.handleCloseHelpIcon}>
                                                <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                            </Grid>
                                            <Popper className={classes.popper} open={open} anchorEl={anchorEl} placement="right-end">
                                                <Paper classes={{ root: classes.root }}>
                                                    <Typography className={classes.typography}>This should indicate the physical location of the venue. This is the address that should go on event invitations.</Typography>
                                                </Paper>
                                            </Popper> */}
                                            {/* <button aria-describedby={id} type="button" onClick={handleClick}>
                                                <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                            </button>
                                <Popper id={id} open={open} anchorEl={anchorEl}>
                                <div className={classes.paper}>This should indicate the physical location of the venue. This is the address that should go on event invitations.</div>
                                </Popper> */}
                                            <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                            <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                            </Icon>
                                            </Button>
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                                }}
                                            >
                                                <Typography className={classes.paper}>This should indicate the physical location of the venue. This is the address that should go on event invitations.</Typography>
                                            </Popover>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="address_1">
                                        <CustomInput label="Address #1" type="text" name="address1" placeholder="123 Street Ave." />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="address_2">
                                        <CustomInput label="Address #2" type="text" name="address2" placeholder="Address #2" />
                                    </div>
                                        
                                </Grid>
                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="city_name">
                                    <label>
                                    City
                                    </label>
                                        {/* <CustomInput label="City" type="text" name="cityName" placeholder="City Name" /> */}
                                        <Autocomplete
                                        fields={{ groupBy: 'value', value: 'menu' }}
                                        value={ props.formikProps.values.cityName }
                                        inputProps={{id: 'city',placeholder:'City Name'}}
                                        wrapperStyle={{ position: 'relative' }}
                                        items={ props.citySuggestionList }
                                        getItemValue={ item => item.key }
                                        shouldItemRender={(item, value) => item.key.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                        onChange={(e) => {
                                            props.onCitySelect(e.target.value);
                                            props.formikProps.setFieldValue('cityName', e.target.value)
                                        }}
                                        onSelect={(val) => {
                                            props.onCitySelect(val);
                                            props.formikProps.setFieldValue('cityName',val)}}
                                        renderMenu={ children => (
                                        <div className = "suggestionDiv">
                                            { children }
                                        </div>
                                        )}
                                        renderItem={ (item, isHighlighted) => (
                                        <div
                                        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                        key={ item.id } >
                                        { item.key }
                                        </div>
                                        )}
                                    />
                                    {!props.isCitySelected && props.formikProps.values.cityName.length > 0 ?
                                        <div className="error">*Select a valid  city</div>
                                    :
                                        <div className="error"><ErrorMessage name='cityName' /></div>
                                    }
                                    </div>




                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="state" style={{position: 'relative'}}>
                                        <CustomSelectWithScroll label="State/Province" showLabel placeholder="State" states={states} name="state" value={props.formikProps.values.state} handleChange={props.formikProps.handleChange} handleBlur={props.formikProps.handleBlur} />
                                    </div>
                                </Grid>
                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="zipcode">
                                        <CustomInput label="Zip Code" type="zip" name="zipcode" placeholder="12345" upperCaseInput/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="city_name">
                                    <label>
                                            Closest Airport
                                    </label>
                                    <Autocomplete
                                        fields={{ groupBy: 'value', value: 'menu' }}
                                        value={ props.formikProps.values.airport }
                                        inputProps={{id: 'airport',placeholder:'Airport closest to venue (including regional)'}}
                                        wrapperStyle={{ position: 'relative' }}
                                        items={ props.airportSuggestionList }
                                        getItemValue={ item => item.key }
                                        shouldItemRender={(item, value) => item.key.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                        onChange={(e) => {
                                            props.onAirportSelect(e.target.value);
                                            props.formikProps.setFieldValue('airport', e.target.value)
                                        }}
                                        onSelect={(val) => {
                                            props.onAirportSelect(val);
                                            props.formikProps.setFieldValue('airport',val)}}
                                        renderMenu={ children => (
                                        <div className = "suggestionDiv">
                                            { children }
                                        </div>
                                        )}
                                        renderItem={ (item, isHighlighted) => (
                                        <div
                                        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                        key={ item.id } >
                                        { item.key }
                                        </div>
                                        )}
                                    />
                                    {!props.isAirportSelected && props.formikProps.values.airport.length > 0 ?
                                        <div className="error">*Select a valid airport</div>
                                    :
                                        <div className="error"><ErrorMessage name='airport' /></div>
                                    }
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Hidden xsDown={true}>
                            <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

AddVenueAddress.propTypes = {
    classes: PropTypes.object.isRequired,
};

const enhance = withStyles(styles)

export default enhance(AddVenueAddress);

import { Record } from 'immutable';
import { SUBSCRIBE} from 'shared/state/types';
class State extends Record({
  sending: false,
  sended: false,
  error: '',
  success: ''
}) { }


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case SUBSCRIBE.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        success: '',
        error: ''
      });
    case SUBSCRIBE.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        success: action.success,
        error: ''
      });
    case SUBSCRIBE.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        error: action.error
      });
    default:
      return state;
  }
}


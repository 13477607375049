import React from 'react';
import '../assets/style/press.scss';
import { Grid } from '@material-ui/core';
import "react-alice-carousel/lib/alice-carousel.css";
import { Helmet } from "react-helmet";
import Footers from '../../landingPage/components/footer';;

function presslaunch(props) {
    return (
        <div className="press-page-ui">
            <div className="press-launch-ui container">
            <Helmet>
                    <title>Event Temple to Revolutionize The Events Industry | FIRST-HOLD</title>
                    <meta name="description" content="Event Temple, led by Jennifer Brown, is set to revolutionize the events industry with innovative solutions and approaches. Discover FIRST-HOLD and change to the game."></meta>
                    <meta name="robots" content="index"></meta>
                </Helmet>
                <Grid container className="banner-section">
                    <Grid className="banner-wrapper" item xs={12} sm={8} md={9} lg={9} xl={9}>
                        <div className="search-wrapper eventTemple" >
                            <div className="heading-text main">
                                <p>FIRST-HOLD partners with industry leader
                                    Event Temple to revolutionize the events industry</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <div className="logo-image">
                            <img className="" src={require('shared/components/assets/images/LogoWithText.png')} alt="FIRST-HOLD" />
                        </div>
                    </Grid>
                </Grid>
                <Grid container className="social-block">
                    <Grid className="banner-wrapper" item xs={12} sm={8} md={9} lg={9} xl={9}>
                        <div className="search-wrapper">
                            <div className="heading-text main">
                                <p class="light-text">News provided by</p>
                                <p class="bold-text">FIRST-HOLD</p>
                                <p class="light-text">October 09, 2023, 09:00 ET</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <div className="logo-image">
                            <p className="light-text">Share This Article</p>
                            <ul class="social-icon-block">
                                <li>
                                    <a href="https://www.facebook.com/">
                                        <img className="" src={require('../assets/images/facebook.png')} alt="FACEBOOK" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/">
                                        <img className="" src={require('../assets/images/twitter.png')} alt="TWITTER" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/">
                                        <img className="" src={require('../assets/images/linkedin.png')} alt="LINKEDIN" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.pinterest.com/">
                                        <img className="" src={require('../assets/images/pinterest.png')} alt="PINTEREST" />
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:?subject=FIRST-HOLD Press Release | For Immediate Release&body=https://first-hold.com/press-san-francisco-launch">
                                        <img className="" src={require('../assets/images/email.png')} alt="EMAIL" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                            <div className="heading-text main">
                                <p class="light-text">Contact</p>
                                <p class="bold-text">Jennifer Brown</p>
                                <p class="bold-text"><a href="mailto:hello@first-hold.com">hello@first-hold.com</a></p>
                            </div>
                    </Grid>
                </Grid>
                <div className="press-launch-description">
                    <p>FOR IMMEDIATE RELEASE</p>
                    <p>San Francisco, CA - Event Temple, an award-winning hotel & venue management software, has announced a new integration with FIRST-HOLD, a premier venue availability management solution.  The integration will allow event venue managers to seamlessly display their available dates within their existing workflow, making the event venue sourcing process easier and more efficient than ever before.</p>
                    <p>With this integration, Event Temple users can now easily sync their venue's availability with FIRST-HOLD's real-time availability management system. This means that venue managers can display their venue's available dates and times to potential clients without ever leaving their existing workflow. This streamlined process not only saves time but also ensures that event planners are able to quickly and easily find available dates for their events.</p>
                    <p>“We are thrilled to announce our integration with FIRST-HOLD," said Trevor MacLean, Strategic Partnerships Manager at Event Temple.  "At Event Temple we are focused on offering an easy to use and integrated solution to help hotels and venues grow revenue and save time. Making the event planning and booking process as seamless as possible, and this integration is a huge step in that direction. By bringing together our powerful venue management platform with FIRST-HOLD's availability management system, we are empowering venue managers to provide an even better experience for their clients.”</p>
                    <p>The integration between Event Temple and FIRST-HOLD is designed to be easy to use, allowing venue managers to quickly and easily manage their venue's availability. With just a few steps, venue managers can sync their venue's availability with FIRST-HOLD's system, making it easy for clients to see what dates and times are available.</p>
                    <p>Event Temple customers will also benefit from FIRST-HOLD's date availability widget that can be added to their own website which will mark a significant enhancement for Event Temple user’s customer experience. With this innovative addition, customers now have yet another seamless touchpoint to effortlessly check the availability of dates.</p>
                    <p>This intuitive feature not only streamlines the planning process but also empowers people planning events with real-time access to crucial information, ensuring they can make informed decisions and plan their events with utmost convenience.</p>
                    <p>"We are excited to partner with Event Temple to provide an enhanced experience for venue managers," said Jennifer Brown, President of FIRST-HOLD. "Our real-time availability management system is the perfect complement to Event Temple’s powerful event management platform, and we look forward to helping venue managers streamline their workflow and provide a better experience for their clients."</p>
                    <p>FIRST-HOLD and Event Temple are committed to delivering top-tier service and convenience, and this technological collaboration exemplifies our dedication to providing an unparalleled experience for our valued customers.</p>
                    <p>The Event Temple and FIRST-HOLD integration is available now for all FIRST-HOLD and Event Temple users.</p>
                    <p>To learn more about the integration, visit FIRST-HOLD or Event Temple’s website or contact the companies directly.</p>
                    <p class="bold-text">Event Temple <br/><a href="mailto:info@eventtemple.com">info@eventtemple.com</a></p>
                    <p class="bold-text">FIRST-HOLD<br/><a href="mailto:hello@first-hold.com">hello@first-hold.com</a></p>

                </div>
            </div>
            <Footers />
        </div>
    );
}
export default presslaunch;

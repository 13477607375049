import React from 'react';
import {object} from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Hidden, Button } from '@material-ui/core';
import { Helmet } from "react-helmet";


const styles = theme => ({
    root: {
        width: '176px',
        height: '49px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        background: 'linear-gradient(to bottom, #f1435e, #ee2738)',       
        fontSize: '13px',
        fontWeight: '500', 
        marginBottom: '32px',
        '&$disabled': {
          background: '#9aa7af',
          color: 'white',
          boxShadow: 'none',
        },
        '&:hover': {
            background: "#d80e2a"
        }
      },
    disabled: {
    },
});


function EventPlanner(props) {
    const { classes } = props;
    return (
        <Grid container className="event_planner fh-container">
            <Helmet>
            <title>Unique Wedding Destinations | Creative Wedding Venues</title>
          <meta name="description" content=" Creative wedding venues for your unforgettable day. FIRST-HOLD has a variety of unique wedding locations for you to choose from."></meta>
          <link rel="canonical" href="https://www.first-hold.com/planners" />
          <meta property="og:title" content="Unique Wedding Destinations | Creative Wedding Venues" />
          <meta property="og:description" content="Creative wedding venues for your unforgettable day. FIRST-HOLD has a variety of unique wedding locations for you to choose from." />
          <meta property="og:url" content="https://www.first-hold.com/planners" />
          <meta property="og:image" content="https://www.first-hold.com/static/media/LogoWithText.6a00fa2d.png" />
          <meta property="og:type" content="website" />
          <meta name="publisher" content="FIRST-HOLD" />
        </Helmet>
            { <Grid xs={12} item>
                <Grid container>
                    <Grid item md={4} lg={4} xl={4} implementation="css" smDown component={Hidden} />
                    <Grid item className="event_planner_heading" xs={12} md={12} sm={12} lg={12} xl={12}>
                        <Grid className="event-planner">At FIRST-HOLD, we’re upgrading the venue selection experience for everyone:  planners, caterers, and venue managers.
                        </Grid>
                        {/* <Grid className="get-qualified">We believe it should be easy for planners to discover and instantly place holds on unique professional event venues that fit their event size, style, and budget.</Grid> */}
                    </Grid>
                    <Grid item md={4} lg={4} xl={4} implementation="css" smDown component={Hidden} />
                </Grid>
            </Grid> }
            <Grid xs={12} item style={{margin: "12px 0 4px 0"}}>
                <Grid container justify="center" spacing={0}>
                    <Grid item className="theresa_image">
                        <img className="event_planner_image" src={require('../assets/images/Planner_1.png')} alt="image1"/>
                        <Grid item className="event_planner_name">
                            {/* <img className="verified_image" src={require('../assets/images/verified.png')} alt="Verified"/> */}
                        </Grid>
                    </Grid>
                    <Grid item className="danny_image">
                        <img className="event_planner_image" src={require('../assets/images/Planner_2.png')} alt="image2"/>
                        <Grid item className="event_planner_name">
                            {/* <img className="verified_image" src={require('../assets/images/verified.png')} alt="Verified"/> */}
                        </Grid>
                    </Grid>
                    <Grid item className="jennie_image">
                        <img className="event_planner_image" src={require('../assets/images/Planner_3.png')} alt="image3"/>
                        <Grid item className="event_planner_name">
                            {/* <img className="verified_image" src={require('../assets/images/verified.png')} alt="Verified"/> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid xs={12} item>
                <Grid className="device_image" container justify="center">
                    <img className="device_image_dimension" src={require('../assets/images/device.png')} alt="device"/>
                </Grid>
            </Grid> */}
            <Grid xs={12} item>
                <Grid container className="be_ready" justify="center">
                    Source the perfect venue in a matter of minutes.
                </Grid>
            </Grid>
            <Grid xs={12} item>
                <Grid container justify="center">
                    <Button
                     href="/register" 
                    classes={{
                        root: classes.root,
                        disabled: classes.disabled,
                    } }
                    >
                    Get started now
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

EventPlanner.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(EventPlanner);

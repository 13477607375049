import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import './assets/style/ModalBox.scss';


const styles = theme => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    color: '#9e9e9e',
    right: '8px',
  },
});

withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
  },
}))(MuiDialogActions);

class ModalBoxEvent extends React.Component {
  state = {
    open: this.props.check,
  };
  componentWillMount() {
  
}
componentWillReceiveProps(nextProps){
this.setState({open:nextProps.check})
}
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
redirect = () =>{
    window.open("https://chrome.google.com/webstore/detail/first-hold/gbgllbpnnhldcnpkbfmiiikkbggjknke");
    }
  render() {

    return (
      <div>
        {/* <Button variant="outlined" color="secondary" onClick={this.handleClickOpen}>
          Open dialog
        </Button> */}
        <Dialog className="success_modal_container"
          aria-labelledby="customized-dialog-title"
          open={this.state.open} >

          <DialogContent className="dialog-content">
            <Grid container className="modalBox_content">
       
              <Grid item xs={12}>
                <Grid container className="boxMatter">
                  <Grid item xs={12}>&nbsp;</Grid>
                  <Grid item xs={12}>
                    <div className="dont_single">Don’t miss a single update on your holds</div>
                  </Grid>
                  <Grid item xs={12}>&nbsp;</Grid>
                  <Grid item md={12} sm={12}>
                    <div className="dont_p dont_p_text dont_p-d-none-sm download-ext-text">Manage your holds and see notification in your browser window!  Download our Extension, it’s Fast, Easy and FREE. </div>
                    <div className="dont_p dont_p-d-none-lg">Manage your holds and see notifications in your browser window!  Download our Extension from your computer.  It’s Fast, Easy and FREE</div>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} className="">
                    <DialogActions className="action-center">
                      <Button onClick={()=>this.redirect()} className="button-bg dont_p-d-none-sm download-ext-btn">
                        Download Extension
                          </Button>
                          <Button style={{display:'none'}} onClick={()=>this.handleClose()} className="button-bg ok-class-show">
                            Ok
                          </Button>
                          <div className="cancel_button">
                          <DialogActions className="dialogActions">
                                 <span className="material-icons close closeButton" onClick={this.handleClose}>close</span>
                         </DialogActions>
                        </div>            
                                </DialogActions>
                  </Grid>
                  {/* <Grid item xs={12} md={12} sm={12}>
                    <span className="noThank_btn" onClick={this.handleClose} >No, Thanks</span>
                  </Grid> */}
                </Grid>
              </Grid>

              <Grid item xs={12}>&nbsp;</Grid>

            </Grid>

          </DialogContent>



        </Dialog>


      </div>
    );
  }
}

export default ModalBoxEvent;

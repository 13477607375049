import { Record } from 'immutable';

import {
  ADD_VENUE,
  VENUE_TYPES,
  GET_VENUE,
  EDIT_VENUE,
  VENUE_IMAGE_UPLOAD,
  CONTACT_IMAGE_UPLOAD,
  DELETE_VENUE_IMAGE,
  VENUE_DOCUMENT_UPLOAD,
  VENUE_DOCUMENT_DELETE,
  GET_AIRPORT,
  UPDATE_IMAGE_PHOTOGRAPHER,
  EDIT_DOCUMENT
} from 'shared/state/types';
import { IMAGE_UPLOAD,FIND_GOOGLE_CALENDAR_EVENTS,FIND_GOOGLE_CALENDAR_EVENTS_SINGLE, SAVE_GOOGLE_CALENDAR_EVENTS } from '../../shared/state/types';
const nl2br = require('react-nl2br');

class State extends Record({
  venueData: {},
  successDocument:'',
  sending: false,
  sended: false,
  requested: false,
  requesting: false,
  updating: false,
  updated: false,
  error: '',
  success: '',
  venueTypeList: {},
  venueDetails: {},
  deleted: false,
  deleting: false,
  airportList: [],
  venueError: {}
}) { }


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case ADD_VENUE.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        success: '',
        error: ''
      });
    case ADD_VENUE.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        // success: 'Venue has been successfully added.',
        venueData: action.venueData,
        error: ''
      });
    case ADD_VENUE.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        venueData: {},
        venueError: action.error
      });
    case EDIT_VENUE.REQUEST:
      return state.merge({
        updating: true,
        updated: false,
        success: '',
        error: ''
      });
    case EDIT_VENUE.SUCCESS:
      return state.merge({
        updating: false,
        updated: true,
        success: 'Data has been saved successfully.',
        venueData: action.venueData,
        error: ''
      });
    case EDIT_VENUE.FAILURE:
      return state.merge({
        updating: false,
        updated: false,
        success: '',
        venueData: {},
        venueError: action.error
      });
    case VENUE_TYPES.REQUEST:
      return state.merge({
        requesting: true,
        requested: false,
        sending: true,
        sended: false,
        success: '',
        error: ''
      });
    case VENUE_TYPES.SUCCESS:
      return state.merge({
        requesting: false,
        requested: true,
        venueTypeList: action.venueTypeList,
        error: '',
        sending: false,
        sended: true,
      });
    case VENUE_TYPES.FAILURE:
      return state.merge({
        requested: false,
        requesting: false,
        success: '',
        venueData: {},
        error: action.error,
        sending: false,
        sended: true,
      });
    case GET_VENUE.REQUEST:
      return state.merge({
        requesting: true,
        requested: false,
        success: '',
        error: ''
      });
    case GET_VENUE.SUCCESS:
      return state.merge({
        requesting: false,
        requested: true,
        venueDetails: action.venueDetails,
        error: ''
      });
    case GET_VENUE.FAILURE:
      return state.merge({
        requested: false,
        requesting: false,
        success: '',
        venueData: {},
        error: action.error
      });
    case IMAGE_UPLOAD.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: ''
      });
    case IMAGE_UPLOAD.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        success: 'image uploaded',
        error: ''
      });
    case IMAGE_UPLOAD.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        error: action.error
      });
      case DELETE_VENUE_IMAGE.REQUEST:
      return state.merge({
        deleting: true,
        deleted: false
      });
    case DELETE_VENUE_IMAGE.SUCCESS:
      return state.merge({
        deleting: false,
        deleted: true,
        success:"Image has been deleted successfully."
      });
    case DELETE_VENUE_IMAGE.FAILURE:
      return state.merge({
        deleting: false,
        deleted: false,
        error: action.error
  });
  case VENUE_IMAGE_UPLOAD.REQUEST:
  return state.merge({
    sending: true,
    sended: false,
    error: ''
  });
case VENUE_IMAGE_UPLOAD.SUCCESS:
  return state.merge({
    sending: false,
    sended: true,
    success: 'Image uploaded successfully.',
    error: ''
  });
case VENUE_IMAGE_UPLOAD.FAILURE:
  return state.merge({
    sending: false,
    sended: false,
    error: 'Image could not be uploaded.'
});

case CONTACT_IMAGE_UPLOAD.REQUEST:
return state.merge({
  sending: true,
  sended: false,
  error: ''
});
case CONTACT_IMAGE_UPLOAD.SUCCESS:
return state.merge({
  sending: false,
  sended: true,
  success: 'Image uploaded successfully.',
  error: ''
});
case CONTACT_IMAGE_UPLOAD.FAILURE:
return state.merge({
  sending: false,
  sended: false,
  error: 'Image could not be uploaded.'
});

  
    case VENUE_DOCUMENT_UPLOAD.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        succes:'',
        error: '',
        successDocument:''
      });
    case VENUE_DOCUMENT_UPLOAD.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        success:'',
        successDocument: 'Document uploaded successfully.',
        error: ''
      });
    case VENUE_DOCUMENT_UPLOAD.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success:'',
        successDocument:'',
        error: 'document could not be uploaded.'
      });
    case VENUE_DOCUMENT_DELETE.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: '',
        success:'',
        successDocument:''
      });
    case VENUE_DOCUMENT_DELETE.SUCCESS:
      return state.merge({
      
        sending: false,
        sended: true,
        success: 'Document deleted successfully.',
        error: '',
        successDocument:''
      });
    case VENUE_DOCUMENT_DELETE.FAILURE:
      return state.merge({
        sending: false,
        sended: true,
        error: 'Document could not be deleted.',
        success:'',
        successDocument:''
      });
      case FIND_GOOGLE_CALENDAR_EVENTS.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: ''
      });
    case FIND_GOOGLE_CALENDAR_EVENTS.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        success: '',
        error: ''
      });
    case FIND_GOOGLE_CALENDAR_EVENTS.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        error: action.error
      });
      case FIND_GOOGLE_CALENDAR_EVENTS_SINGLE.REQUEST:
        return state.merge({
          sending: true,
          sended: false,
          error: ''
        });
      case FIND_GOOGLE_CALENDAR_EVENTS_SINGLE.SUCCESS:
        return state.merge({
          sending: false,
          sended: true,
          success: '',
          error: ''
        });
      case FIND_GOOGLE_CALENDAR_EVENTS_SINGLE.FAILURE:
        return state.merge({
          sending: false,
          sended: false,
          error: action.error
        });

      case FIND_GOOGLE_CALENDAR_EVENTS_SINGLE.REQUEST:
        return state.merge({
          sending: true,
          sended: false,
          error: ''
        });
      case FIND_GOOGLE_CALENDAR_EVENTS_SINGLE.SUCCESS:
        return state.merge({
          sending: false,
          sended: true,
          success: '',
          error: ''
        });
      case FIND_GOOGLE_CALENDAR_EVENTS_SINGLE.FAILURE:
        return state.merge({
          sending: false,
          sended: false,
          error: action.error
        });
  
      case SAVE_GOOGLE_CALENDAR_EVENTS.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: ''
      });
    case SAVE_GOOGLE_CALENDAR_EVENTS.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        success: action.data ? nl2br(action.data):action.data,
        error: ''
      });
    case SAVE_GOOGLE_CALENDAR_EVENTS.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        error: action.error
      });
    case GET_AIRPORT.REQUEST:
      return state.merge({
        requesting: true,
        requested: false,
        success: '',
        error: ''
      });
    case GET_AIRPORT.SUCCESS:
      return state.merge({
        requesting: false,
        requested: true,
        airportList: action.airportList,
        error: ''
      });
    case UPDATE_IMAGE_PHOTOGRAPHER.REQUEST:
      return state.merge({
        requesting: true,
        requested: false,
        success: '',
        error: ''
      });
    case UPDATE_IMAGE_PHOTOGRAPHER.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        success:action.message,
        error: ''
      });
    case UPDATE_IMAGE_PHOTOGRAPHER.FAILURE:
      return state.merge({
        sending: false,
        sended: true,
        success: '',
        error: action.error
      });  
    case EDIT_DOCUMENT.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: '',
        success:'',
        successDocument:''
      });
    case EDIT_DOCUMENT.SUCCESS:
      return state.merge({      
        sending: false,
        sended: true,
        success: 'Document updated successfully.',
        error: '',
        successDocument:''
      });
    case EDIT_DOCUMENT.FAILURE:
      return state.merge({
        sending: false,
        sended: true,
        error: action.error,
        success:'',
        successDocument:''
      }); 
    // case GET_VENUE.FAILURE:
    //   return state.merge({
    //     requested: false,
    //     requesting: false,
    //     success: '',
    //     error: action.error
    //   });
    default:
      return state;
  }
}


import React from 'react';

// import { sortable } from 'react-sortable';
import { Grid,  Icon, LinearProgress } from '@material-ui/core';

import '../assets/styles/imageUploadPopUp.scss';

// class Item extends React.Component {
//     render() {
//       return (
        // <li {...this.props}>
        //     <div className="uploaded_screen">
        //         <Grid container className="image_details">
        //             <Grid item className="drag_icon_container" xs={3} sm={1} md={1} lg={1} xl={1}>
        //                 <Icon className={this.props.classes.handle}>drag_handle</Icon>
        //             </Grid>
        //             <Grid item xs={8} sm={3} md={3} lg={3} xl={3}>
        //                 <Grid container className="name_and_progess_bar_container">
        //                     <Grid item xs={12} className="image_name">
        //                         {this.props.children}
        //                     </Grid>
        //                     <Grid item xs={12} className="progess_bar">
        //                         <LinearProgress
        //                             classes={{
        //                                 colorPrimary: this.props.classes.linearColorPrimary,
        //                                 barColorPrimary: this.props.classes.linearBarColorPrimary,
        //                             }}
        //                         />
        //                     </Grid>
        //                 </Grid>
        //             </Grid>
        //             <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
        //             <Grid item xs={5}>

        //             </Grid>
        //             <Grid className="delete_icon_container" item xs={1}>
        //                 <Icon className={this.props.classes.deleteIcon} onClick={e => { this.props.deleteByName(this.props.children) }}>delete</Icon>
        //             </Grid>
        //         </Grid>
        //     </div>
        // </li>
  //     )
  //   }
  // }
  
  
  // var SortableItem = sortable(Item);
class ProgressBarSort extends React.Component {

    // state = {
    //   items: this.props.items
    // };
  
    // onSortItems = (items) => {
    //   this.setState({
    //     items: items
    //   });
    // }
  
    render() {
      // const { items } = this.state;
      // var listItems = items.map((item, i) => {
      //   return (
      //     <SortableItem
      //       key={i}
      //       onSortItems={this.onSortItems}
      //       items={items}
      //       sortId={i}
      //       deleteByName={this.props.deleteByName}
      //       classes={this.props.classes}>{item}</SortableItem>
      //   );
      // });
  
      return (
        // <ul className='sortable-list'>
        //   {listItems}
        // </ul>
        <li {...this.props}>
            <div className="uploaded_screen">
                <Grid container className="image_details">
                    <Grid item className="drag_icon_container" xs={3} sm={1} md={1} lg={1} xl={1}>
                        <Icon className={this.props.classes.handle}>drag_handle</Icon>
                    </Grid>
                    <Grid item xs={8} sm={3} md={3} lg={3} xl={3}>
                        <Grid container className="name_and_progess_bar_container">
                            <Grid item xs={12} className="image_name">
                                {this.props.children}
                            </Grid>
                            <Grid item xs={12} className="progess_bar">
                                <LinearProgress style={{width:'100%'}}
                                    classes={{
                                        colorPrimary: this.props.classes.linearColorPrimary,
                                        barColorPrimary: this.props.classes.linearBarColorPrimary,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                    <Grid item xs={5}>

                    </Grid>
                    {/* <Grid className="delete_icon_container" item xs={1}>
                        <Icon className={this.props.classes.deleteIcon} onClick={e => { this.props.deleteByName(this.props.children) }}>delete</Icon>
                    </Grid> */}
                </Grid>
            </div>
        </li>
      )
    }
};
export default ProgressBarSort;

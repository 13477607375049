// Store all the action type constants here

/**
 * @typedef {Object} ReduxRequest
 * @property {string} REQUEST Fetch call requested
 * @property {string} SUCCESS Fetch call successful
 * @property {string} FAILURE Fetch call failed
 */
var REQUEST = 'REQUEST';
var SUCCESS = 'SUCCESS';
var FAILURE = 'FAILURE';
/**
 * DO NOT EDIT THIS FUNC
 * @param {string} base the request base name i.e. LOAD_ACCOUNTS
 * @return {ReduxRequest}
 */

export function createAsyncTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce(function (acc, type) {
    acc[type] = "".concat(base, "_").concat(type);
    return acc;
  }, {});
} // Auth

export var SSO_AUTHENTICATE = createAsyncTypes('SSO_AUTHENTICATE'); // Venue Types

export var LIST_VENUE_TYPES = createAsyncTypes('LIST_VENUE_TYPES');
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { findToken, resetPassword } from 'shared/auth/actions';
import CustomizedButton from '../../../shared/components/customizedButton';

import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';

import '../../assets/styles/confirm.scss';
// import { stat } from 'fs';


const Schema = Yup.object().shape({
    password: Yup.string()
    .required('*Required')

     .test('len', '*Password must be of minimum 8 characters length', val => val && val.length > 7),

    confirmPassword: Yup.string()
    .required('*Required')
    .oneOf([Yup.ref('password'), null], "*Passwords don't match!")
});


class ResetPassword extends Component {

  UNSAFE_componentWillMount() {
    return this.props.findToken(this.props.match.params.token)
    .then((response) => {
      if(!response){
        this.props.history.push('/login')
      }
    })
    .catch(error => {
      
    });
  }

  handleSubmit = (values, { setSubmitting }) => {
    const data={
      password: values.password,
      email: this.props.email,
      password_confirmation: values.confirmPassword,
      token: this.props.token
    }
    return this.props.resetPassword(data)
      .then(() => {
        setSubmitting(false);
        this.props.history.push('/login')
      })
      .catch(error => {
        setSubmitting(false);
      });
  };

  render() {
    return (
      <div>
        <div className="confirm">
          <Grid className="signup__inner" container justify="center">
            <Grid item xl={3} xs={10} md={4} lg={4} sm={6}>
              <h3 className="confirmationPadding" align="center">&nbsp;</h3>

              <div className="confirmationBox">
                <h1 className="emailSentTo resetPassword">Reset Password</h1>
                <Grid>
                  <Formik
                    initialValues={{ password: '', confirmPassword:'' }}
                    validationSchema={Schema}
                    onSubmit={this.handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form className="conFIrmformFields">
                        <Grid container justify="center">
                            <label className="fieldLabel">
                                New Password
                            </label>
                          <Field className="inputField textField" align="center" type="password" name="password" placeholder="Password" />
                          <div className="error"><ErrorMessage name="password" /></div>

                        </Grid>
                        <Grid container justify="center">
                            <label className="fieldLabel">
                                Confirm New Password
                            </label>
                          <Field className="inputField textField" align="center" type="password" name="confirmPassword" placeholder="Confirm Password" />
                          <div className="error"><ErrorMessage name="confirmPassword" /></div>

                        </Grid>
                        <Grid container justify="center" className="sendButton">
                            <Grid item>
                                <CustomizedButton label="Save" disabled={isSubmitting} type="submit" />

                            </Grid>
                        </Grid>


                      </Form>
                    )}
                  </Formik>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    email: state.data.auth.resetEmail,
    token: state.data.auth.resetToken
  };
};
export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    findToken,
    resetPassword
  }, dispatch),
)(ResetPassword);

import React from 'react';
import PropTypes from 'prop-types';

import CustomInput from '../../shared/components/CustomInput';
import CustomizedButton from '../../shared/components/customizedButton';
import CustomSelectWithScroll from '../../shared/components/CustomSelectWithScroll';

import {Formik} from 'formik';
import {Grid, Hidden} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    icon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    root: {
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    typography: {
        width: '237px',
        height: '120px',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        fontFamily: 'Raleway'
    }
});

function CompanyInfo(props) {
    const {states, userType} = props;
    return (
        <div>
            <Grid container className="edit_profile_component">
                <Grid className="headings" item xs={12}>
                    <h4 className="form_name">Company Info</h4>
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={props.initialValues}
                        enableReinitialize
                        validationSchema={props.validationSchema}
                        onSubmit={(values, actions) => {
                        props.handleSubmit(values, actions)
                    }}
                        render={props => (
                        <form onSubmit={props.handleSubmit}>
                            <Grid container>
                                <Hidden xsDown={true}>
                                    <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                </Hidden>
                                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                    { parseInt(userType) !== 1 && 
                                    <Grid container className="edit_profile_component_form">                                        
                                             <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <div className="field_div">
                                                        <CustomInput
                                                            label="Company Name"
                                                            type="text"
                                                            name="companyName"
                                                            placeholder="Enter Company Name"/>
                                                    </div>
                                                </Grid>
                                    </Grid>
                                    }

                                    <Grid container className="edit_profile_component_form">
                                        <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomInput
                                                    label="Address Line 1"
                                                    type="text"
                                                    name="addressLine1"
                                                    placeholder="Address Line 1"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomInput
                                                    label="Address Line 2"
                                                    type="text"
                                                    name="addressLine2"
                                                    placeholder="Address Line 2"/>
                                            </div>
                                        </Grid>
                                        <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomInput label="City" type="text" name="city" placeholder="City"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomSelectWithScroll
                                                    placeholder="Select"
                                                    states={states}
                                                    name="state"
                                                    label="State / Province"
                                                    value={props.values.state}
                                                    handleChange={props.handleChange}
                                                    handleBlur={props.handleBlur}
                                                    registrationSelect
                                                    showLabel/>
                                            </div>
                                        </Grid>
                                        <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomInput
                                                    label="Zip Code"
                                                    type="text"
                                                    name="zipCode"
                                                    placeholder="Zip Code"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomInput
                                                    label="Job Title"
                                                    type="text"
                                                    name="jobTitle"
                                                    placeholder="Job Title"/>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ Grid>
                                <Grid className="save_button" item xs={12}>
                                    <Grid container justify='flex-end'>
                                        <Grid item>
                                            <CustomizedButton label="Save" type="submit"/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Hidden xsDown={true}>
                                    <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                </Hidden>
                            </Grid>
                        </form>
                    )}/>
                </Grid>
            </Grid>
        </div>
    );
}

CompanyInfo.propTypes = {
    classes: PropTypes.object.isRequired
};

const enhance = withStyles(styles)

export default enhance(CompanyInfo);

import axios from 'axios';
import { FIND_GOOGLE_CALENDAR } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function getGoogleCalnderListRequested() {
  return {
    type: FIND_GOOGLE_CALENDAR.REQUEST,
  };
}

function getGoogleCalnderListSuccess(data) {
  return {
    type: FIND_GOOGLE_CALENDAR.SUCCESS,
    tokenData: data,
  };
}

function getGoogleCalnderListFailure(error) {
  return {
    type: FIND_GOOGLE_CALENDAR.FAILURE,
    error:error,
  };
}

const request = () => {
  const headers = {};

  if(localStorage.getItem('accesstoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('accesstoken');

  }
  const axiosInstance = axios.create({
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function getGoogleCalnderList() {

    return dispatch => {
      dispatch(getGoogleCalnderListRequested());
        return request().get(`https://www.googleapis.com/calendar/v3/users/me/calendarList`)
        .then(response => {
            dispatch (getGoogleCalnderListSuccess(response.data),
          )
          return response.data;
        }
        ) 
        .catch(errors => {
          // dispatch(getGoogleCalnderListFailure(errors.response));
          if(errors.response && errors.response.data.message){
            dispatch(getGoogleCalnderListFailure(errors.response.data.message));
          }
          else{
             dispatch(getGoogleCalnderListFailure("Not able to find this calendar list"));
          }
          throw errors;
  
          
        });
      

    
 };
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../../CustomInput';
import OtpInput from 'react-otp-input';
const EmailLoginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email!')
        .required('*Email is required'),
    password: Yup.string()
        .required('*Password is required'),
});

function EmailLogin(props) {
    const { classes } = props;
    const [passwordTypePass, setPasswordTypePass] = useState('password');
    const changePasswordFieldType = () => {
        if (passwordTypePass === 'password') {
            setPasswordTypePass('text')
        }
        else {
            setPasswordTypePass('password');
        }
    }

    return (
        <div>
            {
                props.step === 1 &&
                <Grid container md={12} lg={12} xl={12} className="login-container" >
                    <Formik
                        initialValues={props.initialValues}
                        validationSchema={EmailLoginSchema}
                        onSubmit={props.onEmailSignUp}
                    >
                        {({ isSubmitting, values, setFieldValue, handleChange }) => (
                            <Form >
                                <Typography gutterBottom className="heading-1">
                                    {props.venueName} uses FIRST-HOLD’s venue availability platform to securely manage your account information.
                                </Typography>
                                {
                                    props.currentView == 'emailLogin' && <Typography gutterBottom className="heading-2">
                                        Please log in:
                                    </Typography>
                                }

                                {
                                    props.currentView == 'emailSingup' && <Typography gutterBottom className="heading-2">
                                        Please enter your email address to verify or create an account:
                                    </Typography>
                                }

                                <Grid container md={12} lg={12} xl={12}>
                                    <Grid md={12} className="input-box">
                                        <InputLabel>EMAIL ADDRESS *</InputLabel>
                                        <FormControl fullWidth>
                                            <Field className="inputField" align="center" type="text" name="email" placeholder="Enter Email Address" />
                                            <div className="error"><ErrorMessage name="email" /></div>
                                        </FormControl>
                                    </Grid>
                                    <Grid md={12} className="input-box">
                                        <InputLabel>PASSWORD *</InputLabel>
                                        <FormControl fullWidth className="password_field_div">
                                            <CustomInput st='basicinfo' type={passwordTypePass} name="password" placeholder="Enter password" passwordField changePasswordFieldType={changePasswordFieldType} />

                                            {/*<div className="error"><ErrorMessage name="password" /></div>*/}
                                        </FormControl>
                                    </Grid>
                                    <Grid md={12} xs={12} sm={12} lg={12} xl={12} className="button-box">
                                        <FormControl fullWidth>
                                            <Button variant="contained" type="submit" className="sign-btn" color="primary" >
                                                Continue
                                            </Button>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Typography gutterBottom className="bottom-link">
                                    <Link to='/forgotPassword' target="_blank">Forgot Password?</Link>
                                </Typography>
                                <Typography gutterBottom className="bottom-border">
                                    <hr />
                                </Typography>
                                <Typography gutterBottom className="bottom-link">
                                    Don't have account ? <Link onClick={() => props.onChangeView('emailSingup')}>Sign Up</Link>
                                </Typography>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            }

            {props.step === 2 && <Grid container md={12} lg={12} xl={12} className="otp-container">
                <Typography gutterBottom className="heading-2">
                    Check your email!
                </Typography>
                <Typography gutterBottom className="heading-1">
                    We sent a 6-digit confirmation code to {props.emailSignUpInitalValues.email}.
                    <p>It expires shortly, so enter it soon.</p>
                </Typography>
                <Grid item md={12} lg={12} xl={12}>
                    <OtpInput
                        value={props.otp}
                        onChange={(e) => props.handleChange(e)}
                        numInputs={6}
                        isDisabled={props.otp && props.otp.length == 6
                            ? true
                            : false}
                        containerStyle="otp-input-box"
                        inputStyle="otp-input" />
                </Grid>
                <Typography gutterBottom className="bottom-link">
                    <Link onClick={() => props.onHelp()}>Need Help?</Link>
                </Typography>
            </Grid>
            }

            {props.step === 3 && <Grid container md={12} lg={12} xl={12} className="help-container">
                <Typography gutterBottom className="heading-2">
                    How can we help?
                </Typography>
                <Typography gutterBottom className="bottom-link">
                    <Link onClick={() => props.onResendOTP('0')}>Resend Code</Link>
                </Typography>
                <Typography gutterBottom className="bottom-link">
                    <Link onClick={() => props.onChangeView('mobileLogin')}>Sign in with Mobile No.</Link>
                </Typography>
                <Typography gutterBottom className="bottom-link">
                    <Link onClick={() => props.onChangeView('emailSingup')}>Create account with Email Instead</Link>
                </Typography>
            </Grid>
            }
        </div>
    );
}

EmailLogin.propTypes = {
    classes: PropTypes.object.isRequired
}

export default (EmailLogin);
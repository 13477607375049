import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators, compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Grid } from '@material-ui/core';
import '../../../widgetTypeTwo/assets/styles/loginPopup.scss';
import { loginWithMobile, bootstrap, loginOtpVerification, resendOTP, loginWithEmail, signUpEmailOtpVerification } from 'shared/auth/actions';
import { MobileLogin, EmailLogin } from './views/index.js';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: '8px'
    },
    closeButton: {
        position: 'absolute',
        right: '4px',
        top: '4px',
        color: theme.palette.grey[500]
    },
    backButton: {
        position: 'absolute',
        left: '4px',
        top: '4px',
        color: theme.palette.grey[500]
    },
    paper: {
        maxWidth: "480px",
        minHeight: "348px"
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const {
        children,
        classes,
        onClose,
        onStep,
        currentView,
        onBack,
        ...other
    } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {(!([1].includes(onStep)) || currentView !== 'mobileLogin') &&
                <IconButton aria-label="back" className={classes.backButton} onClick={onBack}>
                    <ArrowBackIosIcon />
                </IconButton>
            }
            {onClose
                ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                )
                : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: '8px'
    }
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: '4px'
    }
}))(MuiDialogActions);
class LoginDialogs extends React.Component {
    state = {
        open: true,
        otp: '',
        step: 1,
        currentView: 'mobileLogin',
        initialValues: { mobile_no: '', ext: '1' },
        emailInitalValues: { email: '', password: '' },
        emailSignUpInitalValues: { email: '' },
    };

    loginOtpVerification = (otp) => {
        localStorage.setItem('widgetLogin', '1');
        this.setState({ otp: otp });
        if (otp && otp.length == 6) {
            this.props.loginOtpVerification({ mobile_no: this.state.initialValues.mobile_no, access_code: otp, isPlanner: 1 }).then((response) => {
                if ('token' in response) {
                    this
                        .props
                        .bootstrap()
                        .then(() => {
                            setTimeout(function () {
                                localStorage.setItem('widget_path', this.props.location.pathname);
                                this.props.history.go(0);
                            }.bind(this), 500);
                        })
                } else {
                    localStorage.setItem('verified_mobile_no', this.state.initialValues.mobile_no);
                    localStorage.setItem('widget_path', this.props.location.pathname);
                    localStorage.setItem('user_type', '0');
                    this.props.history.push(`/register-with-mobile?venueId=${this.props.venueId}`);
                }
            }).catch(error => {
                this.setState({ otp: '' });
            })
        }
    }

    emailSignUpOtpVerification = (otp) => {
        localStorage.setItem('widgetLogin', '1');
        this.setState({ otp: otp });
        if (otp && otp.length == 6) {
            this.props.signUpEmailOtpVerification({ email: this.state.emailSignUpInitalValues.email, access_code: otp,isPlanner: 1 }).then((response) => {
                if ('token' in response) {
                    this
                        .props
                        .bootstrap()
                        .then(() => {
                            setTimeout(function () {
                                localStorage.setItem('widget_path', this.props.location.pathname);
                                this.props.history.go(0);
                            }.bind(this), 500);
                        })
                } else {
                localStorage.setItem('verified_email', this.state.emailSignUpInitalValues.email);
                localStorage.setItem('widget_path', this.props.location.pathname);
                localStorage.setItem('user_type', '0');
                this.props.history.push(`/register-with-email?venueId=${this.props.venueId}`);
                }
            }).catch(error => {
                this.setState({ otp: '' });
            });
        }
    }

    onResendOTP = (type = 1) => {
        let params = { mobile_no: this.state.initialValues.mobile_no, contact_type: type };
        if (type == 0) {
            params = { email: this.state.emailSignUpInitalValues.email, contact_type: type };
        }
        this.props.resendOTP(params).then((response) => {
            this.setState({ step: this.state.step - 1, otp: '' });
        });
    }

    handleClose = () => {
        this.setState({ open: false });
        this.props.closeLoginDailog();
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    onLogin = (values, { setSubmitting }) => {
        Object.assign(values, { "isPlanner": "1" });
        this.props.loginWithMobile(values)
            .then((response) => {
                this.setState({ step: 2, initialValues: values, otp: '' });
            }).catch(error => {
                setSubmitting(false);
            });
    }

    onEmailSignUp = (values, { setSubmitting }) => {
        Object.assign(values, { "isPlanner": "1" });
        this.props.loginWithEmail(values)
            .then((response) => {
                this.setState({ step: 2, emailSignUpInitalValues: values, otp: '' });
            }).catch(error => {
                setSubmitting(false);
            });
    }

    onEmailLogin = (values, { setSubmitting }) => {
        Object.assign(values, { "isPlanner": "1" });
        this.props.loginWithEmail(values)
            .then((response) => {
                console.log('sssssss', response);
                if ('token' in response) {
                    this
                        .props
                        .bootstrap()
                        .then(() => {
                            setTimeout(function () {
                                localStorage.setItem('widget_path', this.props.location.pathname);
                                this.props.history.go(0);
                            }.bind(this), 500);
                        })
                } else {
                    this.setState({ step: 2, emailSignUpInitalValues: values, otp: '' });
                }
            }).catch(error => {
                setSubmitting(false);
            });
    }

    onHelp = () => {
        this.setState({ step: 3 });
    }

    onBack = () => {
        if (this.state.currentView !== 'mobileLogin' && this.state.step == 1) {
            this.setState({ currentView: 'mobileLogin', step: 1 });
        } else {
            this.setState({ step: this.state.step - 1 });
        }
    }

    onChangeView = (viewName) => {
        this.setState({ currentView: viewName, step: 1 });
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                    Open dialog
                </Button> */}
                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    classes={{
                        paper: classes.paper
                    }}
                    open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onBack={this.onBack} currentView={this.state.currentView} onStep={this.state.step} onClose={this.handleClose}></DialogTitle>
                    <DialogContent dividers>
                        <Grid item className="dialog-container">
                            <Grid item className="logo">
                                <Link to='/'>
                                    <img src={require('./../assets/images/LogoWithText.png')} alt="logo" />
                                </Link>
                            </Grid>

                            {this.state.currentView === 'mobileLogin' && <MobileLogin venueName={this.props.venueName} step={this.state.step} initialValues={this.state.initialValues} onLogin={this.onLogin} handleChange={this.loginOtpVerification} otp={this.state.otp} onHelp={this.onHelp} onChangeView={this.onChangeView} onResendOTP={this.onResendOTP} />}

                            {(this.state.currentView === 'emailLogin' || this.state.currentView === 'emailSingup') && <EmailLogin step={this.state.step} venueName={this.props.venueName} initialValues={this.state.emailInitalValues} onEmailSignUp={this.onEmailSignUp} handleChange={this.emailSignUpOtpVerification} otp={this.state.otp} onHelp={this.onHelp} onChangeView={this.onChangeView} onResendOTP={this.onResendOTP} currentView={this.state.currentView} emailSignUpInitalValues={this.state.emailSignUpInitalValues} />}



                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

LoginDialogs.propTypes = {
    login: PropTypes.func.isRequired,
    bootstrap: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        userData: state.data.usersVenue.userInfo,
    };
};

const enhance = compose(withStyles(styles), withRouter, connect(mapStateToProps, dispatch =>
    bindActionCreators(
        {
            loginWithMobile,
            bootstrap,
            loginOtpVerification,
            resendOTP,
            loginWithEmail,
            signUpEmailOtpVerification
        },
        dispatch
    )
));
export default enhance(LoginDialogs);
import React from 'react';
import {object, func} from 'prop-types';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Formik, Field, FieldArray, ErrorMessage, Form} from 'formik';
import {Grid, Button, Icon} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import '../assets/styles/addVideos.scss';
import * as Yup from 'yup';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import {spaceVideoAdd, spaceVideoDelete,spaceVideoUpdate} from 'data/spaces/actions';
import CustomizedCheckBox from '../../shared/components/CustomizedCheckbox';
import CustomizedButton from '../../shared/components/customizedButton';
import { getSpace } from 'data/spaces/actions';
import CustomCheckBox from "../../shared/components/CustomCheckBox";
import CustomSelect from "../../shared/components/CustomSelect";

const FILE_SIZE = 1024 * 1024 * 10;
const LoginSchema = Yup
    .object()
    .shape({
        name: Yup
            .string()
            .required('*Name is required')
            .matches(/^[aA-zZ0-9-_\s]+$/, "Name may only contain letters, numbers, dashes and underscores.")
            .max(30, '*Name should not be greater than 30 characters'),
        path: Yup
            .string()
            .required('*Link is required')
            .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, 'Enter correct url!')
    });
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: '8px'
    },
    closeButton: {
        position: 'absolute',
        right: '4px',
        top: '4px',
        color: theme.palette.grey[500]
    },
    backButton: {
        position: 'absolute',
        left: '4px',
        top: '4px',
        color: theme.palette.grey[500]
    },
    paper: {
        maxWidth: "450px",
        minHeight: "336px"
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const {
        children,
        classes,
        onClose,
        ...other
    } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {onClose
                ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                )
                : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: '8px'
    }
}))(MuiDialogContent);

class AddVideosComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        open: false,
        showList: false,
        copyClicked: false,
        copySpace: 'false',
        error: '',
        error_size: '',
        selectLinkError:'',
        spaceName:'',
        spaceId:'',
        noLink:false,
        linkDatalen:this.props.videos && this.props.videos.length,
        initialValues: {
            id: null,
            name: "",
            path: ""
        }
    }
    componentWillMount() {
        if (this.props.venueDetails && this.props.venueDetails.spaces.length > 0) {
            let space = [];
            let prop = this;
            this.props.venueDetails && this.props.venueDetails.spaces.map(function (data, id) {
                if (parseInt(prop.props.id) !== parseInt(data.id)) {
                    space.push({ name: data.name, value: data.id })

                }
                return space;
            })
            this.setState({ spaces: space })
        }

    }
    componentWillReceiveProps(nextProps){
        if(this.props.videos!==nextProps.videos){
        this.setState({linkDatalen:nextProps.videos && nextProps.videos.length})
        }
        if(this.props.venueDetails !==nextProps.venueDetails)
        {
        if(nextProps.venueDetails && nextProps.venueDetails.spaces.length>0){
        let space=[];
        nextProps.venueDetails && nextProps.venueDetails.spaces.map(function(data,id){
        if(parseInt(nextProps.id)!==parseInt(data.id)){
        space.push({name:data.name,value:data.id})
        
        }
        return space;
        
        })
        this.setState({spaces:space}) 
        }
        }
        }
    handleClose = () => {
        this.setState({open: false});
    };

    onSubmit = (values, {setSubmitting}) => {
        if(values.id){
            this
            .props
            .spaceVideoUpdate({
                name: values.name,
                video: values.path
            }, this.props.id,values.id,this.props.type)
            .then((response) => {       
                this
                .props
                .handleVideoUpdate(values);         
                this.setState({open: false});
            });
        }else{
            this
            .props
            .spaceVideoAdd({
                name: values.name,
                video: values.path
            }, this.props.id,this.props.type)
            .then((response) => {
                this
                    .props
                    .handleVideoAdd(response.payload);
                this.setState({open: false});
            });
        }
        
    }
    handleChangeBox = (e) => {
        this.setState({ copyClicked: false })
        if (e.target.value.toString() === 'false') {
            this.setState({ showList: true })
        }
        else {
            this.setState({ showList: false })
            this.setState({selectLinkError:'',documents:[],error:'',noLink:false,spaceId:''})

        }

    }
    finalcopy = (values, setFieldValue) => {
        let prop = this;
        let linklen = this.props.videos && this.props.videos.length;
        for (let j = 0; j < values.length; j++) {
            for (let i = 0; i < this.state.documents.length; i++) {

                if (parseInt(values[j]) === parseInt(this.state.documents[i].id)) {
                    if (linklen < 5) {
                        this.setState({ linkDatalen: prop.state.linkDatalen + 1 })
                        linklen = linklen + 1;

                        prop
                        .props
                        .spaceVideoAdd({'name':this.state.documents[i].name,video: this.state.documents[i].path}, this.props.id,this.props.type)
                            .then((res) => {


                                if (parseInt(j) === parseInt(values.length - 1)) {
                                    prop.props.handleVideoAdd();
                                }
                            })

                    }
                    else {

                        prop.setState({ linkDatalen: 5 })
                        prop.props.handleVideoAdd();;
                        break;
                    }

                }
            }

        }
        if(this.state.documents.length!=0&&values.length!=0)
        {
        setFieldValue('copySpace', false);
        setFieldValue('additional', []);
        setFieldValue('space', '');
        this.setState({selectLinkError:'',noLink:false,spaceId:''})
        this.setState({ copyClicked: false });
        if (this.props.copyFalse) {
            this.props.copyFalse();
        }
        }
        else
        {
            this.setState({selectLinkError:"*Please select Link."})
        }
    }
    handleSubmit = (values) => {
        if (values.space && values.space !== '') {
            this.setState({spaceId:values.space})
            let spaceList=this.state.spaces
            this.setState({ error: "", error_size: '', copyClicked: false })

            if (this.props.copy) {
                this.props.copy()
            }
            this.props.getSpace(values.space).then((response) => {

                if (response.videos.length > 0) {
                    let checkSelectSpace=spaceList.filter(data => data.value.toString()===values.space);
                    this.setState({ showList: false, copyClicked: true, documents: response.videos,spaceName:checkSelectSpace[0].name,noLink:false })
                    //values.space=''
                }
                else {
                    // values.space=''
                    this.setState({ error: "*No Links Found." ,noLink:true})
                }
            })
                .catch(error => { })
        }
        else {
            this.setState({ error: "*Please select space." })
        }

    }
    render() {
        const {classes} = this.props;
        const handleEdit = (data) => {
            this.setState({initialValues: data, open: true});
        }

        const openLink = (data) => {
            window.open(data.path, "_blank");
        }

        const handleDelete = (videoId) => {
            this
                .props
                .spaceVideoDelete(this.props.id, videoId,this.props.type)
                .then((response) => {
                    this
                        .props
                        .handleVideoDeleted(videoId);
                });

        }

        return (
            <Grid container className="add-videos">
                <Formik
                    initialValues={{ copySpace: false, additional: [],space:''}}
                    onSubmit={(values,setFieldValue) => {
                        this.handleSubmit(values,setFieldValue)
                    }}
                    render={(props, values, formikProps, arrayHelpers, setFieldValue) => (
                        <Form>
                <div className="grid-section-vertical">
                    <h3>Links (Max 5)</h3>
                    {  (Array.isArray(this.props.videos) && this.props.videos.length < 5)?
                    <div>
                        <Button
                            variant="outline-danger"
                            class="white-button"
                            onClick={() => this.setState({
                            initialValues: {
                                id: null,
                                name: "",
                                path: ""
                            },
                            open: true
                        })}>+ Add Links</Button>
                        {this.props.name === 'SPACE'
                                            ?
                                            this.state.spaces.length > 0 ?
                                                <CustomizedCheckBox
                                                    name='copySpace'
                                                    marginTop="20px"
                                                    value={props.values.copySpace}
                                                    label="Copy Link(s)"
                                                    handleChange={props.handleChange}
                                                    onChange={this.handleChangeBox} />
                                                : null
                                            : null}
                                        {(this.state.showList && this.state.spaces.length > 0) === true ?
                                            <div>
                                                <CustomSelect
                                                    id="space"
                                                    name="space"
                                                    value={props.values.space}
                                                    spaceData={this.state.spaces}
                                                    placeholder="Select Space" color="#62717a" width="100%" />
                                                <div className="error">{props.values.space!=''&&this.state.noLink===false?'':props.values.space!=this.state.spaceId?'':this.state.error}</div> 

                                                <CustomizedButton marginTop="10px" label="Get All Link(s)" type='submit' />

                                            </div> : null}
                                            {this.state.copyClicked === true ?
                                            <div>
                                                {this.state.spaceName} Links
                                                <FieldArray
                                                    name="additional"
                                                    render={arrayHelpers => (
                                                        this.state.documents.map(function (data, idx) {
                                                            return (
                                                                <div style={{paddingTop:'15px', borderTop:'1px solid #cccc', marginTop:'10px'}}>
                                                                    <CustomCheckBox
                                                                        title="venueProfile"
                                                                        // onClick={t.handleChangeBox}
                                                                        name={data.id}
                                                                        label={data.name}
                                                                        arrayHelpers={arrayHelpers}
                                                                        handleChange={props.handleChange}
                                                                        checkboxGroup={props.values.additional}
                                                                    />

                                                                </div>
                                                            )
                                                        })
                                                    )}
                                                />
                                                <div className="error">{props.values.additional.length!=0?'':this.state.selectLinkError}</div> 
                                                <CustomizedButton marginTop="10px" label="Copy" onClickEvent={() => this.finalcopy(props.values.additional, props.setFieldValue)} />
                                            </div>
                                            :
                                            null}
                        </div>

                        :
                        <p>Max limit reached</p>
                    }
                    <Dialog
                        onClose={this.handleClose}
                        aria-labelledby="customized-dialog-title"
                        classes={{paper: classes.paper }}
                        open={this.state.open}>
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}></DialogTitle>
                        <DialogContent dividers>
                            <Grid item className="video-dialog-container">
                                <Grid container md={12} lg={12} xl={12} className="v-container">
                                    <Formik
                                        initialValues={this.state.initialValues}
                                        validationSchema={LoginSchema}
                                        onSubmit={this.onSubmit}>
                                        {({isSubmitting, values, setFieldValue, handleChange}) => (
                                            <Form >
                                                <Typography gutterBottom className="heading-1">
                                                    {('id' in values && values.id)
                                                        ? 'Edit Link'
                                                        : 'Add New Link'}
                                                </Typography>

                                                <Grid container md={12} xs={12} sm={12} lg={12} xl={12}>
                                                    <Grid md={12} xs={12} sm={12} lg={12} xl={12}>
                                                        <InputLabel className="input-label">Name *</InputLabel>
                                                    </Grid>
                                                    <Grid md={12} xs={12} sm={12} xl={12} lg={12} className="input-box">
                                                        <FormControl fullWidth>
                                                            <Field
                                                                className="inputField"
                                                                align="center"
                                                                type="text"
                                                                name="name"
                                                                placeholder="Enter Name"/>
                                                            <div className="error"><ErrorMessage name="name"/></div>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid md={12} xs={12} sm={12} lg={12} xl={12}>
                                                        <InputLabel className="input-label">Link *</InputLabel>
                                                    </Grid>
                                                    <Grid md={12} xs={12} sm={12} xl={12} lg={12} className="input-box">
                                                        <FormControl fullWidth>
                                                            <Field
                                                                className="inputField"
                                                                align="center"
                                                                type="text"
                                                                name="path"
                                                                placeholder="Enter Link"/>
                                                            <div className="error"><ErrorMessage name="path"/></div>
                                                        </FormControl>
                                                        <span className="hint">e.g. https://youtu.be/BEzt7PBXKXE</span>
                                                    </Grid>

                                                    <Grid md={12} xs={12} sm={12} lg={12} xl={12} className="button-box">
                                                        <FormControl fullWidth>
                                                            <Button variant="contained" type="submit" className="sign-btn" color="primary">
                                                                {('id' in values && values.id)
                                                                    ? 'Update'
                                                                    : 'Submit'}
                                                            </Button>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
                <Grid container md={12} lg={12} xl={12} className="coupon-list-container">
                    <List className="plan_description">
                        {Array.isArray(this.props.videos) && this
                            .props
                            .videos.length > 0 ?
                            this
                            .props
                            .videos
                            .map(function (data, idx) {
                                return (
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar className="avatar">
                                            <Icon className="blueIcon">link</Icon>
                                        </ListItemAvatar>
                                        <div className="c-text">
                                            <h3>{data.name}</h3>
                                        </div>
                                        <ListItemSecondaryAction className="action-icon">
                                            <IconButton aria-label="Preview" onClick={() => openLink(data)}>
                                                <Icon>remove_red_eye</Icon>
                                            </IconButton>
                                            <IconButton aria-label="Edit" onClick={() => handleEdit(data)}>
                                                <Icon>create</Icon>
                                            </IconButton>
                                            <IconButton aria-label="Delete" onClick={() => handleDelete(data.id)}>
                                                <DeleteIcon className="redIcon"/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })
                            :
                            <div style={{margin:'10px 0 0 4px', textAlign:'left'}}>No Link Found</div>
}
                    </List>
                </Grid>
                </Form>
                    )}
                />
            </Grid>
        );
    }
}

AddVideosComponent.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {};
};

const enhance = compose(withStyles(styles),withRouter, connect(mapStateToProps, dispatch => bindActionCreators({
    spaceVideoAdd,
    spaceVideoDelete,
    spaceVideoUpdate,
    getSpace
}, dispatch)));
export default enhance(AddVideosComponent);

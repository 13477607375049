
import React from 'react';
// import { object } from 'prop-types';
// import { withRouter, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import { withStyles } from '@material-ui/core/styles';
// import { Icon, Avatar, Typography } from '@material-ui/core';
// import { Grid, Button, Checkbox } from '@material-ui/core';
import '../assets/styles/spaceProfileEditComponent.scss';
import { spaceDocumentDelete } from 'data/spaces/actions';
import { venueDocumentDelete } from 'data/venues/actions';

class DeletePdfComponent extends React.Component {
    // constructor(props) {
    //   super(props);
    // }


    onDocumentDelete = () => {
        
        if(this.props.type==='VENUE') {
                this.props.venueDocumentDelete(this.props.id,  this.props.documentId)
                .then((res) => {
                this.props.handleDocumentDeleted(this.props.documentId)
            })
            .catch(error => {})
        } else {
            this.props.spaceDocumentDelete(this.props.id,  this.props.documentId)
                .then((res) => {
                    this.props.handleDocumentDeleted(this.props.documentId)
            })
            .catch(error => {})
        }
        
        
    }

    

    render() {
        return (
            <div className="red_icon_div" onClick={this.onDocumentDelete}>
                    <span className="material-icons delete">delete</span>
            </div>
        );
    }
}


export default connect(
    null,
    dispatch => bindActionCreators({
        spaceDocumentDelete,
        venueDocumentDelete
    }, dispatch),
  )( DeletePdfComponent);

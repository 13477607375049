import React from 'react';
import { Grid } from '@material-ui/core';

import '../assets/styles/venueAttractionEdit.scss';
import CustomInput from '../../shared/components/CustomInput';


class VenueAttractionEdit extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    state={
        error:false,
        name:''
    }
onChange=(e)=>{
    if(this.props.requiredRemove){
        this.props.requiredRemove();
    }
    this.setState({name:''})
    if(e.target.value.length==25){
        this.setState({error:true})
        this.setState({name:e.target.name})
    }

}
    render() {
        return (
            <div style={{
                borderRadius: '6px',
                border: 'solid 1px #f0f0f0',
                backgroundColor: '#ffffff',
                width: '100%',
                display: 'block',
                height: 'auto',
                padding: '22px',
                fontSize: '15px',
                lineHeight: '1.07'
            }}>

                <Grid container className="venue-attraction-edit">
                    <Grid xs={12} item>
                        <Grid container style={{ width: '100%' }}>
                            <h3 className="black-heading">
                                Tell us the 5 things you like most about your venue</h3>


                            <CustomInput onChange={this.onChange} handleChange={this.props.formikProps} type="text" maxLength="25" name="naturalLight" placeholder="Natural Light" marginBottom='50px' />
                            {this.props.topErrorNatural?
                            <p className='exp_warning' style={{color: 'red',fontSize: '12px'}}>*Required</p>
                            :''}

                            {this.state.error && this.state.name=='naturalLight'? 
                            <p className='exp_warning' style={{color: 'red',fontSize: '12px'}}>*Should not be greater than 25 characters</p>
                            :''}

                            <CustomInput onChange={this.onChange} handleChange={this.props.formikProps} maxLength="25" type="text" name="beautifulView" placeholder="Beautiful View" marginBottom='50px' />
                            {this.props.topErrorBeautiful?
                            <p className='exp_warning' style={{color: 'red',fontSize: '12px'}}>*Required</p>
                            :''}
                            {this.state.error && this.state.name=='beautifulView'? 
                            <p className='exp_warning' style={{color: 'red',fontSize: '12px'}}>*Should not be greater than 25 characters</p>
                            :''}
                            <CustomInput onChange={this.onChange} handleChange={this.props.formikProps} maxLength="25" type="text" name="nearConvention" placeholder="Near Convention Center" marginBottom='50px' />
                            {this.props.topErrorCon?
                            <p className='exp_warning' style={{color: 'red',fontSize: '12px'}}>*Required</p>
                            :''}
                            {this.state.error && this.state.name=='nearConvention'? 
                            <p className='exp_warning' style={{color: 'red',fontSize: '12px'}}>*Should not be greater than 25 characters</p>
                            :''}
                            <CustomInput onChange={this.onChange} handleChange={this.props.formikProps} maxLength="25" type="text" name="exposedBrick" placeholder="Exposed Brick" marginBottom='50px' />
                            {this.props.topErrorBrick?
                            <p className='exp_warning' style={{color: 'red',fontSize: '12px'}}>*Required</p>
                            :''}
                            {this.state.error && this.state.name=='exposedBrick'? 
                            <p className='exp_warning' style={{color: 'red',fontSize: '12px'}}>*Should not be greater than 25 characters</p>
                            :''}
                            <CustomInput onChange={this.onChange} handleChange={this.props.formikProps} maxLength="25" type="text" name="iconicBuilding" placeholder="Iconic Building" marginBottom='50px' />
                            {this.props.topErrorBuilding?
                            <p className='exp_warning' style={{color: 'red',fontSize: '12px'}}>*Required</p>
                            :''}
                            {this.state.error && this.state.name=='iconicBuilding'? 
                            <p className='exp_warning' style={{color: 'red',fontSize: '12px'}}>*Should not be greater than 25 characters</p>
                            :''}
                            <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}


export default VenueAttractionEdit;

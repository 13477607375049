import React from 'react';

import { Grid } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import './../assets/styles/featuredVenue.scss';
import VenueCardWithFav from '../../shared/components/VenueCardWithFav.js';

function FeatureVenues(props) {
   
    const responsive = {
      0: { items: 1 },
      767: { items: 2.65 },
      1024: { items: 3.65 },
    }

    // const onSlideChange=(e)=>{
    //   console.debug('Item`s position during a change: ', e.item)
    //   console.debug('Slide`s position during a change: ', e.slide)
    // }
   
    // const onSlideChanged=(e)=>{
    //   console.debug('Item`s position after changes: ', e.item)
    //   console.debug('Slide`s position after changes: ', e.slide)
    // }
  
    const venueObject = props.featureVenueData && props.featureVenueData.map(function(data, idx) {
        return(
          <VenueCardWithFav height='122px' width='200px' imageSize='small' addressColor="#1397e1" idx={idx} image={data.image} favourite={data.favourite} venueAddress={data.venueAddress} venueName={data.venueName} category={data.category} enableLink linkTo={"/venue-profile/" + data.id}/>
        );
    }); 

    return (
        <Grid container className="feature_venues fh-container featured_venue_slider">
          <Grid xs={12} item>
            <h1 className="featured_venue_heading">Check out venues already using FIRST-HOLD</h1>
          </Grid>
          <Grid xs={12} item className="featured_venue_carousel">
            <AliceCarousel
              items={venueObject}
              responsive={responsive}
              autoPlayInterval={5000}
              autoPlayDirection="lft"
              autoPlay={true}
              fadeOutAnimation={true}
              touchTrackingEnabled={true}
              mouseTrackingEnabled={true}
              mouseDragEnabled={true}
              playButtonEnabled={false}
              disableAutoPlayOnAction={true}
              // onSlideChange={onSlideChange}
              // onSlideChanged={onSlideChanged}
              buttonsDisabled={true}
              dotsDisabled={true}
            />
          </Grid>
        </Grid>
    );
}

export default (FeatureVenues);

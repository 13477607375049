import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography, Grid, Button, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close'
import { object } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { Field } from 'formik';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit
    },
    closeButton: {
        top: '1px',
        right: '4px',
        position: 'absolute',
        color: '#000000'
    },
    fieldset: {
        margin: '8px',
        width: '30%'
    },
    legend: {
        color: '#000000 !important',
        fontWeight: '600'
    },
    chechboxLabel: {
        margin: '-4px'
    },
    paper: {
        maxWidth: "700px"
    }

});

let spacelength = 0
const DialogTitle = withStyles(styles)((props) => {
    const {
        children,
        classes,
        onClose,
        ...other
    } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose
                ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                )
                : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        minWidth: '500px',
        padding: '8px',
        border: '1px solid #eaeaea',
        margin: '0px 4px 4px 4px'
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: '12px',
        display: 'inline-flex',
        justifyContent: 'space-around'

    }
}))(MuiDialogActions);

class VenueSpaces extends React.Component {

    state = {
        open: this.props.open,
        venues: this.props.venues,
        selectedSpaces: this.props.selectedSpaces,
        selectedValues: [],
        checkAll: '',
        uncheckAll: '',
        spaceLength: 0
    };


    handleClose = () => {
        this.setState({ open: false });
        this.props.onClose();
    };

    handleSave = () => {
        this.props.onSpaceApplied(this.state.selectedValues);
    }

    componentWillMount() {
        spacelength = 0
        this.state.venues.data && this
            .state
            .venues
            .data
            .map((data, idx) => {
                if (data.spaces && data.spaces.length !== 0) {
                    spacelength = spacelength + data.spaces.length
                }
            })
        this.setState({ spaceLength: spacelength })
        this.setState({ selectedValues: this.state.selectedValues.concat(this.state.selectedSpaces) });
    }

    render() {
        const { classes } = this.props;
        let selectedSpace = this.state.selectedValues;
        const spaceId = this.props.spaceId.toString();
        const handleChange = (event) => {
            if (event.target.checked) {
                selectedSpace.push(event.target.value);
                setTimeout(() => {
                    this.setState({ selectedValues: selectedSpace });
                }, 400);
            } else {
                const index = selectedSpace.indexOf(event.target.value);
                if (index > -1) {
                    selectedSpace.splice(index, 1);
                    setTimeout(() => {
                        this.setState({ spaceLength: spacelength })
                        this.setState({ selectedValues: selectedSpace });
                    }, 400);
                }
            }
        };

        const setCheckAll = (event) => {
            this.setState({ selectedValues: spaceId.toString() })
            selectedSpace = []
            selectedSpace.push(spaceId.toString());
            if (event.target.checked) {
                this.setState({ checkAll: true })
                this.setState({ uncheckAll: '' })
                this.state.venues.data && this
                    .state
                    .venues
                    .data
                    .map((data, idx) => {
                        if (data.spaces && data.spaces.length !== 0) {
                            data.spaces && data
                                .spaces
                                .map(function (space_data, idx) {
                                    if (spaceId != space_data.id) {
                                        selectedSpace.push(space_data.id.toString());

                                    }
                                })
                        }
                    })

                setTimeout(() => {
                    this.setState({ selectedValues: selectedSpace });
                }, 400);

            }
            else {
                this.setState({ uncheckAll: true })
                this.setState({ checkAll: '' })

                this.state.venues.data && this
                    .state
                    .venues
                    .data
                    .map((data, idx) => {
                        if (data.spaces && data.spaces.length !== 0)
                            data.spaces && data
                                .spaces
                                .map(function (space_data, idx) {
                                    if (spaceId != space_data.id) {
                                        const index = selectedSpace.indexOf(space_data.id.toString());
                                        if (index > -1) {
                                            selectedSpace.splice(index, 1);
                                        }
                                    }
                                })
                    })

                setTimeout(() => {
                    this.setState({ selectedValues: selectedSpace });
                }, 400);
            }
        }


        const List1 = this.state.venues.data && this
            .state
            .venues
            .data
            .map((data, idx) => {
                return (
                    <FormControl component="fieldset" className={classes.fieldset}>
                        <FormLabel className={classes.legend} component="legend">{data.name.length < 17 ? data.name : data.name.substring(0, 17) + '...'}</FormLabel>
                        {data.spaces && data.spaces.length !== 0
                            ? <FormGroup>
                                {data.spaces && data
                                    .spaces
                                    .map(function (space_data, idx) {
                                        return (
                                            <FormControlLabel className={classes.chechboxLabel} disabled={space_data.id.toString() == spaceId ? true : false}
                                                control={<Checkbox onChange={handleChange} defaultChecked={selectedSpace.indexOf(space_data.id.toString()) > -1 ? true : false} value={space_data.id} />}
                                                label={space_data.name.length < 17
                                                    ? space_data.name
                                                    : `${space_data
                                                        .name
                                                        .substring(0, 17)}...`}
                                            />
                                        )
                                    })
                                }
                            </FormGroup>
                            : null
                        }
                    </FormControl>
                )
            })
        const List2 = this.state.venues.data && this
            .state
            .venues
            .data
            .map((data, idx) => {
                return (
                    <FormControl component="fieldset" className={classes.fieldset}>
                        <FormLabel className={classes.legend} component="legend">{data.name.length < 17 ? data.name : data.name.substring(0, 17) + '...'}</FormLabel>
                        {data.spaces && data.spaces.length !== 0
                            ? <FormGroup>
                                {data.spaces && data
                                    .spaces
                                    .map(function (space_data, idx) {
                                        return (
                                            <FormControlLabel className={classes.chechboxLabel} disabled={space_data.id.toString() == spaceId ? true : false}
                                                control={<Checkbox onChange={handleChange} defaultChecked={true} value={space_data.id} />}
                                                label={space_data.name.length < 17
                                                    ? space_data.name
                                                    : `${space_data
                                                        .name
                                                        .substring(0, 17)}...`}
                                            />
                                        )
                                    })
                                }
                            </FormGroup>
                            : null
                        }
                    </FormControl>
                )
            })

        const List3 = this.state.venues.data && this
            .state
            .venues
            .data
            .map((data, idx) => {
                return (
                    <FormControl component="fieldset" className={classes.fieldset}>
                        <FormLabel className={classes.legend} component="legend">{data.name.length < 17 ? data.name : data.name.substring(0, 17) + '...'}</FormLabel>
                        {data.spaces && data.spaces.length !== 0
                            ? <FormGroup>
                                {data.spaces && data
                                    .spaces
                                    .map(function (space_data, idx) {
                                        return (
                                            <FormControlLabel className={classes.chechboxLabel} disabled={space_data.id.toString() == spaceId ? true : false}
                                                control={<Checkbox onChange={handleChange} defaultChecked={space_data.id.toString() == spaceId ? true : false} value={space_data.id} />}
                                                label={space_data.name.length < 17
                                                    ? space_data.name
                                                    : `${space_data
                                                        .name
                                                        .substring(0, 17)}...`}
                                            />
                                        )
                                    })
                                }
                            </FormGroup>
                            : null
                        }
                    </FormControl>
                )
            })
        return (
            <div>
                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    classes={{ paper: classes.paper }}
                    open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Apply FIRST-HOLD Status to these spaces
                    </DialogTitle>
                    <DialogContent dividers>
                        <div class="checkall-block">
                    {this.state.spaceLength == this.state.selectedValues.length &&
                            <FormControlLabel className={classes.chechboxLabel}
                                control={<Checkbox onChange={setCheckAll} defaultChecked={true} />}
                                label={'Check All'}
                            />}
                        {this.state.spaceLength != this.state.selectedValues.length && <FormControlLabel className={classes.chechboxLabel}
                            control={<Checkbox onChange={setCheckAll} defaultChecked={false} />}
                            label={'Check All'}
                        />}
                        {this.state.checkAll == '' && this.state.uncheckAll == '' && this.state.selectedSpaces.length >= 1 && List1}
                        {this.state.checkAll == true && List2}
                        {this.state.uncheckAll == true && List3}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={this.handleSave} color="primary">
                            Save changes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

VenueSpaces.propTypes = {
    classes: object.isRequired
};

const mapStateToProps = state => {
    return {};
};

const enhance = compose(withStyles(styles), withRouter, connect(mapStateToProps, dispatch => bindActionCreators({}, dispatch)));
export default enhance(VenueSpaces);
import { CONTACT_US } from 'shared/state/types';
import { request } from 'shared/auth';

function contactUsRequested() {
  return {
    type: CONTACT_US.REQUEST,
  };
}

function contactUsFailed(error) {
  return {
    type: CONTACT_US.FAILURE,
    error:error,
  };
}

function contactUsSuccess(data) {
  return {
    type: CONTACT_US.SUCCESS,
    payload: data,
  };
}

export function contactUs(params) {
  return dispatch => {
    dispatch(contactUsRequested());

    return request().post(`/cms/contact?${params}`)
      .then(response =>{
          dispatch(contactUsSuccess(response.data.data))
          return response.data.data;
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(contactUsFailed(errors.response.data.message));
        }
        else{
          dispatch(contactUsFailed('Not able to access api'));
        }
        throw errors;
      });
  };
}

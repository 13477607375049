import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';


import Grid from '@material-ui/core/Grid';
import "../assets/styles/placeHold.scss";
import { object } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';

const DialogContent = withStyles(theme => ({
root: {
},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
root: {
},
}))(MuiDialogActions);

class ErrorPopup extends React.Component {
state = {
open: this.props.openError,
};


handleClose = () => {
  this.props.errorClose();
  this.setState({open:false})
};

render() {

return (
<div className="h">


<Dialog 

className="success_modal_container hold_settings forgot-something-popup"
aria-labelledby="customized-dialog-title"
open={this.state.open} >
<DialogActions className="dialogActions">
<span
className="material-icons close closeButton"
onClick={this.handleClose}
>
close
</span>
</DialogActions>
<DialogContent className="dialog-content">
<Grid container className="modalBox_content">
<Grid item xs={12}>
      <Grid container className="venue-publish-popup-box" >
            <div className="dont_single" style={{color:'grey'}}>{this.props.message}</div>
            <br />
            <div style={{textAlign:'center'}}>
            <Button style={{margin: '0px',float: 'none',textAlign: 'center'}} onClick={()=>localStorage.getItem('type')==='0' ? window.location.href ='./browseVenue': window.location.href ='./myVenue'} class='red-button'>
                {localStorage.getItem('type')==='0' ? 'Browse Venue': 'My Venue'}
                </Button>
                </div>
      </Grid>
  
   

</Grid>



</Grid>

</DialogContent>


</Dialog>

</div>
);
}
}

ErrorPopup.propTypes = {
classes: object.isRequired,
};

const mapStateToProps = state => {
return {

};
};

const enhance = compose(
withRouter,
connect(mapStateToProps,dispatch =>
bindActionCreators(
{

},
dispatch
)
)
);
export default enhance(ErrorPopup);


import React from "react";
import { Grid } from "@material-ui/core";
// import MondayFriday from "../../shared/components/mondayFriday";
import Typography from "@material-ui/core/Typography";
import "../assets/styles/venueProfile.scss";
// import PlaceHold from "shared/components/placeHold";
// import { update } from "immutable";
// import FollowDate from "shared/components/FollowDate";

function tConvert (time) {
  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
}
function VenueProfileComponent(props) {
  
  var updateVenueSlot = [];
  var finalArray = [];
  var finaldata;
  var objTime = {};
   props.venueProfile.spaces && props.venueProfile.spaces.map(function(data, idx) {
       data.timeslots.map(function(datatime, idx) {
        finaldata = "";
        updateVenueSlot = [];
        objTime = {};
        if (parseInt(datatime.sunday) === 0 || parseInt(datatime.sunday) === 1) {
          let obj = {};
          obj.value = datatime.sunday;
          obj.day = "Sunday";
          updateVenueSlot.push(obj);
        }

        if (parseInt(datatime.monday) === 0 || parseInt(datatime.monday) === 1) {
          let obj = {};
          obj.value = datatime.monday;
          obj.day = "Monday";

          updateVenueSlot.push(obj);
        }
        if (parseInt(datatime.tuesday) === 1 || parseInt(datatime.tuesday) === 0) {
          let obj = {};
          obj.value = datatime.tuesday;
          obj.day = "Tuesday";
          updateVenueSlot.push(obj);
        }
        if (parseInt(datatime.wednesday) === 1 || parseInt(datatime.wednesday) === 0) {
          let obj = {};
          obj.value = datatime.wednesday;
          obj.day = "Wednesday";
          updateVenueSlot.push(obj);
        }
        if (parseInt(datatime.thursday) === 1 || parseInt(datatime.thursday) === 0) {
          let obj = {};
          obj.value = datatime.thursday;
          obj.day = "Thursday";
          updateVenueSlot.push(obj);
        }
        if (parseInt(datatime.friday) === 1 || parseInt(datatime.friday) === 0) {
          let obj = {};
          obj.value = datatime.friday;
          obj.day = "Friday";
          updateVenueSlot.push(obj);
        }
        if (parseInt(datatime.saturday) === 1 || parseInt(datatime.saturday) === 0) {
          let obj = {};
          obj.value = datatime.saturday;
          obj.day = "Saturday";
          updateVenueSlot.push(obj);
        }

        var index;
        for (let i = 0; i < updateVenueSlot.length; i++) {
          if (parseInt(updateVenueSlot[i].value) === 1) {
            index = i;
            finaldata = updateVenueSlot[i].day.substring(0, 3);

            break;
          }
        }

        

        for (let i = index + 1; i < updateVenueSlot.length; i++) {

          if (parseInt(updateVenueSlot[i].value) === 0) {

            if (i - 1 > 0 && updateVenueSlot[i - 1].value === 1) {
    
                finaldata = finaldata + " - " + updateVenueSlot[i - 1].day.substring(0, 3);
              
            }
            if (i + 1 !== updateVenueSlot.length) {

                if (parseInt(updateVenueSlot[i + 1].value) === 1) {

                  finaldata = finaldata + " - " + updateVenueSlot[i + 1].day.substring(0, 3);
                }
              
            }
          }
        }

  
        let fd=finaldata.replace(/' '/g,'');
        fd=fd.split(' - ')
        fd = fd.filter( (ele, ind) => ind === fd.findIndex( elem => elem === ele))
        let finData='';
        for(let fin=0;fin<fd.length;fin++){
          finData=finData +" - " +fd[fin];
        }
        finaldata=finData.replace('-','');

        let time=tConvert(datatime.start_time.substring(0, 5)) +" - " +tConvert(datatime.end_time.substring(0, 5));
        objTime.value = finaldata;
        objTime.time =time;
        finalArray.push(objTime);
        return finalArray;

      });
      return finalArray;
    });


    //removing duplicate data
    finalArray.filter( (ele, ind) => ind === finalArray.findIndex( elem => elem.value === ele.value && elem.time === ele.time))

    const nl2br = require('react-nl2br');
    const description = nl2br(props.venueProfile.description);
    return (
    <Grid container className="venue-available">
      <Grid item xs={12} className="mr-2">
        <Grid container>
          <Grid container>
            {/* {ArrayData.length>0 ? (
              <Grid container>
                <h3 className="availabilitytext">Availability</h3>
                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  xl={12}
                  lg={12}
                  className="dayTimeDiv">
                  {ArrayData.map(data => {
                      return (
                        <div className="satSunfDiv">
                          <MondayFriday dataValue={data.time} day={data.value} />
                        </div>
                      );
                  })}
                  
                </Grid>
              </Grid>
            ):(
              <Grid container>
                <h3 className="availabilitytext">No Availability</h3>
              </Grid>
            )} */}
            
            <Grid container>
              <Grid
                item
                
                xs={12}
                sm={12}
                md={12}
                xl={12}
                lg={12}
                className="avilableDescriptionDiv">
                <div className="avilableDescription">
                  <Typography
                    gutterBottom
                    variant="h4"
                    className="matter aboutHead">
                    About{" "}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className="matter aboutMatter">
                    {description!=='null'?description:''}
              
                        </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default VenueProfileComponent;

import { GET_PLAN } from 'shared/state/types';
import { request } from 'shared/auth';

function getPlanRequested() {
  return {
    type: GET_PLAN.REQUEST,
  };
}

function getPlanFailed(error) {
  return {
    type: GET_PLAN.FAILURE,
    error:error,
  };
}

function getPlanSuccess(data) {
  return {
    type: GET_PLAN.SUCCESS,
    payload: data,
  };
}

export function getPlans(planId, params) {
  return dispatch => {
    dispatch(getPlanRequested());

    return request().get(`user_plans`)
      .then(response =>{
          dispatch(getPlanSuccess(response.data.data))
          return response.data.data;
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(getPlanFailed(errors.response.data.message));
        }
        else{
          dispatch(getPlanFailed('Not able to access api'));
        }
        throw errors;
      });
  };
}

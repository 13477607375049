import axios from 'axios';
import { GET_BOOK_EVENT_REQUEST, GET_BOOK_EVENT_SUCCESS, GET_BOOK_EVENT_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function bookEventRequested() {
  return {
    type: GET_BOOK_EVENT_REQUEST
  };
}

function bookEventSuccess(data) {
  return {
    type: GET_BOOK_EVENT_SUCCESS,
    success: data,
  };
}

function bookEventFailure(error) {
  return {
    type: GET_BOOK_EVENT_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }


  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export function bookEvent(id) {
    return dispatch => {
      dispatch(bookEventRequested());
        return request().put(`event_timeslots/book/`+id)
        .then(response =>
          dispatch (bookEventSuccess(response.data),
                ))
        .catch(errors => {
          dispatch(bookEventFailure(errors));
  
          throw errors;
        });
    
   

    
 };
}

import {GET_PLANNER_PROFILE} from 'shared/state/types';
import {request} from 'shared/auth';

function onRequested() {
    return {type: GET_PLANNER_PROFILE.REQUEST};
}

function onFailed(error) {
    return {type: GET_PLANNER_PROFILE.FAILURE, error: error};
}

function onSuccess(data) {
    return {type: GET_PLANNER_PROFILE.SUCCESS, payload: data};
}

export function getPlannerProfile(plannerId) {
    return dispatch => {
        dispatch(onRequested());
        return request()
            .get(`user/profile/`+plannerId)
            .then(response => {
                dispatch(onSuccess(response.data.data))
                return response.data;
            })
            .catch(errors => {
                if (errors.response) {
                    dispatch(onFailed(errors.response.data.errors));
                } else {
                    dispatch(onFailed("Unable to access api"));
                }
                throw errors;
            });
    };
}

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../assets/styles/venueProfile.scss';
import VenueCardWithFav from '../../shared/components/VenueCardWithFav';
import { Grid } from '@material-ui/core';
const logo = require("../assets/images/default_venue.png");
const styles = theme => ({
  like_icon: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  iconImage: {
    height: "16px",
    width: "16px !important"
  },
  favIcon: {
    fontSize: '20px',
    textShadow: '0px 0px #dfd8d8'
  },
  activeFav: {
    fontSize: '24px',
    textShadow: '0px 0px #ff0000'
  }
});
function VenueSpace(props) {
  let featureVenueData=[];
 props.venueProfile.spaces && props.venueProfile.spaces.map(function(data, idx) {
    let obj={};
    let max_seat=0;
    if(data.arrangements.length>0)
    {
    for(let i=0;i<data.arrangements.length;i++){
      if(data.arrangements[i].pivot.maximum > max_seat){
        max_seat=data.arrangements[i].pivot.maximum;
      }
    }
  }
    obj.max_seat=max_seat;
    obj.id=data.id;
    obj.venueId=data.venue_id;
    obj.venueName=data.name;
    obj.image=data.photo[0] && process.env.REACT_APP_MEDIA_URL + data.photo[0].path;
    if (obj.image == null) {
      obj.image = logo;
    }
    obj.venueAddress="";
    obj.favourite=data.is_favourite;
    obj.favouriteId = ''
    if(data.is_favourite && data.favourite){
      obj.favouriteId=data.favourite.id;
    }
    obj.category="general";
    featureVenueData.push(obj);
    return featureVenueData;
  })
  let url=props.url.split('&');
  if(url.length > 1){
    if(url[9]){
      url=url[9].split("=");
    }
  }
  else{
    url=[""]
  }
  const venueObject = featureVenueData.map(function (data, idx) {
    
    return (
      <div className="venue_space_venue_card" key={idx}>

        <VenueCardWithFav 
        venueSpace={true}
         idx={data.id}
         onClickEvent={props.onClick}
         type="2"
         height="90.9px" 
         id={data.id}
         width="150px" 
         max_seat={data.max_seat}
         image={data.image} 
         favourite={data.favourite} 
         favouriteId={data.favouriteId} 
         venueAddress={data.venueAddress} 
         venueName={data.venueName} 
         category={data.category} 
         enableLink={true} 
         linkTo={url[1]==='true'?"/venue-profile/"+props.slug+"/space/"+data.id+props.url:"/venue-profile/"+props.slug+"/space/"+data.id} />
      </div>
    );
  });

  return (


    <div className="venue-available-right">
      <h3 className="availabilitytext">Check Availability</h3>
      {featureVenueData.length>0 ? (
      <Grid className="venueRightContainer" container justify="left">
        {venueObject}
      </Grid>
      ) : (
      <Grid className="venueRightContainer" container justify="left">
        No space.
      </Grid>
      )}
    </div>

  );
}



export default withStyles(styles)(VenueSpace);

import React from 'react';
import { object } from 'prop-types';

import EventPricing from './eventPricing';

import { withStyles } from '@material-ui/core/styles';

import '../assets/styles/addSpace.scss';

const styles = theme => ({
    root: {
        border: 'none',
        fontFamily: 'Raleway',

        '&$disabled': {
            background: '#ffffff',
            boxShadow: 'none',
        },
        '&:hover': {
            background: '#ffffff',
            border: 'none',
        }
    },
    disabled: {
    },
    icon: {
        fontSize: '16px',
        '&$disabled': {
            color: '#ee2738'
        },
    }
});

function SpaceRatesNestedForm(props) {
    // const { classes } = props;
    return (
        
            <EventPricing
                idx={props.idx}
                openForm={props.openForm}
                values={props.values}
                handleChange={props.handleChange}
                handleBlur={props.handleBlur}
                setFieldValue={props.setFieldValue}
                eventName={props.eventName}
                step4Error={props.step4Error}
                eventId={props.eventId}
                // idx={props.idx}
                fixedRentalAvailabilitySortedDays={props.fixedRentalAvailabilitySortedDays}
                flexibleRentalAvailabilitySortedDays={props.flexibleRentalAvailabilitySortedDays}
                anchorElFixedAddOn={props.anchorElFixedAddOn} 
                isFixedAddonToggleOpen={props.isFixedAddonToggleOpen} 
                deleteEvent={props.deleteEvent}
                fixedEventPopper={props.fixedEventPopper} 
                flexibleEventPopper={props.flexibleEventPopper}
                errors={props.errors}
            />

    );
}


SpaceRatesNestedForm.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(SpaceRatesNestedForm);

import React, { useState } from 'react';
import { Link } from "react-router-dom";

import {
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ImageUpload from '../../../shared/components/ImageUpload';
import CustomizedButton from '../../../shared/components/customizedButton';
import CustomInput from '../../../shared/components/CustomInput';

import { useFormikContext, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import '../../assets/styles/auth.scss';
// const FILE_SIZE = 1024 * 1024 * 10;

const styles = theme => ({

  signupForm: {
    margin: '30px auto',
    width: '430px',
    [theme.breakpoints.up('xs')]: {
      padding: '0 25px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 11px',
    },
    borderRadius: '6px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderImageSource: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0))'
  },
  formFields: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '89%'
  },
  signupDiv: {
    display: 'table',
    margin: '0 auto'
  },
  signupButton: {
    fontSize: '15px',
    width: '130px',
    height: '36px',
    borderRadius: '6px',
    backgroundImage: 'linear-gradient(#f1435e, #ee2738)',
    color: '#ffffff',
    fontWeight: 500,
    border: 'none',
    marginBottom: '30px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '34px',
    letterSpacing: 'normal',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    marginTop: '30px'
  },
  greySignupButton: {
    fontSize: '13px',
    width: '130px',
    height: '36px',
    borderRadius: '6px',
    color: '#ffffff',
    fontWeight: 500,
    border: 'none',
    marginBottom: '30px',
    backgroundColor: '#9aa7af',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginTop: '30px'
  },
  already: {
    fontSize: '12px',
    color: '#ffffff',
    fontWeight: 500,
    marginTop: '20px',
    paddingBottom:'20px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal'
  },
  signinLink: {
    color: '#ffffff',
    "&:hover": {
      color: '#ffffff',
      textDecoration: 'underline',
    }
  },
  textField: {
    width: '100%',
    marginBottom: '15px'
  },
  marginRight12: {
    marginRight: '12px'
  },
  icon: {
    fontSize: '20px',
    marginBottom: '18px',
    color: '#ee2738;'
  },
  checkBoxSpace: {
    marginTop: '10px',
    textAlign: 'left'
  },
  bigAvatar: {
    width: 100,
    height: 100,
  }
});
function handleChange(values){
// error="";
// if(values.image){

// if (!values.image.name.match(/.(jpg|jpeg)$/i)) {

//  error="*Unsupported Format only jpg and jpeg images will be accepted";

// }
// else if( values.image && values.image.size >= FILE_SIZE){
//   error="*File is too large";

// }
// else{
// }
// }
}

function CreatePassword(props) {
  const { classes } = props;
  const { values, errors, handleChange, handleSubmit } = useFormikContext();
  const [passwordTypePass,setpasswordTypePass] = useState('password');
  const [confirmPasswordTypePass,setConfirmPasswordTypePass] = useState('password');
  const changePasswordFieldType = () =>{
    if(passwordTypePass === 'password'){
      setpasswordTypePass('text')
    }
    else{

      setpasswordTypePass('password')
    }
  }
  
  const changeConfirmPasswordFieldType = () =>{
    if(confirmPasswordTypePass === 'password'){
      setConfirmPasswordTypePass('text')
    }
    else{

      setConfirmPasswordTypePass('password')
    }
  }
  return (
    <div className="signup">
    <Grid className="signup__inner" container justify="center">
      <Grid item xl={3} xs={10} md={4} lg={4} sm={6}>
        {/* <h3 className="firstHold" align="center">FIRST-HOLD has been added to Chrome!</h3> */}
        <div className="signupForm">
          <h1 align="center" className="createAnAccount">Create Password</h1>
          <Grid container justify="center">
          </Grid>
          <Grid>
            <div className="formFields">
              <Grid container justify="center">
                <Grid item sm={12} xs={12}>
                  <label className="emailLabel">
                    Password
                  </label>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <div className="password_field_div" style={{position:"relative"}}>
                    <CustomInput st='basicinfo' type={passwordTypePass} name="password" placeholder="Enter password" passwordField changePasswordFieldType={changePasswordFieldType}/>
                  </div>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <label className="passwordLabel">
                    Confirm Password
                      </label>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <div className="password_field_div" style={{position:"relative"}}>
                    <CustomInput st='basicinfo' type={confirmPasswordTypePass} name="confirmPassword" placeholder="Enter password" passwordField changePasswordFieldType={changeConfirmPasswordFieldType}/>
                  </div>
              
                  {/* <Field className="passwordField" type="password" name="password" placeholder="Create a password" /> */}
                  {/* <div className="error"><ErrorMessage name="password" /></div> */}
                </Grid>



              </Grid>
              {/* <div className="signupDiv">
                      <CustomizedButton label="Sign Up" type="submit" disabled={emailExists}/>
                    </div> */}
              <div className="signupDiv">
                {/* <Button
                  classes={{
                    root: classes.root,
                    disabled: classes.disabled,
                  }}
                  onClick={(e) => { handleChangeMail(e) }}
                  disabled={props.disableEmailSignupButton}
                >
                  Sign Up
                </Button> */}
                <CustomizedButton label="Next" type="submit" />


              </div>
            </div>
          </Grid>
        </div>
      </Grid>
    </Grid>
  </div>
  );
}

CreatePassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreatePassword);

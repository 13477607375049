import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';

import { ErrorMessage } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Input } from '@material-ui/core';

import '../../../shared/components/assets/style/CustomSelectWithScroll.scss';


const styles = theme => ({
    root: {
        fontSize: '15px',
        lineHeight: '1.07',
        textAlign: 'left',
        color: '#202020',
        textTransform: 'capitalize',
        '&:hover': {
            background: "#f7f7f7"
        },
        '&:focused': {
            background: "#f7f7f7"
        }
    },
    selectContainer: {
        borderRadius: '6px',
        border: 'solid 1px #e1e1e1',
        background: '#f7f7f7 !important',
        height: '36px',
        width: '100%',
        padding: '0px 4px',
        marginTop: '4px',
        '&:hover': {
            backgroundColor: "#f7f7f7 !important"
        },
        '&:focused': {
            backgroundColor: "#f7f7f7 !important"
        }
    },
    select: {
        '&:hover': {
            backgroundColor: "#f7f7f7 !important"
        },
        '&:focus': {
            backgroundColor: "#f7f7f7 !important"
        }
    },
    placeholder: {
        fontFamily: 'Raleway',
        fontSize: '15px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.07',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#b0b0b0'
    },
    options: {
        fontFamily: 'Raleway',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#ee2738',
        borderBottom: '1px solid #f7f7f7',
        backgroundColor: '#ffffff',
    }
});

const changePricingType = (props, value, pricingType) => {
    var arrayLen = props.arrayValue.filter(function (event) {
        return event.pricingTypeName === value &&
            event.rentalType === props.rentalType &&
            event.endTime === props.endTime &&
            event.startTime === props.startTime
    }).length
    props.arrayValue.map((val, index) => (
        (val.pricingTypeName === props.value && val.startTime === props.startTime && val.endTime === props.endTime && val.eventName === props.eventName && props.days.sort().join(',')=== val.days.sort().join(',') ) &&
        (
            arrayLen = arrayLen + 1,  
            props.setFieldValue(`eventSpaceRatesArray.${index}.pricingTypeName`, value),
            props.setFieldValue(`eventSpaceRatesArray.${index}.pricingType`, pricingType),
            props.setFieldValue(`eventSpaceRatesArray.${index}.idx`, arrayLen)
        )
    ))
}

function PricingCategorySelect(props) {
    let timeSlotId = ''
    const { classes } = props;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const addPricing = (e) => {
        const startTime = moment(props.startTime, "h:mm A").format("HH:mm:ss")
        const endTime = moment(props.endTime, "h:mm A").format("HH:mm:ss")
        props.timeSlots && props.timeSlots.map((obj, index) => {
            if (obj.startTime.toString() === props.startTime.toString() && obj.endTime.toString() === props.endTime.toString() && props.days.sort().join(',')=== obj.days.sort().join(',')) {
                timeSlotId = obj.id
            }
            return timeSlotId;
        })
        const seqNo = props.arrayValue.length + 1;

        const pricingType = e.target.value === 'Venue Rental' ? 1 : e.target.value === 'Food & Bev Minimum' ? 2 : e.target.value === 'Venue Rental + Food & Bev Minimum' ? 3 : 4
        if (props.arrayValue && props.rentalType && props.setFieldValue && props.handleChange) {
            if(!props.arrayValue.find(o => o.rentalType === 'fixed' && o.eventName === props.eventName && o.startTime === props.startTime && o.endTime === props.endTime && props.days.sort().join(',')=== o.days.sort().join(',')) && props.rentalType && props.rentalType === "fixed")
            {
                 props.arrayHelpers.push({seqNo: seqNo,id:'', flatRate: '',additionalFlatRate:'', hasAddFlatRate:false, hasAddPricingTypeName:false, additionalRate: '', eventName: props.eventName, eventId: props.eventId, days: props.days, additionalCost: false, sunday: props.days.includes('sunday') ? true :false, monday: props.days.includes('monday') ? true :false, tuesday: props.days.includes('tuesday') ? true :false, wednesday: props.days.includes('wednesday') ? true :false, thursday: props.days.includes('thursday') ? true :false, friday: props.days.includes('friday') ? true :false, saturday: props.days.includes('saturday') ? true :false, pricingType: pricingType, pricingTypeName: e.target.value, rentalType: 'fixed', idx: 1, startTime: props.startTime, endTime: props.endTime, timeSlotId: timeSlotId, additionalPricingTypeName:'',fnbRate:'', minGuest:'' })
                 props.arrayHelpers.push({ seqNo:seqNo + 1,id:'', flatRate: '',additionalFlatRate:'', hasAddFlatRate:false, hasAddPricingTypeName:false, additionalRate: '', days: props.days ,eventName:props.eventName,eventId: props.eventId, additionalCost: false,sunday: props.days.includes('sunday') ? true :false, monday: props.days.includes('monday') ? true :false, tuesday: props.days.includes('tuesday') ? true :false, wednesday: props.days.includes('wednesday') ? true :false, thursday: props.days.includes('thursday') ? true :false, friday: props.days.includes('friday') ? true :false, saturday: props.days.includes('saturday') ? true :false, pricingTypeName: '',pricingType: 0, rentalType: 'fixed', idx:2, startTime: props.startTime, endTime: props.endTime, timeSlotId: timeSlotId, additionalPricingTypeName: '',fnbRate:'', minGuest:'', up:'no'})
            }
            if(!props.arrayValue.find(o => o.rentalType === 'flexible' && o.eventName === props.eventName && o.startTime === props.startTime && o.endTime === props.endTime && props.days.sort().join(',')=== o.days.sort().join(',')) && props.rentalType && props.rentalType === "flexible"){
                 props.arrayHelpers.push({seqNo: seqNo,id:'', flatRate: '', additionalRate: '',additionalFlatRate:'', hasAddFlatRate:false, hasAddPricingTypeName:false, eventName: props.eventName, eventId: props.eventId, days: props.days, additionalCost: false, sunday: props.days.includes('sunday') ? true :false, monday: props.days.includes('monday') ? true :false, tuesday: props.days.includes('tuesday') ? true :false, wednesday: props.days.includes('wednesday') ? true :false, thursday: props.days.includes('thursday') ? true :false, friday: props.days.includes('friday') ? true :false, saturday: props.days.includes('saturday') ? true :false, pricingType: pricingType, pricingTypeName: e.target.value, rentalType: 'flexible', idx: 1, startTime: props.startTime, endTime: props.endTime, timeSlotId: timeSlotId, additionalPricingTypeName:'',fnbRate:'', minGuest:'' })
                 props.arrayHelpers.push({ seqNo:seqNo + 1,id:'', flatRate: '', additionalRate: '',additionalFlatRate:'', hasAddFlatRate:false, hasAddPricingTypeName:false, days: props.days ,eventName:props.eventName,eventId: props.eventId, additionalCost: false,sunday: props.days.includes('sunday') ? true :false, monday: props.days.includes('monday') ? true :false, tuesday: props.days.includes('tuesday') ? true :false, wednesday: props.days.includes('wednesday') ? true :false, thursday: props.days.includes('thursday') ? true :false, friday: props.days.includes('friday') ? true :false, saturday: props.days.includes('saturday') ? true :false, pricingTypeName: '',pricingType: 0, rentalType: 'flexible', idx:2, startTime: props.startTime, endTime: props.endTime, timeSlotId: timeSlotId, additionalPricingTypeName: '',fnbRate:'', minGuest:'', up:'no'})
            }
            props.setFieldValue(props.name, e.target.value)
        }
        else if (props.setFieldValue && props.arrayName && !props.handleChange) {
            changePricingType(props, e.target.value, pricingType)
        }
        else {
            props.handleChange && props.handleChange(e)
        }
    }
    return (
        <div>
            <div className="custom_select_with_scroll">
                {props.showLabel &&
                    <div className="label_container">
                        <label>
                            {props.label}
                        </label>
                    </div>
                }
                <Select
                    className={classes.selectContainer}
                    classes={{ root: classes.root, select: classes.select }}
                    displayEmpty
                    onChange={e => { addPricing(e) }}
                    onBlur={props.handleBlur}
                    value={props.value}
                    name={props.name}
                    input={<Input id="select-multiple-placeholder" />}
                    MenuProps={MenuProps}
                >
                        <MenuItem disabled value="">
                            <em className={classes.placeholder}>{props.placeholder}</em>
                        </MenuItem>
                    {props.names.map(name => (
                        <MenuItem classes={{ root: classes.options }} key={name} value={name}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div className="error"><ErrorMessage name={props.name} /></div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        spaceData: state.data.spaces.spaceData
    };
};

PricingCategorySelect.propTypes = {
    classes: object.isRequired,
};

const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        null
    ),
)

export default enhance(PricingCategorySelect);
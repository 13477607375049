import React, { Component } from 'react';
import "../assets/styles/membership.scss";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Button, Icon, Hidden } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { unsubscribeCustomer } from 'data/plan/actions';
import { getPlans } from 'data/plan/actions';
import { venueList, userDetails } from "shared/venueList/actions";
import CustomSelect from 'shared/components/CustomSelect';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import CustomSelectWithScroll from 'shared/components/CustomSelectWithScroll';
import moment from "moment";
import * as Yup from "yup";
import RangeSlider from "../slider";
import { blue } from '@material-ui/core/colors';






const styles = theme => ({
    button: {
        fontSize: '14px',
        fontWeight: '500',
        letterSpacing: ' 0.5px',
        textAlign: 'center',
        color: '#202020',
        '&:hover': {
            background: "#ffffff",
        },
        '&:disabled': {
            background: '#ffffff',
            color: '#202020 !important'
        },
        root: {
            width: 300,
        },
        slider: {
            padding: '22px 0px',
        },
    },
    saveAndExit: {
        fontSize: '13px',
        fontWeight: '500',
        letterSpacing: '0.46px',
        color: '#202020',
        paddingTop: '7px',
        textDecoration: 'none',
        background: 'none',
        boxShadow: 'none',
        '&:hover': {
            color: '#202020',
            textDecoration: 'none',
            cursor: 'pointer',
            background: 'none',
            boxShadow: 'none',
        },
        '&:disabled': {
            background: '#ffffff',
            color: '#202020 !important'
        },
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
        color: '#d81c2c',
        fontSize: '17px'
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
        color: '#d81c2c',
        fontSize: '17px'
    },
    radio: {
        '&$checked': {
          color:'#1E88E5',
        }
      },
      checked: {}
});
const planUnsubscribe = Yup.object().shape({
    unsubscribeReason: Yup.string()
        .required("*Required"),
    //  message: Yup.string("*Message must be a string")
    // .required('*Required')
    //  .max(385, "Message should not be more than 385 characters"),
    // improve:Yup.string()
    //.required("*Required"),
});
const planUnsubscribeReason = [
    {
        id: "Financial reasons",
        name: "Financial reasons"
    },
    {
        id: "Not seeing the results I wanted",
        name: "Not seeing the results I wanted"
    },
    {
        id: "No longer need",
        name: "No longer need"
    },
    {
        id: "FIRST-HOLD doesn’t have a feature I need",
        name: "FIRST-HOLD doesn’t have a feature I need"
    },
    {
        id: "Issue with FIRST-HOLD",
        name: "Issue with FIRST-HOLD"
    },
    {
        id: "I need to take a break",
        name: "I need to take a break"
    },
    {
        id: "Other",
        name: "Other"
    }
];
const sliderProps = ({
    min: 0,
    max: 10
});
class membership extends Component {
    state = {
        cancelSubscription: false,
        step: 1,
        decrementStep: 1,
        venueList: this.props.list,
        venueId: '',
        onlineSince: '',
        subscription_status: 0,
        planType: '',
        tier_expired_at: '',
        payment_date: '',
        trial_tier_expired_at: '',
        trial_planType: '',
        trial_payment_date: '',
        next: true,
        radioSelectValues:'No',
        desc: '',
        reason: '',
        loading: true,
        cardLast4: '',
        rangeValue: 10,
        plan_id:'',
        subscription_agree_check: 0,
    }


    UNSAFE_componentWillMount() {
        let planTier = ''
        localStorage.removeItem('plan_id')
        localStorage.removeItem('venue_plan_id')
        this.props.getPlans()
        this.props.venueList().then((res) => {
            this.setState({ venueId: (res.venueList.data[0].id).toString() })
            this.setState({ subscription_status: (res.venueList.data[0].subscription_status) })
            this.setState({ subscription_agree_check: (res.venueList.data[0].subscription_agree_check) })
            //this.props.planList.map((data, idx) => {
                let plan = this.props.planList.find(p => p.venue_id === res.venueList.data[0].id)
                if (plan) {
                    if (plan.tier === 1) {
                        planTier = 'Tier One'
                    }
                    else if (plan.tier === 2) {
                        planTier = 'Tier Two'
                    }
                    else {
                        planTier = 'Tier Three'
                    }
                    if (res.venueList.data[0].subscription_status === 1) {
                        this.setState(({ planType: planTier }))
                        this.setState({ tier_expired_at: plan.tier_expired_at })
                        this.setState({ payment_date: plan.updated_at })
                        this.setState({ cardLast4: plan.card_last4 })
                        this.setState({ plan_id: plan.id })
                    }
                    else {
                        this.setState(({ planType: planTier }))
                        this.setState({ tier_expired_at: '-' })
                        this.setState({ payment_date: plan.updated_at })
                        this.setState({ cardLast4: plan.card_last4 })
                    }
                }
                else 
                {
                    this.setState(({ planType: 'Trial' }))
                    this.setState({ tier_expired_at: '-' })
                    this.setState({ payment_date: '-' })  
                }
            //})
            let trailPlan=this.props.planList.find(p => p.venue_id === res.venueList.data[0].id)
            // if(trailPlan)
            // {
            //     //
            // }
            // else 
            // {
            //     this.setState(({ planType: 'Trial' }))
            //     this.setState({ tier_expired_at: '-' })
            //     this.setState({ payment_date: '-' })  
            // }
        }).catch(error => {

        });
        this.props.userDetails().then((res) => {
            this.setState({ onlineSince: (this.props.userData.data.created_at) })
        })
    };


    handleIncrement = (values, setFieldValue) => {
        if (this.state.step === 1) {
            if (this.state.reason != '') {
                //this.setState({next:true})
                this.setState({ step: this.state.step + 1 })
            }
        }
        if (this.state.step === 2) {
            if (this.state.desc != '') {
                //this.setState({next:true})
                this.setState({ step: this.state.step + 1 })
            }
        }
    }
    handleDecrement = (values, actions) => {
        this.setState({ step: this.state.step - 1 })
    }
    handleSubmit = (values, actions) => {

        if (this.state.desc != '' && this.state.radioSelectValues != '' && this.state.step === 3) {
            this.setState({ next: false })
            const formData = {
                venue_id: this.state.venueId,
                plan_id:this.state.plan_id,
                message: this.state.desc,
                reason: this.state.reason,
                recommendation_value: this.state.rangeValue,
                radioValue: this.state.radioSelectValues
            }
            this.props.unsubscribeCustomer(formData, this.state.venueId).then((response) => {
                this.props.venueList().then(() => {
                    this.setState({ loading: false })
                }).catch(error => {

                });
            }).catch(error => { });
        }
    }
    onVenueSelect = (e, setFieldValue) => {
        let planTier = ''
        this.setState({ venueId: e.target.value })
        //this.setState({subscription_status:(res.venueList.data[0].subscription_status)})
        let venueList = this.props.list.data.find(p => p.id === e.target.value)
        this.setState({ subscription_status: venueList.subscription_status })
        this.setState({ subscription_agree_check: (venueList.subscription_agree_check) })
        //this.props.planList.map((data, idx) => {
            let plan = this.props.planList.find(p => p.venue_id === e.target.value)
            if (plan) {
                if (plan.tier === 1) {
                    planTier = 'Tier One'
                }
                else if (plan.tier === 2) {
                    planTier = 'Tier Two'
                }
                else {
                    planTier = 'Tier Three'
                }
                if (venueList.subscription_status === 1) {
                    this.setState(({ planType: planTier }))
                    this.setState({ tier_expired_at: plan.tier_expired_at })
                    this.setState({ payment_date: plan.updated_at })
                    this.setState({ cardLast4: plan.card_last4 })
                    this.setState({ plan_id: plan.id })
                }
                else {
                    this.setState({ cardLast4: plan.card_last4 })
                    this.setState(({ planType: planTier }))
                    this.setState({ tier_expired_at: '-' })
                    this.setState({ payment_date: plan.updated_at })
                }
               
            }
            else 
            {
                this.setState(({ planType: 'Trial' }))
                this.setState({ tier_expired_at: '-' })
                this.setState({ payment_date: '-' })  
            }
           
        //})
        // let trailPlan=this.props.planList.find(p => p.venue_id === e.target.value)
        // if(trailPlan)
        // {
        //     //
        // }
        // else 
        // {
        //     this.setState(({ planType: 'Trial' }))
        //     this.setState({ tier_expired_at: '-' })
        //     this.setState({ payment_date: '-' })  
        // }
    }
        
    
    onSelectReason = (e, setFieldValue,) => {
        setFieldValue('unsubscribeReason', e.target.value)
        this.setState({ reason: e.target.value })
    }

    handleRadioClick = (values, setFieldValue,) => {
        setFieldValue('radioSelectValues', values.target.value)
        this.setState(({ radioSelectValues: values.target.value }))
    }

    handleMessageClick = (values, setFieldValue,) => {
        setFieldValue('message', values.target.value)
        this.setState(({ desc: values.target.value }))


    }

    handleSlide = (e) => {
        // let sliderValue=e.target.value ;
        this.setState({ rangeValue: e.target.value });
        // document.getElementById("myRange").oninput = function() {
        //     var value = sliderValue*10
        //     this.style.background = 'linear-gradient(to right, #f50057 0%, #f50057 ' + value + '%, #fff ' + value + '%, white 100%)'
        //   };
       
    };
    render() {
        const venueList = this.props.list;

        const changeSubmission = (values, actions) => {

        }
        const { classes } = this.props;
        let initialValues = {
            venue_id: this.state.venueId,
            unsubscribeReason: '',

        };

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={planUnsubscribe}
                onSubmit={(values, actions) => {
                    this.handleSubmit(values, actions)
                }}
            >
                {({ isSubmitting, values, handleBlur, handleChange, setFieldValue }) => (
                    <Form>
                        <div className="container membership-wrapper" style={{ paddingTop: '85px', paddingBottom:'50px' }}>
                            <div className=''>
                                {this.state.cancelSubscription === false &&
                                    <div>

                                        <h1 class="main-heading">This guide will help you get the most out of your FIRST-HOLD membership.</h1>
                                        <p className="content">
                                            Looking to get more inquiries from FIRST-HOLD?
                                        </p>
                                        <p className="content">
                                            <a href="https://www.first-hold.com/schedule">Book time with a FIRST-HOLD expert</a> to help with any of the following items.
                                        </p>
                                        <ul className='list-block'>
                                            <li>
                                                <strong>Did you add the widget to your website?</strong> This is an important feature to your FIRST-HOLD membership and statistics have shown will increase your leads.
                                            </li>
                                            <li>
                                                <strong>Improve SEO</strong>– We can help improve Search Engine Optimization on Google, Bing and many others.
                                            </li>
                                            <li>
                                                <strong>Trusted Lead Network</strong>– Are you benefiting from your Trusted Lead Network? Share widget with industry partners.
                                            </li>
                                            <li>
                                                <strong>Improve photos</strong>– This is so important.  We have professional photographers that will take set-up shots at your venue at a special negotiated rate.
                                            </li>
                                            <li>
                                                <strong>Profile Optimization</strong>– Speak with a FIRST-HOLD expert to improve your overall profile.
                                            </li>
                                        </ul>
                                        <h4 class="sub-heading">Budget concerns</h4>
                                        <ul class="list-block">
                                            <li>Consider change your <a href='https://www.first-hold.com/venuePlanList'>plan</a> meet your needs and budget.</li>
                                            <li>Having Financial Difficulties, <a href='https://www.first-hold.com/contactUs'>contact us</a>, we’ll work with you to come up with a solution.</li>
                                        </ul>
                                        <h4 class="sub-heading">Options to deactivate your subscription</h4>
                                        <p class="content">You can end your website subscription at any time using the following method.</p>
                                        <ul className='list-block'>
                                            <li>Review what happens to your content when you cancel your subscription.</li>
                                            <li><span className='text-underline'>Cancel your subscription or plan</span>, which takes your site offline for the next billing cycle.</li>
                                        </ul>
                                        <h4 class="sub-heading">Website Billing</h4>
                                        <p class="content">
                                            Manage your profile subscription and billing information.
                                        </p>
                                        <Grid className="billing-list" container>
                                            <Grid className="" item md={4} xs={6}>
                                                <p>Profile:</p>
                                            </Grid>
                                            <Grid className="" item md={6} xs={6}>
                                                <CustomSelectWithScroll
                                                    className="custome-select"
                                                    value={this.state.venueId}
                                                    placeholder="Select"
                                                    setFieldValue={setFieldValue}
                                                    options={venueList.data}
                                                    handleChange={(e, selected) => { handleChange(e); this.onVenueSelect(e, setFieldValue) }}
                                                    handleBlur={handleBlur}
                                                    name="venue_id" label="" />
                                            </Grid>
                                        </Grid>
                                        <Grid className="billing-list" container>
                                            <Grid className="" item md={4} xs={6}>
                                                <p>Online Since:</p>
                                            </Grid>
                                            <Grid className="" item md={6} xs={6}>
                                                <p>{this.state.onlineSince != '' ? moment(new Date(this.state.onlineSince.slice(0, 10))).format("LL") : this.state.onlineSince}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid className="billing-list" container>
                                            <Grid className="" item md={4} xs={6}>
                                                <p>Payment:</p>
                                            </Grid>
                                            <Grid className="" item md={6} xs={6}>
                                                <p>{this.state.payment_date != '' && this.state.payment_date != '-' ? moment(new Date(this.state.payment_date.slice(0, 10))).format("LL") : this.state.payment_date}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid className="billing-list" container>
                                            <Grid className="" item md={4} xs={6}>
                                                <p>Credit Card:</p>
                                            </Grid>
                                            <Grid className="" item md={6} xs={6}>
                                                <p>{this.state.cardLast4!=''&&this.state.cardLast4!=null?'XXXX-XXXX-XXXX-'+this.state.cardLast4:'-'}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid className="billing-list" container>
                                            <Grid className="" item md={4} xs={6}>
                                                <p>Payment Due:</p>
                                            </Grid>
                                            <Grid className="" item md={6} xs={6}>
                                                <p>{this.state.tier_expired_at != '' && this.state.tier_expired_at != '-' ? moment(new Date(this.state.tier_expired_at.slice(0, 10))).add(1, "days").format("LL") : this.state.tier_expired_at}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid className="billing-list" container>
                                            <Grid className="" item md={4} xs={6}>
                                                <p>Plan:</p>
                                            </Grid>
                                            <Grid className="" item md={6} xs={6}>
                                                <p>{this.state.planType}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid className="billing-list" container>
                                            <Grid className="" item md={4} xs={6}>
                                                <p>Automatically Renew:</p>
                                            </Grid>
                                            <Grid className="switch-btn" item md={6} xs={6}>
                                                <Switch checked={this.state.planType != 'Trial' ? this.state.subscription_agree_check : 0} value="tripleseat_status" />
                                            </Grid>
                                        </Grid>
                                        {(this.state.planType == 'Tier Two' || this.state.planType == 'Tier One' || this.state.planType == 'Tier Three') && this.state.subscription_agree_check === 1 &&
                                            <Grid className="billing-list" container>
                                                <Grid className="" item md={6} xs={12}>
                                                    <div class="search-btn started">
                                                        <a class="" onClick={(e) => { this.setState({ cancelSubscription: true }) }}>CANCEL SUBSCRIPTION</a>
                                                    </div>
                                                </Grid>

                                            </Grid>
                                        }
                                    </div>
                                }
                                {this.state.cancelSubscription &&
                                    <div>
                                        {this.state.step === 1 &&
                                            <div class="subscription-list page-height">
                                                <h4 class="sub-heading">Cancel your subscription</h4>
                                                <p class="pb-10">Why are you canceling your subscription?</p>
                                                <div>
                                                    <CustomSelectWithScroll
                                                        className="custome-select"
                                                        value={values.unsubscribeReason}
                                                        placeholder="Select a reason"
                                                        setFieldValue={setFieldValue}
                                                        options={planUnsubscribeReason}
                                                        handleChange={(e, selected) => { handleChange(e); this.onSelectReason(e, setFieldValue); }}
                                                        handleBlur={handleBlur}
                                                        name="unsubscribeReason" label="" />
                                                </div>
                                            </div>
                                        }
                                        <div>
                                            {this.state.step === 2 &&
                                                <div class="page-height2">
                                                    <div className='pt-20'>

                                                        {/* <h4 class="sub-heading">Cancel your subscription</h4> */}
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">Are you leaving for another availability matcher?</FormLabel>
                                                            <RadioGroup aria-label="improve" name="improve" defaultValue={this.state.radioSelectValues}>
                                                                <FormControlLabel value="Yes" control={<Radio classes={{root: classes.radio, checked: classes.checked}}/>} label="Yes"
                                                                    onChange={(e) => { this.handleRadioClick(e, setFieldValue) }} />
                                                                <FormControlLabel value="No" control={<Radio classes={{root: classes.radio, checked: classes.checked}}/>} label="No"
                                                                    onChange={(e) => { this.handleRadioClick(e, setFieldValue) }} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                    <div className='pd-bottom'>
                                                        <p class="content">How likely are you to recommend FIRST-HOLD to a friend or colleague?</p>
                                                        <div className="pt-20">
                                                            <RangeSlider
                                                                {...sliderProps}
                                                                onChange={this.handleSlide}
                                                                value={this.state.rangeValue}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="content">Anything we can do to improve?*</p>
                                                        <Field className="textarea" component="textarea" align="center" type="text" name="message" placeholder="Type response..."
                                                            onChange={(e) => { this.handleMessageClick(e, setFieldValue) }} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div class="text-center">
                                            {this.state.step === 3 ?
                                                this.state.loading === false ?
                                                    <div className=" cancel-subscription pd-bottom page-height">
                                                        <h1 class="sub-heading">Your subscription has been canceled</h1>
                                                        {/* <p class="description">Your subscription has been canceled and taken offline.</p> */}
                                                        <p class="content">You will no longer be billed for your next billing cycle.</p>
                                                    </div>
                                                    :
                                                    <div className=" cancel-subscription pd-bottom page-height">
                                                        Loading...
                                                    </div>
                                                : null
                                            }
                                        </div>
                                        {this.state.step === 2 &&
                                            <Button variant="outlined" size="medium" className="back-btn" onClick={(e) => {
                                                this.handleDecrement()
                                            }} disabled={this.state.step === 1}>
                                                <Icon className={classNames(classes.leftIcon, classes.iconSmall)} >
                                                    arrow_back
                                                </Icon>
                                                Back
                                            </Button>
                                        }   {this.state.next &&
                                            <Button variant="outlined" size="medium" className="next-btn" type="submit"
                                                disabled={this.state.step === 2 ? (this.state.desc != '' && this.state.radioSelectValues != '' ? false : true) : false}
                                                onClick={(e) => {
                                                    this.handleIncrement(e, setFieldValue)
                                                }}
                                            >{this.state.step != 3 ?
                                                'Next' : 'Done'
                                                }
                                                <Icon className={classNames(classes.rightIcon, classes.iconSmall)} >
                                                    arrow_forward
                                                </Icon>
                                            </Button>
                                        }
                                        {this.state.next === false &&
                                            <Button variant="outlined" size="medium" className="next-btn"
                                                onClick={(e) => {
                                                    window.location.href = '/membership'
                                                }}
                                            >
                                                Done
                                            </Button>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}
membership.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
    return {
        userData: state.data.usersVenue.userInfo,
        list: state.data.usersVenue.venueList,
        planList: state.data.plan.planList
    };
};
const enhance = compose(
    withRouter, withStyles(styles),
    connect(mapStateToProps, dispatch =>
        bindActionCreators(
            {
                venueList,
                getPlans,
                unsubscribeCustomer,
                userDetails
            },
            dispatch
        )
    )
);
export default enhance(withRouter(membership));



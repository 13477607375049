import axios from 'axios';
import { GET_DELETE_EVENT_REQUEST, GET_DELETE_EVENT_SUCCESS, GET_DELETE_EVENT_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function deleteEventRequested() {
  return {
    type: GET_DELETE_EVENT_REQUEST
  };
}

function deleteEventSuccess(data) {
  return {
    type: GET_DELETE_EVENT_SUCCESS,
    eventUpdateData: data,
  };
}

function deleteEventFailure(error) {
  return {
    type: GET_DELETE_EVENT_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }


  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export function deleteEvent(id,params) {
    return dispatch => {
      dispatch(deleteEventRequested());
        return request().delete(`event_timeslots/`+id,params)
        .then(response =>
          dispatch (deleteEventSuccess(response.data),
                ))
        .catch(errors => {
          dispatch(deleteEventFailure(errors)
          );
  
          throw errors;
        });
    
   

    
 };
}

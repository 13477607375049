import axios from 'axios';
import { GET_VENUE_SUGG_REQUEST, GET_VENUE_SUGG_SUCCESS, GET_VENUE_SUGG_FAILURE } from 'shared/state/types';
function venueSuggRequested() {
  return {
    type: GET_VENUE_SUGG_REQUEST
  };
}


function venueSuggSuccess(data) {
  return {
    type: GET_VENUE_SUGG_SUCCESS,
    venueSuggestions: data,
  };
}

function venueSuggFailure(error) {
  return {
    type: GET_VENUE_SUGG_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};
  return axios.create({
    baseURL,
    headers,
  })
};

export function staticVenueOnLoad(params) {
    return dispatch => {
      dispatch(venueSuggRequested());
        return request().post('search/suggession',params)
        .then(response =>
          dispatch (venueSuggSuccess(response.data),
          )
        )
        .catch(errors => {
          dispatch(venueSuggFailure(errors.response));
          throw errors;
        });
      


    
 };
}

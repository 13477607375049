import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import "../../shared/components/assets/style/placeHold.scss";
import DialogActions from "@material-ui/core/DialogActions";
import CustomTimeSelect from "../../shared/components/CustomTimeSelect.js";
import { Formik, Field, ErrorMessage, Form } from "formik";
import CustomSelect from "../../shared/components/CustomSelect";
import { Datepicker } from "react-formik-ui";
import { eventList, eventType } from "../../shared/placeHolds/actions";
import { createEvent } from "../../shared/createEvent/actions";
import { func } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import { seatingCategory } from "shared/search/actions";
import { saveEvent,updateEventData,deleteEvent } from "shared/holdEvent/actions";
import { updateEvent } from "shared/updateEvent/actions";
//import CustomizedButton from "../../shared/components/customizedButton";
import SelectBudget from 'shared/components/SelectBudget'; 
import ModalBoxEvent from "../../shared/components/ModalBoxEvent";
import { city } from "shared/search/actions";
//import { stat } from "fs";
import Autocomplete from "react-autocomplete";

let seatingType = [];
//const RegEx = /^[0-9]{0,8}[-][0-9]{0,8}$/;
const RegEx = /^[\d-]+$/;
const styles = theme => ({
  root: {
    margin: 0,
    overflow: "visible"
  },
  paperWidthSm: {
    margin: "20px",
    overflow: "visible"
  },

  paper: {
    margin: "20px",
    overflow: "visible"
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      fontWeight: "500",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.46px",
      color: "black"
    }
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      borderRadius: "6px",
      // border: "solid 1px black"
    }
  }
});
function tConvert (time) {
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { 
    time = time.slice (1);
    time.pop();
  }
  return time.join (''); 
}
const EventSchema = Yup.object().shape({
  eventName: Yup.string()
    .required("*Required"),
  city: Yup.string()
    .required("*Required"),
  event_type: Yup.string()
    .required("*Required"),
  budget: Yup.string()
    .matches(RegEx, "Please enter a number (i.e. 100) or range (i.e. 100-150)")
    .required("*Required"),
  place_hold_seating: Yup.string()
    .matches(RegEx, "Please enter a number (i.e. 100) or range (i.e. 100-150)")
    .required("*Required"),
  startDateRange: Yup.string()
    .required("*Required"),
  startTime: Yup.string()
    .required("*Required"),
  endTime: Yup.string()
    .required("*Required"),
  setUp: Yup.string()
    .required("*Required"),
  takeDown: Yup.string() 
    .required("*Required"),
  seating: Yup.string()
    .required("*Required"),
  isHold: Yup.boolean(),
  hold_rank: Yup.number()
      .when("isHold", {
        is: true,
        then: Yup.number().required("*Required")
      })
});

const DialogContent = withStyles(theme => ({
  root: {}
}))(MuiDialogContent);

const setUpArray = [
  {
    id: "1 hr",
    name: "1 hr"
  },
  {
    id: "1 hr",
    name: "2 hr"
  },
  {
    id: "1 hr",
    name: "3 hr"
  },
  {
    id: "1 hr",
    name: "4 hr"
  },
  {
    id: "1 hr",
    name: "5 hr"
  },
  {
    id: "1 hr",
    name: "6 hr"
  },
  {
    id: "1 hr",
    name: "7 hr"
  },
  {
    id: "1 hr",
    name: "8 hr"
  },
  {
    id: "1 hr",
    name: "9 hr"
  },
  {
    id: "1 hr",
    name: "10 hr"
  },
  {
    id: "1 hr",
    name: "11 hr"
  },
  {
    id: "1 hr",
    name: "12 hr"
  },
  {
    id: "1 hr",
    name: "24 hr"
  },
  {
    id: "1 hr",
    name: "48 hr"
  },
  {
    id: "1 hr",
    name: "72 hr"
  }
];

class EditEvent extends React.Component {
  // constructor(props, context) {
  //   super(props, context);
  // }

  state = {
    cityList:[],
    date: "",
    holdPopupState:false,
    id:"",
    open: false,
    showForm: false,
    buttonDisabled: true,
    buttonSaveDisabled: true,
    getResponse: false,
    radioSelectedValue: "",
    seatingType: [],
    timeError: "",
    hold_id:"",
    hold_rank:'',
    event_id:'',
    budget:'',
    city:'',
    //date:'',
    end_time:'',
    event_type:'',
    name:'',
    seating:'',
    seating_type:'',
    setup:'',
    teardown:'',
    start_time:'',
    update:false,
    hold_value:'',
    hold_positions:[]
  };
  componentWillMount() {
    this.props.eventType().then(() => {
        this.setState({open:this.props.open})
    }) 
    this.props.seatingCategory().then(response => {
        seatingType = [];
        response.data.map(function(data, idx) {
          let obj = {};
          obj.value = data.id;
          obj.name = data.name;
          seatingType.push(obj);
          return seatingType;
        });
        this.setState({ seatingType: seatingType });
      })
      this.props.city().then(response=>{
      }).catch(error=>{});

    
    if(this.props.dataCity.space && this.props.dataCity.space.venue &&  this.props.dataCity.space.venue.max_hold_limit){
      let holdPositions = [];
      for(let i=1;i<=this.props.dataCity.space.venue.max_hold_limit;i++){
        holdPositions.push({id:i,value:i+" Hold"});
      }
      this.setState({hold_positions:holdPositions});
    }else{
      this.setState({hold_positions:[{id:1,value:"1 Hold"},{id:2,value:"2 Hold"},{id:3,value:"3 Hold"}]});
    }

    if(this.props.eventPlanner.state && this.props.eventPlanner.state != null){
      let data     = JSON.parse(this.props.eventPlanner.state)
      let budget   = data.budget;
      let sSeating = data.seating;
      if(sSeating && typeof(sSeating)!=='string') {
        sSeating = sSeating.toString();
      }
  
      this.setState({seating:sSeating})        
      this.setState({budget:budget})
      /*if(budget >= 0 && budget<=500){
        this.setState({budget:1})
      }
      else if(budget > 500 && budget<=1000){
        this.setState({budget:2})
      }
      else if(budget > 1000 && budget<=5000){
        this.setState({budget:3})
      }
      else if(budget > 5000 && budget<=10000){
        this.setState({budget:4})
      }
      else if(budget > 10000 && budget<=50000){
        this.setState({budget:5})
      }
      else if(budget > 50000 && budget<= 100000){
        this.setState({budget:6})
      }
      else if(budget > 100000){
        this.setState({budget:7})
      }*/

    this.setState({start_time:tConvert(data.start_time == '-'?'00:00:00':data.start_time)})
    this.setState({end_time:tConvert(data.end_time == '-'?'00:00:00':data.end_time)})
    this.setState({name:data.name})
    this.setState({seating_type:data.arrangement_id !== null ? data.arrangement_id : null})

    let time_setup=data.setup_time ? data.setup_time+" hr": null;
    this.setState({setup:time_setup})

    let time_tear=data.teardown_time ? data.teardown_time+" hr": null; 
    this.setState({teardown:time_tear})    

    }else if(this.props.eventPlanner.budget!=null){
      this.setState({name:this.props.eventPlanner.name})
      this.setState({seating:this.props.eventPlanner.min_seating !== null && this.props.eventPlanner.max_seating !== null ? this.props.eventPlanner.min_seating +"-"+this.props.eventPlanner.max_seating : ''})
      this.setState({budget:this.props.eventPlanner.budget})
      this.setState({start_time:tConvert(this.props.eventPlanner.start_time)})
      this.setState({end_time:tConvert(this.props.eventPlanner.end_time)})
      this.setState({max_seating:this.props.eventPlanner.max_seating})
      this.setState({seating_type:this.props.eventPlanner.arrangement !== null ? this.props.eventPlanner.arrangement.id : null})
      let time_setup=this.props.eventPlanner.setup_time ? this.props.eventPlanner.setup_time.split('.'): null;
      time_setup= time_setup !== null ? time_setup[0]+" hr" : null;
      this.setState({setup:time_setup})
  
      let time_tear=this.props.eventPlanner.teardown_time ? this.props.eventPlanner.teardown_time.split('.') : null;
      time_tear=time_tear !== null ? time_tear[0]+" hr" : null;
      this.setState({teardown:time_tear})
    }
 
    this.setState({city:this.props.eventPlanner.city})
    this.setState({event_type:this.props.eventPlanner.event_type.id})

    this.setState({start_date:this.props.eventPlanner.start_date})
    this.setState({end_date:this.props.eventPlanner.end_date})
    this.setState({hold_rank:this.props.dataCity.hold_rank});
    this.setState({cityVal:this.props.dataCity.space.venue && this.props.dataCity.space.venue.address.city})


  }
  UNSAFE_componentWillReceiveProps(nextProps){
    let cityList = []
    if(this.props.cityInfo !== nextProps.cityInfo && Object.keys(nextProps.cityInfo).length > 0){
      nextProps.cityInfo.data.map((city,idx)=>{
        cityList.push({ key: city.city, id: idx})
        return cityList;
      })
      this.setState({cityList: cityList})
    }
    if(this.props.dataCity!=nextProps.dataCity){
      this.setState({cityVal:nextProps.dataCity.space.venue && nextProps.dataCity.space.venue.address.city})
    }
  }
  handleClose = () => {
    this.props.close();
  }
  handleSubmit = (values, { setSubmitting }) => {  
    this.setState({
      holdPopupState:false,
      name: values.eventName,
      // takeDownError: "",
      // setUpError: "",
      timeError: ""
    });

    let seating = [];
  
    /*if(this.props.eventPlanner.event_planner.id==localStorage.getItem('id') && this.props.eventPlanner.state && this.props.eventPlanner.state != null){
      seating[0]  = 0;
      seating[1]  = values.place_hold_seating_free; 
    }else{  
      seating = values.place_hold_seating.split("-");
    }*/
    
    if(values.place_hold_seating &&  values.place_hold_seating.includes('-')){
      seating = values.place_hold_seating.split("-");
    }else{
      seating[0]  = 0;
      seating[1]  = values.place_hold_seating; 
    }
    
    let setup_time = values.setUp.split(" ");
    let takeDown = values.takeDown.split(" ");
    let json = {};
    json.event_type_id = values.event_type;
    json.name = values.eventName;
    json.city = values.city;
    json.start_date = moment(values.startDateRange).format("YYYY-MM-DD");
    if(values.endDateRange){
      json.end_date = moment(values.endDateRange).format("YYYY-MM-DD");
    }
    else{
      json.end_date = moment(values.startDateRange).format("YYYY-MM-DD");
    }


    json.start_time = values.startTime + ":00";
    json.end_time = values.endTime + ":00";
    json.setup_time = setup_time[0];
    json.teardown_time = takeDown[0];
    json.min_seating = seating[0];
    json.max_seating = seating[1];
    json.seating     = seating[0]>0?seating[0]+"-"+seating[1]:seating[1];
    json.budget      = values.budget;
    if(values.hold_rank){
      json.updated_hold_rank = values.hold_rank;
      json.hold_rank         = values.hold_rank;
      
    }  
    json.arrangement_id = values.seating;
    //json.event_timeslot_id = this.props.dataCity.id;
    json.timeslot_id = this.props.dataCity.timeslot_id;
    json.space_id    = this.props.dataCity.space_id
    

    
    var start_time = new Date(
      moment().format("LL") + " " + values.startTime + ":00"
    );
    var end_time = new Date(
      moment().format("LL") + " " + values.endTime + ":00"
    );

    // if (start_time.getTime() > end_time.getTime()) {
    //   this.setState({ timeError: "Invalid Time" });
    //   this.setState(prevState => ({ showForm: true, eventsPresent: false }));
    // }
    let matched_min = new Date(moment().format("LL") + " 00:00:00");

    if (
      start_time.getTime() - setup_time[0] * 3600000 <
      matched_min.getTime()
    ) {
      // this.setState({ setUpError: "Invalid Time" });
      this.setState(prevState => ({ showForm: true, eventsPresent: false }));
    }
    let matched_max = new Date(moment().format("LL") + " 24:00:00");
    if (end_time.getTime() + takeDown[0] * 3600000 > matched_max.getTime()) {
      // this.setState({ takeDownError: "Invalid Time" });
      this.setState(prevState => ({ showForm: true, eventsPresent: false }));
    }

    if (
      // this.state.takeDownError == "" &&
      this.state.timeError.toString() === "" 
      // this.state.setUpError == ""
    ) {
      this.setState(prevState => ({ showForm: false, eventsPresent: true }));
        //update

        let arr=this.state.eventData;
        let update_arr=[];
        let params = json.hold_rank? {data:[json],hold_rank:parseInt(json.hold_rank)} : {data:[json]};

        this.props.updateEventData(this.props.dataCity.id,params).then((response)=>{
          this.props.EditUpdate();
          this.handleClose();
          for(let i=0;i<arr.length;i++){
            let obj={};
            if(this.state.event_id.toString()===arr[i].id.toString()){
              obj.budget=values.budget;
              obj.city=values.city;
              obj.start_time = values.startTime + ":00";
              obj.end_time = values.endTime + ":00";
              obj.event_type=values.event_type;
              obj.id=this.state.event_id;
              obj.name=values.eventName;
              obj.seating=seating[0]+"-"+seating[1];
              obj.seating_type=values.seating;
              obj.holds=arr[i].holds;
              obj.setup=setup_time[0];
              obj.teardown=takeDown[0];
              obj.start_date_follow=values.startDateRange;
              if(values.endDateRange){
                obj.end_date_follow=values.endDateRange;

              }
              else
              {
                obj.end_date_follow=values.startDateRange;

              }
              if (!values.endDateRange || values.startDateRange.toString() === values.endDateRange.toString()) {
                obj.date = moment(values.startDateRange).format("MM/DD/YYYY");
              } else {
                obj.date = moment(values.startDateRange).format("MM/DD/YYYY") +" to "+ moment(values.endDateRange).format("MM/DD/YYYY");
              }
            }
            else{
              obj=arr[i];
            }

            update_arr.push(obj)
          }
          this.setState({eventData:update_arr,update:false})
          this.props.followDataUpdate(update_arr)


     
        }).catch(error=>{});
      
     


    }
  };
  handleCheckboxClick = event => {
    this.setState({ eventsChecked: true ,holdPopupState:false});
    this.setState({ buttonSaveDisabled: false });
    this.setState({ radioSelectedValue: event.target.value });

    // get all checkboxes that has className checkBox
    var options = document.getElementsByClassName("option");

    // check if any of it is checked
    for (var i = 0; i < options.length; i++) {
      if (options[i].getElementsByTagName("input")[0].checked) {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text blue");
      } else {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text grey");
      }
    }
  };
  handleChangeEndDate=(values,setFieldValue)=>{
    this.setState({holdPopupState:false});
    setFieldValue('endDateRange',values.startDateRange?moment(values.startDateRange).format("MM/DD/YYYY"):'01/01/2020')
  }
  holdPopup=()=>{
    this.setState({holdPopupState:true})

  }

  handleRadioClick = event => {
    this.setState({holdPopupState:false})
    this.setState({ buttonDisabled: false });
    this.setState({ radioSelectedValue: event.target.value });

    // get all checkboxes that has className checkBox
    var options = document.getElementsByClassName("option");

    // check if any of it is checked
    for (var i = 0; i < options.length; i++) {
      if (options[i].getElementsByTagName("input")[0].checked) {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text blue");
      } else {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text grey");
      }
    }
  };

  onSelectStart=(values,setFieldValue,eTime)=>{
    if(eTime!==''){
    let starttime=values.target.value;
    let time=starttime.split(':');

    if(parseInt(time[1])+30 === 60){

      starttime=parseInt(time[0])+
      1 +
      ":"+
      "00";

      let a=(parseInt(time[0])+1).toString();
    if(a.length===1)
    {
      starttime="0"+starttime;

    }

    }
    else{
      let time_for=parseInt(time[1])+30;
      if(time_for[1]===0){
        time_for=time_for.replace(0,'');
      }
      else{
        time_for=parseInt(time[1])+30;
      }

      starttime=time[0]+":"+time_for;

    }
    setFieldValue('endTime',starttime);
  }
  }
  onSelectedDate=(setFieldValue)=>{
    setFieldValue('endDateRange','')
  }
  render() {
    //const { classes } = this.props;
   
    var currentDate  = moment().toDate();
    let initialValues={
      eventName:this.state.name?this.state.name:"",
      city: this.state.city && this.state.city!=`--` ? this.state.city : this.props.cityVal ? this.props.cityVal : this.state.cityVal,
      event_type: this.state.event_type?this.state.event_type:"",
      budget: this.state.budget?this.state.budget:"",
      /*place_hold_seating: this.state.seating?
      this.state.seating.includes('-')?
      this.state.seating:
      "0-" + this.state.seating:"",*/ 
      place_hold_seating:this.state.seating,
      startDateRange: this.state.start_date?moment(this.state.start_date).format("MM/DD/YYYY"):"",
      endDateRange: this.state.end_date?moment(this.state.end_date).format("MM/DD/YYYY"):"",
      startTime: this.state.start_time?this.state.start_time:"",
      endTime: this.state.end_time?this.state.end_time:"",
      setUp: this.state.setup?this.state.setup:"",
      takeDown: this.state.teardown?this.state.teardown:"",
      seating: this.state.seating_type?this.state.seating_type:"",
      hold_rank:this.state.hold_rank?this.state.hold_rank:"",
      hold_value:this.state.hold_rank?this.state.hold_rank:"",
      isHold:this.props.dataCity.hold_type==1?true:false,
      check_select: ""
    };

    console.log('initialValues',initialValues);


    return (
      <div className="place-hold">
        <Dialog
          className="success_modal_container place-hold-popup"
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >

 
    <div>
        <ModalBoxEvent check={this.state.holdPopupState}/>
        <div>
           <DialogActions className="dialogActions">
            <span
              className="material-icons close closeButton"
              onClick={this.handleClose}
            >
              close
            </span>
          </DialogActions>

          <DialogContent className="dialog-content">
            <Grid container className="modalBox_content">
                <Grid item xs={12} className="main_place_hold_section">
                  <Formik
                  initialValues={initialValues}
                  validationSchema={EventSchema}
                  onSubmit={this.handleSubmit}
                >             

                  {({ isSubmitting, values, handleBlur, handleChange,setFieldValue }) => (
                    <Form>            
                        <div className="form">
                         <div className="header">
                           {!this.props.details?<div className="header-text">Edit Event</div>:<div className="header-text">Event Details</div>}
                           {!this.props.details?
                           <div className="header-button-div-form button-left">
                             <Button
                               variant="outline-danger"
                               className="white-button-small"
                               onClick={this.handleClose}
                             >
                               Cancel
                             </Button>
                             <Button
                               variant="outline-danger"
                               className="red-button-small"
                               type="submit"
                             >
                               Save
                             </Button>
                        
                           </div>
                                :
                                null
                                }
                         </div>
                         <div className="form-content add-new-form-block">
                           <div className="field-div big">
                             <div className="input-label">Event Name</div>
                             <div className="input-field-section">
                    
                               <Field
                                 className="input-field dark-black"
                                 type="text"
                                 name="eventName"
                                 disabled={this.props.details}
                               />
                               <div className="error">
                                 <ErrorMessage name="eventName" />
                               </div>
                             </div>
                           </div>
  
                           <div className="field-div small city-section">
                             <div className="input-label sm-pd-venue city-sm">City</div>
                             <div className="input-field-section select-down-icon">
                               <Autocomplete
                                      disabled={this.props.details}
                                    fields={{ groupBy: 'value', value: 'menu' }}
                                    value={values.city}
                                    inputProps={{ id: 'city', placeholder: 'City' }}
                                    wrapperStyle={{ position: 'relative' }}
                                    items={this.state.cityList}
                                    getItemValue={item => item.key}
                                    shouldItemRender={(item, value) => item.key.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                    onChange={(e) => { setFieldValue('city', e.target.value) }}
                                    onSelect={(val, item) => { setFieldValue('city', val) }}
                                    renderMenu={children => (
                                      <div className="suggestionDiv">
                                        {children}
                                      </div>
                                    )}
                                    renderItem={(item, isHighlighted) => (
                                      <div
                                        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                        key={item.id} >
                                        {item.key}
                                      </div>
                                    )}
                                />
                               <div
                                 className="error"
                               >
                                 <ErrorMessage name="city" />
                               </div>
                             </div>
                           </div>
                          
                          <div className="field-div small float-right">
                                <div className="input-label sm-pd-venue"># of People</div>
                                <div className="input-field-section">
                                  <Field
                                    className="input-field dark-black"
                                    type="text"
                                    name="place_hold_seating"
                                    placeholder="Ex-100-300"
                                    disabled={this.props.details==true?this.props.details:this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}
                                  />  
                                  <div className="error">
                                    <ErrorMessage name="place_hold_seating" />
                                  </div>
                                </div>
                          </div>  
  
                           <div className="field-div small">
                             <div className="input-label sm-pd-venue">Event Type</div>
                             <div className="input-field-section select-down-icon">
                               <CustomSelect
                                 id="event_type"
                                 name="event_type"
                                 color="#62717a"
                                 width="100%"
                                 data={this.props.eventTypeData.data}
                                 placeholder="Select"
                                 disable={this.props.details==true?this.props.details:this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}
                               />
                               <div className="error">
                                 <ErrorMessage name="event_type" />
                               </div>
                             </div>
                           </div>
  
                           <div className="field-div small float-right">
                             <div className="input-label sm-pd-venue">Venue Rental</div>
                             <div className="input-field-section">                             
                                   
                                        <Field
                                        className="input-field dark-black"
                                        type="text"
                                        name="budget"
                                        placeholder="Ex-100"
                                        disabled={this.props.details==true?this.props.details:this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}
                                      />
                                     
                                      {/* <div className="input-field-section select-down-icon">
                                      <SelectBudget disabled={this.props.details} label={true}/>
                                      </div>                                      */}
                                                                       
                                    <div className="error">
                                    <ErrorMessage name="budget" />                                  
                                </div>
                             </div>
                           </div>
                           
                           { this.props.dataCity.hold_type==1 &&
                            <div className="field-div small">
                              <div className="input-label sm-pd-venue">Current Hold Position</div>
                              <div className="input-field-section select-down-icon">
                                <CustomSelect
                                  id="hold_value"
                                  name="hold_value"
                                  color="#62717a"
                                  width="100%"
                                  data={this.state.hold_positions}
                                  placeholder="Select"
                                  disable={true}
                                />
                                <div className="error">
                                  <ErrorMessage name="hold_value" />
                                </div>
                              </div>
                            </div>
                          }
                          { this.props.dataCity.hold_type==1 &&
                           <div className="field-div small float-right">
                             <div className="input-label sm-pd-venue">Update Hold Position</div>
                             <div className="input-field-section select-down-icon">
                                      <CustomSelect
                                        id="hold_rank"
                                        name="hold_rank"
                                        color="#62717a"
                                        width="100%"
                                        data={this.state.hold_positions}
                                        placeholder="Select"
                                        disable={this.props.details==true?this.props.details:this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}
                                      />                              
                                    <div className="error">
                                    <ErrorMessage name="hold_rank" />                                  
                                </div>
                             </div>
                           </div>
                          }
                           
                           
                           <div className="field-div small">
                             <div className="field-div smaller">
                               <div className="input-label end-date-sm">
                                 Start Date Range
                               </div>
                               <div className="input-field-section">
                   
                               <Datepicker
                                onSelect={()=>this.onSelectedDate(setFieldValue)}
                                onKeyDown={e => e.preventDefault()}
                                minDate={currentDate}
                                className="datepiker"
                                name="startDateRange"
                                dateFormat="MM/dd/yyyy"
                                placeholder="Start Date"
                                 disabled={this.props.details==true?this.props.details:this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}

                              />
                   
                               </div>
                             </div>
  
                             <div className="field-div spacer disp-none-sm">to</div>
                             <div className="field-div smaller float-right">
                               <div className="input-label end-date-sm">
                               End Date Range
                               </div>
                               <div className="input-field-section">
                               
                               <Datepicker
                               onKeyDown={e => e.preventDefault()}
                                className="datepiker"
                                minDate={moment(
                                  values.startDateRange
                                ).toDate()}
                                name="endDateRange"
                                dateFormat="MM/dd/yyyy"
                                placeholder="End Date"
                                maxDate={moment(values.startDateRange)
                                  .add(15, "days")
                                  .toDate()}
                                  disabled={this.props.details==true?this.props.details:this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}
                              />
                                 
                               </div>
                                  <div className="input-label" style={{fontSize:"11px"}}>Optional</div>
                             </div>
                           </div>
  
                           <div className="field-div small float-right tall">
                             <div className="field-div smaller">
                               <div className="input-label sm-pd-start">Start Time</div>
                               <div className="input-field-section">
                                 <CustomTimeSelect
                                   placeholder="Start Time"
                                   styleClass="browseVenue"
                                   name="startTime"
                                   value={values.startTime}
                                   handleChange={e => {
                                    handleChange(e);
                                    this.onSelectStart(e,setFieldValue)
                                  }}
                                   handleBlur={handleBlur}
                                   disable={this.props.details==true?this.props.details:this.state.hold_value !=='0 Holds' && this.state.hold_value!==''?true:false}

                                 />
                               </div>
                             </div>
  
                             <div className="field-div spacer disp-none-sm">to</div>
  
                             <div className="field-div smaller float-right">
                               <div className="input-label sm-pd-end">End Time</div>
                               <div className="input-field-section">
                                 <CustomTimeSelect
                                   placeholder="End Time"
                                   styleClass="browseVenue"
                                   name="endTime"
                                   value={values.endTime}
                                   handleChange={handleChange}
                                   handleBlur={handleBlur}
                                   disable={this.props.details==true?this.props.details:this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}

                                 />
                                 <div className="error">
                                   {this.state.timeError}
                                 </div>
                               </div>
                             </div>
  
                             <div className="field-div smaller">
                               <div className="input-label sm-pd-req-up">Requested Set-up Time</div>
                               <div className="input-field-section select-down-icon">
                                 <CustomSelect
                                   className="select-field lighter-black"
                                   id="setUp"
                                   setUpArray={setUpArray}
                                   name="setUp"
                                   placeholder="Select"
                                   color="#62717a"
                                   width="100%"
                                   disable={this.props.details==true?this.props.details:this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}

                                 />
  
                                 <div className="error">
                                   <ErrorMessage name="setUp" />
                                 </div>
                               </div>
                             </div>
  
                             <div className="field-div smaller float-right">
                               <div className="input-label sm-pd-req-down">Requested Tear Down Time</div>
                               <div className="input-field-section select-down-icon">
                                 <CustomSelect
                                   className="select-field lighter-black"
                                   id="takeDown"
                                   setUpArray={setUpArray}
                                   name="takeDown"
                                   placeholder="Select"
                                   color="#62717a"
                                   width="100%"
                                   disable={this.props.details==true?this.props.details:this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}

                                 />
                       
                                 <div className="error">
                                   <ErrorMessage name="takeDown" />
                                 </div>
                               </div>
                             </div>
                           </div>
                           
                           <div className="field-div small disp-none-small">
                             <div className="input-label sm-pd-seating">Seating Type</div>
                             <div className="input-field-section">
                               <CustomSelect
                                 id="seating"
                                 name="seating"
                                 color="#62717a"
                                 width="100%"
                                 placeholder="Select"
                                 seatingData={seatingType}
                                 disable={this.props.details==true?this.props.details:this.state.hold_value!=='0 Holds' && this.state.hold_value!==''?true:false}

                               />
                               <div className="error">
                                 <ErrorMessage name="seating" />
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                    </Form>
                         )}
                 </Formik>
           
                  
                 
                </Grid>
            </Grid>
          </DialogContent>

        </div>

    

    </div>
     </Dialog>
      </div>
    );
  }

}
EditEvent.propTypes = {
  classes: PropTypes.object.isRequired,
  eventList: func.isRequired,
  eventType: func.isRequired,
  seatingCategory: func.isRequired,
  createEvent: func.isRequired,
  saveEvent:func.isRequired,
  updateEvent:func.isRequired,
  updateEventData:func.isRequired,
  deleteEvent:func.isRequired,
  city:func.isRequired


};

const mapStateToProps = state => {
  return {
    eventData: state.data.eventList.eventDetails,
    eventTypeData: state.data.eventList.eventTypeDetails,
    seatingCategoryData: state.data.browseVenueData.arrangementData,
    holdEventData:state.data.saveEvent,
    cityInfo:state.data.browseVenueData.cityData,
    placeHoldErrorPopUp: state.data.saveEvent.renderErrorPopUp
  };
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        eventList,
        eventType,
        seatingCategory,
        createEvent,
        saveEvent,
        updateEvent,
        updateEventData,
        deleteEvent,
        city
      },
      dispatch
    )
  )
);
export default enhance(EditEvent);

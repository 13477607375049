import { Record } from 'immutable';

import {
  UNBLOCK_EVENT_REQUEST,
  UNBLOCK_EVENT_FAILURE,
  UNBLOCK_EVENT_SUCCESS,
} from 'shared/state/types';

class State extends Record({
  error:'',
  sending:'',
  sended:'',
  success:'',
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case UNBLOCK_EVENT_REQUEST:
      return state.merge({
        error:'',
        success:'',
        sending:true,
        sended:false
      });
    case UNBLOCK_EVENT_FAILURE:
      return state.merge({
        error: "Something went wrong",
        sending:false,
        success:'',
        sended:false

      });
    case UNBLOCK_EVENT_SUCCESS:
      return state.merge({
        success:'Event unblocked successfully.',
        error:'',
        sending:false,
        sended:true
      });
    default:
      return state;
  }
}


import { EDIT_DOCUMENT } from 'shared/state/types';
import { request } from 'shared/auth';

function onRequested() {
  return {
    type: EDIT_DOCUMENT.REQUEST,
  };
}

function onFailed(error) {
  return {
    type: EDIT_DOCUMENT.FAILURE,
    error:error,
  };
}

function onSuccess(data) {
  return {
    type: EDIT_DOCUMENT.SUCCESS,
    payload: data,
  };
}

export function editDocument(documentId,params) {
  return dispatch => {
    dispatch(onRequested());
    return request().put(`resources/${documentId}`,params)
      .then(response =>
        dispatch(onSuccess(response.data.data))
      )
      .catch(errors => {
        dispatch(onFailed(errors.response.data.message));
        throw errors;
      });
  };
}

import { VENUE_DOCUMENT_DELETE } from 'shared/state/types';
import { request } from 'shared/auth';

function venueDocumentDeleteRequested() {
  return {
    type: VENUE_DOCUMENT_DELETE.REQUEST,
  };
}

function venueDocumentDeleteFailed(error) {
  return {
    type: VENUE_DOCUMENT_DELETE.FAILURE,
    error:error,
  };
}

function venueDocumentDeleteSuccess(data) {
  return {
    type: VENUE_DOCUMENT_DELETE.SUCCESS,
    payload: data,
  };
}

export function venueDocumentDelete(venueId,documentId) {
  return dispatch => {
    dispatch(venueDocumentDeleteRequested());
    return request().delete(`venues/${venueId}/documents/${documentId}`)
      .then(response =>
        dispatch(venueDocumentDeleteSuccess(response.data.data))
      )
      .catch(errors => {
        dispatch(venueDocumentDeleteFailed(errors.response.data.errors));
        throw errors;
      });
  };
}

import axios from 'axios';
import { POST_GOOGLE_CALLBACK_REQUEST, POST_GOOGLE_CALLBACK_SUCCESS, POST_GOOGLE_CALLBACK_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function googleCallbackRequested() {
  return {
    type: POST_GOOGLE_CALLBACK_REQUEST
  };
}


function googleCallbackSuccess(data) {
  return {
    type: POST_GOOGLE_CALLBACK_SUCCESS,
    googleUrlDetails: data,
  };
}

function googleCallbackFailure(error) {
  return {
    type: POST_GOOGLE_CALLBACK_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {

  const headers = {};
  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');
    
  }
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function googleCallback(params) {
    return dispatch => {
      let url='sso/google/callback?code=';
      if(localStorage.getItem('fhtoken'))
      {
        url='user/sso/google/callback?code=';
      }

      dispatch(googleCallbackRequested());
        return request().get(url+params)
        .then(response =>
          dispatch (googleCallbackSuccess(response.data),
                )        )
        .catch(errors => {          
          dispatch(googleCallbackFailure(errors.message));
          throw errors;
        });
      


    
 };
}

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import PropTypes from 'prop-types';
import {Icon, Link, Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Formik, Field, ErrorMessage, Form} from 'formik';
import * as Yup from 'yup';
import '../assets/styles/editPdf.scss';
import { editDocument } from 'data/venues/actions';
const LoginSchema = Yup
    .object()
    .shape({
        name: Yup
            .string()
            .required('*File Name is required')
            .matches(/^[aA-zZ0-9-_\s]+$/, "File Name may only contain letters, numbers, dashes and underscores.")
            .max(30, '*File Name should not be greater than 30 characters')
    });
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: '8px'
    },
    closeButton: {
        position: 'absolute',
        right: '4px',
        top: '4px',
        color: theme.palette.grey[500]
    },
    backButton: {
        position: 'absolute',
        left: '4px',
        top: '4px',
        color: theme.palette.grey[500]
    },
    paper: {
        maxWidth: "480px",
        minHeight: "348px"
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const {
        children,
        classes,
        onClose,
        ...other
    } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {onClose
                ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                )
                : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: '8px'
    }
}))(MuiDialogContent);


class EditPdfComponent extends React.Component {

    state = {
        open: false,
        initialValues: {name: (this.props.data? this.props.data.display_name:"")},
        data:this.props.data
    };
    handleClose = () => {
        this.setState({open: false});
    };

    onNameUpdate = (values, { setSubmitting }) =>{
        let arr = this.state.data;
        arr.name = values.name;
        this.props.editDocument(arr.id,{name:values.name}).then((res) => {
            this.props.handleDocumentUpdated(this.props.index,res.payload);
            this.setState({open: false,initialValues:{name:res.payload.display_name}});
        });
        
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Link onClick={() => this.setState({open: true})}>
                    <Icon>create</Icon>
                </Link>
                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    classes={{
                    paper: classes.paper
                }}
                    open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}></DialogTitle>
                    <DialogContent dividers>
                        <Grid item className="edit-pdf-dialog-container">
                            <Grid container md={12} lg={12} xl={12} className="login-container">
                            <Formik
                                initialValues={this.state.initialValues}
                                validationSchema={LoginSchema}
                                onSubmit={this.onNameUpdate}>
                                {({isSubmitting, values, setFieldValue, handleChange}) => (
                                    <Form >
                                        <Typography gutterBottom className="heading-1">
                                            Edit File Name
                                        </Typography>

                                        <Grid container md={12} xs={12} sm={12} lg={12} xl={12}>
                                            <Grid md={12} xs={12} sm={12} lg={12} xl={12}>
                                                <InputLabel>File Name *</InputLabel>
                                            </Grid>
                                            <Grid md={12} xs={12} sm={12} xl={12} lg={12} className="input-box">
                                                <FormControl fullWidth >
                                                    <Field
                                                        className="inputField"
                                                        align="center"
                                                        type="text"
                                                        name="name"
                                                        placeholder="Enter File Name"/>
                                                    <div className="error"><ErrorMessage name="name"/></div>
                                                </FormControl>
                                            </Grid>
                                            <Grid md={12} xs={12} sm={12} lg={12} xl={12} className="button-box">
                                                <FormControl fullWidth>
                                                    <Button variant="contained" type="submit" className="sign-btn" color="primary">
                                                        Update
                                                    </Button>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

EditPdfComponent.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {};
};

const enhance = compose(withStyles(styles), connect(mapStateToProps, dispatch => bindActionCreators({editDocument}, dispatch)));
export default enhance(EditPdfComponent);


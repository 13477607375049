import React from "react";

import CustomCheckBox from "../../shared/components/CustomCheckBox";
import { Grid } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Field, FieldArray,ErrorMessage } from "formik";

import "../assets/styles/venueProfileEditComponent.scss";

export default function ExpansionSection(props) {


  const featureArray = props.featureData;
  const [expanded1, setExpanded1] = React.useState("panel1");
  const [expanded2, setExpanded2] = React.useState("panel2");
  const [expanded3, setExpanded3] = React.useState("panel3");
  const [expanded4, setExpanded4] = React.useState("panel4");
  const [expanded5, setExpanded5] = React.useState("panel5");

  const handleChange1 = panel => (event, newExpanded) => {
    setExpanded1(newExpanded ? panel : false);
  };

  const handleChange2 = panel => (event, newExpanded) => {
    setExpanded2(newExpanded ? panel : false);
  };

  const handleChange3 = panel => (event, newExpanded) => {
    setExpanded3(newExpanded ? panel : false);
  };

  const handleChange4 = panel => (event, newExpanded) => {
    setExpanded4(newExpanded ? panel : false);
  };

  const handleChange5 = panel => (event, newExpanded) => {
    setExpanded5(newExpanded ? panel : false);
  };

  return (
    <Grid container className="exp" style={{ width: "100%" }}>
    {props.features===true?
    <p className='exp_warning' style={{color: 'red',fontSize: '12px'}}>Please select one feature from below</p>:
    ''}
      {featureArray.data && featureArray.data.map(function(data, idx) {
          return data.id === 1 ? (
            <ExpansionPanel className="expansionPanel" expanded={expanded1 === "panel1"} onChange={handleChange1("panel1")} >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className="expansionPanelSummary">
                <h3 className="black-heading">{data.name}</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="rectangle">
                <FieldArray
                  name="complimentary"
                  render={arrayHelpers => (
                    <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
                      <div className="amenities">
                        <div className="grid-section">
                          {featureArray.data[idx].features &&
                            featureArray.data[idx].features.map(function(subData){
                              return (
                            <div>
                            
                                {subData.name==="Other" ?       
                                <div className="check-box-div">
                                
                                <CustomCheckBox
                                  name={subData.id}
                                  label="Other"
                                  arrayHelpers={arrayHelpers}
                                  checkboxGroup={props.values.complimentary}
                                  handleChange={(e)=>{props.handleChange(e); props.handleComplimentaryCustomClick();}}
                                />
                                 { (props.showComplimentaryCustom || (props.showComplimentaryCustom === true && props.values.complimentaryCustom !== '')) ?  (   
                                  <div className="custom">
                                    <label className="other-info-label">
                                      Custom
                                    </label>
                                    
                                    <Field
                                      name="complimentaryCustom" 
                                      label="Custom"
                                      className="input-field big"
                                      placeholder="Enter Amenity"
                                      type="text"
                                    values={props.values.complimentaryCustom}
                                    />

                                 <div className="error">{props.comCustError}</div>


                                  </div>
                                  ) : ("")}
                      
                              </div> : 
                               <div className="check-box-div">
                               <CustomCheckBox
                                 checkboxGroup={props.values.complimentary}
                                 name={subData.id}
                                 arrayHelpers={arrayHelpers}
                                 handleChange={props.handleChange}
                                 label={subData.name}
                               />
                             </div>
                            }
                             </div>
                              );
                            })
                            }
                        
                                      </div>
                      </div>
                    </Grid>
                  )}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : data.id === 2 ? (
            <ExpansionPanel className="expansionPanel" expanded={expanded2 === "panel2"} onChange={handleChange2("panel2")}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className="expansionPanelSummary">
                <h3 className="black-heading">{data.name}</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="rectangle">
                <FieldArray
                  name="additional"
                  render={arrayHelpers => (
                    <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
                      <div className="amenities">
                        <div className="grid-section">
                          {featureArray.data[idx].features &&
                            featureArray.data[idx].features.map(function(
                              subData
                            ) {
                              return (
                                <div>
                              {subData.name==="Other" ?       
                                <div className="check-box-div">
                                
                                <CustomCheckBox
                                  name={subData.id}
                                  label="Other"
                                  arrayHelpers={arrayHelpers}
                                  // handleChange={props.handleChange}
                                  checkboxGroup={props.values.additional}
                                  handleChange={(e)=>{props.handleAdditionalCustomClick();props.handleChange(e)}}
                                />
                                 {(props.showAdditionalCustom || (props.showAdditionalCustom === true && props.values.additionalCustom !== '')) ?  (   
                                  <div className="custom">
                                    <label className="other-info-label">
                                      Custom
                                    </label>
                                    
                                    <Field
                                      name="additionalCustom" 
                                      label="Custom"
                                      className="input-field big"
                                      placeholder="Enter Amenity"
                                      type="text"
                                    values={props.values.additionalCustom}
                                    />

                                  <div className="error">{props.addCustError}</div>

                                  </div>
                                  ) : ("")}
                      
                              </div> : 
                               <div className="check-box-div">
                               <CustomCheckBox
                                 checkboxGroup={props.values.additional}
                                 name={subData.id}
                                 arrayHelpers={arrayHelpers}
                                 handleChange={props.handleChange}
                                 label={subData.name}
                               />
                             </div>
                            }
                        </div>
                              );
                            })}

                        </div>
                      </div>
                    </Grid>
                  )}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : data.id === 3 ? (
            <ExpansionPanel className="expansionPanel" expanded={expanded3 === "panel3"} onChange={handleChange3("panel3")}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className="expansionPanelSummary">
                <h3 className="black-heading">{data.name}</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="rectangle">
                <FieldArray
                  name="complementaryParking"
                  render={arrayHelpers => (
                    <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
                      <div className="amenities">
                        <div className="grid-section">
                          {featureArray.data[idx].features &&
                            featureArray.data[idx].features.map(function(
                              subData
                            ) {
                              return (
                            <div>
                              {subData.name==="Other" ?       
                                <div className="check-box-div">
                                
                                <CustomCheckBox
                                  name={subData.id}
                                  label="Other"
                                  arrayHelpers={arrayHelpers}
                                  // handleChange={props.handleChange}
                                  checkboxGroup={props.values.complementaryParking}
                                  handleChange={(e)=>{props.handleParkingCustomClick();props.handleChange(e)}}
                                />
                                 {(props.showParkingCustom || (props.showParkingCustom === true && props.values.parkingCustom !== '')) ?  (   
                                  <div className="custom">
                                    <label className="other-info-label">
                                      Custom
                                    </label>
                                    
                                    <Field
                                      name="parkingCustom" 
                                      label="Custom"
                                      className="input-field big"
                                      placeholder="Enter Amenity"
                                      type="text"
                                    values={props.values.parkingCustom}
                                    />
       <div className="error"><ErrorMessage name="parkingCustom" /></div>

                                  </div>
                                  ) : ("")}
                      
                              </div> : 
                               <div className="check-box-div">
                               <CustomCheckBox
                                 checkboxGroup={props.values.complementaryParking}
                                 name={subData.id}
                                 arrayHelpers={arrayHelpers}
                                 handleChange={props.handleChange}
                                 label={subData.name}
                               />
                             </div>
                            }

                                </div>
                              );
                            })}

                                      </div>
                      </div>
                    </Grid>
                  )}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )  : data.id === 4 ? (
            <ExpansionPanel className="expansionPanel" expanded={expanded4 === "panel4"} onChange={handleChange4("panel4")}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className="expansionPanelSummary">
                <h3 className="black-heading">{data.name}</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="rectangle">
                <FieldArray
                  name="food"
                  render={arrayHelpers => (
                    <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
                      <div className="amenities">
                        <div className="grid-section">
                          {featureArray.data[idx].features &&
                            featureArray.data[idx].features.map(function(subData) {
                              return (
                                  <div>
                             {subData.name==="Other" ?       
                                <div className="check-box-div">
                                
                                <CustomCheckBox
                                  name={subData.id}
                                  label="Other"
                                  arrayHelpers={arrayHelpers}
                                  // handleChange={props.handleChange}
                                  checkboxGroup={props.values.food}
                                  handleChange={(e)=>{props.handleFoodCustomClick();props.handleChange(e)}}
                                />
                                 {(props.showFoodCustom || (props.showFoodCustom === true && props.values.foodCustom !== '')) ?  (   
                                  <div className="custom">
                                    <label className="other-info-label">
                                      Custom
                                    </label>
                                    
                                    <Field
                                      name="foodCustom" 
                                      label="Custom"
                                      className="input-field big"
                                      placeholder="Enter Amenity"
                                      type="text"
                                    values={props.values.foodCustom}
                                    />

<div className="error"><ErrorMessage name="foodCustom" /></div>

                                  </div>
                                  ) : ("")}
                      
                              </div> : 
                               <div className="check-box-div">
                               <CustomCheckBox
                                 checkboxGroup={props.values.food}
                                 name={subData.id}
                                 arrayHelpers={arrayHelpers}
                                 handleChange={props.handleChange}
                                 label={subData.name}
                               />
                             </div>
                            }
                                </div>
                              );
                            })}

                       </div>
                      </div>
                    </Grid>
                  )}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : data.id === 5 ? (
            <ExpansionPanel className="expansionPanel" expanded={expanded5 === "panel5"} onChange={handleChange5("panel5")}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className="expansionPanelSummary">
                <h3 className="black-heading">{data.name}</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="rectangle">
                <FieldArray
                  name="restriction"
                  render={arrayHelpers => (
                    <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
                      <div className="amenities">
                        <div className="grid-section">
                          {featureArray.data[idx].features &&
                            featureArray.data[idx].features.map(function(subData) {
                              return (
                                  <div>
                             {subData.name==="Other" ?       
                                <div className="check-box-div">
                                
                                <CustomCheckBox
                                  name={subData.id}
                                  label="Other"
                                  arrayHelpers={arrayHelpers}
                                  // handleChange={props.handleChange}
                                  checkboxGroup={props.values.restriction}
                                  handleChange={(e)=>{props.handleRestrictionCustomClick();props.handleChange(e)}}
                                />
                                 {(props.showRestrictionCustom || (props.showRestrictionCustom === true && props.values.restrictionCustom !== '')) ?  (   
                                  <div className="custom">
                                    <label className="other-info-label">
                                      Custom
                                    </label>
                                    
                                    <Field
                                      name="restrictionCustom" 
                                      label="Custom"
                                      className="input-field big"
                                      placeholder="Enter Amenity"
                                      type="text"
                                    values={props.values.restrictionCustom}
                                    />
                         <div className="error"><ErrorMessage name="restrictionCustom" /></div>

                                  </div>
                                  ) : ("")}
                      
                              </div> : 
                               <div className="check-box-div">
                               <CustomCheckBox
                                 checkboxGroup={props.values.restriction}
                                 name={subData.id}
                                 arrayHelpers={arrayHelpers}
                                 handleChange={props.handleChange}
                                 label={subData.name}
                               />
                             </div>
                            }

                                </div>
                              );
                            })}

                                            </div>
                      </div>
                    </Grid>
                  )}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null
        })}
    </Grid>
  );
}

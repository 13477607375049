import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { performSSOAuth } from 'shared/auth/actions';

class Callback extends Component {
  render() {
    const params = queryString.parse(this.props.location.search);

    this.props.performSSOAuth(params);

    return <div>Callback</div>;
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    performSSOAuth,
  }, dispatch),
)(Callback);

import axios from 'axios';
import { EDIT_VENUE_REQUEST, EDIT_VENUE_SUCCESS, EDIT_VENUE_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function editVenueRequested() {
  return {
    type: EDIT_VENUE_REQUEST
  };
}

function editVenueSuccess(data) {
  return {
    type: EDIT_VENUE_SUCCESS,
    editVenueDetails: data,
  };
}

function editVenueFailure(error) {
  return {
    type: EDIT_VENUE_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }

  
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export function saveExit(params,json) {

    return dispatch => {
      dispatch(editVenueRequested());
      return request().put(`venues/${params}`,json)
      .then(response =>
        dispatch(editVenueSuccess(response.data),)
              )
      .catch(errors => {
        dispatch(editVenueFailure(errors.response.data.message));
        throw errors;
      });
      


    
 };
}

import axios from 'axios';
import { GET_DELETE_FOLLOW_DATE_REQUEST, GET_DELETE_FOLLOW_DATE_SUCCESS, GET_DELETE_FOLLOW_DATE_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function deletefollowDateRequested() {
  return {
    type: GET_DELETE_FOLLOW_DATE_REQUEST
  };
}

function deletefollowDateSuccess(data) {
  return {
    type: GET_DELETE_FOLLOW_DATE_SUCCESS,
    followList: data,
  };
}

function deletefollowDateFailure(error) {
  return {
    type: GET_DELETE_FOLLOW_DATE_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }
  
const axiosInstance = axios.create({
  baseURL,
  headers,
})
loadProgressBar({ showSpinner: true }, axiosInstance)
return axiosInstance
};

export function unFollowAPI(id) {
    return dispatch => {
      dispatch(deletefollowDateRequested());
        return request().delete(`event_follow_dates/`+id)
        .then(response =>
          dispatch (deletefollowDateSuccess(response.data),
                ))
        .catch(errors => {
          dispatch(deletefollowDateFailure(errors.response));
  
          throw errors;
        });
    
   

    
 };
}

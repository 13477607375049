import React from 'react';
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
// import CustomisedRadio from "./CustomisedRadio.js";
import Grid from '@material-ui/core/Grid';
// import "./assets/style/placeHold.scss";
import "./assets/style/viewPopup.scss";
// import CustomizedButton from '../components/customizedButton';
import { object } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { deleteSpaceAvailability, getSpaceAvailability, getCalendar } from 'data/spaces/actions';

const styles = theme => ({
root: {
margin: 0,
},
closeButton: {
position: 'absolute',
top: '8px',
color: '#9e9e9e',
right: '8px',
},
});
withStyles(styles)(props => {
const { children, classes, onClose } = props;
return (
<MuiDialogTitle disableTypography className={classes.root}>
<Typography variant="h6">{children}</Typography>
{onClose ? (
<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
<CloseIcon />
</IconButton>
) : null}
</MuiDialogTitle>
);
});

const DialogContent = withStyles(theme => ({
root: {
},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
root: {
},
}))(MuiDialogActions);

class ViewPopup extends React.Component {
state = {
open: false,
};

handleClickOpen = () => {
this.setState({
open: true,
});
};

handleClose = () => {
  this.setState({open:false})
};

deleteSpaceAvailability=(id)=>{
    console.log(this.props.history,"path")
    this.props.history.push(this.props.history.location.pathname);

    this.props.deleteSpaceAvailability(id).then((response)=>{
       this.props.getSpaceAvailability(this.props.spaceId, this.props.month, this.props.year)
       this.props.getCalendar(this.props.spaceId, this.props.month, this.props.year)
       if(this.props.deleteBlockoutLocal)
       {
        this.props.deleteBlockoutLocal(id)
       }
    })
}

render() {
const spaceAvailability =  this.props.spaceAvailabilityData.length > 0 ?  this.props.spaceAvailabilityData.map((data) => {
    return (
        <Grid item sm={12} xs={12} className="wid-100">
        <div className="container-box">
            {/* <div className="checkBoxDiv">            
                <CustomisedRadio event={true}
                  name="check_select"
                  class="radioButton"
                  // value={data.id}
                  // onChange={this.handleCheckboxClick}
                  // radioSelectedValue={
                  //   this.state.radioSelectedValue
                  // }
                />
            </div> */}
            <div className="detailsbox">
                <div className="large-text">
                      <p>{data.status === 0 ? 'Block Out' :'Make Available'}</p>
                      <p className="annual">{data.status === 0 && data.recurring_duration === 1 ? '(Annual)' : null}</p>
                      <br></br>
                      <p className="blue-text">{moment(data.start_date).format("MM/DD/YYYY")} - {moment(data.end_date).format("MM/DD/YYYY")}</p>
                      {/* <p className="blue-text end-date">End Date-{data.end_date}</p> */}
                </div>

                <div className="actionDiv">
                    <span className="material-icons delete" onClick={(e)=>{this.deleteSpaceAvailability(data.id)}}>delete</span>
                </div>
            </div>
  </div>
  </Grid>
    )
  })
  : null
return (
<div>
<Button class="white-button float-right" variant="outlined" color="secondary" onClick={this.handleClickOpen}>
  Restrictions
</Button> 
<Dialog 
className="success_modal_container view-listing-popup restriction-popup"
aria-labelledby="customized-dialog-title"
open={this.state.open} >
<DialogActions className="dialogActions">
<span
className="material-icons close closeButton"
onClick={this.handleClose}
>
close
</span>
</DialogActions>
<DialogContent className="dialog-content">
<Grid container className="modalBox_content">
<Grid item xs={12}>
<Grid container className="">
<Grid item xs={12} sm={12}>
<div className="header">Restrictions on when planners can place holds</div>
</Grid>
<Grid item xs={12} sm={12}>
    <div className="view-listing-popup-box">
        {this.props.spaceAvailabilityData && this.props.spaceAvailabilityData.length > 0 ?
            spaceAvailability
        : <div className="not_available">No restrictions found.</div>
        }
    </div>
</Grid>
</Grid>
</Grid>

</Grid>

</DialogContent>


</Dialog>

</div>
);
}
}

ViewPopup.propTypes = {
classes: object.isRequired,
};

const mapStateToProps = state => {
return {

};
};

const enhance = compose(
withRouter,
connect(mapStateToProps,dispatch =>
bindActionCreators(
{
    getSpaceAvailability,
    deleteSpaceAvailability,
    getCalendar
},
dispatch
)
)
);
export default enhance(ViewPopup);


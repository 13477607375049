import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { Grid } from '@material-ui/core';
import VenueFooter from '../../../shared/footer/venueFooter';
import Faq from '../components/helpAndFaq';
import { faqData } from "data/faq/actions";
 import '../assets/styles/helpAndFaq.scss';

class HelpAndFaq extends Component {
  state={
    apicall: false,
    faqs : []
  }
  componentWillMount() {
    this.props.faqData().then((response) => {
      this.setState({apicall:true})
      this.setState({faqs:response.data.data})
      })
 
  }
  render() {
    return (
      <div className="venue_page">
        <Grid xs={12} item>
         </Grid>
        <Grid xs={12} item className="midContainer">
          <Grid container justify="center">
            <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
            <Faq faqData={this.state.faqs}/>
            </Grid>
          </Grid>
        </Grid>
       
        <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
          <Grid container justify="center">
            <VenueFooter />
          </Grid>
        </Grid>
      </div>

    );
  }
}

const mapStateToProps = state => {

  return {
    faqs:state.data
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        faqData
      },
      dispatch
    )
  )
);
export default enhance(HelpAndFaq);


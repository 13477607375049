import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators,compose } from 'redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import {Grid} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import './assets/style/subscription.scss';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import {Subscribe} from '../../data/subscribe/actions';
import { getKeys} from '../../data/master/actions'
import ReCAPTCHA from "react-google-recaptcha";
const Schema = Yup.object().shape({
    email:  Yup.string()
              .required('*Required.')
              .email(),
    fname:   Yup.string()
              .required('*Required.'),    
    lname:   Yup.string()
              .required('*Required.'),              
    type:   Yup.string()
            .required('*Required.'),
    captcha:   Yup.string()
              .required('*Required.'),   
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: '8px'
    },
    closeButton: {
        position: 'absolute',
        right: '4px',
        top: '4px',
        color: theme.palette.grey[500]
    },
    paper: {
        maxWidth: "480px"
    },
    fullWidth: {
        width: '100%'
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const {
        children,
        classes,
        onClose,
        ...other
    } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {onClose
                ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                )
                : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: '8px'
    }
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: '4px'
       
    }
}))(MuiDialogActions);
class Subscription extends React.Component {
    state = {
        open: this.props.open,
        initialValues:{ email: '',type:'',fname:'',lname:'',captcha:''},
        captachKey:null
    };

    UNSAFE_componentWillMount() {
        this.props.getKeys('captcha_key').then((response) => {
          this.setState({captachKey:response});
        });
    }
    

    handleClose = () => {
        this.setState({open: false});
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    onContact = () =>{
        this.props.onContact();
    }

    onSubmit = (values, { setSubmitting }) =>{
        this.props.Subscribe(values)
        .then((response) => {
            this.setState({open: false});
        }).catch(error => {       
            setSubmitting(false);
        });
    }

    handleChange = (setFieldValue,value) => {
        setFieldValue('captcha', value);
    };

    render() {
        const {classes} = this.props;
        return (
            <div>  
                <Link href="#"  onClick={this.handleClickOpen}><p>Send me your emails</p></Link>            
                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    classes={{
                    paper: classes.paper
                }}
                    open={this.state.open}>
                    <DialogTitle
                        id="customized-dialog-title"
                        onBack={this.onBack}
                        onStep={this.state.step}
                        onClose={this.handleClose}></DialogTitle>
                    <DialogContent dividers>
                        <Grid item className="contact-dialog-container">
                            <Grid container md={12} lg={12} xl={12}>
                                <Typography gutterBottom className="heading-1">
                                    Send me your emails
                                </Typography>
                                <Formik
                                        initialValues={this.state.initialValues}
                                        validationSchema={Schema}
                                        onSubmit={this.onSubmit}
                                        >
                                    {({ isSubmitting, values, setFieldValue, handleChange }) => (
                                    <Form>
                                        <Grid container md={12} lg={12} xl={12}>

                                            <Grid md={12} sm={24} style={{width:"100%"}} >
                                                <FormControl fullWidth margin="dense">  
                                                    <p className="label">I am a</p>                                                                                       
                                                    <Field component="select" className="select_field" name="type">
                                                        <option value="" >Please Select</option>
                                                        <option value="1" >Event Planner</option>
                                                        <option value="2">Venue Manager</option>
                                                    </Field>
                                                    <div className="error"><ErrorMessage name="type" /></div>
                                                </FormControl>
                                            </Grid>

                                            <Grid md={6} sm={12} className="left-container" >
                                                <FormControl fullWidth margin="dense">   
                                                    <p className="label">First Name</p>                                                                             
                                                    <Field className="inputField" placeholder="First Name" name="fname"   />
                                                    <div className="error"><ErrorMessage name="fname" /></div>
                                                </FormControl>
                                            </Grid>
                                           
                                            <Grid md={6} sm={12}   >
                                                <FormControl fullWidth margin="dense">   
                                                    <p className="label">Last Name</p>                                                                             
                                                    <Field className="inputField" placeholder="Last Name" name="lname"   />
                                                    <div className="error"><ErrorMessage name="lname" /></div>
                                                </FormControl>
                                            </Grid>
                                            <Grid md={12} sm={24}  >
                                                <FormControl fullWidth margin="dense">   
                                                    <p className="label">E-mail</p>                                                                             
                                                    <Field className="inputField" placeholder="E-mail" name="email"   />
                                                    <div className="error"><ErrorMessage name="email" /></div>
                                                </FormControl>
                                            </Grid>

                                            { this.state.captachKey && 
                                            <Grid item className="bottomSpacing"  sm={24} md={12}>
                                                <ReCAPTCHA
                                                    sitekey={this.state.captachKey}                                                  
                                                    onChange={e=>{this.handleChange(setFieldValue,values)}}
                                                />
                                                <div className="error"><ErrorMessage name="captcha" /></div>
                                            </Grid>}
                              
                                            <Grid md={12} sm={24}  className="button-box">
                                                <Button variant="contained" type="submit" className="contact-btn" color="primary" >
                                                    Subscribe
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

Subscription.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
     
    };
};


const enhance = compose(withStyles(styles), withRouter, connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        Subscribe,
        getKeys
      },
      dispatch
    )
));
export default enhance(Subscription);

import { createMuiTheme } from '@material-ui/core/styles';
export default createMuiTheme({
  transitions: {
    create: function create() {
      return 'none';
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiInputLabel: {
      shrink: true,
      disableAnimation: true
    },
    MuiInput: {
      disableUnderline: true,
      fullWidth: true
    },
    MuiList: {
      disablePadding: true
    }
  },
  overrides: {
    MuiButton: {
      root: {
        color: '#fff',
        fontSize: '13px',
        textTransform: 'none',
        borderRadius: '6px',
        '&$textPrimary': {
          color: '#fff',
          backgroundColor: '#f1435e',
          "&:hover": {
            backgroundColor: "#f1435e"
          }
        },
        '&$textSecondary': {
          color: '#ee2738',
          backgroundColor: '#fff',
          border: 'solid 1px #e1e1e1',
          boxShadow: 'none',
          "&:hover": {
            backgroundColor: "#fff"
          }
        },
        '&$disabled': {
          boxShadow: 'none',
          color: '#fff !important',
          backgroundColor: '#9aa7af'
        }
      },
      text: {
        color: '#fff',
        backgroundColor: '#f1435e',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
        "&:hover": {
          backgroundColor: "#f1435e"
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: '#62717a !important',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: 1.45,
        letterSpacing: 'normal',
        fontFamily: 'Raleway'
      }
    },
    MuiInputBase: {
      input: {
        padding: '0px 20px',
        minHeight: '36px'
      }
    },
    MuiInput: {
      root: {
        fontSize: '14px',
        color: '#202020',
        backgroundColor: '#f7f7f7',
        border: 'solid 1px #f0f0f0',
        borderRadius: '6px',
        '&::placeholder': {
          color: '##b0b0b0'
        },
        '&$focused': {
          background: '#fff'
        }
      },
      error: {
        borderColor: '#ff3860'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#1397e1 !important',
        padding: '8px'
      }
    },
    MuiSelect: {
      root: {
        fontSize: '12px',
        color: '#62717a',
        textTransform: 'uppercase',
        borderRadius: '6px',
        overflow: 'hidden'
      },
      select: {
        '&:focus': {
          backgroundColor: '#fff'
        },
        '&$disabled': {// backgroundColor: '#f5f5f5',
        }
      },
      selectMenu: {
        backgroundColor: '#f7f7f7'
      }
    },
    MuiMenuItem: {
      root: {
        height: '14px',
        fontSize: '12px',
        borderBottom: '1px solid #f5f5f5'
      }
    }
  }
});
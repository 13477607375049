import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators, compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Grid, Button} from '@material-ui/core';
import {Formik, Field, ErrorMessage, Form} from 'formik';
import * as Yup from 'yup';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import '../assets/styles/couponPopup.scss';
import { applyCouponApi } from 'data/plan/actions';
const LoginSchema = Yup
    .object()
    .shape({
        code: Yup
            .string()
            .required('*Coupon code is required')
    });
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: '8px'
    },
    closeButton: {
        position: 'absolute',
        right: '4px',
        top: '4px',
        color: theme.palette.grey[500]
    },

    dialogTitle: {
        fontSize: "18px",
        fontWeight: "600",
        textAlign: "center",
        padding: "8px",
        textTransform: "UPPERCASE"
    },
    paper: {
        maxWidth: "480px",
        minHeight: "348px"
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const {
        children,
        classes,
        onClose,
        ...other
    } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <h3 className={classes.dialogTitle}>Apply Coupon</h3>
            {onClose
                ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                )
                : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: '8px'
    }
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: '4px'
    }
}))(MuiDialogActions);
class CouponDialogs extends React.Component {
    state = {
        open: false,
        initialValues: {
            code: ''
        }
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    onLogin = (values, {setSubmitting}) => {
        this.props.applyCouponApi({code:values.code,type:3,tier:this.props.planTier}).then((res) => {
            this.props.applyCoupon(res);
            this.handleClose();
        });
        
    }

    removeCoupon = ()=>{       
        this.props.removeCoupon();
        this.handleClose();
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Grid md={12} xs={12} sm={12} xl={12} lg={12} className="coupon-card">
                    <Grid item md={8} lg={8} xl={8} className="lable">
                        <img src={require('./../assets/images/coupon_icon.svg')} alt="logo"/>
                        <p>Apply Coupon Code</p>
                    </Grid>
                    <Grid item md={4} lg={4} xl={4} className="apply-box">
                        <Button size="small" onClick={this.handleClickOpen}>
                            {(Object.keys(this.props.appliedCoupon).length > 0)? 'Applied':'Apply'}
                        </Button>
                    </Grid>
                </Grid>
                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    classes={{
                    paper: classes.paper
                }}
                    open={this.state.open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}></DialogTitle>
                    <DialogContent>
                        <Grid item className="c-dialog-container">
                            <Grid container md={12} lg={12} xl={12} className="login-container">
                                <Formik
                                    initialValues={this.state.initialValues}
                                    validationSchema={LoginSchema}
                                    onSubmit={this.onLogin}>
                                    {({isSubmitting, values, setFieldValue, handleChange}) => (
                                        <Form >
                                            <Grid container md={12} xs={12} sm={12} lg={12} xl={12}>
                                                <Grid md={8} xs={8} sm={8} lg={8} xl={8} className="input-box">
                                                    <FormControl fullWidth>
                                                        <Field
                                                            className="inputField"
                                                            align="center"
                                                            type="text"
                                                            name="code"
                                                            placeholder="Enter coupon code"/>
                                                    </FormControl>
                                                    <div className="error"><ErrorMessage name="code"/></div>
                                                </Grid>
                                                <Grid md={4} xs={4} sm={4} lg={4} xl={4} className="button-box">
                                                    <FormControl fullWidth>
                                                        <Button variant="contained" type="submit" className="sign-btn" color="primary">
                                                            Check
                                                        </Button>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                            { (Object.keys(this.props.appliedCoupon).length > 0) && 
                            <Grid container md={12} lg={12} xl={12} className="coupon-list-container">
                                <List >
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar className="avatar"  >
                                                <CheckCircleIcon className="redIcon"/>
                                        </ListItemAvatar>
                                        <div className="c-text">
                                            <h3>{this.props.appliedCoupon.promo_code}</h3>
                                            <p dangerouslySetInnerHTML={{__html: this.props.appliedCoupon.message}}></p>
                                        </div>
                                 
                                        <ListItemSecondaryAction>
                                            <IconButton aria-label="Delete" onClick={this.removeCoupon}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>
                            }
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

CouponDialogs.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {userData: state.data.usersVenue.userInfo};
};

const enhance = compose(withStyles(styles), withRouter, connect(mapStateToProps, dispatch => bindActionCreators({applyCouponApi}, dispatch)));
export default enhance(CouponDialogs);
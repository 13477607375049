import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import '../assets/styles/aboutUs.scss';
// import PlaceHold from 'shared/components/placeHold';
const nl2br = require('react-nl2br');

function VenueAgreement(props) {

    return (
        <Grid container className="venue-available">
            <Grid xs={12} item>
                        <Grid container>
                            <Grid item md={12} xs={12} sm={12} xl={12} lg={12} className="avilableDescriptionDiv">
                                <div className="avilableDescription">
                                <h1>{props.pageData.data && props.pageData.data.title}</h1>
                                        <hr className="divider"></hr>
                                        {/* <h3>Our Story</h3> */}
                                    <Typography color="textSecondary" className="matter aboutMatter">
                                    {nl2br(props.pageData.data && props.pageData.data.description)}
                            	</Typography>
                                </div>
                            </Grid>
                        </Grid>
            </Grid>
        </Grid>
    );
}


export default VenueAgreement;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';

import * as constClass from '../../constant';


const styles = theme => ({
  root: {
    width: '121px',
    height: '36px',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    marginTop: '13px',
    borderRadius: '6px',
    marginBottom: '22px',
    background: 'linear-gradient(to bottom, #f1435e, #ee2738)',
    fontFamily: 'Raleway',

    '&$disabled': {
      background: '#9aa7af',
      color: 'white',
      boxShadow: 'none',
    },
  },
  disabled: {
  },
});


function SelectSeating(props) {
  let selectOption;
  if (props.options && props.options.length>0){
    selectOption = props.options.map(function (data, idx) {
        return (
            <option key={idx} value={data.value}>{data.name}</option>
        );
    });
  } else {
    selectOption = constClass.selectSeatings.map((dt, i) => {
      return (
        <option value={dt.value}
          key={i} name={dt.value}>{dt.name}</option>

      );
    });
  }
  return (
    <Field component="select" name="seating" style={{ color: props.color }} className="searchSelect">
      <option>{constClass.SEATING}</option>
      {selectOption}
    </Field>
  );
}



export default withStyles(styles)(SelectSeating);
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import TopImageComponent from '../spaceProfile/components/topImageComponent';
import VenueFooter from '../shared/footer/venueFooter';
import VenueProfileEditComponent from './components/venueProfileEditComponent';
import VenueSpaceEdit from './components/venueSpaceEdit';
import VenueAddressEdit from './components/venueAddressEdit';
import VenueAttractionEdit from './components/venueAttractionEdit';
import ButtonsHeader from './components/buttonsHeader';
import { getVenue, venueImageUpload, deleteVenueImage, updatePhotographer } from 'data/venues/actions';
import { Formik, Form } from 'formik';
import { object, func } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import VenuePdfEdit from './components/venuePdfEdit';
import ModalBoxSetting from '../shared/components/ModalBoxSetting';

import { getUploadPostUrl, uploadtoS3 } from 'data/master/actions';
import { saveExit } from '../shared/saveExit/actions';
import getCroppedImg from '../cropImage';
import withWidth from '@material-ui/core/withWidth';

const logo = require("./assets/images/default_venue.png")
const FILE_SIZE = 1024 * 1024 * 3;

let com = [];
let add = [];
let park = [];
let food = [];
let rest = [];
let error = [];
class VenueProfileEdit extends Component {
    state = {

        topErrorNatural: false,
        topErrorBeautiful: false,
        topErrorCon: false,
        topErrorBuilding: false,
        topErrorBrick: false,
        features: false,
        loader: false,
        editvenueErrorMessage: '',
        editvenueError: false,
        errorCrop: '',
        redirect: false,
        cropImage: false,
        imageUrl: '',
        imageFileData: '',
        rotation: 0,
        cropping: false,
        cropDone: false,
        cropData: '',
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 7 / 3,
        croppedAreaPixels: null,
        croppedImage: null,
        getResponse: false,
        openImageUploadForm: false,
        imageUploaded: false,
        imageUploading: false,
        imageFile: [],
        imageFileId: [],
        imageFileName: [],
        venueImageError: '',
        cust_com: '',
        cust_add: '',
        cust_park: '',
        cust_food: '',
        cust_rest: '',
        showFoodCustom: false,
        showRestrictionCustom: false,
        showParkingCustom: false,
        showAdditionalCustom: false,
        showComplimentaryCustom: false,
        naturalLight: '',
        beautifulView: '',
        nearConvention: '',
        exposedBrick: '',
        iconicBuilding: '',
        images: [],
        spaceImage: '',
        pdfData: [],
        venueAddress: '',
        isFlexibleToggleOpen: false,
        anchorElFlexible: null,
        max_hold: '',
        hold_duration: '',
        videos: [],
        onUploadImage:false,
        onDeleteImage:false,
        thumbnailData:''
    }
    //for image cropper
    onCropChange = crop => {
        this.setState({ crop })

    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels: croppedAreaPixels })
        // this.showCroppedImage()
    }

    okButton = async () => {

        this.setState({
            image_upload: false, errorCrop: '', cropImage: false,
            imageUploading: false,
            imageUploaded: true,
        })

        try {
            let imageArray = this.state.images
            const imageArrayLen = imageArray.length
            imageArray.push({ imageId: imageArrayLen + 1, path: '', id: '', name: '', uploading: true, uploaded: false })

            this.setState({ cropping: true })
            const image = this.state.imageFileData ? this.state.imageFileData : this.state.imageUrl
            const croppedImage = await getCroppedImg(
                image,
                this.state.croppedAreaPixels,
                this.state.rotation
            )
            var c = document.getElementById("myCanvas");
            var ctx = c.getContext("2d");
            var ctx1 = c.getContext("2d");
            var d = document.getElementById("myCanvas");
            var img = document.getElementById("scream");
            var prop = this;
            img.src = croppedImage;
            prop.setState({
                cropImage: false
            })
            img.onload = () => {
                c.width = img.width;
                c.height = img.height;
                ctx.drawImage(img, 0, 0, img.width, img.height);
                c.toBlob(function (blob) {
                    var f = new File([blob], "sample.jpg", { type: "application/octet-stream" });
                    prop.setState({ cropData: f });
                    prop.setState({ cropDone: true, imageUrl: image,  cropping: false });
                    prop.props.getUploadPostUrl(1, 'sameimage.jpg').then((res) => {
                        prop.props.uploadtoS3(res.url, f).then(() => {
                            var formData = new FormData();
                            formData.append("photo", res.keyName)
                            formData.append("name", 'name')
                            prop.props.getUploadPostUrl(1, 'sameimage.jpg').then((res1) => {
                                formData.append("thumbnail", res1.keyName);
                                prop.props.uploadtoS3(res1.url, prop.state.thumbnailData).then(() => {
                            prop.props.venueImageUpload(formData, prop.props.match.params.venueId)
                                .then((response) => {
                                    prop.props.getVenue(prop.props.match.params.venueId,1)
                                    if (response.id) {
                                        let newArray = prop.state.imageFile
                                        let imageIdArray = prop.state.imageFileId
                                        newArray.push(`${process.env.REACT_APP_MEDIA_URL}${response.path}`)
                                        imageIdArray.push(response.id)
                                        prop.setState({
                                            cropImage: false,
                                            imageFile: newArray,
                                            imageUploading: false,
                                            imageFileId: imageIdArray,
                                            imageUploaded: true,
                                            venueImageError: '',
                                            //images: this.state.imageArray,
                                        });
                                    }
                                })
                            })
                        })
                                .catch(error => { })
                        })
                    })
                }, "image/jpeg", 0.75)
                d.width = 408;
                d.height = 200;
                //ctx1.drawImage(img, 0, 0, 335, 150, 0, 0, 335, 150);
                ctx1.drawImage(img, 0, 0, img.width, img.height, 0, 0, 408, 200);
                d.toBlob(function (blob) {
                  var g = new File([blob], "sample1.jpg", { type: "application/octet-stream" });
                  prop.setState({ thumbnailData: g });
                  //prop.setState({ cropDone: true, imageUrl: image, imageFile: croppedImage, cropping: false });
        
                }, "image/jpeg", 0.75)
                this.setState({onUploadImage:true,onDeleteImage:false})
            }
            this.setState({ croppedImage: croppedImage, cropping: false })
        } catch (e) {
        }
    }

    cancelButton = (setFieldValue) => {

        this.setState({
            cropping: false,
            cropImage: false,
            image_upload: false,
            errorCrop: '',
            cropDone: true,
            imageUrl: '',
            // imageFile:[],
            imageUploading: false,
            imageUploaded: true,
            imageFileName: [],
            venueImageError: '',
            imageFileData: ''
        });

    }

    onZoomChange = event => {
        this.setState({ zoom: event.currentTarget.value })
    }
    onRotationChange = event => {
        this.setState({ rotation: event.currentTarget.value })
    }

    handleEditPhotosClick = () => {
        error = [];
        if(this.state.openImageUploadForm===false)
        {
        this.props.getVenue(this.props.match.params.venueId,1).then((response) => {
        
        this.setState(prevState => ({
            openImageUploadForm: !prevState.openImageUploadForm
        }));
    
    })
    }
    else{
        this.setState(prevState => ({
            openImageUploadForm: !prevState.openImageUploadForm
        }));  
    }
    }
    handleComplimentaryCustomClick = () => {
        this.setState({ showComplimentaryCustom: !this.state.showComplimentaryCustom })
    };

    handleAdditionalCustomClick = () => {
        this.setState({ showAdditionalCustom: !this.state.showAdditionalCustom })
    };

    handleFoodCustomClick = () => {
        this.setState({ showFoodCustom: !this.state.showFoodCustom })
    };

    handleRestrictionCustomClick = () => {
        this.setState({ showRestrictionCustom: !this.state.showRestrictionCustom })
    };

    handleParkingCustomClick = () => {
        this.setState({ showParkingCustom: !this.state.showParkingCustom })
    };

    handleImageChange = (event, setSubmitting) => {

        error = [];
        this.setState({ zoom: 1, rotation: 0 })

        const len = Object.keys(event.target.files).length
        this.setState({ image_upload: true })

        let i
        for (i = 0; i <= len - 1; i++) {

            if (event.target.files[i].name.match(/.(jpg|jpeg)$/i) && (event.target.files[i].size <= FILE_SIZE)) {
                // let imageArray = this.state.images
                // const imageArrayLen = imageArray.length
                // imageArray.push({imageId:imageArrayLen + 1, path:'', id: '', name: '', uploading: true, uploaded: false})
                // let newArray = this.state.imageFile
                let newFileNameArray = this.state.imageFileName
                newFileNameArray.push(event.target.files[i].name)
                this.setState({
                    imageUploading: false,
                    imageFileName: newFileNameArray,
                    imageUploaded: true,
                    venueImageError: '',
                    imageFileData: URL.createObjectURL(event.target.files[i])
                });
                var formData = new FormData();
                let img = event.target.files[i];
                formData.append("name", img.name);

            }

            if (!event.target.files[i].name.match(/.(jpg|jpeg)$/i)) {
                // this.state.images.pop();
                error.push("*Unsupported Format only jpg and jpeg images will be accepted");
                this.setState({ image_upload: false })


                setSubmitting(true)
            }
            else if (event.target.files[i].size >= FILE_SIZE) {
                error.push("*File size should not be greater than 3MB.");
                this.setState({ image_upload: false })

                setSubmitting(true)
            }
            else {
                this.setState({
                    cropImage: true,
                })

            }
        }
    }

    deleteImage = (value) => {
        let imageArray=this.state.images
        let newArray = this.state.imageFile
        let imageIdArray = this.state.imageFileId
        let imageFileIndex = imageArray.findIndex((obj) => obj.id === value)
        let newFileNameArray = this.state.imageFileName
        // let id;
        if (imageFileIndex !== -1) {
            // id = imageIdArray[imageFileIndex]
            newArray.splice(imageFileIndex, 1);
            imageIdArray.splice(imageFileIndex, 1);
            newFileNameArray.splice(imageFileIndex, 1);
            imageArray.splice(imageFileIndex,1)
            this.setState({
             onDeleteImage:true
           })
        }
        this.setState({
            imageFile: newArray,
            imageFileId: imageIdArray,
            imageFileName: newFileNameArray,
            images:imageArray
        });

        this.props.deleteVenueImage(this.props.match.params.venueId, value)
            .then((response) => {
                this.props.getVenue(this.props.match.params.venueId,1)
            })
            .catch(error => { })
    }
    updateName = (event, index) => {
        let photographerName = this.state.images
        photographerName[index]['p_name'] = event
        this.setState({ images: photographerName })
    }
    updateWebsite = (event, index) => {
        let websiteName = this.state.images
        websiteName[index]['p_website'] = event
        this.setState({ images: websiteName })
    }
    updateImagesOrder = (images) => {
        let orderImages = this.state.images;
        if ('source' in images) {
            orderImages[images.source.index]['order'] = images.destination.index + 1;
            orderImages[images.destination.index]['order'] = images.source.index + 1;

        }
        this.setState({ images: orderImages.sort((a, b) => { return a.order - b.order }) });
    }



    // handleImageChange = (event, setSubmitting) => {
    // const len = Object.keys(event.target.files).length
    // let i
    // for (i = 0; i <= len - 1; i++) {
    // if (event.target.files[i].name.match(/.(jpg|jpeg)$/i) && (event.target.files[i].size <= FILE_SIZE)) {
    // var newArray = this.state.imageFile
    // var newFileNameArray = this.state.imageFileName
    // newArray.push(URL.createObjectURL(event.target.files[i]))
    // newFileNameArray.push(event.target.files[i].name)
    // this.setState({
    // imageFile: newArray,
    // imageUploading: false,
    // imageFileName: newFileNameArray,
    // imageUploaded: true,
    // venueImageError: ''
    // });
    // }
    // if (!event.target.files[i].name.match(/.(jpg|jpeg)$/i)) {
    // this.setState({ venueImageError: '*Unsupported Format only jpg and jpeg images will be accepted' })
    // setSubmitting(true)
    // }
    // else if (event.target.files[i].size >= FILE_SIZE) {
    // this.setState({ venueImageError: '*File is too large' })
    // setSubmitting(true)
    // }
    // }
    // }
    // deleteImage = (value) => {
    // var newArray = this.state.imageFile
    // var newFileNameArray = this.state.imageFileName

    // var imageFileIndex = newArray.indexOf(value)
    // if (imageFileIndex !== -1) {
    // newArray.splice(imageFileIndex, 1);
    // newFileNameArray.splice(imageFileIndex, 1);

    // }
    // this.setState({
    // imageFile: newArray,
    // imageFileName: newFileNameArray
    // });
    // }
    deleteByName = (value) => {
        var newArray = this.state.imageFile
        var newFileNameArray = this.state.imageFileName

        var fileNameIndex = newFileNameArray.indexOf(value)
        if (fileNameIndex !== -1) {
            newArray.splice(fileNameIndex, 1);
            newFileNameArray.splice(fileNameIndex, 1);
        }
        this.setState({
            imageFile: newArray,
            imageFileName: newFileNameArray
        });
    }
    handleImageUpload = () => {
        var link = document.getElementById('image');
        link.click();
    }
    handleOpenFlexiblePopper = (event) => {
        this.setState({
            anchorElFlexible: event.currentTarget,
            isFlexibleToggleOpen: true
        })
    }
    handleCloseFlexiblePopper = () => {
        this.setState({
            anchorElFlexible: null,
            isFlexibleToggleOpen: false
        });
    }
    handleSubmit = (values, actions) => {
        if (this.state.image_upload === true) {
            if (this.state.cropping === true) {
                error = [];
                if (Object.keys(this.state.imageFile).length > 0 && this.state.venueImageError === '') {
                    this.setState({ venueImageError: '' })
                    actions.setSubmitting(false);
                    this.handleEditPhotosClick();
                    this.updatePhotographerDetail(values.photographer);
                }
            }
            else {
                this.setState({ errorCrop: "Please click crop" })

            }
        }
        else {
            if (Object.keys(this.state.imageFile).length > 2 && this.state.venueImageError === '') {
                this.setState({ venueImageError: '' })
                actions.setSubmitting(false);
                this.handleEditPhotosClick();
                this.updatePhotographerDetail(values.photographer);

            }
        }
    };

    updatePhotographerDetail = (params) => {
        this.props.updatePhotographer({ data: params }).then(res => {
            this.props.getVenue(this.props.match.params.venueId);
        });
    }

    handleSave = (funcH, save) => {
        this.setState({ errorOpenFun: funcH, saveExit: save })
        document.getElementById('submitSave').click();
    }
    errorClose = () => {
        this.setState({ editvenueError: false, editvenueErrorMessage: '' })

    }
    editProfileSave = (value) => {
        if (value.naturalLight === '' || value.nearConvention === '' || value.beautifulView === '' || value.exposedBrick === '' || value.iconicBuilding === '') {
            this.setState({ editvenueError: true, editvenueErrorMessage: "Complete venue set-up before editing profile or you may lose data." })

        }
        else if (value.complimentary.length === 0 &&
            value.additional.length === 0 &&
            value.food.length === 0 &&
            value.restriction.length === 0 &&
            value.complementaryParking.length === 0) {
            this.setState({ editvenueError: true, editvenueErrorMessage: "Complete venue set-up before editing profile or you may lose data." })
        }
        else {
            this.setState({ loader: true })
            this.setState({ redirect: true, editvenueError: false, editvenueErrorMessage: "" })
            document.getElementById('submitSave').click();
            setTimeout(function () {
                window.location.assign('/editVenue/' + this.props.match.params.venueId + '/1');
            }.bind(this), 5000)
        }

    }
    handleSaveClick = (values, action) => {
        let features = [];
        let comCust = false;
        let addCust = false;
        let feature_comp = false;
        let topList = false;
        let topListChar = false;
        let error = [];
        this.setState({
            features: false,
            topErrorNatural: false,
            topErrorCon: false,
            topErrorBrick: false,
            topErrorBeautiful: false,
            topErrorBuilding: false
        })
        if (values.naturalLight.length > 25 || values.nearConvention.length > 25 || values.beautifulView.length > 25 || values.exposedBrick.length > 25 || values.iconicBuilding.length > 25) {
            topListChar = true;
            error.push('Top 5 list should not be greater than 25 characters');

        }
        if (values.naturalLight === '' || values.nearConvention === '' || values.beautifulView === '' || values.exposedBrick === '' || values.iconicBuilding === '') {
            error.push('Top 5 list')
            topList = true;
            if (values.naturalLight == '') {

                this.setState({ topErrorNatural: true })
            }
            if (values.nearConvention == '') {
                this.setState({ topErrorCon: true })
            }
            if (values.beautifulView == '') {
                this.setState({ topErrorBeautiful: true })
            }
            if (values.exposedBrick == '') {
                this.setState({ topErrorBrick: true })
            }
            if (values.iconicBuilding == '') {
                this.setState({ topErrorBuilding: true })
            }
        }
        if (values.complimentary.length > 0) {
            feature_comp = true;
            for (let i = 0; i < values.complimentary.length; i++) {
                let obj = {};
                obj.feature_id = values.complimentary[i];
                for (let j = 0; j < this.props.featureData.data[0].features.length; j++) {
                    if (parseInt(this.props.featureData.data[0].features[j].id) === parseInt(values.complimentary[i])
                        && this.props.featureData.data[0].features[j].name === "Other") {
                        if (values.complimentaryCustom &&
                            values.complimentaryCustom.length <= 100) {

                            obj.custom_name = values.complimentaryCustom;

                        }
                        else {
                            comCust = true;
                        }
                    }
                }
                features.push(obj);
            }
        }
        if (values.additional.length > 0) {
            feature_comp = true;

            for (let i = 0; i < values.additional.length; i++) {
                let obj = {};
                obj.feature_id = values.additional[i];
                for (let j = 0; j < this.props.featureData.data[1].features.length; j++) {
                    if (parseInt(this.props.featureData.data[1].features[j].id) === parseInt(values.additional[i]) && this.props.featureData.data[1].features[j].name === "Other") {
                        if (values.additionalCustom && values.additionalCustom.length <= 100) {
                            obj.custom_name = values.additionalCustom;
                        }
                        else {
                            addCust = true;
                        }
                    }
                }
                features.push(obj);
            }
        }
        if (values.complementaryParking.length > 0) {
            feature_comp = true;

            for (let i = 0; i < values.complementaryParking.length; i++) {
                let obj = {};
                obj.feature_id = values.complementaryParking[i];
                features.push(obj);
            }
        }
        if (values.food.length > 0) {
            feature_comp = true;

            for (let i = 0; i < values.food.length; i++) {
                let obj = {};
                obj.feature_id = values.food[i];
                features.push(obj);
            }
        }
        if (values.restriction.length > 0) {
            feature_comp = true;

            for (let i = 0; i < values.restriction.length; i++) {
                let obj = {};
                obj.feature_id = values.restriction[i];
                features.push(obj);
            }
        }

        if (feature_comp === true && topList == false && topListChar == false) {
            this.setState({
                features: false,
                topErrorNatural: false,
                topErrorCon: false,
                topErrorBrick: false,
                topErrorBeautiful: false,
                topErrorBuilding: false
            })
            let description = {
                description: values.description ? values.description : this.props.venueDetails.description,
                features: features,
                attractions:
                    [{ attraction: values.naturalLight },
                    { attraction: values.beautifulView },
                    { attraction: values.nearConvention },
                    { attraction: values.exposedBrick },
                    { attraction: values.iconicBuilding }]
            };
            if (comCust === false && addCust === false) {
                this.props.saveExit(this.props.match.params.venueId, description).then(() => {
                    if (this.state.redirect === false) {
                        this.props.history.push('/myVenue')
                    }
                    else {
                        this.setState({ redirect: false })

                    }
                })
            }

        }
        else {
            if (feature_comp == false) {
                error.push('Features')
                this.setState({ features: true })

            }
            if (this.state.errorOpenFun) {
                this.state.errorOpenFun(error);
            }
        }

    }

    publishUnpublish = (id, json) => {
        this.props.saveExit(id, json).then(() => {
        })
    }

    componentWillMount() {
        this.setState({ loader: false })
        com = [];
        add = [];
        park = [];
        food = [];
        rest = [];
        error = [];
        this.props.getVenue(this.props.match.params.venueId).then((response) => {
            this.setState({ getResponse: true, })
        })

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        error = [];
        if (this.props.venueDetails !== nextProps.venueDetails && Object.keys(nextProps.venueDetails).length !== 0) {
            com = [];
            add = [];
            park = [];
            food = [];
            rest = [];
            error = [];
            this.setState({ venueDetails: nextProps.venueDetails })
            this.setState({ pdfData: nextProps.venueDetails.documents, videos: nextProps.venueDetails.videos });

            if (nextProps.venueDetails.profilePhoto) {
                this.setState({ spaceImage: `${process.env.REACT_APP_MEDIA_URL}${nextProps.venueDetails.profilePhoto.path}` })
            }
            const venue = nextProps.venueDetails
            let attraction = nextProps.venueDetails.attractions && nextProps.venueDetails.attractions.length;
            if (attraction > 0) {

                this.setState({
                    naturalLight: nextProps.venueDetails.attractions[0].attraction,
                    beautifulView: nextProps.venueDetails.attractions[1].attraction,
                    nearConvention: nextProps.venueDetails.attractions[2].attraction,
                    exposedBrick: nextProps.venueDetails.attractions[3].attraction,
                    iconicBuilding: nextProps.venueDetails.attractions[4].attraction
                })
            }
            let len = nextProps.venueDetails.features && nextProps.venueDetails.features.length;
            if (len > 0) {

                for (let i = 0; i < len; i++) {
                    if (nextProps.venueDetails.features && parseInt(nextProps.venueDetails.features[i].type) === 1) {
                        com.push(nextProps.venueDetails.features && nextProps.venueDetails.features[i].id);
                        if (nextProps.venueDetails.features && nextProps.venueDetails.features[i].name === 'Other') {
                            this.setState({ showComplimentaryCustom: true, cust_com: nextProps.venueDetails.features && nextProps.venueDetails.features[i].custom_name });

                        }
                    }
                    if (nextProps.venueDetails.features && parseInt(nextProps.venueDetails.features[i].type) === 2) {
                        add.push(nextProps.venueDetails.features && nextProps.venueDetails.features[i].id);
                        if (nextProps.venueDetails.features && nextProps.venueDetails.features[i].name === 'Other') {
                            this.setState({ showAdditionalCustom: true, cust_add: nextProps.venueDetails.features && nextProps.venueDetails.features[i].custom_name });

                        }
                    }
                    if (nextProps.venueDetails.features && parseInt(nextProps.venueDetails.features[i].type) === 3) {
                        park.push(nextProps.venueDetails.features && nextProps.venueDetails.features[i].id);
                        if (nextProps.venueDetails.features && nextProps.venueDetails.features[i].name === 'Other') {
                            this.setState({ showParkingCustom: true, cust_park: nextProps.venueDetails.features && nextProps.venueDetails.features[i].custom_name });

                        }

                    }
                    if (nextProps.venueDetails.features && parseInt(nextProps.venueDetails.features[i].type) === 4) {
                        food.push(nextProps.venueDetails.features && nextProps.venueDetails.features[i].id);
                        if (nextProps.venueDetails.features && nextProps.venueDetails.features[i].name === 'Other') {
                            this.setState({ showFoodCustom: true, cust_food: nextProps.venueDetails.features && nextProps.venueDetails.features[i].custom_name });

                        }
                    }
                    if (nextProps.venueDetails.features && parseInt(nextProps.venueDetails.features[i].type) === 5) {
                        rest.push(nextProps.venueDetails.features && nextProps.venueDetails.features[i].id);
                        if (nextProps.venueDetails.features && nextProps.venueDetails.features[i].name === 'Other') {
                            this.setState({ showRestrictionCustom: true, cust_rest: nextProps.venueDetails.features && nextProps.venueDetails.features[i].custom_name });

                        }
                    }
                }
            }
            if(this.state.onDeleteImage==false){
            if (venue.photo.length >= 0) {
                let newArray = [], imageIdArray = [], newFileNameArray = []
                //changes

                let imageArray = []
                //chnages

                venue.photo.map((data, idx) => {
                    newArray.push(`${process.env.REACT_APP_MEDIA_URL}${data.path}`)
                    imageIdArray.push(data.id)
                    newFileNameArray.push(data.name)
                    const imageArrayLen = imageArray.length
                    imageArray.push({ imageId: imageArrayLen + 1, path: `${process.env.REACT_APP_MEDIA_URL}${data.path}`, id: data.id, name: data.name, p_name: data.p_name, order: (venue.profilePhoto && venue.profilePhoto.id == data.id) ? 0 : data.order, p_website: data.p_website, uploading: false, uploaded: true })
                    return imageArray;
                })
                /*this.setState({
                    imageFile: newArray,
                    imageUploading: false,
                    imageFileId: imageIdArray,
                    imageUploaded: true,
                    venueImageError: '',
                    imageFileName: newFileNameArray,
                    images: imageArray.sort((a, b) => { return a.order - b.order })
                });*/
                if(this.state.onUploadImage==true){
          this.setState({
            imageFile:this.state.imageFile.concat(newArray.slice(-1)),
            imageFileId:this.state.imageFileId.concat(imageIdArray.slice(-1)),
            imageFileName:this.state.imageFileName.concat(newFileNameArray.slice(-1)),
            imageUploading: false,        
            imageUploaded: true,
            images:this.state.images.slice(0,-1).concat(imageArray.slice(-1)),
            onUploadImage:false,
            onDeleteImage:false,
            venueImageError: ''
  
          })
        }
          else{
          this.setState({
            imageFile: newArray,
            imageUploading: false,
            imageFileId: imageIdArray,
            imageUploaded: true,
            venueImageError: '',
            imageFileName: newFileNameArray,
            images: imageArray
          });
          }
            }
            else {
                this.setState({
                    imageFile: [],
                    imageFileId: [],
                    images: []
                });
            }
        }
        }
        if (this.props.venueDetails !== nextProps.venueDetails && nextProps.venueDetails.address && this.props.venueDetails.address !== nextProps.venueDetails.address && Object.keys(nextProps.venueDetails.address).length !== 0) {
            let line_2 = nextProps.venueDetails.address.line_2 != null ? nextProps.venueDetails.address.line_2 : '';
            const venueAddress = nextProps.venueDetails.address.line_1 +
                ", " + line_2 +
                " " +
                nextProps.venueDetails.address.city +
                " " +
                nextProps.venueDetails.address.state_abbr +
                " " +
                nextProps.venueDetails.address.zip
            this.setState({ venueAddress: venueAddress });
        }

    }

    handleDocumentUploaded = (PDFData) => {
        // let arr=this.state.pdfData;
        // arr.push(PDFData.payload);
        // this.setState({ pdfData: arr })
        this.props.getVenue(this.props.match.params.venueId).then((response) => {
            this.setState({ pdfData: this.props.venueDetails.documents });
        })

    }

    handleDocumentDeleted = (PDFData) => {
        let arr = this.state.pdfData;
        const filteredPeople = arr.filter((item) => item.id !== PDFData);
        this.setState({ pdfData: filteredPeople })
    }

    handleDocumentUpdated = (index, updatedObject) => {
        let arr = this.state.pdfData;
        arr[index] = updatedObject;
        this.setState({ pdfData: arr })
    }


    settingClick = (data) => {
        this.setState({
            venueID: data.id,
            max_hold: data.max_hold_limit,
            hold_duration: data.hold_duration
        });
    }
    saveVenue = (value, url) => {
        if (value.naturalLight === '' ||
            value.nearConvention === '' ||
            value.beautifulView === '' ||
            value.exposedBrick === '' ||
            value.iconicBuilding === '') {
            this.setState({ editvenueError: true, editvenueErrorMessage: "Complete venue set-up before adding event space or you may lose data." })
        }
        else if (value.complimentary.length === 0 &&
            value.additional.length === 0 &&
            value.food.length === 0 &&
            value.restriction.length === 0 &&
            value.complementaryParking.length === 0) {
            this.setState({ editvenueError: true, editvenueErrorMessage: "Complete venue set-up before adding event space or you may lose data." })

        }
        else {
            this.setState({ redirect: true })
            document.getElementById('submitSave').click();
            setTimeout(function () {
                if (url) {
                    window.location.assign(url);
                }
                else {
                    window.location.assign(`/addSpace/${this.props.match.params.venueId}`);

                }
            }.bind(this), 5000)
        }
    }
    requiredRemove = () => {
        this.setState({ topErrorBrick: false })
        this.setState({ topErrorBuilding: false })
        this.setState({ topErrorCon: false })
        this.setState({ topErrorBeautiful: false })
        this.setState({ topErrorNatural: false })
    }

    handleVideoAdd = (vData) => {
        let vArr = this.state.videos;
        vArr.push(vData);
        this.setState({ videos: vArr });
    }

    handleVideoDeleted = (videoId) => {
        let vArr = this.state.videos;
        const filtered = vArr.filter((item) => item.id !== videoId);
        this.setState({ videos: filtered });
    }

    handleVideoUpdate = (vData) => {
        let vArr = this.state.videos;
        let index = vArr.findIndex(x => x.id == vData.id);
        if (index > -1) {
            vArr[index] = vData;
            this.setState({ videos: vArr });
        }
    }



    render(props) {
        let line_2 = this.props.venueDetails.address && this.props.venueDetails.address.line_2 != null ? this.props.venueDetails.address && this.props.venueDetails.address.line_2 : '';
        const venueDetails =
        {
            image: this.props.venueDetails.profilePhoto ? this.props.venueDetails.profilePhoto &&
                process.env.REACT_APP_MEDIA_URL + this.props.venueDetails.profilePhoto.path : logo,
            address: this.props.venueDetails.address &&
                this.props.venueDetails.address.line_1 +

                ", " + line_2 +
                " " +
                this.props.venueDetails.address.city +
                " " +
                this.props.venueDetails.address.state_abbr +
                " " +
                this.props.venueDetails.address.zip,

            name: this.props.venueDetails.name,
        }

        const markers = [
            {
                id: 1,
                latitude:
                    this.props.venueDetails.address &&
                    parseFloat(this.props.venueDetails.address.geo_lat, 10),
                longitude:
                    this.props.venueDetails.address &&
                    parseFloat(this.props.venueDetails.address.geo_lng, 10)
            }
        ];

        const ValidationSchema = Yup.object().shape({
            // additionalCustom: Yup.string().max(100, '*Should not be greater than 100 characters'),
            // parkingCustom: Yup.string().max(100, '*Should not be greater than 100 characters'),
            // foodCustom: Yup.string().max(100, '*Should not be greater than 100 characters'),
            // restrictionCustom: Yup.string().max(100, '*Should not be greater than 100 characters'),
            naturalLight: Yup.string(),
            beautifulView: Yup.string(),
            nearConvention: Yup.string(),
            exposedBrick: Yup.string(),
            iconicBuilding: Yup.string(),

            venueAddress: Yup.string().required().max(255, "Venue Address should not be more than 255 characters")
        });
        const ValidationSchemaAgree = Yup.object().shape({
            agree: Yup.boolean().oneOf([true], "*This must be checked."),
            photographer: Yup.array()
                .of(
                    Yup.object().shape({
                        id: Yup.number(),
                        p_name: Yup.string().when("p_website", {
                            is: (website) => { return website },
                            then: Yup.string().required("*Required"),
                        }).nullable(true),
                        p_website: Yup.string().nullable(true).url('website url must be valid.'),
                    })
                )
        });
        return (
            this.state.getResponse ?

                Object.keys(this.props.venueDetails).length !== 0 ?
                    <div className="venue_page">
                        <div style={{ visibility: 'hidden' }}>
                            <ModalBoxSetting
                                settingClick={this.settingClick}
                                data={this.state.max_hold !== '' && this.state.hold_duration !== ''
                                    && this.state.venueID !== '' ?
                                    {
                                        id: this.state.venueID,
                                        max_hold_limit: this.state.max_hold,
                                        hold_duration: this.state.hold_duration
                                    } :
                                    this.props.venueDetails} max_hold={this.state.max_hold}
                                hold_duration={this.state.hold_duration}
                                venueId={this.state.venueID} handleClose={this.handleClose} />
                        </div>
                        <img id="scream" style={{ display: 'none' }} alt="The Scream" crossOrigin="anonymous" />
                        <canvas style={{ display: 'none' }} id="myCanvas"></canvas>
                        <Grid xs={12} item>
                            <ButtonsHeader
                                publishUnpublish={this.publishUnpublish}
                                handleSave={this.handleSave}
                                venueDetails={this.props.venueDetails}
                                venueId={this.props.match.params.venueId} />
                        </Grid>
                        <Grid xs={12} item>
                            <Formik
                                validationSchema={ValidationSchemaAgree}

                                initialValues={{
                                    agree: false,
                                    venueImage: [],
                                    photographer: this.state.images
                                }}
                                enableReinitialize={true}
                                onSubmit={(values, actions) => {
                                    this.handleSubmit(values, actions)
                                }}
                                render={formikProps => (
                                    <Form onSubmit={formikProps.handleSubmit}>
                                        <TopImageComponent
                                            errorCrop={this.state.errorCrop}
                                            okButton={this.okButton}
                                            cancelButton={this.cancelButton}
                                            imageFileData={this.state.imageFileData}
                                            imageUrl={this.state.imageUrl}
                                            crop={this.state.crop}
                                            zoom={this.state.zoom}
                                            aspect={this.state.aspect}
                                            rotation={this.state.rotation}
                                            onRotationChange={this.onRotationChange}
                                            onCropChange={this.onCropChange}
                                            onCropComplete={this.onCropComplete}
                                            onZoomChange={this.onZoomChange}
                                            cropImage={this.state.cropImage}
                                            markers={markers}
                                            handleChange={formikProps.handleChange}
                                            error={error}
                                            venueData={this.props.venueDetails}
                                            venueDetails={venueDetails}
                                            editImage={true}
                                            formikProps={formikProps}
                                            openImageUploadForm={this.state.openImageUploadForm}
                                            handleEditPhotosClick={this.handleEditPhotosClick}
                                            imageFile={this.state.imageFile}
                                            imageUploaded={this.state.imageUploaded}
                                            imageFileName={this.state.imageFileName}
                                            imageUploading={this.state.imageUploading}
                                            handleImageUpload={this.handleImageUpload}
                                            handleImageChange={this.handleImageChange}
                                            deleteImage={this.deleteImage}
                                            venueImageError={this.state.venueImageError}
                                            deleteByName={this.deleteByName}
                                            updateImagesOrder={this.updateImagesOrder}
                                            updateName={this.updateName}
                                            updateWebsite={this.updateWebsite}
                                            images={this.state.images}
                                            spaceImage={this.state.spaceImage}
                                        />
                                    </Form>
                                )}
                            />
                        </Grid>
                        <Formik
                            validationSchema={ValidationSchema}
                            initialValues={{
                                naturalLight: this.state.naturalLight,
                                beautifulView: this.state.beautifulView,
                                nearConvention: this.state.nearConvention,
                                exposedBrick: this.state.exposedBrick,
                                iconicBuilding: this.state.iconicBuilding,
                                complimentaryCustom: this.state.cust_com,
                                additionalCustom: this.state.cust_add,
                                parkingCustom: this.state.cust_park,
                                foodCustom: this.state.cust_food,
                                restrictionCustom: this.state.cust_rest,
                                complimentary: com,
                                additional: add,
                                complementaryParking: park,
                                food: food,
                                restriction: rest,
                                description: this.props.venueDetails && this.props.venueDetails.description ? this.props.venueDetails && this.props.venueDetails.description : '',
                                venueAddress: this.state.venueAddress,
                            }}
                            onSubmit={(values, actions) => {
                                this.handleSaveClick(values, actions)
                            }}
                            render={formikProps => (
                                <Form onSubmit={formikProps.handleSubmit}>

                                    <Grid xs={12} item className="midContainer">
                                        <Grid container justify="center" className="justify">
                                            <Grid item md={7} xs={12} sm={6} xl={8} lg={8}>

                                                <VenueProfileEditComponent
                                                    features={this.state.features}
                                                    loader={this.state.loader}
                                                    errorClose={this.errorClose}
                                                    editvenueError={this.state.editvenueError}
                                                    editvenueErrorMessage={this.state.editvenueErrorMessage}
                                                    editProfileSave={this.editProfileSave}
                                                    pdfData={this.state.pdfData}
                                                    videos={this.state.videos}
                                                    handleVideoAdd={this.handleVideoAdd}
                                                    handleVideoUpdate={this.handleVideoUpdate}
                                                    handleVideoDeleted={this.handleVideoDeleted}
                                                    values={formikProps.values}
                                                    handleComplimentaryCustomClick={this.handleComplimentaryCustomClick}
                                                    showComplimentaryCustom={this.state.showComplimentaryCustom}
                                                    showAdditionalCustom={this.state.showAdditionalCustom}
                                                    showFoodCustom={this.state.showFoodCustom}
                                                    showParkingCustom={this.state.showParkingCustom}
                                                    showRestrictionCustom={this.state.showRestrictionCustom}
                                                    handleAdditionalCustomClick={this.handleAdditionalCustomClick}
                                                    handleParkingCustomClick={this.handleParkingCustomClick}
                                                    handleFoodCustomClick={this.handleFoodCustomClick}
                                                    handleRestrictionCustomClick={this.handleRestrictionCustomClick}
                                                    // values={formikProps.values} 
                                                    venueDetails={this.props.venueDetails}
                                                    handleChange={formikProps.handleChange}
                                                    venueId={this.props.match.params.venueId}
                                                    handleDocumentUploaded={this.handleDocumentUploaded}
                                                />

                                            </Grid>
                                            <Grid item md={5} xs={12} sm={5} xl={4} lg={4}>
                                                <Grid xs={12} item>
                                                    <Grid container justify="center">
                                                        <VenueSpaceEdit
                                                            errorClose={this.errorClose}
                                                            editvenueError={this.state.editvenueError}
                                                            editvenueErrorMessage={this.state.editvenueErrorMessage}

                                                            values={formikProps.values}
                                                            venue={this.props.venueDetails}
                                                            saveVenue={this.saveVenue}
                                                            space={this.props.venueDetails.spaces}
                                                            venueId={this.props.match.params.venueId}
                                                            anchorElFlexible={this.state.anchorElFlexible}
                                                            handleCloseFlexiblePopper={this.handleCloseFlexiblePopper}
                                                            handleOpenFlexiblePopper={this.handleOpenFlexiblePopper}
                                                            isFlexibleToggleOpen={this.state.isFlexibleToggleOpen} />

                                                        <VenueAddressEdit address={this.state.venueAddress}
                                                            venueId={this.props.match.params.venueId}
                                                            name="venueAddress" />
                                                        {/* address={this.props.venueDetails.address &&
this.props.venueDetails.address.line_1 +
" " +
this.props.venueDetails.address.city +
" " +
this.props.venueDetails.address.country +
" " +
this.props.venueDetails.address.zip}

<ContactInfo message={this.props.messageContact} sendData={this.props.contactDetails} contactClick={this.contactClick} venueProfile={this.props.venue} spaceId={this.props.match.params.spaceId} spaceInfo={this.state.space}/> */}
                                                        {this.props.width !== 'xs' &&
                                                            <div className="desktop-view">
                                                                <VenuePdfEdit pdfData={this.state.pdfData}
                                                                    venueData={this.props.venueDetails}
                                                                    handleDocumentUploaded={this.handleDocumentUploaded}
                                                                    handleDocumentDeleted={this.handleDocumentDeleted}
                                                                    handleDocumentUpdated={this.handleDocumentUpdated}
                                                                    venueDetails={this.state.pdfData}
                                                                    videos={this.state.videos}
                                                                    handleVideoAdd={this.handleVideoAdd}
                                                                    handleVideoUpdate={this.handleVideoUpdate}
                                                                    handleVideoDeleted={this.handleVideoDeleted}
                                                                    venueId={this.props.match.params.venueId} />
                                                                <VenueAttractionEdit
                                                                    formikProps={formikProps}
                                                                    requiredRemove={this.requiredRemove}
                                                                    topErrorBrick={this.state.topErrorBrick}
                                                                    topErrorBuilding={this.state.topErrorBuilding}
                                                                    topErrorCon={this.state.topErrorCon}
                                                                    topErrorBeautiful={this.state.topErrorBeautiful}
                                                                    topErrorNatural={this.state.topErrorNatural}
                                                                />
                                                            </div>
                                                        }

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )} />
                        <VenueFooter className="fh-container" />
                    </div>
                    : "Loading..."
                : <div className="fourohfour"><h2>Loading...</h2></div>

        );

    }
}

VenueProfileEdit.propTypes = {
    getVenue: func.isRequired,
    saveExit: func.isRequired
};

const mapStateToProps = state => {
    return {
        venueDetails: state.data.venues.venueDetails,
        featureData: state.data.featureTypes.featureList

    };
};

const enhance = compose(
    withRouter,
    withWidth(),
    connect(mapStateToProps, dispatch =>
        bindActionCreators(
            {
                getVenue,
                venueImageUpload,
                deleteVenueImage,
                getUploadPostUrl,
                uploadtoS3,
                saveExit,
                updatePhotographer
            },
            dispatch
        )
    )
);
export default enhance(VenueProfileEdit);


import React from 'react';
import { Grid } from '@material-ui/core';
import '../assets/myVenue.scss';
const logo = require("../../shared/components/assets/images/user-default.png");

function VenueManagerHeader(props) {
  // const diffDate = props.diffDate > 30 ? Math.round(props.diffDate/30) : props.diffDate
  return (
    <Grid md={12} xs={12} sm={12} xl={12} lg={12} className="venue-manager-header">
      <div className="header">
        <div className="venue-manager-image">
          <div className="image-div">
            <img className="image"
              src={props.imageSrc ? props.imageSrc : logo}
              alt="FIRST-HOLD"
              height="60"
              width="60"
            />
          </div>
        </div>

        <div className="venue-details">
          <div className="details">
            <div className="title">
              {props.managerName}
            </div>
            <p className="blue-text">
              {props.jobTitle}
            </p>
          </div>
        </div>
      {/* {
        props.planExists ?
          props.tier === null ?
          props.diffDate !== '' ?
            <div className="subtext">
              You have {props.diffDate} days left to try FIRST-HOLD for free. <a onClick={props.openVenuePlanOnClickOfLink} className="red-text">Set up a paid account.</a>
            </div>
            :
            <div className="subtext">
              <a onClick={props.openVenuePlanOnClickOfLink} className="red-text">Set up a paid account.</a>
            </div>
          
            :
            props.diffDate !== '' ?
              <div className="subtext">
                You have {props.diffDate} days left for{props.tier === 1 ? 'Tier One plan' : props.tier === 2 ? 'Tier Two plan' : props.tier === 3 ? 'Tier Three plan' : ''}.  <a onClick={props.openVenuePlanOnClickOfLink} className="red-text">{props.tier !== 3 ?'Upgrade your plan' : 'Update your plan'}</a>
              </div>
              :
              null
          : <div className="subtext">
              <a onClick={props.openVenuePlanOnClickOfLink} className="red-text">Set up a paid account.</a>
            </div>
      } */}
      {/* { 
        props.diffDate === 0 ?
        <div className="plan-validity-text mobile-disp-none">
          <div className="plantext">
              Your plan has been expired. <a onClick={props.openVenuePlanOnClickOfLink} className="red-text">Set up a paid account.</a>
          </div>
        </div>
      :
          
        props.tierType === 'trial' ?
          <div className="plan-validity-text mobile-disp-none">
            <div className="plantext">
                You have {diffDate} {props.diffDate > 30 ? diffDate > 1 ? 'months' : 'month' : 'days'} left to try FIRST-HOLD for free. <a onClick={props.openVenuePlanOnClickOfLink} className="red-text">Set up a paid account.</a>
            </div>
          </div>
          : 
          props.tier !== null &&
          <div className="plan-validity-text mobile-disp-none">
            <div className="plantext">
              You have {diffDate} {props.diffDate > 30 ? diffDate > 1 ? 'months' : 'month' : 'days'} left for {props.tier === 1 ? 'Tier One plan' : props.tier === 2 ? 'Tier Two plan' : props.tier === 3 ? 'Tier Three plan' : ''}.  <a onClick={props.openVenuePlanOnClickOfLink} className="red-text">{props.tier !== 3 ?'Upgrade your plan' : 'Update your plan'}</a>
            </div>
          </div>
      } */}
      </div>
    </Grid>
  );
}

export default VenueManagerHeader;

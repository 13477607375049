import {DELETE_SPACE_HOLIDAY_RATE} from 'shared/state/types';
import {request} from 'shared/auth';

function onRequested() {
    return {type: DELETE_SPACE_HOLIDAY_RATE.REQUEST};
}

function onFailed(error) {
    return {type: DELETE_SPACE_HOLIDAY_RATE.FAILURE, error: error};
}

function onSuccess(message) {
    return {type: DELETE_SPACE_HOLIDAY_RATE.SUCCESS, message: message};
}

export function deleteHolidayRate(Id) {
    return dispatch => {
        dispatch(onRequested());
        return request()
            .delete(`space_price_override/${Id}`)
            .then(response => {
                dispatch(onSuccess(response.data.message))
                return response
            })
            .catch(errors => {
                dispatch(onFailed());
                throw errors;
            });
    };
}

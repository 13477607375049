import { Record } from 'immutable';

import {
  GET_PAGE_REQUEST,
  GET_PAGE_FAILURE,
  GET_PAGE_SUCCESS,
} from 'shared/state/types';

class State extends Record({
  content:[],
  error:''
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_PAGE_REQUEST:
      return state.merge({
        error:''
      });
    case GET_PAGE_FAILURE:
      return state.merge({
        content:{},
        error: action.error


      });
    case GET_PAGE_SUCCESS:
      return state.merge({
        content:action.page_data,
        error:''
      });

    default:
      return state;
  }
}


import React from 'react';
import '../assets/styles/landing-page.scss';
import { Grid } from '@material-ui/core';
import { Link } from "react-router-dom";
import Footer from 'explore-landing-page/component/footer';
import { Helmet } from "react-helmet";
import Footers from '../../landingPage/components/footer';

function Wedding(props) {
    return (
        <div className="">
            <div class="container explore-ui-wrapper">
                <Helmet>
                    <title>FInd A Venue For Your Wedding | FIRST-HOLD</title>
                    <meta name="description" content="Are you looking for a perfect venue for your wedding events? We have multiple venues, according to your specifications at flexible packages Find your venue now!"></meta>
                    <meta name="robots" content="index"></meta>
                </Helmet>
                <Grid container className="topspace">                                        
                    <Grid className="banner-wrapper" item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className="search-wrapper">
                            <div className="heading-text main">
                                <h1>Find a wedding venue near me</h1>
                                <p>Discover great wedding venues.</p>
                            </div>
                            <div className="search-btn">
                                <a href="browseVenue/wedding/type" className="">Search</a>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div className="text-center-image weeding-main">
                            {/* <img className="" src={require('../assets/images/Wedding Page - Main.png')} alt="FIRST-HOLD"/> */}
                            <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                        </div>
                    </Grid>
                </Grid>
                {/* new block start */}
                <Grid container className="box-wrapper">
                    <Grid className="" item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div className="text-center-image weeding-main-1 left-side">
                            {/* <img className="" src={require('../assets/images/Blog - Wedding - Photo2.jpg')} alt="FIRST-HOLD"/> */}
                            <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className="search-wrapper-block">
                            <div className="heading-text ">
                                <h1>How wedding venues work on FIRST-HOLD</h1>
                                <p>FIRST-HOLD matches people planning events to venues with available dates --that's it.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Search size, style and budget</h2>
                                <p>Search for available wedding venues with the right size, vibe, and budget.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Immediately check availability</h2>
                                <p>Finding a wedding venue that fits?  Know if your preferred date is
                                    available — even when you’re searching late at night! Place a hold or
                                    contact the venue to learn more.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Communicate directly</h2>
                                <p>FIRST-HOLD connects you directly with venue managers. They call you
                                    to learn more about your event — all while knowing your date is currently available.</p>
                            </div>
                            <div className="search-btn started">
                                <a href="/browseVenue?address=San Francisco Bay Area" className="">Get started</a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className="featured-venue-bay box-wrapper">
                    <div className="heading-text">
                        <h1>Featured wedding venues</h1>
                    </div>
                    <Grid container className="margin-10">
                        <Grid className="" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="search-wrapper-block two-box">
                                <div className="">
                                    <p>When you search for Bay Area venues on FIRST-HOLD, you  can search for many different characteristics that are important to your big event. </p>
                                    <p className="margin-top-5">Our brides and wedding planners love to search by: </p>
                                    <ul>
                                        <li>event date</li>
                                        <li># of guests</li>
                                        <li>seating style (sit-down dinner or reception)</li>
                                        <li>venue rental budget</li>
                                    </ul>
                                </div>
                                <div className="search-field margin-top">
                                    <p><span className="bold">When you find a great venue, here’s the difference:</span>you’ll
                                        know immediately whether your preferred date is available
                                        (even when you’re searching late at night). </p>
                                </div>
                                <div className="search-field margin-top">
                                    <p>
                                        Also, your inquiries go directly to the venue manager and
                                        you’ll both get each other’s contact info. Both you and the
                                        venue communicate directly and avoid in-app chats.
                                    </p>
                                </div>
                                <div className="search-field margin-top">
                                    <p>All these features mean you’re <span className="bold">saving time in your day</span> and <span className="bold">reducing wedding planning stress</span> when you use FIRST-HOLD
                                        to search for wedding venues.</p>
                                </div>
                                <div className="search-btn started">
                                    <a href="/browseVenue?address=San Francisco Bay Area" className="">Check them out</a>
                                </div>
                            </div>
                        </Grid>
                        <Grid className="" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="text-center-image third-section weeding-main-2">
                                {/* <img className="" src={require('../assets/images/San Francisco - Bay Area1.jpg')} alt="FIRST-HOLD" /> */}
                                <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {/* Articles Section Start */}
                <div className="articles-section">
                    <div className="heading-text">
                        <h1>Articles</h1>
                    </div>
                    <div className="text-with-btn">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={9} lg={6} xl={6}>
                                <h1>Eliminate wedding day stress with the right kind of venue</h1>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={6} xl={6} className="btn-right">
                                <a href="https://first-hold.squarespace.com/firsthold/eliminate-wedding-day-stress-with-the-right-kind-of-venue" className="text-right article-btn">Read Article</a>
                            </Grid>
                        </Grid>
                    </div>
                    <p class="slider-heading-box frequently box-wrapper">
                        Picking the right <span class="bold">wedding </span> venue will eliminate much of your wedding day stress. How? Circumstances will 
                        happen on your day that are out of your control. Your shoes fall apart. Your cake melts. Your ring bearer 
                        and flower girl don’t get along… But thoughtfully selecting a <span class="bold">wedding venue </span> that hits the mark is 100% in 
                        your control, and you can plan for it well in advance of your big day.
                    </p>
                    <div className="text-with-btn">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={9} lg={6} xl={6}>
                                <h1>8 deal breakers when choosing your event venue</h1>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={6} xl={6} className="btn-right">
                                <a href="https://first-hold.squarespace.com/firsthold/8-deal-breakers-to-consider-when-choosing-your-event-venue" className="text-right article-btn">Read Article</a>
                            </Grid>
                        </Grid>
                    </div>
                    <p className="slider-heading-box frequently box-wrapper">
                        Choosing an <span class="bold">event venue</span> for your next corporate meeting or event is the most important part of your
                        planning process. If you don’t have a venue, your event can’t happen. If you don’t have the right venue,
                        your event won’t achieve the experience and results you want it to. The Dealbreaker Checklist we’ve
                        created below will help you consider and identify essential criteria as you plan your next event.
                    </p>
                </div>
                {/* Articles Section End */}
                {/* Need More section start */}
                <div className="need-more-section box-wrapper">
                    <div className="need-more-wrapper">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className="heading-text">
                                    <h1>Need more tips on finding the perfect wedding venue?</h1>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className="search-btn">
                                    <a href="/blog" className="">Read more on our blog</a>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Footer />
            </div>
            <Footers />
        </div>
    );
}
export default Wedding;

import React, { Component } from 'react';

import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators,  compose } from 'redux';
import Popover from '@material-ui/core/Popover';

import { createPlan, updatePlan } from 'data/plan/actions';
import { userDetails } from "shared/venueList/actions";
import Footer from '../../landingPage/components/footer';

import { Grid, Button, Typography, withStyles } from '@material-ui/core';

import '../assets/venuePlan.scss';

// const planType = localStorage.getItem('plan_type')

const styles = theme => ({
    icon: {
        // marginLeft: '8px',
        // paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        // marginLeft: '8px',
        // paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px',
        cursor: 'pointer'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    root: {
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    typography: {
        width: '237px',
        height: 'auto',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        fontFamily: 'Raleway'
    }
})

class VenuePlan extends Component {
    state = {
        planType: localStorage.getItem('plan_type'),
        anchorEl1: null,
        isToggleOpen1: false,
        openToggle1: false,
        anchorEl2: null,
        isToggleOpen2: false,
        openToggle2: false,
        anchorEl3: null,
        isToggleOpen3: false,
        openToggle3: false,
        planId: localStorage.getItem('plan_id')
    }
    UNSAFE_componentWillMount() {
        this.props.userDetails()
        localStorage.removeItem('plan_tier')
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // if (this.props.userData !== nextProps.userData && nextProps.userData.data && nextProps.userData.data.plan && Object.keys(nextProps.userData).length !== 0) {
        //     localStorage.setItem('plan_id',nextProps.userData.data.plan.id)
        // }
    }

    handleHelpIconClick = (event, popup) => {
        if (popup === 1) {
            this.setState({
                anchorEl1: event.currentTarget,
                isToggleOpen1: true,
                openToggle1: true
            })
        }
        else if (popup === 2) {
            this.setState({
                anchorEl2: event.currentTarget,
                isToggleOpen2: true,
                openToggle2: true
            })
        }
        else if (popup === 3) {
            this.setState({
                anchorEl3: event.currentTarget,
                isToggleOpen3: true,
                openToggle3: true
            })
        }
    }
    handleCloseHelpIcon = (popup) => {
        if (popup === 1) {
            this.setState({
                anchorEl1: null,
                isToggleOpen1: false,
                openToggle1: false
            });
        }
        else if (popup === 2) {
            this.setState({
                anchorEl2: null,
                isToggleOpen2: false,
                openToggle2: false
            });
        }
        if (popup === 3) {
            this.setState({
                anchorEl3: null,
                isToggleOpen3: false,
                openToggle3: false
            });
        }
    }

    selectPlan = (tier) => {
        localStorage.setItem('plan_tier', tier)
        // let days; 
        // if(parseInt(tier) === 1){
        //     days = '30'
        // }
        // else if(parseInt(tier) === 2){
        //     days = '365'
        // }
        // else{
        //     days = '730'
        // }
        if(this.props.match && this.props.match.params && this.props.match.params.venueId){
            localStorage.setItem('venue_id',this.props.match.params.venueId)
            this.props.history.push('../payNow')
            // const formData = `days=${days}&tier=${tier}&venue_id=${this.props.match.params.venueId}`
            // this.props.createPlan(formData)
        }
        else{
            this.props.history.push('./payNow')
            // const formData = `days=${days}&tier=${tier}`
            // this.props.createPlan(formData).then((res) => {
            //                             {
            //                                 this.props.history.push('./payNow')
            //                             }
            //                         })
        }
    }

    // selectPlan = (tier) => {
    //     let formData = ''
    //     if(!localStorage.getItem('type')){
    //         localStorage.setItem('tier',tier)
    //         this.props.history.push(`/register`)
    //     }
    //     else if(this.props.match && this.props.match.params && this.props.match.params.planId && this.props.match.params.venueId){
    //         localStorage.setItem('plan_id',this.props.match.params.planId)
    //         localStorage.setItem('venue_id',this.props.match.params.venueId)
    //         if (this.state.planType === 'trial') {
    //             formData = `days=30&trail_tier=${tier}`
    //             if (this.state.planId) {
    //                 this.props.updatePlan(this.state.planId, formData, "show")
    //             }
    //             else {
    //                 this.props.createPlan(formData)
    //                     .then((res) => {
    //                         this.props.userDetails()
    //                             .then((response) => {
    //                                 this.props.history.push('./myVenue')
    //                             })
    //                     })
    //             }
    //         }
    //         else{
    //             localStorage.setItem('plan_tier',tier)
    //             this.props.history.push('/payNow')
    //             // formData = `days=30&tier=${tier}`
    //         }
    //     }
    //     else if(this.props.match && this.props.match.params && this.props.match.params.venueId){
    //         localStorage.setItem('venue_id',this.props.match.params.venueId)
    //         if (this.state.planType === 'trial') {
    //             formData = `days=30&trail_tier=${tier}`
    //             if (this.state.planId) {
    //                 this.props.updatePlan(this.state.planId, formData, "show")
    //             }
    //             else {
    //                 this.props.createPlan(formData)
    //                     .then((res) => {
    //                         this.props.userDetails()
    //                             .then((response) => {
    //                                 this.props.history.push('./myVenue')
    //                             })
    //                     })
    //             }
    //         }
    //         else{
    //             localStorage.setItem('plan_tier',tier)
    //             this.props.history.push('/payNow')
    //             // formData = `days=30&tier=${tier}`
    //         }
    //     }
    //     else{
    //         // if (this.state.planType === 'trial') {
    //         //     formData = `days=30&trail_tier=${tier}`
    //         //     if (this.state.planId) {
    //         //         this.props.updatePlan(this.state.planId, formData, "show")
    //         //     }
    //         //     else {
    //         //         this.props.createPlan(formData)
    //         //             .then((res) => {
    //         //                 this.props.userDetails()
    //         //                     .then((response) => {
    //         //                         this.props.history.push('./myVenue')
    //         //                     })
    //         //             })
    //         //     }
    //         // }
    //         // else{
    //             // localStorage.setItem('plan_tier',tier)
    //             // this.props.history.push('/payNow')
    //         //     // formData = `days=30&tier=${tier}`
    //         // }
    //         localStorage.setItem('plan_tier',tier)
    //         this.props.history.push('/payNow')
    //     }
    // }
    render() {
        const { classes } = this.props;
        const anchorEl = this.state.anchorEl;
        const anchorElTierTwo = this.state.anchorElTierTwo;
        const anchorElTierThree = this.state.anchorElTierThree;

        const open = Boolean(anchorEl);
        const openTierTwo = Boolean(anchorElTierTwo);
        const openTierThree = Boolean(anchorElTierThree);

        const handleClick = event => {
            this.setState({anchorEl : event.currentTarget});
          };

          const handleClickTierTwo = event => {
            this.setState({anchorElTierTwo : event.currentTarget});
          };
      
          const handleClickTierThree = event => {
            this.setState({anchorElTierThree : event.currentTarget});
          };
      
      
          const handleClose = () => {
            this.setState({anchorEl : null});
            }  

          const handleCloseTierTwo = () => {
            this.setState({anchorElTierTwo : null});
            }  

          const handleCloseTierThree = () => {
            this.setState({anchorElTierThree : null});
            }  

          const id = open ? 'simple-popper' : undefined;
          const idTierTwo = openTierTwo ? 'simple-popper' : undefined;
          const idTierThree = openTierThree ? 'simple-popper' : undefined;
        return (
            <div>
                <Grid container className="venue_plan_container">
                    <Grid className="plan_outer_container">
                        <h2 className="blue-heading">Start building better relationships with planners today.</h2>
                        {/*<h3 className="heading-red">Try FIRST-HOLD free</h3>
                        <p>No credit card required</p>*/}
                        {/*<p className="head-text">choose the plan that’s right for you <br/> or your team.</p>*/}
                        {/* <p className="head-text">To help, we’re giving all <b>Tier Two</b> plans 6 months FREE. No credit card required.</p>*/}
                        {/* <p className="head-text-blue">We match people planning events to venues with available dates — that’s it.</p>  */}
                        <p className="head-text-blue">We match people planning events to venues with available dates</p> 
                    <div className="tier-box">
                        <div className="plan_inner_container">
                            <p className="red-text">Most flexible</p>
                            <p className="plan_title">Tier One</p>
                            <p className="sub_title">Monthly plan</p>
                            <p className="bigsub_title">$149/
                            <span className="venue-mouse-over">

                            <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                    venue
                            </Button>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={this.state.anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                        }}
                                    >
                                        <Typography className={classes.typography}>A venue is the building, there can be multiple event spaces in a venue.</Typography>
                                    </Popover>
                            </span>
                           /month</p>
                            <ul className="plan_description big">
                                {/*<li>
                                    <div className="blue_icon_div">
                                    <img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">FREE 30-day trial</div>
                                    </li>*/}

                                <li>
                                    <div className="blue_icon_div">
                                    <img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Live availability on your website,<br/> our website, and on your industry partners’ websites</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div">
                                    <img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Availability link with live calendar for easy embedding in emails,<br/>marketing materials, etc.</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Boost your visibility with SEO-driven marketing: blogs, e-blasts, social</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Planner contact info included with each inquiry</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Unlimited inquiries from planners</div>
                                </li>
                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Seamless calendar integration</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Personalized profile optimization assessment</div>
                                </li>
                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Exclusive listing on FIRST-HOLD’s marketplace</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Dedicated email support</div>
                                </li>
                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">No contracts, cancel anytime, hassle-free</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Zero commission fees – ever!</div>
                                </li>
                            </ul>
                            {!localStorage.getItem('disable_plan_selection') &&
                                <div className="red-section-1">
                                    <Button variant="outlined" color="secondary" className="button-bg" onClick={(e) => { this.selectPlan(1) }}>
                                        {/* {this.state.planType === 'trial' ? 'Start Trial' : this.state.planType === 'paid' ? 'Purchase Plan' : 'Start Listing'} */}
                                        Select
                                    </Button>
                                </div>
                            }
                        </div>

                        <div className="plan_inner_container">
                            <p className="red-text">Most Popular</p>
                            <p className="plan_title blue">Tier Two</p>
                            <p className="sub_title">Annual plan</p>
                            <p className="bigsub_title">$99/
                                <span className="venue-mouse-over">

                                    <Button aria-describedby={idTierTwo} variant="contained" color="primary" onClick={handleClickTierTwo}>
                                            venue
                                    </Button>
                                            <Popover
                                                id={idTierTwo}
                                                open={openTierTwo}
                                                anchorEl={this.state.anchorElTierTwo}
                                                onClose={handleCloseTierTwo}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                                }}
                                            >
                                                <Typography className={classes.typography}>A venue is the building, there can be multiple event spaces in a venue.</Typography>
                                            </Popover>
                                    </span>
                            /month</p>
                            <ul className="plan_description">
                                {/*<li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">FREE 30-day trial</div>
                                            </li>*/}

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Live availability on your website,<br/> our website, and on your industry partners’ websites</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Availability link with live calendar for easy embedding (email signatures, marketing brochures, etc.)</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Boost your visibility with SEO-driven marketing: blogs, e-blasts, social</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">
                                    Planner contact info included with each inquiry
                                    {/* Featured Venue listing on the Featured venues Scroll
                                    <div className="list">
                                        15 day limit
                                    </div> */}
                                    </div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Unlimited inquiries from planners</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Seamless calendar integration</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Personalized profile optimization assessment</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Exclusive listing on FIRST-HOLD’s marketplace</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Dedicated email support</div>
                                </li>
                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">No contracts, cancel anytime, hassle-free</div>
                                </li>
                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Zero commission fees – ever!</div>
                                </li>
                                <li>
                                        <div className="blue_icon_div"></div>
                                        <div className="text"><b>PLUS:</b></div>
                                    </li>
                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Featured venue on homepage for 30 days</div>
                                </li>
                                <li className='tier2-list-text-center'>
                                    {/* <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div> */}
                                    <div className="text red list-text-center">$600 savings with annual plan!</div>
                                </li>

                                {/* <li>
                                    <div className="blue_icon_div"><img className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">
                                        Unlimited <span className={classes.activeIcon} onMouseOver={(e) => { this.handleHelpIconClick(e, 1) }} onMouseOut={(e) => { this.handleCloseHelpIcon(1) }}>API</span> Usage (Spread the word about your availability)
                                </div>
                                    <Popper className={classes.popper} open={this.state.openToggle1} anchorEl={this.state.anchorEl1} placement="right-end">
                                        <Paper classes={{ root: classes.root }}>
                                            <Typography className={classes.typography}>An API easily allows for integration to the venues website, preferred caterers, suppliers, etc.</Typography>
                                        </Paper>
                                    </Popper>
                                </li> */}

                                {/* <li>
                                    <div className="blue_icon_div"><img className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">
                                        One Month Free per <span className={classes.activeIcon} onMouseOver={(e) => { this.handleHelpIconClick(e, 2) }} onMouseOut={(e) => { this.handleCloseHelpIcon(2) }}>Qualified Venue</span> you Recommend to FIRST-HOLD (Max 5)
                                    <Popper className={classes.popper} open={this.state.openToggle2} anchorEl={this.state.anchorEl2} placement="right-end">
                                            <Paper classes={{ root: classes.root }}>
                                                <Typography className={classes.typography}>Qualified Venues are unique venues, think museums, art galleries, estates, wineries, roof top bars, etc.<br/>
Important: It cannot include venues that are currently listing on FIRST-HOLD.</Typography>
                                            </Paper>
                                        </Popper>
                                    </div>
                                </li> */}
                                {/* <li>
                                    <Grid className="advertisement">
                                     This can be FREE for the first 6 months
                                    </Grid>
                                </li> */}
                                
                            </ul>
                            {!localStorage.getItem('disable_plan_selection') &&
                                <div className="red-section-2">
                                    <Button variant="outlined" color="secondary" className="button-bg" onClick={(e) => { this.selectPlan(2) }}>
                                    Select
                                        {/* {this.state.planType === 'trial' ? 'Start Trial' : this.state.planType === 'paid' ? 'Purchase Plan' : 'Start Listing'} */}
                                    </Button>
                                </div>
                            }
                        </div>

                        <div className="plan_inner_container">
                            <p className="red-text">Most Bells & Whistles</p>
                            <p className="plan_title">Tier Three</p>
                            <p className="sub_title">Two-year plan</p>
                            <p className="bigsub_title">$89/
                            <span className="venue-mouse-over">

                                    <Button aria-describedby={idTierThree} variant="contained" color="primary" onClick={handleClickTierThree}>
                                            venue
                                    </Button>
                                            <Popover
                                                id={idTierThree}
                                                open={openTierThree}
                                                anchorEl={this.state.anchorElTierThree}
                                                onClose={handleCloseTierThree}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                                }}
                                            >
                                                <Typography className={classes.typography}>A venue is the building, there can be multiple event spaces in a venue.</Typography>
                                            </Popover>
                                    </span>
                            /month</p>
                           

                            <ul className="plan_description">
                                {/*<li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">FREE 30-day trial</div>
                                            </li>*/}

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Live availability on your website,<br/> our website, and on your industry partners’ websites</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Availability link with live calendar for easy embedding (email signatures, marketing brochures, etc.)</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Boost your visibility with SEO-driven marketing: blogs, e-blasts, social</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Planner contact info included with each inquiry</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Unlimited inquiries from planners</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Seamless calendar integration</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Personalized profile optimization assessment</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Exclusive listing on FIRST-HOLD’s marketplace</div>
                                </li>
                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Dedicated email support</div>
                                </li>
                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">No contracts, cancel anytime, hassle-free</div>
                                </li>
                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Zero commission fees – ever!</div>
                                </li>

                                <li>
                                    <div className="blue_icon_div"></div>
                                    <div className="text"><b>PLUS:</b></div>
                                </li>
                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Featured venue on homepage for 60 days</div>
                                </li>
                                <li>
                                    <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">Exclusive e-blast to our network of 8000+ planners</div>
                                </li>

                                <li className="list-text-center">
                                    {/* <div className="blue_icon_div"><img alt="" className="blue_icon" src={require('../assets/images/approval-small.png')} /></div> */}
                                    <div className="text red">$1440 savings with two-year plan!</div>
                                </li>
                                {/* <li>
                                    <div className="blue_icon_div"><img className="blue_icon" src={require('../assets/images/approval-small.png')} /></div>
                                    <div className="text">
                                        Unlimited
                                    <span className={classes.activeIcon} onMouseOver={(e) => { this.handleHelpIconClick(e, 3) }} onMouseOut={(e) => { this.handleCloseHelpIcon(3) }}> API</span> Usage (Spread the word about your availability)
                                </div>
                                    <Popper className={classes.popper} open={this.state.openToggle3} anchorEl={this.state.anchorEl3} placement="right-end">
                                        <Paper classes={{ root: classes.root }}>
                                            <Typography className={classes.typography}>An API easily allows for integration to the venues website, preferred caterers, suppliers, etc.</Typography>
                                        </Paper>
                                    </Popper>
                                </li> */}

                            </ul>
                            {!localStorage.getItem('disable_plan_selection') &&
                                <div className="red-section-3">
                                    <Button variant="outlined" color="secondary" className="button-bg" onClick={(e) => { this.selectPlan(3) }}>
                                        {/* {this.state.planType === 'trial' ? 'Start Trial' : this.state.planType === 'paid' ? 'Purchase Plan' : 'Start Listing'} */}
                                        Select
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                    <p className="small-font-text">
                        <span>Multiple venues? </span>
                        <span>Bundle for savings</span>
                        <span><a href="mailto:hello@first-hold.com">Contact us!</a></span>
                    </p>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.data.usersVenue.userInfo,
    };
};


const enhance = compose(
    withStyles(styles),
    withRouter,
    connect( mapStateToProps,
        dispatch => bindActionCreators({
            createPlan,
            userDetails,
            updatePlan
        }, dispatch),
    )
)

export default enhance(withRouter(VenuePlan));

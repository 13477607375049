import { UPDATE_PLAN } from 'shared/state/types';
import { request } from 'shared/auth';

function updatePlanRequested() {
  return {
    type: UPDATE_PLAN.REQUEST,
  };
}

function updatePlanFailed(error) {
  return {
    type: UPDATE_PLAN.FAILURE,
    error:error,
  };
}

function updatePlanSuccess(data, message) {
  return {
    type: UPDATE_PLAN.SUCCESS,
    payload: data,
    message: message
  };
}

export function updatePlan(planId, params,msg) {
  return dispatch => {
    dispatch(updatePlanRequested());

    return request().put(`user_plans/${planId}?${params}`)
      .then(response =>{
        if(msg === 'show'){
          dispatch(updatePlanSuccess(response.data.data,'Plan has been successfully updated.'))
        }
        else if(msg === 'hide'){
          dispatch(updatePlanSuccess(response.data.data, ''))
        }
          return response.data.data;
        }
      )
      .catch(errors => {
        if(errors.response && errors.response.data.message){
          dispatch(updatePlanFailed(errors.response.data.message));
        }
        else{
          dispatch(updatePlanFailed('Not able to access api'));
        }
        throw errors;
      });
  };
}

import { request } from 'shared/auth';
import { GET_ALL_EMAILS } from 'shared/state/types';

function getAllEmailsRequest() {
    return {
        type: GET_ALL_EMAILS.REQUEST
    };
}
function getAllEmailsSuccess(data) {
    return {
        type: GET_ALL_EMAILS.SUCCESS,
        emails: data,
    };
}
function getAllEmailsFailure(error) {
    return {
        type: GET_ALL_EMAILS.FAILURE,
        error: error
    };
}

export function getAllEmails(params) {
    return dispatch => {
        dispatch(getAllEmailsRequest());
        return request()
            .post('auth/all_emails',params)
            .then(response => {
                dispatch(getAllEmailsSuccess(response.data));
                return response.data
            })
            .catch(error => {
                if(error.response.data && error.response.data.message){
                    dispatch(getAllEmailsFailure(error.response.data.message));
                    throw error
                }
                else{
                    dispatch(getAllEmailsFailure("Unable to access api"));
                }
            })
    };
}

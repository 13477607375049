import { ADD_VENUE } from 'shared/state/types';
import { request } from 'shared/auth';

function addVenueRequested() {
  return {
    type: ADD_VENUE.REQUEST,
  };
}

function addVenueFailed(error) {
  return {
    type: ADD_VENUE.FAILURE,
    error:error,
  };
}

function addVenueSuccess(data) {
  return {
    type: ADD_VENUE.SUCCESS,
    venueData: data,
  };
}

export function addVenue(params) {
  return dispatch => {
    dispatch(addVenueRequested());

    return request().post('/venues', params)
      .then(response =>{
          dispatch(addVenueSuccess(response.data.data))
          return response.data.data
        }
      )
      .catch(errors => {
        dispatch(addVenueFailed(errors.response.data.errors));
        throw errors;
      });
  };
}

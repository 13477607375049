import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { getSpace, addSpaceImage, deleteSpaceImage, getSpaceAvailability } from 'data/spaces/actions';
import { getVenue, updatePhotographer } from 'data/venues/actions';
import TopImageComponent from '../spaceProfile/components/topImageComponent';
import SpaceProfileEditComponent from './components/spaceProfileEditComponent';
import SpacePlannerEdit from './components/spacePlannerEdit';
import ButtonsHeader from './components/buttonsHeader';
import * as Yup from 'yup';
import { updateSpace } from 'data/spaces/actions';
import { Formik, Form } from 'formik';
import { Grid } from '@material-ui/core';
import { bindActionCreators, compose } from "redux";
import { object, func } from "prop-types";
import { venueProfile } from "shared/venue/actions";
import { getUploadPostUrl, uploadtoS3 } from 'data/master/actions';
// import { saveExit } from '../shared/saveExit/actions';
import getCroppedImg from '../cropImage';
import ErrorPopup from '../shared/components/errorPopup';

const logo = require("./assets/images/default_venue.png")

// const markers = [
//   { id: 1, latitude: -34.397, longitude: 150.644 },
//   { id: 2, latitude: -40.497, longitude: 150.648 },
//   { id: 3, latitude: -51.337, longitude: 150.244 }
// ]
const FILE_SIZE = 1024 * 1024 * 3;
let eventGroup = [
  { date: 'Oct 02 2019', name: 'EVENT 1', holdStatus: '1st hold', eventType: 'Corporate Event', startTime: '09:00', endTime: '15:00', price: '10,000', additionalPrice: '200' },
];
let error = [];

class SpaceProfileEdit extends Component {
  state = {
    loader: false,
    descriptionError: false,
    editspaceErrorMessage: '',
    editspaceError: false,
    copy: false,
    dimension_error: '',
    allError: '',
    area_error: '',
    errorCrop: '',
    redirect: '',
    cropImage: false,
    imageUrl: '',
    imageFileData: '',
    rotation: 0,
    cropping: false,
    cropDone: false,
    cropData: '',
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 7 / 3,
    croppedAreaPixels: null,
    croppedImage: null,
    getResponse: false,
    openImageUploadForm: false,
    imageUploaded: false,
    imageUploading: false,
    imageFile: [],
    imageFileId: [],
    venueImageError: '',
    xCoordinate: '',
    yCoordinate: '',
    eventInfo: {},
    open: false,
    eventDay: '',
    eventType: '',
    eventTime: '',
    eventRates: '',
    day: '',
    venueDetails: {},
    markers: [],
    spaceId: '',
    imageFileName: [],
    images: [],
    spaceImage: '',
    spaceData: this.props.spaceData,
    pdfData: [],
    videos: [],
    onUploadImage:false,
    onDeleteImage:false,
  }

  UNSAFE_componentWillMount() {
    this.setState({ loader: false })
    if (this.props.match.params.spaceId) {
      this.props.getSpace(this.props.match.params.spaceId).then((response) => {
        this.props.getVenue(response.venue_id).then(() => {
          this.setState({ getResponse: true })
        })
      })
        .catch(error => this.setState({ getResponse: 'error' }))
    }
    this.setState({ spaceId: this.props.match.params.spaceId })
    this.props.getSpaceAvailability(this.props.match.params.spaceId)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {

    var venueObj = this.state.venueDetails;
    var markersArray = this.state.markers;
    if (this.props.venueDetails !== nextProps.venueDetails && Object.keys(nextProps.venueDetails).length > 0) {
      const venue = nextProps.venueDetails
      //const space = nextProps.spaceData
      venueObj.venue = venue.name
      venueObj.address = `${venue.address.line_1}${venue.address.line_2 !== null ? venue.address.line_2 : ''}, ${venue.address.city}, ${venue.address.state} ${venue.address.zip}`
      const markersObj = { id: venue.id, latitude: parseFloat(venue.address.geo_lat), longitude: parseFloat(venue.address.geo_lng) }
      markersArray.push(markersObj)
    }
    this.setState({ markers: markersArray })
    if(this.state.onDeleteImage==false){
    if (this.props.spaceData !== nextProps.spaceData && Object.keys(nextProps.spaceData).length !== 0) {
      if (this.state.copy !== true) {
        this.setState({ pdfData: nextProps.spaceData.documents, videos: nextProps.spaceData.videos });
      }
      const space = nextProps.spaceData
      venueObj.name = space.name
      if (space.photo && space.photo.length > 0) {

        let newArray = [], imageIdArray = [], newFileNameArray = []
        //changes
        let imageArray = []
        //chnages
        space.photo.map((data, idx) => {
          if (idx === 0) {
            this.setState({ spaceImage: `${process.env.REACT_APP_MEDIA_URL}${data.path}` })
          }
          newArray.push(`${process.env.REACT_APP_MEDIA_URL}${data.path}`)
          imageIdArray.push(data.id)
          newFileNameArray.push(data.name)
          const imageArrayLen = imageArray.length
          imageArray.push({ imageId: imageArrayLen + 1, path: `${process.env.REACT_APP_MEDIA_URL}${data.path}`, id: data.id, name: data.name, p_name: data.p_name, order: data.order, p_website: data.p_website, uploading: false, uploaded: true }); return imageArray;
        })
        /*this.setState({
          imageFile: newArray,
          imageUploading: false,
          imageFileId: imageIdArray,
          imageUploaded: true,
          venueImageError: '',
          imageFileName: newFileNameArray,
          images: imageArray
        });*/
        
        if(this.state.onUploadImage==true){
          this.setState({
          imageFile:this.state.imageFile.concat(newArray.slice(-1)),
          imageFileId:this.state.imageFileId.concat(imageIdArray.slice(-1)),
          imageFileName:this.state.imageFileName.concat(newFileNameArray.slice(-1)),
          imageUploading: false,        
          imageUploaded: true,
          images:this.state.images.slice(0,-1).concat(imageArray.slice(-1)),
          onUploadImage:false,
          onDeleteImage:false

        })
        }
        else{
          this.setState({
          imageFile: newArray,
          imageUploading: false,
          imageFileId: imageIdArray,
          imageUploaded: true,
          venueImageError: '',
          imageFileName: newFileNameArray,
          images: imageArray
        });
        }
      }
      else {
        this.setState({ spaceImage: '' })
        this.setState({
          imageFile: [],
          imageFileId: [],
          images: []
        });
      }
    }
  }
    this.setState({ venueDetails: venueObj })
  }
  // UNSAFE_componentWillReceiveProps(nextProps){
  //   if (this.props.venueDetails !== nextProps.venueDetails) {
  //   }
  // }
  onCropChange = crop => {
    this.setState({ crop })

  }


  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels })
    // this.showCroppedImage()
  }

  okButton = async () => {
    this.setState({
      image_upload: false, errorCrop: '', cropImage: false,
      imageUploading: false,
      imageUploaded: true,
    })
    try {
      let imageArray = this.state.images
      const imageArrayLen = imageArray.length
      imageArray.push({ imageId: imageArrayLen + 1, path: '', id: '', name: '', uploading: true, uploaded: false })

      this.setState({ cropping: true })
      const image = this.state.imageFileData ? this.state.imageFileData : this.state.imageUrl
      const croppedImage = await getCroppedImg(
        image,
        this.state.croppedAreaPixels,
        this.state.rotation
      )
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = document.getElementById("scream");
      var prop = this;
      img.src = croppedImage;
      this.setState({
        cropImage: false
      })
      img.onload = () => {
        c.width = img.width;
        c.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        c.toBlob(function (blob) {
          var f = new File([blob], "sample.jpg", { type: "application/octet-stream" });
          prop.setState({ cropData: f });
          prop.setState({ cropDone: true, imageUrl: image, cropping: false });
          prop.props.getUploadPostUrl(1, 'sameimage.jpg').then((res) => {
            prop.props.uploadtoS3(res.url, f).then(() => {
              var formData = new FormData();
              formData.append("photo", res.keyName)
              formData.append("name", 'name')

              prop.props.addSpaceImage(prop.state.spaceId, formData)
                .then((response) => {
                  prop.props.getSpace(prop.props.match.params.spaceId,1).then((res) => {
                    prop.props.getVenue(res.venue_id)
                  })
                  if (response.id) {
                    let newArray = prop.state.imageFile
                    let imageIdArray = prop.state.imageFileId
                    newArray.push(`${process.env.REACT_APP_MEDIA_URL}${response.path}`)
                    imageIdArray.push(response.id)
                    prop.setState({
                      cropImage: false,
                      imageFile: newArray,
                      imageUploading: false,
                      imageFileId: imageIdArray,
                      imageUploaded: true,
                      venueImageError: '',
                      //images: this.state.imageArray,

                    });


                  }
                })
                .catch(error => { })
            })
          })
        }, "image/jpeg", 0.75)
        this.setState({onUploadImage:true,onDeleteImage:false})
        //this.setState({onDeleteImage:false})
      }
      this.setState({ croppedImage: croppedImage, cropping: false })

    } catch (e) {
    }
  }

  cancelButton = (setFieldValue) => {

    this.setState({
      image_upload: false,
      errorCrop: '',
      cropping: false,
      cropImage: false,
      cropDone: true,
      imageUrl: '',
      imageUploading: false,
      imageUploaded: true,
      imageFileName: [],
      venueImageError: '',
      imageFileData: ''
    });
  }

  onZoomChange = event => {
    this.setState({ zoom: event.currentTarget.value })
  }
  onRotationChange = event => {
    this.setState({ rotation: event.currentTarget.value })
  }

  handleEditPhotosClick = () => {
    error = [];
    if(this.state.openImageUploadForm===false)
    {
    this.props.getSpace(this.props.match.params.spaceId,1).then((res) => {
    this.setState(prevState => ({
      openImageUploadForm: !prevState.openImageUploadForm
    }));
  })
  }
  else
  {
    this.setState(prevState => ({
      openImageUploadForm: !prevState.openImageUploadForm
    }));
  }
  }
  handleImageChange = (event, setSubmitting) => {
    error = [];
    this.setState({ zoom: 1, rotation: 0 })

    const len = Object.keys(event.target.files).length
    this.setState({ image_upload: true })
    let i
    for (i = 0; i <= len - 1; i++) {

      if (event.target.files[i].name.match(/.(jpg|jpeg)$/i) && (event.target.files[i].size <= FILE_SIZE)) {
        // let imageArray = this.state.images
        //const imageArrayLen = imageArray.length
        // imageArray.push({imageId:imageArrayLen + 1, path:'', id: '', name: '', uploading: true, uploaded: false})
        // let newArray = this.state.imageFile
        let newFileNameArray = this.state.imageFileName
        // newArray.push(URL.createObjectURL(event.target.files[i]))
        newFileNameArray.push(event.target.files[i].name)
        this.setState({
          imageUploading: false,
          imageFileName: newFileNameArray,
          imageUploaded: true,
          venueImageError: '',
          imageFileData: URL.createObjectURL(event.target.files[i])

        });
        var formData = new FormData();
        let img = event.target.files[i];
        formData.append("name", img.name);

        //image code
        // this.props.getUploadPostUrl(1,img.name).then((res) => {
        //   formData.append('photo',res.keyName)

        //   this.props.uploadtoS3(res.url, img).then( ()=>{


        //     this.props.addSpaceImage(this.state.spaceId, formData)
        //     .then((response) => {
        //       this.props.getSpace(this.props.match.params.spaceId).then((res) => {
        //         this.props.getVenue(res.venue_id)
        //       })
        //       if (response.id) {
        //         let newArray = this.state.imageFile
        //         let imageIdArray = this.state.imageFileId
        //         newArray.push(`${process.env.REACT_APP_MEDIA_URL}${response.path}`)
        //         imageIdArray.push(response.id)
        //         this.setState({
        //           imageFile: newArray,
        //           imageUploading: false,
        //           imageFileId: imageIdArray,
        //           imageFileName: newFileNameArray,
        //           imageUploaded: true,
        //           venueImageError: '',
        //           // images: this.state.imageArray
        //         });
        //       }
        //     })
        //     .catch(error => {})
        //   })

        //   })


      }
      if (!event.target.files[i].name.match(/.(jpg|jpeg)$/i)) {
        this.state.images.pop();
        this.setState({ image_upload: false })

        error.push("*Unsupported Format only jpg and jpeg images will be accepted");
        setSubmitting(true)
      }
      else if (event.target.files[i].size >= FILE_SIZE) {
        // this.state.images.pop();
        this.setState({ image_upload: false })

        error.push('*File size should not be greater than 3MB.');
        setSubmitting(true)
      }
      else {
        this.setState({
          cropImage: true,
        })
      }
    }
  }
  error = error.filter((ele, ind) => ind === error.findIndex(elem => elem === ele))

  deleteImage = (value) => {
    let imageArray=this.state.images
    let newArray = this.state.imageFile
    let imageIdArray = this.state.imageFileId
    let imageFileIndex = imageArray.findIndex((obj) => obj.id === value)
    let newFileNameArray = this.state.imageFileName
    
    if (imageFileIndex != -1) {
      // let id
      // id = imageIdArray[imageFileIndex]
      newArray.splice(imageFileIndex, 1);
      imageIdArray.splice(imageFileIndex, 1);
      newFileNameArray.splice(imageFileIndex, 1);
      imageArray.splice(imageFileIndex,1)
     this.setState({
      onDeleteImage:true
    })
    }
    this.setState({
      imageFile: newArray,
      imageFileId: imageIdArray,
      imageFileName: newFileNameArray,
      images:imageArray
    });

    this.props.deleteSpaceImage(this.state.spaceId, value)
      .then((response) => {
        this.props.getSpace(this.props.match.params.spaceId,1).then((res) => {
          this.props.getVenue(res.venue_id)
        })
      })
      .catch(error => { })
  }
  deleteByName = (value) => {
    let newArray = this.state.imageFile
    let newFileNameArray = this.state.imageFileId

    let fileNameIndex = newFileNameArray.indexOf(value)
    if (fileNameIndex !== -1) {
      newArray.splice(fileNameIndex, 1);
      newFileNameArray.splice(fileNameIndex, 1);
    }
    this.setState({
      imageFile: newArray,
      imageFileId: newFileNameArray
    });
  }
  handleImageUpload = () => {
    let link = document.getElementById('image');
    link.click();

  }
  updateName = (event, index) => {
    let photographerName = this.state.images
    photographerName[index]['p_name'] = event
    this.setState({ images: photographerName })
  }
  updateWebsite = (event, index) => {
    let websiteName = this.state.images
    websiteName[index]['p_website'] = event
    this.setState({ images: websiteName })
  }

  updateImagesOrder = (images) => {
    let orderImages = this.state.images;
    //console.log('oooooo', orderImages)
    //console.log('images', images)
    if ('source' in images) {
      orderImages[images.source.index]['order'] = images.destination.index + 1;
      orderImages[images.destination.index]['order'] = images.source.index + 1;
    }
    this.setState({ images: orderImages });
    //console.log('nnnnnn', orderImages)

  }



  handleSubmit = (values, actions) => {
    if (this.state.image_upload === true) {
      if (this.state.cropping === true) {
        if (Object.keys(this.state.imageFile).length > 2 && this.state.venueImageError === '') {
          this.setState({ venueImageError: '' })
          actions.setSubmitting(false);
          this.handleEditPhotosClick();
          this.updatePhotographerDetail(values.photographer);
        }
      }
      else {
        this.setState({ errorCrop: "Please click crop" })
      }
    }
    else {
      if (Object.keys(this.state.imageFile).length > 2 && this.state.venueImageError === '') {
        this.setState({ venueImageError: '' })
        actions.setSubmitting(false);
        this.handleEditPhotosClick();
        this.updatePhotographerDetail(values.photographer);
      }
    }

  };

  updatePhotographerDetail = (params) => {
    this.props.updatePhotographer({ data: params }).then(res => {
      this.props.getSpace(this.props.match.params.spaceId).then((res) => {
        this.props.getVenue(res.venue_id)
      })
    });
  }

  getMouseCoordinate = (e) => {
    if (window.screen.width >= 500) {
      const xCoordinate = e.pageX - 260 + 'px'
      const yCoordinate = e.pageY - 220 + 'px'
      this.setState({
        xCoordinate: xCoordinate,
        yCoordinate: yCoordinate
      })
    }
    else {
      this.setState({
        xCoordinate: '',
        yCoordinate: ''
      })
    }
    this.setState({
      open: false,
      eventInfo: {}
    })
  }
  handleDayClick = (e) => {
    const date = e.toString();
    let formattedDate = date.substring(4, 15);
    let obj = eventGroup.find(data => data.date === formattedDate)
    if (obj) {
      const eventTime = `${moment(obj.startTime, 'HH:mm').format('hh:mm A')} - ${moment(obj.endTime, 'HH:mm').format('hh:mm A')}`
      const eventPrice = obj && `$${obj.price} + $${obj.additionalPrice}/addtional hour`
      this.setState({
        open: true,
        eventInfo: obj,
        day: moment(e).format('MMMM Do'),
        eventTime: eventTime,
        eventType: obj.eventType,
        eventPrice: eventPrice
      })
    }
    else {
      this.setState({
        open: false,
        eventInfo: obj
      })
    }
  }

  redirectLink = () => {
    this.setState({ redirect: 'googleSync' });
  }

  redirectMSLink = () => {
    this.setState({ redirect: 'microsoftSync' });
  }


  handleSave = (funcH, save) => {
    this.setState({ errorOpenFun: funcH })
    document.getElementById('submitSave').click();
    //let prop=this;
    // setTimeout(function(){ 
    //    prop.props.history.push('/myVenue');
    // },1500)

  }
  editProfileSave = (value) => {
    if (value.description === '') {
      this.setState({ editspaceError: true, editspaceErrorMessage: "Complete venue set-up before editing profile or you may lose data." })
    }
    else {
      this.setState({ loader: true })

      this.setState({ editspaceError: false, editspaceErrorMessage: "" })

      this.setState({ redirect: 'editprofile' })
      document.getElementById('submitSave').click();

      setTimeout(function () {
        window.location.assign('/editSpace/' + this.props.match.params.spaceId + '/1');
      }.bind(this), 5000)
    }
  }
  publishUnpublish = (id, json) => {
    this.props.updateSpace(id, json, 'spaceEditpub').then(() => {
    })
  }
  handleDocumentUploaded = (PDFData) => {
    this.props.getSpace(this.props.match.params.spaceId).then((response) => {
      this.setState({ pdfData: response.documents });
    })
  }
  copy = () => {
    this.setState({ copy: true })
  }
  copyFalse = () => {
    this.setState({ copy: false })
  }
  handleSaveClick(values, actions) {
    this.setState({ dimension_error: '', area_error: '', allError: '' })
    let error = false;
    if (values.description !== '' && values.description != null) {


      if (values.length !== '' || values.height !== '' || values.width !== '') {
        if (values.length !== '' && values.height !== '' && values.width !== '') {
          if (values.dimension_unit === '') {
            this.setState({ dimension_error: '*Required' })
            error = true;

          }
        }
        else {
          error = true;
          this.setState({ allError: "*All fields are required" })
        }

      }

      if (values.area !== '' && values.area_unit === '') {
        error = true;
        this.setState({ area_error: '*Required' })

      }
      if (error === false) {
        // let description=values.description.replace(/&/g,'and');
        // let data =  `description=${encodeURI(description)}${values.area_unit!==''?'&area_unit='+values.area_unit:''}${values.dimension_unit!==''?'&dimension_unit='+values.dimension_unit:''}&floor=${values.floor===''?0:values.floor}&length=${values.length===''?0:values.length}&width=${values.width===''?0:values.width}&height=${values.height===''?0:values.height}&area=${values.area===''?0:values.area}`
        let data = {};
        data.description = values.description;
        if (values.area_unit !== '') {
          data.area_unit = values.area_unit;
        }
        if (values.dimension_unit !== '') {

          data.dimension_unit = values.dimension_unit;
        }

        data.floor = values.floor === '' ? '0' : values.floor;


        data.length = values.length === '' ? '0' : values.length;


        data.width = values.width === '' ? '0' : values.width;


        data.height = values.height === '' ? '0' : values.height;


        data.width = values.width === '' ? '0' : values.width;


        data.area = values.area === '' ? '0' : values.area;


        this.props.updateSpace(this.props.match.params.spaceId, data, 'spaceEditpub').then(() => {


          if (this.state.redirect !== 'googleSync' && this.state.redirect !== 'microsoftSync' && this.state.redirect !== 'editprofile') {
            this.setState({ redirect: '' })
            window.location.href = '/myVenue';
          }
          else if (this.state.redirect == 'googleSync') {
            this.setState({ redirect: '' })
            localStorage.setItem('googleCalendarVal', 'justLooking');
            window.location.assign('/webCalendar/' + this.props.match.params.spaceId);
          }
          else if (this.state.redirect == 'microsoftSync') {
            this.setState({ redirect: '' })
            localStorage.setItem('microsoftCalendarVal', 'justLooking');
            window.location.assign('/webMicrosoftCalendar/' + this.props.match.params.spaceId);
          }
        })
      }



    }
    else {
      if (this.state.errorOpenFun) {
        this.state.errorOpenFun(['Description']);
      }
      this.setState({ descriptionError: true })
    }
  }
  syncCalendar = () => {
    var link = document.getElementById('syncCalendar');
    link.click();
  }
  handleDocumentDeleted = (PDFData) => {
    let arr = this.state.pdfData;
    const filtered = arr.filter((item) => item.id !== PDFData);
    this.setState({ pdfData: filtered })
  }
  errorClose = () => {
    this.setState({ editspaceError: false, editspaceErrorMessage: "" })

  }

  handleVideoAdd = (vData) => {
    this.props.getSpace(this.props.match.params.spaceId).then((response) => {
      this.setState({ videos: response.videos });
    })
    // let vArr = this.state.videos;
    // vArr.push(vData);
    // this.setState({ videos: vArr });
  }

  handleVideoDeleted = (videoId) => {
    let vArr = this.state.videos;
    const filtered = vArr.filter((item) => item.id !== videoId);
    this.setState({ videos: filtered });
  }

  handleVideoUpdate = (vData) => {
    let vArr = this.state.videos;
    let index = vArr.findIndex(x => x.id == vData.id);
    if (index > -1) {
      vArr[index] = vData;
      this.setState({ videos: vArr });
    }
  }



  render(props) {
    let line_2 = this.props.venueDetails.address &&
      this.props.venueDetails.address.line_2 != null ? this.props.venueDetails.address &&
    this.props.venueDetails.address.line_2 : '';
    const venueDetails = {
      image: this.props.spaceData.photo ? this.props.spaceData.photo && this.props.spaceData.photo[0] &&
        process.env.REACT_APP_MEDIA_URL + this.props.spaceData.photo[0].path : logo,
      address: this.props.venueDetails.address &&
        this.props.venueDetails.address.line_1 +

        ", " + line_2 +
        " " +
        this.props.venueDetails.address.city +
        " " +
        this.props.venueDetails.address.state_abbr +
        " " +
        this.props.venueDetails.address.zip,
      name: this.props.spaceData.name,
      venue: this.props.venueDetails.name
    };
    // const markers = [
    //   {
    //     id: 1,
    //     latitude:
    //       this.props.venueDetails.address &&
    //       parseFloat(this.props.venueDetails.address.geo_lat, 10),
    //     longitude:
    //       this.props.venueDetails.address &&
    //       parseFloat(this.props.venueDetails.address.geo_lng, 10)
    //   }
    // ];


    //const nl2br = require('react-nl2br');
    const description = this.props.spaceData.description;

    const ValidationSchema = Yup.object().shape({
      agree: Yup.boolean().oneOf([true], "*This must be checked."),
      photographer: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.number(),
            p_name: Yup.string().when("p_website", {
              is: (website) => { return website },
              then: Yup.string().required("*Required"),
            }).nullable(true),
            p_website: Yup.string().nullable(true).url('website url must be valid.'),
          })
        )
    });
    const ValidationSchemaOther = Yup.object().shape({

      description: Yup.string().nullable().max(1500, '*Should not be greater than 1500 characters'),
      length: Yup.number().typeError("*Must be a number")
        .min(1, "Greater than 0."),

      height: Yup.number().typeError("*Must be a number")
        .min(1, "Greater than 0."),

      area: Yup.number().typeError("*Must be a number")
        .min(1, "Greater than 0."),

      width: Yup.number().typeError("*Must be a number")
        .min(1, "Greater than 0."),



    });
    return (

      this.state.getResponse === true && this.props.venueDetails && this.state.markers.length > 0 ?
        Object.keys(this.props.spaceData).length !== 0 ?
          <div className="venue_page fh-container" onMouseDown={(e) => { this.getMouseCoordinate(e) }}>
            {this.state.editspaceError === true ?
              <ErrorPopup errorClose={this.errorClose} message={this.state.editspaceErrorMessage} openError={this.state.editspaceError} />
              : null}
            <img id="scream" style={{ display: 'none' }} alt="The Scream" crossOrigin="anonymous" />
            <canvas style={{ display: 'none' }} id="myCanvas"></canvas>
            <Grid xs={12} item>
              <ButtonsHeader
                syncCalendar={this.syncCalendar}
                publishUnpublish={this.publishUnpublish}
                handleSave={this.handleSave}
                name="spaceEdit"
                spaceDetails={this.props.spaceData}
                venueId={this.props.venueDetails.id}
                venueDetails={this.props.venueDetails}
                spaceId={this.props.match.params.spaceId} />

            </Grid>
            <Grid xs={12} item>
              <Formik
                initialValues={{
                  agree: false,
                  venueImage: [],
                  photographer: this.state.images.sort((a, b) => { return a.order - b.order })

                }}
                enableReinitialize={true}
                validationSchema={ValidationSchema}

                onSubmit={(values, actions) => {
                  this.handleSubmit(values, actions)
                }}
                render={formikProps => (
                  <Form onSubmit={formikProps.handleSubmit}>
                    <TopImageComponent
                      name="space"
                      errorCrop={this.state.errorCrop}
                      okButton={this.okButton}
                      cancelButton={this.cancelButton}
                      imageFileData={this.state.imageFileData}
                      imageUrl={this.state.imageUrl}
                      crop={this.state.crop}
                      zoom={this.state.zoom}
                      aspect={this.state.aspect}
                      rotation={this.state.rotation}
                      onRotationChange={this.onRotationChange}
                      onCropChange={this.onCropChange}
                      onCropComplete={this.onCropComplete}
                      onZoomChange={this.onZoomChange}
                      cropImage={this.state.cropImage}
                      handleChange={formikProps.handleChange}
                      spaceId={this.props.match.params.spaceId ? this.props.match.params.spaceId : null}
                      markers={this.state.markers}
                      error={error}
                      venueDetails={venueDetails}
                      editImage={true}
                      formikProps={formikProps}
                      openImageUploadForm={this.state.openImageUploadForm}
                      handleEditPhotosClick={this.handleEditPhotosClick}
                      imageFile={this.state.imageFile}
                      imageUploaded={this.state.imageUploaded}
                      imageFileName={this.state.imageFileName}
                      imageUploading={this.state.imageUploading}
                      handleImageUpload={this.handleImageUpload}
                      handleImageChange={this.handleImageChange}
                      deleteImage={this.deleteImage}
                      venueImageError={this.state.venueImageError}
                      deleteByName={this.deleteByName}
                      images={this.state.images.sort((a, b) => { return a.order - b.order })}
                      updateName={this.updateName}
                      updateWebsite={this.updateWebsite}
                      updateImagesOrder={this.updateImagesOrder}
                      spaceImage={this.state.spaceImage} />

                  </Form>
                )} />
            </Grid>
            <Grid xs={12} item className="midContainer">
              <Formik
                initialValues={{
                  area_unit: this.props.spaceData.area_unit ? this.props.spaceData.area_unit : '',
                  dimension_unit: this.props.spaceData.dimension_unit ? this.props.spaceData.dimension_unit : '',

                  height: this.props.spaceData.height && this.props.spaceData.height !== 0 ? this.props.spaceData.height : '',
                  length: this.props.spaceData.length && this.props.spaceData.length !== 0 ? this.props.spaceData.length : '',
                  width: this.props.spaceData.width && this.props.spaceData.width !== 0 ? this.props.spaceData.width : '',
                  floor: this.props.spaceData.floor && this.props.spaceData.floor !== '0' ? this.props.spaceData.floor : '',
                  description: description,
                  area: this.props.spaceData.area || this.props.spaceData.area !== 0 ? this.props.spaceData.area : ''
                }}
                validationSchema={ValidationSchemaOther}

                onSubmit={(values, actions) => {
                  this.handleSaveClick(values, actions)
                }}
                render={formikProps => (
                  <form onSubmit={formikProps.handleSubmit}>
                    <Grid container justify="center" className="justify">
                      <Grid item xs={12} sm={6} md={7} xl={8} lg={8}>

                        <SpaceProfileEditComponent
                          descriptionError={this.state.descriptionError}
                          loader={this.state.loader}
                          values={formikProps.values}
                          editProfileSave={this.editProfileSave}
                          dimension_error={this.state.dimension_error}
                          area_error={this.state.area_error}
                          allError={this.state.allError}
                          venueDetails={this.props.venueDetails}
                          // pdfData={this.state.pdfData} 
                          // copyFalse={this.copyFalse} 
                          // copy={this.copy} 
                          // handleDocumentUploaded={this.handleDocumentUploaded}
                          spaceData={this.props.spaceData}
                          spaceId={this.props.match.params.spaceId} />

                      </Grid>
                      <Grid item md={5} xs={12} sm={5} xl={4} lg={4}>
                        <SpacePlannerEdit
                          copyFalse={this.copyFalse}
                          copy={this.copy}
                          handleDocumentUploaded={this.handleDocumentUploaded}
                          handleVideoAdd={this.handleVideoAdd}
                          handleVideoUpdate={this.handleVideoUpdate}
                          venueDetails={this.props.venueDetails}
                          redirectLink={this.redirectLink}
                          redirectMSLink={this.redirectMSLink}
                          pdfData={this.state.pdfData}
                          videos={this.state.videos}
                          handleDocumentDeleted={this.handleDocumentDeleted}
                          handleVideoDeleted={this.handleVideoDeleted}
                          venue={this.props.venue}
                          spaceId={this.props.match.params.spaceId}
                          spaceDetails={this.props.spaceData}
                          handleDayClick={this.handleDayClick}
                          getMouseCoordinate={this.getMouseCoordinate}
                          xCoordinate={this.state.xCoordinate}
                          yCoordinate={this.state.yCoordinate}
                          eventInfo={this.state.eventInfo}
                          open={this.state.open}
                          eventDay={this.state.eventDay}
                          eventType={this.state.eventType}
                          eventTime={this.state.eventTime}
                          eventRates={this.state.eventRates}
                          day={this.state.day}
                          values={formikProps.values} />
                      </Grid>
                    </Grid>

                  </form>
                )}
              />
            </Grid>

          </div>
          : <div className="fourohfour"><h1>404</h1></div>
        :
        this.state.getResponse.toString() === 'error' ?
          <div className="fourohfour"><h1>404</h1></div>
          :
          <div className="fourohfour"><h2>Loading...</h2></div>


    );
  }
}


SpaceProfileEdit.propTypes = {
  classes: object.isRequired,
  getSpace: func.isRequired,
  venueProfile: func.isRequired,
  updateSpace: func.isRequired,


};

const mapStateToProps = state => {
  return {
    spaceData: state.data.spaces.spaceData,
    // venue: state.data.venue.venueDetails,
    venueDetails: state.data.venues.venueDetails,
    uploaded: state.data.spaces.uploaded,
    uploading: state.data.spaces.uploading
  };
};


const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        getSpace,
        venueProfile,
        getVenue,
        addSpaceImage,
        deleteSpaceImage,
        getUploadPostUrl,
        uploadtoS3,
        updateSpace,
        getSpaceAvailability,
        updatePhotographer
      },
      dispatch
    )
  )
);
export default enhance(SpaceProfileEdit);

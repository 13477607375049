

import axios from 'axios';
import { FIND_GOOGLE_CALENDAR_EVENTS_SINGLE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function getGoogleCalnderSingleEventsRequested() {
  return {
    type: FIND_GOOGLE_CALENDAR_EVENTS_SINGLE.REQUEST,
  };
}

function getGoogleCalnderSingleEventsSuccess(data) {
  return {
    type: FIND_GOOGLE_CALENDAR_EVENTS_SINGLE.SUCCESS,
    eventData: data,
  };
}

function getGoogleCalnderSingleEventsFailure(error) {
  return {
    type: FIND_GOOGLE_CALENDAR_EVENTS_SINGLE.FAILURE,
    error:error,
  };
}

const request = () => {
  const headers = {};

  if(localStorage.getItem('accesstoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('accesstoken');

  }
  const axiosInstance = axios.create({
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function getGoogleCalendarSingleEvents(id,recId) {
    return dispatch => {
      dispatch(getGoogleCalnderSingleEventsRequested());
        return request().get('https://www.googleapis.com/calendar/v3/calendars/'+id+'/events/'+recId)
        .then(response => {
          if(response.status === 200){
            dispatch(getGoogleCalnderSingleEventsSuccess(response.data))
            return response.data;
          }
          else if(response.status === 400){
            dispatch(getGoogleCalnderSingleEventsFailure("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(getGoogleCalnderSingleEventsFailure("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(getGoogleCalnderSingleEventsFailure("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(getGoogleCalnderSingleEventsFailure("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(getGoogleCalnderSingleEventsFailure("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(getGoogleCalnderSingleEventsFailure("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(getGoogleCalnderSingleEventsFailure("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(getGoogleCalnderSingleEventsFailure("The server was unavailable."));
          }
          else{
            dispatch(getGoogleCalnderSingleEventsFailure("The server was unavailable."));
          }
          dispatch (getGoogleCalnderSingleEventsSuccess(response.data),
          )
          return response.data;
        }
        ) 
        .catch(errors => {
          if(errors && errors){
            dispatch(getGoogleCalnderSingleEventsFailure(errors.toString()));
          }
          else{
             dispatch(getGoogleCalnderSingleEventsFailure("Something went wrong."));
          }

          return 0;
          
  
          
        });
      

    
 };
}

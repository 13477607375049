import { UPDATE_USER } from 'shared/state/types';
import { requestForUnauthorised } from 'shared/auth';

function updateUserRequested() {
  return {
    type: UPDATE_USER.REQUEST,
  };
}

function updateUserFailed(error) {
  return {
    type: UPDATE_USER.FAILURE,
    error:error,
  };
}

function updateUserSuccess(data) {
  return {
    type: UPDATE_USER.SUCCESS,
    payload: data,
  };
}

export function updateUser(params) {
  return dispatch => {
    dispatch(updateUserRequested());

    return requestForUnauthorised().post('/people/me', params)
      .then(response =>{
          dispatch(updateUserSuccess(response.data.data))
          return response.data.data;
        }
      )
      .catch(errors => {
        if(errors && errors.response){
          dispatch(updateUserFailed(errors.response.data.message));
        }
        else{
          dispatch(updateUserFailed('Unable to access api!'));
        }
        throw errors;
      });
  };
}

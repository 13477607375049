import { GET_VENUE } from 'shared/state/types';
import { request } from 'shared/auth';
import moment from 'moment';

function getRequested() {
  return {
    type:GET_VENUE.REQUEST,
  };
}

function getFailed(error) {
  return {
    type:GET_VENUE.FAILURE,
    error:error,
  };
}

function getSuccess(data) {
  return {
    type:GET_VENUE.SUCCESS,
    venueDetails: data,
  };
}


export function getHoldBookedSpaceEvents(id,year=moment().year()) {
  return dispatch => {
    dispatch(getRequested()); 
    var d = new Date();
    if(d.getFullYear()!=year){
      d.setDate(1);
    }
    d.setFullYear(year);
    let timeMin = d.getFullYear()+'-'+((parseInt(d.getMonth())+1)>9?(parseInt(d.getMonth())+1):'0'+(parseInt(d.getMonth())+1))+'-'+d.getDate();
    d.setDate(moment().add(2, 'years').subtract(1,'months').endOf('month').format("D"));
    d.setMonth(moment().add(2, 'years').subtract(1,'months').endOf('month').format("M")-1);
    let timeMax = (parseInt(d.getFullYear())+2)+'-'+((parseInt(d.getMonth())+1)>9?(parseInt(d.getMonth())+1):'0'+(parseInt(d.getMonth())+1))+'-'+d.getDate();
    return request().get(`spaces/${id}/eventTimeslots?timeMin=${timeMin.trim()}&timeMax=${timeMax.trim()}`)
      .then(response =>{
          dispatch(getSuccess(response.data.data))
          return response.data;
        }
      )
      .catch(errors => {
        if(errors.response && errors.response.data.message){
          dispatch(getFailed(errors.response.data.message));
        }
        else{
          window.location.href = './'
          dispatch(getFailed("Not able to find space events"));
        }
        throw errors;
      });
  };
}

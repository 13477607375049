import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {object} from "prop-types";

import {connect} from "react-redux";
import {bindActionCreators, compose} from 'redux';
import {openListYourSpace} from 'data/master/actions';
import RightSideDropMenu from 'shared/components/RightSideDropMenu';
import {userDetails} from "shared/venueList/actions";

import {
    AppBar,
    Icon,
    Toolbar,
    IconButton,
    MenuItem,
    Menu,
    Button
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import './../assets/styles/header.scss';
const logo = require("../../components/assets/images/user-default.png");

const styles = theme => ({
    root: {
        width: '100%'
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    logo: {      
      height:'56px',
    //   width: '100%',
      marginBottom:"-4px"
    },
    headercontainer: {
        width: '100%',
        maxWidth: '1500px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        display: 'none',
        [
            theme
                .breakpoints
                .up('sm')
        ]: {
            display: 'block'
        }
    },  
    sectionDesktop: {
        display: 'none',
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            display: 'none'
        }
    },
    appbar: {
        background: '#f7f7f7',
        position: 'fixed'
    },
    list: {
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        border: 'none',
        margin: '0px 19px 0px 19px',
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        color: '#202020',
        '&:focus': {
            background: "transparent"
        },
        '&:hover': {
            background: "transparent",
            color: '#202020',
            opacity: 0.9
        }
    },
    signUp: {
        fontSize: '14px',
        fontWeight: 'bold',
        border: 'none',
        margin: '0px 10px 0px 10px',
        textTransform: 'uppercase',
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        color: '#202020',
        '&:focus': {
            background: "transparent"
        },
        '&:hover': {
            background: "transparent",
            color: '#202020',
            opacity: 0.9
        }
    },
    logIn: {
        fontSize: '14px',
        fontWeight: 'bold',
        border: 'none',
        margin: '0px 15px 0px 19px',
        textTransform: 'uppercase',
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.14',
        letterSpacing: 'normal',
        color: '#202020',
        '&:focus': {
            background: "transparent"
        },
        '&:hover': {
            background: "transparent",
            color: '#202020',
            opacity: 0.9
        }
    },
    tryForFree: {
      fontSize: '14px',
      fontWeight: 'bold',
      border: 'none',
      margin: '2px 15px 0px 19px',
      padding:'6px 10px',
      textTransform: 'uppercase',
      fontFamily: 'Raleway',
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      color: '#ffffff',
      background: "#d80e2a !important",
      borderRadius:'4px',
      '&:focus': {
        background: "#d80e2a"
      },
      '&:hover': {
          background: "#d80e2a",
          color: '#ffffff',
          opacity: 0.9
      }
    },
    selected: {        
       backgroundColor: 'transparent !important'
    },
    overWriteLink: {
        textDecoration: 'none',
        color: '#fffff'
    }
    ,chooseVenue:{
        fontSize: '14px',
        fontWeight:'500',
        border: '1px solid #747474',
        marginTop: '8px',
        marginRight:'-8px',
        padding:'8px',
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        color: '#747474',
        background: "#ffffff",
        borderRadius:'4px'
    }
});

class StickyHeader extends React.Component {
    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null
    };

    UNSAFE_componentWillMount() {
        this
            .props
            .userDetails()
            .then(() => {})
    }

    handleProfileMenuOpen = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleMenuClose = () => {
        this.setState({anchorEl: null});
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({mobileMoreAnchorEl: event.currentTarget});
    };

    handleMobileMenuClose = (linkUrl) => {
        if (linkUrl === 'register') {
            this
                .props
                .history
                .push('./register')
        } else if (linkUrl === 'login') {
            this
                .props
                .history
                .push('./login')
        } else if (linkUrl === 'logout') {
            this
                .props
                .history
                .push('./logout')
        }
        this.setState({mobileMoreAnchorEl: null});
    };

    openListSpaceLink = () => {
        this.handleMobileMenuClose()
    }
    render() {
        const {mobileMoreAnchorEl} = this.state;
        const {classes} = this.props;
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
                open={isMobileMenuOpen}
                onClose={this.handleMenuClose}>
                {/*(localStorage.getItem('fhtoken') !== null)
                    ? (localStorage.getItem('type') === '1')
                        ? (localStorage.getItem('fhtoken') === null)
                            ? <MenuItem component={Link} to="/planners" onClick={this.openListSpaceLink}>
                                    <p>For Planners</p>
                                </MenuItem>
                            : <MenuItem component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
                                    <p>My Venues</p>
                                </MenuItem>
                        : <MenuItem component={Link} to="/myEvents">
                                <p>My Events</p>
                            </MenuItem>
                    : (localStorage.getItem('fhtoken') === null)
                            ? <MenuItem component={Link} to="/planners" onClick={this.openListSpaceLink}>
                                    <p>For Planners</p>
                                </MenuItem>
                            : <MenuItem component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
                                <p>My Venues</p>
                            </MenuItem>
                */}

                {(localStorage.getItem('fhtoken') !== null && localStorage.getItem('type') === '1')
                    ?   <MenuItem component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
                            <p>My Venues</p>
                        </MenuItem>
                    :   <MenuItem component={Link} to="/planners" onClick={this.openListSpaceLink}>
                        <p>For Planners</p>
                        </MenuItem>
                }

                {(localStorage.getItem('fhtoken') !== null && localStorage.getItem('type') === '0')
                    ?   <MenuItem component={Link} to="/myEvents" onClick={this.openListSpaceLink}>
                            <p>My Events</p>
                        </MenuItem>
                    :   <MenuItem component={Link} to="/" onClick={this.openListSpaceLink}>
                        <p>For Venues</p>
                        </MenuItem>
                }

                {
                (localStorage.getItem('fhtoken') === null) && 
                    <MenuItem component={Link} to="/venuePlanList" onClick={this.openListSpaceLink}>
                    <p>Pricing</p>
                    </MenuItem>
                }

                {(localStorage.getItem('fhtoken') !== null)
                    ? <MenuItem onClick={() => this.handleMobileMenuClose('logout')}>
                            <p>Logout</p>
                        </MenuItem>
                    : <MenuItem onClick={() => this.handleMobileMenuClose('login')}>
                        <p>Login</p>
                    </MenuItem>
                }

                {
                (localStorage.getItem('fhtoken') === null) && 
                    <MenuItem component={Link} to="/register" onClick={this.openListSpaceLink}>
                        <p>Let's Get Started!</p>
                    </MenuItem>
                }

            </Menu>
        )

        return (
            <div className={classes.root}>
                {
      this.props.location.pathname === ('/help') || this.props.location.pathname.includes ('/help/search') || this.props.location.pathname.includes('/help/article') || this.props.location.pathname === ('/help/articles')||this.props.location.pathname.includes('/blogs') ||this.props.location.pathname.includes('/blog') ||this.props.location.pathname.includes('/blog_detail')  ? null:
            (   <AppBar className={classes.appbar} position="sticky">
                    <Toolbar className={classes.headercontainer}>
                        <Link className={classes.overWriteLink} to='/'>
                            <img
                                className={classes.logo}
                                src={require('./../assets/images/LogoWithText.png')}
                                alt="FIRST-HOLD"
                                title="FIRST-HOLD"/>
                        </Link>
                        <div className={classes.grow}/>

                        <div className={classes.sectionDesktop}>
                            {
                              (localStorage.getItem('fhtoken') !== null && localStorage.getItem('type') === '1') && 
                              <MenuItem
                                  className={classes.list}
                                  component={Link}
                                  to="/myVenue"
                                  onClick={this.openListSpaceLink}>
                                  <p>My Venues</p>
                              </MenuItem>
                            }

                            {
                              (localStorage.getItem('fhtoken') !== null && localStorage.getItem('type') === '0') && 
                              <MenuItem
                                  className={classes.list}
                                  classes={{ selected: classes.selected }}
                                  disableGutters={true}
                                  component={Link}
                                  to="/myEvents"
                                  title="My Events">My Events</MenuItem>
                            }

                            {                            
                            (localStorage.getItem('fhtoken') === null) && 
                              <MenuItem
                                className={classes.signUp}
                                classes={{ selected: classes.selected }}
                                disableGutters={true}
                                selected={this.props.location.pathname === '/planners'}
                                component={Link}
                                to="/planners"
                                title="For Planners">For Planners</MenuItem>
                            }

                            {
                              (localStorage.getItem('fhtoken') === null) && <MenuItem
                                  className={classes.logIn}
                                  classes={{ selected: classes.selected }}
                                  disableGutters={true}
                                  selected={this.props.location.pathname === '/'}
                                  component={Link}
                                  to="/"
                                  title="For Venues">For Venues</MenuItem>
                            }

                            {                            
                            (localStorage.getItem('fhtoken') === null) && <MenuItem
                              className={classes.logIn}
                              classes={{selected: classes.selected}}
                              disableGutters={true}
                              selected={this.props.location.pathname === '/venuePlanList'}
                              component={Link}
                              to="/venuePlanList"
                              title="Pricing">Pricing</MenuItem>
                            }

                            {
                            
                              (localStorage.getItem('fhtoken') === null) && <MenuItem
                                className={classes.logIn}
                                classes={{selected: classes.selected}}
                                disableGutters={true}
                                selected={this.props.location.pathname === '/login'}
                                component={Link}
                                to="/login"
                                title=" Login">Login</MenuItem>
                            }

                            {
                            
                            (localStorage.getItem('fhtoken') === null) && <MenuItem
                              className={classes.tryForFree}                             
                              disableGutters={true}
                              selected={this.props.location.pathname === '/register'}
                              component={Link}
                              to="/register"
                              title="Try for Free">Let's Get Started!</MenuItem>
                            }

                        </div>
                        {
                          (localStorage.getItem('fhtoken') !== null) && <IconButton color="inherit" className="homeheader-icon">

                            {
                                this.props.userData.data && this.props.userData.data.profilePhoto
                                ? <RightSideDropMenu
                                        profilePhoto={this.props.userData.data && this.props.userData.data.profilePhoto.path}/>
                                : <RightSideDropMenu profilePhoto={logo}/>
                            }
                            </IconButton>
                        }
                        <div className={classes.sectionMobile}>
                            { (localStorage.getItem('fhtoken') === null && this.props.location.pathname !== '/planners') && <MenuItem
                              className={classes.chooseVenue}                             
                              disableGutters={true}
                              component={Link}
                              to="/planners"
                              title="Choose a Venue">Choose a Venue</MenuItem>
                            }
                            <IconButton
                                aria-haspopup="true"
                                onClick={this.handleMobileMenuOpen}
                                color="black">
                                <Icon>more_vert</Icon>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
            )}
                {renderMobileMenu}
            </div>
        );
    }
}

StickyHeader.propTypes = {
    classes: object.isRequired
};
const mapStateToProps = state => {
    return {userData: state.data.usersVenue.userInfo};
};

const enhance = compose(withStyles(styles), withRouter, connect(mapStateToProps, dispatch => bindActionCreators({
    openListYourSpace,
    userDetails

}, dispatch)));
export default enhance(StickyHeader);

import React from 'react';
import '../assets/style/press.scss';
import { Grid } from '@material-ui/core';
import "react-alice-carousel/lib/alice-carousel.css";
import Footers from '../../landingPage/components/footer';;

function presslaunch(props) {
    return (
        <div className="press-page-ui">
            <div className="press-launch-ui container">
                <Grid container className="banner-section">                                        
                    <Grid className="banner-wrapper" item xs={12} sm={8} md={9} lg={9} xl={9}>
                        <div className="search-wrapper">
                            <div className="heading-text main">
                                <p>FIRST-HOLD Launches Event Venue Live Availability Platform in the San Francisco Bay Area</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <div className="logo-image">
                            <img className="" src={require('shared/components/assets/images/LogoWithText.png')} alt="FIRST-HOLD"/> 
                        </div>
                    </Grid>
                </Grid> 
                <Grid container className="social-block">                                        
                    <Grid className="banner-wrapper" item xs={12} sm={8} md={9} lg={9} xl={9}>
                        <div className="search-wrapper">
                            <div className="heading-text main">
                                <p class="light-text">News provided by</p>
                                <p class="bold-text">FIRST-HOLD, Inc.</p>
                                <p class="light-text">Mar 31, 2023, 09:00 ET</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <div className="logo-image">
                            <p className="light-text">Share This Article</p>
                            <ul class="social-icon-block">
                                <li>
                                    <a href="https://www.facebook.com/">
                                        <img className="" src={require('../assets/images/facebook.png')} alt="FACEBOOK"/> 
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/">
                                        <img className="" src={require('../assets/images/twitter.png')} alt="TWITTER"/> 
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/">
                                        <img className="" src={require('../assets/images/linkedin.png')} alt="LINKEDIN"/> 
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.pinterest.com/">
                                        <img className="" src={require('../assets/images/pinterest.png')} alt="PINTEREST"/> 
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:?subject=FIRST-HOLD Press Release | For Immediate Release&body=https://first-hold.com/press-san-francisco-launch">
                                        <img className="" src={require('../assets/images/email.png')} alt="EMAIL"/> 
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid> 
                <div className="press-launch-description">
                    <p>FOR IMMEDIATE RELEASE</p>
                    <p>SAN FRANCISCO, CA – March 31, 2023.  – FIRST-HOLD, a leading provider of live availability technology for event venues, is proud to announce the launch of its available date’s platform in the San Francisco Bay Area. The platform is designed to streamline the event booking process and connect people planning events to the perfect venue for their needs.</p>
                    <p>The San Francisco Bay Area is home to a vibrant and diverse event scene, with a wide range of venues available for everything from meetings and conferences to weddings and private events. However, navigating the complex and fragmented market can be a challenge for event organizers, particularly those new to the area or unfamiliar with the local venues.</p>
                    <p>The FIRST-HOLD platform offers a comprehensive solution, providing event planners access to a database of unique venues across the San Francisco Bay Area. The platform's user-friendly interface allows event planners to search and filter venues based on their specific needs, including location, capacity, and date availability.</p>
                    <p>In addition to venue search and date availability functionality for planners, the platform offers venue managers a stress-free way to keep their calendars current by integrating with powerful calendar platforms like Google Calendar, Microsoft Outlook, Tripleseat and any other API based platform.  The goal is to make it simple for venues to keep their calendar current through seamless integration.</p>
                    <p>"We are thrilled to launch our live availability platform in the San Francisco Bay Area" said Jennifer Brown, President of FIRST-HOLD. "Our goal is to make it easier for event planners to discover the perfect venue for their event and instantly check availability.  With our comprehensive platform and expert support team, we believe we can help take the San Francisco Bay Area event scene to the next level." 
                        For more information on FIRST-HOLD and its live availability platform, please visit <a href="https://www.first-hold.com/">www.first-hold.com.</a></p>
                    <p>For more information, contact: <a href="mailto:hello@first-hold.com">hello@first-hold.com</a></p>
                </div>
            </div>
           <Footers />
        </div>
    );
}
export default presslaunch;

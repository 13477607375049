import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import ImageUpload from '../../../shared/components/ImageUpload';
import CustomizedButton from '../../../shared/components/customizedButton';
import { withRouter } from "react-router-dom";
import { useFormikContext, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import CustomInput from '../../../shared/components/CustomInput';
import { mobileExist } from 'shared/auth/actions';
import '../../assets/styles/auth.scss';
// const FILE_SIZE = 1024 * 1024 * 10;

const styles = theme => ({

  signupForm: {
    margin: '30px auto',
    width: '430px',
    [theme.breakpoints.up('xs')]: {
      padding: '0 25px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 11px',
    },
    borderRadius: '6px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderImageSource: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0))'
  },
  formFields: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '89%'
  },
  signupDiv: {
    display: 'table',
    margin: '0 auto'
  },
  signupButton: {
    fontSize: '15px',
    width: '130px',
    height: '36px',
    borderRadius: '6px',
    backgroundImage: 'linear-gradient(#f1435e, #ee2738)',
    color: '#ffffff',
    fontWeight: 500,
    border: 'none',
    marginBottom: '30px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '34px',
    letterSpacing: 'normal',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    marginTop: '30px'
  },
  greySignupButton: {
    fontSize: '13px',
    width: '130px',
    height: '36px',
    borderRadius: '6px',
    color: '#ffffff',
    fontWeight: 500,
    border: 'none',
    marginBottom: '30px',
    backgroundColor: '#9aa7af',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginTop: '30px'
  },
  already: {
    fontSize: '12px',
    color: '#ffffff',
    fontWeight: 500,
    marginTop: '20px',
    paddingBottom: '20px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal'
  },
  signinLink: {
    color: '#ffffff',
    "&:hover": {
      color: '#ffffff',
      textDecoration: 'underline',
    }
  },
  textField: {
    width: '100%',
    marginBottom: '15px'
  },
  marginRight12: {
    marginRight: '12px'
  },
  icon: {
    fontSize: '20px',
    marginBottom: '18px',
    color: '#ee2738;'
  },
  checkBoxSpace: {
    marginTop: '10px',
    textAlign: 'left'
  },
  bigAvatar: {
    width: 100,
    height: 100,
  },
  placeHold: {
    marginBottom: '-8px',
    paddingTop: '24px'
  }
});
function handleChange(values) {
  // error="";
  // if(values.image){

  // if (!values.image.name.match(/.(jpg|jpeg)$/i)) {

  //  error="*Unsupported Format only jpg and jpeg images will be accepted";

  // }
  // else if( values.image && values.image.size >= FILE_SIZE){
  //   error="*File is too large";

  // }
  // else{
  // }
  // }
}
function PersonalInfo(props) {
  const { classes } = props;
  const { values, errors, handleSubmit, setFieldValue } = useFormikContext();
  const [passwordTypePass, setpasswordTypePass] = useState('password');
  const changePasswordFieldType = () => {
    if (passwordTypePass === 'password') {
      setpasswordTypePass('text')
    }
    else {
      setpasswordTypePass('password')
    }
  }
  const handleChangeMobile = (e) => {
    if (errors.phone_number === undefined && values.phone_number && errors.email === undefined && values.email) {
      props.mobileExist({ mobile_no: values.phone_number, email: values.email })
        .then((response) => {
          handleSubmit()
        })
        .catch(error => { })
    }
  }



  return (
    <div className="signup signup-personal">
      <Grid className="signup__inner" container justify="center">
        <Grid item xs={10} md={6} lg={4} sm={6}>
          <div className={`${classes.signupForm} personal-info-form`}>
            <Grid container justify="center">
              {/* Before Upload Image */}

              <ImageUpload handleChange={handleChange(values)} name="image" value={values.image} setFieldValue={setFieldValue} />
              <Grid item xs={12}>
                <div className="image_error"><ErrorMessage name="image" /></div>
              </Grid>

            </Grid>
            <Grid>
              {localStorage.getItem('user_type') === '0' ?
                <div className="hintText">
                  <p>Almost there! (Step 2 of 3)</p>
                  <p>Add a photo so we can see your pretty face!</p>
                  <p>Venue managers respond faster to real, verified planners.</p>
                  {/* Hint: Venue Managers are more likely to respond faster when they connect with a verified planner. */}
                </div>
                : localStorage.getItem('user_type') === '1' &&
                <div className="hintText">
                  Hint: Event planners are more likely to respond faster when they connect with a personal sales experience.
                </div>
              }
            </Grid>
            <div className={classes.formFields}>
              <Grid container justify="center">
                <label className="emailLabel">First Name</label>
                <Field className="emailField" align="center" type="text" name="first_name" placeholder="First Name" />
                <div className="error"><ErrorMessage name="first_name" /></div>

                <label className="emailLabel">Last Name</label>
                <Field className="inputField" align="center" type="text" name="last_name" placeholder="Last Name" />
                <div className="error"><ErrorMessage name="last_name" /></div>

                {values.isMobileDisable &&
                  <Grid item sm={12} xs={12} >
                    <label className="passwordLabel" style={{ textAlign: 'left' }}>Email Address</label>
                    <Field className="inputField" align="center" type="text" name="email" placeholder="Email Address" />
                    <div className="error"><ErrorMessage name="email" /></div>
                  </Grid>
                }

                {values.isMobileDisable &&
                  <Grid item sm={12} xs={12}>
                    <label className="passwordLabel" style={{ textAlign: 'left' }}>Password</label>
                    <div className="password_field_div" style={{ position: "relative" }}>
                      <CustomInput st='basicinfo' type={passwordTypePass} name="password" placeholder="Create a password" passwordField changePasswordFieldType={changePasswordFieldType} />
                    </div>
                  </Grid>
                }

                <Grid className="phoneNumberGrid personalInfoPhone" item xs={values.hideExt ? 12 : 6}>
                  <label className="emailLabel">
                    Phone Number
                  </label>
                  <Field className="inputField" align="center" name="phone_number"
                    render={({ field }) => (
                      <MaskedInput
                        className="inputField"
                        {...field}
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        id="phone"
                        placeholder="(XXX)-XXX-XXXX"
                        type="text"
                        disabled={values.isMobileDisable}
                      />

                    )} />
                  <div className="error"><ErrorMessage name="phone_number" /></div>
                </Grid>
                {!values.hideExt &&
                  <Grid item xs={6} className="extensionGrid personalInfoPhone">
                    <label className="emailLabel">
                      Ext.
                    </label>
                    <Field className="inputField" align="center" type="text" name="extension" placeholder="XXXX" />
                    <div className="error"><ErrorMessage name="extension" /></div>
                  </Grid>
                }
                <Grid item xs={12} className={`${classes.checkBoxSpace} checkbox-wrap`}>
                  <Grid>
                    {localStorage.getItem('user_type') === '0' ?
                      <div className="checkbox">
                        <label className="container">Display my number to venue managers
                          <Field type="checkbox"
                            name="display_number"
                            checked={values.display_number}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      : localStorage.getItem('user_type') === '1' &&
                      <div className="checkbox">
                        <label className="container">Display my number to planners
                          <Field type="checkbox"
                            name="display_number"
                            checked={values.display_number}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    }
                  </Grid>

                </Grid>
              </Grid>
              { /*localStorage.getItem('user_type') === '0' &&
                <div align="center" className={classes.placeHold}>
                  <p>Placing holds is always free with us.</p>
                </div>
                */}
              <div className="signupDiv">
                <CustomizedButton onClickEvent={(e) => { handleChangeMobile(e) }} label="Next" type="submit" />
              </div>
            </div>
          </div>
          <div className={classes.already} align="center">Already have a FIRST-HOLD Account?  <Link className="signinLink" to='/login'>Sign In</Link>.</div>
        </Grid>
      </Grid>
    </div>
  );
}

PersonalInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ mobileExist }, dispatch)
  }
}

const mapStateToProps = state => {
  return {};
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)

export default enhance(PersonalInfo);

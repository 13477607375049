import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';

import { forgotPassword, getAllEmails } from 'shared/auth/actions';
import CustomizedButton from '../../../shared/components/customizedButton';
import CustomisedRadio from '../../../shared/components/CustomisedRadio';
// import CustomRadio from '../../../shared/components/CustomRadio';

import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { Grid, Popper,Paper,Typography, withStyles } from '@material-ui/core';

import '../../assets/styles/confirm.scss';


const Schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email!')
    .required('*Required'),
});

const styles = theme => ({
  activeText: {
    color:'#1397e1'
  },
  popper: {
      paddingLeft: '15px',
      paddingTop: '237px'
  },
  root: {
      boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
      borderRadius: '6px'
  },
  typography: {
      width: '237px',
      height: '100px',
      fontSize: '14px',
      fontWeight: '500',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.43',
      letterSpacing: 'normal',
      padding: '19px',
      color: '#1397e1',
      fontFamily: 'Raleway'
  }
});

class ForgotPassword extends Component {

  static propTypes = {
    forgotPassword: PropTypes.func.isRequired,
  };

  state={
    anchorEl: null,
    isToggleOpen: false,
    showEmails : false,
    radioSelectedValue: 0
  }
  
  handleSubmit = (values, { setSubmitting }) => {
    let formData ={}
    if(this.state.showEmails){
      const email = this.props.allEmails[values.selectedEmail]
      formData={
        email:email
      }
      return this.props.forgotPassword(formData)
      .then(() => {
        setSubmitting(false);
        this.props.history.push('/login')
      })
      .catch(error => {
        setSubmitting(false);
      });
    }
    else{
      formData={
        email:values.email
      }
      return this.props.getAllEmails(formData)
        .then((response) => {
          setSubmitting(false);
          if(response.length > 0){
            this.setState({showEmails: true})
          }
        })
        .catch(error => {
          setSubmitting(false);
          this.setState({showEmails: false})
        });
    }

  };

  handleHelpIconClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      isToggleOpen: true
    })
  }
  handleCloseHelpIcon = () => {
    this.setState({
      anchorEl: null,
      isToggleOpen: false
    });
  }

  handleRadioClick = event => {
    this.setState({ radioSelectedValue: event.target.value });
  }
 
  render() {
    const emailList = (values, setFieldValue, handleChange) =>{return this.props.allEmails ?  this.props.allEmails.map((data, index) => {
      var replacedString = data.substr(3,data.length-10); 
      const encryptedEmail = data.replace(replacedString, "********");
      return (
        <Grid item xs={12}>
          <CustomisedRadio
            display="inline-block"
            name="selectedEmail"
            value={index}
            onChange={this.handleRadioClick}
            handleChange={handleChange}
            class="radioButton"
            radioSelectedValue={parseInt(this.state.radioSelectedValue)}
            label={encryptedEmail}
          />
          {/* <span> {encryptedEmail}</span> */}
              {/* <CustomRadio name="selectedEmail" label={data} value={values.selectedEmail} id={index} setFieldValue={setFieldValue}/> */}
        </Grid>
      )
    })
    : null}
    const {classes} =this.props;
    return (
      <div className="confirm">
        <Grid className="signup__inner" container justify="center">
          <Grid item xl={3} xs={10} md={4} lg={4} sm={6}>
            <h3 className="confirmationPadding" align="center">&nbsp;</h3>

            <div className="confirmationBox">
              <h1 className="emailSentTo">Forgot Password</h1>
              {this.state.showEmails ?
              <div className="yourBusiness" align="center">
                <Grid container>
                  <Grid item className="instruction">Select email address and we'll send you the instructions for resetting your password </Grid>
                </Grid>
              </div>
              :
              <div className="yourBusiness" align="center">
                <Grid container>
                <Grid item className="instruction">Enter your email address</Grid>
                  {/* <Grid item className="instruction">Enter your email address and we'll send you the instructions for resetting your password</Grid> */}
                </Grid>
              </div>
              }
              <Grid>
                <Formik
                  initialValues={{ email: '', selectedEmail: 0 }}
                  validationSchema={Schema}
                  onSubmit={this.handleSubmit}
                >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => (
                    this.state.showEmails ?
                    <Form className="conFIrmformFields">
                      <Grid container justify="flex-start">
                        {emailList(values, setFieldValue, handleChange)}
                      </Grid>                                           
                      <Grid container justify="center" className="sendButton">
                        <Grid item>
                          <CustomizedButton label="Send" disabled={isSubmitting} type="submit" />
                        </Grid>
                      </Grid>
                    </Form>
                      :
                    <Form className="conFIrmformFields">
                      <Grid container justify="center">
                        <label className="fieldLabel">
                          Enter Email
                            </label>
                        <Field className="inputField textField" align="center" type="text" name="email" placeholder="Enter Email" />
                        <div className="error"><ErrorMessage name="email" /></div>

                      </Grid>
                      <Grid item sm={12} className="help">
                      {/* <Grid onMouseOver={this.handleHelpIconClick} onMouseOut={this.handleCloseHelpIcon}>
                        Don’t have access to this email anymore?<span className={this.state.isToggleOpen ? classes.activeText : ''} onMouseOver={this.handleHelpIconClick} onMouseOut={this.handleCloseHelpIcon}> We can help.</span>
                      </Grid> */}
                      <Popper className={classes.popper} open={this.state.isToggleOpen} anchorEl={this.state.anchorEl} placement="right-end">
                          <Paper className={classes.root}>
                              <Typography className={classes.typography}>Enter help@first-hold.com here and then email us.  We’ll help you back in.</Typography>
                          </Paper>
                      </Popper>
 
                      </Grid>                                           
                      <Grid container justify="center" className="sendButton">
                        <Grid item>
                          <CustomizedButton label="Send" disabled={isSubmitting} type="submit" />
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allEmails: state.data.auth.allEmails
  };
};

export default compose(
  withStyles(styles),
connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    forgotPassword,
    getAllEmails
  }, dispatch),
))(ForgotPassword);

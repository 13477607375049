import {SPACEVIDEODELETE} from 'shared/state/types';
import {request} from 'shared/auth';

function onRequested() {
    return {type: SPACEVIDEODELETE.REQUEST};
}

function onFailed(error) {
    return {type: SPACEVIDEODELETE.FAILURE, error: error};
}

function onSuccess(data) {
    return {type: SPACEVIDEODELETE.SUCCESS, payload: data};
}

export function spaceVideoDelete(typeId, videoId,type='SPACE') {
    let url = `spaces/${typeId}/videos/${videoId}`;
    if(type!='SPACE'){
        url = `venues/${typeId}/videos/${videoId}`;
    }
    return dispatch => {
        dispatch(onRequested());
        return request()
            .delete(url)
            .then(response => dispatch(onSuccess(response.data.data)))
            .catch(errors => {
                dispatch(onFailed(errors.response.data.errors));
                throw errors;
            });
    };
}

import { Record } from 'immutable';

import {
  GET_DELETE_FOLLOW_DATE_REQUEST,
  GET_DELETE_FOLLOW_DATE_FAILURE,
  GET_DELETE_FOLLOW_DATE_SUCCESS,

} from 'shared/state/types';

class State extends Record({
  error:'',
  sended:'',
  sending:'',
  success:''
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_DELETE_FOLLOW_DATE_REQUEST:
      return state.merge({
        error:'',
        sended:false,
        sending:true,
        success:''

      });
    case GET_DELETE_FOLLOW_DATE_FAILURE:
      return state.merge({
        error: action.error,
        success:'',
        sended:false,
        sending:false


      });
    case GET_DELETE_FOLLOW_DATE_SUCCESS:
      return state.merge({
        error:'Success',
        success:'Success',
        sended:true,
        sending:false
      });

      
    default:
      return state;
  }
}


import React, { Component }  from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import '../assets/styles/myHoldsContent.scss';

import { object, func } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { pageDetails } from "shared/pageDetails/actions";

class TabPanel extends Component
{
  state={value:0,one_data:'',two_data:'',three_data:'',four_data:'',zero_data:'',one_title:'',two_title:'',three_title:'',four_title:'',six_title:'',five_title:'',five_data:'',six_data:''}
  componentWillMount()  {

    let val=this.props.pathname ? 
    this.props.pathname==='/for-event-planner' ? 1 
    : this.props.pathname==='/for-event-venue' ? 2 
    : this.props.pathname==='/privacy-policy'  ? 4 
    : this.props.history && this.props.history.location == '?for=venue' ? 5
    : this.props.history && this.props.history.location == '?for=planner' ? 6
    : this.state.value
    : this.state.value;

      if(this.props.history && this.props.history.location.search == '?for=venue'){
        val=5;
      }
      else if(this.props.history && this.props.history.location.search =='?for=planner'){
        val=6;
      }
      this.setState({value:val});

      this.props.pageDetails(9).then((response) => {
        if(this.props.history && this.props.history.location.search==='?for=planner'){
          this.props.default_data(response.page_data.data.description)
          }
        this.setState({six_data:response.page_data.data.description,six_title:response.page_data.data.title})
        })
      
        this.props.pageDetails(8).then((response) => {
          if(this.props.history && this.props.history.location.search==='?for=venue'){
            this.props.default_data(response.page_data.data.description)
            }
          this.setState({five_data:response.page_data.data.description,five_title:response.page_data.data.title})
        })
  this.props.pageDetails(3).then((response) => {
  this.setState({three_data:response.page_data.data.description,three_title:response.page_data.data.title})
  })

  this.props.pageDetails(4).then((response) => {
    if(this.props.pathname && this.props.pathname==='/privacy-policy'){
      this.props.default_data(response.page_data.data.description)
    }
    this.setState({four_data:response.page_data.data.description,four_title:response.page_data.data.title})
  })

  this.props.pageDetails(5).then((response) => {
    this.setState({zero_data:response.page_data.data.description,zero_title:response.page_data.data.title})
    if(this.props.history && this.props.history.location.search!=='?for=planner' && this.props.history && this.props.history.location.search!=='?for=venue' && this.props.pathname && this.props.pathname!=='/for-event-venue' && this.props.pathname!=='/for-event-planner'){
      this.props.default_data(response.page_data.data.description)
    }
  })

  this.props.pageDetails(1).then((response) => {
    if(this.props.pathname && this.props.pathname==='/for-event-planner'){
      this.props.default_data(response.page_data.data.description)
    }
    this.setState({one_data:response.page_data.data.description,one_title:response.page_data.data.title})
  })

  this.props.pageDetails(2).then((response) => {
    if(this.props.pathname && this.props.pathname==='/for-event-venue'){
      this.props.default_data(response.page_data.data.description)
    }
    this.setState({two_data:response.page_data.data.description,two_title:response.page_data.data.title})
  })
  }


  render() { 
    const handleChange = (event, newValue) => {
      this.setState({value:newValue})
      if (parseInt(newValue) === 0) {
        this.props.handleChangeContent(this.state.zero_data)
      }
      else if (parseInt(newValue) === 1) {
        this.props.handleChangeContent(this.state.one_data)
      }
      else if (parseInt(newValue) === 2) {
        this.props.handleChangeContent(this.state.two_data)
      }
      else if (parseInt(newValue) === 3) {
        this.props.handleChangeContent(this.state.three_data)
      }
      else if (parseInt(newValue) === 4) {
        this.props.handleChangeContent(this.state.four_data)
      }
      else if (parseInt(newValue) === 5) {
        this.props.handleChangeContent(this.state.five_data)
      }
      else if (parseInt(newValue) === 6) {
        this.props.handleChangeContent(this.state.six_data)
      }
    };
  return (
    <Paper square class="box-tab">
      <Tabs className="tabs"
        value={this.state.value}
        indicatorColor="primary"
        onChange={handleChange}
        variant="scrollable"
        aria-label="disabled tabs example"
      >
        <Tab label={this.state.zero_title} />
        <Tab label={this.state.one_title} />
        <Tab label={this.state.two_title} />
        <Tab label={this.state.three_title} />
        <Tab label={this.state.four_title}/>
        <Tab label={this.state.five_title}/>
        <Tab label={this.state.six_title}/>
      </Tabs>
    </Paper>
  );
}
}

TabPanel.propTypes = {
  classes: object.isRequired,
  pageDetails: func.isRequired


};

const mapStateToProps = state => {

  return {
  data:state.data.pageDetails.content
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        pageDetails
      },
      dispatch
    )
  )
);

export default enhance(TabPanel);

import { request } from 'shared/auth';
import { FIND_TOKEN} from 'shared/state/types';

function findTokenRequest() {
    return {
        type: FIND_TOKEN.REQUEST
    };
}
function findTokenSuccess(data) {
    return {
        type: FIND_TOKEN.SUCCESS,
        data: data,
    };
}
function findTokenFailure() {
    return {
        type: FIND_TOKEN.FAILURE
    };
}

export function findToken(token) {
    return dispatch => {
        dispatch(findTokenRequest());
        return request()
            .get(`/password_reset/find/${token}`)
            .then(response => {
                dispatch(findTokenSuccess(response.data.data));
                return response
            })
            .catch(error => {
                dispatch(findTokenFailure());
            })
    };
}

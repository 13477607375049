import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Grid ,Icon} from '@material-ui/core';
import '../assets/styles/myEvents.scss';
import MyEventsData from "shared/components/myEvents";

class MyEvents extends Component {
  render() {
    return (
      <div className="my_events fh-container" style={{paddingTop:'65px'}}>
          <Grid container className="edit_profile_component">
                <Grid className="headings" item xs={12} >
                    <h4 className="form_name e">My Events</h4>
                    <p className="form_desc">See holds, request a contract and more — through your Chrome Extension <a href="https://firsthold.groovehq.com/help/installing-or-uninstalling-the-chrome-extension" target="_blank"><Icon className="info-icon" >info_outline</Icon></a></p>
                </Grid>
                <MyEventsData label="Place Hold"/>
         
        </Grid>
      </div>
    );
  }
}

export default withRouter(MyEvents);

import axios from 'axios';
import { GET_PLANNER_CALENDAR_DATA } from 'shared/state/types';
// import Cookies from 'js-cookie';
import { loadProgressBar } from 'axios-progress-bar'

function getPlannerCalendarDataRequested() {
  return {
    type: GET_PLANNER_CALENDAR_DATA.REQUEST
  };
}

function getPlannerCalendarDataSuccess(data) {
  return {
    type: GET_PLANNER_CALENDAR_DATA.SUCCESS,
    plannerCalendarData: data,
  };
}

function getPlannerCalendarDataFailure(error) {
  return {
    type: GET_PLANNER_CALENDAR_DATA.FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');
  }
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function getPlannerCalendarData(spaceId, month, year) {

    return dispatch => {
      dispatch(getPlannerCalendarDataRequested());
      if(localStorage.getItem('fhtoken')!=null){
        return request().get(`/planner/venue/space/${spaceId}/availability?month=${month}&year=${year}`)
        .then(response =>{
          dispatch (getPlannerCalendarDataSuccess(response.data.data),
         );
        return response.data.data
        }
        )
        .catch(errors => {
          if(errors.response && errors.response.data.message){
            dispatch(getPlannerCalendarDataFailure(errors.response.data.message));
          }
          else{
            // window.location.href = './'
            dispatch(getPlannerCalendarDataFailure("Not able to find this space"));
          }
  
          throw errors;
        });
      }
      else
      {
      return request().get(`/venue/space/${spaceId}/availability?month=${month}&year=${year}`)
      .then(response =>
        dispatch (getPlannerCalendarDataSuccess(response.data.data),
        )
      )
      .catch(errors => {
        if(errors.response && errors.response.data.message){
          dispatch(getPlannerCalendarDataFailure(errors.response.data.message));
        }
        else{
          // window.location.href = './'
          dispatch(getPlannerCalendarDataFailure("Not able to find this space"));
        }

        throw errors;
      });
      }


    
 };
}

import { Record } from 'immutable';

import {
  MARK_FAV,
  DELETE_FAV
} from 'shared/state/types';

class State extends Record({
  id: localStorage.getItem('id') ? localStorage.getItem('id') : 0,
  type: localStorage.getItem('type') ? localStorage.getItem('type') : null,
  loggingIn: true,
  loggedIn: false,
  image: '',
  error: '',
  success: '',
  fetching: '',
  fetched: '',
  sending: '',
  sended: ''  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case MARK_FAV.REQUEST:
      return state.merge({
        fetching: true,
        fetched: false,
        error: ''
      });
    case MARK_FAV.SUCCESS:
      return state.merge({
        fetching: false,
        fetched: true,
        sending: false,
        sended: true,
        error: '',
        success: "Space favorited successfully."
      });
    case MARK_FAV.FAILURE:
      return state.merge({
        sending: false,
        error: "Space could not be favorited."
      });
    case DELETE_FAV.REQUEST:
      return state.merge({
        fetching: true,
        fetched: false,
        error: ''
      });
    case DELETE_FAV.SUCCESS:
      return state.merge({
        fetching: false,
        fetched: true,
        sending: false,
        sended: true,
        error: '',
        success: "Space unfavorited successfully."
      });
    case DELETE_FAV.FAILURE:
      return state.merge({
        sending: false,
        error: "Space could not be unfavorited."
      });
    default:
      return state;
  }
}


import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './../assets/style/contactPopup.scss';
import DialogActions from '@material-ui/core/DialogActions';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { getArrangements } from 'data/master/actions';
import CustomizedButton from 'shared/components/customizedButton';
import * as Yup from 'yup';
import "../assets/style/placeHold.scss";
import { Datepicker } from "react-formik-ui";
import CustomSelect from "shared/components/CustomSelect";
import CustomTimeSelect from "shared/components/CustomTimeSelect";
import moment from "moment";

const styles = theme => ({
  root: {
    margin: '20px !important',
    overflow: 'scroll',
  },
  paper: {
    margin: '20px'
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
      fontWeight: '500',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.46px',
      color: 'black'
    }
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: '6px',
      border: 'solid 0px black',
    }
  }
});

const DialogContent = withStyles(theme => ({
  root: {
  },
}))(MuiDialogContent);

class ContactPopup extends React.Component {
  // constructor(props, context) {
  //   super(props, context);
  // }

  state = {
    open: false,
    showForm: false,
    input: false,
    eventsPresent: false,
    eventsSelected: false,
    eventsChecked: false,
    buttonDisabled: true,
    radioSelectedValue: '',
    errorMessage: ''
  };

  UNSAFE_componentWillMount() {
    this.props.getArrangements();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickOpen = () => {
    this.props.infoPopupOpen() 
    this.props.handleClose()
      
  };


  render() {
    const { classes } = this.props;
    // const eventsPresent = this.state.eventsPresent;
    return (
      <div>
        {this.props.follow === true ?
          <Button variant="outlined" onClick={this.handleClickOpen} classes={{
            label: classes.label,
            root: classes.button
          }}
            style={{ background: 'linear-gradient(to bottom, #f1435e, #ee2738)' }}

          >
            Contact
          </Button>
          :
          <Button variant="outlined" onClick={this.handleClickOpen} classes={{
            label: classes.label,
            root: classes.button
          }}
          style={{color:'red'}}

          >
  
          Contact
        </Button>
        }
      </div>
    );
  }
}

ContactPopup.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    arrangements: state.data.master.arrangements
  };
};


const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        getArrangements
      },
      dispatch
    )
  )
);
export default enhance(ContactPopup);

import { GET_AIRPORT } from 'shared/state/types';
import { request } from 'shared/auth';

function getAirportRequested() {
  return {
    type:GET_AIRPORT.REQUEST,
  };
}

function getAirportFailed(error) {
  return {
    type:GET_AIRPORT.FAILURE,
    error:error,
  };
}

function getAirportSuccess(data) {
  return {
    type:GET_AIRPORT.SUCCESS,
    airportList: data,
  };
}


export function getAirport(id) {
  return dispatch => {
    dispatch(getAirportRequested());

    return request().get('/airport')
      .then(response =>{
          dispatch(getAirportSuccess(response.data))
          return response.data;
        }
      )
      .catch(errors => {
        if(errors.response && errors.response.data.message){
          dispatch(getAirportFailed(errors.response.data.message));
        }
        else{
          dispatch(getAirportFailed("Not able to find this venue"));
        }
        throw errors;
      });
  };
}

import React from 'react';

import CustomInput from '../../shared/components/CustomInput';
import CustomSelectWithScroll from '../../shared/components/CustomSelectWithScroll';

import { Grid, Hidden } from '@material-ui/core';

import '../assets/styles/addVenue.scss';

function AddVenueName(props) {
    let venueTypes=[]
    let venueTypeValue=[]
    props.states !== [] && Object.keys(props.venueTypeList).map((keyName, i)=>{    
        venueTypes.push(props.venueTypeList[keyName].name)
        venueTypeValue.push(props.venueTypeList[keyName].id)
        return venueTypeValue;
    });
    return (
        <Grid className="add_venue_name" container>
            <Hidden xsDown={true}>
                <Grid item sm={1} md={2} lg={3} xl={3}></Grid>
            </Hidden>
            <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
                <Grid container justify="center" className="add_venue_container">
                    <Hidden xsDown={true}>
                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <div className="venue_name">
                            <CustomInput label="NAME OF VENUE/BUILDING" type="text" name="venueName" placeholder="The White House, The MOMA" />
                        </div>
                        <div className="venue_type">
                        <CustomSelectWithScroll placeholder="Select" venueTypes={venueTypes} venueTypeValue={venueTypeValue} name="venueType" value={props.formikProps.values.venueType} handleChange={props.formikProps.handleChange} handleBlur={props.formikProps.handleBlur} label="TYPE OF VENUE" showLabel />
                        </div>
                        <div className="venue_code">
                            <CustomInput label="4-Letter Venue Code" type="text" name="venueCode" placeholder="WTHS, MOMA, " upperCaseInput />
                        </div>
                        <div className="code_visible">This code is only visible to you</div>
                    </Grid>
                    <Hidden xsDown={true}>
                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                    </Hidden>
                </Grid>
            </Grid>
            <Hidden smDown={true}>
                <Grid item sm={1} md={2} lg={3} xl={3}></Grid>
            </Hidden>
        </Grid>
    );
}

export default AddVenueName;

import React from 'react';
import { Grid } from '@material-ui/core';
import '../assets/styles/spacePlannerEdit.scss';
import DeletePdfComponent from './deletePdf';
import EditPdfComponent from './editPdf';

import { Icon } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import AddPdfComponent from './addPdf';
import AddVideosComponent from './addVideos';

let handleDocumentDeleted;
let handleDocumentUpdated;
let venueId;
class VenuePdfEdit extends React.Component {
  constructor(props) {
    super(props);
    venueId=props.venueId;
    handleDocumentDeleted = props.handleDocumentDeleted;
    handleDocumentUpdated = props.handleDocumentUpdated;
    this.handleClick = this.handleClick.bind(this);
  }
  state={
    pdfData:[]

}


UNSAFE_componentWillReceiveProps(nextProps){
  this.setState({pdfData:nextProps.pdfData})
}
  handleClick() {

  }

  render(props) {
    return (

      <Grid container className="space-planner-edit">
        <Grid xs={12} item>
          <Grid container>
            <Grid item md={12} xs={12} sm={12} xl={12} lg={12} className="space-planner-edit-outer-grid">

                <div className="grid-section-vertical sub-section bordered speces">
                <AddPdfComponent 
                        pdfData={this.state.pdfData}
                        venueDetails={this.props.venueData} 
                        type="VENUE" 
                        id={this.props.venueData.id} 
                        handleDocumentUploaded={this.props.handleDocumentUploaded}/>
          
                {this.props.venueDetails?
              this.props.venueDetails.length>0 ?
        
                <ul className="plan_description">
                
                <br />
              {this.props.venueDetails && this.props.venueDetails.map(function(data, idx) {

                return(
                <li>
                <div className="blue_icon_div">
                  <span className="material-icons picture_as_pdf">picture_as_pdf</span>
                </div>
                <div className="text">
                <a 
                target="_Blank"
                rel="noopener noreferrer" 
              href={'/pdfReader?url='+data.path}>
              {data.name}            
              </a>
                  </div>
                  <div className="plan-descp-icons">

                  <a 
              target="_Blank"
              rel="noopener noreferrer" 
              href={'/pdfReader?url='+data.path}>
                     <Icon>remove_red_eye</Icon>
              </a>
                          <a href={data.path} download="dfadfa">
                                <Icon>get_app</Icon>
                            </a>

                            
             <EditPdfComponent type="VENUE" data={data}  handleDocumentUpdated={handleDocumentUpdated} index={idx}  />
             <DeletePdfComponent type="VENUE" id={venueId} documentId={data.id} handleDocumentDeleted={handleDocumentDeleted} />
                </div>



          
              </li>
                )
              })}
                    
                </ul>
               : <p className='plan_description' style={{float:'left',margin:'10px 0 0 4px'}}>No document Found</p>
               :null
        }
       
              </div>
              <div className="grid-section-vertical sub-section bordered speces">
        <AddVideosComponent type="VENUE" id={venueId} videos={this.props.videos} handleVideoUpdate={this.props.handleVideoUpdate}  handleVideoAdd={this.props.handleVideoAdd}  handleVideoDeleted={this.props.handleVideoDeleted} />
      </div>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default VenuePdfEdit;

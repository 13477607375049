import axios from 'axios';
import { GET_GOOGLE_KEY_REQUEST, GET_GOOGLE_KEY_SUCCESS, GET_GOOGLE_KEY_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function googleKeyRequested() {
  return {
    type: GET_GOOGLE_KEY_REQUEST
  };
}

function googleKeySuccess(data) {
  return {
    type: GET_GOOGLE_KEY_SUCCESS,
    googleData: data,
  };
}

function googleKeyFailure(error) {
  return {
    type: GET_GOOGLE_KEY_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function googleKeyData() {

    return dispatch => {
      dispatch(googleKeyRequested());
        return request().get(`getKeys`)
        .then(response =>
          dispatch (googleKeySuccess(response.data.data))
        )
        .catch(errors => {
          dispatch(googleKeyFailure(errors.response));
  
          throw errors;
        });
      

    
 };
}

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { IconButton, Icon } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { getSpace} from 'data/spaces/actions';
import { getGoogleCalnderList } from '../../../src/data/venues/actions/getGoogleCalendarList';
import { getGoogleToken} from '../../../src/data/venues/actions/getGoogleToken'; 
import {getMicrosoftCalnderList} from '../../../src/data/venues/actions/getMicrosoftCalendarList';
import {getMicrosoftToken} from '../../../src/data/venues/actions/getMicrosoftToken';
import './assets/style/NotificationPopup.scss';
import './assets/style/customMenuItem.scss';
import eventBroadcast from "../../data/eventBroadcast";
import { getTSSpaces } from '../../../src/data/venues/actions/getTSSpaces';
import { getSyncedETSpaces } from '../../../src/data/venues/actions/getSyncedETSpaces';


const styles = theme => ({

  root: {
    display: 'flex',
    float: 'left'
  },
  notificationsIcon: {
    padding: '0px 0px 0px 0px'
  },
  MuiPaper: {
    width: '430px',
    height:'auto',
    boxShadow: 'none',
    position: 'absolute',
    top: '0',
    right: '0px',
    paddingLeft:'5px',

  },
  MuiMenuItem: {
    height: 'auto',
    lineHeight: '0',
    padding: '5px 15px',
    borderBottom: '0',
    hover: {
      background: '#fff',
    }
  }
});

class MenuListComposition extends React.Component {
  state = {
    open: false,
    calendarId:"",
    msCalendarId:"",
    msSyncCalendar:[],
    syncCalendar:[],
    spaceData: {},
    loading: true,
    ts_space:'',
    et_space:''
  };
  UNSAFE_componentWillMount() {
    this.props.getTSSpaces(this.props.spaceId ).then((resultData) => {
      this.setState({ts_space:resultData.data.ts_space_name})

    }
    )
    this.props.getSyncedETSpaces(this.props.spaceId ).then((resultData) => {
      this.setState({et_space:resultData.data.ts_space_name})

    }
    )
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.spaceData !== nextProps.spaceData){
      this.setState({spaceData: nextProps.spaceData})
    }
  }
  handleToggle = () => {
    this.setState({ open: !this.state.open });
    if(!this.state.open && this.state.syncCalendar.length === 0 && !this.state.calendarId ){ 
      this.setState({ loading: true });
      this.props.getSpace(this.props.spaceId).then((spacecResponse) => { 
        this.setState({spaceData:spacecResponse}) 
        if(spacecResponse && spacecResponse.calendar_id){           
          this.setState({calendarId:spacecResponse.calendar_id});
          this.props.getGoogleToken().then((response) => {
            localStorage.setItem('accesstoken', response.access_token);                
                this.props.getGoogleCalnderList().then((result) => {
                  this.setState({ loading: false })
                  let syncCal = result.items.filter(data => data.id == spacecResponse.calendar_id);
                  this.setState({ syncCalendar:syncCal  })            
                }).catch(error =>
                  this.setState({ loading: false })
                );          
            }).catch(error =>
              this.setState({ loading: false })
            );
        }else{
          this.setState({ loading: false })
        } 
      }).catch(error =>
        this.setState({ loading: false })
      );  
    }

    if(!this.state.open && this.state.syncCalendar.length === 0 && !this.state.calendarId ){ 
      this.setState({ loading: true });
      this.props.getSpace(this.props.spaceId).then((spacecResponse) => { 
        this.setState({spaceData:spacecResponse}) 
        if(spacecResponse && spacecResponse.ms_calendar_id){           
          this.setState({calendarId:spacecResponse.ms_calendar_id});
          this.props.getMicrosoftToken().then((response) => {
            localStorage.setItem('msaccesstoken', response.access_token);                
                this.props.getMicrosoftCalnderList().then((result) => {
                  this.setState({ loading: false })
                  let syncCal = result.value.filter(data => data.id == spacecResponse.ms_calendar_id);
                  this.setState({ msSyncCalendar:syncCal  })            
                }).catch(error =>
                  this.setState({ loading: false })
                );          
            }).catch(error =>
              this.setState({ loading: false })
            );
        }else{
          this.setState({ loading: false })
        } 
      }).catch(error =>
        this.setState({ loading: false })
      );  
    }

    
  };

  handleClose = (event) => { 
    this.setState({ open: false });
  }; 

  notclose=()=>{
    this.setState({open:true});
  }

  changeCalendar=()=>{
    eventBroadcast.dispatch("googleCalendarSync", {});
  }

  msChangeCalendar=()=>{
    eventBroadcast.dispatch("microsoftCalendarSync",{});
  }

  render() {
    const { classes } = this.props;
    const { open }    = this.state;  
    console.log('ssssss',this.state.msSyncCalendar);
    return (
    
      <div className={classes.root} onClick={()=>this.notclose()}>
        <IconButton  title="Sync Google Calendar" buttonRef={node => {
          this.anchorEl = node;
        }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}>
          <Icon>sync</Icon>
        </IconButton>
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper classes={{ root: classes.MuiPaper }} className="main_section" >
                <ClickAwayListener onClickAway={this.handleClose}>
                  {
                    this.state.loading ?
                    <div className="no-data-loader" onClick={()=>this.notclose()}>
                      Loading...
                    </div>
                    :  
                    <div>                 
                     <div className="gc-box">
                        <div className="notification-box">
                          <div class= "custom_menu_item" onClick={()=>this.notclose()}>
                              <MenuList>
                                <MenuItem classes={{ root: classes.MuiMenuItem }} className="first_list_row">
                                    <Grid container spacing={15}>                     
                                        <Grid item xs={12}>
                                            <p className="red">Synced Google Calendar</p>
                                        </Grid>                     
                                    </Grid>                  
                                </MenuItem>
                                <MenuItem classes={{ root: classes.MuiMenuItem }} className="second_list_row" >                
                                    <Grid container spacing={15}  alignItems="center"  justifyContent="center">                     
                                      <Grid item xs={12} className="cal-text">   
                                        {
                                          (this.state.syncCalendar.length > 0) ?                   
                                          <a href="https://calendar.google.com/calendar/u/0/r" target="_blank">{this.state.syncCalendar[0].summary}</a>
                                          :
                                          <div className="noneText">NONE</div>
                                        }
                                        </Grid>                     
                                    </Grid>                  
                                </MenuItem>              
                              </MenuList>
                          </div>                                       
                        </div>
                        {/* <div className="changeText" onClick = {this.changeCalendar}>
                        Change
                        </div>  */}
                      </div> 
                      <div className="ms-box">
                      <div className="notification-box">
                        <div class= "custom_menu_item" onClick={()=>this.notclose()}>
                            <MenuList>
                              <MenuItem classes={{ root: classes.MuiMenuItem }} className="first_list_row">
                                  <Grid container spacing={15}>                     
                                      <Grid item xs={12}>
                                          <p className="red">Synced Microsoft Calendar</p>
                                      </Grid>                     
                                  </Grid>                  
                              </MenuItem>
                              <MenuItem classes={{ root: classes.MuiMenuItem }} className="second_list_row" >                
                                  <Grid container spacing={15}  alignItems="center"  justifyContent="center">                       
                                      <Grid item xs={12} className="cal-text">   
                                      {
                                        (this.state.msSyncCalendar.length > 0) ?                   
                                        <a href="https://outlook.live.com/calendar/0/view/month" target="_blank">{this.state.msSyncCalendar[0].name}</a>
                                        :
                                        <div className="noneText">NONE</div>
                                      }
                                      </Grid>                     
                                  </Grid>                  
                              </MenuItem>              
                            </MenuList>
                        </div>                                       
                      </div>
                      {/* <div className="changeText" onClick = {this.msChangeCalendar}>
                      Change
                      </div>  */}
                      </div>   
                      <div className="ms-box two">
                      <div className="notification-box">
                        <div class= "custom_menu_item" onClick={()=>this.notclose()}>
                            <MenuList>
                              <MenuItem classes={{ root: classes.MuiMenuItem }} className="first_list_row">
                                  <Grid container spacing={15}>                     
                                      <Grid item xs={12}>
                                          <p className="red">Synced Tripleseat Space</p>
                                      </Grid>                     
                                  </Grid>                  
                              </MenuItem>
                              <MenuItem classes={{ root: classes.MuiMenuItem }} className="second_list_row" >                
                                  <Grid container spacing={15}  alignItems="center"  justifyContent="center">                       
                                      <Grid item xs={12} className="cal-text">   
                                      {
                                        (this.state.ts_space!=''&&this.state.ts_space!=null) ?                   
                                        <a href="" target="_blank">{this.state.ts_space}</a>
                                        :
                                        <div className="noneText">NONE</div>
                                      }
                                      </Grid>                     
                                  </Grid>                  
                              </MenuItem>              
                            </MenuList>
                        </div>                                       
                      </div>
                      {/* <div className="changeText" onClick = {this.msChangeCalendar}>
                      Change
                      </div>  */}
                      </div> 
                      <div className="ms-box three">
                      <div className="notification-box">
                        <div class= "custom_menu_item" onClick={()=>this.notclose()}>
                            <MenuList>
                              <MenuItem classes={{ root: classes.MuiMenuItem }} className="first_list_row">
                                  <Grid container spacing={15}>                     
                                      <Grid item xs={12}>
                                          <p className="red">Synced Event Temple Room</p>
                                      </Grid>                     
                                  </Grid>                  
                              </MenuItem>
                              <MenuItem classes={{ root: classes.MuiMenuItem }} className="second_list_row" >                
                                  <Grid container spacing={15}  alignItems="center"  justifyContent="center">                       
                                      <Grid item xs={12} className="cal-text">   
                                      {
                                        (this.state.et_space!=''&&this.state.et_space!=null) ?                   
                                        <a href="" target="_blank">{this.state.et_space}</a>
                                        :
                                        <div className="noneText">NONE</div>
                                      }
                                      </Grid>                     
                                  </Grid>                  
                              </MenuItem>              
                            </MenuList>
                        </div>                                       
                      </div>
                      {/* <div className="changeText" onClick = {this.msChangeCalendar}>
                      Change
                      </div>  */}
                      </div> 
                    </div>                  
                    
                }
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}
MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    userData: state.data.usersVenue.userInfo,
    spaceData: state.data.spaces.spaceData
  };
};

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    dispatch => bindActionCreators({
      getSpace,
      getGoogleCalnderList,
      getGoogleToken,
      getMicrosoftToken,
      getMicrosoftCalnderList,
      getTSSpaces,
      getSyncedETSpaces
      
    }, dispatch),
  ),
)
export default enhance(MenuListComposition);

import {SPACEVIDEOADD} from 'shared/state/types';
import {request} from 'shared/auth';

function onRequested() {
    return {type: SPACEVIDEOADD.REQUEST};
}

function onFailed(error) {
    return {type: SPACEVIDEOADD.FAILURE, error: error};
}

function onSuccess(data) {
    return {type: SPACEVIDEOADD.SUCCESS, payload: data};
}

export function spaceVideoAdd(params, id,type='SPACE') {
    let url = `spaces/${id}/videos`
    if(type!='SPACE'){
        url = `venues/${id}/videos`
    }
    return dispatch => {
        dispatch(onRequested());
        return request()
            .post(url, params)
            .then(response => dispatch(onSuccess(response.data.data)))
            .catch(errors => {
                dispatch(onFailed(errors.response.data.errors));
                throw errors;
            });
    };
}

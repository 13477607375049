import { ADD_SPACE_IMAGE } from 'shared/state/types';
import { request } from 'shared/auth';

function addSpaceImageRequested() {
  return {
    type: ADD_SPACE_IMAGE.REQUEST,
  };
}

function addSpaceImageFailed(error) {
  return {
    type: ADD_SPACE_IMAGE.FAILURE,
    error:error,
  };
}

function addSpaceImageSuccess() {
  return {
    type: ADD_SPACE_IMAGE.SUCCESS,
  };
}

export function addSpaceImage(spaceId,params) {
  return dispatch => {
    dispatch(addSpaceImageRequested());

    return request().post(`spaces/${spaceId}/photos`, params)
      .then(response =>{
          dispatch(addSpaceImageSuccess())
          return response.data.data
        }
      )
      .catch(errors => {
        dispatch(addSpaceImageFailed(errors.response.data.message));
        throw errors;
      });
  };
}

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const DefaultBlueRadio = withStyles({
  root: {
    '&$checked': {
      color: blue[600],
    },
  },
  icon: {
    width: 12,
    height: 12
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const BlueRadio = withStyles({
  root: {
    color: blue[400],
    '&$checked': {
      color: blue[600],
    },
  },
  icon: {
    width: 12,
    height: 12
  },
  checked: {},
})(props =><FormControlLabel label={props.label}  control={<Radio {...props}/>} />);

export default function CustomisedRadio(props) {
  if(props.color === "default-blue")
  {
    return (
          <div  style={{display: props.display?props.display:''}}>
            <DefaultBlueRadio
              checked={props.value === props.radioSelectedValue}
              onChange={(e)=>{props.setFieldValue ? props.onChange(e,props.setFieldValue) : props.onChange(e); props.handleChange && props.handleChange(e)}}
              value={props.value}
              name={props.name}
              inputProps={{ 'aria-label': props.value }}
              icon={<RadioButtonUncheckedIcon fontSize="small" />}
              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
              label={props.label}
            />
          </div>
        );
  } else {
      return (
          <div>
            {props.event ? 
              <BlueRadio
              style={{margin:'0px 0px 50px -10px'}}
              checked={props.value === props.radioSelectedValue}
              onChange={(e)=>{props.setFieldValue ? props.onChange(e,props.setFieldValue) : props.onChange(e); props.handleChange && props.handleChange(e)}}
              value={props.value}
              name={props.name}
              className="radio"
              inputProps={{ 'aria-label': props.value }}
              icon={<RadioButtonUncheckedIcon fontSize="small" />}
              checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
              label={props.label}
            />:
            <BlueRadio
            checked={props.value === props.radioSelectedValue}
            onChange={(e)=>{props.setFieldValue ? props.onChange(e,props.setFieldValue) : props.onChange(e); props.handleChange && props.handleChange(e)}}
            value={props.value}
            name={props.name}
            inputProps={{ 'aria-label': props.value }}
            icon={<RadioButtonUncheckedIcon fontSize="small" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
            label={props.label}
          />
            }
            
          
          </div>
        );
  }
}

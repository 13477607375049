import React from 'react';

import { Field } from 'formik';

import './assets/style/CustomCheckBox.scss';

function CustomRadio(props) {
    return (
        <div className="custom_checkbox">
            <label className={(props.value === props.id) ? 'blue_text_container' : 'container' }>{props.label}
                <Field
                    id={props.id}
                    value={props.value}
                    type="radio"
                    name={props.name}
                    checked={props.value === props.id} 
                    onChange={(e)=>{
                        props.onRadioClick && props.onRadioClick(e, props.setFieldValue, props.id)
                        props.setFieldValue(props.name,props.id)}}
                />
                <span className="checkmark"></span>
            </label>
        </div>
    );
}


export default CustomRadio;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAuth } from 'shared/auth/selectors';

class Foobar extends Component {

  static propTypes = {
    auth: PropTypes.object,
  };

  render() {
    const { auth } = this.props;

    return (
      <div>
        Foobar!
        <div>
          <ul>
            <li>ID: { auth.id }</li>
            <li>First Name: { auth.first_name }</li>
            <li>Last Name: { auth.last_name }</li>
            <li>Email: { auth.email }</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    auth: getAuth(state),
  }),
  dispatch => bindActionCreators({}, dispatch),
)(Foobar);

// import axios from 'axios';
import { request } from 'shared/auth';
import { SAVE_GOOGLE_CALENDAR_EVENTS } from 'shared/state/types';
function saveGoogleCalnderEventsRequested() {
  return {
    type: SAVE_GOOGLE_CALENDAR_EVENTS.REQUEST,
  };
}


function saveGoogleCalnderEventsSuccess(data) {
  return {
    type: SAVE_GOOGLE_CALENDAR_EVENTS.SUCCESS,
    data: data,
  };
}

function saveGoogleCalnderEventsFailure(error) {
  return {
    type: SAVE_GOOGLE_CALENDAR_EVENTS.FAILURE,
    error:error,
  };
}

// const request = () => {
//   const headers = {};

//   if(localStorage.getItem('accesstoken'))
//   {
//     headers['Authorization'] = 'Bearer ' + localStorage.getItem('accesstoken');

//   }


//   return axios.create({
//     headers,
//   })
// };

export function saveGoogleCalendaEvents(params) {

    return dispatch => {
      dispatch(saveGoogleCalnderEventsRequested());
      return request().post(`event_timeslots/sync`, params)
      //return request().post('/venues', params)
        .then(response => {
          if(response.status === 200){
            if(response.data.data.length ===0)
            {
              dispatch(saveGoogleCalnderEventsSuccess(response.data.message.message))
              return response.data.message.message;
            }
            else
            {
              dispatch(saveGoogleCalnderEventsSuccess(response.data.data))
              return response.data.data;
            }
            
          }
          else if(response.status === 400){
            dispatch(saveGoogleCalnderEventsFailure("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(saveGoogleCalnderEventsFailure("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(saveGoogleCalnderEventsFailure("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(saveGoogleCalnderEventsFailure("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(saveGoogleCalnderEventsFailure("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(saveGoogleCalnderEventsFailure("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(saveGoogleCalnderEventsSuccess("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(saveGoogleCalnderEventsFailure("The server was unavailable."));
          }
          else{
            dispatch(saveGoogleCalnderEventsFailure("The server was unavailable."));
          }
          dispatch (saveGoogleCalnderEventsSuccess(response.data),
          )
          return response.data;
        }
        ) 
        .catch(errors => {
          if(errors && errors){
            dispatch(saveGoogleCalnderEventsFailure(errors.toString()));
          }
          else{
             dispatch(saveGoogleCalnderEventsFailure("Something went wrong."));
          }
          throw errors;
  
          
        });
      

    
 };
}

import axios from 'axios';
import { POST_CONTACT_REQUEST, POST_CONTACT_SUCCESS, POST_CONTACT_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function contactRequested() {
  return {
    type: POST_CONTACT_REQUEST
  };
}


function contactSuccess(data) {
  return {
    type: POST_CONTACT_SUCCESS,
    contactDetails: data,
  };
}

function contactFailure(error) {
  return {
    type: POST_CONTACT_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {

  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export function contactData(params,id) {
    return dispatch => {
      dispatch(contactRequested());
        return request().post('/venues/'+id+'/contact',params)
        .then(response =>
          dispatch (contactSuccess(response.data),
                )        )
        .catch(errors => {
          
          dispatch(contactFailure(errors.message));
          throw errors;
        });
      


    
 };
}

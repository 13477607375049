import React, { Component } from 'react';
import { getCategory } from '../../../src/data/venues/actions/getCategory';
import { bindActionCreators, compose } from "redux";
import { Redirect } from 'react-router-dom'

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import { object, func } from "prop-types";
import moment from "moment";

import "../assets/styles/helpHome.scss";
import {
    AppBar,
    Icon,
    Toolbar,
    IconButton,
    MenuItem,
    Menu,
    Button
} from '@material-ui/core';

      let id='';  
    function helpHome (props) {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [category, setcategory] = React.useState();
        const [Featured, setfeatured] = React.useState();

        
        const handleClose = () => {
          setAnchorEl(null);
        };
        
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        React.useEffect(() => {
           props.getCategory().then((result) => {
            
            if ('categories' in result && Array.isArray(result.categories)) {
                  setcategory(result.categories);
                  setfeatured(result.articles);
                };
           })
          },[]);
          
        return (
            <div className="help-ui-wrapper">
                <section class="header-section">
                    <div class="header-body">
                        <div class="header-wrap">
                            <a href="/help" class="company-logo">
                                <img class="large" src={require('./../assets/images/LogoWithText.png')} alt="Logo" />
                            </a>
                            <div class="overlay" id="overlay">
                                <nav class="overlay-menu">
                                    <ul class="header-nav-view">
                                        <li><Link to="/help/articles?keyword=venues" target="_blank">Venues</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=caterers" target="_blank">Caterers</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=cvb" target="_blank">CVBs</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=planners" target="_blank">Planners</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=partners" target="_blank">Partnering with FIRST-HOLD</Link></li>
                                    </ul>
                                    <div class="mobile-view-nav">
                                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                            <Icon>more_vert</Icon>
                                        </Button>
                                        <Menu  keepMounted  anchorEl={anchorEl}  onClose={handleClose}  open={Boolean(anchorEl)}>
                                            <MenuItem onClick={handleClose}>Venues</MenuItem>
                                            <MenuItem onClick={handleClose}>Caterers</MenuItem>
                                            <MenuItem onClick={handleClose}>CVBs</MenuItem>
                                            <MenuItem onClick={handleClose}>Planners</MenuItem>
                                            <MenuItem onClick={handleClose}>Partnering with FIRST-HOLD</MenuItem>
                                        </Menu>
                                    </div>
                                </nav>
                            </div>
                           
                        </div>
                        <div class="header-title">
                            <h1>How can we help?</h1>
                            <div class="sub-heading">Get help straight from our team...</div>
                        </div>
                        <div class="article-search">
                            <div class="article-search-container">
                                <form action="/help/articles" method="get" autocomplete="off">
                                    <span class="icon-search">
                                        <img  src={require('../assets/images/search.svg')} alt="Search" />
                                    </span>
                                    <input placeholder="Search for answers..." type="search" name="keyword" />
                                </form>
                            </div>
                        </div>
                    </div>
                   
                </section>
                <div class="page-body">
                    <div class="page-container">
                        <section class="article-overview-list">
                            <h2> Featured articles</h2>

                            <div class="lists">
                                                            {Featured && Featured.map(({ id, title,description,slug}) => (

                                <a class="item" href={`/help/article/${slug}?article_id=${id}`} >
                                    <span class="title">{title}</span>
                                    <span class="description">{description}</span>
                                </a>
                                                            ))}
                            </div>
                            
                        </section>
                        <section class="articles">
                            <h2>Categories</h2>
                            <ul class="article-list">
                                {category && category.map(({ id, title,articles_count,updated_at,slug}) => (
                                <li class="article-list-item" key={id}>
                                 <Link to={`/help/search/${slug}?category_id=${id}`}  className="footer_links" >

                                        <h3>{title}</h3>
                                        <div class="article-list-item-footer">
                                            <div class="authors">
                                                <ul>
                                                    <li><img src="https://s3.amazonaws.com/groovehq/uploaded/thumb/FpFTmdVAcy7k-se6BHqU?1619458087" alt="avatar" /></li>
                                                </ul>
                                                <div class="item-info">
                                                    {articles_count} articles in this category<br/>
                                                    Updated on {moment(new Date(updated_at.slice(0,10))).format("LL")}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                ))}
                                </ul>
                        </section>
                    </div>
                </div>
            </div>
        );
    }

    const mapStateToProps = state => {
        return {
          userData: state.data.usersVenue.userInfo
        };
      };
      
      const enhance = compose(
        withRouter,
        connect(mapStateToProps, dispatch =>
          bindActionCreators(
            {
             
              getCategory
              
            },
            dispatch
          )
        )
      );   
      export default enhance(withRouter(helpHome));



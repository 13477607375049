import { IMAGE_UPLOAD } from 'shared/state/types';
import { request } from 'shared/auth';

function imageUploadRequested() {
  return {
    type: IMAGE_UPLOAD.REQUEST,
  };
}

// function imageUploadFailed(error) {
//   return {
//     type: IMAGE_UPLOAD.FAILURE,
//     error:error,
//   };
// }

// function imageUploadSuccess(data) {
//   return {
//     type: IMAGE_UPLOAD.SUCCESS,
//     payload: data,
//   };
// }

export function imageUpload(params) {
  return dispatch => {
    dispatch(imageUploadRequested());

    return request().post('/resources', params)
      .then(response =>
        {}
        // dispatch(imageUploadSuccess(response.data.data))
      )
      .catch(errors => {
        // dispatch(imageUploadFailed(errors.response.data.errors));
        throw errors;
      });
  };
}

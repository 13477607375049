import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Comp, loggedIn, path, type, ...rest }) => {

    return (
      <Route
        path={path}
        {...rest}
        render={props => {
          return localStorage.getItem('fhtoken') !== null ? localStorage.getItem('type') === '0' ?
           <Redirect to="/browseVenue" /> : localStorage.getItem('type') === '1' ? 
           <Redirect to="/myVenue" /> : localStorage.getItem('autoLogin') === 'true' ? 
           <Redirect to="/myVenue" /> : <Redirect to='/' /> : <Comp {...props} />;
        }}
      />
    );
};

export default ProtectedRoute

import React from 'react';
import { PropTypes } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Snackbar, SnackbarContent, IconButton }from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { Object } from 'es6-shim';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});


function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

class CustomizedSnackbars extends React.Component {

  state = {
    loading: false,
    openInfo: this.props.info ? true : false,
    openSuccess: (this.props.auth.success || this.props.spaces.success!=='' || this.props.venues.success !=='' || this.props.venues.successDocument !=='' || this.props.spaces.successDocument !==''
                  || this.props.createEvent.success!=='' || this.props.saveEvent.success !==''
                  || this.props.bookEventsuccess!==''
                  || this.props.cmsSuccess !==''
                  || this.props.follow.success!=='' || this.props.contactData.success !=='' || this.props.updateEvent.success!==''
                  || this.props.deleteEvent.success!==''
                  || this.props.unblockEvent.success!==''
                  ) ? true : false,
    warning: '',
    openError: (this.props.auth.error || this.props.spaces.error !=='' || this.props.venues.error !==''
                || this.props.createEvent.error!=='' || this.props.saveEvent.error !==''
                || this.props.bookEventfailure!==''
                || this.props.cmsError !==''
                || this.props.follow.error!=='' || this.props.contactData.error !=='' || this.props.updateEvent.error!==''
                || this.props.deleteEvent.error!==''
                || this.props.unblockEvent.error!==''
                ) ? true : false,
    error: '',
    success: ''
  };

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, snackBarName) => {
    this.setState({ open: false, [snackBarName]:false });
  };

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.auth.error !== nextProps.auth.error && nextProps.auth.error !== ''){
      this.setState({openError: nextProps.auth.error, error: nextProps.auth.error})
    }
    if(this.props.auth.success !== nextProps.auth.success && nextProps.auth.success !== ''){
      this.setState({openSuccess: nextProps.auth.success, success:nextProps.auth.success})
    }
    if(this.props.spaces.error !== nextProps.spaces.error && nextProps.spaces.error !== ''){
      this.setState({openError: nextProps.spaces.error, error: nextProps.spaces.error})
    }
    if(this.props.spaces.success !== nextProps.spaces.success && nextProps.spaces.success !== ''){
      this.setState({openSuccess: nextProps.spaces.success,success: nextProps.spaces.success})
    }
    if(this.props.bookEventfailure !== nextProps.bookEventfailure && nextProps.bookEventfailure !== ''){
      this.setState({openError: nextProps.bookEventfailure, error: nextProps.bookEventfailure})
    }
    if(this.props.bookEventsuccess !== nextProps.bookEventsuccess && nextProps.bookEventsuccess !== ''){
      this.setState({openSuccess: nextProps.bookEventsuccess,success: nextProps.bookEventsuccess})
    }
    if(this.props.venues.error !== nextProps.venues.error && nextProps.venues.error !== ''){
      this.setState({openError: nextProps.venues.error, error: nextProps.venues.error})
    }
    if(this.props.venues.success !== nextProps.venues.success && nextProps.venues.success !== ''){
      this.setState({openSuccess: nextProps.venues.success, success: nextProps.venues.success})
    }
    if(this.props.venue.error !== nextProps.venue.error && nextProps.venue.error !== ''){
      this.setState({openError: nextProps.venue.error, error: nextProps.venue.error})
    }
    if(this.props.venue.success !== nextProps.venue.success && nextProps.venue.success !== ''){
      this.setState({openSuccess: nextProps.venue.success, success: nextProps.venue.success})
    }
    if(this.props.venues.successDocument !== nextProps.venues.successDocument && nextProps.venues.successDocument !== ''){
      this.setState({openSuccess: nextProps.venues.successDocument, success: nextProps.venues.successDocument})
    }
    if(this.props.spaces.successDocument !== nextProps.spaces.successDocument && nextProps.spaces.successDocument !== ''){
      this.setState({openSuccess: nextProps.spaces.successDocument, success: nextProps.spaces.successDocument})
    }
    if(this.props.createEvent.error !== nextProps.createEvent.error && nextProps.createEvent.error !== ''){
      this.setState({openError: nextProps.createEvent.error, error: nextProps.createEvent.error})
    }
    if(this.props.createEvent.success !== nextProps.createEvent.success && nextProps.createEvent.success !== ''){
      this.setState({openSuccess: nextProps.createEvent.success,success: nextProps.createEvent.success})
    }
    if(this.props.updateEvent.error !== nextProps.updateEvent.error && nextProps.updateEvent.error !== ''){
      this.setState({openError: nextProps.updateEvent.error, error: nextProps.updateEvent.error})
    }
    if(this.props.updateEvent.success !== nextProps.updateEvent.success && nextProps.updateEvent.success !== ''){
      this.setState({openSuccess: nextProps.updateEvent.success,success: nextProps.updateEvent.success})
    }
    if(this.props.saveEvent.error !== nextProps.saveEvent.error && nextProps.saveEvent.error !== ''){
      this.setState({openError: nextProps.saveEvent.error, error: nextProps.saveEvent.error})
    }
    if(this.props.saveEvent.success !== nextProps.saveEvent.success && nextProps.saveEvent.success !== ''){
      this.setState({openSuccess: nextProps.saveEvent.success,success: nextProps.saveEvent.success})
    }

    if(this.props.subscribe.error !== nextProps.subscribe.error && nextProps.subscribe.error !== ''){
      this.setState({openError: nextProps.subscribe.error, error: nextProps.subscribe.error})
    }
    if(this.props.subscribe.success !== nextProps.subscribe.success && nextProps.subscribe.success !== ''){
      this.setState({openSuccess: nextProps.subscribe.success,success: nextProps.subscribe.success})
    }
    
    if(this.props.deleteEvent.error !== nextProps.deleteEvent.error && nextProps.deleteEvent.error !== ''){
      this.setState({openError: nextProps.deleteEvent.error, error: nextProps.deleteEvent.error})
    }
    if(this.props.deleteEvent.success !== nextProps.deleteEvent.success && nextProps.deleteEvent.success !== ''){
      this.setState({openSuccess: nextProps.deleteEvent.success,success: nextProps.deleteEvent.success})
    }

    if(this.props.unblockEvent.error !== nextProps.unblockEvent.error && nextProps.unblockEvent.error !== ''){
      this.setState({openError: nextProps.unblockEvent.error, error: nextProps.unblockEvent.error})
    }
    if(this.props.unblockEvent.success !== nextProps.unblockEvent.success && nextProps.unblockEvent.success !== ''){
      this.setState({openSuccess: nextProps.unblockEvent.success,success: nextProps.unblockEvent.success})
    }

    if(this.props.follow.error !== nextProps.follow.error && nextProps.follow.error !== ''){
      this.setState({openError: nextProps.follow.error, error: nextProps.follow.error})
    }
    if(this.props.follow.success !== nextProps.follow.success && nextProps.follow.success !== ''){
      this.setState({openSuccess: nextProps.follow.success,success: nextProps.follow.success})
    }
    if(this.props.contactData.error !== nextProps.contactData.error && nextProps.contactData.error !== ''){
      this.setState({openError: nextProps.contactData.error, error: nextProps.contactData.error})
    }
    if(this.props.contactData.success !== nextProps.contactData.success && nextProps.contactData.success !== ''){
      this.setState({openSuccess: nextProps.contactData.success,success: nextProps.contactData.success})
    }
    if(this.props.masterError !== nextProps.masterError && nextProps.masterError !== ''){
      this.setState({openError: true, error: nextProps.masterError})
    }
    if(this.props.masterSuccess !== nextProps.masterSuccess && nextProps.masterSuccess !== ''){
      this.setState({openSuccess: true,success: nextProps.masterSuccess})
    }
    if(this.props.cmsError !== nextProps.cmsError && nextProps.cmsError !== ''){
      this.setState({openError: true, error: nextProps.cmsError})
    }
    if(this.props.cmsSuccess !== nextProps.cmsSuccess && nextProps.cmsSuccess !== ''){
      this.setState({cmsSuccess: true,success: nextProps.cmsSuccess})
    }
    if(this.props.planError !== nextProps.planError && nextProps.planError !== ''){
      this.setState({openError: true, error: nextProps.planError})
    }
    if(this.props.planSuccess !== nextProps.planSuccess && nextProps.planSuccess !== ''){
      this.setState({openSuccess: true,success: nextProps.planSuccess})
    }
    if(this.props.cmsError !== nextProps.cmsError && nextProps.cmsError !== ''){
      this.setState({openError: true, error: nextProps.cmsError})
    }
    if(this.props.cmsSuccess !== nextProps.cmsSuccess && nextProps.cmsSuccess !== ''){
      this.setState({openSuccess: true,success: nextProps.cmsSuccess})
    }
    if(this.props.venues.venueError !== nextProps.venues.venueError && Object.keys(nextProps.venues.venueError).length > 0){
      let error = ''
      Object.keys(nextProps.venues.venueError).map((key) => (
        error = error + nextProps.venues.venueError[key][0]
      ));
      this.setState({openError: true, error: error})
    }
    if(this.props.spaces.spaceError !== nextProps.spaces.spaceError && Object.keys(nextProps.spaces.spaceError).length > 0){
      let error = ''
      Object.keys(nextProps.spaces.spaceError).map((key) => (
        error = error + nextProps.spaces.spaceError[key][0]
      ));
      this.setState({openError: true, error: error})
    }
    // if(this.props.pageData.error !== nextProps.pageData.error && nextProps.pageData.error !== ''){
    //   this.setState({openError: nextProps.pageData.error, error: nextProps.pageData.error})
    // }
    if (nextProps.auth.sending === true || nextProps.spaces.sending === true || nextProps.venues.sending === true
      || nextProps.createEvent.sending === true || nextProps.saveEvent.sending === true || nextProps.follow.sending === true
      || nextProps.contactData.sending === true || nextProps.browseVenueData.sending === true )
    {
      this.setState({loading:true})
    } else {
      if (nextProps.auth.sended === false || nextProps.spaces.sended === true || nextProps.venues.sended === true
        || nextProps.createEvent.sended === false || nextProps.saveEvent.sended === true || nextProps.follow.sended === true
        || nextProps.contactData.sended === false || nextProps.browseVenueData.sended === true)
      {
        this.setState({loading:false})
      }
    }
  }

  render() {
    // const { classes } = this.props;
    return (
      <div className="error-messages-snackbar">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openSuccess}
          autoHideDuration={8000}
          onClose={(e) => {this.handleClose(e, "openSuccess")}}
        >
          <MySnackbarContentWrapper
            onClose={(e) => {this.handleClose(e, "openSuccess")}}
            variant="success"
            message={this.state.success}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openWarning}
          // autoHideDuration={6000}
          onClose={(e) => {this.handleClose(e, "openWarning")}}
        >
          <MySnackbarContentWrapper
            onClose={(e) => {this.handleClose(e,"openWarning")}}
            variant="warning"
            message={this.state.warning}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openError}
          autoHideDuration={8000}
          onClose={(e) => {this.handleClose(e,"openError")}}
        >
          <MySnackbarContentWrapper
            onClose={(e) => {this.handleClose(e, "openError")}}
            variant="error"
            message={this.state.error}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openInfo}
          // autoHideDuration={6000}
          onClose={(e) => {this.handleClose(e,'openInfo')}}
        >
          <MySnackbarContentWrapper
            onClose={(e) => {this.handleClose(e, "openInfo")}}
            variant="info"
            message={this.props.info}
          />
        </Snackbar>
         {/* <LinearProgress color="secondary" /> */}
         {/* {this.state.loading ?
         <CircularProgress />
         :''
        } */}
        {/* <Snackbar style={{top:'100px'}}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.loading}
        >
          <CircularProgress color="secondary" />
        </Snackbar> */}
      </div>
    );
  }
}

CustomizedSnackbars.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {

    error: state.data.auth.error ? state.data.auth.error : state.data.venues.error ? state.data.venues.error : state.data.saveExit.error ? state.data.saveExit.error : '',
    success: state.data.auth.success ? state.data.auth.success : state.data.spaces.successDocument ? state.data.spaces.successDocument  : state.data.venues.successDocument ? state.data.venues.successDocument : state.data.venues.success ? state.data.venues.success : state.data.saveExit.success ? state.data.saveExit.success : '',
    contactData: state.data.contactData,
    auth: state.data.auth,
    spaces: state.data.spaces,
    venues: state.data.venues,
    venue: state.data.venue,
    createEvent: state.data.createEvent,
    saveEvent: state.data.saveEvent,
    follow: state.data.followUnfollowSuccess,
    masterError: state.data.master.error,
    masterSuccess: state.data.master.success,
    cmsError: state.data.cms.error,
    cmsSuccess: state.data.cms.success,
    planError: state.data.plan.error,
    planSuccess: state.data.plan.success,
    browseVenueData: state.data.browseVenueData,
    updateEvent:state.data.updateEvent,
    deleteEvent:state.data.deleteEvent,
    spaceError:state.data.spaces.spaceError,
    venueError: state.data.venues.venueError,
    bookEventsuccess:state.data.bookEvent.success,
    bookEventfailure:state.data.bookEvent.error,
    cmsSuccess:state.data.cms.success,
    cmsError:state.data.cms.error,
    unblockEvent:state.data.unblockEvent,    
    subscribe: state.data.subscribe,


    // pageData:state.data.pageDetails.error
  };
};

const enhance = compose(
  withRouter,
  withStyles(styles1),
  connect(mapStateToProps)
)
export default enhance(CustomizedSnackbars);

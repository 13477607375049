import React from 'react';
import { Grid, Button } from '@material-ui/core';
import '../assets/styles/venueProfileEditComponent.scss';
import { Field } from 'formik';
import ExpansionSection from './expansionSection';
import { featureTypes } from 'shared/featureTypes/actions';
import { object, func } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";

import VenuePdfEdit from './../components/venuePdfEdit';
import VenueAttractionEdit from './../components/venueAttractionEdit';

import CustomizedButton from '../../shared/components/customizedButton';
// import * as Yup from 'yup';
// import addPdf from './addPdf';

import ErrorPopup from '../../shared/components/errorPopup';
import withWidth from '@material-ui/core/withWidth';

class VenueProfileEditComponent extends React.Component {
constructor(props) {
super(props);
this.handleAddPdfClick = this.handleAddPdfClick.bind(this);

}
state={
features:{},
addCustError:"",
comCustError:"",
// pdfData:[]

}

handleAddPdfClick() {
}
componentWillMount(){
this.setState({features:{}})

this.props.featureTypes().then((response) => {
})

}

UNSAFE_componentWillReceiveProps(nextProps){
this.setState({pdfData:nextProps.pdfData})
if(nextProps.values.complimentaryCustom.length===0){
this.setState({comCustError:"*Required"})

}
else if(nextProps.values.complimentaryCustom.length > 100){
this.setState({comCustError:"*Should not be greater than 100 characters"})

}
else{
this.setState({comCustError:""})

}
if(nextProps.values.additionalCustom.length===0){
this.setState({addCustError:"*Required"})
}
else if(nextProps.values.additionalCustom.length > 100){
this.setState({addCustError:"*Should not be greater than 100 characters"})

}
else{
this.setState({addCustError:""})

}
if(this.props.featureData !== nextProps.featureData){
this.setState({features: nextProps.featureData})
}
}
render() { 




console.log('ssss',this.props.width);
return (
this.props.featureData === {}?
"loading...":
<Grid container className="venue-profile-edit">
{this.props.editvenueError===true ? 
<ErrorPopup errorClose={this.props.errorClose} message={this.props.editvenueErrorMessage} openError={this.props.editvenueError} />
:
null
}
<Grid xs={12} item>
<Grid container>

<Grid container style={{width: '95%'}} className="wid-100">
<Grid style={{display: 'block',width:'100%'}}>
<div className="left-side">
<h3 className="black-heading">Venue Description</h3>
</div>
<div className="right-side">
{
 this.props.loader===true?
 <img style={{height:'34px',marginTop:'0px',paddingTop:'0px'}} src={require('../../shared/components/assets/images/loading.gif')} alt="Venue" /> 
 :
<Button onClick={()=>this.props.editProfileSave(this.props.values)} class="white-button">
<p style={{color:'#ee2738'}}>Edit Profile</p></Button>
}
</div>
</Grid>
<Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
<Field
component='textarea'
value={this.props.venueDetails.description}
className="textarea"
name="description"
placeholder="Enter description (required)"
title="Change Venue Description in Edit Profile"
/>
</Grid>
<ExpansionSection
features={this.props.features}
addCustError={this.state.addCustError}
comCustError={this.state.comCustError}
showComplimentaryCustom={this.props.showComplimentaryCustom} 
showAdditionalCustom={this.props.showAdditionalCustom} 
showFoodCustom={this.props.showFoodCustom} 
showParkingCustom={this.props.showParkingCustom} 
showRestrictionCustom={this.props.showRestrictionCustom} 
handleAdditionalCustomClick={this.props.handleAdditionalCustomClick} 
handleParkingCustomClick={this.props.handleParkingCustomClick} 
handleFoodCustomClick={this.props.handleFoodCustomClick} 
handleRestrictionCustomClick={this.props.handleRestrictionCustomClick} 
handleComplimentaryCustomClick={this.props.handleComplimentaryCustomClick} 
values={this.props.values} 
handleChange={this.props.handleChange} 
featureData={this.state.features} />

<div className="grid-section-vertical">
{/* <AddPdfComponent 
pdfData={this.state.pdfData}
venueDetails={this.props.venueDetails} 
type="VENUE" 
id={this.props.venueDetails.id} 
handleDocumentUploaded={this.props.handleDocumentUploaded}/> */}

</div>
<CustomizedButton
height='24px'
width='80px'
label="Done"
type="submit"
id="submitSave"
visibility="hide"
/>
{   this.props.width ==='xs' &&
    <div className="mobile-pdf">
    <VenueAttractionEdit />
    <VenuePdfEdit pdfData={this.props.pdfData}
    venueData={this.props.venueDetails}
    videos={this.props.videos}
    handleVideoAdd={this.props.handleVideoAdd}
    handleVideoUpdate={this.props.handleVideoUpdate}
    handleVideoDeleted={this.props.handleVideoDeleted}
    handleDocumentUploaded={this.props.handleDocumentUploaded}
    handleDocumentDeleted={this.props.handleDocumentDeleted} 
    venueDetails={this.props.pdfData} 
    venueId={this.props.match.params.venueId} /> 
    </div> 
}
</Grid>
</Grid>
</Grid>
</Grid>
);
}
}

VenueProfileEditComponent.propTypes = {
featureTypes:func.isRequired
};
const mapStateToProps = state => {
return {
featureData: state.data.featureTypes.featureList
};
};
const enhance = compose(
withRouter,
withWidth(),
connect(mapStateToProps, dispatch =>
bindActionCreators(
{
featureTypes
},
dispatch
)
)
);
export default enhance(VenueProfileEditComponent);


import axios from 'axios';
import { GET_CREATE_FOLLOW_DATE_REQUEST, GET_CREATE_FOLLOW_DATE_SUCCESS, GET_CREATE_FOLLOW_DATE_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function createfollowDateRequested() {
  return {
    type: GET_CREATE_FOLLOW_DATE_REQUEST
  };
}

function createfollowDateSuccess(data) {
  return {
    type: GET_CREATE_FOLLOW_DATE_SUCCESS,
    followList: data,
  };
}

function createfollowDateFailure(error) {
  return {
    type: GET_CREATE_FOLLOW_DATE_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }

  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export function followAPI(params) {
    return dispatch => {
      dispatch(createfollowDateRequested());
        return request().post(`event_follow_dates`,params)
        .then(response =>
          dispatch (createfollowDateSuccess(response.data),
                ))
        .catch(errors => {
          dispatch(createfollowDateFailure(errors.response));
  
          throw errors;
        });
    
   

    
 };
}

import React from 'react';
import PropTypes from 'prop-types';

import CustomInput from '../../shared/components/CustomInput';
import CustomizedButton from '../../shared/components/customizedButton';
import ImageUpload from '../../shared/components/ImageUpload';
import { Formik, Field } from 'formik';
import { Grid, Hidden, Link ,Icon} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    icon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    root: {
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    typography: {
        width: '237px',
        height: '120px',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        fontFamily: 'Raleway'
    }
});

function PersonalInfo(props) {
    const { deleteImage, profilePhotoId } = props;
    const error=props.error;
    const saveClick=props.saveClick;
    return (
        <div>
            <Grid container className="edit_profile_component">
                <Grid className="headings" item xs={12}>
                    <h4 className="form_name">Personal Info</h4>
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={props.initialValues}
                        enableReinitialize
                        validationSchema={props.validationSchema}
                        onSubmit={(values, actions) => {
                            props.handleSubmit(values, actions)
                        }}
                        render={props => (
                            <form onSubmit={props.handleSubmit}>
                                <Grid container>
                                    <Grid className="save_button" item xs={12}>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <ImageUpload saveClick={saveClick} handleChange={props.handleChange} name="image" value={props.values.image} setFieldValue={props.setFieldValue}/>
                                                {
                                                    profilePhotoId &&
                                                    <Link className="delete-image" onClick={(e)=>{deleteImage(e)}}>Delete</Link>

                                                }
                                            <div style={{color:'red',fontSize:'10px'}}>{error}</div>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Hidden xsDown={true}>
                                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Grid container className="edit_profile_component_form">
                                            <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div className="field_div">
                                                    <CustomInput label="First Name" type="text" name="firstName" placeholder="First Name" />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div className="field_div">
                                                    <CustomInput label="Last Name" type="text" name="lastName" placeholder="Last Name" />
                                                </div>
                                            </Grid>
                                            <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}> 
                                                <div className="field_div">
                                                    <CustomInput label="Phone Number" type="phone" name="phoneNumber" placeholder="(XXX)-XXX-XXXX" />
                                                    {  props.values.phoneVerification? 
                                                        <div className="verified" >
                                                            <Icon>check_circle</Icon> <p>Verified</p>
                                                        </div>  
                                                        :
                                                        <div className="verify-it" >
                                                           <Icon>cancel_circle</Icon> <p>Not Verified</p>
                                                        </div>  
                                                    }
                                                </div> 
                                                                                            
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div className="field_div">
                                                    <CustomInput label="Ext." type="text" name="extension" />
                                                </div>
                                            </Grid>                                       
                                            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className="field_div"> */}
                                                    <div className="checkbox">
                                                        {/* {(props.value.type==0)} ? */}
                                                        {localStorage.getItem('user_type') === '0' ?
                                                                <label className="container">Display my number to venue manager
                                                                    <Field type="checkbox"
                                                                        name="displayMyNumber"
                                                                        checked={props.values.displayMyNumber}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>                                                   
                                                        :localStorage.getItem('user_type') === '1' &&

                                                        <label className="container">Display my number to planners
                                                                    <Field type="checkbox"
                                                                        name="displayMyNumber"
                                                                        checked={props.values.displayMyNumber}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                        }
                                                    </div>
                                                {/* </div>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                    <Grid className="save_button" item xs={12}>
                                        <Grid container justify='flex-end'>
                                            <Grid item>
                                                <CustomizedButton label="Save" type="submit" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Hidden xsDown={true}>
                                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                    </Hidden>
                                </Grid>
                            </form>
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

PersonalInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

const enhance = withStyles(styles)

export default enhance(PersonalInfo);

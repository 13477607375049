import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getVenue } from 'data/venues/actions';

import { Grid, Button } from '@material-ui/core';

import './assets/styles/venueComplete.scss';

class VenueComplete extends Component {
  handleOnLoad = () => {
    const id = this.props.match.params.id
    this.props.getVenue(id)
  }

  render() {
    return (
      <div>
        <Grid container className="venue-complete" onLoad={e => { this.handleOnLoad(e) }}>
          <Grid xs={12} item>
            <Grid container className="mask">

              <Grid item md={9} xs={12} sm={9} xl={9} lg={3}>
              </Grid>

              <Grid item md={9} xs={12} sm={9} xl={9} lg={6} className="venueCompleteContent">

                <img className="check_circle---material" src={require('./assets/images/green-circle-tick.png')} alt="Venue Complete" />

                <h2 className="venue-name">{this.props.venueDetails.name}</h2>

                <p className="thankYouText">
                  Thank you for setting up your venue profile.<br />
                  Next we’ll have you complete your public facing page.<br />
                  We’ll need you to complete this before submitting to us for approval.<br />
                  Once submitted, approval will take less than 24 hours.<br />
                </p>

                <Button variant="outlined" color="secondary" className="button-bg" component={Link} to={`/venueProfileEdit/${this.props.match.params.id}`}>
                  <span className="button-text">Customize Venue Profile</span>
                </Button>
              </Grid>

              <Grid item md={9} xs={12} sm={9} xl={9} lg={3}>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    venueDetails: state.data.venues.venueDetails
  };
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    getVenue
  }, dispatch),
)(withRouter(VenueComplete));


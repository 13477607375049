import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {registerUser, getStates, imageUpload} from 'data/person/actions';
import {FormikWizard} from 'formik-wizard';
import {withRouter} from 'react-router-dom';
import {object, string, boolean} from 'yup';
import PersonalInfo from './views/personalInfo';
import CompanyInfo from './views/companyInfo';
import {getUploadPostUrl, uploadtoS3} from 'data/master/actions';
const phoneRegExp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$//* eslint-disable-line */
class mobileSignUp extends Component {

    static propTypes = {
        registerUser: PropTypes.func.isRequired
    };

    state = {
        toFoobar: false, 
        type: 0,
        first_name: '',
        last_name: '',
        email:'',
        password:'',
        phone_number : '',
        phone_ext: '1',
        display_phone_number: false,
        company_name: '',            
        line_1: '',
        line_2: '',
        city: '',
        state: '',
        country: 'US',
        zip: '',
        job_title:'',
        file: {},
        openError: true,
        isMobileDisable:false
    };

    UNSAFE_componentWillMount() {
        this.props.getStates();
        let verifiedMobileNo    = localStorage.getItem('verified_mobile_no');
        if(verifiedMobileNo){
          this.setState({phone_number:verifiedMobileNo,isMobileDisable:true});
        }

    }
    componentWillReceiveProps(nextProps) {}

    buildFormData = (formData, data, parentKey) => {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object
                .keys(data)
                .forEach(key => {
                    this.buildFormData(formData, data[key], parentKey
                        ? `${parentKey}[${key}]`
                        : key);
                });
        } else {
            const value = data == null
                ? ''
                : data;
            formData.append(parentKey, value);
        }
    }

    handleSubmit = (values) => {
        localStorage.removeItem('personalInfoImage');
        let formData;
        formData = new FormData();
        let obj;
        obj = {
            type: 0,
            first_name: values.personal.first_name,
            last_name: values.personal.last_name,
            email: values.personal.email,
            password: values.personal.password,
            phone_number: values.personal.phone_number,
            phone_ext: values.personal.extension,
            newsletter_enabled: values.company.newsletter?true:false,
            display_phone_number: values.personal.display_number
                ? 1
                : 0,
            company_name: values.company.company_name,
            company_address: {
                line_1: values.company.line_1,
                line_2: values.company.line_2,
                city: values.company.city,
                state: values.company.state,
                country: 'US',
                zip: values.company.zipcode
            },
            address: {
                line_1: values.company.line_1,
                line_2: values.company.line_2,
                city: values.company.city,
                state: values.company.state,
                country: 'US',
                zip: values.company.zipcode
            }
        };

        this.buildFormData(formData, obj);
        if (values.personal.image) {
            this
                .props
                .getUploadPostUrl(1, values.personal.image.name)
                .then((res) => {
                    this
                        .props
                        .uploadtoS3(res.url, values.personal.image)
                        .then(() => {
                            formData.append('photo', res.keyName)
                            this
                                .props
                                .registerUser(formData,true)
                                .then((response) => {
                                  const widgetPath=localStorage.getItem('widget_path');
                                  if(widgetPath){                                   
                                    localStorage.removeItem('verified_mobile_no');  
                                    localStorage.removeItem('verified_ext_mobile_no');
                                    setTimeout(() => {
                                        this.props.history.push(widgetPath)
                                    }, 1000);                                   
                                  }else{
                                    window.location.href = "./myVenue";
                                  }
                                })
                        })
                })
        } else {
            const queryParameters = new URLSearchParams(window.location.search)
            formData.append('venue_id', queryParameters.get("venueId"))            
            this
                .props
                .registerUser(formData,true)
                .then((response) => {
                    const widgetPath=localStorage.getItem('widget_path');
                    if(widgetPath){                  
                        localStorage.removeItem('verified_mobile_no');  
                        localStorage.removeItem('verified_ext_mobile_no');
                        setTimeout(() => {
                            this.props.history.push(widgetPath)
                        }, 1000);
                    }else{
                        window.location.href = "./myVenue";
                    }
                });
        }

    }; 

    render() {
        return (
            <div>
                <img
                    id="scream"
                    style={{
                    display: "none"
                }}
                    width="220"
                    height="277"
                    src={this.props.callbackData
                    ? this.props.callbackData.data.person.photo
                    : 'https://image.shutterstock.com/image-vector/sample-stamp-square-grunge-sign-260n' +
                        'w-1474408826.jpg'}
                    alt="The Scream"
                    crossOrigin="anonymous"/>
                <canvas
                    style={{
                    display: "none"
                }}
                    id="myCanvas"
                    width="240"
                    height="297"></canvas>

                <FormikWizard
                    steps={[
                    {
                        id: 'personal',
                        component: PersonalInfo,
                        initialValues: {
                            first_name: this.state.first_name,
                            last_name: this.state.last_name,
                            email: this.state.email,
                            password: this.state.password,
                            phone_number: this.state.phone_number,
                            extension: this.state.phone_ext,
                            display_number: this.state.display_phone_number,
                            image: undefined,
                            isMobileDisable:this.state.isMobileDisable,
                            hideExt:true
                        },
                        validationSchema: object().shape({
                            first_name: string()
                                .required("*First Name is required")
                                .matches(/^[ A-Za-z_@./()&-]*$/,"*Invalid First Name")
                                .max(100, '*First Name should not be greater than 100 characters'),
                            last_name: string()
                                .required("*Last Name is required")
                                .matches(/^[ A-Za-z_@./()&-]*$/,"*Invalid Last Name")
                                .max(100, '*Last Name should not be greater than 100 characters'),
                            email: string()
                                .email()
                                .required("*Email is required")
                                .max(100, '*Email should not be greater than 100 characters'),
                            password: string()
                                .min(8)
                                .required("*Password is required")
                                .max(100, '*Password should not be greater than 100 characters'),
                            phone_number: string()
                                .matches(phoneRegExp, 'Phone number is not valid')
                                .required("*Phone Number is required")
                                .min(4, '*Phone Number should not be less than than 4 digit')
                                .max(20, '*Phone number should not be greater than 20 digit'),
                            image: string(),
                            extension: string()
                                .matches(/^[0-9]*$/, '*Extension is not valid')
                                .max(8, '*Should not be greater than 8 digit')
                        })
                    }, {
                        id: 'company',
                        component: CompanyInfo,
                        initialValues: {
                            company_name: this.state.company_name,
                            line_1: this.state.line_1,
                            line_2: this.state.line_2,
                            city:this.state.city,
                            state: this.state.state,
                            zipcode: this.state.zipcode,
                            job_title: this.state.job_title,
                            newsletter:true,
                            agree: false
                        },
                        validationSchema: object().shape({
                            company_name: string().max(100, '*Company Name should not be greater than 100 characters'),
                            line_1: string()
                                .required("*Line 1 is required")
                                .max(255, '*Line1 should not be greater than 255 characters'),
                            line_2: string().max(255, '*Line2 should not be greater than 255 characters'),
                            city: string()
                                .required("*City is required")
                                .max(255, '*City should not be greater than 255 characters'),
                            state: string()
                                .required("*State is required")
                                .max(255, '*State should not be greater than 255 characters'),
                            zipcode: string()
                                .required("*Zipcode is required")
                                .matches(/(^[a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d$)|(^\d{5}(-\d{4})?$)/,"*Zip Code is not valid")
                                .max(16, '*Zip Code should not be greater than 16 characters'),
                            job_title: string()
                                ///.required('*Job Title is required')
                                .max(150, '*Job Title should not be greater than 150 characters'),
                                agree: boolean().required("The terms and conditions must be selected.")
                               .oneOf([true], "The terms and conditions must be selected."),
                        })
                    }
                ]}
                    onSubmit={this.handleSubmit}
                    render={({
                    children,
                    isLastStep,
                    status,
                    goToPreviousStep,
                    canGoBack,
                    actionLabel
                }) => (
                    <div>
                        {status && (
                            <div>
                                {status.message}
                                <hr/>
                            </div>
                        )}
                        {children}
                    </div>
                )}/>
            </div>
        );
    }
}

mobileSignUp.propTypes = {
classes: object.isRequired
};

const mapStateToProps = state => {
return {};
};

const enhance = compose(withRouter, connect(mapStateToProps, dispatch => bindActionCreators({
registerUser,
getStates,
imageUpload,
getUploadPostUrl,
uploadtoS3
}, dispatch)));
export default enhance(mobileSignUp);

import { GET_NOTIFICATIONS } from 'shared/state/types';
import { request } from 'shared/auth';

function getNotificationsRequested() {
  return {
    type: GET_NOTIFICATIONS.REQUEST,
  };
}

function getNotificationsFailed(error) {
  return {
    type: GET_NOTIFICATIONS.FAILURE,
    error:error,
  };
}

function getNotificationsSuccess(data) {
  return {
    type: GET_NOTIFICATIONS.SUCCESS,
    data: data,
  };
}

export function getNotifications(page) {
  return dispatch => {
    dispatch(getNotificationsRequested());

    return request().get(`/notifications?page=${page}`)
      .then(response =>{
            dispatch(getNotificationsSuccess(response.data))
            return response.data

        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(getNotificationsFailed(errors.response.data.message));
        }
        else{
          dispatch(getNotificationsFailed("Unable to access api"));
        }
        throw errors;
      });
  };
}

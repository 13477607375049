import { CHANGE_PASSWORD } from 'shared/state/types';
import { request } from 'shared/auth';

function changePasswordRequested() {
  return {
    type: CHANGE_PASSWORD.REQUEST,
  };
}

function changePasswordFailed(error) {
  return {
    type: CHANGE_PASSWORD.FAILURE,
    error:error,
  };
}

function changePasswordSuccess(data) {
  return {
    type: CHANGE_PASSWORD.SUCCESS,
    payload: data,
  };
}

export function changePassword(params) {
  return dispatch => {
    dispatch(changePasswordRequested());

    return request().post('people/me/change_password', params)
      .then(response =>{
          dispatch(changePasswordSuccess())
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(changePasswordFailed(errors.response.data.message));
        }
        else{
          dispatch(changePasswordFailed('Unable to access api'));
        }
        throw errors;
      });
  };
}

import axios from 'axios';
import {UNBLOCK_EVENT_REQUEST, UNBLOCK_EVENT_SUCCESS, UNBLOCK_EVENT_FAILURE} from 'shared/state/types';
import {loadProgressBar} from 'axios-progress-bar'

function onRequested() {
    return {type: UNBLOCK_EVENT_REQUEST};
}

function onSuccess(data) {
    return {type: UNBLOCK_EVENT_SUCCESS, eventUpdateData: data};
}

function onFailure(error) {
    return {type: UNBLOCK_EVENT_FAILURE, error: error};
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
    const headers = {};

    if (localStorage.getItem('fhtoken')) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

    }

    const axiosInstance = axios.create({baseURL, headers})
    loadProgressBar({
        showSpinner: true
    }, axiosInstance)
    return axiosInstance
};

export function unblockEvent(params) {
    return dispatch => {
        dispatch(onRequested());
        return request()
            .post(`event_timeslots/unblock`, params)
            .then(response => dispatch(onSuccess(response.message),))
            .catch(errors => {
                dispatch(onFailure(errors));

                throw errors;
            });

    };
}

import React, { Component, Fragment } from "react";
import { object, func } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { googleKeyData } from "shared/googleKeyData/actions";

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";


class CustomizedGoogleMap extends Component {
state={
    googleMapURL:'',
}
    UNSAFE_componentWillMount() {
        this.props.googleKeyData().then(() => {
            this.setState({googleMapURL:`https://maps.googleapis.com/maps/api/js?key=${this.props.googleKeyDetails && this.props.googleKeyDetails.google_map_key}&libraries=geometry,drawing,places`})
        })
    }
    onMarkerClick = (id) => {
        if(this.props.venue){

        }
        else{
            window.location.href = "/venue-profile/"+id;
        }
    }
    CMap = withScriptjs(withGoogleMap(props =>
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: this.props.latitude ? this.props.latitude:this.props.markers[0] ? this.props.markers[0].latitude:0 , lng: this.props.longitude ? this.props.longitude : this.props.markers[0] ? this.props.markers[0].longitude : 0}}
            defaultOptions={{
                panControl: true,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: true,
                overviewMapControl: true,
                rotateControl: true
            }}
        >
            {props.children}
        </GoogleMap>
    ));



    render() {
        return (
            this.state.googleMapURL ?
            <Fragment>  
                <this.CMap
                    googleMapURL={this.state.googleMapURL}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: this.props.height }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    // center={{ lat: 37.77, lng: 122.42 }}
                >
                    {this.props.markers.length > 0 && this.props.markers.map(mark => (
                        
                        <Marker
                            onClick={()=>this.onMarkerClick(mark.id)}
                            key={mark.id}
                            position={{ lat: mark.latitude, lng: mark.longitude }}
                        >
                    
                        </Marker>
                    ))}

                </this.CMap>
            </Fragment>
            :null
        );
    }
}



CustomizedGoogleMap.propTypes = {
    classes: object.isRequired,
    googleKeyData: func.isRequired,

  
  };
  
  const mapStateToProps = state => {
    return {
      googleKeyDetails: state.data.googleKeyData.googleKey,
    
    };
  };
  
  const enhance = compose(
    withRouter,
    connect(mapStateToProps, dispatch =>
      bindActionCreators(
        {
        googleKeyData
        },
        dispatch
      )
    )
  );
  export default enhance(CustomizedGoogleMap);
  
import { request } from 'shared/auth';
import { LOGIN_MOBILE } from 'shared/state/types';

function onRequested() {
  return {
    type: LOGIN_MOBILE.REQUEST,
  };
}

function onSuccess(data) {
  return {
    type: LOGIN_MOBILE.SUCCESS,
    payload: data,
  };
}

function onFailure(data) {
  return {
      type: LOGIN_MOBILE.FAILURE,
      payload: data,
  };
}

export function loginWithMobile(params) {
 return dispatch => {
  dispatch(onRequested());
   return request()
     .post('/auth/login-with-mobile', params)
     .then(response => {dispatch(onSuccess(response.data.message));
        return response.data})
    .catch(errors => {      
      dispatch(onFailure(errors.response.data.message));
      throw errors;
    })
 };
}

import { GET_EVENT_TYPES } from 'shared/state/types';
import { request } from 'shared/auth';

function eventTypesRequested() {
  return {
    type:GET_EVENT_TYPES.REQUEST,
  };
}

function eventTypesFailed(error) {
  return {
    type:GET_EVENT_TYPES.FAILURE,
    error:error,
  };
}

function eventTypesSuccess(data) {
  return {
    type:GET_EVENT_TYPES.SUCCESS,
    eventTypeList: data,
  };
}

export function eventTypes() {
  return dispatch => {
    dispatch(eventTypesRequested());

    return request().get('/event_types')
      .then(response =>{
          dispatch(eventTypesSuccess(response.data.data))
          return response.data.data;
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(eventTypesFailed(errors.response.data.message));
        }
        else{
          dispatch(eventTypesFailed("Unable to access api"));
        }
        throw errors;
      });
  };
}

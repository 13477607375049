import React from 'react';
import '../assets/styles/landing-page.scss';
import { Grid } from '@material-ui/core';
import { Link } from "react-router-dom";
import FeaturedVenues from 'landingPage/components/featuredVenues';
import { homepage } from "shared/homepage/actions";
import { bindActionCreators, compose } from "redux";
import { Redirect } from 'react-router-dom'
import { object, func } from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Footer from 'explore-landing-page/component/footer';
import { Helmet } from "react-helmet";
import Footers from '../../landingPage/components/footer';

const logo = require("landingPage/assets/images/default_venue.png");

function napa(props) {
    const [venueHomePage, setvenueHomePage] = React.useState();
    React.useEffect(() => {
        props.homepage().then((result) => {
            setvenueHomePage(result.homeVenue.data.featuredVenues)
            //alert(JSON.stringify(result.homeVenue.data.featuredVenues)

        })
    }, []);
    let featureVenueData = [];
    venueHomePage && venueHomePage.map(function (data, idx) {
        let obj = {};
        let type = '';
        if (data.type === '1') {
            type = "Conferences";
        }
        else if (data.type === '2') {
            type = "Weddings";
        }
        else if (data.type === '3') {
            type = "Workshops";
        }
        else if (data.type === '4') {
            type = "Trade Shows";
        }
        else if (data.type === '5') {
            type = "Corporate Parties";
        }
        else if (data.type === '6') {
            type = "Fundraisers";
        }

        let stateAbbr = '';
        if (data.address.state_abbr !== null) {
            stateAbbr = data.address.state_abbr;
        }
        obj.explore = "explore";
        obj.id = data.slug;
        obj.image = data.profilePhoto ? data.profilePhoto &&
            data.profilePhoto.path : logo;
        obj.venueName = data.name;
        obj.venueAddress = data.address &&
            data.address.city + ", " + stateAbbr;
        obj.favourite = " ";
        obj.category = type;
        featureVenueData.push(obj);
        return featureVenueData;
    })
    return (
        <div className="">
            <div class="container explore-ui-wrapper">
                <Helmet>
                    <title>Best Event Venues in Napa | Check Availability</title>
                    <meta name="title" content="Best Event Venues in Napa | Check Availability"></meta>
                    <meta name="description" content="Discover the best event venues in Napa with FIRST-HOLD. Check availability and book the perfect space for your next event."></meta>
                    <link rel="canonical" href="https://www.first-hold.com/napa" />
                    <meta name="robots" content="index"></meta>
                    <script type="application/ld+json">{`
                    {
                        "@context":"http://www.schema.org",
                        "@type":"product",
                        "brand":"First Hold",
                        "name":"Best Event Venues in Napa | Check Availability",
                        "image":"https://www.first-hold.com/static/media/LogoWithText.6a00fa2d.png",
                        "description":"Discover the best event venues in Napa with FIRST-HOLD. Check availability and book the perfect space for your next event.",
                        "aggregateRating":{"@type":"aggregateRating",
                        "ratingValue":"4.9",
                        "reviewCount":"1245"
                        }
                        }
                        
                    `}
                    </script>

                </Helmet>
                <Grid container className="topspace">
                    <Grid className="banner-wrapper" item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <form action="/browseVenue" method="get" autocomplete="off">
                            <div className="search-wrapper">
                                <div className="heading-text main">
                                    <h1>Check availability of venues in Napa</h1>
                                </div>
                                <div className="heading-text main">
                                    <p>If you are searching for the best corporate event venues in Napa, look no further than FIRST-HOLD. We are here to assist you in finding the best event venues in Napa that will make your corporate event truly extraordinary and unforgettable.</p>
                                </div>
                                <div className="search-field">
                                    <span class="icon-search">
                                        <img src={require('../assets/images/location-icon.png')} alt="Search" />
                                    </span>
                                    <input type="search" className="form-control" placeholder="Napa" name="address" />
                                </div>
                                <div className="search-btn">
                                    <button type="submit" className="">Search</button>
                                </div>
                            </div>
                        </form>
                    </Grid>
                    <Grid className="" item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div className="text-center-image napa-main">
                            {/* <img className="" src={require('../assets/images/Bay Area - Main Photo.jpg')} alt="FIRST-HOLD"/> */}
                            <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                        </div>
                    </Grid>
                </Grid>
                {/* new block start */}
                <Grid container className="box-wrapper">
                    <Grid className="" item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div className="text-center-image napa-main-1 left-side">
                            {/* <img className="" src={require('../assets/images/Blog - Wedding - Photo2.jpg')} alt="FIRST-HOLD"/> */}
                            <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className="search-wrapper-block">
                            <div className="heading-text ">
                                <h1>How venues work on FIRST-HOLD</h1>
                                <p>FIRST-HOLD matches people planning events to venues with available dates --that's it.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Search size, style and budget</h2>
                                <p>Search for available event venues with the right size, vibe, and budget.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Immediately check availability</h2>
                                <p>Finding an event venue that fits?  Know if your preferred date is
                                    available — even when you’re searching late at night! Place a hold or
                                    contact the venue to learn more.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Communicate directly</h2>
                                <p>FIRST-HOLD connects you directly with venue managers. They call you
                                    to learn more about your event — all while knowing your date is currently available.</p>
                            </div>
                            <div className="search-btn started">
                                <a href="/browseVenue?address=Napa" className="">Get started</a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className="featured-venue-bay box-wrapper">
                    <div className="heading-text">
                        <h1>Featured Napa venues</h1>
                    </div>
                    <Grid container className="margin-10">
                        <Grid className="" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="search-wrapper-block two-box">
                                <div className="">
                                    <p>When you search for Napa event venues on FIRST-HOLD, you can search for many different characteristics that are important to your event.</p>
                                    <p className="margin-top-5">If you are looking for the best event venues in Napa, you'll be delighted to discover a wide array of stunning options that cater to various preferences and styles.</p>
                                    <p className="margin-top-5">Our meeting, event and wedding planners love to search by:</p>
                                    <ul>
                                        <li>event date</li>
                                        <li># of guests</li>
                                        <li>seating style (sit-down dinner or reception)</li>
                                        <li>venue rental budget</li>
                                    </ul>
                                </div>
                                <div className="search-field margin-top">
                                    <p><span className="bold">When you find a great venue, here’s the difference:</span> you’ll
                                        know immediately whether your preferred date is available
                                        (even when you’re searching late at night). </p>
                                </div>
                                <div className="search-field margin-top">
                                    <p>
                                        Also, your inquiries go directly to the venue manager and
                                        you’ll both get each other’s contact info. Both you and the
                                        venue communicate directly and avoid in-app chats.
                                    </p>
                                </div>
                                <div className="search-field margin-top">
                                    <p>All these features mean you’re <span className="bold">saving time in your day </span> and <span className="bold">reducing meeting and event planning stress</span> when you use FIRST-HOLD
                                        to search for venues.</p>
                                </div>
                                <div className="search-btn started">
                                    <a href="/browseVenue?address=Napa" className="">Check them out</a>
                                </div>
                            </div>
                        </Grid>
                        <Grid className="" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="text-center-image third-section napa-main-2">
                                {/* <img className="" src={require('../assets/images/San Francisco - Bay Area1.jpg')} alt="FIRST-HOLD" /> */}
                                <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            
                <div className="slider-heading-box search">
                    <div className="heading-text">
                        <h1>Search for Napa event, wedding & meeting venues</h1>
                    </div>
                </div>
                <div className="venueTypeCard">
                    <Link to="/browseVenue/conference/type">
                        <Grid item className="venues">
                            <img className="find_feature_venue_image" src={"/static/media/Conference.70ff4062.jpg"} alt="venue type" />
                            <Grid container>
                                <Grid item xs={12} className="find_venue_event">
                                    Conference
                                </Grid>
                            </Grid>
                        </Grid>
                    </Link>
                    <Link to="/browseVenue/wedding/type">
                        <Grid item className="venues">
                            <img className="find_feature_venue_image" src={"/static/media/Wedding.979ba511.jpg"} alt="venue type" />
                            <Grid container>
                                <Grid item xs={12} className="find_venue_event" >
                                    Wedding
                                </Grid>
                            </Grid>
                        </Grid>
                    </Link>
                    <Link to="/browseVenue/meeting/type">
                        <Grid item className="venues">
                            <img className="find_feature_venue_image" src={"/static/media/Meeting.b824b119.jpg"} alt="venue type" />
                            <Grid container>
                                <Grid item xs={12} className="find_venue_event">
                                    Meeting
                                </Grid>
                            </Grid>
                        </Grid>
                    </Link>
                    <Link to="/browseVenue/Trade Show/type">
                        <Grid item className="venues">
                            <img className="find_feature_venue_image" src={"static/media/Trade%20Show.8f37b4f3.jpg"} alt="venue type" />
                            <Grid container>
                                <Grid item xs={12} className="find_venue_event" >
                                    Trade Show
                                </Grid>
                            </Grid>
                        </Grid>
                    </Link>
                    <Link to="/browseVenue/Corporate Event/type">
                        <Grid item className="venues">
                            <img className="find_feature_venue_image" src={"/static/media/Corporate%20Event.02b9582b.jpg"} alt="venue type" />
                            <Grid container>
                                <Grid item xs={12} className="find_venue_event" >
                                    Corporate Event
                                </Grid>
                            </Grid>
                        </Grid>
                    </Link>
                    <Link to="/browseVenue/Fundraiser/type">
                        <Grid item className="venues">
                            <img className="find_feature_venue_image" src={"/static/media/Fundraiser.b034bd5d.jpg"} alt="venue type" />
                            <Grid container>
                                <Grid item xs={12} className="find_venue_event" >
                                    Fundraiser
                                </Grid>
                            </Grid>
                        </Grid>
                    </Link>
                </div>
                <div className="box-wrapper">
                    <FeaturedVenues featureVenueData={featureVenueData} />
                </div>
                <div className="slider-heading-box frequently box-wrapper-padding">
                    <div className="heading-text ask">
                        <h1>For the best event venues in Napa, FIRST-HOLD offers unique charm and breathtaking settings.</h1>
                    </div>
                    <p>At FIRST-HOLD, we consistently provide you with top Napa event venues, ensuring that your special event is filled with beauty, elegance, and cherished memories.</p>
                    </div>
                <div className="slider-heading-box frequently box-wrapper-padding">
                    <div className="heading-text ask">
                        <h1>Frequently Asked Questions</h1>
                    </div>
                    <p class="">
                        <span className="bold">How many people can fit in Napa event venues?</span> Some venues just list square footage. When planning for your event, look for or ask for the specific number of people your venue can hold for your style of event – whether it’s a sit down dinner or standing reception.
                    </p>
                    <p>
                        <span className="bold">How much does it cost to rent a venue in Napa?</span> Corporate event venues in Napa can deliver a wide range of experiences for your event - from prestigious first-class finishes and state-of-the-art technology upwards of $25,000 for one night, to understated venues that are under $3,000.
                    </p>
                    <p>
                        <span className="bold">How can I communicate with event venues in Napa?</span> We don’t know about you, but website “contact
                        forms” feel impersonal to us. At FIRST-HOLD we want you to communicate directly with venues based on
                        dates they actually have available. No more blind “complete the form” scenarios when you don’t know if
                        your preferred date is even open, and we won’t force you into using an in-house chat feature. After selecting your date and answering simple questions about your event, 
                        you’ll receive an email with the venue manager’s name, phone number, and email. You can communicate directly with the venue manager and speed up the planning process.
                    </p>
                </div>
                <Footer />
            </div>
            <Footers />
        </div>
    );
}
napa.propTypes = {
    classes: object.isRequired,
    homepage: func.isRequired
};
const mapStateToProps = state => {
    return {

    };
};
const enhance = compose(
    withRouter,
    connect(mapStateToProps, dispatch =>
        bindActionCreators(
            {

                homepage

            },
            dispatch
        )
    )
);
export default enhance(withRouter(napa));

import React, { Component } from 'react';
import { MuiThemeProvider, Grid } from '@material-ui/core';

import { theme } from '@firsthold/components';

export class Layout extends Component {

  render() {
    return (
      <MuiThemeProvider theme={ theme }>
        <Grid container>
          { this.props.children }
        </Grid>
      </MuiThemeProvider>
    );
  }
}

export default Layout;

import { VENUE_IMAGE_UPLOAD } from 'shared/state/types';
import { request } from 'shared/auth';

function venueImageUploadRequested() {
  return {
    type: VENUE_IMAGE_UPLOAD.REQUEST,
  };
}

function venueImageUploadFailed(error) {
  return {
    type: VENUE_IMAGE_UPLOAD.FAILURE,
    error:error,
  };
}

function venueImageUploadSuccess(data) {
  return {
    type: VENUE_IMAGE_UPLOAD.SUCCESS,
    payload: data,
  };
}

export function venueImageUpload(params,id) {
  return dispatch => {
    dispatch(venueImageUploadRequested());

    return request().post(`venues/${id}/photos`, params)
      .then(response =>{
          dispatch(venueImageUploadSuccess(response.data.data))
          return response.data.data
        }
      )
      .catch(errors => {
        dispatch(venueImageUploadFailed(errors.response.data.errors));
        throw errors;
      });
  };
}

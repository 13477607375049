import { GET_KEYS } from 'shared/state/types';
import { request } from 'shared/auth';

function getKeysRequested() {
  return {
    type: GET_KEYS.REQUEST,
  };
}

function getKeysFailed(error) {
  return {
    type: GET_KEYS.FAILURE,
    error:error,
  };
}

function getKeysSuccess(data) {
  return {
    type: GET_KEYS.SUCCESS,
    data: data,
  };
}

export function getKeys(keyType = 'stripe_key') {
  return dispatch => {
    dispatch(getKeysRequested());

    return request().get(`/getKeys`)
      .then(response =>{
            dispatch(getKeysSuccess(response.data))
            return response.data.data[keyType];
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(getKeysFailed(errors.response.data.message));
        }
        else{
          dispatch(getKeysFailed("Unable to access api"));
        }
        throw errors;
      });
  };
}

import axios from 'axios';
import { GET_UPDATE_EVENT_REQUEST, GET_UPDATE_EVENT_SUCCESS, GET_UPDATE_EVENT_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function updateEventRequested() {
  return {
    type: GET_UPDATE_EVENT_REQUEST
  };
}

function updateEventSuccess(data) {
  return {
    type: GET_UPDATE_EVENT_SUCCESS,
    eventUpdateData: data,
  };
}

function updateEventFailure(error) {
  return {
    type: GET_UPDATE_EVENT_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }

const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export function updateEvent(id,params) {
    return dispatch => {
      dispatch(updateEventRequested());
        return request().put(`events/`+id,params)
        .then(response =>
          dispatch (updateEventSuccess(response.data),
                ))
        .catch(errors => {
          dispatch(updateEventFailure(errors.response.data.message));  
          throw errors;
        });
    
   

    
 };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import RightSideDropMenu from 'shared/components/RightSideDropMenu';

import { AppBar, Toolbar, IconButton, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import { bindActionCreators, compose } from 'redux';
import { connect } from "react-redux";
import './../assets/styles/venueHeader.scss';
import NotificationPopup from '../../components/NotificationPopup';
import SyncCalendarPopup from '../../components/SyncCalendarPopup';
import { userDetails,venueList } from "shared/venueList/actions";
import ExtensionPopup from '../../components/extensionPopup';
import eventBroadcast from "../../../data/eventBroadcast";
import '../../../venue/assets/styles/spacePlannerEdit.scss';
import VenueSelectPopup from 'shared/components/venueSelectPopup';

const logo = require("../../components/assets/images/user-default.png");


const styles = theme => ({
    signupDiv: {
        width: '100%',

    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        transition: 'none',
        paddingLeft: '45px',
        paddingRight: '8px',
        fontSize: '11px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    appbar: {
        backgroundColor: '#ffffff',
        color: '#36434b',
        boxShadow: ' inset 0 -1px 0 0 #dbdbdb'
    },

    selected: {
        borderBottom: '2px #ee2738 solid',
        backgroundColor: '#ffffff !important'
    },
    image: {
        height: '56px',
        borderRadius: '50px'
    },
    profileImageIcon: {
        padding: '0px 0px 0px 20px'
    },
    notificationsIcon: {
        padding: '0px 0px 0px 0px'
    },
    logo: {
        paddingTop: '4px'
    },
    eventNote: {
        [theme.breakpoints.up('sm')]: {
            padding: '13px 10px 0px 35px'
        },
        paddingTop: '8px'
    },
    touchApp: {
        [theme.breakpoints.up('sm')]: {
            padding: '10px 10px 0px 24px'
        },
        paddingTop: '6px'
    },

    changeHover: {
        padding: '12px 0px 12px 12px',

        '&:hover': {
            backgroundColor: '#ffffff',
            color: '#ee2738',
        }
    }
});

class VenueHeader extends React.Component {
    UNSAFE_componentWillMount() {
        this.props.userDetails().then((res) => {
        })
        this.props.venueList().then(() => {
    
        }).catch(error =>
            {
    
            });
    }

    componentWillMount() {
        let venueList = [];
        let spacelist =[];
        this.props.venueList().then((response) => {
        if (Array.isArray(response.venueList.data)) {
            response.venueList.data.map(function (data) {
              venueList.push({ "id": data.id, "name": data.name })
              data.spaces.map(function (res){
                spacelist.push({ "id": res.id,venue_id:res.venue_id ,"name": res.name ,'description':res.description})
                return spacelist
              });
              return venueList;
    
            });
            this.setState({VenueListItems:venueList})
            this.setState({spaceListItems:spacelist})

          }        
        }).catch(error =>
            {
    
            });
            setTimeout(() => {
            this.setState({buttonDisable:false})
            },4000
            )
      }; 


    state = {
        extensionPopup: false,
        anchorEl: null,
        mobileMoreAnchorEl: null,
        searchOption: [
            { abbr: 'OneKearnyClub', name: 'One Kearny Club' },
            { abbr: 'ZappaRoom', name: 'Zappa Room' },
            { abbr: '2ndStreetGallery', name: '2nd Street Gallery' }
        ],
        VenueListItems:'',
        venueSelect:null,
        venueId:'',
        spaceListItems:'',
        buttonDisable:true
    };
    hClick = () => {
        var link = document.getElementById('modalBoxforSetting');
        link.click();
    }

    showExtension = () => {
        this.setState({ extensionPopup: true });
    }

    changeCalendar = (isGoogle = true) => {
        if (!isGoogle) {
            eventBroadcast.dispatch("googleCalendarSync", {});
        } else {
            eventBroadcast.dispatch("microsoftCalendarSync", {});
        }
    }

    closeExtPopup = () => {
        this.setState({ extensionPopup: false });
    }
    
      closeVenueSelectPopup=()=>{
        this.setState({venueSelect:false})
      }
    render() {

        const { classes } = this.props;

        console.log('sss', this.props.spaceData);
        let url = this.props.location.pathname.split('/');
        let spaceId = 0;
        if (url.length > 0) {
            spaceId = url.length == 3 ? url[2] : 0;
            url = url[1];
        }
        return (

            <div className={`${classes.signupDiv} venue-header space-planner-edit`}>
                {
                    this.props.location.pathname!=('/blog/top-10-trending-event-themes-of-2024')&&(this.props.location.pathname === ('/help') || this.props.location.pathname.includes('/help/search') || this.props.location.pathname.includes('/help/article') || this.props.location.pathname === ('/help/articles') || this.props.location.pathname.includes('/blogs') ||this.props.location.pathname.includes('/blog') ||this.props.location.pathname.includes('/blog_detail'))? null :

                        <AppBar className={classes.appbar}>

                            <Toolbar>
                                <Grid container className="name">
                                    <Grid item md={6} xs={8} sm={8} xl={4} lg={4}>
                                        <Grid container className="top-header-name">
                                            <Grid item className={classes.logo}>
                                                <Link to='/'>
                                                    <img className={classes.image} src={require('./../assets/images/LogoWithText.png')} alt="FIRST-HOLD" />
                                                </Link>
                                            </Grid>
                                            {this.props.location.pathname.includes('/spaceProfileEdit/') &&
                                                <Grid item className={classes.eventNote}>
                                                     <a style={{display:'none'}} rel="noopener noreferrer" className="link show-ext-popup" href='https://calendar.google.com/calendar/r' target="_blank" title="Google Calendar">
                                                            <img src={require('../assets/images/google_calendar.svg')} alt="Google" />
                                                        </a>
                                                    {(!localStorage.getItem("extensionPopup")) &&
                                                        <a rel="noopener noreferrer" className="link hide-ext-popup" onClick={() => this.showExtension()} title="Google Calendar">
                                                            <img src={require('../assets/images/google_calendar.svg')} alt="Google" />
                                                        </a>
                                                    }

                                                    {/* {(localStorage.getItem("extensionPopup") && 'calendar_id' in this.props.spaceData && !this.props.spaceData.calendar_id) &&
                                                        <a rel="noopener noreferrer" className="link" onClick={() => this.changeCalendar()} title="Google Calendar">
                                                            <img src={require('../assets/images/google_calendar.svg')} alt="Google" />
                                                        </a>
                                                    } */}

                                                    {/* {(localStorage.getItem("extensionPopup") && 'calendar_id' in this.props.spaceData && this.props.spaceData.calendar_id) &&
                                                        <a rel="noopener noreferrer" className="link" href='https://calendar.google.com/calendar/r' target="_blank" title="Google Calendar">
                                                            <img src={require('../assets/images/google_calendar.svg')} alt="Google" />
                                                        </a>
                                                    } */}
                                                     {localStorage.getItem("extensionPopup") && 
                                                        <a rel="noopener noreferrer" className="link hide-ext-popup" href='https://calendar.google.com/calendar/r' target="_blank" title="Google Calendar">
                                                            <img src={require('../assets/images/google_calendar.svg')} alt="Google" />
                                                        </a>
                                                        
                                                    }
                                                </Grid>
                                            }
                                            {this.props.location.pathname.includes('/spaceProfileEdit/') &&
                                                <Grid item className={classes.eventNote}>
                                                    <a style={{display:'none'}} rel="noopener noreferrer" className="link show-ext-popup" href='https://outlook.live.com/calendar/0/view/month' target="_blank" title="Microsoft Calendar">
                                                            <img src={require('../assets/images/microsoft_calendar.svg')} alt="Microsoft" />
                                                        </a>
                                                    {(!localStorage.getItem("extensionPopup")) &&
                                                        <a rel="noopener noreferrer" className="link hide-ext-popup" onClick={() => this.showExtension()} title="Microsoft Calendar">
                                                            <img src={require('../assets/images/microsoft_calendar.svg')} alt="Microsoft" />
                                                        </a>
                                                    }
                                                    {/* {(localStorage.getItem("extensionPopup") && 'ms_calendar_id' in this.props.spaceData && !this.props.spaceData.ms_calendar_id) &&
                                                        <a rel="noopener noreferrer" className="link" onClick={() => this.changeCalendar(false)} title="Microsoft Calendar">
                                                            <img src={require('../assets/images/microsoft_calendar.svg')} alt="Microsoft" />
                                                        </a>
                                                    } */}
                                                    {/* {(localStorage.getItem("extensionPopup") && 'ms_calendar_id' in this.props.spaceData && this.props.spaceData.ms_calendar_id) &&
                                                        <a rel="noopener noreferrer" className="link" href='https://outlook.live.com/calendar/0/view/month' target="_blank" title="Microsoft Calendar">
                                                            <img src={require('../assets/images/microsoft_calendar.svg')} alt="Microsoft" />
                                                        </a>
                                                    } */}
                                                    {localStorage.getItem("extensionPopup") && 
                                                        <a rel="noopener noreferrer" className="link hide-ext-popup" href='https://outlook.live.com/calendar/0/view/month' target="_blank" title="Microsoft Calenda">
                                                            <img src={require('../assets/images/microsoft_calendar.svg')} alt="Microsoft" />
                                                        </a>
                                                    }
                                                </Grid>
                                            }
                                            <Grid item className={classes.touchApp}>
                                                <Link className="link" to='/myHold' title="My Hold">
                                                    <IconButton classes={{ root: classes.changeHover }} color="inherit">
                                                        <Icon>touch_app</Icon>
                                                    </IconButton>
                                                </Link>
                                            </Grid>
                                            {url === 'venueProfileEdit' &&
                                                <Grid item className={classes.touchApp}>
                                                    <Link className="link background" title="Hold Setting" onClick={this.hClick}>
                                                        <IconButton classes={{ root: classes.changeHover }} color="inherit">
                                                            <Icon style={{ fontFamily: "Raleway" }}>H</Icon>
                                                        </IconButton>
                                                    </Link>
                                                </Grid>
                                            }
                                            {(url === 'venueProfileEdit' && spaceId) &&
                                                <Grid item className={classes.touchApp}>
                                                    <a rel="noopener noreferrer" className="link" href={'/venueProfile/' + spaceId} target="_blank" title="Venue Preview">
                                                        <IconButton classes={{ root: classes.changeHover }} color="inherit">
                                                            <Icon>visibility</Icon>
                                                        </IconButton>
                                                    </a>
                                                </Grid>
                                            }
                                            <Grid item className={classes.touchApp}>
                                                <Link className="link" onClick={()=>this.state.buttonDisable===true?null:this.setState({venueSelect:true})} title="Sync Calendar" disabled="true">
                                                    <IconButton classes={{ root: classes.changeHover }} color="inherit" disabled={this.state.buttonDisable}>
                                                        <Icon>refresh</Icon>
                                                    </IconButton>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={2} lg={4} xl={4}></Grid>
                                    <Grid item md={4} xs={4} sm={4} lg={4} xl={4}>
                                        <Grid container justify="flex-end">
                                            {url === 'spaceProfileEdit' &&
                                                <SyncCalendarPopup spaceId={spaceId} />
                                            }
                                            <NotificationPopup userData={this.props.userData} />
                                            <IconButton classes={{ root: classes.changeHover }} className={classes.rightImageIcon} color="inherit">
                                                {this.props.userData.data && this.props.userData.data.profilePhoto ?
                                                    <RightSideDropMenu profilePhoto={this.props.userData.data && this.props.userData.data.profilePhoto.path} />
                                                    :
                                                    <RightSideDropMenu profilePhoto={logo} />
                                                }
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Toolbar>

                        </AppBar>
                }
                {this.state.extensionPopup === true && <ExtensionPopup close={this.closeExtPopup} />}
                
         { this.state.venueSelect &&
          <VenueSelectPopup
          open={this.state.venueSelect}
          placeholder="Select"
          options={this.state.VenueListItems}
          closeVenueSelectPopup={this.closeVenueSelectPopup}
          spaceListItems={this.state.spaceListItems}
          />
        }
        
            </div>
        );
    }
}


VenueHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
    return {
        userData: state.data.usersVenue.userInfo,
        spaceData: state.data.spaces.spaceData,
        list: state.data.usersVenue.venueList,
    };
};

const enhance = compose(
    connect(
        mapStateToProps,

        dispatch =>
            bindActionCreators(
                {
                    userDetails,
                    venueList 
                },
                dispatch
            )
    ),
    withRouter,
    withStyles(styles)
)

export default enhance(VenueHeader);

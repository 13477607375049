import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

// import CustomizedCheckBox from './CustomizedCheckbox';

// import { Formik } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import './assets/style/customMenuItem.scss';

const styles = theme => ({
    MuiPaper: {
        width: '400px',
        boxShadow: 'none',
        position: 'absolute',
        top: '0',
        left: '-392px',

    },
    MuiMenuItem: {
        height: 'auto',
        lineHeight: '0',
        padding: '5px 15px',
        borderBottom: '0',

        hover: {
            background: '#fff',
        }
    },
    root: {
        padding: '0px !important'
    }

});

function CustomMenuItem(props) {
    const { classes, resourceType,manager, readStatus, price, subject, eventName, eventDate, spaceName, venueName, handleClose, createdAt, spaceImage,holdDuration,expaired,eventType,link } = props;
    const oneDay = 24 * 60 * 60 * 1000;
    const currentDate = new Date()
    const resourceDate = new Date(createdAt)
    const diffDays = Math.round(Math.abs((currentDate - resourceDate) / oneDay));
    return (
        // <Formik
        //     initialValues={{ readStatus: readStatus }}
        //     onSubmit={(values)=>{handleSubmit(values)}}
        //     render={props => (
        <div className="custom_menu_item">
            {/*readStatus === 0 ? */}
                <MenuList>
                    <MenuItem classes={{ root: classes.MuiMenuItem }} className="first_list_row" onClick={handleClose}>
                        <Grid container spacing={15}>
                            <Grid item xs={2}>
                                <p className="gray">{diffDays}D AGO </p>
                            </Grid>
                            {  (resourceType !==32 &&  resourceType !==33 && resourceType !==34 && resourceType !==35) &&                          
                                <Grid item xs={6}>
                                    <p className="red">{subject}</p>
                                </Grid>
                            }
                            {  (resourceType !==32 &&  resourceType !==33 && resourceType !==34 && resourceType !==35) &&               
                                <Grid item xs={4}>
                                    <p className="blue"></p>
                                </Grid>
                            }
                        </Grid>
                    </MenuItem>
                    <MenuItem classes={{ root: classes.MuiMenuItem }} className="second_list_row" onClick={handleClose}>
                        <Grid container spacing={15}>
                            <Grid item xs={2}>
                                {
                               (resourceType ===32 || resourceType ===33 || resourceType ===34 || resourceType ===35) ?
                               <img className="space_image" src={require('./assets/images/logo.png')} alt="FIRST-HOLD" />
                               :spaceImage ?
                                <img className="space_image" src={spaceImage} alt="Space" />
                                :<i class="material-icons">
                                    business
                                </i>}
                            </Grid>
                            {  (resourceType ===32 ||  resourceType ===33 || resourceType ===34 || resourceType ===35) &&  
                                <Grid item xs={8} sm={8} className="linkBox">
                                    <a href={link} target="_blank" >
                                        <p >{subject}</p>
                                    </a>
                                </Grid>
                            }

                            {  (resourceType !==32 &&  resourceType !==33 && resourceType !==34 && resourceType !==35) &&  
                            <Grid item xs={6} sm={4}  >
                                <div className="notif-div">
                                    <h4>
                                        {eventName}
                                    </h4>
                                    <h6><em>{moment(eventDate).format('MM/DD/YYYY').replace(/\b0/g, '')}</em></h6>
                                </div>
                                <div className="notif-div">
                                    <h5>{spaceName}</h5>
                                    <h6 title={venueName}>{venueName?(venueName.length < 15?venueName:(venueName.substring(0,15)+'...')):''}</h6>
                                </div>
                                    {(resourceType === 23 || resourceType === 25) && 
                                    <h6>Manager: {manager}</h6>
                                    }
                                    {price && <h6>{price}</h6>}
                            </Grid>
                            }
                            {                            
                                (resourceType === 1 || resourceType === 3) &&                                                       
                                <Grid item xs={6} sm={3}>
                                    <div className="notif-div">
                                        <h5>Hold Duration</h5>
                                        <h6>{holdDuration}</h6>
                                    </div>
                                </Grid>
                            }
                            {                            
                                (resourceType === 1 || resourceType === 3) && 
                                <Grid item xs={6} sm={3} >
                                    <div className="notif-div">
                                        <h5>Time Remaining</h5>
                                        <h6>{expaired}</h6>
                                    </div>                               
                                </Grid>
                            }
                        </Grid>
                    </MenuItem>
                    {/* <MenuItem className="buttons" className="third_list_row" onClick={handleClose}>
                      {resourceType==13 ?
                        <Grid>
                            <button className="request_button">Extend</button>
                            <button className="book_button">Deny</button>
                        </Grid>
                        :resourceType==14 || resourceType==24 ?
                        <Grid>
                            <button className="request_button">Accept</button>
                            <button className="book_button">Decline</button>
                            <button className="book_button">Reply And Counter</button>
                        </Grid>
                        : resourceType==16 ?
                        <Grid>
                            <button className="book_button">Book</button>
                            <button className="book_button">Deny</button>
                        </Grid>
                        : resourceType==17 ?
                        <Grid>
                            <button className="book_button">Challenge</button>
                            <button className="book_button">Deny</button>
                        </Grid>
                        : resourceType==27 ?
                        <Grid>
                            <button className="request_button">Request Extension</button>
                            <button className="book_button">Book</button>
                        </Grid>
                        :
                        null
                      }   
                      </MenuItem> */}
                    <hr className="hr_tag"></hr>
                </MenuList>
        </div>
        //     )}
        // />
    );
}
CustomMenuItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomMenuItem);

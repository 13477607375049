import { FAQ } from 'shared/state/types';
import { request } from 'shared/auth';

function faqRequested() {
  return {
    type: FAQ.REQUEST,
  };
}

function faqFailed(error) {
  return {
    type: FAQ.FAILURE,
    error:error,
  };
}

function faqSuccess(data) {
  return {
    type: FAQ.SUCCESS,
    payload: data,
  };
}

export function faqData(params) {
  return dispatch => {
    dispatch(faqRequested());

    return request().get(`/faqs`)
      .then(response =>{
          dispatch(faqSuccess(response.data))
          return response;
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(faqFailed(errors.response.data.message));
        }
        else{
          dispatch(faqFailed('Not able to access api'));
        }
        throw errors;
      });
  };
}

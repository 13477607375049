import axios from 'axios';
import {
  POST_GOOGLE_CALLBACK_REQUEST,
  POST_GOOGLE_CALLBACK_SUCCESS,
  POST_GOOGLE_CALLBACK_FAILURE
} from 'shared/state/types';
import {
  loadProgressBar
} from 'axios-progress-bar'

function onRequested() {
  return {
    type: POST_GOOGLE_CALLBACK_REQUEST
  };
}


function onSuccess(data) {
  return {
    type: POST_GOOGLE_CALLBACK_SUCCESS,
    details: data,
  };
}

function onFailure(error) {
  return {
    type: POST_GOOGLE_CALLBACK_FAILURE,
    error: error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};
  if (localStorage.getItem('fhtoken')) {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');
  }
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({
    showSpinner: true
  }, axiosInstance)
  return axiosInstance;
};

export function microsoftCallback(params) {
  return dispatch => {
    let url = 'sso/microsoft/callback?code=';
    if (localStorage.getItem('fhtoken')) {
      url = 'user/sso/microsoft/callback?code=';
    }
    dispatch(onRequested());
    return request().get(url + params)
      .then(response => dispatch(onSuccess(response.data)))
      .catch(errors => {
        dispatch(onFailure(errors.message));
        throw errors;
      });
  };
}

import React from 'react';

import { Grid , Button } from '@material-ui/core';


function HowItWork(props) {
    return (
        <Grid container className="how_it_work_screen fh-container">
            <Grid xs={12} item>
                <Grid className="how_it_works" container justify="center">
                How it Works for Planners
                </Grid>
                <Grid className="how-it-works-blue-text" container justify="center">
                   <p>Meeting planning just got an all-in-one upgrade.</p>
                   <br className="d-none"></br>
                   <br className="d-none"></br>
                </Grid>
                <Grid className="how-it-works-text" container justify="center">
                   <p>FIRST-HOLD lets you discover and  <u>instantly check availability</u> on unique & interesting professional event venues any time of the day or night — for FREE.</p>
                </Grid>
            </Grid>
            <Grid xs={12} item className="how-pd-auto">
                <Grid container justify="center">
                    <Grid className="event_planners_section" item xs={12} sm={12} md={4} xl={4} lg={4}>
                        <Grid container justify="center">
                        <Grid item className="event-blue-text" xs={12} md={8} sm={12} lg={8} xl={8}>Step One</Grid>
                            <Grid item className="event_planners" xs={12} md={8} sm={12} lg={8} xl={8}>SEARCH SIZE, STYLE, & BUDGET</Grid>
                            <Grid item className="event_planner_content" xs={12} md={8} sm={12} lg={8} xl={8}>Search for available venues with the right size, vibe, and budget.</Grid>
                        </Grid>
                    </Grid>
                    <Grid className="event_planners_section" item xs={12} md={4} sm={12} xl={4} lg={4}>
                        <Grid container justify="center">
                        <Grid item className="event-blue-text" xs={12} md={8} sm={12} lg={8} xl={8}>Step Two</Grid>
                            <Grid item className="event_planners" xs={12} md={8} sm={12} lg={8} xl={8}>IMMEDIATELY CHECK AVAILABILITY</Grid>
                            <Grid item className="event_planner_content" xs={12} md={8} sm={12} lg={8} xl={8}>Finding venues that fit? Know if your preferred date is available — even when you’re working late at night! Place a hold or contact the venue to learn more.</Grid>
                        </Grid>
                    </Grid>
                    <Grid className="venue_managers_section" item xs={12} md={4} sm={12} xl={4} lg={4}>
                        <Grid container justify="center">
                        <Grid item className="event-blue-text" xs={12} md={8} sm={12} lg={8} xl={8}>Step Three</Grid>
                            <Grid item className="venue_managers" xs={12} md={8} sm={12} lg={8} xl={8}>COMMUNICATE DIRECTLY</Grid>
                            <Grid item className="venue_manager_content" xs={12} md={8} sm={12} lg={8} xl={8}>FIRST-HOLD connects you directly with venue managers. They call you to learn more about your event — all while knowing your date is available.</Grid>
                        </Grid>
                    </Grid>
                    <Grid className="venue_managers_section" item xs={12} md={12} sm={12} xl={12} lg={12}>
                        <Grid container justify="center">
                                <Grid className="how-it-works-text" container justify="center">
                                    <p>We match people planning events to venues with available dates &mdash; that’s it.</p>
                                </Grid> 
                                <Grid container justify="center">
                                    <Button href="/register" > Get started now </Button>                                   
                                </Grid>
                                <Grid container justify="center">
                                    <p className="it-free">It’s free!</p>
                                </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid className="how-it-works-heading" container justify="center">
                                <h3>Are you a venue manager?</h3>
                        </Grid>
                        <Grid className="how-it-works-link" container justify="center">
                            <p><a href="/"><u>See how FIRST-HOLD works for you.</u></a> (We take no commission  &mdash; ever!)
                            </p>
                        </Grid>  
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default (HowItWork);
import React from 'react';

import { Grid } from '@material-ui/core';

import CustomizedGoogleMap from '../../shared/components/customizedGoogleMap';
import '../assets/styles/venueProfile.scss';
import VenueDetailsImage from './venueDetailsImage';

function TopImageComponent(props) {
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={8} xl={8} lg={8}>
                <VenueDetailsImage name={props.name} deleteByName={props.deleteByName} venueDetails={props.venueDetails} formikProps={props.formikProps} imageFile={props.imageFile} imageUploaded={props.imageUploaded} imageFileName={props.imageFileName} imageUploading={props.imageUploading} handleImageUpload={props.handleImageUpload} handleImageChange={props.handleImageChange}  editImage={props.editImage} openImageUploadForm={props.openImageUploadForm} handleEditPhotosClick={props.handleEditPhotosClick} isEditVenueImage={true} deleteImage={props.deleteImage} venueImageError={props.venueImageError} venueName={props.venueName} viewPhotoClick={props.viewPhotoClick} viewPhoto={props.viewPhoto}/>
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={4} lg={4}>
                <CustomizedGoogleMap venue={true} markers={props.markers} isMarkerShown height="394px" />
            </Grid>
        </Grid>

    );
}
export default TopImageComponent;

import React from 'react';

import SpaceCard from '../../shared/components/SpaceCard';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Icon, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';

import '../assets/styles/venueSpaceEdit.scss';
import ErrorPopup from '../../shared/components/errorPopup';

const logo = require("../assets/images/default_venue.png")

const styles = theme => ({
iconImage: {
height: "16px",
width: "16px !important"
},
favIcon: {
fontSize: '20px',
textShadow: '0px 0px #dfd8d8'
},
activeFav: {
fontSize: '24px',
textShadow: '0px 0px #ff0000'
},

icon: {
marginLeft: '8px',
paddingTop: '1px',
color: '#b0b0b0',
cursor: 'pointer',
fontSize: '15px'
},
activeIcon: {
marginLeft: '8px',
paddingTop: '1px',
color: '#1397e1',
fontSize: '15px'
},
popper: {
paddingLeft: '15px',
paddingTop: '237px'
},
root:{
boxShadow: '0px 9px 11px 4px rgba(23,0,19,0.14)',
borderRadius: '6px'
},
typography: {
width: '237px',
height: '185px',
fontSize: '14px',
fontWeight: '500',
fontStyle: 'normal',
fontStretch: 'normal',
lineHeight: '1.43',
letterSpacing: 'normal',
padding: '19px',
color: '#1397e1'
},

paper: {
width: '237px',
height: 'auto',
fontSize: '14px',
fontWeight: '500',
fontStyle: 'normal',
fontStretch: 'normal',
lineHeight: '1.43',
letterSpacing: 'normal',
padding: '19px',
color: '#1397e1',
boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
fontFamily: 'Raleway',
backgroundColor: '#ffffff'
}

});
function VenueSpaceEdit(props) {
const { classes} = props;
//const openFixed = Boolean(anchorElFixed);
//const openFlexible = Boolean(anchorElFlexible);
const [anchorEl, setAnchorEl] = React.useState(null);
let data=props.venue;
let published='';
if(data.approved.toString()==='1' && data.published.toString()==='1'){
published="Live";
}
else if(data.approved.toString()==='1' && data.published.toString()==='0'){
published='Ready to publish'
}
else if(data.approved.toString()==='2')
{
published="Rejected";
}
else if(data.approved.toString()==='0'){
published="Under Review";
}
else if(data.approved.toString()==='-1'){
published="Draft";
}
else if(data.approved.toString()==='1' && data.published.toString()==='0')
{
published="Not yet published";
}
else if(data.approved.toString()==='1' && data.published.toString()==='1')
{
published="Publish";
}

const handleClick = event => {
setAnchorEl(anchorEl ? null : event.currentTarget);
};

const handleClose = () => {
setAnchorEl(null);
};
const open = Boolean(anchorEl);
const id = open ? 'simple-popper' : undefined;
// let featureVenueData = [
// { image: "https://d31ogwdhz811go.cloudfront.net/venue_photos/big/0.62386500%201517362530.JPG", venueName: 'Zappa Room', venueAddress: '', favourite: 'active', category: 'Conferences' },
// ];
let featureVenueData=[];
let i=0;
if(props.space && props.space.length > 0){
props.space.map(function(data,idx){
//data dynamic
let obj={};
obj.id=data.id;
obj.image=data.photo[0] ? data.photo[0] && process.env.REACT_APP_MEDIA_URL + data.photo[0].path : logo;
obj.venueName=data.name;
obj.venueAddress="";
obj.favourite=data.is_favourite;
obj.category="";
featureVenueData.push(obj);
return featureVenueData;
})
}
let save=props.saveVenue;

const venueObject = featureVenueData.map(function (data, idx) {
return (
data.venueName ? 
featureVenueData.length===1 ? 
<div className="venue_space_venue_card_single" key={idx}>
<SpaceCard data={props.values} saveVenue={save} idx={idx} height="90.9px" width="150px" image={data.image} favourite={data.favourite} venueAddress={data.venueAddress} venueName={data.venueName} category={data.category} enableLink linkTo={"/spaceProfileEdit/"+data.id} />
</div>
:
<div className="venue_space_venue_card" key={idx}>
<SpaceCard data={props.values} saveVenue={save} idx={idx} height="90.9px" width="150px" image={data.image} favourite={data.favourite} venueAddress={data.venueAddress} venueName={data.venueName} category={data.category} enableLink linkTo={"/spaceProfileEdit/"+data.id} />
</div>

:
<div className="venue-space-empty" style={{width: '155px',height: '84.4px','background-color': '#f0f0f0',margin: '5px 25px 30px 20px'}}></div>

);
});

return (
<Grid style={{width: '100%'}}>
{props.editvenueError===true ? 
<ErrorPopup errorClose={props.errorClose} message={props.editvenueErrorMessage} openError={props.editvenueError} />
:
null
}
{featureVenueData.length > 0 ?
<div className="venue-available-right" onMouseOut={props.handleCloseFlexiblePopper}>
<h3 className="black-heading" style={{display:'inline-block'}}>Event Spaces <span style={{color:'#69c7ee',fontWeight:'normal'}}>(Max 10)</span></h3>
<Grid item className="help_icon" style={{padding:'17px 5px'}}>
{/* <Grid onTouchStart={props.handleOpenFlexiblePopper} onMouseOver={props.handleOpenFlexiblePopper} onMouseOut={props.handleCloseFlexiblePopper}>
<Icon className={props.isFlexibleToggleOpen ? classes.activeIcon : classes.icon}>
help_outline
</Icon>
</Grid>
<Popper className={classes.popper} open={openFlexible} anchorEl={anchorElFlexible} placement="right-end">
<Paper classes={{ root: classes.root }}>
<Typography className={classes.typography}>Does your venue have more than one event space? If yes, please add each of your event spaces here. If no, and your venue is your main event space, then create a space and call it Full Venue.</Typography>
</Paper>
</Popper> */}
{/* <button aria-describedby={id} type="button" onClick={handleClick}>
<Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
help_outline
</Icon>
</button>
<Popper id={id} open={open} anchorEl={anchorEl}>
<div className={classes.paper}>Does your venue have more than one event space? If yes, please add each of your event spaces here. If no, and your venue is your main event space, then create a space and call it Full Venue.</div>
</Popper> */}
<Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
<Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
help_outline
</Icon>
</Button>
<Popover
id={id}
open={open}
anchorEl={anchorEl}
onClose={handleClose}
anchorOrigin={{
vertical: 'bottom',
horizontal: 'center',
}}
transformOrigin={{
vertical: 'top',
horizontal: 'center',
}}
>
<Typography className={classes.typography}>Does your venue have more than one event space? If yes, please add each of your event spaces here. If no, and your venue is your main event space, then create a space and call it Full Venue.</Typography>
</Popover>
</Grid>
{i<10 
?
props.space.length>9?
null
:
<div clavenueObjectssName="add-spaces-button" style={{display:'inline-block',marginLeft:'200px'}}>
{published!=='Live' && published!=="Ready to publish" ? 
<Link className="buttonAdd" 
onClick={()=>props.saveVenue(props.values)}
//to={`/addSpace/${props.venueId}`}
style={{width: '100px',
borderRadius: '6px',
backgroundColor: '#ffffff',
fontSize: '13px',
fontWeight: '500',
lineHeight: 'normal',
letterSpacing: '0.46px',
textAlign: 'right',
color: '#ee2738',
border: '0 !important',
fontFamily: 'raleway',
cursor: 'pointer',
textDecoration: 'none'}}>+ Add Space</Link>
:null}
</div>
:
null
}

<div style={{width: '100%'}}>
<Grid className="venueRightContainer" container justify="left">
{venueObject}
</Grid></div></div>
:
<Grid container className="venue-space-edit">
<Grid xs={12} item>
<Grid container>

<Grid container className="venue-space-edit-inner-container" onMouseOut={props.handleCloseFlexiblePopper}>
<div className="venue-available-right" >
<h3 className="black-heading">Event Spaces <span style={{color:'#69c7ee',fontWeight:'normal'}}>(Max 10)</span></h3>
<Grid item className="help_icon">
{/* <Grid onTouchStart={props.handleOpenFlexiblePopper} onMouseOver={props.handleOpenFlexiblePopper} onMouseOut={props.handleCloseFlexiblePopper}>
<Icon className={props.isFlexibleToggleOpen ? classes.activeIcon : classes.icon}>
help_outline
</Icon>
</Grid>
<Popper className={classes.popper} open={openFlexible} anchorEl={anchorElFlexible} placement="right-end">
<Paper classes={{ root: classes.root }}>
<Typography className={classes.typography}>Does your venue have more than one event space? If yes, please add each of your event spaces here. If no, and your venue is your main event space, then create a space and call it Full Venue.</Typography>
</Paper>
</Popper>
*/}
<Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
<Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
help_outline
</Icon>
</Button>
<Popover
id={id}
open={open}
anchorEl={anchorEl}
onClose={handleClose}
anchorOrigin={{
vertical: 'bottom',
horizontal: 'center',
}}
transformOrigin={{
vertical: 'top',
horizontal: 'center',
}}
>
<Typography className={classes.typography}>Does your venue have more than one event space? If yes, please add each of your event spaces here. If no, and your venue is your main event space, then create a space and call it Full Venue.</Typography>
</Popover>
</Grid>

</div>

<div clavenueObjectssName="add-spaces-button" className="add-space">
{published!=='Live' && published!=="Ready to publish" ? 
<Link className="add-space-button" onClick={()=>props.saveVenue(props.values)}>+ Add Space</Link>

// <Link className="add-space-button" to={`/addSpace/${props.venueId}`}>+ Add Space</Link>
:null}
</div>
<Grid className="rectangle" container justify="left">

<div className="venue-space-empty">
<img src={require('../assets/images/default_venue.png')} alt="Venue" />
</div>
{/* <div className="venue-space-empty"></div> */}
{/* <div className="venue-space-empty"></div>
<div className="venue-space-empty"></div>
<div className="venue-space-empty"></div>
<div className="venue-space-empty"></div>
<div className="venue-space-empty"></div>
<div className="venue-space-empty"></div> */}
</Grid>

</Grid>
</Grid>
</Grid>
</Grid>
}
</Grid>
);
}


export default withStyles(styles)(VenueSpaceEdit);


import axios from 'axios';
import { GET_UPDATE_EVENT_DETAILS_REQUEST, GET_HOLD_EVENT_SUCCESS, GET_UPDATE_EVENT_DETAILS_FAILURE } from 'shared/state/types';
// import Cookies from 'js-cookie';
import { loadProgressBar } from 'axios-progress-bar'

function updateEventDataRequested() {
  return {
    type: GET_UPDATE_EVENT_DETAILS_REQUEST
  };
}

function updateEventDataSuccess(data) {
  return {
    type: GET_HOLD_EVENT_SUCCESS,
  };
}

function updateEventDataFailure(error) {
  return {    
    type: GET_UPDATE_EVENT_DETAILS_FAILURE,
    error:(typeof error === 'object')?error.message:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }


  
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export function updateEventData(id,params) {
    return dispatch => {
      dispatch(updateEventDataRequested());
        return request().put(`event_timeslots/`+id,params)
        .then(response =>
          dispatch (updateEventDataSuccess(response.data),
                ))
        .catch(errors => {
          dispatch(updateEventDataFailure(errors.response.data.message));  
          throw errors;
        });
    
   

    
 };
}

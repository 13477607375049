import React, { memo, useState, useEffect } from "react";
import "./assets/styles/slider.scss";

function useWindowSize() {
 
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []); 

  return windowSize;
}

const RangeSlider = ({ classes, label, onChange, value, ...sliderProps }) => {
  const newValue =
    ((value - sliderProps.min) * 11) / (sliderProps.max - sliderProps.min);

  const size = useWindowSize();

  const isDesktop = size.width > 770;
  const cols = isDesktop ? 2 : 1;
  const pxShift = isDesktop ? 0.4 : 0.5;
  const ratio = size.width / cols / 100 - pxShift;

  const newPosition = 10 + newValue * ratio;


  return (
    <div className={"slider"}>
      <div className="rangeWrap">
        {/* <div className="rangeValue" id={"rangeV"}>
          <span style={{ left: `calc(${newValue}% + (${newPosition}px))` }}>
            {value}
          </span>
        </div> */}
        <div className="slider-value">
            <span className="">0</span>
            <span className={value==1?"active":''}>1</span>
            <span className={value==2?"active":''}>2</span>
            <span className={value==3?"active":''}>3</span>
            <span className={value==4?"active":''}>4</span>
            <span className={value==5?"active":''}>5</span>
            <span className={value==6?"active":''}>6</span>
            <span className={value==7?"active":''}>7</span>
            <span className={value==8?"active":''}>8</span>
            <span className={value==9?"active":''}>9</span>
            <span className={value==10?"active":''}>10</span>
        </div>
        <input
          {...sliderProps}
          type={"range"}
          value={value}
          className={`slider ${classes}`}
          id={"myRange"}
          onChange={onChange}
        />
        <div className="float-clear">
          <div className="float-left">Not at all likely</div>
          <div className="float-right">Extremely likely</div>
        </div>
      </div>
    </div>
  );
};

export default memo(RangeSlider);

import axios from 'axios';
import {GET_DELETE_REQUEST,GET_DELETE_SUCCESS,GET_DELETE_FAILURE } from 'shared/state/types';
// import Cookies from 'js-cookie';
import { loadProgressBar } from 'axios-progress-bar'

function deleteEventRequest() {
  return {
    type: GET_DELETE_REQUEST
  };
}

function deleteEventSuccess(data) {
  return {
    type: GET_DELETE_SUCCESS,
  };
}

function deleteEventFailure(error) {
  return {    
    type: GET_DELETE_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }
  
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export function deleteEvent(id) {
    return dispatch => {
      dispatch(deleteEventRequest());
        return request().delete(`events/`+id)
        .then(response =>
          dispatch (deleteEventSuccess(response.data),
                ))
        .catch(errors => {
          dispatch(deleteEventFailure(errors.response.data.message),
          );
  
          throw errors;
        });
    
   

    
 };
}

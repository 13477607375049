import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators,compose } from 'redux';
// import axios, { post } from 'axios';
import { registerUser, emailExists, getStates, imageUpload } from 'data/person/actions';
import { FormikWizard } from 'formik-wizard';
import { withRouter } from 'react-router-dom';
import { microsoftCallback } from '../../shared/microsoftSignUp/actions/microsoftCallback';
import queryString from 'query-string';
import { object, string, number,boolean,ref} from 'yup';
import EmailInfo from './views/emailInfo';
import TypeInfo from './views/typeInfo';
import PersonalInfo from './views/personalInfo';
import CompanyInfo from './views/companyInfo';
import { setToken } from 'shared/auth';
import { getUploadPostUrl, uploadtoS3} from 'data/master/actions';
import ErrorPopup from './errorPopup';
import CreatePassword from './views/createPassword';

const phoneRegExp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/ /* eslint-disable-line */
class Register extends Component {

  static propTypes = {
    registerUser: PropTypes.func.isRequired,
    microsoftCallback: PropTypes.func.isRequired
  };

  state = {
    toFoobar: false,
    email:'',
    password:'',
    type:0,
    first_name:'',
    last_name:'',
    password:'',
    type:0,
    file:{},
    openError:true
    };
  
  UNSAFE_componentWillMount() {
    localStorage.removeItem('user_type');
    this.props.getStates();
    let value;
    if(this.props.location){
      value=queryString.parse(this.props.location.search);
    }
    this.props.microsoftCallback(value.code).then(response => {
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = document.getElementById("scream");
      var prop=this;

       img.onload=()=>{
        ctx.drawImage(img, 10, 10,img.width,img.height);

         c.toBlob(function(blob) {
          var file = new File([blob], "sample.jpg", {type: "application/octet-stream"});
           prop.setState({file:file});
        }, "image/jpeg", 0.75)
   
      }
 
    })
  }
  componentWillReceiveProps(nextProps) {
    if(this.props.callbackData.data !== nextProps.callbackData.data){
      this.setState({first_name:nextProps.callbackData.data.person.first_name,last_name:nextProps.callbackData.data.person.last_name,email:nextProps.callbackData.data.person.email})     
      localStorage.setItem('personalInfoImage', nextProps.callbackData.data.person.photo==null?"":nextProps.callbackData.data.person.photo);
  
    }

  }
 buildFormData=(formData, data, parentKey)=> {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
            formData.append(parentKey, value);

    }
  }

  setBasicAction = (sectionValues,formValues)=>{
    this.setState({email:sectionValues.email,password:sectionValues.password});   
  }

  setTypeAction = (sectionValues,formValues)=>{
    this.setState({type:sectionValues.user_type});   
  }
    
  handleSubmit = (values) => {
    localStorage.setItem('microsoftRegister','1');
    localStorage.removeItem('personalInfoImage');
    let formData;
    formData = new FormData();
    localStorage.removeItem('disable_plan_selection')
    localStorage.removeItem('tier')
    let tier
    if(values.company.special_offer){
      tier = '2'
    }
    else{
      tier = '1'
    }
    let obj;
    if(values.company.line_2.length > 0)
    {
      if(parseInt(values.type.user_type) === 0){
        if(values.company.company_name){
          obj= {
            identity_provider:this.props.callbackData.data.person.identity_provider,
            refresh_token:this.props.callbackData.data.person.refresh_token,
            provider_id:this.props.callbackData.data.person.provider_id,
            type: values.type.user_type,
            email: this.props.callbackData.data.person.email,
            password: values.change_password.password,
            password_confirmation: values.change_password.password,
            first_name: values.personal.first_name,
            last_name: values.personal.last_name,
            phone_number: values.personal.phone_number,
            phone_ext: values.personal.extension,
            display_phone_number: values.personal.display_number?1:0,
            newsletter_enabled: values.company.newsletter?true:false,
            job_title: values.company.job_title_planner?values.company.job_title_planner:'Not',
            company_name: values.company.company_name,
            company_address: {
              line_1: values.company.line_1,
              line_2: values.company.line_2,
    
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            },
            address: {
              line_1: values.company.line_1,
              line_2: values.company.line_2,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            }
          };
        }
        else{
          obj= {
            identity_provider:this.props.callbackData.data.person.identity_provider,
            refresh_token:this.props.callbackData.data.person.refresh_token,
            provider_id:this.props.callbackData.data.person.provider_id,
            type: values.type.user_type,
            email: this.props.callbackData.data.person.email,
            password: values.change_password.password,
            password_confirmation: values.change_password.password,
            job_title: values.company.job_title_planner?values.company.job_title_planner:'Not',
            first_name: values.personal.first_name,
            last_name: values.personal.last_name,
            phone_number: values.personal.phone_number,
            phone_ext: values.personal.extension,
            display_phone_number: values.personal.display_number?1:0,
            newsletter_enabled: values.company.newsletter?true:false,
            company_address: {
              line_1: values.company.line_1,
              line_2: values.company.line_2,
    
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            },
            address: {
              line_1: values.company.line_1,
              line_2: values.company.line_2,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            }
          };
        }
      }
      else{
        obj= {
          identity_provider:this.props.callbackData.data.person.identity_provider,
          refresh_token:this.props.callbackData.data.person.refresh_token,
          provider_id:this.props.callbackData.data.person.provider_id,
          tier: tier,
          type: values.type.user_type,
          email: this.props.callbackData.data.person.email,
          password: values.change_password.password,
          password_confirmation: values.change_password.password,
          first_name: values.personal.first_name,
          last_name: values.personal.last_name,
          phone_number: values.personal.phone_number,
          phone_ext: values.personal.extension,
          display_phone_number: values.personal.display_number?1:0,
          newsletter_enabled: values.company.newsletter?true:false,
          job_title: values.company.job_title,
          // company_name: values.company.company_name,
          company_address: {
            line_1: values.company.line_1,
            line_2: values.company.line_2,
  
            city: values.company.city,
            state: values.company.state,
            country: 'US',
            zip: values.company.zipcode
          },
          address: {
            line_1: values.company.line_1,
            line_2: values.company.line_2,
            city: values.company.city,
            state: values.company.state,
            country: 'US',
            zip: values.company.zipcode
          }
        };
      }
    }
    else{
      if(values.type.user_type === 0){
        if(values.company.company_name){
          obj= {
            identity_provider:this.props.callbackData.data.person.identity_provider,
            refresh_token:this.props.callbackData.data.person.refresh_token,
            provider_id:this.props.callbackData.data.person.provider_id,
            email: this.props.callbackData.data.person.email,
            password: values.change_password.password,
            password_confirmation: values.change_password.password,
            type: values.type.user_type,
            first_name: values.personal.first_name,
            last_name: values.personal.last_name,
            phone_number: values.personal.phone_number,
            phone_ext: values.personal.extension,
            display_phone_number: values.personal.display_number?1:0,
            newsletter_enabled: values.company.newsletter?true:false,
            job_title: values.company.job_title_planner?values.company.job_title_planner:'Not',
            company_name: values.company.company_name,
            company_address: {
              line_1: values.company.line_1,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            },
            address: {
              line_1: values.company.line_1,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            }
          };
        }
        else{
          obj= {
            identity_provider:this.props.callbackData.data.person.identity_provider,
            refresh_token:this.props.callbackData.data.person.refresh_token,
            provider_id:this.props.callbackData.data.person.provider_id,
            email: this.props.callbackData.data.person.email,
            password: values.change_password.password,
            password_confirmation: values.change_password.password,
            type: values.type.user_type,
            first_name: values.personal.first_name,
            last_name: values.personal.last_name,
            phone_number: values.personal.phone_number,
            phone_ext: values.personal.extension,
            job_title: values.company.job_title_planner?values.company.job_title_planner:'Not',
            display_phone_number: values.personal.display_number?1:0,
            newsletter_enabled: values.company.newsletter?true:false,
            company_address: {
              line_1: values.company.line_1,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            },
            address: {
              line_1: values.company.line_1,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            }
          };
        }
      }
      else{
        obj= {
          identity_provider:this.props.callbackData.data.person.identity_provider,
          refresh_token:this.props.callbackData.data.person.refresh_token,
          provider_id:this.props.callbackData.data.person.provider_id,
          email: this.props.callbackData.data.person.email,
          password: values.change_password.password,
          password_confirmation: values.change_password.password,
          type: values.type.user_type,
          first_name: values.personal.first_name,
          last_name: values.personal.last_name,
          phone_number: values.personal.phone_number,
          phone_ext: values.personal.extension,
          display_phone_number: values.personal.display_number?1:0,
          newsletter_enabled: values.company.newsletter?true:false,
          job_title: values.company.job_title,
          tier: tier,
          // company_name: values.company.company_name,
          company_address: {
            line_1: values.company.line_1,
            city: values.company.city,
            state: values.company.state,
            country: 'US',
            zip: values.company.zipcode
          },
          address: {
            line_1: values.company.line_1,
            city: values.company.city,
            state: values.company.state,
            country: 'US',
            zip: values.company.zipcode
          }
        };
      }
    }
 
    this.buildFormData(formData,obj)

    console.log('sss',obj);

    if(values.personal.image){            
      this.props.getUploadPostUrl(1,values.personal.image.name).then((res) => {
        this.props.uploadtoS3(res.url, values.personal.image).then( ()=>{
          formData.append('photo',res.keyName)
          this.props.registerUser(formData)
          .then((response) => {
            if(parseInt(values.type.user_type) === 1) {
              window.location.href="./myVenue";
            }
      
            else {
              window.location.href="/browseVenue?signup=1";      
            }           
          })
        })
      })
    }
   else if(this.props.callbackData.data.person.photo){

    this.props.getUploadPostUrl(1,"myJPEG.jpg").then((res) => {
      this.props.uploadtoS3(res.url,this.state.file).then( ()=>{
        formData.append('photo',res.keyName)

        this.props.registerUser(formData)
        .then((response) => {
          if(parseInt(values.type.user_type) === 1) {
            window.location.href="./myVenue";
    
          }
    
          else {
            window.location.href="/browseVenue?signup=1";
    
          }
         
        })
      })
    })
  }else{
    this.props.registerUser(formData)
    .then((response) => {
      if(parseInt(values.type.user_type) === 1) {
        window.location.href="./myVenue";
      }
      else {
        window.location.href="/browseVenue?signup=1";
      }     
    });
  }   
 
  };

  errorClose= () =>{
    this.setState({openError:false});
  }
  render() {
    return (
      
      this.props.sendingData.sended===true ? 
      parseInt(this.props.callbackData.data.person.type) === 0 ||  parseInt(this.props.callbackData.data.person.type) === 1 ?

      setTimeout(function(){ 
        if(localStorage.getItem('fhtoken') && localStorage.getItem('spaceId'))
        {
          let spaceId =localStorage.getItem('spaceId');
          localStorage.removeItem('spaceId');
          localStorage.setItem('microsoftCalendarVal','justLooking');
          window.location.href ='/webMicrosoftCalendar/'+spaceId;
        }
        else{
            if(this.props.redirectPath && this.props.redirectPath !== '/login'){
              // this.props.history.push(`.${this.props.redirectPath}`)
              localStorage.setItem('msSignIn','1');
              setToken(this.props.callbackData.data.token, this.props.callbackData.data.person)
              window.location.href =`.${this.props.redirectPath}`;
            }
            if (this.props.callbackData.data.person.type === 1) {
              localStorage.setItem('msSignIn','1');
              setToken(this.props.callbackData.data.token, this.props.callbackData.data.person)
              window.location.href = './myVenue'
            }
            else if (this.props.callbackData.data.person.type === 0) {
              localStorage.setItem('msSignIn','1');
              setToken(this.props.callbackData.data.token, this.props.callbackData.data.person)

               window.location.href ='./browseVenue'
            }
          }
        }.bind(this), 50)
       :

      <div>
<img id="scream" style={{display:"none"}} width="220" height="277" src={this.props.callbackData?this.props.callbackData.data.person.photo:'https://image.shutterstock.com/image-vector/sample-stamp-square-grunge-sign-260nw-1474408826.jpg'} alt="The Scream" crossOrigin="anonymous"/>
<canvas style={{display:"none"}} id="myCanvas" width="240" height="297"></canvas>

      <FormikWizard
        steps={[
          {
            id: 'change_password',
            component: CreatePassword,
            initialValues: {
              password:'',
              confirmPassword:''
            },
            validationSchema: object().shape({
              password: string()
                .required('*Password is required'),
              confirmPassword: string()
                .oneOf([ref('password'), null], "Passwords don't match!")
                .required('*Password is required')
            }),
          },
          {
            id: 'type',
            component: TypeInfo,
            initialValues: {
              user_type:  this.state.type,
            },
            validationSchema: object().shape({
              user_type: number().required("*User Type is required"),
            }),
            onAction:this.setTypeAction
          },
          {
            id: 'personal',
            component: PersonalInfo,
            initialValues: {
              first_name: this.state.first_name,
              last_name: this.state.last_name,
              phone_number: '',
              extension: '',
              display_number: true,
              image: undefined
            },
            validationSchema: object().shape({
              first_name: string().required("*First Name is required").max(100, '*First Name should not be greater than 100 characters'),
              last_name: string().required("*Last Name is required").max(100, '*Last Name should not be greater than 100 characters'),
              phone_number: string().matches(phoneRegExp, 'Phone number is not valid').required("*Phone Number is required").min(4, '*Phone Number should not be less than than 4 digit').max(20, '*Phone number should not be greater than 20 digit'),
              image: string(),
              extension:string().matches(/^[0-9]*$/, '*Extension is not valid').max(8, '*Should not be greater than 8 digit')
            }),
          },
          {
            id: 'company',
            component: CompanyInfo,
            initialValues: {
              company_name: '',
              line_1: '',
              line_2: '',
              city: '',
              state: '',
              zipcode:'',
              job_title: '',
              job_title_planner:'',
              agree: false,
              newsletter:true,
              special_offer: false
            },
            validationSchema: object().shape({
              // company_name: string().required('*Company Name is required').max(100, '*Company Name should not be greater than 100 characters'),
              company_name: string().max(100, '*Company Name should not be greater than 100 characters'),
              line_1: string().required("*Line 1 is required").max(255, '*Line1 should not be greater than 255 characters'),
              line_2: string().max(255, '*Line2 should not be greater than 255 characters'),
              city: string().required("*City  is required").max(255, '*City should not be greater than 255 characters'),
              state: string().required("*State  is required"),
              zipcode: string().required("*Zip Code  is required").matches(/(^[a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d$)|(^\d{5}(-\d{4})?$)/,"*Zip Code is not valid").max(16, '*Zip Code should not be greater than 16 characters'),
              job_title: string().required('*Job Title is required').max(150, '*Job Title should not be greater than 150 characters'),
              job_title_planner: string().max(150, '*Job Title should not be greater than 150 characters'),
              agree: boolean().required("The terms and conditions must be selected.")
              .oneOf([true], "The terms and conditions must be selected."),
             
            }),
          },
        ]}
        onSubmit={this.handleSubmit}
        render={({
          children,
          isLastStep,
          status,
          goToPreviousStep,
          canGoBack,
          actionLabel,
        }) => (
          
            <div>
              {status && (
                <div>
                  {status.message}
                  <hr />
                </div>
              )}
              {children}
            </div>
          )
        }
      />
    </div>
        :
        this.props.sendingData.sended==='error' ? 

        localStorage.getItem("fhtoken")?
        
        <ErrorPopup message="This microsoft account is already synced with different First-Hold account." openError={this.state.openError} errorClose={this.errorClose} />
        :
        window.location.href ='./register'
        :
        <div style={{margin:'300px',fontSize:'40px'}}>Loading...</div>
        );
    
  }
}


Register.propTypes = {
  classes: object.isRequired,
};


const mapStateToProps = state => {
  return {
   callbackData:state.data.microsoftSignUp.microsoftData,
   sendingData:state.data.microsoftSignUp
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        registerUser,
        emailExists,
        getStates,
        imageUpload,
        microsoftCallback,
        getUploadPostUrl,
        uploadtoS3
      },
      dispatch
    )
  )
);
export default enhance(Register);

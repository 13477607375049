import { Record } from 'immutable';

import {
  CREATE_PLAN,
  UPDATE_PLAN,
  GET_PLAN,
  STRIPE_API,
  COUPON_CODE,
  UNSUBSCRIBE_PLAN
} from 'shared/state/types';

class State extends Record({
  sending: false,
  sended: false,
  error: '',
  success: '',
  updating: false,
  updated: false,
  fetching: false,
  fetched: false,
  planList:[],
  couponDetail:{}
}) { }


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case CREATE_PLAN.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        success: '',
        error: ''
      });
    case CREATE_PLAN.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        success: 'Plan has been selected successfully.',
        error: ''
      });
    case CREATE_PLAN.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        error: action.error
      });
    case UPDATE_PLAN.REQUEST:
      return state.merge({
        updating: true,
        updated: false,
        success: '',
        error: ''
      });
    case UPDATE_PLAN.SUCCESS:
      return state.merge({
        updating: false,
        updated: true,
        success: action.message,
        error: ''
      });
    case UPDATE_PLAN.FAILURE:
      return state.merge({
        updating: false,
        updated: false,
        success: '',
        error: action.error
      });
    case GET_PLAN.REQUEST:
      return state.merge({
        fetching: true,
        fetched: false,
        planList: []
      });
    case GET_PLAN.SUCCESS:
      return state.merge({
        fetching: false,
        fetched: true,
        planList: action.payload
      });
    case GET_PLAN.FAILURE:
      return state.merge({
        fetching: false,
        fetched: false,
      });
    case STRIPE_API.REQUEST:
      return state.merge({
        success: '',
        error: action.errorMessage
      });
    case COUPON_CODE.REQUEST:
        return state.merge({
          fetching: true,
          fetched: false,
          couponDetail: {},
          error:''
        });
    case COUPON_CODE.SUCCESS:
        return state.merge({
          fetching: false,
          fetched: true,
          couponDetail: action.payload
        });
    case COUPON_CODE.FAILURE:
        return state.merge({
          sending: false,
          sended: false,
          success: '',
          error: action.error
        });
        case UNSUBSCRIBE_PLAN.REQUEST:
          return state.merge({
            sending: true,
            sended: false,
            success: '',
            error: ''
          });
        case UNSUBSCRIBE_PLAN.SUCCESS:
        return state.merge({
        sending: false,
        sended: true,
        success: 'Plan has been unsubscribed successfully.',
        error: ''
      });
      case UNSUBSCRIBE_PLAN.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        error: action.error
      });
    default:
      return state;
  }
}


import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {getArticles} from '../../../src/data/venues/actions/getArticles';
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { object, func } from "prop-types";
import { getCategory } from '../../../src/data/venues/actions/getCategory';
import { useLocation } from 'react-router';

import "../assets/styles/helpSearch.scss";
import {
    Icon,
    MenuItem,
    Menu,
    Button
} from '@material-ui/core';
import moment from "moment";
import { Category } from '@material-ui/icons';


let id=''

function search (props) {

        const [anchorEl, setAnchorEl] = React.useState(null);
        const [article, setarticle] = React.useState();
        const [category, setcategory] = React.useState({});

        
        const handleClose = () => {
          setAnchorEl(null);
        };
        
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        React.useEffect(() => {
            const queryParams = new URLSearchParams(window.location.search)
         props.getArticles(queryParams.get('category_id')).then((result) => {
             
                    if ('articles' in result && Array.isArray(result.articles)) {
                            
                        setarticle(result.articles);
                        setcategory(result.category);
                    };
                    
                })
           },[]);           
        return (
            <div className="help-ui-wrapper">
                <section class="header-section">
                    <div class="header-body">
                        <div class="header-wrap">
                            <a href="/help" class="company-logo">
                                <img class="large" src={require('./../assets/images/LogoWithText.png')} alt="Logo" />
                            </a>
                            <div class="overlay" id="overlay">
                                <nav class="overlay-menu">
                                    <ul class="header-nav-view">
                                    <li><Link to="/help/articles?keyword=venues" target="_blank">Venues</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=caterers" target="_blank">Caterers</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=cvb" target="_blank">CVBs</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=planners" target="_blank">Planners</Link></li>
                                        
                                        <li><Link to="/help/articles?keyword=partners" target="_blank">Partnering with FIRST-HOLD</Link></li>
                                    </ul>
                                    <div class="mobile-view-nav">
                                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                            <Icon>more_vert</Icon>
                                        </Button>
                                        <Menu  keepMounted  anchorEl={anchorEl}  onClose={handleClose}  open={Boolean(anchorEl)}>
                                            <MenuItem onClick={handleClose}>Venues</MenuItem>
                                            <MenuItem onClick={handleClose}>Caterers</MenuItem>
                                            <MenuItem onClick={handleClose}>CVBs</MenuItem>
                                            <MenuItem onClick={handleClose}>Planners</MenuItem>
                                            <MenuItem onClick={handleClose}>Partnering with FIRST-HOLD</MenuItem>
                                        </Menu>
                                    </div>
                                </nav>
                            </div>
                           
                        </div>
                        <div class="article-search">
                            <div class="article-search-container">
                                <form action="/help/articles" method="get" autocomplete="off">
                                    <span class="icon-search">
                                        <img  src={require('../assets/images/search.svg')} alt="Search" />
                                    </span>
                                    <input placeholder="Search for answers..." type="search" name="keyword" />
                                </form>
                            </div>
                        </div>
                    </div>
                   
                </section>
            <section class="breadcrums">
            

                <ol class="breadcrums-items">
                  <li><a href="/help">Home</a></li>
                  <li>{category.title}</li>
                </ol>
            
              </section>
            <div class="page-body">
                <div class="page-container">
                    <section class="articles article-overview-list">
                        <h2>{category.title} </h2><p>{category.description}</p><br/>
                        <ul class="article-list">
                        {article && article.map(({ id,title,page_title,description,updated_at,slug}) => (

                            <li class="article-list-item">
                             <Link to={`/help/article/${slug}?article_id=${id}`}  className="footer_links" >

                                    <h3>{title}</h3>
                                    <p>{description}</p>
                                    <div class="article-list-item-footer">
                                            <div class="authors">
                                                <ul>
                                                    <li><img src="https://s3.amazonaws.com/groovehq/uploaded/thumb/FpFTmdVAcy7k-se6BHqU?1619458087" alt="avatar" /></li>
                                                </ul>
                                                <div class="item-info">
                                                    Written by Jennifer Brown<br/>
                                                    Updated on {moment(new Date(updated_at.slice(0,10))).format("LL")}
                                                </div>
                                            </div>
                                        </div>
                                </Link>
                            </li>
                        ))}
                        </ul>
                    </section>
                </div>
            </div>
        </div>
        );
    }
    const mapStateToProps = state => {
        return {
          userData: state.data.usersVenue.userInfo
        };
      }
      
      const enhance = compose(
        withRouter,
        connect(mapStateToProps, dispatch =>
          bindActionCreators(
            {
             
              getArticles,
              getCategory
              
              
            },
            dispatch
          )
        )
      );   
      export default enhance(withRouter(search));



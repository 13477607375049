import { SSO_AUTHENTICATE } from 'shared/state/types';
import { request } from 'shared/auth';

function ssoAuthRequested() {
  return {
    type: SSO_AUTHENTICATE.REQUEST,
  };
}

function ssoAuthSuccess(data) {
  return {
    type: SSO_AUTHENTICATE.SUCCESS,
    payload: data,
  };
}

function ssoAuthFailed(error) {
  return {
    type: SSO_AUTHENTICATE.FAILURE,
    error,
  };
}

export function performSSOAuth(params) {
  return dispatch => {
    dispatch(ssoAuthRequested());
    return request().get('/sso/google/callback', { params })
      .then(response => dispatch(ssoAuthSuccess(response.data.data)))
      .catch(error => dispatch(ssoAuthFailed(error)));
  };
}

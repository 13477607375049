import React from 'react';
import '../assets/styles/googleCalendar.scss';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Redirect } from 'react-router-dom'
 import CustomisedRadio from '../../../shared/components/CustomisedRadio.js'
 import '../../../shared/components/assets/style/placeHold.scss';
 import { userDetails } from "../../../shared/venueList/actions";
 import { func } from "prop-types";
 import { connect } from "react-redux";
 import { bindActionCreators, compose } from "redux";
 import { updateUser } from '../../../data/person/actions';
 const styles = theme => ({
  root: {
    margin: 0,
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
      fontWeight: '500',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.46px',
      color: 'black'
    }
  },
  Button: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: '6px',
      border: 'solid 1px black',
    }
  }
  
});

class googleCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.renderRedirect = this.renderRedirect.bind(this);
  }

  state = {
    value: 'I have one Google Calendar in which I manage one or more venues.',
    redirect: false,
    radioSelectedValue: "justLooking",
  };

  componentWillMount(){
    this.props.userDetails().then((response) => {
      if(response.userInfo.data.single_calendar == 0){
        this.setState({
          value: 'I have a different Google Calendar for each of my venues.',
          redirect: false,
          radioSelectedValue: "oneOfTopOptions",
        });
      }
    }).catch(error =>
        {});
  }
  componentDidMount(){
    if(this.props.match.params.id.toString()==='null')
    {
      //return <Redirect to={'/spaceProfileEdit/2'} />
      window.location.href='/myVenue';
    }

    localStorage.setItem('googleCalendarVal',this.state.radioSelectedValue);
  }

  setRedirect = () => {

    let formData;
    formData = new FormData();
    if(this.state.radioSelectedValue=='oneOfTopOptions'){
      formData.append('single_calendar',0);
    }
    else{
      formData.append('single_calendar',1);
    }
    this.props.updateUser(formData)
            .then((response) => {
          
            })


    this.setState({
      redirect: true
    })
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={'/webCalendar/'+this.props.match.params.id} />
    }
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
  };
  
  

  handleRadioClick = event => {
    this.setState({ buttonDisabled: false });
    this.setState({ radioSelectedValue: event.target.value });
    
    localStorage.setItem('googleCalendarVal',event.target.value);
    
    // get all checkboxes that has className checkBox
    var options = document.getElementsByClassName("option");

    // check if any of it is checked
    for (var i = 0; i < options.length; i++) {
      if (options[i].getElementsByTagName("input")[0].checked) {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text blue");
      } else {
        options[i]
          .getElementsByTagName("p")[0]
          .setAttribute("class", "option-text grey");
      }
    }
  } 
  render() {
    const { classes } = this.props;
    return (
      <div className="home_page google_calendar">
        <div className="search_page"></div>
        <Grid container className="main-form">
          <Grid item md={8} lg={8} xl={8} >
            <p className="heading">Set up your Google Calendar FIRST-HOLD Integration
             </p>
          </Grid>
          <Grid item md={12} lg={12} xl={12} >
            <div className={classes.root}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className="radio_label">Which of the following describes your Google Calendar use?</FormLabel>
                
              <div className="optionsDiv">
                            <div className="option">
                            <CustomisedRadio
                                name="readyOption"
                                value="justLooking"
                                onChange={this.handleRadioClick}
                                class="radioButton"
                                radioSelectedValue={this.state.radioSelectedValue}/>
                              <p className="option-text">
                              I have one Google Calendar in which I manage one or more venues.
                              </p>
                            </div>
                            <div className="option">
                            <CustomisedRadio
                                name="readyOption"
                                value="oneOfTopOptions"
                                onChange={this.handleRadioClick}
                                class="radioButton"
                                radioSelectedValue={this.state.radioSelectedValue} />
                              <p className="option-text">
                              I have a different Google Calendar for each of my venues.
                              </p>

                            
                            </div>
                            <div className="option">
                            <CustomisedRadio
                                name="readyOption"
                                value="probablyGoingToBook"
                                onChange={this.handleRadioClick}
                                class="radioButton"
                                radioSelectedValue={this.state.radioSelectedValue} />
                              <p className="option-text">
                              I’m not sure.
                              </p>
                            </div>
                          </div>
              </FormControl>

            </div>
          </Grid>

          <Grid item md={8} lg={8} xl={8} >
          <div className="buttonContent">
          {/* <CustomizedButton type="submit" label="Next" disabled={false} onClick={this.renderRedirect}/> */}

          {this.renderRedirect()}
            <button onClick={this.setRedirect}>Next</button>
        
           </div>
          
          </Grid>
        </Grid>
      </div>
    );
  }
}
googleCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
  userDetails: func.isRequired,
  updateUser:func.isRequired
};
const mapStateToProps = state => {

  return {
    userData: state.data.usersVenue.userInfo,
  };
};
const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        userDetails,
        updateUser
      },
      dispatch
    )
  )
);
export default enhance(googleCalendar);


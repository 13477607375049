import { GET_ARRANGEMENTS } from 'shared/state/types';
import { request } from 'shared/auth';

function getArrangementsRequested() {
  return {
    type: GET_ARRANGEMENTS.REQUEST,
  };
}

function getArrangementsFailed(error) {
  return {
    type: GET_ARRANGEMENTS.FAILURE,
    error:error,
  };
}

function getArrangementsSuccess(data) {
  return {
    type: GET_ARRANGEMENTS.SUCCESS,
    arrangements: data,
  };
}

export function getArrangements(spaceId) {
  return dispatch => {
    dispatch(getArrangementsRequested());

    return request().get(`/arrangements`)
      .then(response =>{
          if(response.status === 200){
            dispatch(getArrangementsSuccess(response.data.data))
            return response.data.data
          }
          else if(response.status === 400){
            dispatch(getArrangementsFailed("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(getArrangementsFailed("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(getArrangementsFailed("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(getArrangementsFailed("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(getArrangementsFailed("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(getArrangementsFailed("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(getArrangementsFailed("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(getArrangementsFailed("The server was unavailable."));
          }
          else{
            dispatch(getArrangementsFailed("The server was unavailable."));
          }
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(getArrangementsFailed(errors.response.data.message));
        }
        else{
          dispatch(getArrangementsFailed("Unable to access api"));
          throw errors;
        }
      });
  };
}

import React from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CustomizedButton from '../../shared/components/customizedButton';
import { Field, ErrorMessage } from 'formik';
import { Grid, LinearProgress } from '@material-ui/core';
import Cropper from 'react-easy-crop'

import './assets/style/venueImageUpload.scss';

const styles = theme => ({
    linearColorPrimary: {
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        background: 'linear-gradient(to bottom, #f1435e, #ee2738)',
        height: '26px'
    },
    linearBarColorPrimary: {
        backgroundColor: '#c4d0d9'
    },
});


function VenueImageUpload(props) {
    const { classes } = props;
    return (
        <div>
            <div className="venue_image_upload" style={{ position: "relative" }}>
                {(props.imageUploaded && !props.imageUploading) ?
                    <>
                      <div>
                          <img
                              className="venue_image"
                              src={props.imageFile ? props.imageFile : props.imageUrl}
                              alt="Venue"
                              style={{ height: "100%", width: "100%" }}
                          />
                          <div className="button_on_image">
                          </div>
                          <div className="button_on_image_div">
                              {/* <Field className="file_type_field" type="file" id="image" name={props.name} accept="image/jpg, image/jpeg"
                                          onChange={e => {
                                              props.handleImageChange(e, props.setSubmitting)
                                              // props.setFieldValue(`${props.name}`, e.target.files[0])
                                          }}
                                      /> */}
                              <CustomizedButton label="Upload" onClickEvent={props.handleImageUpload} />
                          </div>
                        </div>
                        <div className="clear"></div>
                        <div>
                    {props.cropping?
                          <Cropper
                          image={props.imageFile? props.imageFile : props.imageUrl}
                          crop={props.crop}
                          zoom={props.zoom}
                          aspect={props.aspect}
                          rotation={props.rotation}
                        //   onRotationChange={props.setRotation}
                          onCropChange={props.onCropChange}
                          onCropComplete={props.onCropComplete}
                        //   onZoomChange={props.onZoomChange}
                      />
                :
                // <CustomizedButton label="Upload2" onClickEvent={props.handleImageUpload} />
                        null
                    }
                        </div>

                    </>
                    :
                    //TODO

                    <Grid container className="image_upload" alignItems="center" justify="center">

                        <Grid item className="file_name" xs={12} >
                            {props.imageFileName}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justify="center">
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    {props.imageUploading ?
                                        <LinearProgress
                                            classes={{
                                                colorPrimary: classes.linearColorPrimary,
                                                barColorPrimary: classes.linearBarColorPrimary,
                                            }}
                                        />
                                        :
                                        <CustomizedButton label="Upload" marginBottom="100px" onClickEvent={props.handleImageUpload} />
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    {/* <Field className="file_type_field" type="file" id="image" name={props.name} accept="image/jpg, image/jpeg"
                                        onChange={e => {
                                            props.handleImageChange(e, props.setSubmitting)
                                            props.setFieldValue(`${props.name}`, e.target.files[0])
                                        }}
                                    /> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Field className="file_type_field" type="file" id="image" name={props.name} value='' accept="image/jpg, image/jpeg"
                    onChange={e => {
                        props.handleChange(e)
                        props.handleImageChange(e, props.setSubmitting)
                        // props.setFieldValue(`${props.name}`, e.target.files[0])
                    }}
                />
            </div>
            {/* <Field className="file_type_field" type="file" id="image" name={props.name} accept="image/jpg, image/jpeg"
                onChange={e => {
                    props.handleImageChange(e, props.setSubmitting,props.setFieldValue, props.name)
                    // props.setFieldValue(`${props.name}`, e.target.files[0])
                }}
            /> */}
            {props.cropping===true?
                        <div className="error">*You need to crop this photo by clicking crop button below.</div>
                    :null}
            <div className="error">{props.venueImageError && props.venueImageError}</div>
            <div className="error"><ErrorMessage name="venueImage" /></div>
            {/* <div>ffff{props.zoom}</div> */}
        </div>
    );
}


VenueImageUpload.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(VenueImageUpload);

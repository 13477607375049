import { Record } from 'immutable';

import {
  GET_BOOK_EVENT_REQUEST,
  GET_BOOK_EVENT_FAILURE,
  GET_BOOK_EVENT_SUCCESS,
} from 'shared/state/types';

class State extends Record({
  error:'',
  sending:'',
  sended:'',
  success:'',
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_BOOK_EVENT_REQUEST:
      return state.merge({
        error:'',
        success:'',
        sending:true,
        sended:false
      });
    case GET_BOOK_EVENT_FAILURE:
      return state.merge({
        error: action.error && action.error.response.data.message,
        sending:false,
        success:'',
        sended:false


      });
    case GET_BOOK_EVENT_SUCCESS:
      return state.merge({
        success:'Success',
        error:'',
        sending:false,
        sended:true
      });
    default:
      return state;
  }
}


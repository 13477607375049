import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { object, func } from "prop-types";
import queryString from 'query-string';
import { Grid } from "@material-ui/core";
import Search from "../components/search.js";
import RightPanel from "../components/rightPanel";
import RightPanelVenue from "../components/rightPanelVenue";
import CustomizedGoogleMap from "../../shared/components/customizedGoogleMap";
import { bindActionCreators, compose } from "redux";
import "../assets/styles/browseVenue.scss";
import { connect } from "react-redux";
import { browseVenue, addCategory, seatingCategory } from "shared/search/actions";
import { eventType } from "shared/placeHolds/actions";
import { homepage } from "shared/homepage/actions";
import ModalBox from "../../shared/components/ModalBox";
import { staticVenueOnLoad } from "shared/home/actions";
import {debounce} from 'lodash';
import * as Yup from "yup";

const logo = require("../assets/images/default_venue.png");
var markers = [];
var venues = [];
let categoryType = [];
let seatingType = [];
let suggestions = [];
let default_address=[];
  let addresses= [];
  let venue_list=   [];
class BrowseVenue extends Component {
state = {
type: '',
errorCity:false,
seating: '',
guest: '',
start_date: '',
end_date: '',
start_time: '',
end_time: '',
budget: '',
address:'',
suggestions:[],
venues: [],
getResponse: false,
categoryType: [],
seatingType: [],
markers: [],
dataLength: false,
eventCategory:"",
venueType:[],
initialValues: queryString.parse(this.props.location.search),
latitude: 37.7749,
longitude: -122.4194
};

componentDidMount(){

let searchParams= {
  "search_key": "Conferences"
  }

this.props.staticVenueOnLoad(searchParams).then(response => {
if(response.venueSuggestions && Object.keys(response.venueSuggestions).length !== 0) {
suggestions=[];

response.venueSuggestions.default_address.map(function (data, idx) {
  var obj_a={};
  obj_a.title=data.city;
  obj_a.id=idx+'|1';
  obj_a.type=1;
  obj_a.default=1;
  suggestions.push(obj_a);
  // let obj={};
  // obj.key=data.city;
  // obj.id=idx+'|1';
  // suggestions.push(obj);
  // default_address=response.venueSuggestions.default_address;

})

response.venueSuggestions.addresses.map(function (data, idx) {
  var obj_a={};
  obj_a.title=data.city;
  obj_a.id=idx+'|1';
  obj_a.type=1;
  obj_a.default=0;
  suggestions.push(obj_a);
})

response.venueSuggestions.venues.map(function (data, idx) {
  var obj_v={};
  obj_v.title=data.name;
  obj_v.id=data.id+'|2';
  obj_v.slug=data.slug+'|2';
  obj_v.type=2;
  obj_v.default=0;
  suggestions.push(obj_v);
})

// address
// addresses=response.venueSuggestions.addresses;


//venues
// venues=response.venueSuggestions.venues;

this.setState({ suggestions: suggestions });

}
})
.catch(error => this.setState({ errorValue: true }));
}

componentWillMount() {
markers = [];
venues = []; 
let latitude = 37.7749
let longitude= -122.4194
let jsonData={};
this.props.staticVenueOnLoad();

this.props.homepage()
.then((response) => {
let venueType = [];
response && response.homeVenue.data.venueType.map(function(data, idx) {
let obj={};
obj.id=data.id;
// obj.image=data.image?data.image:'https://d31ogwdhz811go.cloudfront.net/venue_photos/big/0.62386500%201517362530.JPG';
obj.image=data.name?require("../../home/assets/images/venue_for/"+data.name+".jpg"):'https://d31ogwdhz811go.cloudfront.net/venue_photos/big/0.62386500%201517362530.JPG';
obj.event=data.name; 
obj.type = 'type';
venueType.push(obj);
return venueType;
})
this.setState({venueType:venueType})
})
.catch(error => {})
if((this.props.location.search!=='?signup=1' && this.props.location.search !=='') || (this.props.match.params.venueType && this.props.match.params.category)){
// if((this.props.match.params.venueType && this.props.match.params.category)){
this.setState({ dataLength: "calling" });

jsonData =
{
type: this.state.initialValues.category,
seating: this.state.initialValues.seating,
guest: this.state.initialValues.guest_count,
start_date: this.state.initialValues.startDate,
end_date: this.state.initialValues.endDate,
start_time: this.state.startTime,
end_time: this.state.endTime,
budget: this.state.initialValues.budget
};
this.setState({
guest: this.state.initialValues.guest_count,
address:this.state.initialValues.address,
type: this.state.initialValues.category,
seating: this.state.initialValues.seating,
start_date: this.state.initialValues.startDate,
end_date: this.state.initialValues.endDate,
start_time: this.state.startTime,
end_time: this.state.endTime,
budget: this.state.initialValues.budget});
let searchKey ;
let searchBy;
if(this.props.match.params.category && this.props.match.params.venueType){
searchKey = this.props.match.params.venueType;
searchBy = this.props.match.params.category;
} else {
searchKey = this.state.initialValues.address?this.state.initialValues.address:'all';
searchBy = 'all';
}
this.props.browseVenue(jsonData, encodeURIComponent(searchKey), searchBy).then(response => {
if (response.data.length > 0) {
this.setState({ dataLength: true });
} else {
this.setState({ dataLength: false });
}
this.setState({ getResponse: true });
response.data.map(function(data, idx) {
let obj = {};
let markerObj = {};
obj.id = data.id;
obj.slug = data.slug;
let img = data.profilePhoto && process.env.REACT_APP_MEDIA_URL + data.profilePhoto.path;
obj.image = img;
if (img === null) {
obj.image = logo;
}
obj.venueName = data.name;
obj.venueAddress = data.address && data.address.city+", "+data.address.state_abbr;
obj.favourite = data.is_favourite;
if (data.is_favourite === true && data.favourite) {
obj.favouriteId = data.favourite.id;
}
obj.lat = data.address && parseFloat(data.address.geo_lat);
obj.long = data.address && parseFloat(data.address.geo_lng);
markerObj.id = data.slug; 
markerObj.name = data.name;
markerObj.address = data.address && data.address.line1+' '+data.address.city;
markerObj.latitude = data.address && parseFloat(data.address.geo_lat);
markerObj.longitude = data.address && parseFloat(data.address.geo_lng);
venues.push(obj);
markers.push(markerObj);

if(idx === 0){
latitude = data.address && parseFloat(data.address.geo_lat);
longitude = data.address && parseFloat(data.address.geo_lng);
}
return venues;
});
this.setState({ venues: venues });
this.setState({ markers: markers });
this.setState({
latitude: latitude,
longitude: longitude
})
});
}
//for category type
this.props.eventType().then(response => {
categoryType=[];

response.eventTypeDetails.data.map(function(data, idx) {
let obj = {};

if(data.name==='Business Event'){
    obj.value = data.id;
    obj.name = data.name;
    obj.sen=" (Corporate & Association Meetings or Events… )";
}
else if(data.name==='Social Event'){
    obj.value = data.id;
    obj.name = data.name;
    obj.sen=" (Rehearsal Dinners, Reunions, Fundraisers...)";
}
else if(data.name==='Wedding'){
    obj.value = data.id;
    obj.name = data.name;
    obj.sen=" (Wedding Ceremonies & Receptions)";
}
else{
    obj.value = data.id;
    obj.name = data.name;
    obj.sen = '';
}
categoryType.push(obj);
return categoryType;

});
this.setState({ categoryType: categoryType });
});

//for seating type
this.props.seatingCategory().then(response => {
seatingType=[];

response.data.map(function(data, idx) {
let obj = {};
obj.value = data.id;
obj.name = data.name;
seatingType.push(obj);
return seatingType;

});
this.setState({ seatingType: seatingType });
})
.catch(error => this.setState({ errorValue: true }));
}
clickVenue = values => {

    if(values.address){
        this.setState({errorCity:false})

        venues = [];
        markers = [];
        // let latitude = 37.7749
        // let longitude= -122.4194
        this.setState({
        type: values.category,
        seating: values.seating,
        start_date: values.startDate,
        end_date: values.endDate,
        start_time: values.startTime,
        end_time: values.endTime,
        budget: values.budget,
        guest: values.guest_count,
        address:values.address
        })
        let venue_type='0';
        if(this.props.match.params.category && this.props.match.params.venueType){
          venue_type = this.props.match.params.venueType;
        }
        const json = {
        type: values.category,
        seating: values.seating,
        guest: values.guest_count,
        start_date: values.startDate,
        end_date: values.endDate,
        start_time: values.startTime,
        end_time: values.endTime,
        budget: values.budget,
        venue_type: venue_type
        };
        this.setState({ dataLength: "calling" });
        let searchKey ;
        let searchBy;
        // if(this.props.match.params.category && this.props.match.params.venueType){
        // searchKey = this.props.match.params.venueType;
        // searchBy = this.props.match.params.category;
        // } else {
        searchKey = values.address;
        searchBy = 'all';
        // }
        this.props.browseVenue(json, encodeURIComponent(searchKey), searchBy)
        .then(response => {
        this.setState({ getResponse: true });
        if (response.data.length > 0) {
        this.setState({ dataLength: true });
        } else {
        this.setState({ dataLength: false });
        }
        
        response.data.map(function(data, idx) {
        let markerObj={};
        let obj = {};
        let lat_long = {};
        obj.id = data.id;
        obj.slug = data.slug;
        let img =
        data.profilePhoto &&
        process.env.REACT_APP_MEDIA_URL + data.profilePhoto.path;
        obj.image = img;
        
        if (img === null) {
        obj.image = logo;
        }
        
        obj.venueName = data.name;
        obj.venueAddress = data.address && data.address.city+", "+data.address.state_abbr   ;
        obj.favourite = data.is_favourite;
        
        if (data.is_favourite) {
        obj.favouriteId = data.favourite && data.favourite.id;
        }
        
        obj.latitude = data.address && data.address.geo_lat;
        obj.longitude = data.address && data.address.geo_lng;
        lat_long.latitude = data.address && parseFloat(data.address.geo_lat);
        lat_long.longitude = data.addrmarkersess && parseFloat(data.address.geo_lng);
        
        markerObj.id = data.slug; 
        markerObj.name = data.name;
        markerObj.address = data.address && data.address.line1+' '+data.address.city;
        markerObj.latitude = data.address && parseFloat(data.address.geo_lat);
        markerObj.longitude = data.address && parseFloat(data.address.geo_lng);
        
        markers.push(markerObj);
        venues.push(obj);
        return venues;
        });
        
        this.setState({ venues: venues });
        this.setState({ markers: markers });
        
        })
        .catch(error => this.setState({ errorValue: true }));
    }
    else{
        this.setState({errorCity:true})
    }

};
venueAccordingToType = (data, type,id) => {
venues = []; 
this.setState({ dataLength: "calling" , initialValues:{category:id}});

this.props.browseVenue("", encodeURIComponent(data), type).then(response => {
if (response.data.length > 0) {
this.setState({ dataLength: true });
} else {
this.setState({ dataLength: false });
}
response.data.map(function(data, idx) {
let obj = {};
let markerObj = {};
obj.id = data.id;
obj.slug = data.slug;
let img = data.profilePhoto && process.env.REACT_APP_MEDIA_URL + data.profilePhoto.path;
obj.image = img;

if (img === null) {
obj.image = logo;
}
obj.venueName = data.name;
obj.venueAddress = data.address && data.address.city+", "+data.address.state_abbr;
obj.favourite = data.is_favourite;

if ( data.is_favourite && data.favourite ) {
obj.favouriteId = data.favourite.id;
}
obj.lat = data.address && data.address.geo_lat;
obj.long = data.address && data.address.geo_lng;
markerObj.id = data.slug;
markerObj.name = data.name;
markerObj.address = data.address && data.address.line1+' '+data.address.city;
markerObj.latitude = data.address && parseFloat(data.address.geo_lat);
markerObj.longitude = data.address && parseFloat(data.address.geo_lng);
venues.push(obj);
markers.push(markerObj);
return venues;
});
this.setState({ venues: venues });
this.setState({ markers: markers });
});
};
reset = (resetForm,initialValues) => {
resetForm(initialValues);
venues=[];
this.setState({ venues: [] , getResponse: false , dataLength:"resetValue", initialValues : {address:''}});
this.props.history.push('/browseVenue')
window.location.href = '/browseVenue'
}
handleSearchChange =  debounce((value) => {
    let searchParams ={};
  if(value!==''){
    searchParams={    
      search_key:value
    }
  }
  const request = async () => {
    // const response = await this.props.staticVenueOnLoad(searchParams)
           
let address_array=[];
let venue_array=[];
let default_array=[];
let title_loc=false;
suggestions=[];
        address_array = addresses.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(value.toLowerCase())));
        venue_array = venue_list.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(value.toLowerCase())));
        default_array = default_address.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(value.toLowerCase())));
      // if(response.venueSuggestions && Object.keys(response.venueSuggestions).length !== 0) {
           if(address_array.length!==0){
            let obj_a={};
            obj_a.key='Location';
            obj_a.id='Title';
            suggestions.push(obj_a);
           
        address_array.map(function (data, idx) {
              obj_a={};
              obj_a.key=data.city;
              obj_a.id=idx+'|1';
              suggestions.push(obj_a);
            })
          }
          if(venue_array.length!==0){
            let obj_v={};
            obj_v.key='Venues';
            obj_v.id='Title';
            suggestions.push(obj_v);

            venue_array.map(function (data, idx) {
              obj_v={};
              obj_v.key=data.name;
              obj_v.id=data.id+'|2';
              suggestions.push(obj_v);
            })
          }

          if(default_array.length!==0){
            let obj_d={};
            if(title_loc===false){
            obj_d={};
            obj_d.key='Location';
            obj_d.id='Title';
            title_loc=true;
            suggestions.push(obj_d);
            }

            default_address.map(function (data, idx) {
              obj_d={};
              obj_d.key=data.city;
              obj_d.id=idx+'|1';
              suggestions.push(obj_d);
            })
          }

      if(value===''){
        suggestions=[];
            default_array.map(function (data, idx) {
              let obj_b={};
              obj_b.key=data.city;
              obj_b.id=idx+'|1';
              suggestions.push(obj_b);
            })
          }

            // response.venueSuggestions.venue_types && response.venueSuggestions.venue_types.map(function (data, idx) {
            //   let obj={};
            //   obj.key=data.name;
            //   obj.id=idx+'|3';
            //   suggestions.push(obj);
            //   return suggestions;
            // })

            if(suggestions.length===0){
              let obj={};
              obj.key='FIRST-HOLD is expanding every day. We don\'t currently have a venue in the city you searched.';
              obj.id='No';
              suggestions.push(obj);
              // return suggestions;
            }
         
          // }
          this.setState({ suggestions: suggestions });
        }
        request()
        }, 1600);
      
  

render() {
// let venueType=[]
return (

<div className="browse_venue_page fh-container">
{this.props.location && this.props.location.search==='?signup=1'?(localStorage.getItem('disablePopup')!='true'?
<ModalBox />:null)
:null}
<Grid container>
<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>

<CustomizedGoogleMap
markers={this.state.markers}
isMarkerShown
height="400px"
latitude={this.state.latitude}
longitude={this.state.longitude}
/>

<Search errorCity={this.state.errorCity} onSearchChnage={this.handleSearchChange} menuData={this.state.suggestions} initialValues={this.state.initialValues} reset={this.reset} sendData={this.props.sendData} searchData={this.clickVenue} categoryData={categoryType} seatingData={seatingType} />
</Grid>
<Grid className="right_panel" item xs={12} sm={6} md={8} lg={8} xl={8}>
{
this.state.dataLength==="resetValue" ?
<RightPanel
venueType={this.state.venueType}
venueAccordingToType={this.venueAccordingToType}
/>
:
(
this.state.dataLength === "calling" ? (
<h3 style={{ margin: "50px 0" ,textAlign:"center"}}>Loading...</h3>
) : (
this.props.match.params.venueType || this.state.getResponse? (
venues.length === 0 ? (

<h3 style={{ margin: "50px 0",textAlign:"center" }}>
No Data Found</h3>
) : (
<RightPanelVenue 
venues={venues} 

address={this.state.address}
type={this.state.type}
seating={this.state.seating}
guest={this.state.guest}
start_date={this.state.start_date}
end_date={this.state.end_date}
start_time={this.state.start_time}
end_time={this.state.end_time}
budget={this.state.budget}/>
)
) : (
<RightPanel
venueType={this.state.venueType}
venueAccordingToType={this.venueAccordingToType}
/>
)

)
)
}
</Grid>
</Grid>
</div>
);
}
}

BrowseVenue.propTypes = {
classes: object.isRequired,
browseVenue: func.isRequired,
addCategory: func.isRequired,
eventType: func.isRequired,
seatingCategory: func.isRequired,
homepage: func.isRequired,
staticVenueOnLoad: func.isRequired,

};

const mapStateToProps = state => {
return {
browseVenueData: state.data.browseVenueData.browseData,
addCategoryData: state.data.browseVenueData.categoryData,
seatingCategoryData: state.data.browseVenueData.arrangementData,
sendData: state.data.browseVenueData.sending,
venueTypeList: state.data.venues.venueTypeList,
suggestions: state.data.venueLoadSuggestion.suggestions,

};
};
const enhance = compose(
withRouter,
connect(mapStateToProps, dispatch =>
bindActionCreators(
{
browseVenue,
addCategory,
eventType,
homepage,
seatingCategory,
staticVenueOnLoad,

},
dispatch
)
)
);
export default enhance(BrowseVenue);

// export default withRouter(BrowseVenue);


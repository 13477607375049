import React from 'react';

import { Grid , Button } from '@material-ui/core';
import { Link } from "react-router-dom";

function openVenuePlan(props){
    localStorage.setItem('disable_plan_selection',true)
  }
function HowItWork(props) {
    return (
        <Grid container className="how_it_work_screen fh-container">
            <Grid xs={12} item>
                <Grid className="how_it_works" container justify="center">
                Why it Works for Venues
                </Grid>
                <Grid className="how-it-works-blue-text" container justify="center">
                   <p>Getting qualified leads just got an all-in-one upgrade.</p><br></br>
                   <br></br>
                </Grid>
                <Grid className="how-it-works-text" container justify="center">
                   <p>FIRST-HOLD is a no-commission venue marketing platform that brings experienced planners and caterers straight to <u>your</u> inbox.</p>
                </Grid>
            </Grid>
            <Grid xs={12} item className="how-it-works-list">
                <Grid container justify="center">
                    <Grid className="event_planners_section" item xs={12} sm={12} md={4} xl={4} lg={4}>
                        <Grid container justify="center">
                            <Grid item className="event_planners" xs={12} md={8} sm={12} lg={8} xl={8}>LIVE AVAILABILITY LISTINGS &mdash; ON YOUR WEBSITE AND OURS!</Grid>
                            <Grid item className="event_planner_content" xs={12} md={8} sm={12} lg={8} xl={8}>Show live availability on our website, on yours, and share with favorite industry partners like CVBs and caterers with our cloud-based calendar syncing.</Grid>
                        </Grid>
                    </Grid>
                    <Grid className="event_planners_section" item xs={12} sm={12} md={4} xl={4} lg={4}>
                        <Grid container justify="center">
                            <Grid item className="event_planners contact-information" xs={12} md={8} sm={12} lg={8} xl={8}>CONTACT INFORMATION PROVIDED WITH EVERY HOLD</Grid>
                            <Grid item className="event_planner_content" xs={12} md={8} sm={12} lg={8} xl={8}>Receive direct contact info for holds placed on your venue. You call planners directly to find out event details and close the business.</Grid>
                        </Grid>
                    </Grid>
                    <Grid className="event_planners_section" item xs={12} sm={12} md={4} xl={4} lg={4}>
                        <Grid container justify="center">
                            <Grid item className="event_planners" xs={12} md={8} sm={12} lg={8} xl={8}>EASY GOOGLE CALENDAR INTEGRATION</Grid>
                            <Grid item className="event_planner_content" xs={12} md={8} sm={12} lg={8} xl={8}>There’s no cumbersome technology to buy & learn. We integrate directly with Google Calendar!</Grid>
                        </Grid>
                    </Grid>
                    <Grid className="event_planners_section pt-5" item xs={12} sm={12} md={4} xl={4} lg={4}>
                        <Grid container justify="center">
                            <Grid item className="event_planners" xs={12} md={8} sm={12} lg={8} xl={8}>10-MINUTE PROFILE SETUP</Grid>
                            <Grid item className="event_planner_content" xs={12} md={8} sm={12} lg={8} xl={8}>Just a simple 5-step process to start listing your venue.</Grid>
                        </Grid>
                    </Grid>
                    <Grid className="event_planners_section pt-5" item xs={12} sm={12} md={4} xl={4} lg={4}>
                        <Grid container justify="center">
                            <Grid item className="event_planners" xs={12} md={8} sm={12} lg={8} xl={8}>GET BETTER QUALIFIED HOLDS</Grid>
                            <Grid item className="event_planner_content" xs={12} md={8} sm={12} lg={8} xl={8}>FIRST-HOLD caters to experienced planners and caterers who know what they’re looking for. </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="event_planners_section pt-5" item xs={12} sm={12} md={4} xl={4} lg={4}>
                        <Grid container justify="center">
                            <Grid item className="event_planners" xs={12} md={8} sm={12} lg={8} xl={8}>NO COMMISSION &mdash; EVER!</Grid>
                            <Grid item className="event_planner_content" xs={12} md={8} sm={12} lg={8} xl={8}>Join today with flexible pricing tiers. We never take a commission from your sale.</Grid>
                        </Grid>
                    </Grid>
                    <Grid className="venue_managers_section list-venue_managers" item xs={12} md={12} sm={12} xl={12} lg={12}>
                        <Grid container justify="center">
                                <Grid className="how-it-works-text pb-0" container justify="center">
                                        <p>And, we have dedicated customer service reps available to answer any questions you have. </p>
                                </Grid> 
                                <Grid className="how-it-works-text" container justify="center">
                                        <p>Try FIRST-HOLD free for 30 days!</p>
                                </Grid> 
                                <Grid container justify="center">
                                    <Button href="/register" > Join now!
                                    </Button>
                               
                                </Grid>
                               
                        </Grid>
                    </Grid>
                    <Grid className="marginTopD">
                        <Link style={{color: '#1397e1',fontSize: '12px'}} to='/venuePlanList' onClick={(e)=>{openVenuePlan(props)}} target="_blank">View Plan Details</Link>
                      </Grid>
                    <Grid container justify="center">
                   
                        <Grid className="how-it-works-heading" container justify="center">
                                <h3>Are you a professional event planner?</h3>
                        </Grid>
                        <Grid className="how-it-works-link" container justify="center">
                            <p><a href="/"><u>See how FIRST-HOLD works for you.</u></a> (Browse & place holds on real, available event venues now)
                            </p>
                        </Grid>  
                    </Grid>
                    
                </Grid>
            </Grid>
        </Grid>
    );
}

export default (HowItWork);

import { DELETE_SPACE_AVAILABILITY } from 'shared/state/types';
import { request } from 'shared/auth';

function deleteSpaceAvailabilityRequested() {
  return {
    type: DELETE_SPACE_AVAILABILITY.REQUEST,
  };
}

function deleteSpaceAvailabilityFailed(error) {
  return {
    type: DELETE_SPACE_AVAILABILITY.FAILURE,
    error:error,
  };
}

function deleteSpaceAvailabilitySuccess(message) {
  return {
    type: DELETE_SPACE_AVAILABILITY.SUCCESS,
    message: message
  };
}

export function deleteSpaceAvailability(spaceAvailabilityId) {
  return dispatch => {
    dispatch(deleteSpaceAvailabilityRequested());

    return request().delete(`space_availables/${spaceAvailabilityId}`)
      .then(response =>{
          dispatch(deleteSpaceAvailabilitySuccess(response.data.message))
          return response
      }
      )
      .catch(errors => {
        dispatch(deleteSpaceAvailabilityFailed());
        throw errors;
      });
  };
}

import axios from 'axios';
import { FIND_GOOGLE_TOKEN } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function findGoogleTokenRequested() {
  return {
    type: FIND_GOOGLE_TOKEN.REQUEST,
  };
}

function findGoogleTokenSuccess(data) {
  return {
    type: FIND_GOOGLE_TOKEN.SUCCESS,
    tokenData: data,
  };
}

function findGoogleTokenFailure(error) {
  return {
    type: FIND_GOOGLE_TOKEN.FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function getGoogleToken() {

    return dispatch => {
      dispatch(findGoogleTokenRequested());
        return request().get(`google/access_token`)
        .then(response => {
            dispatch (findGoogleTokenSuccess(response.data),
          )
          return response.data;
        }
        ) 
        .catch(errors => {
          if(errors.response && errors.response.data.message){
            dispatch(findGoogleTokenFailure(errors.response.data.message));
          }
          else{
             dispatch(findGoogleTokenFailure("Not able to find this token"));
          }
  
          throw errors;
        });
      

    
 };
}

import { createSelector } from 'reselect';

const getStateAuth = state => state.data.auth;

const getAuth = createSelector(
  getStateAuth,
  auth => auth,
)

export {
  getStateAuth,
  getAuth,
};

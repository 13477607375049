import React from 'react';

import { Grid, Hidden } from '@material-ui/core';
import { Field, ErrorMessage,useFormikContext } from 'formik';

import '../../shared/components/venueImageUpload';
import '../assets/styles/addVenue.scss';
import VenueImageUpload from '../../shared/components/venueImageUpload';
import CustomizedButton from '../../shared/components/customizedButton';

function AddVenuePhoto(props) {
    const { values } = useFormikContext();
    return (
        <Grid className="add_venue_photo" container>
            <Hidden xsDown={true}>
                <Grid item sm={1} md={2} lg={3} xl={3}></Grid>
            </Hidden>
            <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
                <Grid container justify="center" className="add_venue_photo_container">
                    <Grid className="learning_hall_container" item xs={12}>
                        <h4 className="learning_hall">{props.formikProps.values.venueName}</h4>
                    </Grid>
                    <Hidden xsDown={true}>
                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                    </Hidden>
                    <Grid className="image_detail_form" item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <div className="add_venue_profile_photo_heading">Add a Dynamic Venue Photo -- Main Profile Photo</div>

                        <VenueImageUpload
                          name='venueImage'
                          cropping={props.cropping}
                          cropDone={props.cropDone}
                          okButton={props.okButton}
                          setFieldValue={props.setFieldValue}
                          crop={props.crop}
                          zoom={props.zoom}
                          rotation={props.rotation}
                          onRotationChange={props.onRotationChange}
                          aspect={props.aspect}
                          onCropChange={props.onCropChange}
                          onCropComplete={props.onCropComplete}
                          setSubmitting={props.setSubmitting}
                          venueImageError={props.venueImageError}
                          handleChange={props.handleChange}
                          imageFile={props.imageFile}
                          imageUploaded={props.imageUploaded}
                          imageFileName={props.imageFileName}
                          imageUploading={props.imageUploading}
                          handleImageUpload={props.handleImageUpload}
                          handleImageChange={props.handleImageChange}
                          height="260px"
                          imageUrl={props.imageUrl}
                        />

                        {props.cropping ?  
                        <div className="venue-profile-cropper-block">
                            <div className="field_div wid-50">
                                <div className="slider-container" style={{padding:'5px'}}>
                                    <label className="container">
                                        Slide to Zoom photo
                                    </label>
                                    <br />
                                    <input type="range"
                                    min="1"
                                    max="20"
                                    defaultValue={props.zoom}
                                    className="slider full-width"
                                    id="myRange"
                                    step="1"
                                    // className="full-width"
                                    onChange={props.onZoomChange}
                                    />
                                </div>
                            </div>
                            <div className="field_div wid-50">
                            <div className="slider-container" style={{padding:'5px'}}>
                                <label className="container">
                                    Slide to Rotate photo
                                </label>
                                <br />
                                <input type="range"
                                  min="0"
                                  max="360"
                                  defaultValue={props.rotation}
                                  className="slider full-width"
                                  id="myRotate"
                                  step="1"
                                //   className="full-width"
                                  onChange={props.onRotationChange}
                                />
                            </div>
                        </div>
                       
                 
                        {/* <div>
                          <img
                            src={props.croppedImage}
                            alt="Cropped"
                            className="max-full-width"
                          />
                        </div> */}
                     

                     
  <CustomizedButton label="Crop" onClickEvent={()=>props.okButton(props.okButton)}/>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <CustomizedButton label="Cancel" onClickEvent={()=>props.cancelButton(props.setFieldValue)}/>
                        <br />
                        <br />
                        </div>

                        :null }
                     
                        <div className="field_div">
                                                    <div className="checkbox">
                                                        <label className="container">
                                                        I have <a href="/terms#photos" target="_blank" style={{color:"#1397e1"}}> copyright </a> to use these photos.
                                                            <Field type="checkbox"
                                                                name="imageCheck"
                                                                onChange={props.handleChange}
                                                                checked={values.imageCheck}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <div className="error"><ErrorMessage name="imageCheck" /></div>
                                                </div>



                        <div className="add_venue_description">Add Venue Description</div>
                        <div className="description">Write a brief, eye-catching description of this venue:</div>
                        <div className="venue_description">
            
                            <Field component="textarea" name="venueDescription" placeholder="1,500 characters" />
                            <div className="error"><ErrorMessage name="venueDescription" /></div>
                        </div>
                    </Grid>
                    <Hidden xsDown={true}>
                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                    </Hidden>
                </Grid>
            </Grid>
            <Hidden smDown={true}>
                <Grid item sm={1} md={2} lg={3} xl={3}></Grid>
            </Hidden>
        </Grid>
    );
}


export default AddVenuePhoto;

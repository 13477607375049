import React from 'react';
import { object } from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
Button
} from '@material-ui/core';

const styles = theme => ({
button: {
// backgroundImage: 'linear-gradient(#f1435e,#ee2738)',
// color: '#ffffff !important',
fontSize: '14px',
fontWeight: '500',
fontStyle: 'normal',
fontStretch: 'normal',
lineHeight: 'normal',
letterSpacing: '0.5px',
// width: '232px',
border:'none !important',
background:'transparent'
},
image: {
// width: '26px',
// height: '24px',
// marginLeft: '9px'
},
});

const MicrosoftSignUp = (props) => (
<Button onClick={props.onMicrosoftClick} color="secondary" className={props.classes.button}>
{/* {props.text} */}
<img className={props.classes.image} src={require('../../assets/images/outlook.png')} alt="gmail icon" />
 <span className="login-btn-text">Sign in with Outlook </span>
</Button>
)
MicrosoftSignUp.propTypes = {
classes: object.isRequired,
};

export default withStyles(styles)(MicrosoftSignUp);


import {Record} from 'immutable';

import {GET_HOLDS,GET_PLANNER_PROFILE} from 'shared/state/types';

class State extends Record({fetching: false, fetched: false, holds: {},planner_profile:{}}) {}

export default function reducer(state = new State(), action) {
    switch (action.type) {
        case GET_HOLDS.REQUEST:
            return state.merge({fetching: true, fetched: false, holds: []});
        case GET_HOLDS.SUCCESS:
            return state.merge({fetching: false, fetched: true, holds: action.payload});
        case GET_HOLDS.FAILURE:
            return state.merge({fetching: false, fetched: false});
        case GET_PLANNER_PROFILE.REQUEST:
            return state.merge({fetching: true, fetched: false, planner_profile: {}});
        case GET_PLANNER_PROFILE.SUCCESS:
            return state.merge({fetching: false, fetched: true, planner_profile: action.payload});
        case GET_PLANNER_PROFILE.FAILURE:
            return state.merge({fetching: false, fetched: false});
        default:
            return state;
    }
}

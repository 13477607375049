import { Record } from 'immutable';

import {
  GET_VENUE_REQUEST,
  GET_VENUE_FAILURE,
  GET_VENUE_SUCCESS,
  GET_PLANNER_CALENDAR_DATA
} from 'shared/state/types';

class State extends Record({
  venueDetails:{},
  similarVenue:[],
  plannerCalendarData:[],
  error:''
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_VENUE_REQUEST:
      return state.merge({
        error:''
      });
    case GET_VENUE_FAILURE:
      return state.merge({
        venueDetails:{},
        error: action.error,
        plannerCalendarData: []

      });
    case GET_VENUE_SUCCESS:
      return state.merge({
        venueDetails:action.venueDetails.venue,
        similarVenue:action.venueDetails.similiar_venue,
        error:''
      });
    case GET_PLANNER_CALENDAR_DATA.REQUEST:
        return state.merge({
          error:''
        });
    case GET_PLANNER_CALENDAR_DATA.FAILURE:
      return state.merge({
        plannerCalendarData: [],
        error: action.error
    });
    case GET_PLANNER_CALENDAR_DATA.SUCCESS:
      return state.merge({
        plannerCalendarData:action.plannerCalendarData,
        error:''
    });

    default:
      return state;
  }
}


import React from 'react';
import { object } from 'prop-types';

import CustomizedButton from '../../../shared/components/customizedButton';

import { Formik, ErrorMessage, Field, Form } from 'formik';
import { Grid, Button } from '@material-ui/core';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';

import '../../assets/styles/auth.scss';

const phoneRegExp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/  /* eslint-disable-line */
const PhoneEditSchema = Yup.object().shape({
  phone: Yup.string().matches(phoneRegExp, 'Invalid Phone Number!')
});


function PhoneEdit(props) {
  return (
    <Grid className="phone_container" item md={6} lg={6} sm={6} xl={6} xs={12}>
      <Formik
        validationSchema={PhoneEditSchema}
        initialValues={{ phone: props.phone }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          props.handleSubmit(values)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container justify="center">
              <Field align="center" type="text" name="phone"
                render={({ field }) => (
                  <MaskedInput
                    className="phone_edit"
                    {...field}
                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    id="phone"
                    placeholder="(XXX)-XXX-XXXX"
                    type="text"
                  />
                )} />
              <div className="email_phone_edit_error"><ErrorMessage name="phone" /></div>
            </Grid>
            <Grid className="button_container" container justify="center">
              <CustomizedButton type="submit" label='Save' disabled={isSubmitting} />
            </Grid>
            <Grid align="center" className="phone_edit_back_button">
              <Button onClick={() => props.onClickBack('phone')} classes={{
                root: props.classes.linkButton
              }}>
                back
            </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

PhoneEdit.propTypes = {
  classes: object.isRequired,
};

export default PhoneEdit;
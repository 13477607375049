import React, { Component } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { Grid, Avatar, Button, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { sendConfirmation, updateUser, getUser } from 'data/person/actions';
import CustomizedButton from '../../../shared/components/customizedButton';
import EmailEdit from '../components/emailEdit';
import PhoneEdit from '../components/phoneEdit';
import '../../assets/styles/confirm.scss';
const profileLogo = require('../../assets/images/blank-profile-picture-973460_640.png');

let contactType = 0;
const styles = theme => ({
  mailIcon: {
    padding: '0px 0px 0px 9px',
    '&:focus': {
      background: "none",
    },
    '&:hover': {
      background: "none",
    }
  },
  bigAvatar: {
    margin: 10,
    width: 108,
    height: 108,
  },
  root: {
    height: '20px',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    fontSize: '12px',
    color: '#1397e1',
    width: '177px'
  },
  linkButton: {
    color: '#36434b',
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    fontSize: '14px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    fontFamily: 'Raleway',
    marginTop: '-8px',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#1397e1',
    },
  }
});

class ProfileInfoConfirm extends Component {

  state = {
    emailEdit: false,
    phoneEdit: false,
    phone: this.props.phone_number ? this.props.phone_number : localStorage.getItem('phone_number'),
    email: this.props.email ? this.props.email : localStorage.getItem('email')
  }
  UNSAFE_componentWillMount() {
    this.props.getUser();
  }

  handleEditClick = (value) => {
    if (value === 'email') {
      this.setState({ emailEdit: !this.state.emailEdit })
    }
    else {
      this.setState({ phoneEdit: !this.state.phoneEdit })
    }
  }
  handleButtonClick = (value) => {
    if (value === 'email') {
      this.setState({ emailEdit: false })
      contactType = 0
    }
    else {
      this.setState({ phoneEdit: false })
      contactType = 1
    }
    const formData = { contact_type: contactType }
    this.props.sendConfirmation(formData)
      .then((response) => {
        if (response === 'Contact is verified already.') {
          this.props.history.push("/login")
        }
        else {
          if (contactType === 0) {
            this.props.history.push("/confirmEmail")
          }
          else if (contactType === 1) {
            this.props.history.push("/confirmPhone")
          }
        }
      })
      .catch(error => { })
  }
  handleSubmit = (values) => {
    if (values.email) {
      contactType = 0

    }
    else if (values.phone) {
      contactType = 1
    }
    const formData = contactType === 1 ? { phone_number: values.phone } : { email: values.email }
    this.props.updateUser(formData)
      .then((response) => {
        if (values.email) {
          this.setState({ emailEdit: false })
        }
        else if (values.phone) {
          this.setState({ phoneEdit: false })
        }
      })
      .catch(error => { })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }


  render() {
    const queryParameters = new URLSearchParams(window.location.search)
    const type = queryParameters.get("edit")
    let phoneNumber = String(this.props.phone_number)
    let maskedPhoneNumber = phoneNumber.replace(/\D+/g, '').replace(/\d(?=\d{4})/g, "*");
    let emailId = String(this.props.email)

    let emailLength = emailId.length;
    let indexOfFirstChar = emailId[0];
    let emailSplit = emailId.indexOf("@")
    let partialEmail = '';
    let hiddenEmail = '';
    let maskedEmail='';
    
    for (let i = emailSplit + 3; i < emailLength; i++) {
      partialEmail = partialEmail + emailId[i];
    }
    for (let i = 1; i <= emailSplit + 2; i++) {
      hiddenEmail = hiddenEmail + "*";
    }
     maskedEmail = indexOfFirstChar + hiddenEmail + partialEmail;

    const { classes } = this.props;
    return (
      <div>
        <div className="profile_confirmation">
          <Grid className="profile_confirmation__inner" container justify="center">
            <Grid item xl={10} xs={10} md={8} lg={6} sm={10}>
              <div className="profile_confirmation_form">
                <Grid container justify="center">
                  {localStorage.getItem('profilePhoto') ?
                    <Avatar alt={this.props.first_name} src={localStorage.getItem('profilePhoto') ? localStorage.getItem('profilePhoto') : profileLogo} className={classes.bigAvatar} />
                    :

                    <div className="user-icon-profile">

                      <Icon className="icon">person</Icon>

                    </div>

                  }
                </Grid>
                {/*<h1 align="center" className="profile_confirmation_heading">Let Venue Managers know you mean business! </h1>*/}
                <div className="profile_confirmation_content" align="center">
                  FIRST-HOLD needs to confirm your account via a personalized code.<br />
                  Select how you’d like to confirm:
                </div>
                <Grid className="contact_container" container justify="center">
                  {this.state.phoneEdit ?
                    <PhoneEdit classes={classes} phone={this.props.phone_number} updating={this.props.updating} handleSubmit={this.handleSubmit} onClickBack={this.handleEditClick} />
                    :
                    <Grid className="phone_container" item md={6} lg={6} sm={6} xl={6} xs={12}>
                      <Grid className="email_phone" align="center">
                        {maskedPhoneNumber}
                      </Grid>
                      <Grid className="textAndEmailButton" container justify="center">
                        {/*this.props.phone_verified_at ?
                          <CustomizedButton label='Verified' disabled='true' />
                          : */<CustomizedButton label='Text Me' disabled={this.props.sending} onClickEvent={() => this.handleButtonClick('phone')} />
                        }


                      </Grid>
                      <Grid align="center" className="phone_edit_button">
                        {type != 1 && <Button onClick={() => this.handleEditClick('phone')} classes={{
                          root: classes.linkButton
                        }}>
                          edit
                        </Button>}
                      </Grid>
                    </Grid>
                  }
                  {this.state.emailEdit ?
                    <EmailEdit classes={classes} email={this.props.email} updating={this.props.updating} handleSubmit={this.handleSubmit} onClickBack={this.handleEditClick} />
                    :
                    <Grid className="email_container" item md={6} lg={6} sm={6} xl={6} xs={12}>
                      { this.props.email==null?'':<Grid className="email_phone" align="center">
                        { maskedEmail}
                      </Grid>}
                      <Grid className="textAndEmailButton" container justify="center">

                        {/*this.props.email_verified_at ?
                          <CustomizedButton label='Verified' disabled='true' />


                          :*/
                          <CustomizedButton label='Email Me' disabled={this.props.sending} onClickEvent={() => this.handleButtonClick('email')} />

                        }

                      </Grid>
                      <Grid align="center">
                        {type != 1 && <Button onClick={() => this.handleEditClick('email')} classes={{
                          root: classes.linkButton
                        }}>
                          edit
                        </Button>}
                      </Grid>
                    </Grid>
                  }
                </Grid>
                <div class="contact-us-link">
                  <p>
                    Credentials changed? <a href="mailto:hello@first-hold.com">Contact us</a>
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

ProfileInfoConfirm.propTypes = {
  classes: object.isRequired,
};

const mapStateToProps = state => {
  return {
    email: state.data.auth.email,
    email_verified_at: state.data.auth.userData.email_verified_at,
    phone_verified_at: state.data.auth.userData.phone_number_verified_at,

    phone_number: state.data.auth.phone_number,
    sending: state.data.auth.sending,
    success: state.data.auth.success,
    updating: state.data.auth.updating
  };
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(
    mapStateToProps,
    dispatch => bindActionCreators({
      sendConfirmation,
      updateUser,
      getUser
    }, dispatch),
  )
)

export default enhance(ProfileInfoConfirm);

import axios from 'axios';
import { UPLOAD_TO_S3 } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function uploadtoS3Requested() {
  return {
    type: UPLOAD_TO_S3.REQUEST,
  };
}

function uploadtoS3Success(data) {
  return {
    type: UPLOAD_TO_S3.SUCCESS,
    data: data,
  };
}

function uploadtoS3Failure(error) {
  return {
    type: UPLOAD_TO_S3.FAILURE,
    error:error,
  };
}

const request = () => {
  const headers = {'Content-Type': 'multipart/form-data'};

  const axiosInstance = axios.create({
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function uploadtoS3(url, data) {

    return dispatch => {
      dispatch(uploadtoS3Requested());
        return request().put(url, data)
        .then(response => {
          if(response.status === 200){
            dispatch(uploadtoS3Success(response.data))
            return response.data;
          }
          else if(response.status === 400){
            dispatch(uploadtoS3Failure("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(uploadtoS3Failure("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(uploadtoS3Failure("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(uploadtoS3Failure("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(uploadtoS3Failure("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(uploadtoS3Failure("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(uploadtoS3Failure("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(uploadtoS3Failure("The server was unavailable."));
          }
          else{
            dispatch(uploadtoS3Failure("The server was unavailable."));
          }
          return response.data;
        }
        ) 
        .catch(errors => {
          if(errors && errors){
            dispatch(uploadtoS3Failure(errors.toString()));
          }
          else{
             dispatch(uploadtoS3Failure("Something went wrong."));
          }

          return 0;
          
  
          
        });
      

    
 };
}

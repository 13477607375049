import { DELETE_IMAGE_UPLOAD } from 'shared/state/types';
import { request } from 'shared/auth';

function deleteResourceRequested() {
  return {
    type: DELETE_IMAGE_UPLOAD.REQUEST,
  };
}

function deleteResourceFailed(error) {
  return {
    type: DELETE_IMAGE_UPLOAD.FAILURE,
    error:error,
  };
}

function deleteResourceSuccess() {
  return {
    type: DELETE_IMAGE_UPLOAD.SUCCESS,
  };
}

export function deleteUploadImage(id) {
  return dispatch => {
    dispatch(deleteResourceRequested());

    return request().delete(`resources/${id}`,)
      .then(response =>{
        dispatch(deleteResourceSuccess())
      }
      )
      .catch(errors => {
          if(errors){
            dispatch(deleteResourceFailed("Not Found"));
          }
          else{
            dispatch(deleteResourceFailed("Unable to access api"));
          }
        throw errors;
      });
  };
}

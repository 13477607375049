import { UNSUBSCRIBE_PLAN } from 'shared/state/types';
import { request } from 'shared/auth';

function getPlanRequested() {
  return {
    type: UNSUBSCRIBE_PLAN.REQUEST,
  };
}

function getPlanFailed(error) {
  return {
    type: UNSUBSCRIBE_PLAN.FAILURE,
    error:error,
  };
}

function getPlanSuccess(data) {
  return {
    type: UNSUBSCRIBE_PLAN.SUCCESS,
    payload: data,
  };
}

export function unsubscribeCustomer(params,venueId) {
  return dispatch => {
    dispatch(getPlanRequested());

    return request().post('user_plans/unsubscribe',params)
      .then(response =>{
          dispatch(getPlanSuccess(response.data.data))
          return response.data.data;
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(getPlanFailed(errors.response.data.message));
        }
        else{
          dispatch(getPlanFailed('Not able to access api'));
        }
        throw errors;
      });
  };
}

import React , { Component }from 'react';
import { Grid } from '@material-ui/core';
import Footers from '../../landingPage/components/footer';
import './assets/style/blog.scss';
import { getBlogs } from '../../../src/data/venues/actions/getBlogs';
import { bindActionCreators, compose } from "redux";
import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment";
import {
    AppBar,
    Icon,
    Toolbar,
    IconButton,
    MenuItem,
    Menu,
    Button
} from '@material-ui/core';
const logo = require("./assets/blog_default.png");

class blog extends Component {

    state = {
        blogs:[],
        category:null,
        loading:true
      }
    componentDidMount()
    {
        let queryParamsvalue=''
        let key=''
        const count=count
        const queryParams = new URLSearchParams(window.location.search)
        if(queryParams.get('slug'))
        {
            queryParamsvalue=queryParams.get('slug')
            key='blogDetail'   
        }
        else if(queryParams.get('category'))
        {
            queryParamsvalue=queryParams.get('category')
            key='category'
        }
       
        this.props.getBlogs(queryParamsvalue!=''?queryParamsvalue:'',key).then((result) => {
            if ('data' in result && Array.isArray(result.data)) {
            this.setState({blogs:result.data})
            this.setState({loading:false})
            };
    
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.category !== nextProps.category) {
            let queryParamsvalue=''
            let key=''
            const count=count
            const queryParams = new URLSearchParams(window.location.search)
            if(queryParams.get('slug'))
            {
                queryParamsvalue=queryParams.get('slug')
                key='blogDetail'   
            }
            else if(queryParams.get('category'))
            {
                queryParamsvalue=queryParams.get('category')
                key='category'
            }
           
            this.props.getBlogs(queryParamsvalue!=''?queryParamsvalue:'',key).then((result) => {
                if ('data' in result && Array.isArray(result.data)) {
                this.setState({blogs:result.data})
                this.setState({loading:false})
                };
        
            })  
        }
 
    }
render() {
    return (
        <div className="">
            <div class="container top">
                <div class="header-title-nav-wrapper">
                    <div class="header-title-logo">
                        <a href="/planners" data-animation-role="header-element">
                            <img class="large" src={require('../assets/images/logo.png')} alt="Logo" />
                            <span> Blog</span>
                        </a>
                    </div>
                    <div class="header-nav">
                        <div class="header-nav-wrapper">
                            <div class="mobile-view-nav">
                               <div class="category-btn">Categories</div>
                                   <div class="category-btn-hover">
                                        <Link to={`/blogs/event-venue?category=Event Venue`} onClick={(e)=>{this.setState({category:'Event Venue',blogs:[]})}}>
                                            Event Venue
                                        </Link>
                                        <Link to={`/blogs/weddings?category=Weddings`} onClick={(e)=>{this.setState({category:'Weddings',blogs:[]})}}>
                                            Weddings
                                        </Link>
                                        <Link to={`/blogs/corporate-events?category=Corporate Events`} onClick={(e)=>{this.setState({category:'Corporate Events',blogs:[]})}}>
                                            Corporate Events
                                        </Link>
                                   </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Grid container className="">
                {this.state.loading==false && this.state.blogs && this.state.blogs.map(({ id, title,created_at,image,slug,image_alt }) => (
                    <Grid className="image-block" item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <div class="blog-wrpper">
                            <Link to={`/blog/${slug}`}>
                                <div className="blog_image_box">
                                <img src={image?image: logo} alt={image_alt}></img>
                                </div>
                                {/*<p>{moment(new Date(created_at.slice(0,10))).format("l")}</p>*/}
                                <h1>
                                    <a href="" style={{whiteSpace: "pre-wrap"}}>{title}</a>
                                </h1>
                            </Link>
                          
                        </div>
                    </Grid>
                    ))}
                </Grid>
            </div>
            {this.state.loading==true &&<div className="loading_blog">Loading...</div>} 
            <Footers />
        </div>
    );
}
}

const mapStateToProps = state => {
    return {
    };
};

const enhance = compose(
    withRouter,
    connect(mapStateToProps, dispatch =>
        bindActionCreators(
            {

                getBlogs

            },
            dispatch
        )
    )
);
export default enhance(withRouter(blog));

import React from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Icon, Grid, List, ListItem, Avatar, Card, CardHeader } from '@material-ui/core';
import './assets/style/contactinfo.scss';
import ContactPopup from '../../shared/components/contactPopup';
import { useState, useEffect } from "react";
const styles = theme => ({
  title: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "1.43",
    textAlign: "left",
    color: "#62717a",
    wordBreak: 'break-word',
    paddingRight: '5px',
    fontFamily: "Raleway"
  },
  subheader: {
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "1.33",
    textAlign: "left",
    color: "#62717a",
    fontFamily: "Raleway"
  },
  icon: {
    color: "#1397e1",
    fontSize: "17px"
  },
  pdficon: {
    color: "#e20d0d",
    fontSize: "17px"
  },
  listItemText: {
    fontSize: "14px",
    fontWeight: "600",
    textAlign: "left",
    color: "#36434b",
    fontFamily: "Raleway"
  }
});

function ContactInfo(props) {
  const { classes } = props;
  const logo = require("../../shared/components/assets/images/user-default.png");
  //for event
  let eventGroup = [];
  if(props.spaceId){
      props.spaceInfo.timeslots && props.spaceInfo.timeslots.map(function(datatime, idx) {
        datatime.event_types.map(function(dataevent, idx) {
          eventGroup.push(dataevent.name);
          return eventGroup;
        });
        return eventGroup;
      });
  }
  else{
    props.venueProfile.spaces && props.venueProfile.spaces.map(function(data, idx) {
     data.timeslots.map(function(datatime, idx) {
       datatime.event_types.map(function(dataevent, idx) {
          eventGroup.push(dataevent.name);
          return eventGroup;
        });
        return eventGroup;
      });
      return eventGroup;
    });
  }
  //remove repeated data
  let event = eventGroup.filter( (ele, ind) => ind === eventGroup.findIndex( elem => elem === ele))
  //data bind for event
  const eventData = event.map(function(data, idx) {
    return (
      <ListItem>
        <Icon className={classes.icon}>domain</Icon>
        <p

          // href="javascript:void(0)"
          style={{
            color: "#36434b",
            fontSize: "14px",
            textAlign: "left",
            fontWeight: "600",
            fontFamily: "Raleway",
            padding: "0 16px"
          }}
        >
          {data+'s'}
        </p>
      </ListItem>
    );
  });
  const [spacedata, setspacedata] = useState([]);
  const [spacevideodata, setspacevideodata] = useState([]);
  const [venuevideodata, setvenuevideodata] = useState([]);
  const [venuedata, setvenuedata] = useState([]);
  useEffect(() => {
    if(props.spaceInfo.documents)
    {
    setspacedata(props.spaceInfo.documents)
    setspacevideodata(props.spaceInfo.videos)
    }
    if(props.venueProfile.documents)
    {
    setvenuedata(props.venueProfile.documents)
    setvenuevideodata(props.venueProfile.videos)
    }

  }, []);
  let documentData;
  let videoData;
  if (props.spaceId) {
    documentData =
       spacedata &&
        spacedata.map(function(data, idx) {
        return (
  
          parseInt(localStorage.getItem('type'))===0?
          <ListItem>
        <Icon className={classes.pdficon}>picture_as_pdf</Icon>

            <a 
            rel="noopener noreferrer"
            target="_Blank"
            style={{
            color: "#36434b",
            fontSize: "14px",
            textAlign: "left",
            fontWeight: "600",
            fontFamily: "Raleway",
            padding: "0 16px"
          }} href={'/pdfReader?url='+data.path}>
          {data.name}   
          </a>
          </ListItem>
          :null
          
          
      
        );
      });
      videoData =
      spacevideodata &&
      spacevideodata.map(function(data, idx) {
        return (
  
          parseInt(localStorage.getItem('type'))===0?
          <ListItem>
        <Icon className={classes.icon}>link</Icon>

            <a 
            rel="noopener noreferrer"
            target="_Blank"
            style={{
            color: "#36434b",
            fontSize: "14px",
            textAlign: "left",
            fontWeight: "600",
            fontFamily: "Raleway",
            padding: "0 16px"
          }} href={data.path}>
          {data.name}   
          </a>
          </ListItem>
          :null
          
          
      
        );
      });
  } else {

    documentData =
      venuedata &&
      venuedata.map(function(data, idx) {

        return (
            parseInt(localStorage.getItem('type'))===0?
            
            <ListItem>            

            <Icon className={classes.icon}>picture_as_pdf</Icon>
                <a 
                rel="noopener noreferrer"
                target="_Blank"
                style={{
                color: "#36434b",
                fontSize: "14px",
                textAlign: "left",
                fontWeight: "600",
                fontFamily: "Raleway",
                padding: "0 16px"
              }} href={'/pdfReader?url='+data.path}>
            {data.name}   
              </a>
              </ListItem>
              : null

      
        );
      });

      videoData =
       venuevideodata &&
       venuevideodata.map(function(data, idx) {

        return (
            parseInt(localStorage.getItem('type'))===0?
            
            <ListItem>
            

            <Icon className={classes.icon}>link</Icon>
               {data.name&& <a 
                rel="noopener noreferrer"
                target="_Blank"
                style={{
                color: "#36434b",
                fontSize: "14px",
                textAlign: "left",
                fontWeight: "600",
                fontFamily: "Raleway",
                padding: "0 16px"
              }} href={data.path}>
            {data.name}   
              </a>}
              </ListItem>
              : null

      
        );
      });
  }
  return (
  
    <Grid xs={12} item className="contackInfoDiv">
      <Grid xs={12} item className="managerInfoDiv contact_button">
        <div className="managerDiv">
          <Card className="managerInfo">

            <CardHeader
              classes={{
                title: classes.title,
                subheader: classes.subheader
              }}
              avatar={
                <Avatar aria-label="Recipe">
                  <img alt='' src={props.venueProfile.contacts[0].profile_image ? props.venueProfile.contacts[0].profile_image.path : 
                    props.venueProfile.manager &&
                    props.venueProfile.manager.profilePhoto ? props.venueProfile.manager &&
                    props.venueProfile.manager.profilePhoto && props.venueProfile.manager.profilePhoto.path : logo} />
                </Avatar>
              }
              action={<ContactPopup message={props.message} sendData={props.sendData} contactClick={props.contactClick} label="Contact" className="contactBtn" calendarEnabled={props.calendarEnabled}/>}
              title={
                props.venueProfile.contacts &&
                props.venueProfile.contacts[0].job_title
              }
              subheader={
                props.venueProfile.contacts &&
                props.venueProfile.contacts[0].name
              }
            />
          </Card>
        </div>
      </Grid>
      <Grid xs={12} item className="managerInfoDiv">
        <Grid className="managerDiv">
          <div className="listDiv">
            <List className="ulDiv">
              {eventData}
              {props.venueProfile.address && props.venueProfile.address.zip!=='' && props.venueProfile.address.airport!==null
            ?
            <ListItem>
                
            <Icon className={classes.icon}>flight</Icon>
            <p
              href=""
              style={{
                color: "#36434b",
                fontSize: "14px",
                textAlign: "left",
                fontWeight: "600",
                fontFamily: "Raleway",
                padding: "0 16px"
              }}
            >
            {props.venueProfile.address.airport}
                            </p>

          </ListItem>:null}
   
              {documentData}
              {videoData}
            </List>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}


ContactInfo.propTypes = {
  classes: object.isRequired
};

export default withStyles(styles)(ContactInfo);

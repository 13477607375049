import { Record } from 'immutable';

import {
  POST_GOOGLE_SIGNUP_REQUEST,
  POST_GOOGLE_SIGNUP_FAILURE,
  POST_GOOGLE_SIGNUP_SUCCESS,
  POST_GOOGLE_CALLBACK_SUCCESS,
  POST_GOOGLE_CALLBACK_REQUEST,
  POST_GOOGLE_CALLBACK_FAILURE
 
} from 'shared/state/types';

class State extends Record({
    microsoftData:{},
    microsoftUrl:{},
    sended:'',
    sending:'',
    error:''
  
}) {}



export default function reducer(state = new State(), action) {
  switch (action.type) {
    case POST_GOOGLE_SIGNUP_REQUEST:
      return state.merge({
        microsoftUrl:{},
       error:'',
  
        
      });
    case POST_GOOGLE_SIGNUP_FAILURE:
      return state.merge({
        microsoftUrl:{},
        error: action.error,
    
      });
    case POST_GOOGLE_SIGNUP_SUCCESS:
      return state.merge({
        error:'',
        microsoftUrl:action.urlDetails
 
      });

      case POST_GOOGLE_CALLBACK_REQUEST:
      return state.merge({
       microsoftData:{},
       error:'',
       sended:false,
       sending:true
        
      });
    case POST_GOOGLE_CALLBACK_FAILURE:
      return state.merge({
        microsoftData:{},
        error: action.error,
        sended:'error',
        sending:false
      });
    case POST_GOOGLE_CALLBACK_SUCCESS:
      return state.merge({
        error:'',
        microsoftData:action.details,
        sended:true,
        sending:false
 
      });
    default:
      return state;
  }
}


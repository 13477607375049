import React from 'react';
import { Grid } from '@material-ui/core';
import Footers from '../../landingPage/components/footer';
import './assets/style/blog.scss';
import { getBlogs } from '../../../src/data/venues/actions/getBlogs';
import { bindActionCreators, compose } from "redux";
import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment";
import {
    AppBar,
    Icon,
    Toolbar,
    IconButton,
    MenuItem,
    Menu,
    Button
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import ContactUs from 'CMS/locationContactPopup/container/locationContactPopup';

  const DialogContent = withStyles(theme => ({
    root: {
    },
  }))(MuiDialogContent);
 

function blogDetail(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setopen] = React.useState(false);
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose(event) {
        setAnchorEl(null);
    }

const handleContactPopup = () => {
    setopen(true);
  };
  const handleCloseontactPopup = () => {
    setopen(false);
  };
    const [blogs, setblogs] = React.useState({});
    const [next, setnext] = React.useState({});
    const [prev, setprev] = React.useState({});
    let key='blogDetail'

    React.useEffect(() => {
        const queryParams = props.match.params.blog_id 
        props.getBlogs(queryParams ? queryParams : '',key).then((result) => {
            if ('data' in result) {

                setblogs(result.data[0]);
                setprev(result.data[0].prev);
                setnext(result.data[0].next);
            };

        })
    }, []);

    function handleNextBlog(){
        window.location.href = `/blog/${next.slug}`
    }

    function handlePrevBlog(){
        window.location.href = `/blog/${prev.slug}`
    }

    return (
        <div className="">
              <Helmet>
          <title>{blogs.title}</title>
          <meta name="title" content={blogs.meta_title}/>
          <meta name="description" content={blogs.meta_description}/>
          </Helmet>
            <div class="container top">
                <div class="header-title-nav-wrapper">
                    <div class="header-title-logo">
                        <a href="/planners" data-animation-role="header-element">
                            <img class="large" src={require('../assets/images/logo.png')} alt="Logo" />
                            <span> Blog</span>
                        </a>
                    </div>
                    <div class="header-nav">
                        <div class="header-nav-wrapper">
                            <div class="mobile-view-nav">
                               <div class="category-btn">Categories</div>
                                   <div class="category-btn-hover">
                                        <Link to={`/blogs/event-venue?category=Event Venue`}>
                                            Event Venue
                                        </Link>
                                        <Link to={`/blogs/weddings?category=Weddings`}>
                                            Weddings
                                        </Link>
                                        <Link to={`/blogs/corporate-events?category=Corporate Events`}>
                                            Corporate Events
                                        </Link>
                                   </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="main-wrapper blog-detail-page">
                    <div class="main-heading">
                        <h1 style={{whiteSpace: "pre-wrap"}}>{blogs.title}</h1>

                    </div>
                    <div class="block-details last-block">
                        {ReactHtmlParser(blogs.description)}

                    </div>
                    <div class="block-details light-blue">
                        <p>Planning your perfect event begins with picking the right venue and date.
                        </p>
                    </div>
                    <div class="text-center">
                        <Link to="/planners">
                        <div className='btn-inline-block'>
                        <button type="button" class="btn">Search venues Now</button>
                        </div>
                        </Link>
                          <div className="btn-inline-block">
                             <Button type="button" class="btn"
                                //  variant="outline-danger"
                                 onClick={handleContactPopup}>
                                 Contact Us
                                </Button>
                                <Dialog className="contact_modal_container contact_popup"
                                 aria-labelledby="customized-dialog-title"
                                 open={open}>

                                 <DialogActions className="dialogActions">
                                     <span className="material-icons close closeButton" onClick={handleCloseontactPopup}>close</span>
                                 </DialogActions>
                                    <DialogContent className="dialog-content">
                                          <ContactUs />
                                       </DialogContent>
                                </Dialog>
                         </div>
                    </div>
                </div>
                <Grid container className="next-heading">
                    <Grid className="btn-block1" item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {prev != null && <Button onClick={handlePrevBlog} style={{whiteSpace: "pre-wrap"}}>{prev.title} </Button>}
                    </Grid>
                    <Grid className="btn-block2" item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {next != null && <Button onClick={handleNextBlog} style={{whiteSpace: "pre-wrap"}}>{next.title}</Button>}
                    </Grid>
                </Grid>
            </div>
            <Footers />
        </div>
    );
}

const mapStateToProps = state => {
    return {
    };
};

const enhance = compose(
    withRouter,
    connect(mapStateToProps, dispatch =>
        bindActionCreators(
            {

                getBlogs

            },
            dispatch
        )
    )
);
export default enhance(withRouter(blogDetail));

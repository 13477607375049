import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {  IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import "./assets/style/placeHold.scss";
// import SettingsIcon from '@material-ui/icons/Settings';
import { object } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';


const styles = theme => ({
root: {
margin: 0,
},
closeButton: {
position: 'absolute',
top: '8px',
color: '#9e9e9e',
right: '8px',
},
});

withStyles(styles)(props => {
const { children, classes, onClose } = props;
return (
<MuiDialogTitle disableTypography className={classes.root}>
<Typography variant="h6">{children}</Typography>
{onClose ? (
<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
<CloseIcon />
</IconButton>
) : null}
</MuiDialogTitle>
);
});

const DialogContent = withStyles(theme => ({
root: {
},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
root: {
},
}))(MuiDialogActions);

class ErrorList extends React.Component {
state = {
open: this.props.openError,
venueId:this.props.name==='spaceEdit'?this.props.venueId:this.props.match.params.venueId
};


handleClose = () => {
  this.props.errorClose();
  this.setState({open:false})
};

render() {

return (
<div className="h">


<Dialog 

className="success_modal_container hold_settings forgot-something-popup"
aria-labelledby="customized-dialog-title"
open={this.state.open} >
<DialogActions className="dialogActions">
<span
className="material-icons close closeButton"
onClick={this.handleClose}
>
close
</span>
</DialogActions>
<DialogContent className="dialog-content">
<Grid container className="modalBox_content">
<Grid item xs={12}>
      {(localStorage.getItem('plan_id') !== null && localStorage.getItem('plan_id') !== null) || (this.props.saveExit=='saveExit' && this.props.errorLists.length > 0) ?
        <Grid container className="venue-publish-popup-box" >        
              <div className="dont_single" >Oops. You forgot to complete something</div>
              <br />
              <p><b>Check:</b></p>
        </Grid>
        :
        <Grid container className="venue-publish-popup-box" >
              <div className="dont_single" >Select from flexible plans</div>
              <br />
              <div className="set-up-paid-link-container">
              <div className="approval-btn">
                <Button href={`/venuePlan/${this.state.venueId}`}className="venue-link" class='red-button'>
                Show me!                        
                </Button>
           </div>
              </div>
              <a href = "mailto:hello@first-hold.com">Have questions?</a>
        </Grid>
      }
      { this.props.errorLists.length > 0 ?
      <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
          <Grid container justify="flex-start" className="similarDiv what-we-love-block">
                    <div className="what-we-love-listing">
                        <ul>
                        {this.props.errorLists[0]?<li>{this.props.errorLists[0]}</li>:null}
                        {this.props.errorLists[2]?<li>{this.props.errorLists[2]}</li>:null}
                        {/* </ul>
                        
                        <ul className="right-list"> */}
                        {this.props.errorLists[1]?<li>{this.props.errorLists[1]}</li>:null}
                        {this.props.errorLists[3]?<li>{this.props.errorLists[3]}</li>:null}
                        </ul>
                    </div>
          </Grid>
    </Grid>
    :null}

</Grid>



</Grid>

</DialogContent>


</Dialog>

</div>
);
}
}

ErrorList.propTypes = {
classes: object.isRequired,
};

const mapStateToProps = state => {
return {

};
};

const enhance = compose(
withRouter,
connect(mapStateToProps,dispatch =>
bindActionCreators(
{

},
dispatch
)
)
);
export default enhance(ErrorList);


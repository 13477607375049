import React from 'react';
import PropTypes from 'prop-types';

import CustomInput from '../../shared/components/CustomInput';
import CustomizedButton from '../../shared/components/customizedButton';

import { Formik,Field } from 'formik';
import { Grid, Hidden,Icon,Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    icon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    root: {
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    typography: {
        width: '237px',
        height: '120px',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        fontFamily: 'Raleway'
    }
});

function BasicInfo(props) {
    // const { classes } = props;
    return (
        <div>
            <Grid container className="edit_profile_component">
                <Grid className="headings" item xs={12}>
                    <h4 className="form_name">Update Email</h4>
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={props.initialValues}
                        enableReinitialize
                        validationSchema={props.validationSchema}
                        onSubmit={(values, actions) => {
                            props.handleSubmit(values, actions)
                        }}
                        render={props => (
                            <form onSubmit={props.handleSubmit}>
                                <Grid container>
                                    <Hidden xsDown={true}>
                                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        { props.values.isEmailBlank==false &&
                                            <Grid container className="edit_profile_component_form">
                                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <div className="field_div">
                                                        <CustomInput disabled label="Current Email" type="email" name="currentEmail" placeholder="Current Email" />
                                                        {  props.values.emailVerfication ? 
                                                            <div className="verified" >
                                                                <Icon>check_circle</Icon> <p>Verified</p>
                                                            </div> 
                                                            :
                                                            <div className="verify-it" >
                                                                   <Icon>cancel_circle</Icon> <p>Not Verified</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid className="right_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <div className="field_div">
                                                        <CustomInput label="New Email" type="email" name="newEmail" placeholder="New Email" />
                                                    </div>
                                                </Grid>                                          
                                            </Grid>
                                        }
                                        { props.values.isEmailBlank==true &&
                                            <Grid container className="edit_profile_component_form">
                                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <div className="field_div">                    
                                                        <CustomInput  label="Email" type="email" name="newEmail" placeholder="Email" />
                                                        {  props.values.emailVerfication? 
                                                            <div className="verified" >
                                                                <Icon>check_circle</Icon> <p>Verified</p>
                                                            </div>
                                                            :
                                                            <div className="verify-it" >
                                                                   <Icon>cancel_circle</Icon> <p>Not Verified</p>
                                                            </div>
                                                        }  
                                                    </div>
                                                </Grid>                          
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid className="save_button" item xs={12}>
                                        <Grid container justify='flex-end'>
                                            <Grid item>
                                                <CustomizedButton label="Save" type="submit" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Hidden xsDown={true}>
                                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                    </Hidden>
                                </Grid>
                            </form>
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

BasicInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

const enhance = withStyles(styles)

export default enhance(BasicInfo);

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

import './assets/style/ModalBox.scss';

const closeButton = {
  position: 'absolute',
  top: '8px',
  right: '8px',
  color: 'black',
  right: '8px',
  with: 'fit-content',
  cursor: 'pointer',
  fontWeight: 700
}


const DialogContent = withStyles(theme => ({
  root: {
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
  },
}))(MuiDialogActions);

class ModalBox extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  render() {

    return (
      <div>
        <div className="material-icons help-button" onClick={this.handleClickOpen}>help</div>
        <Dialog className="success_modal_container dont-miss-a success_modal_container_small"
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}>

          <DialogContent className="dialog-content sign-up1" >
            <CloseIcon style={closeButton} onClick={this.handleClose} />
            <h5 className='dialouge_heading'>Users</h5>
            <p className='dialouge_text'>Are your venue managers and <br /> internal staff entered as <br />Users in <b>Tripleseat?</b> </p>
            <p>If so, check this box.</p>
          </DialogContent>
        </Dialog>


      </div>
    );
  }
}

export default ModalBox;

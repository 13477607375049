import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSpace } from 'data/spaces/actions';
import { getVenue } from 'data/venues/actions';

import { Grid, Button } from '@material-ui/core';

import './assets/styles/spaceComplete.scss';

class SpaceComplete extends Component {
  UNSAFE_componentWillMount() {
    const id = this.props.match.params.spaceId
    this.props.getSpace(id).then((response) => {
      if (response.venue_id) {
        this.props.getVenue(response.venue_id)
      }
    })
  }

  render() {
    return (
      <div>
        <Grid container className="space-complete">
          <Grid xs={12} item>
            <Grid container className="mask">

              <Grid item md={9} xs={12} sm={9} xl={9} lg={3}>
              </Grid>

              <Grid item md={9} xs={12} sm={9} xl={9} lg={6} className="venueCompleteContent">

                <img className="check_circle---material" src={require('./assets/images/green-circle-tick.png')} alt="Space Complete" />

                <h2 className="title">{this.props.venueDetails.code}</h2>
                <h2 className="sub-title">{this.props.spaceData.name}</h2>

                <p className="thankYouText">
                  Thank you for taking the time to complete your space profile. You can view the space profile and edit the details and features of the space by clicking “customize space profile.” Keep in mind that spaces within a venue under review will not go live until the venue is approved.
              </p>

                <Button variant="outlined" color="secondary" className="button-bg" component={Link} to={`/spaceProfileEdit/${this.props.spaceData.id}`}>
                  <span className="button-text">Customize Space Profile</span>
                </Button>
              </Grid>

              <Grid item md={9} xs={12} sm={9} xl={9} lg={3}>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spaceData: state.data.spaces.spaceData,
    venueDetails: state.data.venues.venueDetails
  };
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    getSpace,
    getVenue
  }, dispatch),
)(withRouter(SpaceComplete));


import { GET_USER } from 'shared/state/types';
import { requestForUnauthorised } from 'shared/auth';

function getUserRequested() {
  return {
    type: GET_USER.REQUEST,
  };
}

function getUserFailed(error) {
  return {
    type: GET_USER.FAILURE,
    error:error,
  };
}

function getUserSuccess(data) {
  return {
    type: GET_USER.SUCCESS,
    payload: data,
  };
}

export function getUser(params) {
  return dispatch => {
    dispatch(getUserRequested());
    return requestForUnauthorised().get('/people/me', params)
      .then(response =>{
          dispatch(getUserSuccess(response.data.data))
        }
      )
      .catch(errors => {
        if(errors && errors.response){
          dispatch(getUserFailed(errors.response.data.errors));
        }
        else{
          dispatch(getUserFailed("Unable to access api"));
        }
        throw errors;
      });
  };
}

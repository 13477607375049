import React from 'react';
import {string} from 'prop-types';

import { Datepicker } from 'react-formik-ui'

import './assets/style/DatePicker.scss'

function DatePicker(props) {

  return (
    <Datepicker readonly="true"
        className={props.theme === 'blue' ? 'blue_theme_date_picker' : 'black_theme_date_picker'}
        name={props.name}
        placeholder={props.placeholder}
        dateFormat={props.dateFormat}
        minDate={props.minDate}
    />
  );
}

DatePicker.propTypes = {
    name: string.isRequired,
    placeholder: string.isRequired,
    theme: string.isRequired
};

export default DatePicker;

import {COUPON_CODE} from 'shared/state/types';
import {request} from 'shared/auth';

function onRequested() {
    return {type: COUPON_CODE.REQUEST};
}

function onFailed(error) {
    return {type: COUPON_CODE.FAILURE, error: error};
}

function onSuccess(data) {
    return {type: COUPON_CODE.SUCCESS, payload: data};
}

export function applyCouponApi(params) {
    return dispatch => {
        dispatch(onRequested());
        return request()
            .get(`promo-code/check`,{ params })
            .then(response => {
                dispatch(onSuccess(response.data.data))
                return response.data.data;
            })
            .catch(errors => {
                if (errors.response) {
                    dispatch(onFailed(errors.response.data.message));
                } else {
                    dispatch(onFailed('Not able to access api'));
                }
                throw errors;
            });
    };
}

import React from 'react';

import { Field } from 'formik';

import './assets/style/CustomCheckBox.scss';

function CustomCheckBox(props) {
    return (
        <div style={props.marginTop ? {marginTop:props.marginTop} :props.title==='venueProfile'?{display:'inline-block'}:null} className="custom_checkbox">
            <label className={((props.checkboxGroup && (props.checkboxGroup.includes(props.name))) || props.value) ? 'blue_text_container' : props.disabled ? 'disabled_day container' : 'container'}>{props.label}
                <Field type="checkbox"
                    name={props.name}
                    disabled={props.disabled}
                    checked={(props.checkboxAndCheckboxGroup? props.value : props.checkboxGroup? props.checkboxGroup.includes(props.name): false )}
                    
                    onChange={e => {
                        if(props.onRentalChange){
                            props.onRentalChange(props.name,props.setFieldValue)       
                        }
                        if (e.target.checked) {
                            props.checkboxAndCheckboxGroup ?
                            props.arrayHelpers.push(props.checkboxGroupValue)
                            :
                            props.arrayHelpers.push(props.name)
                            if(props.onDayChange){
                                props.onDayChange(props.checkboxGroupValue,'add')
                            }
                            
                        }
                        else {
                            let index
                            if(props.checkboxAndCheckboxGroup){
                                index = props.checkboxGroup.indexOf(props.checkboxGroupValue)
                            }
                            else{
                                index = props.checkboxGroup.indexOf(props.name)
                            }
                            props.arrayHelpers.remove(index)
                            if (props.eventArray) {
                                const eventArray = props.eventArray
                                eventArray.map((data, idx) => {
                                    const deletedEvent = eventArray.find(obj => {
                                        return obj.eventName === props.name && obj.id === ''
                                    })
                                    // const deletedEvent = data.eventName === props.name ? data : undefined
                                    if(deletedEvent !== undefined){
                                        // const deletedEventId = deletedEvent.id ? deletedEvent.id : '';
                                        const deletedEventIndex = eventArray.map(e => e.seqNo).indexOf(deletedEvent.seqNo);
                                        // if(deletedEventId === '' ){
                                            // const idx = eventArray.indexOf(data.eventName == props.name)
                                            if(deletedEventIndex >= 0){
                                                eventArray.splice(deletedEventIndex, 1);
                                                props.setFieldValue(props.eventArray, eventArray)
                                            }
                                        // }
                                    }
                                   return '';
                                })
                                props.setFieldValue('pricingCategory',[['','']])
                                props.setFieldValue('pricingCategoryError',[['','']])

                            }
                            if(props.onDayChange){
                                props.onDayChange(props.checkboxGroupValue,'remove')
                            }
                        }
                        if(props.onClick){
                            props.onClick();
                        }
                        props.handleChange(e)


                    }
                    }
                />
                <span className={props.disabled && !props.name && !props.value ? 'disabled_checkmark' : 'checkmark'}></span>
            </label>
        </div>
    );
}


export default CustomCheckBox;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';


// import CloseIcon from '@material-ui/icons/Close';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import "../../shared/components/assets/style/placeHold.scss"
// import SettingsIcon from '@material-ui/icons/Settings';
import { object } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
const logo = require("../../shared/components/assets/images/user-default.png");

// const styles = theme => ({
// root: {
// margin: 0,
// },
// closeButton: {
// position: 'absolute',
// top: '8px',
// color: '#9e9e9e',
// right: '8px',
// },
// });


const DialogContent = withStyles(theme => ({
root: {
},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
root: {
},
}))(MuiDialogActions);

class PlannerPopup extends React.Component {
state = {
open: this.props.open,
};


handleClose = () => {
  this.props.close();
  this.setState({open:false})
};

render() {
let data=this.props.eventData.state ? this.props.eventData.state:'';
let error='';
if(data.includes('}')){
data=JSON.parse(data);
}
else{
  error="error";
}
return (
<div className="h">


<Dialog 
className="success_modal_container hold_settings forgot-something-popup"
aria-labelledby="customized-dialog-title"
open={this.state.open} style={{width:"110%"}}>
<DialogActions className="dialogActions">
<span
className="material-icons close closeButton"
onClick={this.handleClose}
>
close
</span>
</DialogActions>
<DialogContent className="dialog-content" style={{width:'100%'}}>
<Grid container className="modalBox_content" style={{padding:'30px'}}>
<Grid item xs={12}>
       
        {parseInt(this.props.eventPlanner.id)!==parseInt(localStorage.getItem('id'))
          ?
        this.props.eventPlanner.profilePhoto?
        <div style={{marginTop:'3%',float:'left',width:'35%',display:'inline-block'}}>

                <img 
                    src={this.props.eventPlanner.profilePhoto.path}
                    alt="FIRST-HOLD"
                    height="60"
                    width="60"
                />        </div>

                :
                '' : ''}



    <div style={this.props.eventPlanner.profilePhoto?{textAlign:"left",width:parseInt(this.props.eventPlanner.id)!==parseInt(localStorage.getItem('id'))?'65%':'100%',display:'inline-block',padding:'0px  10px 5px'}:{textAlign:"left",width:'100%',display:'inline-block',padding:'0px  20px 5px'}} container className="venue-publish-popup-box planner-profile-popup" >
            <div className="dont_single" style={{textAlign: 'center !important',textTransform:'none'}}>
            {data.planner_name && error!=='error' && parseInt(this.props.eventPlanner.id)===parseInt(localStorage.getItem('id'))
          ?
        <p> {data.planner_name}</p>
        :
        <p> {data.planner_name ? data.planner_name : this.props.eventPlanner.first_name+" "+this.props.eventPlanner.last_name}</p>

        }

{data.planner_name && error!=='error' && parseInt(this.props.eventPlanner.id)===parseInt(localStorage.getItem('id'))
?
<p style={{fontSize:'10px'}}>{data.company_name}</p>

:
<p style={{fontSize:'10px'}}>{data.company_name ? data.company_name : this.props.eventPlanner.company_name}</p>

}

            </div>
            <br />
            {data.sales_rep_name && error!=='error' && parseInt(this.props.eventPlanner.id)===parseInt(localStorage.getItem('id'))
              ?
              <p style={{fontSize:'14px'}}>Sales: {data.sales_rep_name ? data.sales_rep_name : ' '} </p>

              :
              <div style={{fontSize:'14px'}}>
            <p>Job Title: {this.props.eventPlanner.job_title==null ?  'None' : this.props.eventPlanner.job_title}</p>
            <p>Address: {this.props.eventPlanner.company_address.line_1} {this.props.eventPlanner.company_address.line_2}, {this.props.eventPlanner.company_address.city} {this.props.eventPlanner.company_address.country}, {this.props.eventPlanner.company_address.state_abbr}</p>
            </div>
            }
            <p style={{fontSize:'14px'}}>Phone:&nbsp;
            {error!=='error' && parseInt(this.props.eventPlanner.id)===parseInt(localStorage.getItem('id'))
              ?
              " "+data.planner_phone && data.planner_phone!=null ? data.planner_phone :'N/A'
              :
              " "+data && data.planner_phone ? data.planner_phone : this.props.eventPlanner.phone_number}</p>
            <p style={{fontSize:'14px'}}>Email:&nbsp;
            {error!=='error' && parseInt(this.props.eventPlanner.id)===parseInt(localStorage.getItem('id'))
              ?
              "  "+data.planner_email && data.planner_email!=null ? data.planner_email : 'N/A'
              :
              "  "+data && data.planner_email ? data.planner_email : this.props.eventPlanner.email}</p>

  
      </div>
      <div class="img-logo">
                      <img src={require('../../shared/components/assets/images/LogoWithText.png')} alt="FIRST-HOLD" title="My Events"  />
                      </div>

               

</Grid>



</Grid>

</DialogContent>


</Dialog>

</div>
);
}
}

PlannerPopup.propTypes = {
classes: object.isRequired,
};

const mapStateToProps = state => {
return {

};
};

const enhance = compose(
withRouter,
connect(mapStateToProps,dispatch =>
bindActionCreators(
{

},
dispatch
)
)
);
export default enhance(PlannerPopup);


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout } from 'shared/auth/actions';

class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  };
  render() {   
    const widgetPath=localStorage.getItem('widget_path');  
    this.props.logout()
    if(widgetPath){
      this.props.history.push(widgetPath);
    }else{
      this.props.history.push('./login')
    } 
    return <div />;
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    logout,
  }, dispatch),
)(Logout);

import React from 'react';
import {
  Link
} from "react-router-dom";

import {
  Button,
  Icon,
  Grid
} from '@material-ui/core';
import {
  withStyles
} from '@material-ui/core/styles';

import {
  useFormikContext,
  Field
} from 'formik';
import PropTypes from 'prop-types';

import '../../assets/styles/auth.scss';

const styles = theme => ({

      signupForm: {
        margin: '30px auto',
        width: '430px',
        borderRadius: '6px',
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0))'
      },
      formFields: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '89%'
      },
      already: {
        fontSize: '12px',
        color: '#ffffff',
        fontWeight: 500,
        marginTop: '20px',
        paddingBottom: '20px',
        marginBottom: '83px',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal'
      },
      signinLink: {
        color: '#ffffff',
        fontWeight: 'bold',
        "&:hover": {
          color: '#ffffff',
          textDecoration: 'underline',
        }
      },
        icon: {
          fontSize: '20px',
          marginBottom: '18px',
          color: '#ee2738;'
        }
      });

    const reply_click = (userType, setFieldValue, handleSubmit, values) => {
      if (userType === 'planner') {
        setFieldValue('user_type', 0)
        localStorage.setItem('user_type', 0);
      } else if (userType === 'manager') {
        setFieldValue('user_type', 1)
        localStorage.setItem('user_type', 1);
      }
    }

    function TypeInfo(props) {

      const {
        classes
      } = props;
      const {
        setFieldValue,
        values,
        handleSubmit
      } = useFormikContext()
      return ( <
        div className = "signup" >
        <
        Grid className = "signup__inner"
        container justify = "center" >
        <
        Grid item xs = {
          10
        }
        md = {
          4
        }
        lg = {
          4
        }
        sm = {
          6
        } >
        <
        div className = {
          `${classes.signupForm} type-info-form`
        } >
        <
        div className = "welcome__text" > Welcome < /div> <
        div className = "info__text" > Which of the following describes you ? < /div> <
        div className = "text__center" >
        <
        div className = {
          classes.formFields
        } >
        <
        Grid container justify = "center"
        className = "rectangle__container" >
        <
        Field type = "hidden"
        name = "user_type"
        value = "0" / >
        <
        Button id = "planner"
        className = "rectangle rectangle__button_hover"
        variant = "outlined"
        disableRipple = {
          true
        }
        spacing = {
          1
        }
        type = "submit"
        onClick = {
          e => reply_click('planner', setFieldValue, handleSubmit, values)
        } >
        <
        span > < /span> <
        Icon className = "icon1" > location_city < /Icon> <
        span className = "user__type__text" > I am a planner looking
        for venues. < /span> <
        /Button>

        <
        Button id = "manager"
        className = "rectangle rectangle__button_hover"
        variant = "outlined"
        disableRipple = {
          true
        }
        type = "submit"
        onClick = {
          e => reply_click('manager', setFieldValue, handleSubmit, values)
        } >
        <
        Icon className = "icon2" > group < /Icon> <
        span className = "user__type__text" > I am a venue manager looking to generate leads. < /span> <
        /Button> <
        /Grid> <
        /div> <
        /div> <
        /div> <
        div className = {
          classes.already
        }
        align = "center" > Already have a FIRST - HOLD Account ? < Link className = {
          classes.signinLink
        }
        to = '/login' > Sign In < /Link>.</div >
        <
        /Grid> <
        /Grid> <
        /div>
      );
    }

    TypeInfo.propTypes = {
      classes: PropTypes.object.isRequired,
    };

    export default withStyles(styles)(TypeInfo);

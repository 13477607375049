import BaseModel from 'data/BaseModel';

class Person extends BaseModel({
  id: 0,
  email: null,
  first_name: null,
  last_name: null,
}) {

}

export default Person;

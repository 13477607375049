import React from 'react';

import { Field, ErrorMessage } from 'formik';
import MaskedInput from "react-text-mask";
import { Icon, Link } from '@material-ui/core';

import './assets/style/CustomInput.scss';


function CustomInput(props) {
    const phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    return (
        <div className={props.upperCaseInput ? 'upper_case_input custom_input' : 'custom_input'}>
            <label>
                {props.label}
            </label>
            {props.type !== 'phone' ?
                props.passwordField ?

                    <>
                        <Field style={props.st==='basicinfo' ? {border:"solid 0px #e1e1e1"} : props.marginBottom?{'margin-bottom':'10px'}:null} type={props.type} name={props.name} placeholder={props.placeholder} />
                        <Link onClick={(e)=>{props.changePasswordFieldType(e)}}>
                            <Icon style={props.st==='basicinfo'?{bottom:"7px"}:null} 
                      className={props.type==="text"?"eye_icon_disable":"eye_icon"}>remove_red_eye</Icon>
                        </Link>
                    </>
                    :
                    props.onChange  ?
                    <Field maxLength={props.maxLength?props.maxLength:''} onChange={props.onChange?e => {
                        props.onChange(e)
                        props.handleChange.handleChange(e)}:''} style={props.marginBottom?{'margin-bottom':'10px'}:null} type={props.type} name={props.name} placeholder={props.placeholder} disabled={props.disabled} />
                        :
                    <Field maxLength={props.maxLength?props.maxLength:''} style={props.marginBottom?{'margin-bottom':'10px'}:null} type={props.type} name={props.name} placeholder={props.placeholder} disabled={props.disabled} />
                :
                <Field  style={props.marginBottom?{'margin-bottom':'10px'}:null} name={props.name}
                    render={({ field }) => (
                        <MaskedInput
                            {...field}
                            mask={phoneNumberMask}
                            placeholder={props.placeholder}
                            type={props.type}

                        />
                    )}

                />
            }


            <div className="error"><ErrorMessage name={props.name} /></div>
        </div>
    );
}


export default CustomInput;

import React from 'react';
import PropTypes from 'prop-types';

import CustomCheckBox from '../../../shared/components/CustomCheckBox';
import SpaceArrangement from './spaceArrangement.js';

import { Grid, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FieldArray, ErrorMessage } from 'formik';

import '../assets/styles/addSpace.scss';

const styles = theme => ({
    icon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    root: {
        boxShadow: '0px 9px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    typography: {
        width: '237px',
        height: '120px',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1'
    }
});

function AddSpaceCapacity(props) {
    const leftPanel = (arrayHelpers) => {
        return props.formikProps.values.arrangements.map((arrangement, index) => {
            return (
                <div className="checkbox_div">
                    <CustomCheckBox name={`arrangements[${index}].enable`} value={props.formikProps.values.arrangements[`${index}`].enable} label={arrangement.name} checkboxGroupValue={arrangement.name} arrayHelpers={arrayHelpers} handleChange={props.formikProps.handleChange} checkboxGroup={props.formikProps.values.checkboxGroup} checkboxAndCheckboxGroup/>
                    {props.formikProps.values.arrangements[`${index}`].enable &&
                        <Grid className="space_arrangement_container" container spacing={40}>
                            {arrangement.name !== 'Empty' &&
                            <Grid className="capacity_info" item xs={6} sm={6} md={4} lg={3} xl={3}>
                                <SpaceArrangement minName={`arrangements[${index}].min`} min />
                            </Grid>
                            }
                            <Grid className="capacity_info" item xs={6} sm={6} md={4} lg={3} xl={3}>
                                <SpaceArrangement maxName={`arrangements[${index}].max`} max />
                            </Grid>
                            <Hidden smDown={true}>
                                <Grid item xs={6} sm={6} md={4} lg={6} xl={6}></Grid>
                            </Hidden>
                        </Grid>
                    }
                </div>
            )
        })
    }

    const right_panel =  props.formikProps.values.arrangements.map((arrangement, index) => {
            return (
                props.formikProps.values.arrangements[`${index}`].enable &&
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Grid container className="arrangement-desc">
                            <Grid item xs={12} className="text-on-arrangement-image">{arrangement.name}</Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center">
                                    {  arrangement.name === 'Sit-Down' ?
                                    <img className="arrangements_image" src={require('../assets/images/sitdown.png')} alt={arrangement.name} />
                                    :arrangement.name === 'Classroom' ?
                                    <img className="arrangements_image" src={require('../assets/images/classroom.png')} alt={arrangement.name} />
                                    :arrangement.name === 'Boardroom'?
                                    <img className="arrangements_image" src={require('../assets/images/boardroom.png')} alt={arrangement.name} />
                                    :arrangement.name === 'Theater'?
                                    <img className="arrangements_image" src={require('../assets/images/theater.png')} alt={arrangement.name} />
                                    :arrangement.name === 'Reception'?
                                    <img className="arrangements_image" src={require('../assets/images/reception.png')} alt={arrangement.name} />
                                    :arrangement.name === 'Tradeshow'?
                                    <img className="arrangements_image" src={require('../assets/images/tradeshow.png')} alt={arrangement.name} />
                                    :arrangement.name === 'Pre-Existing Arrangement'?
                                    <img className="arrangements_image" src={require('../assets/images/pre-arranged.png')} alt={arrangement.name} />
                                    :arrangement.name === 'Empty'?
                                    <img className="arrangements_image" src={require('../assets/images/empty.png')} alt={arrangement.name} />
                                    :arrangement.name === 'Wedding'?
                                    <img className="arrangements_image" src={require('../assets/images/Wedding.png')} alt={arrangement.name} />
                                    :null
                                    }
                                </Grid>
                                <div></div>
                            </Grid>
                            {arrangement.name !== 'Empty' ?
                                arrangement.min && arrangement.max &&
                                <Grid xs={12} item className="capacity-on-arrangement-image">
                                    {arrangement.min}-{arrangement.max}
                                </Grid>
                                :
                                arrangement.max &&
                                <Grid xs={12} item className="capacity-on-arrangement-image">
                                    {arrangement.max} {arrangement.name==='Empty'?' Max':''}
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                
            )
    })
    return (
        <div className="add_space_capacity_container">
            <Grid container className="add_space_capacity">
                <Grid className="space_name_container" item xs={12}>
                <div className="space_name"><span className="upper_case">{props.venueName}</span> - <span className="capitalise">{props.spaceName}</span></div>
                    <div className="capacity">Capacity</div>
                    <div className="instructions">{(!props.values.sitdown && !props.values.classroom && !props.values.boardroom && !props.values.theater && !props.values.reception && !props.values.tradeshow && !props.values.preexisting && !props.values.empty) ? '(check all that apply)' : 'Tell planners the most comfortable arrangements and capacities for this space.'}</div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid className="left_panel left_panel-pos" item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div className="how_can">How can this space be arranged?</div>
                            <div className="error"><ErrorMessage name="checkboxGroup" /></div>
                            {/*<div className="covid-text-right">
                                <h4>COVID-19 UPDATE</h4>
                                <p>“Ample Space” capacity should reflect your social distancing recommendation</p>
                                 </div>*/}
                            <FieldArray
                                name="checkboxGroup"
                                render={arrayHelpers => (
                                    <div className="leftPanel-left">
                                        {leftPanel(arrayHelpers)}
                                    </div>
                                )}
                            />
                        </Grid>
                        <Grid className="right_panel" item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div className="venue_and_space_name"><span className="venue_code">{props.venueName}</span> - <span className="space_name_heading">{props.spaceData.name}</span></div>
                            <Grid container spacing={24}>
                                {right_panel}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

AddSpaceCapacity.propTypes = {
    classes: PropTypes.object.isRequired,
};

const enhance = withStyles(styles)

export default enhance(AddSpaceCapacity);

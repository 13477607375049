import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
// import { withStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import '../assets/styles/myFavourites.scss';
import { favourites } from "../../shared/favourites/actions";
import VenueCardWithFav from '../../shared/components/VenueCardWithFav';

class MyFavourites extends Component {

  state={
    List:[],
    callAPI:''
  }
  UNSAFE_componentWillMount() {
    this.props.favourites().then((list) => {
      this.setState({callAPI:'calling'})
      let arr=[];
      const defaultVenueImage = require("../../venueProfile/assets/images/default_venue.png");
      
      list.favourites.data.map((favourite, idx) => {
        let obj={};
        if(favourite.favourable!==null){
          const favourable = favourite.favourable
          let profilePhoto = null
          // let type = 1
          let link = null
  
          if (favourite.favourableType === 'App\\Venue') {       
            // type = 1
            profilePhoto = (favourable.profilePhoto) ? favourable.profilePhoto.path : null
            link = "/venue-profile/" + favourable.slug
          } else {
            // type = 0;
            profilePhoto = (favourable.photo && favourable.photo.length) ? favourable.photo[0].path : null
            link = "/venue-profile/" + favourable.venue_id + "/space/" + favourable.id
          }
          
          let image = (profilePhoto) ? profilePhoto : defaultVenueImage
          obj.id=favourable.id;
          obj.venueLat=favourable.address && favourable.address.geo_lat;
          obj.venueLong=favourable.address && favourable.address.geo_lng;
          obj.image=image;
          obj.favouriteId=favourite.id;
          if(favourable.address){
            obj.address=favourable.address && favourable.address.city + ", " + favourable.address.state_abbr;
          }
          else{
            if(favourable.venue){
              obj.address=favourable.venue.address && favourable.venue.address.city + ", " + favourable.venue.address.state_abbr;
              obj.vs_name=favourable.venue.name;
            }
            else{
              obj.vs_name="";

              obj.address='';
            }

          }
          
          obj.venueName=favourable.name;
          obj.venueCity=favourable.address && favourable.address.city;
          obj.link=link;
          obj.fav=true;
          arr.push(obj);
    
        }
        return arr;
       })
       if(this.state.List.length===0){
         this.setState({List:arr})
       }
    }).catch(error =>
      {}
    );


  };

removeFavourite=(id)=>{
let arr=[];
  this.state.List.map((data, idx) => {
    if(id!==data.favouriteId)
    {
    arr.push(data);
    }
    return arr;
    })

    this.setState({List:arr});
}

  render() {
    // const { list } = this.props;

    return (
      <div className="my_favourites" style={{paddingTop:'65px'}}>
        <Grid container className="edit_profile_component">
          <Grid className="headings mb-10" item xs={12}>
              <h4 className="form_name">My favorites</h4>
          </Grid>
{this.state.callAPI===''?
<Grid item xs={12}>
<center><h4>Loading...</h4></center>
</Grid>
:
       this.state.List ?
        this.state.List.length > 0 ?
          <Grid container justify="center" className="">
          {this.state.List.map((data,idx)=>{
            
            return(
              <div key={idx} className="similar_venue_card">
              <VenueCardWithFav 
                idx={data.id}
                addressColor="#1397e1" 
                removeFavourite={this.removeFavourite}
                type={data.type}
                width="330px"
                height="192px"
                venueLat={data.venueLat}
                venueLong={data.venueLong}
                image={data.image}
                favourite={data.fav}
                favouriteId={data.favouriteId}
                venueAddress={data.address}
                venueName={data.venueName}
                vs_name={data.vs_name}

                venueCity={data.venueCity}
                category={'general'}
                enableLink
                linkTo={data.link}
              />
            </div>
            )
          })
        }
          </Grid>
          :
          <Grid item xs={12}>
            <center><h4>No favorites found.</h4></center>
          </Grid>
      :
      ''
    
}
    
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    list: state.data.favourites.favourites
  };
};
const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        favourites
      },
      dispatch
    )
  )
);
export default enhance(MyFavourites);

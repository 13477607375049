import {GET_SPACE_HOLIDAY_RATE} from 'shared/state/types';
import {request} from 'shared/auth';

function onRequested() {
    return {type: GET_SPACE_HOLIDAY_RATE.REQUEST};
}

function onFailed(error) {
    return {type: GET_SPACE_HOLIDAY_RATE.FAILURE, error: error};
}

function onSuccess(data) {
    return {type: GET_SPACE_HOLIDAY_RATE.SUCCESS, spaceHolidayRateData: data};
}

export function getHolidayRate(spaceId) {
    return dispatch => {
        dispatch(onRequested());
        return request()
            .get(`/space_price_override?space_id=`+spaceId)
            .then(response => {
                if (response.status === 200) {
                    dispatch(onSuccess(response.data.data))
                    return response.data.data
                } else if (response.status === 400) {
                    dispatch(onFailed("The request was invalid."));
                } else if (response.status === 401) {
                    dispatch(onFailed("The request did not include an authentication token or the authentication token " +
                            "was expired."));
                } else if (response.status === 403) {
                    dispatch(onFailed("The client did not have permission to access the requested resource."));
                } else if (response.status === 404) {
                    dispatch(onFailed("The requested resource was not found."));
                } else if (response.status === 405) {
                    dispatch(onFailed("The HTTP method in the request was not supported by the resource."));
                } else if (response.status === 409) {
                    dispatch(onFailed("The request could not be completed due to a conflict. "));
                } else if (response.status === 500) {
                    dispatch(onFailed("The request was not completed due to an internal error on the server side."));
                } else if (response.status === 503) {
                    dispatch(onFailed("The server was unavailable."));
                } else {
                    dispatch(onFailed("The server was unavailable."));
                }
            })
            .catch(errors => {
                if (errors.response && errors.response.data && errors.response.data.errors) {
                    dispatch(onFailed(errors.response.data.errors));
                } else {
                    dispatch(onFailed('Unable to access api'));
                }
                throw errors;
            });
    };
}

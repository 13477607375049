import { Record } from 'immutable';

import {
  GET_HOLD_EVENT_REQUEST,
  GET_HOLD_EVENT_FAILURE,
  GET_HOLD_EVENT_SUCCESS,
  GET_UPDATE_EVENT_DETAILS_REQUEST,
  GET_UPDATE_EVENT_DETAILS_FAILURE,
  GET_UPDATE_EVENT_DETAILS_SUCCESS,
  GET_DELETE_REQUEST,
  GET_DELETE_FAILURE,
  GET_DELETE_SUCCESS,
} from 'shared/state/types';

class State extends Record({

  holdEvent: {},
  error: '',
  sending: '',
  sended: '',
  success: '',
  renderErrorPopUp: false,
  message: ""
}) { }


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_HOLD_EVENT_REQUEST:
      return state.merge({
        error: '',
        success: '',
        sending: true,
        sended: false,
        renderErrorPopUp: false,
      });
    case GET_HOLD_EVENT_FAILURE:
      return state.merge({
        holdEvent: {},
        error: action.error,
        sending: false,
        success: '',
        sended: false,
        renderErrorPopUp: true,
        message: action.message
      });
    case GET_HOLD_EVENT_SUCCESS:
      return state.merge({
        holdEvent: "",
        success: 'Success',
        error: '',
        sending: false,
        sended: true,
        renderErrorPopUp: false
      });
    case GET_UPDATE_EVENT_DETAILS_REQUEST:
      return state.merge({
        error: '',
        success: '',
        sending: true,
        sended: false
      });
    case GET_UPDATE_EVENT_DETAILS_FAILURE:
      return state.merge({
        holdEvent: {},
        error: action.error,
        sending: false,
        success: '',
        sended: false


      });
    case GET_UPDATE_EVENT_DETAILS_SUCCESS:
      return state.merge({
        holdEvent: "",
        success: 'Event updated successfully',
        error: '',
        sending: false,
        sended: true
      });
    case GET_DELETE_REQUEST:
      return state.merge({
        error: '',
        success: '',
        sending: true,
        sended: false
      });
    case GET_DELETE_FAILURE:
      return state.merge({
        holdEvent: {},
        error: action.error,
        sending: false,
        success: '',
        sended: false


      });
    case GET_DELETE_SUCCESS:
      return state.merge({
        holdEvent: "",
        success: 'Successfully Delete',
        error: '',
        sending: false,
        sended: true
      });
    default:
      return state;
  }
}


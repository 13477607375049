import { SPACE_DOCUMENT_UPLOAD } from 'shared/state/types';
import { request } from 'shared/auth';

function spaceDocumentUploadRequested() {
  return {
    type: SPACE_DOCUMENT_UPLOAD.REQUEST,
  };
}

function spaceDocumentUploadFailed(error) {
  return {
    type: SPACE_DOCUMENT_UPLOAD.FAILURE,
    error:error,
  };
}

function spaceDocumentUploadSuccess(data) {
  return {
    type: SPACE_DOCUMENT_UPLOAD.SUCCESS,
    payload: data,
  };
}

export function spaceDocumentUpload(params,id) {
  return dispatch => {
    dispatch(spaceDocumentUploadRequested());
    return request().post(`spaces/${id}/documents`, params)
      .then(response =>
        dispatch(spaceDocumentUploadSuccess(response.data.data))
      )
      .catch(errors => {
        dispatch(spaceDocumentUploadFailed(errors.response.data.errors));
        throw errors;
      });
  };
}

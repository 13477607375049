import axios from 'axios';
import { GET_USER_INFO_REQUEST, GET_USER_INFO_SUCCESS, GET_USER_INFO_FAILURE } from 'shared/state/types';
function userInfoRequested() {
  return {
    type: GET_USER_INFO_REQUEST
  };
}

function userInfoSuccess(data) {
  return {
    type: GET_USER_INFO_SUCCESS,
    userInfo: data,
  };
}

function userInfoFailure(error) {
  return {
    type: GET_USER_INFO_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }


  return axios.create({
    baseURL,
    headers,
  })
};

export function userDetails() {

    return dispatch => {
      dispatch(userInfoRequested());
        return request().get(`people/me`)
        .then(response =>
          dispatch (userInfoSuccess(response.data),
        )
        ) 
        .catch(errors => {
          dispatch(userInfoFailure(errors.response));
  
          throw errors;
        });
      

    
 };
}

import React from 'react';
import classnames from 'classnames';
import {
    bindActionCreators,
    compose
  } from "redux";
  import { connect } from "react-redux";
  import PropTypes from 'prop-types';

class CalendarDay extends React.Component {
    render () {
        const { day, isToday, events, onClick,weekOff } = this.props; 
        const dayClasses = classnames({
            'flexColumn': true,
            'day': true,
            'inactive': day.siblingMonth,
            'today': isToday                 
        });       
        
        return (           
            <div 
                onClick={onClick.bind(null, this, day)}
                className={dayClasses}>
                <div className="inner-grid" style={(this.props.makeToken === true) ?{backgroundColor:'#f7f7f7'}: null } >
                    <div className="date">
                        {day.day}
                    </div>
                    {
                        (Array.isArray(weekOff) && weekOff.indexOf(day.weekDay) == -1 ) ?
                        <div className="event noTimeSlotAvilable"></div>  : events
                    }
                    {/* {events} */}
                </div>
            </div>
        );
    }
}

CalendarDay.propTypes = {
    day: PropTypes.object.isRequired,
    isToday: PropTypes.bool,
    events: PropTypes.array,
    onClick:PropTypes.func,
};

CalendarDay.defaultProps = {
    onClick: () => {},
}

const enhance = compose(

    connect( dispatch =>
      bindActionCreators(
        {
     
        },
        dispatch
      )
    )
  );
export default enhance(CalendarDay);

import { REGISTER_USER } from 'shared/state/types';
import { request } from 'shared/auth';

function registerUserRequested() {
  return {
    type: REGISTER_USER.REQUEST,
  };
}

function registerUserFailed(error) {
  return {
    type: REGISTER_USER.FAILURE,
    error:error,
  };
}

function registerUserSuccess(data) {
  return {
    type: REGISTER_USER.SUCCESS,
    payload: data,
  };
}

export function registerUser(params,isMobileRegister=false) {
  return dispatch => {
    dispatch(registerUserRequested());
    let url  = '/auth/register';
    if(isMobileRegister){
      url  = '/auth/register-with-mobile';
    }  
    return request().post(url, params)
      .then(response =>{
          dispatch(registerUserSuccess(response.data.data))
          return response.data.data;
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(registerUserFailed(errors.response.data.message));
        }
        else{
          dispatch(registerUserFailed("Unable to access api"));
        }
        throw errors;
      });
  };
}

import React from 'react';
import { Link,useLocation } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Favorite from '@material-ui/icons/Favorite';
import ExitToApp from '@material-ui/icons/ExitToApp';
// import Event from '@material-ui/icons/Event';
import Business from '@material-ui/icons/Business';


import './assets/style/RightSideDropMenu.scss';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
     transform:'scale(1) translateZ(0px)',
    top:'50px !important',
    transformOrigin:'39px 0px 0px !important',
    right:'auto !important',
    // left:'auto !important',
  },
  root:{
    fontSize:'12px',
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: '#eee',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function RightSideDropMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function externalLink(link){   
    window.open(link,'_blank');
  }
  
  return (   
    <div className="rightMenuDiv">
    {props.profilePhoto? <img onClick={handleClick} className="rightImg" src={props.profilePhoto} alt="FIRST-HOLD" title="Menu" />:''}
     
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        {
          (location.pathname.includes('/calendar/') == true
          || location.pathname.includes('/WidgetTypeTwo/') == true)?
            <div>
            {
              parseInt(localStorage.getItem('type')) === 1 ?
              <StyledMenuItem  onClick={()=>externalLink('/myVenue')} >
                <ListItemIcon>
                  <Business className="menu_icons"/>
                </ListItemIcon>
                <ListItemText primary="My Venue(s)" />
              </StyledMenuItem>
              :
              <StyledMenuItem   onClick={()=>externalLink('/myFavourites')}>
                <ListItemIcon>
                  <Favorite className="menu_icons"/>
                </ListItemIcon>
                <ListItemText primary="My Favorites" />
              </StyledMenuItem>  
            }       
              <StyledMenuItem  onClick={()=>externalLink('/editProfile')} >
                <ListItemIcon>
                  <AccountCircle className="menu_icons"/>
                </ListItemIcon>
                <ListItemText primary="Account" />
              </StyledMenuItem>
            </div>
          :
          <div>
          {
            parseInt(localStorage.getItem('type')) === 1 ?
            <><StyledMenuItem component={Link} to='/myVenue'>
                    <ListItemIcon>
                      <Business className="menu_icons" />
                    </ListItemIcon>
                    <ListItemText primary="My Venue(s)" />
                  </StyledMenuItem><StyledMenuItem component={Link} to='/membership'>
                      <ListItemIcon>
                        <Business className="menu_icons" />
                      </ListItemIcon>
                      <ListItemText primary="Membership" />
                    </StyledMenuItem></>
            
            :
            <StyledMenuItem component={Link} to='/myFavourites'>
              <ListItemIcon>
                <Favorite className="menu_icons"/>
              </ListItemIcon>
              <ListItemText primary="My Favorites" />
            </StyledMenuItem>  
          }       
            <StyledMenuItem component={Link} to='/editProfile'>
              <ListItemIcon>
                <AccountCircle className="menu_icons"/>
              </ListItemIcon>
              <ListItemText primary="Account" />
            </StyledMenuItem>
          </div>
        }   
        <StyledMenuItem component={Link} to='/logout'>
          <ListItemIcon>
            <ExitToApp className="menu_icons"/>
          </ListItemIcon>
          <ListItemText primary="Logout"/>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import VenueFooter from '../../../shared/footer/venueFooter';
import LocationContactPopup from '../components/locationContactPopup';
import { contactUs } from 'data/cms/actions';

import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import '../assets/styles/locationContactPopup.scss';
import { getKeys} from '../../../data/master/actions'

class Contact extends Component {

  state = {captachKey:null};
  UNSAFE_componentWillMount() {
    this.props.getKeys('captcha_key').then((response) => {
      this.setState({captachKey:response});
    });
  }
  handleSubmit = (values) => {
    let formData = ''
    if(values.contact){
      formData =`name=${values.name}&contact=${values.contact}&email=${values.email}&message=${values.message}`
    }
    else{
      formData =`name=${values.name}&email=${values.email}&message=${values.message}`
    }
    this.props.contactUs(formData).then((response) => {
      window.location.href = '/thankyou';
    })
  }
  
  render() {
    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .required('*Required'),
      email: Yup.string()
        .email().required('*Required'),
      contact: Yup.string(),
      message: Yup.string()
        .required('*Required'),
      captcha: Yup.string()
        .required('*Required')
    });
    const initialValues = {
      name: '',
      email: '',
      contact: '',
      message: '',
      captcha:''
    }
    return (
      <div className="venue_page">       
        <Grid xs={12} item className="midContainer" >
          <Grid container justify="center">
            <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values, actions)
                }}
                render={props => (
                  <form onSubmit={props.handleSubmit}>
                    <LocationContactPopup  formikProps={props} captachKey={this.state.captachKey} />
                  </form>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>

    );
  }
}


export default connect(
  null,
  dispatch => bindActionCreators({
    contactUs,
    getKeys
  }, dispatch),
)(withRouter(Contact));


import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Grid } from "@material-ui/core";
import "./assets/style/placeHold.scss";
import DialogActions from "@material-ui/core/DialogActions";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import CustomizedButton from "../../shared/components/customizedButton";

const styles = theme => ({
  root: {
    margin: 0,
    overflow: "visible"
  },
  paperWidthSm: {
    margin: "20px",
    overflow: "visible"
  },

  paper: {
    margin: "20px",
    overflow: "visible"
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      fontWeight: "500",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "0.46px",
      color: "black"
    }
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      borderRadius: "6px",
      // border: "solid 1px black"
    }
  }
});

const DialogContent = withStyles(theme => ({
  root: {}
}))(MuiDialogContent);


class FollowPopup extends React.Component {
  // constructor(props, context) {
  //   super(props, context);
  // }

  state = {
  
    open: true,
    showForm: true,


  };


  handleClose = () => {
    this.setState({
      open: false,
      showForm: false
    
    });

  };

  handleCancelClick = () => {
    this.setState(prevState => ({ showForm: false, open: true }));
  };

  render() {
    // const { classes } = this.props;
    return (
      <div className="place-hold">

        <Dialog
          className="success_modal_container"
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          // onClose={this.handleClose}
        >
          <DialogActions className="dialogActions">
            <span
              className="material-icons close closeButton"
              onClick={this.props.handleClosefollow}
            >
              close
            </span>
          </DialogActions>

          <DialogContent className="dialog-content">
          <div className="events">
                            <div className="header">
                              <div className="header-text">
                                Follow 
                             </div>
                              </div>
                              </div>
            <Grid container className="modalBox_content follow-inner-popup">
                <div>You are now following <span style={{fontWeight: 'bold'}}>{this.props.date}</span> for your <span style={{fontWeight: 'bold'}}>{this.props.name}</span>. You will be notified if another planner places a hold on this event space for this date. (Remember… this is not a hold)</div>
                <div style={{textAlign:'center'}}>
                  <CustomizedButton label="OK"
                        width="165px"
                        height="36px"
                        variant="outlined"
                        onClickEvent={this.props.handleClosefollow}/></div>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

FollowPopup.propTypes = {
  classes: PropTypes.object.isRequired

};

const mapStateToProps = state => {
  return {

  };
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
      },
      dispatch
    )
  )
);
export default enhance(FollowPopup);

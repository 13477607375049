import { VERIFY_CONFIRMATION } from 'shared/state/types';
import { requestForUnauthorised } from 'shared/auth';

function verifyConfirmationRequested() {
  return {
    type: VERIFY_CONFIRMATION.REQUEST,
  };
}

function verifyConfirmationFailed(error) {
  return {
    type: VERIFY_CONFIRMATION.FAILURE,
    error:error,
  };
}

function verifyConfirmationSuccess(data) {
  return {
    type: VERIFY_CONFIRMATION.SUCCESS,
    message: data,
  };
}

export function verifyConfirmation(params) {
  return dispatch => {
    dispatch(verifyConfirmationRequested());

    return requestForUnauthorised().put('auth/confirmations', params)
      .then(response =>{
        dispatch(verifyConfirmationSuccess(response.data.message))
        return response.data.message}
      )
      .catch(errors => {
        dispatch(verifyConfirmationFailed(errors.response.data.message));
        throw errors;
      });
  };
}

import axios from 'axios';
import { GET_SPACE_PROFILE_REQUEST, GET_SPACE_PROFILE_SUCCESS, GET_SPACE_PROFILE_FAILURE } from 'shared/state/types';
// import Cookies from 'js-cookie';
import { loadProgressBar } from 'axios-progress-bar'

function spaceRequested() {
  return {
    type: GET_SPACE_PROFILE_REQUEST
  };
}

function spaceSuccess(data) {
  return {
    type: GET_SPACE_PROFILE_SUCCESS,
    spaceDetails: data,
  };
}

function spaceFailure(error) {
  return {
    type: GET_SPACE_PROFILE_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');
  }
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function spaceProfile(params,photoCount) {

    return dispatch => {
      dispatch(spaceRequested());
      if(photoCount===1)
      {
      if(localStorage.getItem('fhtoken')!=null){
        return request().get(`planner/venue/space/${params}/profile?photoCount=${photoCount}`)
        .then(response =>
          dispatch (spaceSuccess(response.data),
         )
        )
        .catch(errors => {
          dispatch(spaceFailure(errors.response));
  
          throw errors;
        });
      }
      else
      {
      return request().get(`venue/space/${params}/profile?photoCount=${photoCount}`)
      .then(response =>
        dispatch (spaceSuccess(response.data),
        )
      )
      .catch(errors => {
        dispatch(spaceFailure(errors.response));

        throw errors;
      });
      }

    }
    else
    {
      if(localStorage.getItem('fhtoken')!=null){
        return request().get(`planner/venue/space/${params}/profile?photoCount=${photoCount}`)
        .then(response =>
          dispatch (spaceSuccess(response.data),
         )
        )
        .catch(errors => {
          dispatch(spaceFailure(errors.response));
  
          throw errors;
        });
      }
      else
      {
      return request().get(`venue/space/${params}/profile?photoCount=${photoCount}`)
      .then(response =>
        dispatch (spaceSuccess(response.data),
        )
      )
      .catch(errors => {
        dispatch(spaceFailure(errors.response));

        throw errors;
      });
      }

    
    }
    
 };
}

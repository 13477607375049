import React from 'react';
import { Link } from "react-router-dom";

import { Grid } from '@material-ui/core';

import './assets/style/VenueTypeCard.scss';

function VenueTypeCard(props) {
    return (
        <div className="venueTypeCard">
            {props.enableLink ?
                <Link key={props.idx} to={props.linkTo} params={props.event} onClick={props.onClick}>
                    <Grid item className="venues">
                        <img className="find_feature_venue_image" src={props.image} alt="venue type" />
                        <Grid container>
                            <Grid item xs={12} className="find_venue_event">
                                {props.event}
                            </Grid>
                        </Grid>
                    </Grid>
                </Link>
                :
                <Grid item key={props.idx} className="venues">
                    <img className="find_feature_venue_image" src={props.image} alt="venue type" />
                    <Grid container>
                        <Grid item xs={12} className="find_venue_event" >
                            {props.event}
                        </Grid>
                    </Grid>
                </Grid>
            }
        </div>
    );
}

export default (VenueTypeCard);

import React from 'react';

import CustomInput from '../../../shared/components/CustomInput';

import { Grid, Hidden } from '@material-ui/core';

import '../assets/styles/addSpace.scss';

function AddSpaceName(props) {
    return (
        <Grid className="add_space_name" container>
            <Hidden xsDown={true}>
                <Grid item sm={1} md={2} lg={3} xl={3}></Grid>
            </Hidden>
            <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
                <Grid container justify="center" className="add_space_name_container">
                    <Hidden xsDown={true}>
                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <div className="space_name">
                            <CustomInput label="NAME OF SPACE" type="text" name="spaceName" placeholder="Ballroom, Ocean View Gallery, etc." />
                        </div>
                    </Grid>
                    <Hidden xsDown={true}>
                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                    </Hidden>
                </Grid>
            </Grid>
            <Hidden smDown={true}>
                <Grid item sm={1} md={2} lg={3} xl={3}></Grid>
            </Hidden>
        </Grid>
    );
}

export default AddSpaceName;
import React from 'react';

import CustomInput from '../../shared/components/CustomInput';

import { Grid, Hidden,Link } from '@material-ui/core';

import '../assets/styles/addVenue.scss';

import ImageUpload from '../../shared/components/ImageUpload';
import CustomizedCheckBox from '../../shared/components/CustomizedCheckbox';


function AddVenueContact(props) {

    const { deleteImage, profilePhotoId } = props;
    const error=props.error;
    const saveClick=props.saveClick;
    return (
        <div className="add_venue_contact_container image-design">
            <Grid container className="add_venue_contact">
                <Grid className="learning_hall_container" item xs={12}>
                    <h4 className="learning_hall">{props.formikProps.values.venueName}</h4>
                </Grid>
                <Grid item xs={12}>
                <Grid className="save_button" item xs={12}>
                    <Grid container justify="center">
                    {props.formikProps.values.agree===false?
                         <Grid item>
                         
               <ImageUpload profilePhotoId={profilePhotoId} contactImageData={props.contactImageData} saveClick={saveClick} handleChange={props.handleChange} name="contact_image" value={props.formikProps.values.contact_image} setFieldValue={props.formikProps.setFieldValue}/>
                        {
                           profilePhotoId &&
                           <Link className="delete-image" onClick={(e)=>{deleteImage(e)}}>Delete</Link>
                        }
                        <div style={{color:'red',fontSize:'10px'}}>{error}</div>
                        <div className="hintText">
                        Hint: Event planners are more likely to respond faster when
                        they connect with a personal sales experience.
                        </div>
                        </Grid>
                        :
                        <Grid item>
                          <img style={{marginTop: '25px',borderRadius: '50%'}} className="uploaded_image_icon" src={localStorage.getItem('profilePhoto')} alt="Venue" />
                          <div className="hintText">
                            Hint: Event planners are more likely to respond faster when
                            they connect with a personal sales experience.
                            </div>
                        </Grid>
                        }
                        </Grid>
                        </Grid>
                    <Grid container>
                        <Hidden xsDown={true}>
                            <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                        </Hidden>
                        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                            <Grid container className="add_venue_contact_form">
                                <Grid className="left_panel" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="what_is_contact">Who is the main contact for this venue?
                                    </div>
                                </Grid>
                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="contact_name">
                                        <CustomInput label="Contact Full Name" type="text" name="contactName" placeholder="First Last" />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="contact_phone">
                                        <CustomInput label="Contact Phone Number" type="phone" name="contactPhoneNumber" placeholder="(000)-000-0000" />
                                    </div>                                  
                                </Grid>
                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="contact_email">
                                        <CustomInput label="Contact Email" type="text" name="contactEmail" placeholder="email@domain.com" />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="contact_job_title">
                                        <CustomInput label="Contact Job Title" type="text" name="contactJobTitle" placeholder="Venue Manager" />
                                    </div>
                                </Grid>
                                <Grid style={{marginTop:'40px'}} item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomizedCheckBox setFieldValue={props.formikProps.setFieldValue} handleChange={props.formikProps.handleChange} onChange={props.onClick} name='agree' value={props.formikProps.values.agree} label="Same contact as main profile" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Hidden xsDown={true}>
                            <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                        </Hidden>
                 
                    </Grid>
                  
                </Grid>
            </Grid>
        </div>
    );
}


export default AddVenueContact;

import axios from 'axios';
import { GET_FOLLOW_DATE_REQUEST, GET_FOLLOW_DATE_SUCCESS, GET_FOLLOW_DATE_FAILURE } from 'shared/state/types';

function followDateRequested() {
  return {
    type: GET_FOLLOW_DATE_REQUEST
  };
}

function followDateSuccess(data) {
  return {
    type: GET_FOLLOW_DATE_SUCCESS,
    followList: data,
  };
}

function followDateFailure(error) {
  return {
    type: GET_FOLLOW_DATE_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }


  return axios.create({
    baseURL,
    headers,
  })
};

export function followDateData(params ={}) {
    return dispatch => {
      dispatch(followDateRequested());
        return request().get(`event_follow_dates`,{ params })
        .then(response =>
          dispatch (followDateSuccess(response.data),
                ))
        .catch(errors => {
          dispatch(followDateFailure(errors.response));
  
          throw errors;
        });
    
   

    
 };
}

import { DELETE_VENUE_IMAGE } from 'shared/state/types';
import { request } from 'shared/auth';

function deleteVenueImageRequested() {
  return {
    type: DELETE_VENUE_IMAGE.REQUEST,
  };
}

function deleteVenueImageFailed(error) {
  return {
    type: DELETE_VENUE_IMAGE.FAILURE,
    error:error,
  };
}

function deleteVenueImageSuccess(data) {
  return {
    type: DELETE_VENUE_IMAGE.SUCCESS,
    payload: data,
  };
}

export function deleteVenueImage(venue_id, photo_id) {
  return dispatch => {
    dispatch(deleteVenueImageRequested());

    return request().delete(`venues/${venue_id}/photos/${photo_id}`,)
      .then(response =>
        dispatch(deleteVenueImageSuccess(response.data.data))
      )
      .catch(errors => {
        dispatch(deleteVenueImageFailed(errors.response.data.errors));
        throw errors;
      });
  };
}

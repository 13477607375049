import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Grid, Hidden } from '@material-ui/core';

// import { deleteVenueImage } from 'data/venues/actions';

import '../assets/styles/notifications.scss';

class Notifications extends Component {
    
    render() {
        return (
            <div className="edit_profile">
                <div className="add_venue_address_container">
                <Grid container className="edit_profile_component">
                <Grid className="headings" item xs={12}>
                    <h4 className="form_name">Basic Info</h4>
                </Grid>
                <Grid item xs={12}>
                                <Grid container>
                                    <Hidden xsDown={true}>
                                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Grid container className="edit_profile_component_form">
                                            tyjhhhhhhhhhhhhhhhhhhhhh hj,,,,uillllllllsdfsefs
                                        </Grid>
                                    </Grid>
                                    <Hidden xsDown={true}>
                                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                    </Hidden>
                                </Grid>
                </Grid>
            </Grid>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        // userData: state.data.auth.userData,
        // states: state.data.auth.states
    };
};

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({
        // deleteVenueImage
    }, dispatch),
)(withRouter(Notifications));

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import "../../shared/components/assets/style/placeHold.scss"
import {object} from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router-dom";
const DialogContent = withStyles(theme => ({root: {}}))(MuiDialogContent);
const DialogActions = withStyles(theme => ({root: {}}))(MuiDialogActions);
class ReasonPopup extends React.Component {
    state = {
        open: this.props.open
    };

    handleClose = () => {
        this
            .props
            .close();
        this.setState({open: false})
    };
    render() {
        return (
            <div className="h">

                <Dialog
                    className="success_modal_container hold_settings forgot-something-popup"
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                    style={{
                    width: "110%"
                }}>
                    <DialogActions className="dialogActions">
                        <span className="material-icons close closeButton" onClick={this.handleClose}>
                            close
                        </span>
                    </DialogActions>
                    <DialogContent
                        className="dialog-content"
                        style={{
                        width: '100%'
                    }}>
                        <Grid
                            container
                            className="modalBox_content"
                            style={{
                            padding: '30px'
                        }}>
                            <Grid item xs={12}>

                                {this.props.dataCity.hold_reason
                                    ? <p>{this.props.dataCity.hold_reason}</p>
                                    : <strong>No Reason found.</strong>
}
                            </Grid>
                        </Grid>
                    </DialogContent>

                </Dialog>

            </div>
        );
    }
}

ReasonPopup.propTypes = {
    classes: object.isRequired
};

const mapStateToProps = state => {
    return {};
};

const enhance = compose(withRouter, connect(mapStateToProps, dispatch => bindActionCreators({}, dispatch)));
export default enhance(ReasonPopup);

import {UPDATE_IMAGE_PHOTOGRAPHER} from 'shared/state/types';
import {request} from 'shared/auth';

function onRequested() {
    return {type: UPDATE_IMAGE_PHOTOGRAPHER.REQUEST};
}

function onFailed(error) {
    return {type: UPDATE_IMAGE_PHOTOGRAPHER.FAILURE, error: error};
}

function onSuccess(data) {
    return {type: UPDATE_IMAGE_PHOTOGRAPHER.SUCCESS, message: data.message};
}

export function updatePhotographer(params) {
    return dispatch => {
        dispatch(onRequested());
        return request()
            .post(`resources/blukUpdate`, params)
            .then(response => {
                dispatch(onSuccess(response.data))
                return response.data
            })
            .catch(errors => {
                dispatch(onFailed(errors.response.data.error));
                throw errors;
            });
    };
}

import React, {Component} from 'react';
//import Footer from '../components/footer';
import {connect} from "react-redux";
import {object, func} from "prop-types";
import {bindActionCreators, compose} from "redux";
//import BannerList from '../components/banner';
//import EventPlanner from '../components/eventPlanner';
//import SectionOne from '../components/sectionOne';
//import SectionTwo from '../components/sectionTwo';
//import SectionThree from '../components/sectionThree';
//import SectionFive from '../components/sectionFive';
//import SectionFour from '../components/sectionFour';
//import FeaturedVenues from '../components/featuredVenues';
import { homepage } from "shared/homepage/actions";
import '../assets/styles/landingPage.scss';
import { Suspense, lazy } from "react";
const BannerList = lazy(() => import(
    '../components/banner'))
const SectionOne = lazy(() => import(
        '../components/sectionOne'))
const SectionTwo = lazy(() => import(
            '../components/sectionTwo'))
const SectionThree = lazy(() => import(
                '../components/sectionThree'))
const SectionFour = lazy(() => import(
                    '../components/sectionFour'))
const SectionFive = lazy(() => import(
                        '../components/sectionFive'))
const EventPlanner = lazy(() => import(
                            '../components/eventPlanner'))
const Footer = lazy(() => import(
                                '../components/footer'))                           
const FeaturedVenues =lazy(() => import(
    '../components/featuredVenues'))
const logo = require("../assets/images/default_venue.png");
class LandingPage extends Component {

   

    UNSAFE_componentWillMount() {
        this.props.homepage();
    }

    componentDidUnmount() {
        clearInterval(this.timeout);
    }

    render() {        
        let featureVenueData=[];
        this.props.venueHomePage.featuredVenues && this.props.venueHomePage.featuredVenues.map(function(data, idx) {
            let obj={};
            let type='';
            if(data.type==='1'){
            type="Conferences";
            }
            else if(data.type==='2'){
            type="Weddings";
            }
            else if(data.type==='3'){
            type="Workshops";
            }
            else if(data.type==='4'){
            type="Trade Shows";
            }
            else if(data.type==='5'){
            type="Corporate Parties";
            }
            else if(data.type==='6'){
            type="Fundraisers";
            }

            let stateAbbr='';
            if(data.address.state_abbr!==null){
                stateAbbr=data.address.state_abbr;
            }
            obj.id=data.slug;
            obj.image=data.profilePhoto ? data.profilePhoto &&
            data.profilePhoto.thumbnail_path : logo;
            obj.venueName=data.name;
            obj.venueAddress= data.address &&
            data.address.city +", " +stateAbbr;
            obj.favourite=" ";
            obj.category=type;
            featureVenueData.push(obj); 
            return featureVenueData;
        });
        return (
            <div className="home_page fh-container">
                <Suspense fallback=
                  {<div style={{color:"Black",height:"90vh",textAlign:"center", paddingTop:"250px"}}>Loading...</div>}>
                   <BannerList/>
                </Suspense>
                <Suspense fallback=
                  {<div style={{color:"Black",height:"90vh",textAlign:"center", paddingTop:"250px"}}>Loading...</div>}>
                <SectionOne  />
                </Suspense>
                <Suspense fallback=
                  {<div style={{color:"Black",height:"90vh",textAlign:"center", paddingTop:"250px"}}>Loading...</div>}>
                <SectionTwo />
                </Suspense>
                <Suspense fallback=
                  {<div style={{color:"Black",height:"90vh",textAlign:"center", paddingTop:"250px"}}>Loading...</div>}>
                <SectionThree />
                </Suspense>
                <Suspense fallback=
                  {<div style={{color:"Black",height:"90vh",textAlign:"center", paddingTop:"250px"}}>Loading...</div>}>
                <SectionFour />
                </Suspense>
                 <Suspense fallback=
                  {<div style={{color:"Black",height:"90vh",textAlign:"center",paddingTop:"150px"}}>Loading...</div>}>          
                <FeaturedVenues  featureVenueData={featureVenueData} />
                </Suspense>
                <Suspense fallback=
                  {<div style={{color:"Black",height:"90vh",textAlign:"center", paddingTop:"250px"}}>Loading...</div>}>
                <SectionFive />
                </Suspense>
                <Suspense fallback=
                  {<div style={{color:"Black",height:"90vh",textAlign:"center", paddingTop:"250px"}}>Loading...</div>}>
                <EventPlanner />
                </Suspense>
                <Suspense fallback=
                  {<div style={{color:"Black",height:"90vh",textAlign:"center", paddingTop:"250px"}}>Loading...</div>}>
                <Footer />
                </Suspense>
            </div>
        );
    }
}

LandingPage.propTypes = {
    classes: object.isRequired,
    homepage: func.isRequired
};
const mapStateToProps = state => {
    return {
        venueHomePage: state.data.homepage.homePageData,
        homeBannerActiveSlide: state.data.master.homeBannerActiveSlide
     };
};

const enhance = compose(connect(mapStateToProps, dispatch => bindActionCreators({homepage }, dispatch)));
export default enhance(LandingPage);

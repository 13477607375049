import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import '../assets/styles/paymentConfirmation.scss';

class PaymentConfirmation extends Component {

    render() {
        return (
            <div className="venue_page paymentconfirmation">
                <Grid xs={12} item className="paymentimage" style={{ textAlign: 'center' }}>
                    <img src={require('../assets/images/LogoWithName.png')}
                        width='120' justify='center'
                        alt="Venue" />
                </Grid>
                <Grid>
                    <p className="title">Payment Confirmation</p>
                </Grid>
                <Grid xs={12} item className="midContainer-payment">
                    <Grid container className="right" justify="right">
                        <h3>We're stronger together</h3>
                        <p>Thank you.</p>
                    </Grid>

                    <Grid style={{ display: 'block' }}>
                        <div className="annual-left">
                            <p>Annual Plan</p>
                        </div>
                        <div className="annual-right">
                            <p>total <span>$550.00</span></p>
                        </div>

                    </Grid>
                    <div className="payment-method">
                        <p className="one" >Payment Method</p>
                        <p className="two" >Credit card</p>
                        <p className="three" >Visa: **** &nbsp; **** &nbsp; **** &nbsp; xxxx</p>
                    </div>
                </Grid>
            </div>

        );
    }
}

export default (PaymentConfirmation);


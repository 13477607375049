import React from 'react';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '176px',
    height: '49px',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    borderRadius: '6px',
    background: 'linear-gradient(to bottom, #f1435e, #ee2738)',
    fontSize: '13px',
    fontWeight: '500',
    marginBottom: '16px',
    '&$disabled': {
      background: '#9aa7af',
      color: 'white',
      boxShadow: 'none',
    },
    '&:hover': {
      background: "#d80e2a",
      color: "#ffffff"
    }

  },
  disabled: {
  },
});
function FourohFour(props) {
  const { classes } = props;
  return <div className="fourohfour"><h1>404</h1>
    <Button
      href="/"
      classes={{
        root: classes.root,
        disabled: classes.disabled,
      }}
    >
      Back to Homepage
                    </Button>
  </div>;
}

export default withStyles(styles)(FourohFour);
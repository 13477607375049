import { Record } from 'immutable';

import {
  GET_FAVOURITES_REQUEST,
  GET_FAVOURITES_FAILURE,
  GET_FAVOURITES_SUCCESS,
} from 'shared/state/types';

class State extends Record({
  favourites:{},
  error:''
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {

    case GET_FAVOURITES_REQUEST:
      return state.merge({
        error:''
      });
    case GET_FAVOURITES_FAILURE:
      return state.merge({
        favourites:{},
        error: action.error
      });
    case GET_FAVOURITES_SUCCESS:
      return state.merge({
        favourites:action.favourites,
        error:'Success'
      });

    default:
      return state;
  }
}

import React from 'react';

import { Grid } from '@material-ui/core';
import VenueCardWithFav from '../../shared/components/VenueCardWithFav';
function RightPanelVenue(props) {
    const venueObject = props.venues.map(function(data, idx) {
    
        return(
            <div className="venue_card" key={idx} >
                <VenueCardWithFav 
                 type='1'
                 imageSize='big' 
                 venueLat={data.latitude} 
                 venueLong={data.longitude} 
                 width= '266px' 
                 height= '145px' 
                 addressColor="#36434b" 
                 event={data.event} 
                 idx={data.id} 
                 image={data.image} 
                 favourite={data.favourite} 
                 favouriteId={data.favouriteId} 
                 venueAddress={data.venueAddress} 
                 venueName={data.venueName} 
                 venueCity={data.venueAddress} 
                 enableLink={true} linkTo={"/venue-profile/"+data.slug+"?event_type="+props.type+"&seating="+props.seating+"&place_hold_seating="+props.guest+"&startDateRange="+props.start_date+"&endDateRange="+props.end_date+"&startTime="+props.start_time+"&endTime="+props.end_time+"&budget="+props.budget+"&city="+props.address+"&browseVenue=true"}/>
            </div>
        );
    });

    return (
        <div className="browse_venue_right_panel">
            <h4 className="venue_heading">Venues</h4>
            <Grid className="venues" container>
                    {venueObject}
            </Grid>
        </div>
    );
}


export default RightPanelVenue;

import React from 'react';
import PropTypes from 'prop-types';
import CustomInput from '../../shared/components/CustomInput';
import CustomizedButton from '../../shared/components/customizedButton';
import { Formik, Field } from 'formik';
import { Grid, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
const styles = theme => ({
    icon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    root: {
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    typography: {
        width: '237px',
        height: '120px',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        fontFamily: 'Raleway'
    },
    colorSwitchBase: {
        //color: '#007E33',
        '&$colorChecked': {
          color: '#007E33',
          //backgroundColor: '#007E33',
          '& + $colorBar': {
            backgroundColor: '#007E33',
          },
        },
      },
      colorBar: {
        //backgroundColor:'#007E33'
      },
      colorChecked: {
        color: '#007E33',
      }
});

function EventTempleInfo(props) {
    
    const handleChange = name => event => {       
        props.handleETStatus(event.target.checked);       
    };
    const { classes } = props;

    return (
        <div>
            <Grid container className="edit_profile_component">
                <Grid className="headings" item xs={12}>
                    <h4 className="form_name">Set-up Event Temple  <Switch checked={props.event_temple_status} onChange={handleChange('EventTemple_status')} value="EventTemple_status" disabled={props.tripleseat_status==0?false:true}
                     classes={{
                        switchBase: classes.colorSwitchBase,
                        checked: classes.colorChecked,
                        bar: classes.colorBar,
                      }}/></h4>
                   {props.tripleseat_status==0?null: <p>Tripleseat is active, Event Temple can’t be enabled</p> }             
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={props.initialValues}
                        enableReinitialize
                        validationSchema={props.validationSchema}
                        onSubmit={(values, actions) => {
                            props.handleSubmit(values, actions)
                        }}
                        render={props => (
                            <form onSubmit={props.handleSubmit}>
                                <Grid container>
                                    <Hidden xsDown={true}>
                                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Grid container className="edit_profile_component_form">
                                            <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div className="field_div">
                                                    <CustomInput label="Event Temple Organization ID" type="text" name="event_temple_org_id" placeholder="Event Temple Organization ID" />
                                                </div>
                                            </Grid>
                                            <Grid className="right_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div className="field_div">
                                                    <CustomInput label="Event Temple Key" type="text" name="event_temple_consumer_key" placeholder="Event Temple Key" />
                                                </div>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid className="save_button" item xs={12}>
                                        <Grid container justify='flex-end'>
                                            <Grid item>
                                                <CustomizedButton label="Save" type="submit" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Hidden xsDown={true}>
                                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                    </Hidden>
                                </Grid>
                            </form>
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

EventTempleInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

const enhance = withStyles(styles)

export default enhance(EventTempleInfo);



import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { Link } from "react-router-dom";

import CustomizedButton from '../shared/components/customizedButton';
import { getUser, updateUser } from 'data/person/actions';
import CustomInput from '../shared/components/CustomInput';
import { markFav } from 'shared/planner/actions';
import { login, bootstrap } from 'shared/auth/actions';
import GoogleSignUp from './register/components/googleSignUp';

import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { googleSignUp } from "shared/googleSignUp/actions";

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('*Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match!")
    .required('*Password is required')
});
class CreatePassword extends Component {

  static propTypes = {
    // login: PropTypes.func.isRequired,
    bootstrap: PropTypes.func.isRequired,
    storePassword: PropTypes.func.isRequired,
    // googleSignUp: PropTypes.func.isRequired
  };
state={
  passwordTypePass:'password',
  confirmPasswordTypePass:'password'
}
 changePasswordFieldType = () =>{
  if(this.state.passwordTypePass === 'password'){
    this.setState({passwordTypePass:'text'})
  }
  else{

    this.setState({passwordTypePass:'password'})
  }
}

changeConfirmPasswordFieldType = () =>{
  if(this.state.confirmPasswordTypePass === 'password'){
    this.setState({confirmPasswordTypePass:'text'})
  }
  else{

    this.setState({confirmPasswordTypePass:'password'})
  }
}
//  onClick = () =>{

//     this.props.googleSignUp().then((response) => {
//      window.location.assign(response.googleUrlDetails.data.auth_url) 

//     })
//   }
  handleSubmit = (values, { setSubmitting }) => {
    const formData = {
      password: values.password
  };
    console.log(formData,">>>>>>>>>>>>formdata>>>>>>>>>>>>")
    // return this.props.updateUser(formData)
    // .then((response) => {
    //   console.log(response,">>>>>>>>>>>>>>>>>>response>>>>>>>>>>>>>")
    //   localStorage.setItem('type', response.type)
    //   // if (response.type === 1) {
    //   //   window.location.href = './myVenue'
    //   // }
    //   // else if (response.type === 0) {
    //   //    window.location.href ='./browseVenue'
    //   // }
    //   window.location.href ='./register'
    //     this.props.getUser()
    //     this.props.userDetails()
    // })
    localStorage.setItem('googleSIgnInPassword',values.password)
      setSubmitting(false);
      // window.location.href ='./register'
      this.props.history.push('/register')
  };

  render() {
    return (
      <div>
{/* 
<img id="userPhoto" alt="The Scream" crossOrigin="anonymous"/>
<canvas id="userCanvas"></canvas> */}

        <div className="login">
          <Grid className="signup__inner" container justify="center">
            <Grid item xl={3} xs={10} md={4} lg={4} sm={6}>
              {/* <h3 className="firstHold" align="center">FIRST-HOLD has been added to Chrome!</h3> */}
              <div className="createPasswordForm">
                <h1 align="center" className="createAnAccount">Create Password</h1>
                {/* <Grid container justify="center">                  
                  <GoogleSignUp text="LOGIN WITH GOOGLE" onClick={this.onClick}/>
                </Grid>
                <div className="yourBusiness" align="center">
                  Your business e-mail used for scheduling
                </div> */}
                <Grid>
                  <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={PasswordSchema}
                    onSubmit={this.handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form className="formFields">
                        <Grid container justify="center">
                        <Grid item sm={12} xs={12}>
                          <label className="emailLabel">
                            Password
                          </label>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                        <div className="password_field_div" style={{position:"relative"}}>
                          <CustomInput st='basicinfo' type={this.state.passwordTypePass} name="password" placeholder="Enter password" passwordField changePasswordFieldType={this.changePasswordFieldType}/>
                        </div>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                        <label className="passwordLabel">
                            Confirm Password
                          </label>
                        </Grid>
                        <Grid item sm={12} xs={12}>

                  <div className="password_field_div" style={{position:"relative"}}>
                   <CustomInput st='basicinfo' type={this.state.confirmPasswordTypePass} name="confirmPassword" placeholder="Enter password" passwordField changePasswordFieldType={this.changeConfirmPasswordFieldType}/>
                  </div>
                            {/* <Field className="passwordField" type="password" name="password" placeholder="Enter Password" />
                          <div className="error"><ErrorMessage name="password" /></div> */}
                        </Grid>   
                        {/* <Grid item sm={12} className="forgotPassword">
                        <Link to="/forgotPassword" >
                              Forgot Password?
                            </Link>
                        </Grid>                                                */}
                         
                        </Grid>
                      
                        <div className="signupDiv">
                          <CustomizedButton label="Submit" type="submit" />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </div>
              {/* <div className="already" align="center">New user? <Link className="signinLink" to='/register'>Create Account</Link>.</div> */}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   // console.log(state.data.auth)
//   return {
//     signUpPassword: state.data.person.signUpPassword,
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return {
      ...bindActionCreators({ updateUser, getUser ,bootstrap }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(CreatePassword);

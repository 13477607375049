import React from 'react';
import { object } from 'prop-types';

import EditVenueImage from './editVenueImage';
import CustomizedCheckBox from '../../shared/components/CustomizedCheckbox';
import CustomizedButton from '../../shared/components/customizedButton';

import { Grid, Icon, Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Gallery from 'shared/components/gallery';
import { withStyles } from '@material-ui/core/styles';

import '../assets/styles/venueProfile.scss';

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // width: '610px',
        height: '400px',
        borderRadius: '6px',
        // backgroundColor: ' #ffffff',
        outline: 'none',
        left: '50%',
        top: '50%',
        transform: "translate(-50%, -50%)"
    },
    root: {
        padding: '0px !important'
    },
    closeButton: {
        textAlign: 'right',
        color: '#ffffff',
        fontSize: '18px',
        width: '100%'
    },
    buyout: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#36434b',
        paddingLeft: '15px'
    },
    copyrights: {
        paddingTop: '11px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '17px'
        }
    },
    done: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '0px'
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '17px',
            paddingBottom: '20px'
        }
    },
    header: {
        boxShadow: 'inset 0 -1px 0 0 #f0f0f0',
        backgroundColor: '#ffffff',
    },
    title:{
        padding: '0px'
    }

});

function VenueDetailsImage(props) {
    const { classes } = props;
    return (
        <div className="venue_details_image detail_image">
            <img className="image_of_venue" src={props.venueDetails.image} alt="Venue" />
            <Grid container className="venue_details_over_image">
                <Grid item className="sm-100 div1">
                    <div Landscape orientation recommended but not required className="venue_name_in_venue_details_image">
                        <h1>{props.venueDetails.name}</h1>
                    </div>
                    <div className="venue-name"><h1>{props.venueName}</h1></div>
                </Grid>
                <Grid item  className="venue_address_details_in_venue_details_image sm-100">
                    <Icon className="place_icon_in_venue_details_image">
                        place

                    </Icon>{props.venueDetails.address}
                </Grid>
                {props.editImage ?
                    (<Grid item className="view_photos_container">
                        <Button class="white-button" onClick={(e) => { props.handleEditPhotosClick(e) }}>Edit Photos</Button>
                    </Grid>)
                    :
                    (<Grid  item className="view_photos_container">
                        <Gallery label="View Photos" photos={props.venueDetails.photos} viewPhotoClick={props.viewPhotoClick} viewPhoto={props.viewPhoto}/>
                    </Grid>)
                }
                {props.formikProps ?
                    <Dialog
                        open={props.openImageUploadForm}
                        onClose={(e) => { props.handleEditPhotosClick(e) }}
                        keepMounted
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        fullWidth={true}
                    >
                        <DialogTitle className={classes.title}>
                            <Grid container className={classes.header} alignItems="center">
                                <Grid className={classes.buyout} item xs={12} sm={4} md={5} lg={5} xl={5}>
                                    {props.venueDetails.name} Photos
                                </Grid>
                                <Grid className={classes.copyrights} item xs={12} sm={6} md={5} lg={5} xl={5}>
                                    <CustomizedCheckBox name='agree' value={props.formikProps.values.agree} label="I have copyrights to use these photos." />
                                </Grid>
                                <Grid className={classes.done} item xs={12} sm={2} md={2} lg={2} xl={2}>
                                    <CustomizedButton
                                        height='24px'
                                        width='80px'
                                        label="Done"
                                        type="submit"
                                        onClickEvent={() => {
                                            props.formikProps.handleSubmit()
                                        }} />
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent classes={{
                                root: classes.root
                            }}>
                            {props.isEditVenueImage &&
                                <EditVenueImage 
                                name="venue"
                                deleteByName={props.deleteByName} 
                                formikProps={props.formikProps} 
                                handleEditPhotosClick={props.handleEditPhotosClick} 
                                imageFile={props.imageFile} 
                                imageUploaded={props.imageUploaded} 
                                imageFileName={props.imageFileName} 
                                imageUploading={props.imageUploading} 
                                handleImageUpload={props.handleImageUpload} 
                                handleImageChange={props.handleImageChange} 
                                deleteImage={props.deleteImage} 
                                venueImageError={props.venueImageError} />
                            }
                        </DialogContent>
                    </Dialog>
                : null}
            </Grid>
        </div>
        
    );
}

VenueDetailsImage.propTypes = {
    classes: object.isRequired,
};

const enhance = withStyles(styles)

export default enhance(VenueDetailsImage);

import { AUTO_LOGIN } from 'shared/state/types';
import { request } from 'shared/auth';

function autoLoginRequested() {
  return {
    type: AUTO_LOGIN.REQUEST,
  };
}

function autoLoginFailed(error) {
  return {
    type: AUTO_LOGIN.FAILURE,
    error:error,
  };
}

function autoLoginSuccess(data) {
  return {
    type: AUTO_LOGIN.SUCCESS,
    payload: data,
  };
}

export function autoLogin(params) {
  localStorage.setItem('adminLogin','1')
  return dispatch => {
    dispatch(autoLoginRequested());

    return request().get('auth/loginas?email='+params.email+'&token='+params.token, params)
      .then(response =>{
          dispatch(autoLoginSuccess(response.data.data))
          return response.data.data;
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(autoLoginFailed(errors.response.data.message));
        }
        else{
          dispatch(autoLoginFailed('Not able to access api'));
        }
        throw errors;
      });
  };
}

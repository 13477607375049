import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import "./assets/style/placeHold.scss";
import "./assets/style/venuePublishPopup.scss";
import { object } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";

const styles = theme => ({
    iconImage:{
        height:"24px",
        width:"24px !important",
        marginLeft:"26px",
        '&:hover': {
          backgroundColor:'#d80e2a'
        }
      },
root: {
margin: 0,
},
closeButton: {
position: 'absolute',
top: '8px',
color: '#9e9e9e',
right: '8px',
},
});

withStyles(styles)(props => {
const { children, classes, onClose } = props;
return (
<MuiDialogTitle disableTypography className={classes.root}>
<Typography variant="h6">{children}</Typography>
{onClose ? (
<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
<CloseIcon />
</IconButton>
) : null}
</MuiDialogTitle>
);
});

const DialogContent = withStyles(theme => ({
root: {
},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
root: {
},
}))(MuiDialogActions);

class FinishPopup extends React.Component {
state = {
open: this.props.openFinish,
};

handleCancelClick = () => {
  this.setState({open:false})
};

handleClose = () => {
this.props.finishClose()
  this.setState({open:false})
};

render() {
    // const { classes } = props;
return (
<div>

<Dialog 
className="success_modal_container view-listing-popup"
aria-labelledby="customized-dialog-title"
open={this.state.open} >
<DialogActions className="dialogActions">
<span
className="material-icons close closeButton"
onClick={this.handleClose}
>
close
</span>
</DialogActions>
<DialogContent className="dialog-content1" >
<Grid container className="modalBox_content">
<Grid item xs={12}>
<Grid container className="">


<Grid item xs={12} sm={12}>

    <div className="venue-publish-popup-box submit-for-approval">
    <div className="dont_single">SUBMIT FOR APPROVAL</div>

      <p style={{textAlign:'center'}}>
          Thank you for completing your profile.  Please make sure all of your event space profiles are completed with dynamic photos.  You will have an opportunity to edit after it’s been approved.  We hope you’re excited – we are!
      </p> 
      <p>Approval will take less than 24 hours.</p>
           <div className="approval-btn">
                <Button onClick={()=>{this.props.handleFinishClick();this.handleClose()}} class='red-button'>
                      Let's Go!                        
                </Button>
           </div>
    </div>

</Grid>

</Grid>
      

</Grid>

</Grid>

</DialogContent>


</Dialog>

</div>
);
}
}

FinishPopup.propTypes = {
classes: object.isRequired,
};

const mapStateToProps = state => {
return {

};
};

const enhance = compose(
withRouter,
connect(mapStateToProps,dispatch =>
bindActionCreators(
{

},
dispatch
)
)
);

export default enhance(FinishPopup);


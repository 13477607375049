import axios from 'axios';
import { GET_FAVOURITES_REQUEST, GET_FAVOURITES_SUCCESS, GET_FAVOURITES_FAILURE } from 'shared/state/types';
function favouritesRequested() {
  return {
    type: GET_FAVOURITES_REQUEST
  };
}

function favouritesSuccess(data) {
  return {
    type: GET_FAVOURITES_SUCCESS,
    favourites: data,
  };
}

function favouritesFailure(error) {
  return {
    type: GET_FAVOURITES_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }


  return axios.create({
    baseURL,
    headers,
  })
};

export function favourites() {
  return dispatch => {
    dispatch(favouritesRequested());
    return request().get(`favourites`)
      .then(response =>
        dispatch (favouritesSuccess(response.data),)
      )
      .catch(errors => {
        dispatch(favouritesFailure(errors.response));

        throw errors;
      });
  };
}

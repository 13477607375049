import React from 'react';
import { object } from 'prop-types';
import { ErrorMessage } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Input } from '@material-ui/core';

import './assets/style/CustomTimeSelect.scss';

const time = [

    {"name":"6:00 AM","value":"06:00"},
    {"name":"6:30 AM","value":"06:30"},
    {"name":"7:00 AM","value":"07:00"},
    {"name":"7:30 AM","value":"07:30"},
    {"name":"8:00 AM","value":"08:00"},
    {"name":"8:30 AM","value":"08:30"},
    {"name":"9:00 AM","value":"09:00"},
    {"name":"9:30 AM","value":"09:30"},
    {"name":"10:00 AM","value":"10:00"},
    {"name":"10:30 AM","value":"10:30"},
    {"name":"11:00 AM","value":"11:00"},
    {"name":"11:30 AM","value":"11:30"},
    {"name":"12:00 PM","value":"12:00"},
    {"name":"12:30 PM","value":"12:30"},
    {"name":"1:00 PM","value":"13:00"},
    {"name":"1:30 PM","value":"13:30"},
    {"name":"2:00 PM","value":"14:00"},
    {"name":"2:30 PM","value":"14:30"},
    {"name":"3:00 PM","value":"15:00"},
    {"name":"3:30 PM","value":"15:30"},
    {"name":"4:00 PM","value":"16:00"},
    {"name":"4:30 PM","value":"16:30"},
    {"name":"5:00 PM","value":"17:00"},
    {"name":"5:30 PM","value":"17:30"},
    {"name":"6:00 PM","value":"18:00"},
    {"name":"6:30 PM","value":"18:30"},
    {"name":"7:00 PM","value":"19:00"},
    {"name":"7:30 PM","value":"19:30"},
    {"name":"8:00 PM","value":"20:00"},
    {"name":"8:30 PM","value":"20:30"},
    {"name":"9:00 PM","value":"21:00"},
    {"name":"9:30 PM","value":"21:30"},
    {"name":"10:00 PM","value":"22:00"},
    {"name":"10:30 PM","value":"22:30"},
    {"name":"11:00 PM","value":"23:00"},
    {"name":"11:30 PM","value":"23:30"},
    {"name":"12:00 AM","value":"00:00"},
    {"name":"12:30 AM","value":"00:30"},
    {"name":"1:00 AM","value":"01:00"},
    {"name":"1:30 AM","value":"01:30"},
    {"name":"2:00 AM","value":"02:00"},
    {"name":"2:30 AM","value":"02:30"},
    {"name":"3:00 AM","value":"03:00"},
    {"name":"3:30 AM","value":"03:30"},
    {"name":"4:00 AM","value":"04:00"},
    {"name":"4:30 AM","value":"04:30"},
    {"name":"5:00 AM","value":"05:00"},
    {"name":"5:30 AM","value":"05:30"},
];
  
const hour = [
    {"name":"1hr","value":"1"},
    {"name":"2hr","value":"2"},
    {"name":"3hr","value":"3"},
    {"name":"4hr","value":"4"},
    {"name":"5hr","value":"5"},
    {"name":"6hr","value":"6"},
    {"name":"7hr","value":"7"},
    {"name":"8hr","value":"8"},
    {"name":"9hr","value":"9"},
    {"name":"10hr","value":"10"},
    {"name":"11hr","value":"11"},
    {"name":"12hr","value":"12"}
]; 

const styles = theme => ({
    root: {
        fontSize: '12px',
        lineHeight: '1.07',
        textAlign: 'left',
        // paddingTop: '10px !important',
       
    },
    selectContainer : {
        borderRadius: '6px',
        border: 'solid 1px #f0f0f0 !important',
        background: '#f7f7f7 !important',
        height: '36px',
        width: '100%',
        padding: '0px 4px',
        marginTop: '4px',
        '&:hover': {
            backgroundColor: "#f7f7f7 !important"
        },
        '&:focused': {
            backgroundColor: "#f7f7f7 !important"
        }
    },
    select : {
        '&:hover': {
            backgroundColor: "#f7f7f7 !important"
        },
        '&:focus': {
            backgroundColor: "#f7f7f7 !important"
        }
    },
    placeholder: {
        fontFamily: 'Raleway',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.07',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#b0b0b0',
    },
    options: {
        fontFamily: 'Raleway',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#ee2738',
        borderBottom: '1px solid #f7f7f7',
        backgroundColor: '#ffffff',
        textTransform: 'upper-case',
    },
    rootBrowse: {
        fontSize: '12px',
        lineHeight: '1.07',
        textAlign: 'left',
        fontWeight:'600'
       
    },
    selectContainerBrowse : {
        borderRadius: '6px',
        border: 'solid 1px #e1e1e1',
        background: '#ffffff !important',
        height: '36px',
        width: '100%',
        padding: '8px 15px',
        marginTop: '0px',

        '&:hover': {
            backgroundColor: "#ffffff !important"
        },
        '&:focused': {
            backgroundColor: "#ffffff !important"
        }
    },
    selectBrowse : {
        backgroundColor:'#ffffff',
        '&:hover': {
            backgroundColor: "#ffffff !important"
        },
        '&:focus': {
            backgroundColor: "#ffffff !important"
        }
    },
    placeholderBrowse: {
        fontFamily: 'Raleway',
        
        fontSize: '12px',
        fontWeight: '600',
        fontStyle: 'normal',
        lineHeight: '1.67',
        textAlign: 'left',
        color: 'rgb(98, 113, 122)'
    },
    optionsBrowse: {
        fontFamily: 'Raleway',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#ee2738',
        borderBottom: '1px solid #ffffff',
        backgroundColor: '#ffffff',
        textTransform: 'upper-case',
        padding: '8px 15px',

    }
});
const SpaceStarttime = [
    {"name":"4:00 AM","value":"04:00"},
    {"name":"4:30 AM","value":"04:30"},
    {"name":"5:00 AM","value":"05:00"},
    {"name":"5:30 AM","value":"05:30"},
    {"name":"6:00 AM","value":"06:00"},
    {"name":"6:30 AM","value":"06:30"},
    {"name":"7:00 AM","value":"07:00"},
    {"name":"7:30 AM","value":"07:30"},
    {"name":"8:00 AM","value":"08:00"},
    {"name":"8:30 AM","value":"08:30"},
    {"name":"9:00 AM","value":"09:00"},
    {"name":"9:30 AM","value":"09:30"},
    {"name":"10:00 AM","value":"10:00"},
    {"name":"10:30 AM","value":"10:30"},
    {"name":"11:00 AM","value":"11:00"},
    {"name":"11:30 AM","value":"11:30"},
    {"name":"12:00 PM","value":"12:00"},
    {"name":"12:30 PM","value":"12:30"},
    {"name":"1:00 PM","value":"13:00"},
    {"name":"1:30 PM","value":"13:30"},
    {"name":"2:00 PM","value":"14:00"},
    {"name":"2:30 PM","value":"14:30"},
    {"name":"3:00 PM","value":"15:00"},
    {"name":"3:30 PM","value":"15:30"},
    {"name":"4:00 PM","value":"16:00"},
    {"name":"4:30 PM","value":"16:30"},
    {"name":"5:00 PM","value":"17:00"},
    {"name":"5:30 PM","value":"17:30"},
    {"name":"6:00 PM","value":"18:00"},
    {"name":"6:30 PM","value":"18:30"},
    {"name":"7:00 PM","value":"19:00"},
    {"name":"7:30 PM","value":"19:30"},
    {"name":"8:00 PM","value":"20:00"},
    {"name":"8:30 PM","value":"20:30"},
    {"name":"9:00 PM","value":"21:00"},
    {"name":"9:30 PM","value":"21:30"},
    {"name":"10:00 PM","value":"22:00"},
    {"name":"10:30 PM","value":"22:30"},
    {"name":"11:00 PM","value":"23:00"},
];
const SpaceEndtime = [
    {"name":"5:00 AM","value":"05:00"},
    {"name":"5:30 AM","value":"05:30"},
    {"name":"6:00 AM","value":"06:00"},
    {"name":"6:30 AM","value":"06:30"},
    {"name":"7:00 AM","value":"07:00"},
    {"name":"7:30 AM","value":"07:30"},
    {"name":"8:00 AM","value":"08:00"},
    {"name":"8:30 AM","value":"08:30"},
    {"name":"9:00 AM","value":"09:00"},
    {"name":"9:30 AM","value":"09:30"},
    {"name":"10:00 AM","value":"10:00"},
    {"name":"10:30 AM","value":"10:30"},
    {"name":"11:00 AM","value":"11:00"},
    {"name":"11:30 AM","value":"11:30"},
    {"name":"12:00 PM","value":"12:00"},
    {"name":"12:30 PM","value":"12:30"},
    {"name":"1:00 PM","value":"13:00"},
    {"name":"1:30 PM","value":"13:30"},
    {"name":"2:00 PM","value":"14:00"},
    {"name":"2:30 PM","value":"14:30"},
    {"name":"3:00 PM","value":"15:00"},
    {"name":"3:30 PM","value":"15:30"},
    {"name":"4:00 PM","value":"16:00"},
    {"name":"4:30 PM","value":"16:30"},
    {"name":"5:00 PM","value":"17:00"},
    {"name":"5:30 PM","value":"17:30"},
    {"name":"6:00 PM","value":"18:00"},
    {"name":"6:30 PM","value":"18:30"},
    {"name":"7:00 PM","value":"19:00"},
    {"name":"7:30 PM","value":"19:30"},
    {"name":"8:00 PM","value":"20:00"},
    {"name":"8:30 PM","value":"20:30"},
    {"name":"9:00 PM","value":"21:00"},
    {"name":"9:30 PM","value":"21:30"},
    {"name":"10:00 PM","value":"22:00"},
    {"name":"10:30 PM","value":"22:30"},
    {"name":"11:00 PM","value":"23:00"},
    {"name":"11:30 PM","value":"23:30"},
    {"name":"12:00 AM","value":"00:00"},
    {"name":"12:30 AM","value":"00:30"},
    {"name":"1:00 AM","value":"01:00"},
    {"name":"1:30 AM","value":"01:30"},
    {"name":"2:00 AM","value":"02:00"},
    
];
function CustomTimeSelect(props) {
    const {classes} = props;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
    };
    return (
        <div>
            <div className="custom_select_with_scroll time_select">
                {props.showLabel &&
                    <div className="label_container">
                        <label>
                            {props.label}
                        </label>
                    </div>
                }
                <Select
                 disabled={props.disable}
                    className={props.styleClass==='browseVenue' ? classes.selectContainerBrowse : classes.selectContainer}
                    classes={props.styleClass==='browseVenue' ? {root: classes.rootBrowse, select: classes.selectBrowse} : {root: classes.root, select: classes.select}}
                    displayEmpty
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.value}
                    name={props.name}
                    input={<Input id="select-multiple-placeholder" />}
                    MenuProps={MenuProps}
                >
                    {props.type==='browseVenue' ? 
                        <div style={{padding:'5px 15px', height: '36px'}}>
                            <em className={props.styleClass==='browseVenue'? classes.placeholderBrowse :classes.placeholder}>{props.placeholder}</em>
                        </div>
                    : <MenuItem disabled value="">
                    <em className={props.styleClass==='browseVenue'? classes.placeholderBrowse :classes.placeholder}>{props.placeholder}</em>
                    </MenuItem>
                    }
                    {props.selectHour ? 
                        hour.map(name => (
                            <MenuItem classes={{root:classes.options}} key={name.name} value={name.value}>
                                {name.name}
                            </MenuItem>
                        ))
                    :
                    props.type==='startTime'||props.type==='endTime'?props.type==='startTime'?
                    SpaceStarttime.map(name => (
                        <MenuItem classes={{root:classes.options}} key={name.name} value={name.value}>
                            {name.name}
                        </MenuItem>
                    )):
                    SpaceEndtime.map(name => (
                        <MenuItem classes={{root:classes.options}} key={name.name} value={name.value}>
                            {name.name}
                        </MenuItem>
                    )):
                        time.map(name => (
                            <MenuItem classes={{root:classes.options}} key={name.name} value={name.value}>
                                {name.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </div>
            <div className="error"><ErrorMessage name={props.name} /></div>
        </div>
    );
}

CustomTimeSelect.propTypes = {
    classes: object.isRequired,
};
  
export default withStyles(styles)(CustomTimeSelect);

import axios from 'axios';
import { GET_PAGE_REQUEST, GET_PAGE_SUCCESS, GET_PAGE_FAILURE } from 'shared/state/types';
function pageRequested() {
  return {
    type: GET_PAGE_REQUEST
  };
}

function pageSuccess(data) {
  return {
    type: GET_PAGE_SUCCESS,
    page_data: data,
  };
}

function pageFailure(error) {
  return {
    type: GET_PAGE_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }


  return axios.create({
    baseURL,
    headers,
  })
};

export function pageDetails(id) {

    return dispatch => {
      dispatch(pageRequested());
      return request().get(`cms/${id}`)
      .then(response =>
        dispatch (pageSuccess(response.data),
        )
      )
      .catch(errors => {
        dispatch(pageFailure(errors.response.data.message));

        throw errors;
      });

 };
}

// import axios from 'axios';
import { GET_SUCCESS_ERROR_SUCCESS } from 'shared/state/types';

function followSuccess(data) {
  return {
    type: GET_SUCCESS_ERROR_SUCCESS,
    followList: data,
  };
}

export function followUnfollowSuccess(params) {
    return dispatch => {

          dispatch(followSuccess('Success'))
    
   

    
 };
}

import React from 'react';
import '../assets/styles/venueProfile.scss';

import VenueCardWithFav from '../../shared/components/VenueCardWithFav';
import { Grid } from '@material-ui/core';
const logo = require("../assets/images/default_venue.png");



function SimilarVanue(props) {



  let featureVenueData=[];
  props.similarVenue.map(function (data, idx) {
  let obj={};
  obj.venueName=data.name;
  let img=data.profilePhoto && process.env.REACT_APP_MEDIA_URL + data.profilePhoto.path;
  if(img==null){
    img=logo;
  }
  let state='';
  if(data.address.state_abbr!==null){
state=", "+data.address.state_abbr;
  }

  obj.image=img;
  obj.venueAddress= data.address && data.address.city + ""+state;
  obj.favourite=data.is_favourite;
  obj.favouriteId = ''
  if(data.is_favourite && data.favourite){
    obj.favouriteId=data.favourite.id;
  }
  obj.venueCity= data.address && data.address.city;
  obj.venueLat=data.address && data.address.geo_lat;
  obj.venueLong=data.address && data.address.geo_lng;
  obj.category="general";
  obj.id=data.slug;
  featureVenueData.push(obj);
  return featureVenueData;
  })
  // let featureVenueData = [
  //   { image: "https://d31ogwdhz811go.cloudfront.net/uploads/defaultImage/5d6535c5232ff373404c90e2730fffee.jpg",
  //     venueName: 'Name of the Space',
  //     venueAddress: 'City & State',
  //     favourite: 'active',
  //     venueCity: '10 MILES',
  //     category: 'Conferences' }
  // ];

  const venueObject = featureVenueData.map(function (data, idx) {
    return (
      <div key={idx} className="similar_venue_card">
        <VenueCardWithFav idx={data.id} type="1" width="330px" height="192px"
          venueLat={data.venueLat}
          venueLong={data.venueLong}
          image={data.image}
          favourite={data.favourite}
          favouriteId={data.favouriteId}
          venueAddress={data.venueAddress}
          venueName={data.venueName}
          venueCity={data.venueCity}
          category={data.category}
          enableLink
          linkTo={"/venue-profile/"+data.id}
        />
      </div>
    );
  });

  return (
    <Grid container justify="center" className="similarContainer">
      <Grid container justify="center">
        <h3 className="similerText">Discover More Spaces</h3>
      </Grid>
      <Grid container justify="center" className="">
        {venueObject}
      </Grid>

    </Grid>
  );
}



export default SimilarVanue;

import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import {
  AppBar,
  Icon,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import './../assets/styles/header.scss';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  browse: {
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '12px'
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  browseIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appbar: {
    backgroundColor: '#ffffff',
    color: '#36434b',
    boxShadow: ' inset 0 -1px 0 0 #dbdbdb',
    position: 'absolute'
  },
  list: {
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    border: 'none',
    margin: '0px 19px 0px 19px',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    '&:focus': {
      background: "#ffffff",
    },
    '&:hover': {
      background: "#ffffff",
    }
  },
  signUp: {
    fontSize: '14px',
    margin: '0px 10px 0px 10px',
    textTransform: 'uppercase',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    fontWeight: 'bold',
    border: 'none',
    '&:focus': {
      background: "#ffffff",
    },
    '&:hover': {
      background: "#ffffff",
    }
  },
  logIn: {
    fontSize: '14px',
    margin: '0px 15px 0px 19px',
    textTransform: 'uppercase',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    fontWeight: 'bold',
    border: 'none',
    '&:focus': {
      background: "#ffffff",
    },
    '&:hover': {
      background: "#ffffff",
    }
  },
  button: {
    backgroundColor: '#1397e1',
    fontFamily: 'Raleway',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: '#ffffff',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#1397e1',
      fontSize: '11px',
      '&:hover': {
        background: '#0a85ca',
      },
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: '#1397e1',
      '&:hover': {
        background: '#0a85ca',
      },
      // width: '152px',
      height: '38px'

    },
  },
  selected: {
    borderBottom: '2px #ee2738 solid',
    backgroundColor: '#ffffff !important'
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
    },
  },
  overWriteLink: {
    textDecoration: 'none',
    color: '#fffff'
  }
});

class Header extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    token: localStorage.getItem('fhtoken')
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = (linkUrl) => {
    if (linkUrl === 'register') {
      this.props.history.push('./register')
    }
    else if (linkUrl === 'login') {
      this.props.history.push('./login')
    }
    else if (linkUrl === 'logout') {
      this.props.history.push('./logout')
    }
    this.setState({ mobileMoreAnchorEl: null });
  };

  render() {
    const { mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem component={Link} to="/planners"  onClick={this.handleMobileMenuClose}>
          <p>For Planners</p>
        </MenuItem>

        <MenuItem component={Link} to="/"  onClick={this.handleMobileMenuClose}>
          <p>For Venues</p>
        </MenuItem>
        
        <MenuItem component={Link} to="/register" onClick={() => this.handleMobileMenuClose('register')}>
          <p>Sign up</p>
        </MenuItem>
        
        <MenuItem component={Link} to="/login" onClick={() => this.handleMobileMenuClose('login')}>
          <p>Login</p>
        </MenuItem>
      </Menu>
    )

    return (
      <div className={classes.root}>
        {(localStorage.getItem('id') === null) ?
          <AppBar className={classes.appbar}>
            <Toolbar>
              <Link className={classes.overWriteLink} to='/'>
                <img className={classes.logo} src={require('./../assets/images/LogoWithText.png')} alt="FIRST-HOLD" />
              </Link>
              <div className={classes.grow} />
              <div className={classes.browse}>
                <Link className={classes.overWriteLink} to="/browseVenue">
                  <Button variant="contained" color="primary" align="left" className={classes.button}>
                    Browse Venues
                  </Button>
                </Link>
              </div>
              <div className={classes.sectionDesktop}>
                <MenuItem className={classes.list} classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/planners'} to="/planners" component={Link}>For Planners</MenuItem>
                <MenuItem className={classes.list} classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/'} to="/" component={Link}>For Venues</MenuItem>
                <MenuItem className={classes.signUp} classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/register'} component={Link} to="/register">Sign up</MenuItem>
                <MenuItem className={classes.logIn} classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/login'} component={Link} to="/login">Login</MenuItem>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                  <Icon>more_vert</Icon>
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          : <AppBar className={classes.appbar}>
            <Toolbar>
              <Link className={classes.overWriteLink} to='/'>
                <img className={classes.logo} src={require('./../assets/images/homepageheaderlogo.png')} alt="FIRST-HOLD" />
              </Link>
              <div className={classes.grow} />
              <div className={classes.browse}>
              </div>
              {(localStorage.getItem('fhtoken') !== undefined) &&
                <div className={classes.sectionDesktop}>
                  <MenuItem className={classes.logIn} classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/logout'} component={Link} to="/logout">Logout</MenuItem>
                </div>
              }
              <div className={classes.sectionMobile}>
                <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                  <Icon>more_vert</Icon>
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        }
        {renderMobileMenu}
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};


const enhance = compose(
  withRouter,
  withStyles(styles)
)
export default enhance(Header);

import axios from 'axios';
import {FIND_GOOGLE_CALENDAR_EVENTS} from 'shared/state/types';
import {loadProgressBar} from 'axios-progress-bar'

function onRequested() {
    return {type: FIND_GOOGLE_CALENDAR_EVENTS.REQUEST};
}

function onSuccess(data) {
    return {type: FIND_GOOGLE_CALENDAR_EVENTS.SUCCESS, eventList: data};
}

function onFailure(error) {
    return {type: FIND_GOOGLE_CALENDAR_EVENTS.FAILURE, error: error};
}

const request = () => {    const headers = {};

    if (localStorage.getItem('msaccesstoken')) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('msaccesstoken');
    }
    const axiosInstance = axios.create({headers})
    loadProgressBar({
        showSpinner: true
    }, axiosInstance)
    return axiosInstance;
};

export function getMicrosoftCalendaEvents(id) {
    
    return dispatch => {
        dispatch(onRequested());
        var d = new Date();
        // var n = d.getFullYear();
        let timeMin = d.getFullYear() + '-' + ((parseInt(d.getMonth()) + 1) > 9
            ? (parseInt(d.getMonth()) + 1)
            : '0' + (parseInt(d.getMonth()) + 1)) + '-' + d.getDate() + 'T00:00:00';
        let timeMax = (parseInt(d.getFullYear()) + 2) + '-' + ((parseInt(d.getMonth()) + 1) > 9
            ? (parseInt(d.getMonth()) + 1)
            : '0' + (parseInt(d.getMonth()) + 1)) + '-' + d.getDate() + 'T00:00:00';

        return request().get('https://graph.microsoft.com/v1.0/me/calendars/'+encodeURIComponent(id)+"/events?top=90&filter=start/dateTime ge '"+timeMin+"' and end/dateTime le '"+timeMax+"'").then(response => {
            if (response.status === 200) {
                dispatch(onSuccess(response.data))
                return response.data;
            } else if (response.status === 400) {
                dispatch(onFailure("The request was invalid."));
            } else if (response.status === 401) {
                dispatch(onFailure("The request did not include an authentication token or the authentication token " +
                        "was expired."));
            } else if (response.status === 403) {
                dispatch(onFailure("The client did not have permission to access the requested resource."));
            } else if (response.status === 404) {
                dispatch(onFailure("The requested resource was not found."));
            } else if (response.status === 405) {
                dispatch(onFailure("The HTTP method in the request was not supported by the resource."));
            } else if (response.status === 409) {
                dispatch(onFailure("The request could not be completed due to a conflict. "));
            } else if (response.status === 500) {
                dispatch(onFailure("The request was not completed due to an internal error on the server side."));
            } else if (response.status === 503) {
                dispatch(onFailure("The server was unavailable."));
            } else {
                dispatch(onFailure("The server was unavailable."));
            }
            dispatch(onSuccess(response.data),)
            return response.data;
        }).catch(errors => {
            if (errors && errors) {
                dispatch(onFailure(errors.toString()));
            } else {
                dispatch(onFailure("Something went wrong."));
            }
            return 0;
        });

    };
}

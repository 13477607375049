// import axios from 'axios';
import { request } from 'shared/auth';

import { GET_BROWSE_VENUE_REQUEST, GET_BROWSE_VENUE_FAILURE, GET_BROWSE_VENUE_SUCCESS } from 'shared/state/types';

function browseVenueRequested() {
  return {
    type: GET_BROWSE_VENUE_REQUEST,
    sending:true,
    sended:false
  };
}

function browseVenueSuccess(data) {

  return {
    sending:false,
    sended:true,
    type: GET_BROWSE_VENUE_SUCCESS,
    browseVenueDetails: data,
  };
}


function browseVenueFailure(error) {
  return {
    sending:false,
    sended:false,
    type: GET_BROWSE_VENUE_FAILURE,
    error:error,
  };
}



export function browseVenue(params,name,type) {
  if(name===undefined && type===undefined){
    name="all";
    type="all";
  }
  if(params==''){
    params = {}
  }
  if(localStorage.getItem('user_lat')){
    params.lat = localStorage.getItem('user_lat')
  }
  if(localStorage.getItem('user_lng')) {
    params.lng = localStorage.getItem('user_lng')
  }
  return dispatch => {
      dispatch(browseVenueRequested());
      if(localStorage.getItem('fhtoken')!=null){
        return request().post(`planner/search/`+name+`/`+type, params)
        .then(response =>
          {dispatch (browseVenueSuccess(response.data),
       )
        return response.data}
        )
        .catch(errors => {
       

          dispatch(browseVenueFailure(errors.response));

          throw errors;
        });
      } else {
        return request().post(`search/`+name+`/`+type,params)
        .then(response =>
          {dispatch (browseVenueSuccess(response.data),
          )
        return response.data}
        )
        .catch(errors => {

          dispatch(browseVenueFailure(errors.response));

          throw errors;
        });
      }
      


    
 };
}

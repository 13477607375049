import React from 'react';

import { Grid } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
//import VenueCardWithFav from '../../shared/components/VenueCardWithFav.js';
import { Suspense, lazy } from "react";
const VenueCardWithFav = lazy(() => import(
  '../../shared/components/VenueCardWithFav.js'))
function FeatureVenues(props) {
  const responsive = {
    0: { items: 1 },
    767: { items: 2.65 },
    1024: { items: 3.65 },
  }
  let checkPage = "";
  let checkTag = "";
  const venueObject = props.featureVenueData && props.featureVenueData.map(function (data, idx) {
    checkPage = data.explore
    checkTag = data.checkTag
    return (
      <Suspense fallback=
      {<div style={{color:"Black",height:"122",width:'200px',textAlign:"center",paddingTop:"50px"}}>Loading...</div>}>          
      <VenueCardWithFav height='122px' width='200px' imageSize='small' addressColor="#1397e1" idx={idx} image={data.image} favourite={data.favourite} venueAddress={data.venueAddress} venueName={data.venueName} category={data.category} enableLink linkTo={"/venue-profile/" + data.id} />
    </Suspense>
      // <VenueCardWithFav height='122px' width='200px' imageSize='small' addressColor="#1397e1" idx={idx} image={data.image} favourite={data.favourite} venueAddress={data.venueAddress} venueName={data.venueName} category={data.category} enableLink linkTo={"/venue-profile/" + data.id} />
    );
  });

  return (
    <Grid container className="feature_venues featured_venue_slider">
      {checkPage != "explore" && <Grid xs={12} container className="top-section">
        <Grid md={6} sm={12} item alignContent="center" className="left-container">
          <h4>Adapts to fit <br /> <span className="bold">​any</span> venue.​</h4>
        </Grid>
        <Grid md={6} sm={12} item className="right-container">
          <p>Nearly every venue we work with has multiple personalities besides just hosting events.​</p>
          <p style={{ marginTop: "12px" }}>Art gallery, museum, winery, theater, cruise ship terminal, bar, nightclub… If you have other uses for your space besides events, we’re built for you, too.​</p>
        </Grid>
      </Grid>}
      <Grid xs={12} item>
        {checkTag ? <h1 className="featured_venue_heading">Popular Unique Event Spaces</h1> : <h1 className="featured_venue_heading">Unique Venues for Events, Meetings, and Weddings</h1>}
      </Grid>
      <Grid xs={12} item className="featured_venue_carousel">
        <AliceCarousel
          items={venueObject}
          responsive={responsive}
          autoPlayInterval={3000}
          autoPlayDirection="lft"
          autoPlay={true}
          fadeOutAnimation={true}
          touchTrackingEnabled={true}
          mouseTrackingEnabled={true}
          mouseDragEnabled={true}
          playButtonEnabled={false}
          disableAutoPlayOnAction={true}
          buttonsDisabled={true}
          dotsDisabled={true}
        />
      </Grid>
    </Grid>
  );
}

export default (FeatureVenues);

import axios from 'axios';
import { GET_HOLD_EVENT_REQUEST, GET_HOLD_EVENT_SUCCESS, GET_HOLD_EVENT_FAILURE } from 'shared/state/types';
// import Cookies from 'js-cookie';
import { loadProgressBar } from 'axios-progress-bar'

function holdEventRequested() {
  return {
    type: GET_HOLD_EVENT_REQUEST
  };
}

function holdEventSuccess(data) {
  return {
    type: GET_HOLD_EVENT_SUCCESS,
    holeEventDetails: data,
  };
}

function holdEventFailure(error,message) {
  return {
    type: GET_HOLD_EVENT_FAILURE,
    error:error,
    message:message,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }
const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export function saveEvent(params, showMessage) {
    return dispatch => {
      dispatch(holdEventRequested());
        return request().post(`event_timeslots`,params)
        .then(response =>
          dispatch (holdEventSuccess(response.data),
                ))
        .catch(errors => {
          if(errors.response.data && errors.response.data.message && showMessage === 'yes'){
            dispatch(holdEventFailure(errors.response.data.message));
         }
         else{
             dispatch(holdEventFailure("",errors.response.data.message));
         }
          throw errors;
        });
    
   

    
 };
}

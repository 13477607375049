import axios from 'axios';
import { FIND_GOOGLE_CALENDAR } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar';

function onRequested() {
  return { type: FIND_GOOGLE_CALENDAR.REQUEST };
}

function onSuccess(data) {
  return { type: FIND_GOOGLE_CALENDAR.SUCCESS, tokenData: data };
}

function onFailure(error) {
  return { type: FIND_GOOGLE_CALENDAR.FAILURE, error: error };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};
  if (localStorage.getItem('fhtoken')) {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');
  }
  const axiosInstance = axios.create({ baseURL, headers })
  loadProgressBar({ showSpinner: true }, axiosInstance);
  return axiosInstance;
};

export function getSyncedETSpaces(space_id) {
  return dispatch => {
    dispatch(onRequested());
    return request().get(`cron/et_spaces_name/${space_id}`)
      .then(response => {
        dispatch(onSuccess(response.data));
        return response.data;
      })
      .catch(errors => {
        if (errors.response && errors.response.data.message) {
          dispatch(onFailure(errors.response.data.message));
        } else {
          dispatch(onFailure("Not able to find this location list"));
        }
        throw errors;
      });
  };
}

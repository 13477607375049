import {
  combineReducers
} from 'redux';
import {
  connectRouter
} from 'connected-react-router';

import auth from 'shared/auth/reducer';
import person from 'data/person/reducer';
import venue from 'shared/venue/reducer';
import planner from 'shared/planner/reducer';
import venueLoadSuggestion from 'shared/home/reducer';
import eventList from 'shared/placeHolds/reducer';
import createEvent from 'shared/createEvent/reducer';
import saveEvent from 'shared/holdEvent/reducer';
import updateEvent from 'shared/updateEvent/reducer';
import spaces from 'data/spaces/reducer';
import venues from 'data/venues/reducer';
import master from 'data/master/reducer';
import cms from 'data/cms/reducer';
import plan from 'data/plan/reducer';
import usersVenue from 'shared/venueList/reducer';
import favourites from 'shared/favourites/reducer';
import contactData from 'shared/contact/reducer';
import featureTypes from 'shared/featureTypes/reducer';
import googleSignUp from 'shared/googleSignUp/reducer';
import browseVenueData from 'shared/search/reducer';
import followDateData from 'shared/followDateData/reducer';
import followAPI from 'shared/followAPI/reducer';
import unFollowAPI from 'shared/unFollowAPI/reducer';
import followUnfollowSuccess from 'shared/followUnfollowSuccess/reducer';
import deleteEvent from 'shared/deleteEvent/reducer';
import pageDetails from 'shared/pageDetails/reducer';
import homepage from 'shared/homepage/reducer';
import googleKeyData from 'shared/googleKeyData/reducer';
import spaceProfile from 'shared/spaceProfile/reducer';
import myHold from 'data/myHold/reducer';
import bookEvent from 'shared/bookEvent/reducer';
import city from 'shared/city/reducer';
import microsoftSignUp from 'shared/microsoftSignUp/reducer';
import unblockEvent from 'shared/unblockEvent/reducer';
import subscribe from 'data/subscribe/reducer';


import saveExit from 'shared/saveExit/reducer';
export default history => combineReducers({
  router: connectRouter(history),
  data: combineReducers({
    auth,
    person,
    venue,
    venueLoadSuggestion,
    eventList,
    createEvent,
    saveEvent,
    updateEvent,
    contactData,
    browseVenueData,
    followDateData,
    followAPI,
    unFollowAPI,
    followUnfollowSuccess,
    venues,
    spaces,
    master,
    usersVenue,
    favourites,
    planner,
    deleteEvent,
    saveExit,
    featureTypes,
    googleSignUp,
    cms,
    plan,
    pageDetails,
    homepage,
    googleKeyData,
    spaceProfile,
    myHold,
    bookEvent,
    city,
    microsoftSignUp,
    unblockEvent,
    subscribe
  }),
});

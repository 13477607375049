import { Record } from 'immutable';

import {
  GET_SPACE_PROFILE_REQUEST,
  GET_SPACE_PROFILE_FAILURE,
  GET_SPACE_PROFILE_SUCCESS,
} from 'shared/state/types';

class State extends Record({
  spaceData:{},
  error:''
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_SPACE_PROFILE_REQUEST:
      return state.merge({
        error:''
      });
    case GET_SPACE_PROFILE_FAILURE:
      return state.merge({
        spaceData:{},
        error: action.error


      });
    case GET_SPACE_PROFILE_SUCCESS:
      return state.merge({
        spaceData:action.spaceDetails,
        error:''
      });

    default:
      return state;
  }
}


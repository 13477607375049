//import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Button } from '@material-ui/core';
import store from 'shared/state/store';

import Routes from './routes.js'

import * as serviceWorker from './serviceWorker';
const isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
const isEdge = navigator.userAgent.toLowerCase().indexOf('edg/') > -1;
const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox/') > -1;
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
//alert(navigator.userAgent.toLowerCase());
//alert(isChrome);
if ("geolocation" in navigator) {
  navigator.geolocation.getCurrentPosition(function(position) {
    localStorage.setItem('user_lat',position.coords.latitude)
    localStorage.setItem('user_lng',position.coords.longitude)
  });
} else {
  
}

if((isChrome || isSafari || isFirefox || isEdge)){
  ReactDOM.render(
    <Provider store={ store() }>
      <Routes/>
    </Provider>,
    document.getElementById('root'),
  );
  
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();

}else {
  ReactDOM.render(
    <div className="message-block">
        <div className="logo-section">
          <img className="logo_image" src={require('shared/components/assets/images/LogoWithText.png')} alt="FIRST-HOLD" title="FIRST-HOLD"/>
        </div>
      <div className="message-block-btm">
        <p className="big-text">
            At FIRST-HOLD, we’re upgrading the venue selection experience for <br></br>everyone: professional planners, caterers and venue managers.
        </p>
        <p className="small-text">
             We believe it should be easy for unique and interesting venues to connect with professional planners who <br></br>are ready to place holds on their space
        </p>
        <p className="smallest-text">Want to learn more?</p>
        <div className="block-btn">
            <Button href="" >Find us on Google</Button>
        </div>
      </div>
      {/* <h2>Thank you for visiting FIRST-HOLD.  The premier venue sourcing platform where you can source the perfect venue for your upcoming event, view availability and place a hold instantly.</h2>
      <h3> We are working to improve the FIRST-HOLD experience and will be live on Firefox soon.  In the interim, you can visit FIRST-HOLD using Chrome and Safari.</h3>
      <h4>Thank you for your patience.</h4>   */}
    </div>,
    document.getElementById('root'),
  );
  
}


import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import moment from 'moment';
import VenueFooter from 'shared/footer/venueFooter';
import ReactHtmlParser from 'react-html-parser';

// import { getHolds } from 'data/myHold/actions';

import TabPanel from '../components/myHoldTab';

import { Grid } from '@material-ui/core';

import '../assets/styles/myHoldsContent.scss';
import { Helmet } from "react-helmet";

class MyTerms extends Component {
  state = {
    loading:false
  }
  UNSAFE_componentWillMount() {
    this.setState({loading:true})
    let t = this;
    setTimeout(function () {
      t.setState({ loading: "loaded" })
    }, 2000)
    // this.props.getHolds(14).then(() => {
    //   this.setState({loading:"loaded"})
    // })
  }
  handleChangeContent = (val) => {
    this.setState({ content: val })
  }
  default_data=(val)=>{
    this.setState({content:val})
  }
  render() {
    // const oneDay = 24 * 60 * 60 * 1000;
    // const currentDate = new Date()
    this.props.holds && this.props.holds.event_timeslots && this.props.holds.event_timeslots.length > 0 && this.props.holds.event_timeslots.map((data, index) => {
      // const startDate = moment(data.created_at).format("MMM DD, YYYY").toString()
      // const expiredDate = data.expired_on !== null ? moment(data.expired_on).format("MMM DD, YYYY").toString() : ''
      // const createdAt = moment.utc(data.created_at, "YYYY-MM-DD HH:mm:ss").toDate();
      // const diffDate = Math.round(Math.abs((currentDate - createdAt) / oneDay))
      return (
        <div className="my-hold">
        
        </div>


      )
    })
    return (
      <div>
      <div className="my_hold_page terms termsPage">
      <Helmet>
          <meta name="description" content="User understands and agrees that they are responsible for acquiring the rights to use any/all photos that have been uploaded to their profile on first-hold."></meta>
        </Helmet>

        {this.state.loading!=='loaded'?
          <div style={{    textAlign: 'center', paddingTop: '100px',height:'300px',widht:'100%'}}>
            <p>Loading...</p></div>


          :
          // <div style={{height:'200px',widht:'100%'}}>
          // <p>Loading...</p></div>
          <Grid container alignItems="center">
            <Grid className="myhold-header ">
              <div className="filter-tabs">
                <TabPanel pathname={this.props.location.pathname} default_data={this.default_data} handleChangeContent={this.handleChangeContent} />
              </div>
            </Grid>
            <Grid style={{width:"100%"}}>
              <div className="para-content">
                {this.state.content ?
                  <div>{ReactHtmlParser(this.state.content)}</div>
                  :
                  <div style={{ textAlign: 'center', paddingTop: '100px', height: '250px', widht: '100%' }}>
                    <p>Loading...</p></div>
                }
              </div>
            </Grid>
          </Grid>
        }        
        </div>
        <VenueFooter className="fh-container" />
        {/* <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
          <Grid container justify="center">
            <VenueFooter />
          </Grid>
        </Grid> */}
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    // holds: state.data.myHold.holds
  };
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    // getHolds
  }, dispatch),
)(withRouter(MyTerms));

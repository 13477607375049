import { EDIT_VENUE } from 'shared/state/types';
import { request } from 'shared/auth';

function editVenueRequested() {
  return {
    type: EDIT_VENUE.REQUEST,
  };
}

function editVenueFailed(error) {
  return {
    type: EDIT_VENUE.FAILURE,
    error:error,
  };
}

function editVenueSuccess(data) {
  return {
    type: EDIT_VENUE.SUCCESS,
    venueData: data,
  };
}

export function editVenue(params,venue_id) {
  return dispatch => {
    dispatch(editVenueRequested());

    return request().put(`/venues/${venue_id}`, params)
      .then(response =>{
          dispatch(editVenueSuccess(response.data.data))
          return response.data.data
        }
      )
      .catch(errors => {
        dispatch(editVenueFailed(errors.response.data.errors));
        throw errors;
      });
  };
}

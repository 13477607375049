import { EMAIL_EXISTS } from 'shared/state/types';
import { request } from 'shared/auth';

function emailExistsRequested() {
  return {
    type: EMAIL_EXISTS.REQUEST,
  };
}

function emailExistsFailed(error) {
  return {
    type: EMAIL_EXISTS.FAILURE,
    error:error,
  };
}

function emailExistsSuccess(data) {
  return {
    type: EMAIL_EXISTS.SUCCESS,
    emailExistsMessage: data,
  };
}

export function emailExists(params) {
  return dispatch => {
    dispatch(emailExistsRequested());

    return request().post('/auth/email_exist', params)
      .then(response =>
        {
        dispatch(emailExistsSuccess(response.data.message))
        return(response.data.message)
      }
      )
      .catch(errors => {
        dispatch(emailExistsFailed(errors.response));

        throw errors;
      });
  };
}

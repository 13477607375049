import React from 'react';
import { object } from 'prop-types';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import { withStyles } from '@material-ui/core/styles';
import { Grid, Icon } from '@material-ui/core';
import { markFav } from 'shared/planner/actions';
import { deleteFav } from 'shared/planner/actions';



import './assets/style/VenueCardWithFav.scss';

const styles = theme => ({
  like_icon: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  iconImage: {
    height: "16px",
    width: "16px !important"
  },
  favIcon: {
    fontSize: '24px',
    textShadow: '0px 0px #dfd8d8',
    color: '#ffffff',
    margin :'10px'

  },
  activeFav: {
    fontSize: '24px',
    textShadow: '0px 0px #ff0000',
    color: '#FF0000',
    margin :'10px'
  }
});

class VenueCardWithFav extends React.Component {
  // constructor(props, context) {
  //   super(props, context);
  // }

  state = {
    favourite: this.props.favourite,
    favouriteId: this.props.favouriteId
  };

  markFav = () => {

     if(this.state.favourite===true) {
      //Delete favorite
 
      this.props.deleteFav(this.state.favouriteId)
        .then(() => {
          if(this.props.removeFavourite)
          {
            this.props.removeFavourite(this.state.favouriteId);
          }
          this.setState({ favouriteId:'' });

        })
        .catch(error => {})
     } else {
       //Add favorite 
       const favData = {
        favourable_type: parseInt(this.props.type) === 1 ? 'Venue': 'Space',
        favourable_id: this.props.idx
      }
       if(localStorage.getItem('fhtoken')){
        this.setState({favourite:true})   
  
    
       this.props.markFav(favData)
         .then((response) => {
           this.setState({ favourite: true, favouriteId: response.data.data.id });
 
         })
         .catch(error => {})
      }
      else{
        localStorage.setItem('venueId',JSON.stringify(favData));
          if(window.location.pathname.includes('browseVenue')===true && process.env.REACT_APP_WEB_URL !== undefined){
            // window.location.href =process.env.REACT_APP_WEB_URL+'login';
            const path = process.env.REACT_APP_WEB_URL+'login'
            this.props.history.push(path)
          }
          else{
            // window.location.href ='login';
            this.props.history.push('/login')

          }

       }
       }
   
      
  }

  getDistanceFromLatLonInKm = (lat1,lon1,lat2,lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2-lon1);
    var a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c; // Distance in km
    d=d* 0.621371;//distance in miles

    return d;
  };

  deg2rad = (deg) => {
    return deg * (Math.PI/180)
  };



  render() {
    const { classes } = this.props;
    return (
      <div className="venueCardWithFav" style={{position:'relative'}}>
        {this.props.enableLink ?
        <div>
           <Grid className="overlay_icon" container justify="flex-end" style={{right:'0px',top:'0px',position: 'absolute','z-index': '99',width: 'auto' }}>
           <div style={{cursor:'pointer'}} onClick={this.markFav ? this.markFav:null}>
             <Icon className={this.state.favourite === true ? classes.activeFav : classes.favIcon}>
                 {this.state.favourite === true ? 'favorite' : 'favorite_border'}
               </Icon>
           </div>
         </Grid>
          <Link onClick={this.props.onClickEvent ? ()=>this.props.onClickEvent(this.props.id):''} key={this.props.idx} to={this.props.linkTo} params={this.props.event}>
            <div key={this.props.idx} className="venue">
   
              <img className="venue_image" style={{objectFit:'cover', height: this.props.height, width: this.props.width }} src={this.props.image} alt="Venue" />
              <Grid container className="venue_content">
                <Grid item xs={12} className="venue_name">
                  {this.props.venueName}
                  </Grid>
                  <Grid item xs={12} className="venue_name">

                  {this.props.vs_name?this.props.vs_name:null}

                {this.props.venueSpace===true?  <p style={{margin:'0px',fontSize:'13px'}}>{this.props.max_seat} Max</p>:null}
                </Grid>
                <Grid item xs={12} className="venue_address" style={{ color: this.props.addressColor }}>
                  {this.props.venueAddress}
                </Grid>
                {this.props.venueCity && localStorage.getItem('lat') && localStorage.getItem('long') ? (
                  <Grid item xs={12} className="venue_city">
                    <Icon className="place_icon">
                      place
                    </Icon>
                    {Math.floor(this.getDistanceFromLatLonInKm(localStorage.getItem('lat'), localStorage.getItem('long'), this.props.venueLat, this.props.venueLong))}
                  </Grid>) : ('')
                }
              </Grid>
              {this.props.isInputAddress &&
                <Grid item xs={12} className="input_address">
                  <Icon className="place_icon">
                    place
                  </Icon>
                  Input address
                </Grid>
              }
            </div>
          </Link>
         </div>
         :
          <div key={this.props.idx} className="venue">

            <Grid className="overlay_icon" container justify="flex-end" style={{ width: this.props.width }}>
              <Icon className={this.props.favourite === true ? classes.activeFav : classes.favIcon}>
                {this.props.favourite === true ? 'favorite' : 'favorite_border'}
              </Icon>
            </Grid>
            <img className="venue_image" style={{ height: this.props.height, width: this.props.width }} src={this.props.image} alt="Venue" />
            <Grid container className="venue_content">
              <Grid item xs={12} className="venue_name">
                {this.props.venueName}
                </Grid>
                  <Grid item xs={12} className="venue_name">

                {this.props.vs_name?this.props.vs_name:null}
              </Grid>
              <Grid item xs={12} className="venue_address" style={{ color: this.props.addressColor }}>
                {this.props.venueAddress}
              </Grid>
              {this.props.venueCity ? (<Grid item xs={12} className="venue_city">
                {this.props.venueCity}</Grid>) : ('')}
            </Grid>
            {this.props.isInputAddress &&
              <Grid item xs={12} className="input_address">
                <Icon className="place_icon">
                  place
                      </Icon>
                Input address
                  </Grid>
            }
          </div>
        }
      </div>
    );
  }
}

VenueCardWithFav.propTypes = {
  classes: object.isRequired,
};

const mapStateToProps = state => {
  return {
    markFavMessage: state.data.auth.markFavMessage,
    deleteFavMessage: state.data.auth.deleteFavMessage,
  };
};

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    dispatch =>
      bindActionCreators(
        {
          markFav,
          deleteFav
        },
        dispatch
      )
  )
);
export default enhance(VenueCardWithFav);

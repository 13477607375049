import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Grid,  Icon } from '@material-ui/core';

import  ReactBnbGallery  from 'react-bnb-gallery';

import './assets/style/ModalBox.scss';
import './assets/style/gallery.scss';

const styles = theme => ({
  root: {
    margin: 0,
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
      fontWeight: '500',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.46px',
      color: 'black'
    }
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: '6px',
      border: 'solid 1px black',
    }
  }
});

const DialogContent = withStyles(theme => ({
  root: {
  },
}))(MuiDialogContent);


class Gallery extends React.Component {
  // constructor(props, context) {
  //   super(props, context);
  // }

  state = {
    open: false,
    galleryOpened: false
  };



  handleClickOpen = () => {
    if(this.props.viewPhotoClick){
      this.props.viewPhotoClick() 
    }
    this.setState({ open: true, galleryOpened: true });
  };

  toggleGallery = () => {
    this.setState(
      prevState => ({
      // galleryOpened: !prevState.galleryOpened,
      open: false, 
      galleryOpened: false
      })
    );
  };

  render() {
    const { classes } = this.props;
    console.log('sss',this.props.photos);
    return (
      <div class="venue-gallery">
        {this.props.name!=="space" ? 
          <Button variant="outlined" onClick={this.handleClickOpen} classes={{
            label: classes.label,
            root: classes.button
          }}>
          {this.props.label}
          </Button>
          :
          <Icon onClick={this.handleClickOpen} className={this.props.type==="eye_icon"}>remove_red_eye</Icon>
        } 
        {this.props.viewPhotoClick?
        this.props.viewPhoto=== true ?         
        <Grid item xs={12}>
          <ReactBnbGallery
            show={this.state.galleryOpened}
            activePhotoIndex={this.props.index}
            preloadSize={3}
            startIndex={2}
            photos={this.props.photos}
            onClose={this.toggleGallery}
            showThumbnails={true} /> 
        </Grid>:''
        :
        <Grid item xs={12}>
          <ReactBnbGallery
            show={this.state.galleryOpened}
            activePhotoIndex={this.props.index}
            preloadSize={3}
            startIndex={2}
            photos={this.props.photos}
            onClose={this.toggleGallery}
            showThumbnails={true} /> 
        </Grid>
       }
      </div>
    );
  }
}

Gallery.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles)
)
export default enhance(Gallery);

import React from 'react';
import { object } from 'prop-types';

import { Field, ErrorMessage } from 'formik';
import { withStyles } from '@material-ui/core/styles';

import '../assets/styles/addSpace.scss';

const styles = theme => ({
    root: {
        padding:'0px 5px',
        borderRadius: '6px',
        border: 'solid 1px #f0f0f0',
        backgroundColor: '#f7f7f7',
        fontSize: '15px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.07',
        letterSpacing: 'normal',
        color: '#b0b0b0',
        boxShadow: 'none',
        width: '92px',
        height: '37px',
        textAlign: 'center'
    }
});

function SpaceArrangement(props) {
    const {classes} = props
    return (
        <div className="space_arrangement">
            <div className="space_arrangement_description">{props.min ? 'Ample space per guest' : 'Space is full to capacity'}</div>
            {props.min ? 
                <img className="space_image" src={require('../assets/images/minimum.svg')} alt="Arrangement"/>
                 : <img className="space_image" src={require('../assets/images/maximum.svg')} alt="Arrangement"/>
            }
            <div className="min_max">{props.min ? 'Minimum' : 'Maximum'}</div>
            <Field
                className={classes.root}
                name={props.min ? props.minName : props.maxName}
            />
            {props.error ?
                <div className="error">{props.error}</div>
                :
                <div className="error"><ErrorMessage name={props.min ? props.minName : props.maxName} /></div>
            }
        </div>
    );
}

SpaceArrangement.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(SpaceArrangement);
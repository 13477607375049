import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { IconButton, Icon } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
// import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import {
Formik,
ErrorMessage,
// Field,
Form
} from 'formik';
import "./assets/style/placeHold.scss";
// import SettingsIcon from '@material-ui/icons/Settings';
import CustomizedButton from '../components/customizedButton';
import { saveExit } from '../saveExit/actions';
import { object, func } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import CustomSelect from "../components/CustomSelect";

const styles = theme => ({
root: {
margin: 0,
},
closeButton: {
position: 'absolute',
top: '8px',
color: '#9e9e9e',
right: '8px',
},
});

withStyles(styles)(props => {
const { children, classes, onClose } = props;
return (
<MuiDialogTitle disableTypography className={classes.root}>
<Typography variant="h6">{children}</Typography>
{onClose ? (
<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
<CloseIcon />
</IconButton>
) : null}
</MuiDialogTitle>
);
});

const DialogContent = withStyles(theme => ({
root: {
},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
root: {
},
}))(MuiDialogActions);

class ModalBoxSetting extends React.Component {
state = {
open: false,
};

handleClickOpen = () => {
this.setState({
open: true,
});
};

handleClose = () => {
  this.setState({open:false})
};
submitClick=(values)=>{
  let hold_duration;
  let max_hold;
  if(values.hold_duration===''){
    hold_duration=this.props.hold_duration;
  }
  else{
    hold_duration=values.hold_duration;

  }
  if(values.max_hold===''){
    max_hold=this.props.max_hold;
  }
  else
  {
  max_hold=values.max_hold;
  }

  if(values.hold_duration==='No Limit'){
    hold_duration=10;
  }
  if(values.max_hold==='No Limit'){
    max_hold=10;
  }
let json={
hold_duration:hold_duration,
max_hold_limit:max_hold
}
this.props.saveExit(this.props.venueId,json).then(() => {
  this.handleClose();
  this.setState({open:false})
})
if(this.props.settingClick)
{
  let data={
    id:this.props.venueId,
    max_hold_limit:max_hold,
    hold_duration:hold_duration
  }
  this.props.settingClick(data)
}
}
render() {
// const validationSchema = Yup.object().shape({
// max_hold: Yup.string()
// .required('*Required'),
// hold_duration: Yup.string()
// .required('*Required')
// });
return (
<div className="h">

<Icon name="modalBoxforSetting" id="modalBoxforSetting"
onClick={()=>{this.props.settingClick(this.props.data);this.handleClickOpen()}} 
style={{cursor:"pointer", color: 'black',fontSize: '15px'}}>H</Icon>

{/* <Button variant="outlined" color="secondary" onClick={this.handleClickOpen}>
Open dialog
</Button> */}
<Dialog 

className="success_modal_container hold_settings h-popup"
aria-labelledby="customized-dialog-title"
open={this.state.open} >
<DialogActions className="dialogActions">
<span
className="material-icons close closeButton"
onClick={this.handleClose}
>
close
</span>
</DialogActions>
<DialogContent className="dialog-content">
<Grid container className="modalBox_content">
<Grid item xs={12}>
<Grid container className="boxMatter">
<Grid item xs={12}>&nbsp;</Grid>
<Grid item xs={12}>
<div className="dont_single">HOLD SETTINGS</div>
<p style={{textAlign:'center'}}>Settings will apply to holds at each event space</p>
</Grid>
<Grid item xs={12}>&nbsp;</Grid>
<Grid item md={12} sm={12}>
<div className="dont_p">
<Formik
// validationSchema={validationSchema}
initialValues={{max_hold:'',hold_duration:''}}
onSubmit={(values, actions) => {
this.submitClick(values)
}}
>
{({ isSubmitting }) => (
<Form>
<label>Hold Duration - </label>
<label>{this.props.hold_duration==0?'Unlimited':this.props.hold_duration} Days</label>

<Grid container justify="center">
<CustomSelect

id="hold_duration"
name="hold_duration"
color="#62717a"
width="100%"
data={[{id:'1',value:'1'},
{id:'2',value:'2'},
{id:'3',value:'3'},
{id:'4',value:'4'},
{id:'5',value:'5'},
{id:'6',value:'6'},
{id:'7',value:'7'},
{id:'14',value:'14'},
{id:'30',value:'30'},
{id:'60',value:'60'},
{id:'90',value:'90'},
{id:'Unlimited',value:'0'}]}
placeholder="In Days" /> 
<p style={{fontSize: '12px',
    paddingTop: '0px',
    lineHeight: '20px',
    marginTop: '0px'}}>Planners will receive a notification if a hold is expiring and can request an extension.</p>
<div className="error"><ErrorMessage name="hold_duration" /></div>

</Grid>
<br />
<label>Maximum Hold Limit - </label>
<label>{this.props.max_hold}</label>

<Grid container justify="center">
<CustomSelect
id="max_hold"
name="max_hold"
color="#62717a"
width="100%"
data={[
{id:'2',value:'2'},
{id:'3',value:'3'},
{id:'4',value:'4'},
{id:'5',value:'5'},
{id:'6',value:'6'},
{id:'7',value:'7'},
]}
placeholder="Hold Count" /> 

<p style={{fontSize: '12px',
    paddingTop: '0px',
    lineHeight: '20px',
    marginTop: '0px',
    width:'100%'}}>Maximum number of holds allowed per date/time</p>
<div className="error"><ErrorMessage name="max_hold" /></div>
<br />

</Grid> 
<Grid container justify="center">
<CustomizedButton type="submit" label='Submit'/>
</Grid>
</Form>

)}
</Formik>
</div>
</Grid>
<Grid item xs={12} md={12} sm={12}>
<DialogActions className="action-center">
</DialogActions>
</Grid>
</Grid>
</Grid>


</Grid>

</DialogContent>


</Dialog>

</div>
);
}
}

ModalBoxSetting.propTypes = {
classes: object.isRequired,
saveExit: func.isRequired
};

const mapStateToProps = state => {
return {

};
};

const enhance = compose(
withRouter,
connect(mapStateToProps,dispatch =>
bindActionCreators(
{
saveExit

},
dispatch
)
)
);
export default enhance(ModalBoxSetting);


import { Record } from 'immutable';

import {
  GET_EVENT_REQUEST,
  GET_EVENT_FAILURE,
  GET_EVENT_SUCCESS,
  GET_EVENT_TYPE_REQUEST,
  GET_EVENT_TYPE_FAILURE,
  GET_EVENT_TYPE_SUCCESS
} from 'shared/state/types';

class State extends Record({
  eventTypeDetails:{},
  eventDetails:{},
  createEvent:{},
  error:'',
  sending:'',
  sended:''
  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_EVENT_TYPE_REQUEST:
      return state.merge({
        error:''

      });
    case GET_EVENT_TYPE_FAILURE:
      return state.merge({
        eventTypeDetails:{},
        error: action.error


      });
    case GET_EVENT_TYPE_SUCCESS:
      return state.merge({
        eventTypeDetails:action.eventTypeDetails,
        error:'Success'
      });


      case GET_EVENT_REQUEST:
      return state.merge({
        error:''
      });
    case GET_EVENT_FAILURE:
      return state.merge({
        eventDetails:{},
        error: action.error


      });
    case GET_EVENT_SUCCESS:
      return state.merge({
        eventDetails:action.eventDetails,
        error:'Success'
      });

      
    default:
      return state;
  }
}


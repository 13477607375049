import React from 'react';



import { Grid, Icon } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { object } from 'prop-types';


import Gallery from 'shared/components/gallery';
import { Field, ErrorMessage } from 'formik';
import '../assets/styles/imageUploadPopUp.scss';
import '../../shared/components/assets/style/ModalBox.scss';
import '../../shared/components/assets/style/gallery.scss'

// class Item extends React.Component {
//     render() {
//       return (
//         <li {...this.props}>
//             <div className="uploaded_screen">
//                 <Grid container className="image_details">
//                     <Grid item className="drag_icon_container" xs={3} sm={1} md={1} lg={1} xl={1}>
//                         <Icon className={this.props.classes.handle}>drag_handle</Icon>
//                     </Grid>
//                     <Grid item xs={8} sm={3} md={3} lg={3} xl={3}>
//                         <Grid container>
//                             <Grid item xs={12}>
//                                 <img
//                                     className="venue_image"
//                                     src={this.props.children}
//                                     alt="Venue"
//                                 />
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                     <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
//                     <Grid item xs={5}>

//                     </Grid>
//                     <Grid className="delete_icon_container" item xs={1}>
//                         <Icon className={this.props.classes.deleteIcon} onClick={e => { this.props.deleteImage && this.props.deleteImage(this.props.children) }}>delete</Icon>
//                     </Grid>
//                 </Grid>
//             </div>
//         </li>
//       )
//     }
//   }

// const styles = theme => ({
//     root: {
//       margin: 0,
//     },
//     label: {
//       [theme.breakpoints.down('xs')]: {
//         fontSize: '13px',
//         fontWeight: '500',
//         fontStyle: 'normal',
//         fontStretch: 'normal',
//         lineHeight: 'normal',
//         letterSpacing: '0.46px',
//         color: 'black'
//       }
//     },
//     button: {
//       [theme.breakpoints.down('xs')]: {
//         borderRadius: '6px',
//         border: 'solid 1px black',
//       }
//     }
//   });

// const DialogContent = withStyles(theme => ({
//   root: {
//   },
// }))(MuiDialogContent);

// var SortableItem = sortable(Item);
class ImageSort extends React.Component {

  // state = {
  //   items: this.props.items
  // };

  // onSortItems = (items) => {
  //   this.setState({
  //     items: items
  //   });
  // }

  state = {
    open: false,
    galleryOpened: false
  };

  handleClickOpen = () => {
    this.setState({ open: true, galleryOpened: true });
  };
  handleChangeName = (e, index) => {
    if(!(this.props.venueData && this.props.venueData.profilePhoto && parseInt(this.props.venueData.profilePhoto.id) === parseInt(this.props.id))){
    this.props.updateName(e.target.value, index)
    }
  };
  handleChangeWebsite = (e, index) => {
    if(!(this.props.venueData && this.props.venueData.profilePhoto && parseInt(this.props.venueData.profilePhoto.id) === parseInt(this.props.id))){
    this.props.updateWebsite(e.target.value, index)
    }
  };

  toggleGallery = () => {
    this.setState(
      prevState => ({
        // galleryOpened: !prevState.galleryOpened,
        open: false,
        galleryOpened: false
      })
    );
  };


  render() {
    // const { classes } = this.props;

    const photo = [];
    let len = this.props.photos && this.props.photos.length;

    for (let i = 0; i < len; i++) {
      let obj = {};
      obj.photo =
        this.props.photos && process.env.REACT_APP_MEDIA_URL + this.props.photos[i].path;
      obj.thumbnail =
        this.props.photos && process.env.REACT_APP_MEDIA_URL + this.props.photos[i].path;
      photo.push(obj);
    }
    // const { items } = this.state;
    // var listItems = items.map((item, i) => {
    //   return (
    //     <SortableItem
    //       key={i}
    //       onSortItems={this.onSortItems}
    //       items={items}
    //       sortId={i}
    //       deleteImage={this.props.deleteImage}
    //       classes={this.props.classes}>{item}</SortableItem>
    //   );
    // });

    return (
      // <ul className='sortable-list'>
      //   {listItems}
      // </ul>
      <div className="uploaded_screen">
        <Grid container className="image_details">
          <Grid item className="drag_icon_container" xs={3} md={2} lg={1}>
            <Icon title="Drag to change order" className={this.props.classes.handle}>drag_handle</Icon>
          </Grid>
          <Grid item xs={6} md={6} lg={7} className="edit-photos-image">
            <Grid container alignItems="center" justify="center">
              <Grid item xs={5} md={5} className="v_image_container" >
                <img
                  className="venue_image"
                  src={this.props.items}
                  title="Drag to change order"
                  alt="Venue"
                />
              </Grid>
              <Grid item xs={7} md={7}>
                <Field type="hidden" name={`photographer.${this.props.index}.id`} />
                <div class="custom_input">
                  <Field type="text" name={`photographer.${this.props.index}.p_name`} placeholder="Photographer Name" onInput={e => { this.handleChangeName(e, this.props.index) }} />
                  <div className="error"><ErrorMessage name={`photographer.${this.props.index}.p_name`} /></div>
                </div>
                <div class="custom_input">
                  <Field type="text" name={`photographer.${this.props.index}.p_website`} placeholder="Photographer Website" onInput={e => { this.handleChangeWebsite(e, this.props.index) }} />
                  <div className="error"><ErrorMessage name={`photographer.${this.props.index}.p_website`} /></div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                    <Grid item xs={5}>

                    </Grid> */}
          <Grid className="delete_icon_container" item xs={3} md={4} lg={4} >
            <a href={this.props.items} download>
              <Button variant="outlined">
                <Icon className={this.props.type === "eye_icon"}>get_app</Icon>
              </Button>
            </a>

            {/* <Button variant="outlined" onClick={()=>this.handleClickOpen(this.props.items)}>
                            <Icon className={this.props.type==""}>remove_red_eye</Icon>
                        </Button> */}
            <Gallery name={this.props.name} label="View Photos" photos={photo} index={this.props.index} />


            {this.props.id ?
              this.props.venueData && this.props.venueData.profilePhoto && parseInt(this.props.venueData.profilePhoto.id) === parseInt(this.props.id)
                ?
                <div style={{ width: 'auto' }} className="grey-delete">
                  <Icon className={this.props.classes.deleteIcon} style={{ color: 'grey' }}>delete</Icon>
                  <p style={{ fontSize: '10px' }}>Change this photo in Edit Profile</p>
                </div>
                :
                <Icon className={this.props.classes.deleteIcon} onClick={e => { this.props.deleteImage && this.props.deleteImage(this.props.id) }}>delete</Icon>
              :
              <Icon className={this.props.classes.deleteIcon}>delete</Icon>


            }
          </Grid>
        </Grid>
      </div>
    )
  }
};
ImageSort.propTypes = {
  classes: object.isRequired,
};
export default ImageSort;

import axios from 'axios';
import { GET_EVENT_REQUEST, GET_EVENT_SUCCESS, GET_EVENT_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function eventRequested() {
  return {
    type: GET_EVENT_REQUEST
  };
}

function eventSuccess(data) {
  return {
    type: GET_EVENT_SUCCESS,
    eventDetails: data,
  };
}

function eventFailure(error) {
  return {
    type: GET_EVENT_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }

  
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export function eventList(params) {
  let link='';
  if(params=='past'){
    link=`events?past=0`;
  }
  else{
    link=`events`
  }
    return dispatch => {
      dispatch(eventRequested());
        return request().get(link)
        .then(response =>
          dispatch (eventSuccess(response.data),
                ))
        .catch(errors => {
          dispatch(eventFailure(errors.response));
  
          throw errors;
        });
    
   

    
 };
}

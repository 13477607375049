import { GET_SPACE_AVAILABILITY } from 'shared/state/types';
import { request } from 'shared/auth';

function getSpaceAvailabilityRequested() {
  return {
    type: GET_SPACE_AVAILABILITY.REQUEST,
  };
}

function getSpaceAvailabilityFailed(error) {
  return {
    type: GET_SPACE_AVAILABILITY.FAILURE,
    error: error,
  };
}

function getSpaceAvailabilitySuccess(data) {
  return {
    type: GET_SPACE_AVAILABILITY.SUCCESS,
    spaceAvailabilityData: data,
  };
}

export function getSpaceAvailability(spaceId) {
  return dispatch => {
    dispatch(getSpaceAvailabilityRequested());

    return request().get(`/space_availables?space_id=${spaceId}`)
      .then(response =>{
          if(response.status === 200){
            dispatch(getSpaceAvailabilitySuccess(response.data))
            return response.data
          }
          else if(response.status === 400){
            dispatch(getSpaceAvailabilityFailed("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(getSpaceAvailabilityFailed("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(getSpaceAvailabilityFailed("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(getSpaceAvailabilityFailed("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(getSpaceAvailabilityFailed("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(getSpaceAvailabilityFailed("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(getSpaceAvailabilityFailed("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(getSpaceAvailabilityFailed("The server was unavailable."));
          }
          else{
            dispatch(getSpaceAvailabilityFailed("The server was unavailable."));
          }
        }
      )
      .catch(errors => {
          if(errors.response && errors.response.data && errors.response.data.errors){
            dispatch(getSpaceAvailabilityFailed(errors.response.data.errors));
          }
          else{
            dispatch(getSpaceAvailabilityFailed('Unable to access api'));
          }
        throw errors;
      });
  };
}

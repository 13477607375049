import { request } from 'shared/auth';
import { MARK_FAV} from 'shared/state/types';

function markFavRequest() {
    return {
        type: MARK_FAV.REQUEST,

    };
}
function markFavSuccess(data) {
    return {
        type: MARK_FAV.SUCCESS,
        message: data,
    };
}
function markFavFailure() {
    return {
        type: MARK_FAV.FAILURE
    };
}

export function markFav(params) {
    return dispatch => {
        dispatch(markFavRequest());
        return request()
            .post('/favourites', params)
            .then(response => {
                dispatch(markFavSuccess(response.data.data));
                return response
            })
            .catch(error => {
                dispatch(markFavFailure());
            })
    };
}

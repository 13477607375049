import React from 'react';

import { Grid } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import '../../shared/components/assets/style/seatingAndCapacity.scss';

class SeatingAndCapacity extends React.Component {
  
    items = this.props.spaceInfo && this.props.spaceInfo.arrangements

    state = {
        currentIndex: 0,
        itemsInSlide: 3,
        responsive: { 768 : { items: 3 } },
        galleryItems: this.galleryItems(),
    }

    slidePrevPage = () => {
        const currentIndex = this.state.currentIndex - this.state.itemsInSlide
        this.setState({ currentIndex })
      }
     
    slideNextPage = () => {
        const {
          itemsInSlide,
          galleryItems: { length },
        } = this.state
        let currentIndex = this.state.currentIndex + itemsInSlide
        if (currentIndex > length) currentIndex = length
     
        this.setState({ currentIndex })
      }

      handleOnSlideChange = (event) => {
        const { itemsInSlide, item } = event
        this.setState({ itemsInSlide, currentIndex: item })
      }
    
    galleryItems() {
   
         return this.items.map((data) => 

         <div style={{'backgroundColor':'#f7f7f7','borderRadius':'5px','border':'1px solid #c4d0d9','maxWidth': '100%' ,'margin':'0 0px'}}  className={this.items.length===1?"single-img-max":"multiple-img"}>
         <h6 style={{'margin': '5px 0px 4px 0px','textAlign':'center','fontSize':'13px','fontWeight':'600','fontFamily':'Raleway'}}>{data.name}</h6>
             <div style={{'margin': '7px 0px 0px 14px','width': '80%','height': '114px'}}>
                  {  data.name === 'Sit-Down' ?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}}  src={require('./assets/images/sitdown.png')} alt={data.name} />
                    :data.name === 'Classroom' ?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}}  src={require('./assets/images/classroom.png')} alt={data.name} />
                    :data.name === 'Boardroom'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}}  src={require('./assets/images/boardroom.png')} alt={data.name} />
                    :data.name === 'Theater'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('./assets/images/theater.png')} alt={data.name} />
                    :data.name === 'Reception'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('./assets/images/reception.png')} alt={data.name} />
                    :data.name === 'Tradeshow'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('./assets/images/tradeshow.png')} alt={data.name} />
                    :data.name === 'Pre-Existing Arrangement'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('./assets/images/pre-arranged.png')} alt={data.name} />
                    :data.name === 'Empty'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('./assets/images/empty.png')} alt={data.name} />
                    :data.name === 'Wedding'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('./assets/images/Wedding.png')} alt={data.name} />
                    :null
                  }
             </div>
         <h6 style={{'fontFamily':'Raleway' , 'margin': '5px 0px 4px 0px','textAlign':'center','fontSize':'13px','fontWeight':'600'}}>
       
         {data.pivot.minimum ?
           data.pivot.minimum +' - '+ data.pivot.maximum :
            data.pivot.maximum}
            {data.name==='Empty'?
            ' Max':''}</h6>
     </div>
  )
 }

 render() {    
     const { galleryItems, responsive, currentIndex } = this.state
    //  const { classes } = this.props;

      return (
        <Grid container className="feature_venues fh-container">
          <Grid xs={12} item>
            {/* <h1 className="featured_venue_heading">Featured Venues</h1> */}
          </Grid>
          <Grid xs={12} item className="featured_venue_carousel">
                    <AliceCarousel
                    dotsDisabled={true}
                    items={galleryItems}
                    infinite={true}
                    slideToIndex={currentIndex}
                    onInitialized={this.handleOnSlideChange}
                    onSlideChanged={this.handleOnSlideChange}
                    onResized={this.handleOnSlideChange}   
                    responsive={responsive}                     
                    />               
          </Grid>
        </Grid>
    );
}
}

export default (SeatingAndCapacity);

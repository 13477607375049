import React from 'react';

import { Field } from 'formik';

import * as constClass from '../../constant';
import './assets/style/CustomSelect.scss';

function Search(props) {
    let selectOption
    if(props.name === 'category'){
        selectOption = props.categoryData.map(function (data, idx) {
            return (
                <option key={idx} value={data.value} class="category_option">{data.name.toUpperCase()} {data.sen}</option>
            );
        });
    }
    else if (props.name === 'seating'){
        selectOption = props.seatingData.map(function (data, idx) {
            return (
                <option key={idx} value={data.value}>{data.name} {('maximum' in data)?('- '+data.maximum+' Max'):'' } </option>
            );
        });
    }
    else if (props.name === 'space'){
        selectOption = props.spaceData && props.spaceData.map(function (data, idx) {
            return (
                <option key={idx} value={data.value}>{data.name}</option>
            );
        });
    }
    else if(props.name === 'guest_count'){
        selectOption = constClass.selectGuests.map(function (data, idx) {
            return (
                <option key={idx} value={data.value}>{data.name}</option>
            );
        });
    }
    else if(props.name === 'budget'){
        selectOption = constClass.selectBudgets.map(function (data, idx) {
            return (
                <option key={idx} value={data.value}>{data.name}</option>
            );
        });
    }
    else if(props.name === 'event_type'){
        selectOption = props.data.map(function (data, idx) {
            if(data.name==='Business Event'){
            return (
           
                    <option key={idx} value={data.id}>{data.name + ` (Corporate & Association Meetings or Events… )`}</option>
            );
            }
            else if(data.name==='Social Event'){
                return (
           
                    <option key={idx} value={data.id}>{data.name + ` (Rehearsal Dinners, Reunions, Fundraisers...)`}</option>
            );
            }
            else if(data.name==='Wedding'){
                return (
           
                    <option key={idx} value={data.id}>{data.name + ` (Wedding Ceremonies & Receptions)`}</option>
            );
            }
            else{
                return (
           
                    <option key={idx} value={data.id}>{data.name}</option>
            );
            }
        });
    }
    else if(props.name === 'place_hold_seating'){
        selectOption = constClass.selectPlaceHoldSeatings.map(function (data, idx) {
            if(data.value === props.selected){
              return (
                <option key={idx} value={data.value} selected>{data.name}</option>
              );
            } else {
                return (
                  <option key={idx} value={data.value}>{data.name}</option>
                );
            }
        });
    }

    else if(props.name === 'city'){
        selectOption = props.data.map(function (data, idx) {
            let value;
            if(data.city.length<100){
                if(data.city === props.selected){
                 
                     value= <option key={idx} value={data.city} selected>{data.city}</option>;
                    
                  } else {
                        value=<option key={idx} value={data.city}>{data.city}</option>;
                      
                  }
            }
            return value;
        });
    }
    else if(props.name==="setUp" || props.name==='takeDown'){
        selectOption = props.setUpArray.map(function (data, idx) {
        return(
            <option key={idx} value={data.value}>{data.name}</option>

        );
        })
    }
    else if(props.name==="syncEnd" || props.name==='syncEnd'){
        selectOption = props.syncEndArray.map(function (data, idx) {
        return(
            <option key={idx} value={data.name}>{data.name}</option>

        );
        })
    }
    else if(props.name==='spaceName'){
        selectOption = props.spaceData && props.spaceData.map(function (data, idx) {
            if(props.value && data.id === props.value){
                return (
                  <option key={idx} value={data.id} selected>{data.name}</option>
                );
              } else {
            return(
                <option key={idx} value={data.id}>{data.name}</option>
                );
              }
            }) 
    }

    else if(props.name==='max_hold'){
        selectOption = props.data && props.data.map(function (data, idx) {
            if(props.value && data.value === props.value){
                return (
                  <option key={idx} value={data.value} selected>{data.id}</option>
                );
              } else {
            return(
                <option key={idx} value={data.value}>{data.id}</option>
            );
              }
            }) 
    }else if(props.name==='hold_rank' || props.name==='hold_value'){
        console.log('ss'+props.name,props.value)
        selectOption = props.data && props
        .data
        .map(function (data, idx) {
            if (props.value && data.id === props.value) {
                return (
                    <option key={idx} value={data.id} selected>{data.value}</option>
                );
            } else {
                return (
                    <option key={idx} value={data.id}>{data.value}</option>
                );
            }
        })
    }
    else if(props.name==='hold_duration'){
        selectOption = props.data && props.data.map(function (data, idx) {
            if(props.value && data.value === props.value){
                return (
                  <option key={idx} value={data.value} selected>{data.id}</option>
                );
              } else {
            return(
                <option key={idx} value={data.value}>{data.id}</option>
            );
              }
            }) 
    }
    else if(props.name === 'seatingType' || props.name === 'isFlexible'|| props.name === 'otherVenues'){
        selectOption = props.data.map(function (data, idx) {
            return (
                <option key={idx} value={data.value}>{data.name} {('maximum' in data)?('- '+data.maximum+' Max'):'' } </option>
            );
        });
        
    }
    return (
        // <div id={props.id} className="customized-select">
        
            <Field disabled={props.disable} value={props.value} className="select_field" component="select" name={props.name} style={{textTransform: props.name==='category'?'none':null,                marginTop: props.marginTop?props.marginTop:null,width:props.width, height:props.height, color:props.color}}>
                <option key="-1" value="" >{props.placeholder}</option>
                {selectOption}
            </Field>
        // </div>
    );
}


export default Search;

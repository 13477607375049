import { Record } from 'immutable';

import {
  GET_GOOGLE_KEY_REQUEST,
  GET_GOOGLE_KEY_FAILURE,
  GET_GOOGLE_KEY_SUCCESS,

} from 'shared/state/types';

class State extends Record({
  googleKey:{}
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_GOOGLE_KEY_REQUEST:
      return state.merge({
        error:''
      });
    case GET_GOOGLE_KEY_FAILURE:
      return state.merge({
        googleKey:{},
        error: action.error


      });
    case GET_GOOGLE_KEY_SUCCESS:
      return state.merge({
        googleKey:action.googleData,
        error:'Success'
      });
  
    default:
      return state;
  }
}


import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import "../../shared/components/assets/style/placeHold.scss"
import { object } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';


// const styles = theme => ({
// root: {
// margin: 0,
// },
// closeButton: {
// position: 'absolute',
// top: '8px',
// color: '#9e9e9e',
// right: '8px',
// },
// });


const DialogContent = withStyles(theme => ({
root: {
},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
root: {
},
}))(MuiDialogActions);

class Book extends React.Component {
state = {
open: this.props.open,
};


handleClose = () => {
  this.props.close();
  this.setState({open:false})
};

render() {

return (
<div className="h">


<Dialog 

className="success_modal_container hold_settings forgot-something-popup"
aria-labelledby="customized-dialog-title"
open={this.state.open} >
<DialogActions className="dialogActions">
<span
className="material-icons close closeButton"
onClick={this.handleClose}
>
close
</span>
</DialogActions>
<DialogContent className="dialog-content">
<Grid container className="modalBox_content" style={{padding:'40px'}}>
<Grid item xs={12}>

      <Grid style={{textAlign:"center"}} container className="venue-publish-popup-box" >
            <div className="dont_single" >Do you want to book this hold?</div>
         

            <br />
      </Grid>
    
      <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>

                        <Grid container justify="flex-start" className="similarDiv what-we-love-block">
                                  <div className="what-we-love-listing">
            <Button onClick={()=>{this.props.bookEvent(this.props.eventPlanner);this.handleClose()}}>
                Yes                     
              </Button>
              <Button onClick={()=>{this.handleClose()}}>
                No
              </Button>
                                  </div>
                        </Grid>
                      </Grid>

</Grid>



</Grid>

</DialogContent>


</Dialog>

</div>
);
}
}

Book.propTypes = {
classes: object.isRequired,
};

const mapStateToProps = state => {
return {

};
};

const enhance = compose(
withRouter,
connect(mapStateToProps,dispatch =>
bindActionCreators(
{

},
dispatch
)
)
);
export default enhance(Book);


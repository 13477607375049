import React from "react";
import { Grid } from "@material-ui/core";
import MondayFriday from "../../shared/components/mondayFriday";
// import SaturdaySunday from "../../shared/components/saturdaySunday";
import Typography from "@material-ui/core/Typography";
import "../assets/styles/spaceInfo.scss";
import SeatingAndCapacity from "../../shared/components/seatingAndCapacity";
const nl2br = require('react-nl2br');

function tConvert(time) {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];
  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
}

function SpaceInfo(props) {  

  let area=props.spaceDetails.area ? props.spaceDetails.area:'';

  if(area!==''){
    area=area.toString();
  //  let area_final=area.slice(0,1)+","+area.slice(1,)
   
  }

  //for time details
  var updateVenueSlot = [];
  // var area_unit=props.spaceDetails.area_unit?props.spaceDetails.area_unit=='sq mt'?'sq. mt' : 'sq. ft':'';
  // var dimension_unit=props.spaceDetails.dimension_unit?props.spaceDetails.dimension_unit == 'feet'?'feet' :'meters':'';

  var finalArray = [];
  var finaldata;
  var objTime = {};
  
  (props.displayTimeSlots) &&
  props.displayTimeSlots.map(function(datatime, idx) {
      finaldata = "";
      updateVenueSlot = [];
      objTime = {};

      if (parseInt(datatime.monday) === 0 || parseInt(datatime.monday) === 1) {
        let obj = {};
        obj.value = datatime.monday;
        obj.day = "Monday";

        updateVenueSlot.push(obj);
      }
      if (parseInt(datatime.tuesday) === 1 || parseInt(datatime.tuesday) === 0) {
        let obj = {};
        obj.value = datatime.tuesday;
        obj.day = "Tuesday";
        updateVenueSlot.push(obj);
      }
      if (parseInt(datatime.wednesday) === 1 || parseInt(datatime.wednesday) === 0) {
        let obj = {};
        obj.value = datatime.wednesday;
        obj.day = "Wednesday";
        updateVenueSlot.push(obj);
      }
      if (parseInt(datatime.thursday) === 1 || parseInt(datatime.thursday) === 0) {
        let obj = {};
        obj.value = datatime.thursday;
        obj.day = "Thursday";
        updateVenueSlot.push(obj);
      }
      if (parseInt(datatime.friday) === 1 || parseInt(datatime.friday) === 0) {
        let obj = {};
        obj.value = datatime.friday;
        obj.day = "Friday";
        updateVenueSlot.push(obj);
      }
      if (parseInt(datatime.saturday) === 1 || parseInt(datatime.saturday) === 0) {
        let obj = {};
        obj.value = datatime.saturday;
        obj.day = "Saturday";
        updateVenueSlot.push(obj);
      }
      if (parseInt(datatime.sunday) === 0 || parseInt(datatime.sunday) === 1) {
        let obj = {};
        obj.value = datatime.sunday;
        obj.day = "Sunday";
        updateVenueSlot.push(obj);
      }
      let obj={};
      updateVenueSlot.push(obj);

      var index;
      for (let i = 0; i < updateVenueSlot.length; i++) {
        if (parseInt(updateVenueSlot[i].value) === 1) {
          index = i;
          finaldata = updateVenueSlot[i].day.substring(0, 3);
          break;
        }
      }

      for (let j = index + 1; j < updateVenueSlot.length; j++) {

        if (parseInt(updateVenueSlot[j].value) === 0) {
    
          if (j - 1 > 0 && parseInt(updateVenueSlot[j - 1].value) === 1) {
                  finaldata =  finaldata + " - " + updateVenueSlot[j - 1].day.substring(0, 3);

          }

              if (j + 1 < updateVenueSlot.length && parseInt(updateVenueSlot[j + 1].value) === 1) {

                finaldata = finaldata + " , " + updateVenueSlot[j + 1].day.substring(0, 3);
              }

     

        }
        if(j===parseInt(updateVenueSlot.length-1) &&  parseInt(updateVenueSlot[j - 1].value) === 1){
          finaldata =  finaldata + " - " + updateVenueSlot[j-1].day.substring(0, 3);


        }
      }



      let fd=finaldata.replace(/' '/g,'');

      fd=fd.split(' - ')

      fd = fd.filter( (ele, ind) => ind === fd.findIndex( elem => elem === ele))
      let finData='';
      for(let fin=0;fin<fd.length;fin++){
        finData=finData +" - " +fd[fin];
      }
      finaldata=finData.replace('-','');


      if(finaldata.includes(',')===true){
        let data=''
        finaldata=finaldata.split(',');

        for(let va=0;va<finaldata.length;va++){

          if(finaldata[va].includes('-')===true){

            let split_data=finaldata[va].split('-');

            if(split_data[0].trim()===split_data[1].trim()){
              data=data+','+split_data[1]
            }
            else{
              data=data+','+split_data[0]+'-'+split_data[1]

            }
          }
          else
          {
            data=data+","+finaldata[va]
          }
        }
      finaldata=data.replace(',','');

      }

      let time =
        tConvert(datatime.start_time.substring(0, 5)) +
        " - " +
        tConvert(datatime.end_time.substring(0, 5));
      objTime.value = finaldata;
      objTime.time = time;
      finalArray.push(objTime);
      return finalArray;
    });

  let ArrayData = finalArray.filter(
    (ele, ind) =>
      ind ===
      finalArray.findIndex(
        elem => elem.value === ele.value && elem.time === ele.time
      )
  );
  const spaceObject = ArrayData.map(function(data, idx) {
    return (
      <div className="mondFriDiv jjn">
        <MondayFriday style={{'fontFamily':'Raleway'}} dataValue={data.time} day={data.value} />
      </div>
    );
  });

  let loading=''
  if(spaceObject.length !==0){
     loading=true
  }
  else {
  loading=false
  }

  //average price
  let price = "";
  let price_name=[];
  let avg_price_arr=[];
  let p=[];
  let foodToken=false;
  
    props.spaceDetails.timeslots &&
    props.spaceDetails.timeslots.map(function(datatime, idx) {
    datatime.event_types.map(function(data, idx) {

        if(parseInt(data.pivot.pricing_type)===1){
          avg_price_arr.push(data.pivot.base_rate);
        }
        if(parseInt(data.pivot.pricing_type)===2){
          avg_price_arr.push(data.pivot.base_rate);
          foodToken=true;
          let obj={};
          obj.name="Food & Beverage Minimum";
          obj.price="0";
          p.push(obj);

        }
        if(parseInt(data.pivot.pricing_type)===3){
          if(foodToken===false){
            let obj={};
            obj.name="Food & Beverage Minimum";
            obj.price="0";
            p.push(obj);
          }
          avg_price_arr.push(data.pivot.fnb_rate + data.pivot.base_rate);
        }

        if(parseInt(data.pivot.pricing_type)===4){
          if(data.pivot.min_guest!==null && data.pivot.min_guest!==0){
            avg_price_arr.push(data.pivot.base_rate * data.pivot.min_guest);
          }
          else{
            avg_price_arr.push(data.pivot.base_rate);

          }
        }
      
      else if(data.pivot.pricing_type_name){
        let obj={};
        obj.name=data.pivot.pricing_type_name;
        obj.price=data.pivot.base_rate;
        
        p.push(obj);
        price_name.push(data.pivot.pricing_type_name+": $"+data.pivot.base_rate)
      }
      return price_name;
      });
      return price_name;
    });


let min=avg_price_arr[0];
let p_arr=[];

for(let i=0;i<p.length;i++){

  let min_p='';
  let max_p='';
let obj_p={};
let token=true;
  for(let j=0;j<p.length;j++){
    if(p[i].name.toLowerCase()===p[j].name.toLowerCase()){
      token=false;
      if(min_p==='' && min_p!==0){
        
        min_p=p[i].price;
      }
      
      if(max_p===''){
        max_p=p[i].price;
      }
      if(min_p > p[j].price)
      {
        if(p[j].price!==0){
          min_p=p[j].price;
        }
      }
      if(max_p < p[j].price){
        max_p=p[j].price;
      }
    }
  }

  let p_obj = p_arr.filter(data => data.name.toLowerCase()===p[i].name.toLowerCase());

  if(p_obj.length===0){
    if(token===false){
      obj_p.name=p[i].name;
      if(min_p===max_p){
        obj_p.price="$"+min_p;
      }
      else
      {
        obj_p.price="$"+min_p +" - $"+max_p

      }
      p_arr.push(obj_p);

    }
    else
    {
      p_arr.push(p[i]);
 
    }
  }
else if(p_arr.length===0){

  p_arr.push(p[i]);

}
}

let max=0;

    for(let i=0;i<avg_price_arr.length;i++){
      if(avg_price_arr[i]<min)
      {
        min=avg_price_arr[i];
      }
  
      if(avg_price_arr[i]>max){
        max=avg_price_arr[i];
      }
   
    }
    if(min && max){
      if(min !== null && max!==null && min!==max){
        price="$"+min+" - $"+max;
      }
      else if(min===max){
        price='$'+max;
      }
      else {
        price="$"+max;
      }

    }
    else{
      price="None";
    }
   
    
  //features
  let complimentary = [];
  let paid = [];
  let parking = [];
  let food_beverage = [];
  let restriction = [];
    props.venueProfile.features &&
    props.venueProfile.features.map(function(data, idx) {

      if (parseInt(data.type) === 1) {

        if (data.custom_name !== null && data.custom_name !== "") {
          paid.push(data.custom_name);
        } else {

          paid.push(data.name);
        }
      } else if (parseInt(data.type) === 2) {
        if (data.custom_name !== null && data.custom_name !== "") {
          complimentary.push(data.custom_name);
        } else {
          complimentary.push(data.name);
        }
      } else if (parseInt(data.type) === 3) {
        if (data.custom_name !== "") {
          food_beverage.push(data.custom_name);
        } else {
          food_beverage.push(data.name);
        }
      } else if (parseInt(data.type) === 4) {
        if (data.custom_name !== "") {
          parking.push(data.custom_name);
        } else {
          parking.push(data.name);
        }
      } else if (parseInt(data.type) === 5) {
        if (data.custom_name !== "") {
          restriction.push(data.custom_name);
        } else {
          restriction.push(data.name);
        }
      }
return '';
    });

    const addReqCharges = p_arr.map(function(n, idx) {
      let aName = n.name.charAt(0).toUpperCase() + n.name.slice(1);
      return <li>{aName.length < 25? aName:aName.substring(0,25)}                      
         {n.price!=='$0'? ': '+n.price : ''}</li>
      });

  return (
       
    
      
      <Grid container className="space-info">

          {props.dataGet ? 

      <Grid xs={12} item>
        <Grid container>
          <Grid container>
            <Grid
              item             
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="dayTimeDiv"
            >
              <h4 className="black-heading">Hours of Operation</h4>
              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                xl={12}
                lg={12}
                className="dayTimeDiv"
              >
                {loading == true ? (spaceObject.length !==0 ?spaceObject: 'No Data Available'):null}
              </Grid>
            </Grid>
            <Grid
              item            
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="dayTimeDiv"
             >
              <h4 className="black-heading">Starting at</h4>
              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                xl={12}
                lg={12}
                className="dayTimeDiv"
              >
                <div className="avgPriceDiv">{price !=='' ? price +  '' : 'None'}</div>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="dayTimeDiv"
             >
              <h4 className="black-heading">Additional Required Charges</h4>
              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                xl={12}
                lg={12}
                className="dayTimeDiv"
              >
              <div className="avgPriceDiv">{p_arr.length > 0 ? <ul class="additional-list">{addReqCharges}</ul> : 'None'}</div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                md={11}
                sm={11}
                xl={11}
                lg={11}
                className="avilableDescriptionDiv"
              >
                <div className="description">
                  <Typography
                    color="textSecondary"
                    className="matter aboutMatter"
                  >
                    {props.spaceDetails.description !== 'null' ?nl2br(props.spaceDetails.description):''}
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                xl={11}
                lg={11}
                className="seatindAndCapacityGrid"
              >
                <h4 className="black-heading">Seating & Capacity</h4>
                {props.spaceDetails && props.spaceDetails.arrangements.length>0?
                  <SeatingAndCapacity spaceInfo={props.spaceDetails} />
                :
                <h6>No arrangements found</h6>
              
                }
              </Grid>
            </Grid>
            
            <Grid container className="covid-19-text" style={{justifyContent: 'center'}}>Minimum capacity allows for ample space and social distancing</Grid>
            <Grid container>
              {paid.length !== 0 ? (
                <Grid item md={6} xs={6} sm={6} xl={6} lg={6}>
                  <h4 className="blue-heading">Amenities</h4>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    xl={12}
                    lg={12}
                    className="contents"
                  >
                
                  {paid.map(function(n, idx) {
                      return <p>{n}</p>;
                    })}
                  </Grid>
                </Grid>
              ) : null}
              {complimentary.length !== 0 ? (
                <Grid item md={6} xs={6} sm={6} xl={6} lg={6}>
                  <h4 className="blue-heading">
                  Complimentary
                                    </h4>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    xl={12}
                    lg={12}
                    className="contents"
                  >
                    {complimentary.map(function(n, idx) {
                      return <p>{n} </p>;
                    })}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>

            <Grid container>
              {food_beverage.length !== 0 ? (
                <Grid item md={6} xs={6} sm={6} xl={6} lg={6}>
                  <h4 className="blue-heading">Food & Beverages</h4>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    xl={12}
                    lg={12}
                    className="contents"
                  >
                    {food_beverage.map(function(n, idx) {
                      return <p>{n} </p>;
                    })}{" "}
                  </Grid>
                </Grid>
              ) : null}
              {parking.length !== 0 ? (
                <Grid item md={6} xs={6} sm={6} xl={6} lg={6}>
                  <h4 className="blue-heading">Parking</h4>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    xl={12}
                    lg={12}
                    className="contents"
                  >
                    {parking.map(function(n, idx) {
                      return <p>{n} </p>;
                    })}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid container>
              {restriction.length !== 0 ? (
                <Grid item md={6} xs={6} sm={6} xl={6} lg={6}>
                  <h4 className="blue-heading">Restrictions</h4>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    xl={12}
                    lg={12}
                    className="contents"
                  >
                       {restriction.map(function(n, idx) {
                      return <p>{n} </p>;
                    })}
                
                  </Grid>
                </Grid>
              ) : null}
            </Grid>

              {props.spaceDetails.area || props.spaceDetails.length || props.spaceDetails.height || props.spaceDetails.width || props.spaceDetails.floor ?
                          <Grid container>
                          <Grid item md={6} xs={6} sm={6} xl={6} lg={6}>
                          {props.spaceDetails.area===0 && props.spaceDetails.height===0 && props.spaceDetails.width===0 && props.spaceDetails.floor==='0' ?
'':<h4 className="blue-heading">Other Info</h4>}
                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  xl={12}
                  lg={12}
                  className="contents"
                >
                  {props.spaceDetails.area && props.spaceDetails.area!=='0' ? <p>{props.spaceDetails.area.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + props.area }<br /> </p>:null}
                  {/* <p>{props.spaceDetails.area ? props.spaceDetails.area + ' ' + area_unit :null}</p><br /> */}
                  {props.spaceDetails.floor && props.spaceDetails.floor!=='0' ? <p>{props.spaceDetails.floor}<br /></p>: null} 
                  {props.spaceDetails.length && props.spaceDetails.height && props.spaceDetails.width ? props.spaceDetails.length +' X '+ props.spaceDetails.width +' X '+props.spaceDetails.height+ " "+props.dimension: null}
                </Grid>
                </Grid>
            </Grid>
                : null }

           
          </Grid>
        </Grid>
      </Grid>
  
      : <div class="fourohfour">
      <h3>Loading...</h3></div>}
      </Grid>
     
   
  );
}

export default SpaceInfo;

import React from 'react';
import moment from "moment";
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import "../assets/styles/holidayOverrides.scss";
import {object} from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router-dom";
import {getHolidayRate} from "../../../data/spaces/actions/getHolidayRate";
import {deleteHolidayRate} from "../../../data/spaces/actions/deleteHolidayRate";

const styles = theme => ({
    root: {
        margin: 0
    },
    closeButton: {
        position: 'absolute',
        top: '8px',
        color: '#9e9e9e',
        right: '8px'
    }
});
withStyles(styles)(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose
                ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                )
                : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({root: {}}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({root: {}}))(MuiDialogActions);

class HolidayOverrides extends React.Component {
   
    state = {
        open: this.props.open,       
        loading:false
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close();
    };
   

    UNSAFE_componentWillMount() {  
        this.setState({loading:true});      
        this.props.getHolidayRate(this.props.match.params.spaceId).then((response) => {
            this.setState({loading:false});
        });
    }
   
    render() {
        const {classes} = this.props;
        const deleteHolidayRate =(id,index) => {
            this.props.deleteHolidayRate(id).then(response=>{
                this.props.getHolidayRate(this.props.match.params.spaceId);
            });
        };

        const sortOrder = this.props.formikProps.values.fixedValue
        ? this.props.fixedRentalAvailabilitySortedDays
        : this.props.flexibleRentalAvailabilitySortedDays;

        let timeSlotSortDay = [];
        if(this.props.formikProps.values.flexibleRental){
            this
            .props
            .formikProps
            .values
            .flexibleRentalAvailability
            .map(function (data, idx) {
                timeSlotSortDay.push({id:data.id,name:sortOrder[idx]});
            });
        } 
        
        if(this.props.formikProps.values.fixedValue){
            this
            .props
            .formikProps
            .values
            .fixedRentalAvailability
            .map(function (data, idx) {
                timeSlotSortDay.push({id:data.id,name:sortOrder[idx]});
            });
        } 

      

        const holidayRates = this.props.spaceHolidayRateData.length > 0 && this.props.spaceHolidayRateData.map(function (data, idx) {
            let sortName = timeSlotSortDay.filter( element => element.id==data.timeslot.id );
            return (             
            <Grid container item xs={12} sm={12} className="list-box" >
                <Grid item xs={6} justifyContent="left" >
                    {
                      moment(data.start_date).isSame(data.end_date)?  
                      <p className="blue-text">{moment(data.start_date).format('DD/MM/YYYY')}</p>
                      :<p className="blue-text">{moment(data.start_date).format('DD/MM/YYYY')} - {moment(data.end_date).format('DD/MM/YYYY')}</p>
                    }
                  
                    <p className="grey-text">{moment(data.timeslot.start_time, "hh:mm")
                                                .format("hh:mm A")
                                                .replace(/^0+/, '') + '-' + moment(data.timeslot.end_time, "hh:mm")
                                                .format("hh:mm A")
                                                .replace(/^0+/, '') + ' ' + sortName[0]['name']}</p>
                    <p className="grey-text">
                    {
                        (data.event_types && data.event_types.length > 0) ?
                        data.event_types.map(function (eventType, idx) {
                            return (idx==0?eventType.name:(", "+eventType.name))
                        })
                        :'None'
                    }
                    </p>
                </Grid>
                <Grid item xs={5} justifyContent="left" >
                    {
                        data.base_amount && <p className="grey-text">{ (data.pricing_type_id==1 || data.pricing_type_id==3) ?'Venue Rental':(data.pricing_type_id==2?'Minimum Rate': data.pricing_type_id==4?'Per Person':'Venue Rental')} :${data.base_amount}</p>
                    }

                    {
                        data.food_item && <p className="grey-text">Food & Bev Minimum :${data.food_item}</p>
                    }
                    
                   
                </Grid>
                <Grid item xs={1} justifyContent="right" >
                    <div className="actionDiv" >
                        <span className="material-icons" onClick={(e)=>{ deleteHolidayRate(data.id,idx)}} >delete</span>
                    </div>
                </Grid>                                               
            </Grid>
            )
         });  
        return (
            <div>
               
                <Dialog
                    className="success_modal_container view-listing-popup"
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}>
                    <DialogActions className="dialogActions">
                        <span className="material-icons close closeButton" onClick={this.handleClose}>
                            close
                        </span>
                    </DialogActions>
                    <DialogContent className="dialog-content">
                        <Grid container className="modalBox_content">
                            <Grid item xs={12}>
                                <Grid container className="">
                                    <Grid item xs={12} sm={12}>
                                        <div className="header">Rental Rates Overrides</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} className="modal_container">
                                    {
                                      (this.props.spaceHolidayRateData.length > 0 && this.state.loading == false)? 
                                        holidayRates                                    
                                        : this.state.loading == true ?
                                        <div className="not_available">Loading...</div>
                                        :<div className="not_available">No overrides found.</div>
                                    }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

HolidayOverrides.propTypes = {
    classes: object.isRequired
};

const mapStateToProps = state => {
    console.log('adfad',state.data)
    return {
        spaceHolidayRateData: state.data.spaces.spaceHolidayRateData
    };
};

const enhance = compose(withRouter, connect(mapStateToProps, dispatch => bindActionCreators({
    getHolidayRate,
    deleteHolidayRate
}, dispatch)));
export default enhance(HolidayOverrides);

import { GET_SPACE } from 'shared/state/types';
import { request } from 'shared/auth';

function getSpaceRequested() {
  return {
    type: GET_SPACE.REQUEST,
  };
}

function getSpaceFailed(error) {
  return {
    type: GET_SPACE.FAILURE,
    error: error,
  };
}

function getSpaceSuccess(data) {
  return {
    type: GET_SPACE.SUCCESS,
    spaceData: data,
  };
}

export function getSpace(spaceId,photoCount) {
  return dispatch => {
    dispatch(getSpaceRequested());
    if(photoCount===1)
    {
    return request().get(`/spaces/${spaceId}?photoCount=${photoCount}`)
      .then(response =>{
          if(response.status === 200){
            dispatch(getSpaceSuccess(response.data.data))
            return response.data.data
          }
          else if(response.status === 400){
            dispatch(getSpaceFailed("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(getSpaceFailed("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(getSpaceFailed("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(getSpaceFailed("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(getSpaceFailed("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(getSpaceFailed("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(getSpaceFailed("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(getSpaceFailed("The server was unavailable."));
          }
          else{
            dispatch(getSpaceFailed("The server was unavailable."));
          }
        }
      )
      .catch(errors => {
          if(errors.response && errors.response.data && errors.response.data.errors){
            dispatch(getSpaceFailed(errors.response.data.errors));
          }
          else{
            dispatch(getSpaceFailed('Unable to access api'));
          }
        throw errors;
      });
    }
    else
    {
      return request().get(`/spaces/${spaceId}?photoCount=${photoCount}`)
      .then(response =>{
          if(response.status === 200){
            dispatch(getSpaceSuccess(response.data.data))
            return response.data.data
          }
          else if(response.status === 400){
            dispatch(getSpaceFailed("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(getSpaceFailed("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(getSpaceFailed("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(getSpaceFailed("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(getSpaceFailed("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(getSpaceFailed("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(getSpaceFailed("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(getSpaceFailed("The server was unavailable."));
          }
          else{
            dispatch(getSpaceFailed("The server was unavailable."));
          }
        }
      )
      .catch(errors => {
          if(errors.response && errors.response.data && errors.response.data.errors){
            dispatch(getSpaceFailed(errors.response.data.errors));
          }
          else{
            dispatch(getSpaceFailed('Unable to access api'));
          }
        throw errors;
      });
    }
  };
}

import { GET_HOLDS } from 'shared/state/types';
import { request } from 'shared/auth';

function getHoldsRequested() {
  return {
    type: GET_HOLDS.REQUEST,
  };
}

function getHoldsFailed(error) {
  return {
    type: GET_HOLDS.FAILURE,
    error:error,
  };
}

function getHoldsSuccess(data) {
  return {
    type: GET_HOLDS.SUCCESS,
    payload: data,
  };
}

export function getHolds(value,key,otherVal='',page,sortBy='event_start_date',orderBy='asc',limit=10,search_date='',end_date='',event_follows=0) {
  let url;
  let sortParams = "";
  if(sortBy && orderBy){
    sortParams = '&order_by='+sortBy+'&dir='+orderBy;
  }
  if(value && value!==''){
    if(key==='search'){
      url=`event_timeslots/venueholds?hold=`+value+'&search='+otherVal+'&page='+page+sortParams+'&limit='+limit+"&event_follows="+event_follows+"&search_date="+search_date+"&end_date="+end_date;
    }
    else if(key==='paging'){
      if(otherVal!=='' && otherVal){
        url=`event_timeslots/venueholds?search=`+otherVal+`&hold=`+value+`&page=`+page+sortParams+'&limit='+limit+"&event_follows="+event_follows+"&search_date="+search_date+"&end_date="+end_date;
      }
      else{
        url=`event_timeslots/venueholds?hold=`+value+`&page=`+page+sortParams+'&limit='+limit+"&event_follows="+event_follows+"&search_date="+search_date+"&end_date="+end_date;

      }
    }
    else{
      url=`event_timeslots/venueholds?hold=`+value+'&page=1'+sortParams+'&limit='+limit+"&event_follows="+event_follows+"&search_date="+search_date+"&end_date="+end_date;

    }
  }
  else{
    if(key==='search'){
      url=`event_timeslots/venueholds?search=`+otherVal+'&page='+page+sortParams+'&limit='+limit+"&event_follows="+event_follows+"&search_date="+search_date+"&end_date="+end_date;
    }
    else if(key==='paging'){

      if(otherVal && otherVal!==''){
        url=`event_timeslots/venueholds?search=`+otherVal+`&page=`+page+sortParams+'&limit='+limit+"&event_follows="+event_follows+"&search_date="+search_date+"&end_date="+end_date;
      }
      else{
        url=`event_timeslots/venueholds?page=`+page+sortParams+'&limit='+limit+"&event_follows="+event_follows+"&search_date="+search_date+"&end_date="+end_date;

      }
    }
    else{
      url=`event_timeslots/venueholds?page=1`+sortParams+'&limit='+limit+"&event_follows="+event_follows+"&search_date="+search_date+"&end_date="+end_date;

    }
  }
  return dispatch => {
    dispatch(getHoldsRequested());

    return request().get(url)
      .then(response =>{
          dispatch(getHoldsSuccess(response.data.data)
         )
         return response.data;
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(getHoldsFailed(errors.response.data.errors));
        }
        else{
          dispatch(getHoldsFailed("Unable to access api"));
        }
        throw errors;
      });
  };
}

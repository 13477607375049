import axios from 'axios';
import { GET_HOME_PAGE_REQUEST, GET_HOME_PAGE_SUCCESS, GET_HOME_PAGE_FAILURE } from 'shared/state/types';
// import Cookies from 'js-cookie';
import { loadProgressBar } from 'axios-progress-bar'

function homePageRequested() {
  return {
    type: GET_HOME_PAGE_REQUEST
  };
}

function homePageSuccess(data) {
  return {
    type: GET_HOME_PAGE_SUCCESS,
    homeVenue: data,
  };
}

function homePageFailure(error) {
  return {
    type: GET_HOME_PAGE_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function homepage() {

    return dispatch => {
      dispatch(homePageRequested());
      return request().get(`pages/home`)
      .then(response =>
        dispatch (homePageSuccess(response.data),
        )
      )
      .catch(errors => {
        dispatch(homePageFailure(errors.response));
        throw errors;
      });
      


    
 };
}

import { SEND_CONFIRMATION } from 'shared/state/types';
import { requestForUnauthorised } from 'shared/auth';

function sendConfirmationRequested() {
  return {
    type: SEND_CONFIRMATION.REQUEST,
  };
}

function sendConfirmationFailed(error) {
  return {
    type: SEND_CONFIRMATION.FAILURE,
    error:error,
  };
}

function sendConfirmationSuccess(data) {
  return {
    type: SEND_CONFIRMATION.SUCCESS,
    message: data,
  };
}

export function sendConfirmation(params) {
  return dispatch => {
    dispatch(sendConfirmationRequested());

    return requestForUnauthorised().post('/auth/confirmations', params)
      .then(response =>{
        dispatch(sendConfirmationSuccess(response.data.message))
        return response.data.message}
      )
      .catch(errors => {
        dispatch(sendConfirmationFailed(errors.response.data.message));
        throw errors;
      });
  };
}

import { Record } from 'immutable';

import {
  CONTACT_US,
} from 'shared/state/types';

class State extends Record({
  sending: false,
  sended: false,
  error: '',
  success: ''
}) { }


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case CONTACT_US.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        success: '',
        error: ''
      });
    case CONTACT_US.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        success: 'Email sent successfully.',
        error: ''
      });
    case CONTACT_US.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        error: action.error
      });
    default:
      return state;
  }
}


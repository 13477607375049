import React, { useState, useEffect } from 'react';
import { object } from 'prop-types';
import ReactDOM from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import CustomizedButton from '../../shared/components/customizedButton';
import ImageSort from './imageSort';
import ProgressBarSort from './progressBarSort'

import * as classNames from 'classnames';
import { Field, FieldArray } from 'formik';
import { Grid, Icon, withTheme } from '@material-ui/core';

import '../assets/styles/imageUploadPopUp.scss';
import Cropper from 'react-easy-crop'
// import getCroppedImg from '../../cropImage';
import { DragDropContext, Droppable, Draggable, resetServerContext } from 'react-beautiful-dnd';


const styles = theme => ({
    linearColorPrimary: {
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
        borderRadius: '3px',
        background: 'linear-gradient(to bottom, #f1435e, #ee2738)',
        width: '121px',
        height: '17px'
    },
    linearBarColorPrimary: {
        backgroundColor: '#c4d0d9'
    },
    phone: {
        color: '#e1e1e1',
        fontSize: '72px',
        [theme.breakpoints.down("xs")]: {
            fontSize: '35px'
        }
    },
    desktop: {
        color: '#e1e1e1',
        fontSize: '81px',
        marginLeft: '30px',
        marginRight: '30px',
        [theme.breakpoints.down("xs")]: {
            fontSize: '35px'
        }
    },
    camera: {
        color: '#e1e1e1',
        fontSize: '61px',
        [theme.breakpoints.down("xs")]: {
            fontSize: '35px'
        }
    },
    description: {
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '1.33',
        textAlign: 'center',
        color: '#9aa7af'
    },
    icons: {
        paddingTop: '70px',
        paddingBottom: '14px',
        [theme.breakpoints.down("xs")]: {
            paddingBottom: '35px',
            paddingTop: '45px'
        }
    },
    addPhotoButton: {
        paddingTop: '34px',
        paddingBottom: '100px',
        [theme.breakpoints.down("xs")]: {
            paddingBottom: '34px'
        }
    },
    deleteIcon: {
        fontSize: '22px',
        color: '#36434b'
    },
    photoButton: {
        paddingTop: '15px',
        paddingBottom: '15px'
    },
    handle: {
        color: '#9aa7af'
    }
});

const getItemStyle = (draggableStyle, isDragging) => ({
    userSelect: 'none',
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({

});

let spaceImage = []
function EditVenueImage(props) {
    const { classes } = props;
    useEffect(() => {
        resetServerContext();
    });

    /*
        props.images && props.images.length>0 && props.images.map(function (data, idx) {       
                spaceImage.push({content: ( 
                        data.uploading && !data.uploaded ?
                                <div>
                                <ProgressBarSort items={data.name} classes={classes} deleteByName={props.deleteByName} />                     
                                </div>
                                : (!data.uploading && data.uploaded) &&
                                <div>
                                    <ImageSort venueData={props.venueData} index={idx} name={props.name} photos={props.images} items={data.path} id={data.id} classes={classes} deleteImage={props.deleteImage} />
                                </div>
                    ),photoId:data.id        
                });
        });
    */

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        props.updateImagesOrder(result);
    }

    return (
        props.formikProps ?
            <div>
                <div className="edit_venue_image edit-photos">
                    {props.images && props.images.length === 0 && props.imageFileData === '' ?

                        <div className="upload_button_screen">
                            <Grid container justify="center">
                                <Grid item className={classes.icons}>
                                    <Icon className={classes.phone}>stay_primary_landscape</Icon>
                                    <Icon className={classes.desktop}>desktop_mac</Icon>
                                    <Icon className={classes.camera}>photo_camera</Icon>
                                </Grid>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item xs={12} className={classes.description}>
                                    Landscape orientation recommended but not required
                                </Grid>
                            </Grid>
                            <Grid container>
                                {props.error && props.error.length > 0 ?
                                    <Grid container>
                                        <Grid item xs={12} className="error">{props.error[0]}</Grid>
                                    </Grid>
                                    : null}                        </Grid>

                            <Grid container justify="center">
                                <p style={{ color: "#4a4a4a", fontSize: "13px", width: '100%', paddingTop: '8px', textAlign: 'center', fontWeight: '600' }}>Photo Credit Optional</p>
                                <div style={{ color: "#1397e1", fontSize: "13px", float: 'left', width: '100%', paddingTop: '4px', textAlign: 'center', fontWeight: '500' }}>

                                    {props.spaceId ? "Minimum of 3 and Maximum of 15 photos per event space." :
                                        "Minimum of 3 and Maximum of 15 photos per venue."
                                    }


                                </div>
                                <br />
                                <Grid item className={classes.addPhotoButton}>

                                    {props.condition ?
                                        <CustomizedButton height='26px' width='105px' label="+ Add Photos" type="button" onClickEvent={props.handleImageUpload} />
                                        : <div><CustomizedButton disabled={true} height='26px' width='105px' label="+ Add Photos" type="button" />
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        :
                        props.cropImage === false ?
                            <div>


                                {/*props.images && props.images.length>0 && props.images.map(function (data, idx) {
                                return (                        
                                data.uploading && !data.uploaded ?
                                    <div>
                                        <ProgressBarSort items={data.name} classes={classes} deleteByName={props.deleteByName} />
                                    </div>
                                    : (!data.uploading && data.uploaded) &&
                                    <div>
                                        <ImageSort venueData={props.venueData} index={idx} name={props.name} photos={props.images} items={data.path} id={data.id} classes={classes} deleteImage={props.deleteImage} />
                                    </div>
                                )
                            })*/
                                }

                                <FieldArray
                                    name="photographer"
                                    render={arrayHelpers => (
                                        <div>

                                            {

                                                (!props.spaceId && props.venueData && props.venueData.profilePhoto) &&
                                                <ImageSort venueData={props.venueData} index={0} name={props.name} photos={props.images} items={props.venueData.profilePhoto.path} id={props.venueData.profilePhoto.id} classes={classes} deleteImage={props.deleteImage} />
                                            }
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="droppable">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                        >
                                                            {
                                                                props.images && props.images.length > 0 && props.images.map((data, idx) => (

                                                                    !props.spaceId && idx == 0 ?
                                                                        null
                                                                        :
                                                                        <Draggable key={(data.id).toString()} draggableId={(data.id).toString()} index={(idx)} >
                                                                            {(provided, snapshot) => (
                                                                                <div>
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        style={getItemStyle(
                                                                                            provided.draggableStyle,
                                                                                            snapshot.isDragging
                                                                                        )}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        {(!data.uploading && data.uploaded) &&
                                                                                            <div className={classNames('item', snapshot.isDragging && 'dragging')}>
                                                                                                <ImageSort venueData={props.venueData} index={idx} name={props.name} photos={props.images} items={data.path} id={data.id} classes={classes} deleteImage={props.deleteImage} updateName={props.updateName} updateWebsite={props.updateWebsite} />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                ))
                                                            }
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </div>
                                    )}
                                />


                                {
                                    props.images && props.images.length > 0 && props.images.map(function (data, idx) {
                                        return (
                                            (data.uploading && !data.uploaded) &&
                                            <ProgressBarSort items={data.name} classes={classes} deleteByName={props.deleteByName} />
                                        )
                                    })
                                }


                                {props.error && props.error.length > 0 ?
                                    <Grid container>
                                        <Grid item xs={12} className="error">{props.error[0]}</Grid>
                                    </Grid>
                                    : null}

                                <Grid container justify="center">
                                    <p style={{ color: "#4a4a4a", fontSize: "13px", width: '100%', paddingTop: '8px', textAlign: 'center', fontWeight: '600' }}>Photo Credit Optional</p>
                                    <div style={{ color: "#1397e1", fontSize: "13px", float: 'left', width: '100%', paddingTop: '4px', textAlign: 'center', fontWeight: '500' }}>

                                        {props.spaceId ? "Minimum of 3 and Maximum of 15 photos per event space." :
                                            "Minimum of 3 and Maximum of 15 photos per venue"
                                        }

                                    </div>
                                    <br />

                                    <Grid item className={classes.photoButton}>

                                        {props.condition ?
                                            props.images && props.images.length >= 15 ?
                                                <div>
                                                    <CustomizedButton disabled={true} height='26px' width='105px' label="+ Add Photos" type="button" />
                                                    <p style={{ color: 'red', fontSize: '10px' }}>Maximum number of photos reached.</p>
                                                </div>
                                                :
                                                <CustomizedButton height='26px' width='105px' label="+ Add Photos" type="button" onClickEvent={props.handleImageUpload} />

                                            :
                                            <div>
                                                <CustomizedButton disabled={true} height='26px' width='105px' label="+ Add Photos" type="button" />
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                            :
                            <div className="image-c">
                                <Cropper
                                    image={props.imageFileData ? props.imageFileData : props.imageUrl}
                                    crop={props.crop}
                                    zoom={props.zoom}
                                    aspect={props.aspect}
                                    rotation={props.rotation}
                                    onCropChange={props.onCropChange}
                                    onCropComplete={props.onCropComplete}
                                />
                            </div>

                    }
                    <Field className="file_type_field" type="file" id="image" name="venueImage" accept="image/jpg, image/jpeg"
                        onChange={(e) => {
                            props.handleImageChange(e, props.formikProps.setSubmitting)
                            // props.formikProps.handleChange()
                        }}
                    />
                </div>
                {props.cropImage !== false ? <div className="cropper-btm-section">

                    <div className="field_div wid-50">
                        <div className="slider-container" style={{ padding: '5px' }}>
                            <label className="container">
                                Slide to Zoom photo
                            </label>
                            <br />
                            <input type="range"
                                min="1"
                                max="20"
                                style={{ position: 'absolute' }}

                                defaultValue={props.zoom}
                                className="slider"
                                id="myRange"
                                step="1"
                                // className="full-width"
                                onChange={props.onZoomChange}
                            />
                        </div>
                    </div>
                    <div className="field_div wid-50">
                        <div className="slider-container" style={{ padding: '5px' }}>
                            <label className="container">
                                Slide to Rotate photo
                            </label>
                            <br />
                            <input type="range"
                                min="0"
                                max="360"
                                defaultValue={props.rotation}
                                style={{ position: 'absolute' }}

                                className="slider"
                                id="myRotate"
                                step="1"
                                //   className="full-width"
                                onChange={props.onRotationChange}
                            />
                        </div>

                        {/* <br /> */}
                        <br />
                    </div>
                    <div className="crop-btn">
                        <CustomizedButton display="inline-block" label="Crop" onClickEvent={() => props.okButton(props.okButton)} />
                        &nbsp;&nbsp;<CustomizedButton display="inline-block" label="Cancel" onClickEvent={() => props.cancelButton(props.formikProps.setFieldValue)} />
                    </div>
                </div> : null}
            </div>



            : null
    );
}

EditVenueImage.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(EditVenueImage);

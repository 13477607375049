import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import "./assets/style/venuePublishPopup.scss";
import { object } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import CustomSelectWithScroll from 'shared/components/CustomSelectWithScroll';
import CustomizedButton from 'shared/components/customizedButton';
import { Formik, Form } from 'formik';
import 'shared/headers/assets/styles/venueHeader.scss';
import 'venue/assets/styles/webCalendar.scss';
import ExtensionPopup from 'shared/components/extensionPopup';
import { googleSignUp } from "shared/googleSignUp/actions";
import { microsoftSignUp } from "shared/microsoftSignUp/actions";
import { getUser } from 'data/person/actions';
import ErrorPopup from 'shared/components/errorPopup';





const styles = theme => ({
    iconImage: {
        height: "24px",
        width: "24px !important",
        marginLeft: "26px",
        '&:hover': {
            backgroundColor: '#d80e2a'
        }
    },
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        top: '8px',
        color: '#9e9e9e',
        right: '8px',
    },
});

withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});



const DialogActions = withStyles(theme => ({
    root: {
    },
}))(MuiDialogActions);

class venueSelectPopup extends React.Component {
    state = {
        open: this.props.open,
        spaceListItems: '',
        spaceId: '',
        editCalendarPopup: null,
        venueSelectPopup: true,
        extensionPopup: false,
        extensionCalenderPopup: false,
        description: '',
        editspaceError: false,
        editspaceErrorMessage: '',
        loaderClickSync: false,
        loaderMSClickSync: false,
        isShown: 0,
        syncVenueId:'',
        syncSpaceId:''
    };


  UNSAFE_componentWillMount(){
    //makeToken=false;
    this.setState({loaderClickSync:false,loaderMSClickSync:false})
    this.props.getUser().then((response) => {
      //localStorage.setItem('profilePhoto',this.props.userData.profilePhoto ? this.props.userData.profilePhoto.path : profile_default);
    })
  }
 componentWillMount()
 {
   let url=''
   if(window.location.pathname.includes('/venueProfileEdit'))
   {
      url= window.location.pathname.split('/')[2]
      this.setState({syncVenueId:url.toString()})
            let selectedSpaces = [];
            let spaceListItems = this.props.spaceListItems;
            if (spaceListItems) {
                selectedSpaces = spaceListItems.filter(data => {
                    return data.venue_id.toString() === url;
                });
            }
            this.setState({ spaceListItems: selectedSpaces });

   }
   if(window.location.pathname.includes('/spaceProfileEdit'))
   {
    url= window.location.pathname.split('/')[2]
    let venueItems = this.props.spaceListItems.find(p => p.id.toString() === url)
    if(venueItems)
    { 
        let selectedSpaces = [];
            let spaceListItems = this.props.spaceListItems;
            if (spaceListItems) {
                selectedSpaces = spaceListItems.filter(data => {
                    return data.venue_id.toString() === venueItems.venue_id.toString();
                });
            }
        this.setState({syncVenueId:venueItems.venue_id.toString()})
        this.setState({description:venueItems.description}) 
        this.setState({ spaceListItems: selectedSpaces }) 
        this.setState({syncSpaceId:url})
    } 
   }
   
 }
    handleCancelClick = () => {
        this.setState({ open: false })
    };

    handleClose = () => {
        this.props.closeVenueSelectPopup()
        this.setState({ open: false })
    };
    onVenueSelect = (e) => {
        let selectedSpaces = [];
        if ('target' in e && e.target.value) {
            let spaceListItems = this.props.spaceListItems;
            if (spaceListItems) {
                selectedSpaces = spaceListItems.filter(data => {
                    return data.venue_id === e.target.value;
                });
            }
            this.setState({ spaceListItems: selectedSpaces });
        }
    }

    onSpaceSelect = (e) => {
        let selectedSpacesList = [];
        let description = ''
        if ('target' in e && e.target.value) {
            let spaceList = this.props.spaceListItems;
            if (spaceList) {
                selectedSpacesList = spaceList.filter(data => {
                    return data.id === e.target.value;
                });
            }
            selectedSpacesList.map(function (data) {
                description = data.description
            })
            this.setState({ description: description })
        }
    }
    handleSubmit = (values, actions) => {
        this.setState({ spaceId: values.spaceId })
        this.setState({ editCalendarPopup: true })
        this.setState({ venueSelectPopup: false })

    }

    onClickGoto = () => {
        if (this.state.description === null || this.state.description === '') {
            this.setState({ editspaceErrorMessage: 'Complete venue set-up before syncing calendar or you may lose data.' })
            this.setState({ editspaceError: true });
        }
        else {
            this.setState({ loaderClickSync: true });
            this.setState({ editspaceErrorMessage: '', editspaceError: false });
            if (this.props.userData.refresh_token && this.props.userData.refresh_token !== '' && this.props.userData.identity_provider != 'microsoft') {
                localStorage.setItem('googleCalendarVal', 'justLooking');
                window.location.assign('/webCalendar/' + this.state.spaceId);
            }
            else {
                localStorage.setItem('spaceId', this.state.spaceId);
                this.props.googleSignUp().then((response) => {
                    /*var link = document.getElementById('saveExit');
                    link.click();  */
                    window.location.assign(response.googleUrlDetails.data.auth_url)
                })
            }
        }

    }

    onSyncMircoSoft = () => {

        if (this.state.description === null || this.state.description === '') {
            this.setState({ editspaceErrorMessage: 'Complete venue set-up before syncing calendar or you may lose data.' })
            this.setState({ editspaceError: true })
        }
        else {
            this.setState({ loaderMSClickSync: true });
            this.setState({ editspaceErrorMessage: '', editspaceError: false });
            let accessToken = undefined;
            if (this.props.userData.social_logins) {
                accessToken = this.props.userData.social_logins.filter(element => element.identity_provider == 'microsoft')[0];
            }

            if (accessToken && accessToken.refresh_token && accessToken.refresh_token !== '') {
                localStorage.setItem('microsoftCalendarVal', 'justLooking');
            window.location.assign('/webMicrosoftCalendar/' +  this.state.spaceId);
            }
            else {

                localStorage.setItem('spaceId', this.state.spaceId);
                this.props.microsoftSignUp().then((response) => {
                    window.location.assign(response.urlDetails.data.auth_url);
                });
            }
        }

    }

    tsLocation = () => {
        this.props.history.push(`/tslocations/${this.state.spaceId}`);
    }

    etSpaces = () => {
        this.props.history.push(`/etspaces/${this.state.spaceId}`);
    }

    showExtension = () => {
        this.setState({ extensionPopup: true })
    }
    showCalenderExtension = () => {
        this.setState({ extensionCalenderPopup: true })
    }
    closeExtPopup = () => {
        this.setState({ loaderClickSync: false, extensionPopup: false, loaderMSClickSync: false })
    }
    closeExtrnlPopup = () => {
        this.setState({ loaderClickSync: false, extensionPopup: false, extensionCalenderPopup: false, loaderMSClickSync: false })
    }


    errorClose = () => {
        this.setState({ editspaceError: false, editspaceErrorMessage: "" })

    }

    render() {
        // const { classes } = props;
        return (

            <div className=" venue-header space-planner-edit webCalendar">
                <Formik
                    initialValues={{
                        venueId: this.state.syncVenueId,
                        spaceId: this.state.syncSpaceId,

                    }}
                    onSubmit={(values, actions) => {
                        this.handleSubmit(values, actions)
                    }}
                >
                    {({ values, handleBlur, handleChange, setFieldValue }) => (
                        <Form>
                            {this.state.editspaceError === true ?
                                <ErrorPopup errorClose={this.errorClose} message={this.state.editspaceErrorMessage} openError={this.state.editspaceError} />
                                : null}
                            {this.state.extensionPopup === true ?
                                <ExtensionPopup close={this.closeExtPopup} />
                                : null}
                            {this.state.extensionCalenderPopup === true ?
                                <ExtensionPopup closeExtrnlPopup={this.closeExtrnlPopup} />
                                : null}
                            <Grid container className="space-planner-edit">
                                <Grid xs={12} item>
                                    <Grid container>
                                        <Grid item md={12} xs={12} sm={12} xl={12} lg={12} className="space-planner-edit-outer-grid">
                                            <Grid container justify="center" className="sync_calendar_popup event_calendar" >
                                                <Grid item className="event_info_popup" style={{ left: (this.state.xSyncCoordinate !== '' && this.state.xSyncCoordinate), top: (this.state.ySyncCoordinate !== '' && this.state.ySyncCoordinate) }}>
                                                    <Grid className="event_date" >
                                                        {this.state.open == true && 'Sync Calendar'}
                                                        <DialogActions className="dialogActions calendar-close">
                                                            <span className="material-icons close closeButton" onClick={() => (this.handleClose())} >close</span>
                                                        </DialogActions>
                                                    </Grid>
                                
                                                    {this.state.venueSelectPopup &&
                                                        <div className="webCalendar p-5">
                                                            {/* <div className="header"></div> */}
                                                            <Grid container className="">
                                                                <Grid item md={4} xs={4} sm={4} lg={4} xl={4}>
                                                                    <label className="bold-text">Select Venue</label>
                                                                </Grid>
                                                                <Grid item md={8} xs={8} sm={8} lg={8} xl={8}>
                                                                    <div className="input-field-section">
                                                                        <CustomSelectWithScroll
                                                                            value={values.venueId}
                                                                            placeholder="Select Venue"
                                                                            options={this.props.options}
                                                                            handleChange={e => { handleChange(e); this.onVenueSelect(e) }}
                                                                            name="venueId"
                                                                            label="Select Venue"
                                                                            handleBlur={handleBlur}
                                                                            setFieldValue={setFieldValue}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item md={4} xs={4} sm={4} lg={4} xl={4}>
                                                                    <label className="bold-text">Select Space</label>
                                                                </Grid>
                                                                <Grid item md={8} xs={8} sm={8}  lg={8} xl={8}>
                                                                    <div className="input-field-section">
                                                                        <CustomSelectWithScroll
                                                                            value={values.spaceId}
                                                                            placeholder="Select Space"
                                                                            options={this.state.spaceListItems}
                                                                            handleChange={e => { handleChange(e); this.onSpaceSelect(e) }}
                                                                            name="spaceId"
                                                                            label="Select Space"
                                                                            handleBlur={handleBlur}
                                                                            setFieldValue={setFieldValue}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item md={4} xs={4} sm={4} lg={4} xl={4}>
                                                                </Grid>
                                                                <Grid item md={8} xs={8} sm={8} lg={8} xl={8}>
                                                                    <div className="buttonContent mt-5">
                                                                        {values.venueId !== '' && values.spaceId !== '' ?
                                                                            <CustomizedButton type="submit" label="Next" disabled={false} />
                                                                            :
                                                                            <CustomizedButton type="submit" label="Next" disabled={true} />
                                                                        }
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    }

                                                    {this.state.editCalendarPopup &&


                                                        <Grid container justify="center" className="cal-box">
                                                            <div style={{ display: 'none' }} className="calendar-icon show-ext-popup" onClick={() => (this.onClickGoto())} onMouseEnter={() => this.setState({ isShown: 1 })} onMouseLeave={() => this.setState({ isShown: 0 })}>
                                                                <img src={require('venue/assets/images/google_calendar.svg')} alt="Google" />
                                                                {this.state.isShown == 1 && (<p>Google</p>)}
                                                            </div>
                                                            <div style={{ display: 'none' }} className="calendar-icon microsoft show-ext-popup" onClick={() => (this.onSyncMircoSoft())} onMouseEnter={() => this.setState({ isShown: 2 })} onMouseLeave={() => this.setState({ isShown: 0 })}>
                                                                <img src={require('venue/assets/images/microsoft_calendar.svg')} alt="Microsoft" />
                                                                {this.state.isShown == 2 && (<p>Microsoft</p>)}
                                                            </div>
                                                            {localStorage.getItem("extensionCalenderPopup") ?
                                                                <div className="calendar-icon hide-ext-popup" onClick={() => (this.onClickGoto())} onMouseEnter={() => this.setState({ isShown: 1 })} onMouseLeave={() => this.setState({ isShown: 0 })}>
                                                                    <img src={require('venue/assets/images/google_calendar.svg')} alt="Google" />
                                                                    {this.state.isShown == 1 && (<p>Google</p>)}
                                                                </div> :
                                                                <div className="calendar-icon hide-ext-popup" onClick={() => this.showCalenderExtension()} onMouseEnter={() => this.setState({ isShown: 1 })} onMouseLeave={() => this.setState({ isShown: 0 })}>
                                                                    <img src={require('venue/assets/images/google_calendar.svg')} alt="Google" />
                                                                    {this.state.isShown == 1 && (<p>Google</p>)}
                                                                </div>
                                                            }
                                                            {localStorage.getItem("extensionPopup") ?
                                                                <div className="calendar-icon microsoft hide-ext-popup" onClick={() => (this.onSyncMircoSoft())} onMouseEnter={() => this.setState({ isShown: 2 })} onMouseLeave={() => this.setState({ isShown: 0 })}>
                                                                    <img src={require('venue/assets/images/microsoft_calendar.svg')} alt="Microsoft" />
                                                                    {this.state.isShown == 2 && (<p>Microsoft</p>)}
                                                                </div> :
                                                                <div className="calendar-icon microsoft hide-ext-popup" onClick={() => this.showExtension()} onMouseEnter={() => this.setState({ isShown: 2 })} onMouseLeave={() => this.setState({ isShown: 0 })}>
                                                                    <img src={require('venue/assets/images/microsoft_calendar.svg')} alt="Microsoft" />
                                                                    {this.state.isShown == 2 && (<p>Microsoft</p>)}
                                                                </div>
                                                            }
                                                            <div className="calendar-icon microsoft" onClick={() => (this.handleClose(), this.tsLocation(this.props.values))} onMouseEnter={() => this.setState({ isShown: 3 })} onMouseLeave={() => this.setState({ isShown: 0, })}>
                                                                <img src={require('venue/assets/images/tripleseat.svg')} alt="Tripleseat" />
                                                                {this.state.isShown == 3 && (<p>Tripleseat</p>)}
                                                            </div>
                                                            <div className="calendar-icon microsoft" onClick={() => (this.handleClose(), this.etSpaces(this.props.values))} onMouseEnter={() => this.setState({ isShown: 4 })} onMouseLeave={() => this.setState({ isShown: 0 })}>
                                                                <img src={require('venue/assets/images/EventTemple.jpg')} alt="Event Temple" />
                                                                {this.state.isShown == 4 && (<p className="EventTemple">Event Temple</p>)}
                                                            </div>

                                                        </Grid>

                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>


            </div>
        );
    }
}

venueSelectPopup.propTypes = {
    classes: object.isRequired,
};

const mapStateToProps = state => {
    return {
        userData: state.data.auth.userData,
    };
};

const enhance = compose(
    withRouter,
    connect(mapStateToProps, dispatch =>
        bindActionCreators(
            {
                googleSignUp,
                microsoftSignUp,
                getUser
            },
            dispatch
        )
    )
);
export default enhance(venueSelectPopup);


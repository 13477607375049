import { GET_STATES } from 'shared/state/types';
import { request } from 'shared/auth';

function getStatesRequested() {
  return {
    type: GET_STATES.REQUEST,
  };
}

function getStatesFailed(error) {
  return {
    type: GET_STATES.FAILURE,
    error:error,
  };
}

function getStatesSuccess(data) {
  return {
    type: GET_STATES.SUCCESS,
    states: data,
  };
}

export function getStates(params) {
  return dispatch => {
    dispatch(getStatesRequested());

    return request().get('/state', params)
      .then(response =>{
          dispatch(getStatesSuccess(response.data.data))
        }
      )
      .catch(errors => {
        if(errors.response){
          dispatch(getStatesFailed(errors.response.data.errors));
        }
        else{
          dispatch(getStatesFailed("Unable to access api"));
        }
        throw errors;
      });
  };
}

import axios from 'axios';
import { FIND_GOOGLE_CALENDAR_EVENTS } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'
import moment from "moment";

function getGoogleCalnderEventsRequested() {
  return {
    type: FIND_GOOGLE_CALENDAR_EVENTS.REQUEST,
  };
}

function getGoogleCalnderEventsSuccess(data) {
  return {
    type: FIND_GOOGLE_CALENDAR_EVENTS.SUCCESS,
    eventList: data,
  };
}

function getGoogleCalnderEventsFailure(error) {
  return {
    type: FIND_GOOGLE_CALENDAR_EVENTS.FAILURE,
    error:error,
  };
}

const request = () => {
  const headers = {};

  if(localStorage.getItem('accesstoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('accesstoken');

  }
  const axiosInstance = axios.create({
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;
};

export function getGoogleCalendaEvents(id,year=moment().year(),nextPageToken) {

    return dispatch => {
      dispatch(getGoogleCalnderEventsRequested());
        var d = new Date();
        // var n = d.getFullYear();
        if(d.getFullYear()!=year){
          d.setDate(1);
        }
        d.setFullYear(year);
        let timeMin = d.getFullYear()+'-'+((parseInt(d.getMonth())+1)>9?(parseInt(d.getMonth())+1):'0'+(parseInt(d.getMonth())+1))+'-'+d.getDate()+'T00:00:00-07:00';
        
        d.setDate(moment().add(2, 'years').subtract(1,'months').endOf('month').format("D"));
        d.setMonth(moment().add(2, 'years').subtract(1,'months').endOf('month').format("M")-1);
        let timeMax = (parseInt(d.getFullYear())+2)+'-'+((parseInt(d.getMonth())+1)>9?(parseInt(d.getMonth())+1):'0'+(parseInt(d.getMonth())+1))+'-'+d.getDate()+'T00:00:00-07:00';
        if(nextPageToken!==""&&nextPageToken!==undefined)
        {
        return request().get('https://www.googleapis.com/calendar/v3/calendars/'+encodeURIComponent(id)+'/events?timeMin='+timeMin+'&timeMax='+timeMax+'&orderBy=startTime&singleEvents=true'+'&pageToken='+nextPageToken)
        .then(response => {
          if(response.status === 200){
            dispatch(getGoogleCalnderEventsSuccess(response.data))
            return response.data;
          }
          else if(response.status === 400){
            dispatch(getGoogleCalnderEventsFailure("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(getGoogleCalnderEventsFailure("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(getGoogleCalnderEventsFailure("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(getGoogleCalnderEventsFailure("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(getGoogleCalnderEventsFailure("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(getGoogleCalnderEventsFailure("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(getGoogleCalnderEventsFailure("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(getGoogleCalnderEventsFailure("The server was unavailable."));
          }
          else{
            dispatch(getGoogleCalnderEventsFailure("The server was unavailable."));
          }
          dispatch (getGoogleCalnderEventsSuccess(response.data),
          )
          return response.data;
        }
        ) 
      
        .catch(errors => {
          if(errors && errors){
            dispatch(getGoogleCalnderEventsFailure(errors.toString()));
          }
          else{
             dispatch(getGoogleCalnderEventsFailure("Something went wrong."));
          }

          return 0;
          
  
          
        });
      }
      else
      {
        return request().get('https://www.googleapis.com/calendar/v3/calendars/'+encodeURIComponent(id)+'/events?timeMin='+timeMin+'&timeMax='+timeMax+'&orderBy=startTime&singleEvents=true')
        .then(response => {
          if(response.status === 200){
            dispatch(getGoogleCalnderEventsSuccess(response.data))
            return response.data;
          }
          else if(response.status === 400){
            dispatch(getGoogleCalnderEventsFailure("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(getGoogleCalnderEventsFailure("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(getGoogleCalnderEventsFailure("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(getGoogleCalnderEventsFailure("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(getGoogleCalnderEventsFailure("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(getGoogleCalnderEventsFailure("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(getGoogleCalnderEventsFailure("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(getGoogleCalnderEventsFailure("The server was unavailable."));
          }
          else{
            dispatch(getGoogleCalnderEventsFailure("The server was unavailable."));
          }
          dispatch (getGoogleCalnderEventsSuccess(response.data),
          )
          return response.data;
        }
        ) 
      
        .catch(errors => {
          if(errors && errors){
            dispatch(getGoogleCalnderEventsFailure(errors.toString()));
          }
          else{
             dispatch(getGoogleCalnderEventsFailure("Something went wrong."));
          }

          return 0;
          
  
          
        });
      }

    
 };
}

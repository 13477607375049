import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form  } from 'formik';
import { withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import '../assets/styles/search.scss';
import CustomizedButton from '../../shared/components/customizedButton';
import { Datepicker } from 'react-formik-ui'
import Autocomplete from "react-autocomplete"; 
import SelectEvent from 'shared/components/SelectEvent'; 
import SelectSeating from 'shared/components/SelectSeating'; 
import SelectGuest from 'shared/components/SelectGuest'; 
import SelectBudget from 'shared/components/SelectBudget'; 
import moment from "moment";

const styles = theme => ({
});

function HomeSearch(props) {
const { classes } = props;
const today = new Date();
today.setDate(today.getDate() + 1);
var currentDate = moment().toDate();
const selectSearch=()=>{
   var link = document.getElementById('browse');
   link.click();
}
let menuItems = props.menuData;
return (
<Grid container className="home-search">
   <Grid xs={12} item>&nbsp;</Grid>
   <Grid xs={12} item>
      <Grid container justify="center">
         <Formik 
         initialValues={{ address: "" }}
         onSubmit={(values, { setSubmitting }) => {
            let searchData = '';
            let searchLink ='./browseVenue';
            let type;
            if(values.addressKey) {

              type = values.addressKey.split('|')[1];
            }
            Object.keys(values).forEach(key=>{
              if(key !=='addressKey' && key !=='address' ){
                searchData  += searchData!=='' ?'&' + key+'='+values[key] : '?' + key+'='+values[key]
              }
            });
            
            if(parseInt(type)===1){
              if(searchData!==''){
                searchLink = '/browseVenue'+searchData+'&address='+encodeURIComponent(values.address); 
              } else {
                searchLink = '/browseVenue?address='+encodeURIComponent(values.address);
              }               
            }
            else if(parseInt(type)===2){
              searchLink = '/venue-profile/'+values.addressKey.split('|')[0]+searchData  
            }  
            else if(parseInt(type)===3){
              searchLink = '/browseVenue/'+values.address+'/type'+searchData  
            } else {
               if(searchData===''){
                  searchLink = '/browseVenue?address='+encodeURIComponent(values.address) 

               }
               else
               {
                  searchLink = '/browseVenue'+searchData 

               }
            }
            window.location.href= searchLink;          
            setSubmitting(false);
         }}
         >
         {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         setFieldValue
         /* and other goodies */
         }) => (
        <Form method="GET">
         <div className="searchBgBox">
            <Grid container justify = "center">
               <Grid container className={classes.root}>
                  <div className="form-wrapper-2 cf">
                     <Grid item xs={12}>
                        <Grid container className="search_container">
                        <div 
                        onClick={()=>selectSearch()}
                        style={{
                        cursor:"pointer",
                        width: '60px !important',
                        height: '46px !important',
                        position: 'absolute',
                        top: '0px'}}>search</div>
                        <Autocomplete
                            fields={{ groupBy: 'value', value: 'menu' }}
                            value={values.address}
                            inputProps={{id: 'states-autocomplete',placeholder:'Search by Venue, City or Region'}}
                            wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                            items={menuItems}
                            getItemValue={item => item.title}
                            shouldItemRender={(item, val) => 
                              {
                                 if(val.toLowerCase()=== "dallas"){
                                    let dallas = menuItems.filter(item => item.title==='Saddle up! We’ll be your way shortly');
                                    if(dallas.length==0){
                                      menuItems.push({title: "Saddle up! We’ll be your way shortly", id: "No", type: 10, default: 0});
                                    }
                                    val = 'Saddle up! We’ll be your way shortly';
                                  }else{
                                    let dIndex = menuItems.findIndex( x => x.title === 'Saddle up! We’ll be your way shortly');
                                    if(dIndex > -1){menuItems.splice(dIndex,1)}
                                  }
  
                                  if(val.toLowerCase()=== "chicago"){
                                    let chicago = menuItems.filter(item => item.title==='Hold onto your hat! We’ll be there soon');
                                    if(chicago.length==0){
                                      menuItems.push({title: "Hold onto your hat! We’ll be there soon", id: "No", type: 10, default: 0});
                                    }
                                    val = 'Hold onto your hat! We’ll be there soon';
                                  }else{
                                    let cIndex = menuItems.findIndex( x => x.title === 'Hold onto your hat! We’ll be there soon');
                                    if(cIndex > -1){ menuItems.splice(cIndex,1)}
                                  }

                                 if(item.title.toLowerCase().indexOf(val.toLowerCase()) !== -1 || ((val.toLowerCase()== "wine" || val.toLowerCase()== "wine country" ) && item.title.toLowerCase().indexOf("Napa & Sonoma".toLowerCase()) !== -1) ){
                                    return item;
                                 }                             
                              }
                            }
                            header={<span style={{ marginLeft: '60px' }}>header</span>}
                            renderMenu={item => (
                              <div className = "suggestionDiv">
                                    { item }
                              </div>
                            )}
                            renderItem={(item, isHighlighted) =>
                              <div className={`head`}>
                                
                                {values.address===''?
                                  item.default===1 ?
                                    <div className={`item ${isHighlighted ? 'item-highlighted' : ''}`}>
                                      {item.title}
                                    </div>
                                    :
                                    ''
                                    :
                                    <div className={`item ${isHighlighted ? 'item-highlighted' : ''}`}>
                                      {item.title}
                                    </div>
                                }
                              </div>
                            }
                            onChange={(event, val) => setFieldValue('address', val)}
                            onSelect={(val,item) => { if(item.id!=='No' && item.id!=='Title'){ selectSearch(item.title);setFieldValue('address',item.title); setFieldValue('addressKey',item.id); }}}
                            />
                        {/* <Autocomplete
                        
                        fields={{ groupBy: 'value', value: 'menu' }}
                         value={ values.address }
                         inputProps={{id: 'states-autocomplete',placeholder:'Search by Venue, City or Region'}}
                         wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                         items={ props.menuData }
                         getItemValue={ item => item.key }
                         //shouldItemRender={ matchSuggestion }
                         onChange={(e) => {props.onSearchChnage(e.target.value); setFieldValue('address', e.target.value)}}
                         onSelect={(val,item) => {if(item.id!=='No'){ selectSearch(item.key);setFieldValue('address',item.key); setFieldValue('addressKey',item.id); }}}
                         renderMenu={ children => (
                         <div className = "suggestionDiv">
                              { children }
                         </div>
                          )}
                         renderItem={ (item, isHighlighted) => (
                         <div
                         className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                         key={ item.id } >
                         { item.key }
                         </div>
                          )}
                          /> */}
                           {/* <Autocomplete
                           value={values.value}
                           inputProps={{ id: 'states-autocomplete', placeholder:'Enter A Venue Name, City* or Address' }}
                           wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                           items={ getVenue(values.value) }
                           getItemValue={ item => item.name }
                           shouldItemRender={ matchVenue }
                           onChange={(e) => setFieldValue('value', e.target.value)}
                           onSelect={(val) => setFieldValue('value', val)}
                           renderMenu={ children => (
                           <div className = "suggestionDiv">
                              { children }
                           </div>
                           )
                           }
                           renderItem={ (item, isHighlighted) => (
                           <div
                           className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                           key={ item.abbr } >
                           {item.name}
                  </div>
                  )}
                  /> */}
                  <CustomizedButton name="browse" id="browse" label="" height='50px' width= '137px' type="submit"/>
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                     <Grid container className="search_container2">
                        <Grid className="date_pick" item md={2} sm={6}>
                        {values.endDate
                                ?<Datepicker
                                onKeyDown={e => e.preventDefault()}
                                className='datepiker' 
                                placeholder="Start Date" name='startDate' 
                                       minDate={currentDate}
                                       maxDate={moment(
                                         values.endDate
                                       ).toDate()}
                                       dateFormat="MM/dd/yyyy"/>
                        
                                       :
                        <Datepicker
                        onKeyDown={e => e.preventDefault()}
                        className='datepiker' 
                        placeholder="Start Date" name='startDate' 
                               minDate={currentDate}
                               dateFormat="MM/dd/yyyy"/>
                     }
                   
                        </Grid>
                        <Grid className="date_pick end-date" item md={2} sm={6}>
                           <Datepicker className='datepiker'
                           onKeyDown={e => e.preventDefault()}

                                      minDate={moment(
                                        values.startDate
                                      ).toDate()}
                                      dateFormat="MM/dd/yyyy" 
                                      placeholder="End Date" 
                                      name='endDate'  />
                        </Grid>
                        <Grid className="custom-select" item md={2} sm={6}>
                           <SelectEvent color="#62717a" options={props.categoryType}/>
                        </Grid>
                        <Grid className="custom-select" item md={2} sm={6}>
                           <SelectSeating color="#62717a" options={props.seatingType}/>
                        </Grid>
                        <Grid className="custom-select" item md={2} sm={6}>
                           <SelectGuest color="#62717a"/>
                        </Grid>
                        <Grid className="custom-select" item md={2} sm={6}>
                           <SelectBudget color="#62717a"/>
                        </Grid>
                     </Grid>
                  </Grid>
         </div>
         </Grid>
         <Grid container justify = "center">
         <Grid container className={classes.root}>
         <div className="search_bottom">
         <Grid item xs={12}>
         <Grid item xl={12}>
         {/* <span>*Currently only in San Francisco Bay Area</span> */}
         </Grid>
         </Grid>
         </div>
         </Grid>
         </Grid>
         </Grid>
         </div> 
         </Form>
         )}
         </Formik>
      </Grid>
   </Grid>
</Grid>
);
}
HomeSearch.propTypes = {
classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(HomeSearch);

import React, { Component } from 'react';
import CustomizedCheckBox from '../../../shared/components/CustomizedCheckbox';
import CustomSelectWithScroll from '../../../shared/components/CustomSelectWithScroll';
import '../assets/styles/calendar.scss';
// import { ErrorMessage } from 'formik';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom'

const elements = ['1', '2', '3'];
const date = ['Thursday 8/24/17'];
const time = ['1:00 p.m. - 2:00 p.m.'];
const reason = ['Office Meeting'];

const options = [
    '1st Hold',
    '2nd Hold',
    '3rd Hold',
    'Booked',
    'Blocked',

];

class Calendar extends Component {

  state = {
    redirect: false,
  };

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='spaceProfileEdit' />
    }
  }

  render() {
    return (
      <div className="home_page syncing_calendar">
        <div className="search_page"></div>
        <Grid container className="main-form">
          <Grid item md={8} lg={8} xl={8} >
            <p>The following events were not assigned a hold status.
You can manually assign them a status now, if applicable, before proceeding.</p>
            {/* <p className="small">They will be assigned a hold status in the FIRST-HOLD system.
Review and make edits below: </p> */}
          </Grid>
          <Grid item md={12} lg={12} xl={12} >
            <h3>FIRST-HOLD Status</h3>
          </Grid>
          {elements.map((value, index) => {
            return <Grid container className="data-table">
              <Grid item md={3} lg={3} xl={3} className="vertical_align_date">
                <h5>{date}</h5>
                <p className="small">{time}</p>
              </Grid>
              <Grid item md={3} lg={3} xl={3} className="vertical_align_reason">
                <h6>{reason}</h6>
              </Grid>
              <Grid item md={6} lg={6} xl={6} >
                <Formik
                  initialValues={{ category: " " }}
                  render={props => (
                    <form onSubmit={props.handleSubmit}>
                       <CustomSelectWithScroll placeholder="Status" options={options} name="Status" className="custom_select"/>
                      <CustomizedCheckBox name='webCalendar' value={props.values.webCalendar} label="Apply status to all repeat instances of this event" />
                    </form>
                  )}
                />
              </Grid>
            </Grid>
          })}
          <Grid item md={8} lg={8} xl={8} >
            {this.renderRedirect()}
            <button onClick={this.setRedirect}>Next</button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (Calendar);

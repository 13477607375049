import { Record } from 'immutable';

import {
  GET_BROWSE_VENUE_REQUEST,
  GET_BROWSE_VENUE_FAILURE,
  GET_BROWSE_VENUE_SUCCESS,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_FAILURE,
  GET_CATEGORY_SUCCESS,
  GET_ARRANGEMENTS_REQUEST,
  GET_ARRANGEMENTS_FAILURE,
  GET_ARRANGEMENTS_SUCCESS,
  GET_CITY_REQUEST,
  GET_CITY_FAILURE,
  GET_CITY_SUCCESS
} from 'shared/state/types';

class State extends Record({
  browseData:{},
  categoryData:{},
  arrangementData:{},
  error:'',
  sending:'',
  sended:'',
  cityData:{}

  
}) {}


export default function reducer(state = new State(), action) {
  switch (action.type) {
    case GET_BROWSE_VENUE_REQUEST:
      return state.merge({
        error:'',
        sending:true,
        sended:false

      });
    case GET_BROWSE_VENUE_FAILURE:
      return state.merge({
        browseData:{},
        error: action.error,
        sending:false,
        sended:false

      });
    case GET_BROWSE_VENUE_SUCCESS:
      return state.merge({
        browseData:action.browseVenueDetails,
        error:'Success',
        sending:false,
        sended:true
      });

      case GET_CATEGORY_REQUEST:
      return state.merge({
        error:''
      });
      case GET_CATEGORY_FAILURE:
      return state.merge({
        categoryData:{},
        error: action.error


      });
      case GET_CATEGORY_SUCCESS:
      return state.merge({
        categoryData:action.categoryData,
        error:'Success'
      });
      case GET_ARRANGEMENTS_REQUEST:
      return state.merge({
        error:''
      });
      case GET_ARRANGEMENTS_FAILURE:
      return state.merge({
        arrangementData:{},
        error: action.error


      });
      case GET_ARRANGEMENTS_SUCCESS:
      return state.merge({

        arrangementData:action.arragementDetails,
        error:'Success'
      });



      case GET_CITY_REQUEST:
      return state.merge({
        error:'',
        success:'',
        sending:true,
        sended:false

      });
    case GET_CITY_FAILURE:
      return state.merge({
        cityData:{},
        error: action.error,
        sending:false,
        sended:false,
        success:''

      });
    case GET_CITY_SUCCESS:
      return state.merge({
        cityData:action.cityDetails,
        success:'',
        error:'',
        sending:false,
        sended:true
      });

      default:
      return state;
  }
}


import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Icon, Hidden } from "@material-ui/core";
import './assets/style/StepProgressBar.scss';

const styles = theme => ({
  button: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: ' 0.5px',
    textAlign: 'center',
    color: '#202020',
    '&:hover': {
      background: "#ffffff",
    },
    '&:disabled': {
      background: '#ffffff',
      color: '#202020 !important'
    },
  },
  saveAndExit: {
    fontSize: '13px',
    fontWeight: '500',
    letterSpacing: '0.46px',
    color: '#202020',
    paddingTop: '7px',
    textDecoration: 'none',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      color: '#202020',
      textDecoration: 'none',
      cursor: 'pointer',
      background: 'none',
      boxShadow: 'none',
    },
    '&:disabled': {
      background: '#ffffff',
      color: '#202020 !important'
    },   
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    color: '#d81c2c',
    fontSize: '17px'
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
    color: '#d81c2c',
    fontSize: '17px'
  },
});

function StepProgressBar(props) {
  const { classes } = props;
  return (
    <Grid container className="step_progress_bar">
      <Hidden smDown={true}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Grid container>
            <Grid className="add_venue_heading" item xs={6}>
              {props.formName}
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" size="medium" className={classes.button} onClick={props.decrementStep} disabled={props.step === 1}>
                <Icon className={classNames(classes.leftIcon, classes.iconSmall)} >
                  arrow_back
                  </Icon>
                Back
                </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="progress_bar" item xs={12} sm={4} md={4} lg={4} xl={4}>
          {props.step === 1 ?
            <img src={require('./assets/images/step1.svg')} alt="Step1" />
            : props.step === 2
              ? <img src={require('./assets/images/step2.svg')} alt="Step2" />
              : props.step === 3
                ? <img src={require('./assets/images/step3.svg')} alt="Step3" />
                : <img src={require('./assets/images/step4.svg')} alt="Step4" />
          }
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Grid container>
            <Grid item xs={6}>
              <Grid container justify="flex-end" style={{flexWrap:'nowrap'}}>
              {props.step !== 4 ?
                <Button variant="outlined" size="medium" className={classes.button}
                  disabled={props.isSubmitting  || (props.step === 4)}
                  onClick={(e) => {
                    props.step === 4 ?
                    props.changeSubmission('save')
                    :
                    props.changeSubmission('next')
                    props.handleSubmit()
                  }}
                >
                  Next
                    <Icon className={classNames(classes.rightIcon, classes.iconSmall)} >
                    arrow_forward
                    </Icon>
                </Button>
                :
                <Button variant="outlined" size="medium" className={classes.button}
                  disabled={props.isSubmitting || (props.formName === 'Add Venue' && props.step !== 4)}
                  onClick={(e) => {
                    props.changeSubmission('save')
                    props.handleSubmit()
                  }}
                >{props.formName==='Add Space'||props.formName==='Edit Space'?'Save':'Save & Exit'}{props.formName!=='Add Space'&&props.formName!=='Edit Space'?<Icon className={classNames(classes.rightIcon, classes.iconSmall)} >
                arrow_forward
                </Icon>:null}</Button>

              }
              {
                (props.formName==='Add Space'||props.formName==='Edit Space')&&props.step===4?

                <Button variant="outlined" size="medium" className={classes.button} style={{marginLeft:'20px' , whiteSpace:'nowrap'}}
                  disabled={props.isSubmitting || (props.formName === 'Add Venue' && props.step !== 4)}
                  onClick={(e) => {
                    props.handleExit("exit")
                    props.handleSubmit()
                  }}
                >Save & Exit
                <Icon className={classNames(classes.rightIcon, classes.iconSmall)} >
                    arrow_forward
                    </Icon>
                </Button>:''
                }
              </Grid>
            </Grid>
            {/* <Grid item xs={6}>
              <Grid container justify="flex-end" className="btn-sapce-block ">
                <Button classes={{
                  root: classes.saveAndExit
                }}
                  disabled={props.isSubmitting || (props.formName === 'Add Venue' && props.step !== 4)}
                  onClick={(e) => {
                    props.changeSubmission('save')
                    props.handleSubmit()
                  }}
                >Save & Exit</Button>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp={true}>
        <Grid item xs={12}>
          <Grid container>
            <Grid className="add_venue_heading" item xs={6}>
              {props.formName}
            </Grid>
          </Grid>
        </Grid>
        <Grid className="progress_bar" item xs={12}>
          {props.step === 1 ?
            <img className="mobile_view_progress_bar" src={require('./assets/images/step1.svg')} alt="Step1" />
            : props.step === 2
              ? <img className="mobile_view_progress_bar" src={require('./assets/images/step2.svg')} alt="Step2" />
              : props.step === 3
                ? <img className="mobile_view_progress_bar" src={require('./assets/images/step3.svg')} alt="Step3" />
                : <img className="mobile_view_progress_bar" src={require('./assets/images/step4.svg')} alt="Step4" />
          }
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Button variant="outlined" size="medium" className={classes.button} onClick={props.decrementStep} disabled={props.step === 1}>
                <Icon className={classNames(classes.leftIcon, classes.iconSmall)} >
                  arrow_back
                  </Icon>
                Back
                </Button>
            </Grid>
            <Grid item xs={6}>
              <Grid container justify="flex-end" style={{flexWrap:'nowrap'}}>
                {props.step !== 4 ?
                  <Button variant="outlined" size="medium" className={classes.button}
                    disabled={props.isSubmitting  || (props.step === 4)}
                    onClick={() => {
                      props.changeSubmission('next')
                      props.handleSubmit()
                    }}
                  >
                    Next
                      <Icon className={classNames(classes.rightIcon, classes.iconSmall)} >
                      arrow_forward
                      </Icon>
                  </Button>
                  :
                  
                <Button variant="outlined" size="medium" className={classes.button}
                disabled={props.isSubmitting || (props.formName === 'Add Venue' && props.step !== 4)}
                onClick={(e) => {
                  props.changeSubmission('save')
                  props.handleSubmit()
                }}
              >{props.formName==='Add Space'||props.formName==='Edit Space'?'Save':'Save & Exit'}
              {props.formName!=='Add Space'&&props.formName!=='Edit Space'?<Icon className={classNames(classes.rightIcon, classes.iconSmall)} >
                arrow_forward
                </Icon>:null}
              </Button>
                }
                 {
                (props.formName==='Add Space'||props.formName==='Edit Space')&&props.step===4?

                <Button variant="outlined" size="medium" className={classes.button} style={{marginLeft:'20px', whiteSpace:'nowrap'}}
                  disabled={props.isSubmitting || (props.formName === 'Add Venue' && props.step !== 4)}
                  onClick={(e) => {
                    props.handleExit("exit")
                    props.handleSubmit()                   
                  }}
                >Save & Exit
                <Icon className={classNames(classes.rightIcon, classes.iconSmall)} >
                    arrow_forward
                    </Icon>
                </Button>:''
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            {/* <Button classes={{
              root: classes.saveAndExit
            }}
              disabled={props.isSubmitting || (props.formName === 'Add Venue' && props.step !== 4)}
              onClick={() => {
                props.changeSubmission('save')
                props.handleSubmit()
              }}
            >Save & Exit</Button> */}
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
}

StepProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = withStyles(styles)

export default enhance(StepProgressBar);
import { request } from 'shared/auth';
import { RESET_PASSWORD } from 'shared/state/types';

function resetPasswordRequest() {
    return {
        type: RESET_PASSWORD.REQUEST
    };
}
function resetPasswordSuccess(data) {
    return {
        type: RESET_PASSWORD.SUCCESS,
        message: data,
    };
}
function resetPasswordFailure() {
    return {
        type: RESET_PASSWORD.FAILURE
    };
}

export function resetPassword(params) {
    return dispatch => {
        dispatch(resetPasswordRequest());
        return request()
            .post('/password_reset/reset', params)
            .then(response => {
                dispatch(resetPasswordSuccess(response.data.message));
                return response
            })
            .catch(error => {
                dispatch(resetPasswordFailure());
            })
    };
}

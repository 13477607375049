import { request } from 'shared/auth';
import { LOGIN_MOBILE,LOGIN } from 'shared/state/types';

function onRequested() {
  return {
    type: LOGIN_MOBILE.REQUEST,
  };
}

function onSuccess(data) {
  return {
    type: LOGIN,
    payload: data,
  };
}

function onVerificationSuccess(data) {
  return {
    type: LOGIN_MOBILE.SUCCESS,
    payload: data,
  };
}

function onFailure(data) {
  return {
      type: LOGIN_MOBILE.FAILURE,
      payload: data,
  };
}

export function loginWithEmail(params) {
 return dispatch => {
  dispatch(onRequested());
   return request()
     .post('/auth/login-with-email', params)
     .then(response => {
        if('data' in response.data &&'token' in response.data.data){
          dispatch(onSuccess(response.data.data));
          return response.data.data;
        }else if('message' in response.data){
          dispatch(onVerificationSuccess(response.data.message));   
        }        
        return response.data;
      })
    .catch(errors => {      
        dispatch(onFailure(errors.response.data.message));
      throw errors;
    })
 };
}

import React from 'react';
import { Grid } from '@material-ui/core';
import './assets/styles/webCalendar.scss';
import { Formik, Form } from 'formik';
import { bindActionCreators, compose } from "redux";
import CustomSelect from '../shared/components/CustomSelect';
import CustomSelectWithScroll from '../shared/components/CustomSelectWithScroll';
import { Redirect } from 'react-router-dom'
import { userDetails,venueList} from '../shared/venueList/actions';
import { getSpace, updateSpace } from '../../src/data/spaces/actions';
import { getVenue } from '../../src/data/venues/actions';
import { getTSLocations } from '../../src/data/venues/actions/getTSLocations';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomizedButton from '../shared/components/customizedButton';
import ErrorPopup from '../shared/components/errorPopup';
import CustomizedCheckBox from '../shared/components/CustomizedCheckbox';
import ModalBoxTripleseat from "../shared/components/ModalBoxTripleseat";
import SyncCheckBox from "../../src/shared/components/SyncCheckbox";
import VenueSpaces  from "../../src/venueOwner/components/venueSpaces";
import { getCronData } from '../../src/data/venues/actions/getCronData';

class TSLocations extends React.Component {
  state = {
    spaceId: '',
    vanueId: '',
    spaceName: '',
    calenderItems: '',
    venueData: '',
    spaceList: [],
    openError: false,
    roomItems: '',
    contactsItems: '',
    usersItems: '',
    userType: false,

    venuenSpaces:false,
    selectedSpaces:[],
    spaces:{},
    selectedSpaces:[],
    selectedSpaceIndex:0,
    selectedSpaceType:1,
    contactPage:1,
    userPage:1,
    initial:false,
    contact_total_pages:0,
    user_total_pages:0,
    location_id:'Select',
    room_id:'Select',
    ts_contact_id:'',
    ts_user_id:'',
    ts_space_name:'',
    ts_account_id:''
  }
  
  componentWillMount() {
    this.props.userDetails().then((response) => {
      if(response.userInfo.data.tripleseat_status===0)
      {
      this.setState({openError:true})
      }
      })
  let tsLocations=[]
    this.setState({ spaceId: this.props.match.params.id });
    
    this.setState({ spaces: [(this.props.match.params.id).toString()]});
    this.props.venueList().then(() => {}).catch(error =>{});    

    this.props.getTSLocations(this.state.contactPage,this.state.userPage).then((result) => {
      let stateArray = [];
      let contactsArray = [];
      let usersArray = [];
      if ('locations' in result && Array.isArray(result.locations)) {
        result.locations.map(function (data) {
          stateArray.push({ "id": data.location.id, "name": data.location.name, "rooms": data.location.rooms })
          return stateArray;
        });
      }
      if ('contacts' in result && 'results' in result.contacts && Array.isArray(result.contacts.results)) {
        result.contacts.results.map(function (data) {
          contactsArray.push({ "id": data.id, "name": data.first_name + " " + data.last_name, "account_id": data.account_id })
          return contactsArray;
        });
        this.setState({contact_total_pages:result.contacts.total_pages})
      }
      if ('users' in result && 'results' in result.users && Array.isArray(result.users.results)) {
        result.users.results.map(function (data) {
          usersArray.push({ "id": data.id, "name": data.first_name + " " + data.last_name })
          return usersArray;

        });
        this.setState({user_total_pages:result.users.total_pages})
      }
      tsLocations=stateArray
      this.setState({ calenderItems: stateArray, contactsItems: contactsArray, usersItems: usersArray });
      this.props.getCronData(this.props.match.params.id,'tripleseat' ).then((resultData) => {
        const space_ids = resultData.data.spaces_ids.split(',');
        let ts_location_ids=resultData.data.ts_location_id.toString()
        let ts_room_ids=resultData.data.ts_room_ids.toString()
        let ts_contact_data=resultData.data.ts_contact_id
        let ts_user_data=resultData.data.ts_user_id
        let ts_space_name=resultData.data.ts_space_name
        let ts_account_id=resultData.data.ts_account_id
  
        this.setState({spaces:space_ids,location_id:ts_location_ids,room_id:ts_room_ids,ts_account_id:ts_account_id})
        if(ts_contact_data!=null)
        {
         this.setState({ts_contact_id:ts_contact_data,userType:false})
        }
        if(ts_user_data!=null)
        {
         this.setState({ts_user_id:ts_user_data,userType:true})
        }
        //let locations = tsLocations;
        let roomsArray = [];
        let sLocation = tsLocations.find(x => x.id == ts_location_ids);
        if ('rooms' in sLocation && Array.isArray(sLocation.rooms)) {
          sLocation.rooms.map(function (data) {
            if (!data.is_unassigned) {
              if(data.id.toString()===ts_room_ids)
              {
                                ts_space_name=data.name
              }
              roomsArray.push({ "id": data.id, "name": data.name });
              return roomsArray;
            }
          });
        }
        this.setState({ roomItems: roomsArray ,ts_space_name:ts_space_name});
        
     }).catch(error =>{
     //this.setState({location_id:'Select',room_id:'Select'})
     });
    }).catch(error => {
      this.setState({ openError: true });
    });

    this.props.getSpace(this.props.match.params.id).then((res) => {
      this.setState({ spaceName: res.name })
      this.setState({ vanueId: res.venue_id })
      this.props.getVenue(this.state.vanueId).then((response) => {
        this.setState({ venueData: response })
        this.setState({ spaceList: response.data.spaces })
      }).catch(error => { });
    }).catch(error => { });
  //   this.props.getCronData(this.props.match.params.id,'tripleseat' ).then((resultData) => {
  //     const space_ids = resultData.data.spaces_ids.split(',');
  //     let ts_location_ids=resultData.data.ts_location_id.toString()
  //     let ts_room_ids=resultData.data.ts_room_ids.toString()
  //     let ts_contact_data=resultData.data.ts_contact_id
  //     let ts_user_data=resultData.data.ts_user_id
  //     let ts_space_name=resultData.data.ts_space_name

  //     this.setState({spaces:space_ids,location_id:ts_location_ids,room_id:ts_room_ids,ts_space_name:ts_space_name})
  //     if(ts_contact_data!=null)
  //     {
  //      this.setState({ts_contact_id:ts_contact_data,userType:false})
  //     }
  //     if(ts_user_data!=null)
  //     {
  //      this.setState({ts_user_id:ts_user_data,userType:true})
  //     }
  //     let locations = tsLocations;
  //     let roomsArray = [];
  //     let sLocation = tsLocations.find(x => x.id == ts_location_ids);
  //     alert(JSON.stringify(tsLocations))
  //     if ('rooms' in sLocation && Array.isArray(sLocation.rooms)) {

  //       sLocation.rooms.map(function (data) {
  //         alert("hh")
  //         if (!data.is_unassigned) {
  //           roomsArray.push({ "id": data.id, "name": data.name });
  //           return roomsArray;
  //         }
  //       });
  //     }
  //     this.setState({ roomItems: roomsArray });
      
  //  }).catch(error =>{
  //  //this.setState({location_id:'Select',room_id:'Select'})
  //  });
  } 
 
  updatePage=()=>{
    let contactPageNo=this.state.contactPage
    let userPageNo=this.state.userPage
    let userTypeCheck=this.state.contactPage
    let totalPages=this.state.contact_total_pages
    if(this.state.userType != "false")
      {
        userTypeCheck=userTypeCheck
        totalPages=totalPages
      }
      else{
        userTypeCheck=this.state.userPage
        totalPages=this.state.user_total_pages
      }
     if(this.state.userType != "false"){
       contactPageNo=contactPageNo+1
      this.setState({contactPage:contactPageNo})
    }
    else{
    userPageNo=userPageNo+1
     this.setState({userPage:userPageNo})
    }if(userTypeCheck+1<=totalPages){
    this.props.getTSLocations(contactPageNo,userPageNo).then((result) => {
      let stateArray = [];
      let contactsArray = [];
      let usersArray = [];
      if ('locations' in result && Array.isArray(result.locations)) {
        result.locations.map(function (data) {
          stateArray.push({ "id": data.location.id, "name": data.location.name, "rooms": data.location.rooms })
          return stateArray;
        });
      }if(this.state.userType != "false"){
      if ('contacts' in result && 'results' in result.contacts && Array.isArray(result.contacts.results)) {
        result.contacts.results.map(function (data) {
          contactsArray.push({ "id": data.id, "name": data.first_name + " " + data.last_name, "account_id": data.account_id })
          return contactsArray;
        });
      }
    }
    else{
      if ('users' in result && 'results' in result.users && Array.isArray(result.users.results)) {
        result.users.results.map(function (data) {
          usersArray.push({ "id": data.id, "name": data.first_name + " " + data.last_name })
          return usersArray;

        });
      }
    }
      this.setState({ calenderItems: stateArray, contactsItems: this.state.contactsItems.concat(contactsArray), usersItems: this.state.usersItems.concat(usersArray)});
    }).catch(error => {
      this.setState({ openError: true });
    }); 
    }
  }
  onLocation = (e) => {
    let roomArray = [];
    if ('target' in e && e.target.value) {
      let locations = this.state.calenderItems;
      let sLocation = locations.find(x => x.id == e.target.value);
      if ('rooms' in sLocation && Array.isArray(sLocation.rooms)) {
        sLocation.rooms.map(function (data) {
          if (!data.is_unassigned) {
            roomArray.push({ "id": data.id, "name": data.name });
            return roomArray;
          }
        });
      }
      this.setState({ roomItems: roomArray });
    }
  }

  onContact = (e, setFieldValue) => {

    if ('target' in e && e.target.value) {
      let contacts = this.state.contactsItems;
      let sContact = contacts.find(x => x.id == e.target.value);
      if ('account_id' in sContact) {
        setFieldValue('ts_account_id', sContact.account_id);
      }
    }
  }
  onUser = (e, setFieldValue) => {

    if ('target' in e && e.target.value) {
      let users = this.state.usersItems;
      let sContact = users.find(x => x.id == e.target.value);
      if ('account_id' in sContact) {
        setFieldValue('ts_account_id', sContact.account_id);
      }
    }
  }

  errorClose = () => {
    this.setState({ openError: false });
    this.props.history.push(`/editProfile`);
  }
  userChange = (e, values) => {
    this.setState({ userType: !this.state.userType });
    if (e.target.value ==='false') {
      this.setState({ts_contact_id:''})

    }
    else {
    this.setState({ts_user_id:''})

    }
  }
  onMultipleSpace = (e,type,index) =>{
    let sSpaces = [];
    if(type == 1){
      sSpaces = this.state.spaces;      
    }
    if(type == 2){
    sSpaces = this.state.spaces;
    }
    this.setState({venuenSpaces:true,selectedSpaceIndex:index,selectedSpaceType:type,selectedSpaces:sSpaces});
  }
  onModalClose = ()=>{
    this.setState({venuenSpaces:false,selectedSpaces:[]});
  }
  onSpaceApplied = (value) =>{
   if(this.state.selectedSpaceType == 2){
    
    this.setState({spaces:value});
  }
    
    this.setState({venuenSpaces:false,selectedSpaces:[]});
  }

  handleSubmit = (values, actions) => {
    let id = '';
    if (!values.spaceName) {
      id = parseInt(this.props.match.params.id, 10)
    }
    else {
      id = values.spaceName;
    }
    if (this.state.userType === true) {
      values.ts_contact_id = ''
      values.ts_account_id = ''
    }
    if (this.state.userType === false) {
      values.ts_user_id = ''
    }
    let paramsData = `ts_location_id=${encodeURIComponent(values.ts_location_id)}&ts_room_ids=${encodeURIComponent(values.ts_rooms_id)}&ts_contact_id=${encodeURIComponent(values.ts_contact_id)}&ts_account_id=${encodeURIComponent(values.ts_account_id)}&ts_user_id=${encodeURIComponent(values.ts_user_id)}&ts_user_type=${encodeURIComponent(values.ts_user_type ? 1 : 0)}&space_ids=${encodeURIComponent(this.state.spaces)}&ts_space_name=${encodeURIComponent(this.state.ts_space_name)}`;
    let thisProp = this;
    let propsVar = this.props;
    if (this.state.venueData.data.spaces !== undefined) {
      propsVar.updateSpace(id, paramsData).then((response) => {
        thisProp.props.history.push(`/spaceProfileEdit/${id}`);
      }).catch(error => { }
      );
      return true;
    }
  }

  onRoom=(e)=>{
    let tsRooms = this.state.roomItems.find(x => x.id == e.target.value);
    this.setState({ts_space_name:tsRooms.name})

  }
  render() {
    const  initialValues = {
      ts_location_id: this.state.location_id,
      ts_rooms_id: this.state.room_id,
      ts_contact_id:this.state.ts_contact_id,
      ts_account_id: this.state.ts_account_id,
      ts_user_id: this.state.ts_user_id,
      ts_user_type: this.state.userType===false?false:true,
      space_ids:this.state.spaces
    }
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          this.handleSubmit(values, actions)
        }}
      >
        {({ isSubmitting, values, handleBlur, handleChange, setFieldValue }) => (
          <Form>
            <div className="home_page">
              <div className="search_page">
              </div>
            </div>
            {this.state.openError == false &&
              <div className="webCalendar">
                <div className="header"></div>
                <Grid container>
                  <Grid item md={12} lg={12} xl={12}>
                    <div className="main">
                      <p className="large-text">
                        Set up your tripleseat locations with FIRST-HOLD Integration
                      </p>
                      <div className="content sm-d-none">
                        <p className="bold-text">
                          <p className="left">FIRST-HOLD Space</p>
                          <p className="right" style={{ marginLeft: "40px" }}>Tripleseat Locations</p>
                          <p className="right">Tripleseat Rooms</p>
                          {values.ts_user_type ===false ?
                            (<p className="right">Tripleseat Contacts</p>) :
                            
                            (<p className="right">Tripleseat Users</p>)}
                        </p>
                      </div>
                      <div className="content pad-top triplesheat">
                        <p className="blue-text">
                          <p className="blue-left">
                            <CustomSelect
                              id="spaceName"
                              value={parseInt(this.props.match.params.id, 10)}
                              name="spaceName"
                              spaceData={this.state.spaceList}
                              placeholder="Select Space"
                              color="#62717a"
                                />
                                <div div className="content pad-top">
                                    <p className="">
                                        <div className="blue-left">
                                            <SyncCheckBox
                                            value={values.space_ids}
                                            setFieldValue={setFieldValue}
                                            name={"space-"} 
                                            onChange={this.onMultipleSpace} 
                                            type={2}
                                            index={0}
                                            handleChange={handleChange}
                                            values={this.state.spaces}
                                            label={this.state.spaces.length > 1 ? 'Applied to '+this.state.spaces.length+' spaces': 'Apply status to more spaces'}
                                            />
                                        </div>
                                    </p>
                                </div>
                          </p>
                          
                             { 
                              this.state.venuenSpaces? <VenueSpaces open={this.state.venuenSpaces} spaceId={this.state.spaceId} selectedSpaces={this.state.selectedSpaces} onSpaceApplied={this.onSpaceApplied}   venues={this.props.list} onClose={this.onModalClose}  /> : null
                                }

                          <p className="right" style={{ marginRight: "10px", marginTop: "14px" }}>
                            <div className="input-field-section">
                              <CustomSelectWithScroll
                                value={values.ts_location_id}
                                setFieldValue={setFieldValue}
                                placeholder="Select"
                                options={this.state.calenderItems}
                                handleChange={e => { handleChange(e); this.onLocation(e) }}
                                handleBlur={handleBlur}
                                name="ts_location_id" label="Tripleseat Locations" />
                            </div>
                          </p>
                          <p className="right" style={{ marginRight: "10px", marginTop: "14px" }}>
                            <div className="input-field-section">
                              <CustomSelectWithScroll
                                value={values.ts_rooms_id}
                                setFieldValue={setFieldValue}
                                placeholder="Select"
                                options={this.state.roomItems}
                                handleChange={e => { handleChange(e); this.onRoom(e) }}
                                handleBlur={handleBlur}
                                name="ts_rooms_id" label="Tripleseat Locations Rooms" />
                            </div>
                          </p>
                          {values.ts_user_type === false && 
                            (<p className="right" style={{ marginRight: "10px", marginTop: "16px" }} >
                              <div className="input-field-section">
                                <CustomSelectWithScroll
                                  value={values.ts_contact_id}
                                  setFieldValue={setFieldValue}
                                  placeholder="Select"
                                  options={this.state.contactsItems}
                                  handleChange={(e, selected) => { handleChange(e); this.onContact(e, setFieldValue) }}
                                  handleBlur={handleBlur}
                                  updatePage={this.updatePage}
                                  name="ts_contact_id" label="Tripleseat Contacts" />
                              </div>
                            </p>)} {values.ts_user_type === true &&
                            (<p className="right" style={{ marginRight: "10px", marginTop: "16px" }}>
                              <div className="input-field-section">
                                <CustomSelectWithScroll
                                  value={values.ts_user_id}
                                  setFieldValue={setFieldValue}
                                  placeholder="Select"
                                  options={this.state.usersItems}
                                  handleChange={(e, selected) => { handleChange(e); this.onUser(e, setFieldValue) }}
                                  handleBlur={handleBlur}
                                  updatePage={this.updatePage}
                                  name="ts_user_id" label="Tripleseat Users" />
                              </div>
                            </p>)}
                          <p style={{ marginRight: "16px", marginTop: "24px" }}>
                            <CustomizedCheckBox
                              name='ts_user_type'
                              value={values.ts_user_type}
                              label='Users'
                              setFieldValue={setFieldValue}
                              onChange={e => { this.userChange(e, values) }}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </p>
                          <ModalBoxTripleseat />
                        </p>
                      </div>

                      <div className="content content-sm">
                        <p className="blue-text">
                          <p className="left">FIRST-HOLD Space</p>
                          <p className="blue-left">
                            <CustomSelect
                              id="spaceName"
                              value={parseInt(this.props.match.params.id, 10)}
                              name="spaceName"
                              spaceData={this.state.spaceList}
                              placeholder="Select Space"
                              color="#62717a"
                            />
                          </p>
                        </p>
                        <p className="blue-text">
                          <p className="right heading-right">Tripleseat Locations</p>
                          <p className="right">
                            <div className="input-field-section">
                              <CustomSelectWithScroll
                                value={values.ts_location_id}
                                setFieldValue={setFieldValue}
                                placeholder="Select"
                                options={this.state.calenderItems}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                name="ts_location_id" label="Tripleseat Locations" />
                            </div>
                          </p>
                        </p>
                        <p className="blue-text">
                          <p className="right heading-right">Tripleseat Rooms</p>
                          <p className="right">
                            <div className="input-field-section">
                              <CustomSelectWithScroll
                                value={values.ts_rooms_id}
                                setFieldValue={setFieldValue}
                                placeholder="Select"
                                options={this.state.roomItems}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                name="ts_rooms_id" label="Tripleseat Rooms" />
                            </div>
                          </p>
                        </p>
                        <p className="blue-text">
                          <p className="right heading-right">Tripleseat Contacts</p>
                          <p className="right">
                            <div className="input-field-section">
                              <CustomSelectWithScroll
                                value={values.ts_contact_id}
                                setFieldValue={setFieldValue}
                                placeholder="Select"
                                options={this.state.contactsItems}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                updatePage={this.updatePage}
                                name="ts_contact_id" label="Tripleseat Contacts" />
                            </div>
                          </p>
                        </p>

                      </div>
                      <div className="buttonContent">
                        {values.ts_location_id !== 'Select' && values.spaceName !== '' && values.ts_rooms_id !== 'Select' && (values.ts_contact_id !== '' || values.ts_user_id !== '') ?
                          <CustomizedButton type="submit" label="Next" disabled={false} />
                          :
                          <CustomizedButton type="submit" label="Next" disabled={true} />
                        }
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            }
            {this.state.openError &&
              <ErrorPopup openError={this.state.openError} errorClose={this.errorClose} />
            }
          </Form>
        )}
      </Formik>
    );
  }
}

//export default WebCalendar;
const mapStateToProps = state => {
  return {
    userData: state.data.usersVenue.userInfo,
    list: state.data.usersVenue.venueList,
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        userDetails,
        getSpace,
        updateSpace,
        getTSLocations,
        getVenue,
        SyncCheckBox,
        VenueSpaces,
        venueList,
        getCronData
      },
      dispatch
    )
  )
);
export default enhance(TSLocations);

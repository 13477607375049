import React from 'react';
import { Grid } from '@material-ui/core';
import './assets/styles/webCalendar.scss';
import { Formik, Form } from 'formik';
import { bindActionCreators, compose } from "redux";
import CustomSelect from '../shared/components/CustomSelect';
import CustomSelectWithScroll from '../shared/components/CustomSelectWithScroll';
import { Redirect } from 'react-router-dom'
import { userDetails,venueList} from '../shared/venueList/actions';
import { getSpace, updateSpace } from '../../src/data/spaces/actions';
import { getVenue } from '../../src/data/venues/actions';
import { getTSLocations } from '../../src/data/venues/actions/getTSLocations';
import { getETSpaces} from '../../src/data/venues/actions/getETSpaces';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomizedButton from '../shared/components/customizedButton';
import ErrorPopup from '../shared/components/errorPopup';
import CustomizedCheckBox from '../shared/components/CustomizedCheckbox';
import ModalBoxTripleseat from "../shared/components/ModalBoxTripleseat";
import SyncCheckBox from "../../src/shared/components/SyncCheckbox";
import VenueSpaces  from "../../src/venueOwner/components/venueSpaces";
import { getCronData } from '../../src/data/venues/actions/getCronData';

class ETLocations extends React.Component {
  state = {
    spaceId: '',
    vanueId: '',
    spaceName: '',
    calenderItems: '',
    venueData: '',
    spaceList: [],
    openError: false,
    roomItems: '',
    spaceItems: '',
    usersItems: '',
    userType: '',
    venuenSpaces:false,
    selectedSpaces:[],
    spaces:{},
    selectedSpaces:[],
    selectedSpaceIndex:0,
    selectedSpaceType:1,
    room_id:'Select',
    et_space_name:'',
    
  }
  componentWillMount() {
    let et_location_ids='Select'
    let et_space_name=''
    this.setState({ spaceId: this.props.match.params.id });
    this.setState({ spaces: [(this.props.match.params.id).toString()]});
    this.props.venueList().then(() => {}).catch(error =>{}); 
    this.props.userDetails().then((response) => {
      if(response.userInfo.data.event_temple_status===0)
      {
      this.setState({openError:true})
      }
      })
      
    this.props.getCronData(this.props.match.params.id,'eventTemple' ).then((resultData) => {
      const space_ids = resultData.data.spaces_ids.split(',');
      et_location_ids=resultData.data.ts_location_id.toString()
      et_space_name=resultData.data.ts_space_name
      this.setState({room_id:et_location_ids,spaces:space_ids})

    })   
    this.props.getETSpaces().then((result) => {
      let spaceArray = [];
      if ('spaces' in result && Array.isArray(result.spaces.data)) {
        result.spaces.data.map(function (data) {
          if(data.id.toString()===et_location_ids)
              {
               et_space_name=data.attributes.name
              }
          spaceArray.push({ "id": data.id, "name": data.attributes.name})
          return spaceArray;
        });
      }
      
      this.setState({ spaceItems: spaceArray ,et_space_name:et_space_name});
    }).catch(error => {
      this.setState({ openError: true });
    });

    this.props.getSpace(this.props.match.params.id).then((res) => {
      this.setState({ spaceName: res.name })
      this.setState({ vanueId: res.venue_id })
      this.props.getVenue(this.state.vanueId).then((response) => {
        this.setState({ venueData: response })
        this.setState({ spaceList: response.data.spaces })
      }).catch(error => { });
      // this.props.getCronData(this.props.match.params.id,'eventTemple' ).then((resultData) => {
      //   const space_ids = resultData.data.spaces_ids.split(',');
      //   let et_location_ids=resultData.data.ts_location_id.toString()
      //   let et_space_name=resultData.data.ts_space_name
      //   this.setState({room_id:et_location_ids,spaces:space_ids,et_space_name:et_space_name})

      // })
    }).catch(error => { });

  }


  errorClose = () => {
    this.setState({ openError: false });
    this.props.history.push(`/editProfile`);
  }
  
  onMultipleSpace = (e,type,index) =>{
    let sSpaces = [];
    if(type == 1){
      sSpaces = this.state.spaces;      
    }
    if(type == 2){
    sSpaces = this.state.spaces;
    }
    this.setState({venuenSpaces:true,selectedSpaceIndex:index,selectedSpaceType:type,selectedSpaces:sSpaces});
  }
  onModalClose = ()=>{
    this.setState({venuenSpaces:false,selectedSpaces:[]});
  }
  onSpaceApplied = (value) =>{
   if(this.state.selectedSpaceType == 2){
    
    this.setState({spaces:value});
  }
    
    this.setState({venuenSpaces:false,selectedSpaces:[]});
  }

  handleSubmit = (values, actions) => {
    let id = '';
    if (!values.spaceName) {
      id = parseInt(this.props.match.params.id, 10)
    }
    else {
      id = values.spaceName;
    }
    let paramsData = `et_space_id=${encodeURIComponent(values.et_space_id)} &space_ids=${encodeURIComponent(this.state.spaces)}&et_space_name=${encodeURIComponent(this.state.et_space_name)}`;
    let thisProp = this;
    let propsVar = this.props;
    if (this.state.venueData.data.spaces !== undefined) {
      propsVar.updateSpace(id, paramsData).then((response) => {
        thisProp.props.history.push(`/spaceProfileEdit/${id}`);
      }).catch(error => { }
      );
      return true;
    }
  }

  onRoom=(e)=>{
    let etRooms = this.state.spaceItems.find(x => x.id == e.target.value);
    this.setState({et_space_name:etRooms.name})

  }
  render() {

    return (
      <Formik
        initialValues={{
          et_space_id: this.state.room_id,
          space_ids:this.state.spaces
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          this.handleSubmit(values, actions)
        }}
      >
        {({ isSubmitting, values, handleBlur, handleChange, setFieldValue }) => (
          <Form>
            <div className="home_page">
              <div className="search_page">
              </div>
            </div>
            {this.state.openError == false &&
              <div className="webCalendar">
                <div className="header"></div>
                <Grid container>
                  <Grid item md={12} lg={12} xl={12}>
                    <div className="main">
                      <p className="large-text">
                        Set up your Event Temple spaces with FIRST-HOLD Integration
                      </p>
                      <div className="content sm-d-none">
                        <p className="bold-text">
                          <p className="left">FIRST-HOLD Space</p>
                          <p className="right" style={{ marginLeft: "40px" }}>Event Temple Spaces</p>
                         
                        </p>
                      </div>
                      <div className="content pad-top triplesheat">
                        <p className="blue-text">
                          <p className="blue-left">
                            <CustomSelect
                              id="spaceName"
                              value={parseInt(this.props.match.params.id, 10)}
                              name="spaceName"
                              spaceData={this.state.spaceList}
                              placeholder="Select Space"
                              color="#62717a"
                              disable={true}
                                />
                                <div div className="content pad-top">
                                    <p className="">
                                        <div className="blue-left">
                                            <SyncCheckBox
                                            value={values.space_ids}
                                            setFieldValue={setFieldValue}
                                            name={"space-"} 
                                            onChange={this.onMultipleSpace} 
                                            type={2}
                                            index={0}
                                            handleChange={handleChange}
                                            values={this.state.spaces}
                                            label={this.state.spaces.length > 1 ? 'Applied to '+this.state.spaces.length+' spaces': 'Apply status to more spaces'}
                                            />
                                        </div>
                                    </p>
                                </div>
                          </p>
                          
                             { 
                              this.state.venuenSpaces? <VenueSpaces open={this.state.venuenSpaces} spaceId={this.state.spaceId} selectedSpaces={this.state.selectedSpaces} onSpaceApplied={this.onSpaceApplied}   venues={this.props.list} onClose={this.onModalClose}  /> : null
                                }

                          <p className="right" style={{ marginRight: "10px", marginTop: "14px" }}>
                            <div className="input-field-section">
                              <CustomSelectWithScroll
                                value={values.et_space_id}
                                setFieldValue={setFieldValue}
                                placeholder="Select"
                                options={this.state.spaceItems}
                                handleChange={e => { handleChange(e); this.onRoom(e) }}
                                handleBlur={handleBlur}
                                name="et_space_id" label="Event Temple Spaces"  />
                            </div>
                          </p>
                          
                          
                         
                        </p>
                      </div>

                      
                      <div className="buttonContent">
                        {values.et_space_id !== 'Select'&& values.spaceName !== '' ?
                          <CustomizedButton type="submit" label="Next" disabled={false} />
                          :
                          <CustomizedButton type="submit" label="Next" disabled={true} />
                        }
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            }
            {this.state.openError &&
              <ErrorPopup openError={this.state.openError} errorClose={this.errorClose} EventTemple={true}/>
            }
          </Form>
        )}
      </Formik>
    );
  }
}

//export default WebCalendar;
const mapStateToProps = state => {
  return {
    userData: state.data.usersVenue.userInfo,
    list: state.data.usersVenue.venueList,
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        userDetails,
        getSpace,
        updateSpace,
        getTSLocations,
        getETSpaces,
        getVenue,
        SyncCheckBox,
        VenueSpaces,
        venueList,
        getCronData
      },
      dispatch
    )
  )
);
export default enhance(ETLocations);

import React, { Component } from 'react';
import {debounce} from 'lodash';
import Banner from '../components/banner';
import FeaturedVenues from '../components/featuredVenues';
import FindVenuesFor from '../components/findVenuesFor';
import EventPlanner from '../components/eventPlanner';
import HowItWork from '../components/howItWork';
import Footer from '../../landingPage/components/footer';
import HomeSearch from '../components/HomeSearch';
import '../assets/styles/home.scss';
import { connect } from "react-redux";
import { object, func } from "prop-types";
import { bindActionCreators, compose } from "redux";
import { staticVenueOnLoad } from "shared/home/actions";
import { addCategory, seatingCategory } from "shared/search/actions";
import { eventType } from "shared/placeHolds/actions";
import { homepage } from "shared/homepage/actions";




let bannerData = [
  // {image:"https://d31ogwdhz811go.cloudfront.net/uploads/defaultImage/5d6535c5232ff373404c90e2730fffee.jpg", heading:"Point. Click. Hold. ", subHeading:"Source and hold your next venue, instantly.", content:"Welcome to the new venue marketplace. Our real-time, searchable selection of available meeting & event venues was made just for you.", link:"Get the Chrome extension", buttonText:" Download for free"},
  {image:require("../assets/images/Banner_images/Header - Davis - Edited.4736eaf1.jpg"), heading:"Point. Click. Hold. ", subHeading:"Source and hold your next venue, instantly.", content:"Welcome to the new venue marketplace. Our real-time, searchable selection of available meeting & event venues was made just for you.", link:"Get the Chrome extension", buttonText:" Download for free"},
  {image:require("../assets/images/Banner_images/Header - deYoung2 - Edited.jpg"), heading:"Point. Click. Hold. ", subHeading:"Source and hold your next venue, instantly.", content:"Welcome to the new venue marketplace. Our real-time, searchable selection of available meeting & event venues was made just for you.", link:"Get the Chrome extension", buttonText:" Download for free"},
  {image:require("../assets/images/Banner_images/Header - Filoli - Edited.jpg"), heading:"Point. Click. Hold. ", subHeading:"Source and hold your next venue, instantly.", content:"Welcome to the new venue marketplace. Our real-time, searchable selection of available meeting & event venues was made just for you.", link:"Get the Chrome extension", buttonText:" Download for free"},
  // {image:"https://d31ogwdhz811go.cloudfront.net/uploads/defaultImage/904497d58475d852b87723df65dbdcbe.jpg", heading:"Point. Click. Hold. ", subHeading:"Source and hold your next venue, instantly.", content:"Welcome to the new venue marketplace. Our real-time, searchable selection of available meeting & event venues was made just for you.", link:"Get the Chrome extension", buttonText:" Download for free"},
  // {image:"https://d31ogwdhz811go.cloudfront.net/uploads/defaultImage/63bb7b7b70258708cb35ec9855e597a9.jpg", heading:"Point. Click. Hold. ", subHeading:"Source and hold your next venue, instantly.", content:"Welcome to the new venue marketplace. Our real-time, searchable selection of available meeting & event venues was made just for you.", link:"Get the Chrome extension", buttonText:" Download for free"}
]


// let findFeatureVenueData = [
//   {image:"https://d31ogwdhz811go.cloudfront.net/venue_photos/big/0.62386500%201517362530.JPG", event:'Conferences'},
//   {image:"https://d31ogwdhz811go.cloudfront.net/venue_photos/big/0.87167800%201533079925.jpg", event:'Weddings'},
//   {image:"https://d31ogwdhz811go.cloudfront.net/venue_photos/big/0.23927400%201554847470.jpg", event:'Workshops'},
//   {image:"https://d31ogwdhz811go.cloudfront.net/venue_photos/big/0.90046400%201549980450.jpg", event:'Trade Shows'},
//   {image:"https://d31ogwdhz811go.cloudfront.net/venue_photos/big/0.66912100%201555129941.JPG", event:'Corporate Parties'},
//   {image:"https://d31ogwdhz811go.cloudfront.net/venue_photos/big/0.37192600%201559673607.JPG", event:'Fundraisers'}
// ]

// const photos = [{
//     photo: "https://source.unsplash.com/aZjw7xI3QAA/844x463",
//     thumbnail: "https://source.unsplash.com/aZjw7xI3QAA",
//   }, {
//     photo: "https://source.unsplash.com/c77MgFOt7e0/844x463",
//     thumbnail: "https://source.unsplash.com/c77MgFOt7e0",
//   }, {
//     photo: "https://source.unsplash.com/QdBHnkBdu4g/844x463",
//     thumbnail: "https://source.unsplash.com/QdBHnkBdu4g",
//   },{
//     photo: "https://source.unsplash.com/aZjw7xI3QAA/844x463",
//     thumbnail: "https://source.unsplash.com/aZjw7xI3QAA",
//   }, {
//     photo: "https://source.unsplash.com/c77MgFOt7e0/844x463",
//     thumbnail: "https://source.unsplash.com/c77MgFOt7e0",
//   }, {
//     photo: "https://source.unsplash.com/QdBHnkBdu4g/844x463",
//     thumbnail: "https://source.unsplash.com/QdBHnkBdu4g",
//   }];
const logo = require("../assets/images/default_venue.png");

let categoryType = [];
let seatingType = [];
let suggestions = [];
let default_address=[];
let addresses= [];
let venues=[];
class Home extends Component {

  state={
    slideCount:0,
    activeSlide:0,
    activeSlideLoop:0,
    showPopup: false,
    categoryType: [],
    seatingType:[],
    suggestions:[]
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.homeBannerActiveSlide && nextProps.homeBannerActiveSlide !== 0){
      this.setState({activeSlide:nextProps.homeBannerActiveSlide})
    }
  }
  componentDidMount(){



    this.setState({slideCount:bannerData.length});
    let searchParams=    {
  "search_key": "Conferences"
  }


    this.props.staticVenueOnLoad(searchParams).then(response => {
      if(response.venueSuggestions && Object.keys(response.venueSuggestions).length !== 0) {
          suggestions=[];
         //default address
          response.venueSuggestions.default_address.map(function (data, idx) {
              var obj_a={};
              obj_a.title=data.city;
              obj_a.id=idx+'|1';
              obj_a.default=1;
              obj_a.type=1;
              suggestions.push(obj_a);
              // let obj={};
              // obj.key=data.city;
              // obj.id=idx+'|1';
              // suggestions.push(obj);
              // default_address=response.venueSuggestions.default_address;

            })

          response.venueSuggestions.addresses.map(function (data, idx) {
              var obj_a={};
              obj_a.title=data.city;
              obj_a.id=idx+'|1';
              obj_a.type=1;
              obj_a.default=0;
              suggestions.push(obj_a);
            })

          response.venueSuggestions.venues.map(function (data, idx) {
              var obj_v={};
              obj_v.title=data.name;
              obj_v.id=data.slug+'|2';
              obj_v.type=2;
              obj_v.default=0;
              suggestions.push(obj_v);
            })

           // address
           addresses=response.venueSuggestions.addresses;

           
            //venues
            venues=response.venueSuggestions.venues;


            
          this.setState({ suggestions: suggestions });

      }
    })
    .catch(error => this.setState({ errorValue: true }));
    

    //for category type
    this.props.eventType().then(response => {
      categoryType=[];
      response.eventTypeDetails.data.map(function(data, idx) {
        let obj = {};
        obj.value = data.id;
        obj.name = data.name;
        categoryType.push(obj);
        return categoryType;
      });
      this.setState({ categoryType: categoryType });
    });

      //for seating type
    this.props.seatingCategory().then(response => {
      seatingType=[];

        response.data.map(function(data, idx) {
          let obj = {};
          obj.value = data.id;
          obj.name = data.name;
          seatingType.push(obj);
          return seatingType;
        });
        this.setState({ seatingType: seatingType });
      })
      .catch(error => this.setState({ errorValue: true }));
  }
  clickNext = () => {
    if(this.state.activeSlide <= this.state.slideCount-1){
      this.setState({activeSlide:this.state.activeSlide + 1})
    }
  }
  clickPrevious = () => {
    if(this.state.activeSlide > 0){
      this.setState({activeSlide:this.state.activeSlide - 1})
    }
  }

  handleSearchChange =  debounce((value) => {
let searchParams ={};
  if(value!==''){
    searchParams={    
      search_key:value
    }
  }

     
  let address_array=[];
  let venue_array=[];
  let default_array=[];
  let title_loc=false;
  suggestions=[];
  const request = async () => {
    // const response = await this.props.staticVenueOnLoad(searchParams)


         address_array = addresses.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(value.toLowerCase())));
        //  venue_array = venues.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(value.toLowerCase())));
        //  default_array = default_address.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(value.toLowerCase())));
      // if(response.venueSuggestions && Object.keys(response.venueSuggestions).length !== 0) {
 
      if(address_array.length!==0){
            let obj_a={};
            obj_a.key='Location';
            obj_a.id='Title';
            title_loc=true;
            suggestions.push(obj_a);
           
        address_array.map(function (data, idx) {
          if(data.city.toLowerCase()!=='san francisco'){
            obj_a={};
            obj_a.key=data.city;
            obj_a.id=idx+'|1';
              suggestions.push(obj_a);
          }
            })
          }
          // if(venue_array.length!==0){
          //   let obj_v={};
          //   obj_v.key='Venues';
          //   obj_v.id='Title';
          //   suggestions.push(obj_v);

          //   venue_array.map(function (data, idx) {
          //     obj_v={};
          //     obj_v.key=data.name;
          //     obj_v.id=data.id+'|2';
          //     suggestions.push(obj_v);
          //   })
          // }

          // if(default_array.length!==0){
          //  let obj_d={};
          //   if(title_loc===false){
          //   obj_d={};
          //   obj_d.key='Location';
          //   obj_d.id='Title';
          //   title_loc=true;
          //   suggestions.push(obj_d);
          //   }

          //   default_array. map(function (data, idx) {
          //     obj_d={};
          //     obj_d.key=data.city;
          //     obj_d.id=idx+'|1';
          //     suggestions.push(obj_d);
          //   })
          // }

      if(value===''){
        suggestions=[];
            let obj_a={};
            obj_a.key='Location';
            obj_a.id='Title';
            title_loc=true;
            suggestions.push(obj_a);
            default_address.map(function (data, idx) {
              let obj_b={};
              obj_b.key=data.city;
              obj_b.id=idx+'|1';
              suggestions.push(obj_b);
            })
          }
      if(suggestions.length===0){
              let obj={};
              obj.key='FIRST-HOLD is expanding every day. We don\'t currently have a venue in the city you searched.';
              obj.id='No';
              suggestions.push(obj);
              // return suggestions;
            }
          // }
          this.setState({ suggestions: suggestions });
        }
        request()
        }, 1600);
      
  

  // handleSearchChange = (value) => {
  //   let searchParams ={};
  //   if(value!=''){
  //     searchParams={    
  //       search_key:value
  //     }
  //   }
  //   const request = async () => {
  //     const response = await this.props.staticVenueOnLoad(searchParams)
  //     if(response.venueSuggestions && Object.keys(response.venueSuggestions).length !== 0) {
  //             suggestions=[];
  //             response.venueSuggestions.addresses && response.venueSuggestions.addresses.map(function (data, idx) {
  //                 let obj={};
  //                 obj.key=data.city;
  //                 obj.id=idx+'|1';
  //                 suggestions.push(obj);
  //               })
  //               response.venueSuggestions.venues && response.venueSuggestions.venues.map(function (data, idx) {
  //                 let obj={};
  //                 obj.key=data.name;
  //                 obj.id=data.id+'|2';
  //                 suggestions.push(obj);
  //               })
  //               response.venueSuggestions.venue_types && response.venueSuggestions.venue_types.map(function (data, idx) {
  //                 let obj={};
  //                 obj.key=data.name;
  //                 obj.id=idx+'|3';
  //                 suggestions.push(obj);
  //               })
    
  //         }
  //         this.setState({ suggestions: suggestions });
  // }
  // request()
  //   // this.props.staticVenueOnLoad(searchParams).then(response => {
  //   //   if(response.venueSuggestions && Object.keys(response.venueSuggestions).length !== 0) {
  //   //       suggestions=[];
  //   //       response.venueSuggestions.addresses && response.venueSuggestions.addresses.map(function (data, idx) {
  //   //           let obj={};
  //   //           obj.key=data.city;
  //   //           obj.id=idx+'|1';
  //   //           suggestions.push(obj);
  //   //         })
  //   //         response.venueSuggestions.venues && response.venueSuggestions.venues.map(function (data, idx) {
  //   //           let obj={};
  //   //           obj.key=data.name;
  //   //           obj.id=data.id+'|2';
  //   //           suggestions.push(obj);
  //   //         })
  //   //         response.venueSuggestions.venue_types && response.venueSuggestions.venue_types.map(function (data, idx) {
  //   //           let obj={};
  //   //           obj.key=data.name;
  //   //           obj.id=idx+'|3';
  //   //           suggestions.push(obj);
  //   //         })

  //   //   }
  //   //   this.setState({ suggestions: suggestions });
  //   // })
  //   // .catch(error => this.setState({ errorValue: true }));
  // }

  //for static venue
  UNSAFE_componentWillMount() {
    this.props.staticVenueOnLoad();
    this.props.homepage();

  }

  searchLocal=()=>{
    
  }

  render() {
 let featureVenueData=[];
  this.props.venueHomePage.featuredVenues && this.props.venueHomePage.featuredVenues.map(function(data, idx) {
    let obj={};
  let type='';
  if(data.type.toString()==='1'){
    type="Conferences";
  }
  else if(data.type.toString()==='2'){
    type="Weddings";
  }
  else if(data.type.toString()==='3'){
    type="Workshops";
  }
  else if(data.type.toString()==='4'){
    type="Trade Shows";
  }
  else if(data.type.toString()==='5'){
    type="Corporate Parties";
  }
  else if(data.type.toString()==='6'){
    type="Fundraisers";
  }

  let stateAbbr='';

  if(data.address.state_abbr!==null){
    stateAbbr=data.address.state_abbr;
  }
  obj.id=data.slug;
    obj.image=data.profilePhoto ? data.profilePhoto &&
   data.profilePhoto.thumbnail_path : logo;
    obj.venueName=data.name;
    
    obj.venueAddress= data.address &&
         data.address.city +
          ", " +
       stateAbbr;
    obj.favourite=" ";
    obj.category=type;
    featureVenueData.push(obj);
    return featureVenueData;
    })

    let findFeatureVenueData=[];
    this.props.venueHomePage.venueType && this.props.venueHomePage.venueType.map(function(data, idx) {
    let obj={};
    obj.id=data.id;
    obj.image=data.name?require("../assets/images/venue_for/"+data.name+".jpg"):'https://d31ogwdhz811go.cloudfront.net/venue_photos/big/0.62386500%201517362530.JPG';
    obj.event=data.name;
      

    findFeatureVenueData.push(obj);

    return findFeatureVenueData;
      })


    // let featureVenueData = [
    //   {image:"https://d31ogwdhz811go.cloudfront.net/venue_photos/big/0.62386500%201517362530.JPG",
    //    venueName:'Zappa Room',
    //    venueAddress:'Seattle, WA',
    //    favourite:'active',
    //    category:'Conferences'}];

    
    return (

        <div className="home_page">
          <Banner className="fh-container" clickNext={this.clickNext} clickPrevious={this.clickPrevious} slideCount={this.state.slideCount} activeSlide={this.state.activeSlide} 
          bannerData = {bannerData}/>
          <HomeSearch className="fh-container" menuData={this.state.suggestions} categoryType={this.state.categoryType} seatingType={this.state.seatingType} onSearchChnage={this.handleSearchChange}/>
          <HowItWork className="fh-container" />
          <FeaturedVenues className="fh-container" featureVenueData={featureVenueData} />
          <FindVenuesFor className="fh-container" findFeatureVenueData={findFeatureVenueData} />
          <EventPlanner className="fh-container"/>
          <Footer className="fh-container"/>
        </div>
    );
  }
}
Home.propTypes = {
  classes: object.isRequired,
  staticVenueOnLoad: func.isRequired,
  suggession: func.isRequired,
  addCategory: func.isRequired,
  eventType: func.isRequired,
  seatingCategory: func.isRequired,
  homepage: func.isRequired
};
const mapStateToProps = state => {
  return {
    suggestions: state.data.venueLoadSuggestion.suggestions,
    addCategoryData: state.data.browseVenueData.categoryData,
    seatingCategoryData: state.data.browseVenueData.arrangementData,
    venueHomePage: state.data.homepage.homePageData,
    homeBannerActiveSlide: state.data.master.homeBannerActiveSlide
  };
};

const enhance = compose(

  connect(
    mapStateToProps,
    
    dispatch =>
      bindActionCreators(
        {
          staticVenueOnLoad,
          addCategory,
          eventType,
          seatingCategory,
          homepage
        },
        dispatch
      )
  )
);
export default enhance(Home);


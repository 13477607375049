import { Record } from 'immutable';

import {
  POST_CONTACT_REQUEST,
  POST_CONTACT_FAILURE,
  POST_CONTACT_SUCCESS
 
} from 'shared/state/types';

class State extends Record({
  contactInfo:{},
  error:'',
  sending:'',
  sended:'',
  success:''

  
}) {}



export default function reducer(state = new State(), action) {

  switch (action.type) {
    case POST_CONTACT_REQUEST:
      return state.merge({
        error:'',
        success:'',
        sending:true,
        sended:false
        
      });
    case POST_CONTACT_FAILURE:
      return state.merge({
        contactInfo:{},
        error: action.error,
        success:'',
        sending:false,
        sended:false
        

      });
    case POST_CONTACT_SUCCESS:
      return state.merge({
        error:'',
        contactInfo:action.contactDetails.message,
        success:action.contactDetails.message,
        sending:false,
        sended:true
      });
     
    default:
      return state;
  }
}


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import axios, { post } from 'axios';
import { registerUser, emailExists, getStates, imageUpload } from 'data/person/actions';
import steps from './steps';
import { FormikWizard } from 'formik-wizard';
import { withRouter } from 'react-router-dom';
import { getUploadPostUrl, uploadtoS3} from 'data/master/actions';

class Register extends Component {
  static propTypes = {
    registerUser: PropTypes.func.isRequired,
  };

  state = {
    toFoobar: false,
    error:''
  };

  UNSAFE_componentWillMount() {
    localStorage.removeItem('personalInfoImage');
    localStorage.removeItem('disable_plan_selection')
    localStorage.removeItem('user_type');
    this.props.getStates();
  }

  buildFormData=(formData, data, parentKey)=> {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
            formData.append(parentKey, value);

    }
  }
    
  handleSubmit = (values, setFieldValue) => {
    const googleSignInPassword = localStorage.getItem('googleSignInPassword');
    console.log(googleSignInPassword,"signin")
    let formData;
    formData = new FormData();
    localStorage.removeItem('disable_plan_selection')
    localStorage.removeItem('tier')
    let obj;
    let tier
    if(values.company.special_offer){
      tier = '2'
    }
    else{
      tier = '1'
    }
    if(values.company.line_2.length > 0)
    {
      if(parseInt(values.type.user_type) === 0){
        if(values.company.company_name){
          obj= {
            type: values.type.user_type,
            email: values.basics.email,
            password: values.basics.password,
            password_confirmation: values.basics.password,
            first_name: values.personal.first_name,
            last_name: values.personal.last_name,
            phone_number: values.personal.phone_number,
            phone_ext: values.personal.extension,
            display_phone_number: values.personal.display_number?1:0,
            newsletter_enabled: values.company.newsletter?true:false,
            job_title: values.company.job_title_planner?values.company.job_title_planner:'Not',
            company_name: values.company.company_name,
            company_address: {
              line_1: values.company.line_1,
              line_2: values.company.line_2,
    
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            },
            address: {
              line_1: values.company.line_1,
              line_2: values.company.line_2,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            }
          };
        }
        else{
          obj= {
            type: values.type.user_type,
            email: values.basics.email,
            password: values.basics.password,
            password_confirmation: values.basics.password,
            job_title: values.company.job_title_planner?values.company.job_title_planner:'Not',
            first_name: values.personal.first_name,
            last_name: values.personal.last_name,
            phone_number: values.personal.phone_number,
            phone_ext: values.personal.extension,
            display_phone_number: values.personal.display_number?1:0,
            newsletter_enabled: values.company.newsletter?true:false,
            company_address: {
              line_1: values.company.line_1,
              line_2: values.company.line_2,
    
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            },
            address: {
              line_1: values.company.line_1,
              line_2: values.company.line_2,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            }
          };
        }
      }
      else{
        obj= {
          tier: tier,
          type: values.type.user_type,
          email: values.basics.email,
          password: values.basics.password,
          password_confirmation: values.basics.password,
          first_name: values.personal.first_name,
          last_name: values.personal.last_name,
          phone_number: values.personal.phone_number,
          phone_ext: values.personal.extension,
          display_phone_number: values.personal.display_number?1:0,
          newsletter_enabled: values.company.newsletter?true:false,
          job_title: values.company.job_title,
          // company_name: values.company.company_name,
          company_address: {
            line_1: values.company.line_1,
            line_2: values.company.line_2,
  
            city: values.company.city,
            state: values.company.state,
            country: 'US',
            zip: values.company.zipcode
          },
          address: {
            line_1: values.company.line_1,
            line_2: values.company.line_2,
            city: values.company.city,
            state: values.company.state,
            country: 'US',
            zip: values.company.zipcode
          }
        };
      }
    }
    else{
      if(values.type.user_type === 0){
        if(values.company.company_name){
          obj= {
            email: values.basics.email,
            password: values.basics.password,
            password_confirmation: values.basics.password,
            type: values.type.user_type,
            first_name: values.personal.first_name,
            last_name: values.personal.last_name,
            phone_number: values.personal.phone_number,
            phone_ext: values.personal.extension,
            display_phone_number: values.personal.display_number?1:0,
            newsletter_enabled: values.company.newsletter?true:false,
            job_title: values.company.job_title_planner?values.company.job_title_planner:'Not',
            company_name: values.company.company_name,
            company_address: {
              line_1: values.company.line_1,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            },
            address: {
              line_1: values.company.line_1,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            }
          };
        }
        else{
          obj= {
            email: values.basics.email,
            password: values.basics.password,
            password_confirmation: values.basics.password,
            type: values.type.user_type,
            first_name: values.personal.first_name,
            last_name: values.personal.last_name,
            phone_number: values.personal.phone_number,
            phone_ext: values.personal.extension,
            job_title: values.company.job_title_planner?values.company.job_title_planner:'Not',
            display_phone_number: values.personal.display_number?1:0,
            newsletter_enabled: values.company.newsletter?true:false,
            company_address: {
              line_1: values.company.line_1,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            },
            address: {
              line_1: values.company.line_1,
              city: values.company.city,
              state: values.company.state,
              country: 'US',
              zip: values.company.zipcode
            }
          };
        }
      }
      else{
        obj= {
          email: values.basics.email,
          password: values.basics.password,
          password_confirmation: values.basics.password,
          type: values.type.user_type,
          first_name: values.personal.first_name,
          last_name: values.personal.last_name,
          phone_number: values.personal.phone_number,
          phone_ext: values.personal.extension,
          display_phone_number: values.personal.display_number?1:0,
          newsletter_enabled: values.company.newsletter?true:false,
          job_title: values.company.job_title,
          tier: tier,
          // company_name: values.company.company_name,
          company_address: {
            line_1: values.company.line_1,
            city: values.company.city,
            state: values.company.state,
            country: 'US',
            zip: values.company.zipcode
          },
          address: {
            line_1: values.company.line_1,
            city: values.company.city,
            state: values.company.state,
            country: 'US',
            zip: values.company.zipcode
          }
        };
      }
    }
 
    this.buildFormData(formData,obj)

    if(values.personal.image){
    //image code
      this.props.getUploadPostUrl(1,values.personal.image.name).then((res) => {
        this.props.uploadtoS3(res.url, values.personal.image).then( ()=>{
     
      formData.append('photo',res.keyName)
    
    this.props.registerUser(formData)
    .then((response) => {
      const redirectPath = localStorage.getItem('redirect_path') 
      localStorage.removeItem('googleSignInPassword')
      localStorage.removeItem('redirect_path') 
      if(parseInt(values.type.user_type) === 1) {
        if(redirectPath !== null){
          //this.props.history.push(redirectPath)
          window.location.href = redirectPath;
        }
        else{
          window.location.href="./profileInfoConfirm";
        }
      }

      else {
        if(redirectPath !== null){
          //this.props.history.push(redirectPath)
          window.location.href = redirectPath;
        }
        else{
          window.location.href="./profileInfoConfirm";
        }
      }
     
    })
  })
      })
    }
    else{
      this.props.registerUser(formData)
      .then((response) => {
        localStorage.removeItem('googleSignInPassword')
        if(parseInt(values.type.user_type) === 1) {
          window.location.href="./profileInfoConfirm";
        }
  
        else {
          window.location.href="./profileInfoConfirm";
        }
       
      })
    }


  };

  render() {
    return (
      <FormikWizard
        steps={steps}
        onSubmit={(values,setFieldValue)=>{this.handleSubmit(values,setFieldValue)}}
        render={({
          children,
          isLastStep,
          status,
          goToPreviousStep,
          canGoBack,
          actionLabel,
        }) => (
            <div>
              {status && (
                <div>
                  {status.message}
                  <hr />
                </div>
              )}
              {children}
              {/* <div>
              { canGoBack &&
                <button type="button" onClick={goToPreviousStep}>
                  Previous
                </button>
              }
              <button type="submit">
                {actionLabel || (isLastStep ? 'Submit' : 'Next step')}
              </button>
            </div> */}

            </div>
          )}
      />
    );
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    registerUser,
    emailExists,
    getStates,
    imageUpload,
    getUploadPostUrl,
    uploadtoS3
  }, dispatch),
)(withRouter(Register));

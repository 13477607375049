import React from 'react';
import { object } from 'prop-types';

// import CustomizedCheckBox from '../../../shared/components/CustomizedCheckBox';
import CustomizedCheckBox from '../../../shared/components/CustomizedCheckbox';
import CustomRadio from '../../../shared/components/CustomRadio';
import CustomTimeSelect from '../../../shared/components/CustomTimeSelect';

import { Grid, Button, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FieldArray, ErrorMessage } from 'formik';

import '../assets/styles/addSpace.scss';

const styles = theme => ({
  root: {
    border: 'solid 1px #e1e1e1',
    fontFamily: 'Raleway',

    '&$disabled': {
      background: '#e1e1e1',
      boxShadow: 'none',
    },
    '&:hover': {
      background: '#f7f7f7',
      border: 'solid 1px #e1e1e1',
    }
  },
  deleteIcon: {
    fontSize: '22px',
    color: '#36434b'
  },
  disabled: {
  },
});

function NestedForm(props) {
  const { classes } = props;
  const addTimeSlot = (arrayHelpers, name) => {
    if (arrayHelpers.name === 'fixedRentalAvailability' && name === 'fixedRentalAvailability') {
      const len = props.values.fixedRentalAvailability.length + 1
      arrayHelpers.push({ idx: len, startTime: '', endTime: '', days: [], sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, additionalCost: false })
      const pricingCategoryValue = props.values.pricingCategory
      pricingCategoryValue.push(['', ''])
      props.setFieldValue('pricingCategory', pricingCategoryValue)
        // props.handleAddTimeSlot(props.values, pricingCategoryValue, 'fixed')
    }
    else if (arrayHelpers.name === 'flexibleRentalAvailability' && name === 'flexibleRentalAvailability') {
      const len = props.values.flexibleRentalAvailability.length + 1
      arrayHelpers.push({ idx: len, startTime: '', endTime: '', days: [], sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, additionalCost: false, maxHour: '', multipleEvent: 1, timeBetweenEvent: '', minHour:'', showMaxHour:false})
      const pricingCategoryValue = props.values.pricingCategory
      pricingCategoryValue.push(['', ''])
      props.setFieldValue('pricingCategory', pricingCategoryValue)
        // props.handleAddTimeSlot(props.values, pricingCategoryValue, 'flexible')
    }
  }

  const addMinimumHour = (idx, action) => {
    if(action === 'add'){
      const fieldName = `flexibleRentalAvailability.${idx}.showMaxHour`
      props.setFieldValue(fieldName,true)
    }
    else if(action === 'remove'){
      const fieldName = `flexibleRentalAvailability.${idx}.showMaxHour`
      props.setFieldValue(fieldName,false)
    }
  }
  return (
    <div>
      {props.fieldArrayName === 'fixedRentalAvailability' ?
        <FieldArray
          name="fixedRentalAvailability"
          render={arrayHelpers => (
            <div>
              {(props.values.fixedRentalAvailability && props.values.fixedRentalAvailability.length > 0 && props.openForm) && (
                props.values.fixedRentalAvailability.map((fixedRental, index) => (
                  <Grid container className="nested_space_availability_form_container position-relative" key={index}>
                    <span className="material-icons close add_space_close closeButton" onClick={e => { props.deleteTimeslotForFixed && props.deleteTimeslotForFixed(fixedRental.idx, props.values, props.setFieldValue) }}>close</span>
                    <Grid className="left" item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid container spacing={40} className="box-spacing-sm">
                        <Grid item md={1} xs={12} className="index_container">
                          <div className="form_index">
                            {index + 1}
                          </div>
                        </Grid>
                        <Grid item md={5} xs={12} className="start-sm">
                          <CustomTimeSelect placeholder="Start Time" name={`fixedRentalAvailability.${index}.startTime`} value={fixedRental.startTime} handleChange={props.handleChange} handleBlur={props.handleBlur} type={props.startTime!==''&&props.startTime!=='undefined'?props.startTime:''}/>
                        </Grid>
                        <Grid item md={5} xs={12} className="end-sm">
                          <CustomTimeSelect placeholder="End Time" name={`fixedRentalAvailability.${index}.endTime`} value={fixedRental.endTime} handleChange={props.handleChange} handleBlur={props.handleBlur} type={props.endTime!==''&&props.endTime!=='undefined'?props.endTime:''} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className="right" item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FieldArray
                        name={`fixedRentalAvailability.${index}.days`}
                        render={arrayHelpers => (
                          <Grid container spacing={40}>
                            <Hidden xsDown={true}>
                              <Grid item sm={3} md={3} lg={3} xl={3}>
                                {/* <CustomizedCheckBox name={`fixedRentalAvailability.${index}.monday`} label="Monday" value={fixedRental.monday} disabled={(props.fixedRentalDaysArray.includes('monday') && fixedRental.monday === false) ? true : false} checkboxGroupValue='monday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} /> */}
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.monday`} label="Monday" value={fixedRental.monday} checkboxGroupValue='monday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.tuesday`} label="Tuesday" value={fixedRental.tuesday} checkboxGroupValue='tuesday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                              </Grid>
                              <Grid item sm={3} md={3} lg={3} xl={3}>
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.wednesday`} label="Wednesday" value={fixedRental.wednesday} checkboxGroupValue='wednesday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.thursday`} label="Thursday" value={fixedRental.thursday} checkboxGroupValue='thursday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                              </Grid>
                              <Grid item sm={3} md={3} lg={3} xl={3}>
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.friday`} label="Friday" value={fixedRental.friday} checkboxGroupValue='friday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.saturday`} label="Saturday" value={fixedRental.saturday} checkboxGroupValue='saturday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                              </Grid>
                              <Grid item sm={3} md={3} lg={3} xl={3}>
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.sunday`} label="Sunday" value={fixedRental.sunday} checkboxGroupValue='sunday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                              </Grid>
                            </Hidden>
                            <Hidden smUp={true}>
                              <Grid className="alignment_of_days" item xs={12}>
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.monday`} label="Monday" value={fixedRental.monday} checkboxGroupValue='monday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.tuesday`} label="Tuesday" value={fixedRental.tuesday} checkboxGroupValue='tuesday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.wednesday`} label="Wednesday" value={fixedRental.wednesday} checkboxGroupValue='wednesday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.thursday`} label="Thursday" value={fixedRental.thursday} checkboxGroupValue='thursday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.friday`} label="Friday" value={fixedRental.friday} checkboxGroupValue='friday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.saturday`} label="Saturday" value={fixedRental.saturday} checkboxGroupValue='saturday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                                <CustomizedCheckBox name={`fixedRentalAvailability.${index}.sunday`} label="Sunday" value={fixedRental.sunday} checkboxGroupValue='sunday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={fixedRental.days} onDayChange={props.onFixedDaySelect} />
                              </Grid>
                            </Hidden>
                          </Grid>
                        )}
                      />
                      {!fixedRental.monday && !fixedRental.tuesday && !fixedRental.wednesday && !fixedRental.thursday && !fixedRental.friday && !fixedRental.saturday && !fixedRental.sunday &&
                        <div className="error">{props.errors.myCustomCheckboxFixedTest}</div>
                      }
                    </Grid>
                    <Grid item className="additionalCost" md={6} xs={12}>
                      <CustomizedCheckBox name={`fixedRentalAvailability.${index}.additionalCost`} label="Allow additional hours for additional cost" value={fixedRental.additionalCost} />
                    </Grid>
                  </Grid>
                ))
              )}
              {((props.openForm && !props.values.flexibleRental && props.values.fixedRentalAvailability.length <=3) || (props.values.flexibleRental && props.openForm && props.values.fixedRentalAvailability.length + props.values.flexibleRentalAvailability.length <=3)) &&
                <div className="add_time_slot">
                  <Button classes={{ root: classes.root }} variant="outlined" color="secondary" onClick={(e) => { addTimeSlot(arrayHelpers, 'fixedRentalAvailability') }}>
                    + Add Time Slot
                  </Button>
                </div>
              }
            </div>
          )}
        />
        :
        // props.fieldArrayName === 'flexibleRentalAvailability' ?
        // <FieldArray
        //   name="flexibleRentalAvailability"
        //   render={arrayHelpers => (
        //     <div>
        //       {(props.values.flexibleRentalAvailability && props.values.flexibleRentalAvailability.length > 0 && props.openForm) && (
        //         props.values.flexibleRentalAvailability.map((flexibleRental, index) => (
        //           <Grid container className="nested_space_availability_form_container" key={index}>
        //             <span className="material-icons close add_space_close closeButton" onClick={e => { props.deleteTimeslotForFlexible && props.deleteTimeslotForFlexible(flexibleRental.idx, props.values, props.setFieldValue) }}>close</span>
        //             <Grid className="left" item xs={12} sm={12} md={6} lg={6} xl={6}>
        //               <Grid container spacing={40}>
        //                 <Grid item xs={1} className="index_container">
        //                   <div className="form_index">
        //                     {index + 1}
        //                   </div>
        //                 </Grid>
        //                 <Grid item xs={5}>
        //                   <CustomTimeSelect placeholder="Start Time" names={time} name={`flexibleRentalAvailability.${index}.startTime`} value={flexibleRental.startTime} handleChange={props.handleChange} handleBlur={props.handleBlur} />
        //                 </Grid>
        //                 <Grid item xs={5}>
        //                   <CustomTimeSelect placeholder="End Time" names={time} name={`flexibleRentalAvailability.${index}.endTime`} value={flexibleRental.endTime} handleChange={props.handleChange} handleBlur={props.handleBlur} />
        //                 </Grid>
        //               </Grid>
        //             </Grid>
        //             <Grid className="right" item xs={12} sm={12} md={6} lg={6} xl={6}>
        //               <FieldArray
        //                 name={`flexibleRentalAvailability.${index}.days`}
        //                 render={arrayHelpers => (
        //                   <Grid container spacing={40}>
        //                     <Hidden xsDown={true}>
        //                       <Grid item sm={3} md={3} lg={3} xl={3}>
        //                         {/* <CustomCheckBox name={`flexibleRentalAvailability.${index}.monday`} label="Monday" value={flexibleRental.monday} disabled={(props.flexibleRentalDaysArray.includes('monday') && flexibleRental.monday === false) ? true : false} checkboxGroupValue='monday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} /> */}
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.monday`} label="Monday" value={flexibleRental.monday} checkboxGroupValue='monday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.tuesday`} label="Tuesday" value={flexibleRental.tuesday} checkboxGroupValue='tuesday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                       </Grid>
        //                       <Grid item sm={3} md={3} lg={3} xl={3}>
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.wednesday`} label="Wednesday" value={flexibleRental.wednesday} checkboxGroupValue='wednesday' checkboxAndCheckboxGroup  arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.thursday`} label="Thursday" value={flexibleRental.thursday} checkboxGroupValue='thursday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                       </Grid>
        //                       <Grid item sm={3} md={3} lg={3} xl={3}>
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.friday`} label="Friday" value={flexibleRental.friday} checkboxGroupValue='friday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.saturday`} label="Saturday" value={flexibleRental.saturday} checkboxGroupValue='saturday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                       </Grid>
        //                       <Grid item sm={3} md={3} lg={3} xl={3}>
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.sunday`} label="Sunday" value={flexibleRental.sunday} checkboxGroupValue='sunday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                       </Grid>
        //                     </Hidden>
        //                     <Hidden smUp={true}>
        //                       <Grid className="alignment_of_days" item xs={12}>
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.monday`} label="Monday" value={flexibleRental.monday} checkboxGroupValue='monday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.tuesday`} label="Tuesday" value={flexibleRental.tuesday} checkboxGroupValue='tuesday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.wednesday`} label="Wednesday" value={flexibleRental.wednesday} checkboxGroupValue='wednesday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.thursday`} label="Thursday" value={flexibleRental.thursday} checkboxGroupValue='thursday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.friday`} label="Friday" value={flexibleRental.friday} checkboxGroupValue='friday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.saturday`} label="Saturday" value={flexibleRental.saturday} checkboxGroupValue='saturday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                         <CustomCheckBox name={`flexibleRentalAvailability.${index}.sunday`} label="Sunday" value={flexibleRental.sunday} checkboxGroupValue='sunday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
        //                       </Grid>
        //                     </Hidden>
        //                   </Grid>
        //                 )}
        //               />
        //               <div className="error"><ErrorMessage name={`flexibleRentalAvailability.${index}.days`} /></div>
        //             </Grid>
        //             <Grid item className="additionalCost" xs={6}>
        //               <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.additionalCost`} label="Allow additional hours for additional cost" value={flexibleRental.additionalCost} />
        //             </Grid>
        //           </Grid>
        //         ))
        //       )}
        //        {props.openForm &&
        //           <div className="add_time_slot">
        //             <Button classes={{ root: classes.root }} variant="outlined" color="secondary" onClick={(e) => { addTimeSlot(arrayHelpers, 'flexibleRentalAvailability') }}>
        //               + Add Time Slot
        //             </Button>
        //           </div>
        //         }
        //     </div>
        //   )}
        // />
        // : null

        props.fieldArrayName === 'flexibleRentalAvailability' ?
          <FieldArray
            name="flexibleRentalAvailability"
            render={arrayHelpers => (
              <div>
                {(props.values.flexibleRentalAvailability && props.values.flexibleRentalAvailability.length > 0 && props.openForm) && (
                  props.values.flexibleRentalAvailability.map((flexibleRental, index) => (
                    <Grid container className="nested_space_availability_form_container" key={index}>
                      <span className="material-icons close add_space_close closeButton" onClick={e => { props.deleteTimeslotForFlexible && props.deleteTimeslotForFlexible(flexibleRental.idx, props.values, props.setFieldValue) }}>close</span>
                      <Grid className="left" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={40}>
                          <Grid item md={1} className="index_container">
                            <div className="form_index">
                              {index + 1}
                            </div>
                          </Grid>
                          <Grid item md={11} xs={12}>
                            <span class="nested-block">Do you allow more than one event in this space per day?</span>
                            <div>
                              <Grid className="flexible-block">
                                <CustomRadio name={`flexibleRentalAvailability.${index}.multipleEvent`} label="Yes, I allow more than one event per day" value={flexibleRental.multipleEvent} id={1} setFieldValue={props.setFieldValue}/>
                              </Grid>
                              <Grid className="flexible-block">
                                <CustomRadio name={`flexibleRentalAvailability.${index}.multipleEvent`} label="No only one event per day" value={flexibleRental.multipleEvent} id={0} setFieldValue={props.setFieldValue}/>
                              </Grid>
                              <Grid className="flexible-block">
                                <div class="max-hours">Pricing based on</div>
                                <div className="max-hours-select">
                                  <CustomTimeSelect placeholder="Min Hour" selectHour name={`flexibleRentalAvailability.${index}.minHour`} value={flexibleRental.minHour} handleChange={props.handleChange} handleBlur={props.handleBlur} />
                                </div>
                                <>
                                {flexibleRental.showMaxHour ?
                                  <>
                                    <div class="max-hours padding_left_min">Max hours:</div>
                                    <div className="max-hours-select">
                                      <CustomTimeSelect placeholder="Max Hour" selectHour name={`flexibleRentalAvailability.${index}.maxHour`} value={flexibleRental.maxHour} handleChange={props.handleChange} handleBlur={props.handleBlur} />
                                    </div>
                                    <span className="material-icons close close_button closeButton" onClick={(e) => { addMinimumHour(index, 'remove') }}>close</span>
                                  </>
                                  :
                                  <div className="min-add_time_slot">
                                    <Button classes={{ root: classes.root }} variant="outlined" color="secondary" onClick={(e) => { addMinimumHour(index, 'add') }}>
                                      + Add Maximum Hour
                                    </Button>
                                  </div>
                                }
                                </>
                              </Grid>
                            </div>

                          </Grid>
                        </Grid>
                      </Grid>
                      {flexibleRental.multipleEvent === 1  &&
                       <Grid className="right_flexible" item xs={12} sm={12} md={12} lg={12} xl={12}>
                       <div className="earliest_time"> Earliest time a planner can get in and latest they can leave for this time window?</div>
                     </Grid>
                      } {flexibleRental.multipleEvent === 0  &&
                        <Grid className="right_flexible" item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="earliest_time"> Earliest time a planner can get in and latest they can leave?</div>
                        </Grid>
                      }
                      <Grid className="right_flexible" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid className="right-select">
                          <CustomTimeSelect placeholder="Start Time" name={`flexibleRentalAvailability.${index}.startTime`} value={flexibleRental.startTime} handleChange={props.handleChange} handleBlur={props.handleBlur} type={props.startTime!==''&&props.startTime!=='undefined'?props.startTime:''}/>
                        </Grid>
                        <Grid className="right-select">
                          <CustomTimeSelect placeholder="End Time" name={`flexibleRentalAvailability.${index}.endTime`} value={flexibleRental.endTime} handleChange={props.handleChange} handleBlur={props.handleBlur} type={props.endTime!==''&&props.endTime!=='undefined'?props.endTime:''}/>
                        </Grid>

                        <Grid className="right-field">
                          <FieldArray
                            name={`flexibleRentalAvailability.${index}.days`}
                            render={arrayHelpers => (
                              <Grid container spacing={40}>
                                <Hidden xsDown={true}>
                                  <Grid item sm={3} md={3} lg={3} xl={3}>
                                    {/* <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.monday`} label="Monday" value={flexibleRental.monday} disabled={(props.flexibleRentalDaysArray.includes('monday') && flexibleRental.monday === false) ? true : false} checkboxGroupValue='monday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} /> */}
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.monday`} label="Monday" value={flexibleRental.monday} checkboxGroupValue='monday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.tuesday`} label="Tuesday" value={flexibleRental.tuesday} checkboxGroupValue='tuesday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                  </Grid>
                                  <Grid item sm={3} md={3} lg={3} xl={3}>
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.wednesday`} label="Wednesday" value={flexibleRental.wednesday} checkboxGroupValue='wednesday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.thursday`} label="Thursday" value={flexibleRental.thursday} checkboxGroupValue='thursday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                  </Grid>
                                  <Grid item sm={3} md={3} lg={3} xl={3}>
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.friday`} label="Friday" value={flexibleRental.friday} checkboxGroupValue='friday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.saturday`} label="Saturday" value={flexibleRental.saturday} checkboxGroupValue='saturday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                  </Grid>
                                  <Grid item sm={3} md={3} lg={3} xl={3}>
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.sunday`} label="Sunday" value={flexibleRental.sunday} checkboxGroupValue='sunday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                  </Grid>
                                </Hidden>
                                <Hidden smUp={true}>
                                  <Grid className="alignment_of_days" item xs={12}>
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.monday`} label="Monday" value={flexibleRental.monday} checkboxGroupValue='monday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.tuesday`} label="Tuesday" value={flexibleRental.tuesday} checkboxGroupValue='tuesday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.wednesday`} label="Wednesday" value={flexibleRental.wednesday} checkboxGroupValue='wednesday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.thursday`} label="Thursday" value={flexibleRental.thursday} checkboxGroupValue='thursday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.friday`} label="Friday" value={flexibleRental.friday} checkboxGroupValue='friday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.saturday`} label="Saturday" value={flexibleRental.saturday} checkboxGroupValue='saturday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                    <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.sunday`} label="Sunday" value={flexibleRental.sunday} checkboxGroupValue='sunday' checkboxAndCheckboxGroup arrayHelpers={arrayHelpers} handleChange={props.handleChange} checkboxGroup={flexibleRental.days} onDayChange={props.onFlexibleDaySelect} />
                                  </Grid>
                                </Hidden>
                              </Grid>
                            )}
                          />
                          {!flexibleRental.monday && !flexibleRental.tuesday && !flexibleRental.wednesday && !flexibleRental.thursday && !flexibleRental.friday && !flexibleRental.saturday && !flexibleRental.sunday &&
                              <div className="error">{props.errors.myCustomCheckboxFlexibleTest}</div>
                          }
                        </Grid>
                        <div className="error"><ErrorMessage name={`flexibleRentalAvailability.${index}.days`} /></div>
                      </Grid>
                      {flexibleRental.multipleEvent === 1  &&
                        <Grid item className="time_between_events" xs={12} md={6} >
                          <div className="additionalCost-block">How much time do you need between events?</div>
                          <div className="text-muted">(After one events loads-out and before the next loads-in)</div>
                          
                            <div className="additional-time">
                              <CustomTimeSelect placeholder="Time Between Event" selectHour name={`flexibleRentalAvailability.${index}.timeBetweenEvent`} value={flexibleRental.timeBetweenEvent} handleChange={props.handleChange} handleBlur={props.handleBlur} />
                            </div>
                          {/* <CustomizedCheckBox name={`flexibleRentalAvailability.${index}.additionalCost`} label="Allow additional hours for additional cost" value={flexibleRental.additionalCost} /> */}
                        </Grid>
                      }
                    </Grid>
                  ))
                )}
                {((props.openForm && !props.values.fixedValue && props.values.flexibleRentalAvailability.length <=3 ) || (props.values.fixedValue && props.openForm && props.values.fixedRentalAvailability.length + props.values.flexibleRentalAvailability.length <=3)) &&
                   <div className="add_time_slot">
                     <Button classes={{ root: classes.root }} variant="outlined" color="secondary" onClick={(e) => { addTimeSlot(arrayHelpers, 'flexibleRentalAvailability') }}>
                       + Add Time Slot
                     </Button>
                   </div>
                }
              </div>
            )}
          />
          : null
      }
    </div>
  );
}


NestedForm.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(NestedForm);

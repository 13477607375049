import { VENUE_DOCUMENT_UPLOAD } from 'shared/state/types';
import { request } from 'shared/auth';

function venueDocumentUploadRequested() {
  return {
    type: VENUE_DOCUMENT_UPLOAD.REQUEST,
  };
}

function venueDocumentUploadFailed(error) {
  return {
    type: VENUE_DOCUMENT_UPLOAD.FAILURE,
    error:error,
  };
}

function venueDocumentUploadSuccess(data) {
  return {
    type: VENUE_DOCUMENT_UPLOAD.SUCCESS,
    payload: data,
  };
}

export function venueDocumentUpload(params,id) {
  return dispatch => {
    dispatch(venueDocumentUploadRequested());
    return request().post(`venues/${id}/documents`, params)
      .then(response =>
        dispatch(venueDocumentUploadSuccess(response.data.data))
      )
      .catch(errors => {
        dispatch(venueDocumentUploadFailed(errors.response.data.errors));
        throw errors;
      });
  };
}

import axios from 'axios';
import { GET_CREATE_EVENT_REQUEST, GET_CREATE_EVENT_SUCCESS, GET_CREATE_EVENT_FAILURE } from 'shared/state/types';
// import Cookies from 'js-cookie';
import { loadProgressBar } from 'axios-progress-bar'

function createEventRequested() {
  return {
    type: GET_CREATE_EVENT_REQUEST
  };
}

function createEventSuccess(data) {
  return {
    type: GET_CREATE_EVENT_SUCCESS,
    eventDetails: data,
  };
}

function createEventFailure(error) {
  return {
    type: GET_CREATE_EVENT_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }


  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export function createEvent(params) {
    return dispatch => {
      dispatch(createEventRequested());
        return request().post(`events`,params)
        .then(response =>
          dispatch (createEventSuccess(response.data),
                ))
        .catch(errors => {
          dispatch(createEventFailure(errors.response.data.message));
  
          throw errors;
        });
    
   

    
 };
}

import React, {useState} from 'react';
import {object, func} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';

import {emailExists, enableSignupButton} from 'data/person/actions';
import {ErrorMessage, Field, useFormikContext} from 'formik';
import {Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import CustomizedButton from '../../../shared/components/customizedButton';
import CustomInput from '../../../shared/components/CustomInput';

import '../../assets/styles/auth.scss';

const styles = theme => ({
    root: {
        width: '121px',
        height: '36px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        background: 'linear-gradient(to bottom, #f1435e, #ee2738)',
        fontFamily: 'Raleway',
        padding: '0px',

        '&$disabled': {
            background: '#9aa7af',
            color: 'white',
            boxShadow: 'none'
        },
        '&:hover': {
            background: "#d80e2a"
        }
    },
    disabled: {}
});

function EmailInfo(props) {
    console.log('sss',props);
    const [passwordTypePass,
        setpasswordTypePass] = useState('password');
    const changePasswordFieldType = () => {
        if (passwordTypePass === 'password') {
            setpasswordTypePass('text')
        } else {

            setpasswordTypePass('password')
        }
    }

    const {values, errors, handleChange, handleSubmit} = useFormikContext();
    const handleChangeMail = (e) => {
        if (errors.email === undefined && values.email) {
            props
                .emailExists({email: values.email})
                .then((response) => {
                    handleSubmit()
                })
                .catch(error => {})
        }
    }
    const enableSignup = (e) => {
        props.enableSignupButton()
    }

    return (
        <div className="signup">
            <Grid className="signup__inner" container justify="center">
                <Grid item xl={3} xs={10} md={4} lg={4} sm={6}>                   
                    <div className="signupForm">
                    <h1 align="center" className="createAnAccount"></h1>
                        <div className="hintText">
                            Your business e-mail used for scheduling
                        </div>
                        <Grid>
                            <div className="formFields">
                                <Grid container justify="center">
                                    <Grid item sm={12} xs={12}>
                                        <label className="emailLabel">
                                            Email Address
                                        </label>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <Field
                                            className="emailField"
                                            align="center"
                                            type="text"
                                            name="email"
                                            disabled={values.emailStatus}
                                            placeholder="Email Address"
                                            onChange={e => {
                                            enableSignup(e);
                                            handleChange(e)
                                        }}/>
                                        <div className="error"><ErrorMessage name="email"/></div>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <label className="passwordLabel">
                                            Password
                                        </label>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <div
                                            className="password_field_div"
                                            style={{
                                            position: "relative"
                                        }}>
                                            <CustomInput
                                                st='basicinfo'
                                                type={passwordTypePass}
                                                name="password"
                                                placeholder="Create a password"
                                                passwordField
                                                changePasswordFieldType={changePasswordFieldType}/>
                                        </div>
                                    </Grid>

                                </Grid>

                                <div className="signupDiv">
                                    <CustomizedButton
                                        disabled={props.disableEmailSignupButton}
                                        onClickEvent={(e) => {
                                        handleChangeMail(e)
                                    }}
                                        label="Next"
                                        type="submit"/>

                                </div>
                            </div>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({
            emailExists,
            enableSignupButton
        }, dispatch)
    }
}
const mapStateToProps = state => {
    return {disableEmailSignupButton: state.data.auth.emailExistsError};
};

EmailInfo.propTypes = {
    classes: object.isRequired
};

const enhance = compose(withStyles(styles), withRouter, connect(mapStateToProps, mapDispatchToProps),)

export default enhance(EmailInfo);

import React from "react";
import { Formik, Form } from "formik";
import { Grid } from "@material-ui/core";
import Datepicker from "../../shared/components/DatePicker";
import CustomizedButton from "../../shared/components/customizedButton";
// import CategorySelect from "../../shared/components/CategorySelect";
import CustomSelect from '../../shared/components/CustomSelect';
import CustomTimeSelect from '../../shared/components/CustomTimeSelect.js';
import moment from "moment";
import Autocomplete from "react-autocomplete"; 

const time = [
"12:00 am",
"12:30 am",
"1:00 am",
"1:30 am",
"2:00 am",
"2:30 am",
"3:00 am",
"3:30 am",
"4:00 am",
"4:30 am",
"5:00 am",
"5:30 am",
"6:00 am",
"6:30 am",
"7:00 am",
"7:30 am",
"8:00 am",
"8:30 am",
"9:00 am",
"9:30 am",
"10:00 am",
"10:30 am",
"11:00 am",
"11:30 am",
"12:00 pm",
"12:30 pm",
"1:00 pm",
"1:30 pm",
"2:00 pm",
"2:30 pm",
"3:00 pm",
"3:30 pm",
"4:00 pm",
"4:30 pm",
"5:00 pm",
"5:30 pm",
"6:00 pm",
"6:30 pm",
"7:00 pm",
"7:30 pm",
"8:00 pm",
"8:30 pm",
"9:00 pm",
"9:30 pm",
"10:00 pm",
"10:30 pm",
"11:00 pm",
"11:30 pm"
];

      

function Search(props) {

var currentDate = moment().toDate();
let menuItems = props.menuData;
return (
<div className="browse_venue_search">
<Formik initialValues={props.initialValues}
onSubmit={actions => {
props.searchData(actions);
}}
>
{({ isSubmitting, values, handleBlur, handleChange, resetForm,setFieldValue }) => (
<Form>
<Grid container spacing={24} alignItems="center" justify="center" className="search">
<Grid item xs={10} className="fieldJustification">
<div className="starting-address">Search</div>
<div className="start-search">
<Autocomplete
                            fields={{ groupBy: 'value', value: 'menu' }}
                            value={values.address}
                            inputProps={{id: 'states-autocomplete',placeholder:'Search by Venue, City or Region'}}
                            wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                            items={menuItems}
                            getItemValue={item => item.title}
                            shouldItemRender={(item, val) => 
                              {
                                if(val.toLowerCase()=== "dallas"){
                                  let dallas = menuItems.filter(item => item.title==='Saddle up! We’ll be your way shortly');
                                  if(dallas.length==0){
                                    menuItems.push({title: "Saddle up! We’ll be your way shortly", id: "No", type: 10, default: 0});
                                  }
                                  val = 'Saddle up! We’ll be your way shortly';
                                }else{
                                  let dIndex = menuItems.findIndex( x => x.title === 'Saddle up! We’ll be your way shortly');
                                  if(dIndex > -1){menuItems.splice(dIndex,1)}
                                }

                                if(val.toLowerCase()=== "chicago"){
                                  let chicago = menuItems.filter(item => item.title==='Hold onto your hat! We’ll be there soon');
                                  if(chicago.length==0){
                                    menuItems.push({title: "Hold onto your hat! We’ll be there soon", id: "No", type: 10, default: 0});
                                  }
                                  val = 'Hold onto your hat! We’ll be there soon';
                                }else{
                                  let cIndex = menuItems.findIndex( x => x.title === 'Hold onto your hat! We’ll be there soon');
                                  if(cIndex > -1){ menuItems.splice(cIndex,1)}
                                } 

                                if(item.title.toLowerCase().indexOf(val.toLowerCase()) !== -1 || ((val.toLowerCase()== "wine" || val.toLowerCase()== "wine country" ) && item.title.toLowerCase().indexOf("Napa & Sonoma".toLowerCase()) !== -1) ){
                                  return item;
                                }                             
                             }
                            }
                            header={<span style={{ marginLeft: '60px' }}>header</span>}
                            renderMenu={item => (
                              <div className = "suggestionDiv">
                                    { item }
                              </div>
                            )}
                            renderItem={(item, isHighlighted) =>
                              <div className={`head`}>
                                  {values.address=='' || values.address==null?
                                    item.default===1 ?
                                      <div className={`item ${isHighlighted ? 'item-highlighted' : ''}`}>
                                        {item.title}
                                      </div>
                                      :
                                      ''
                                      :
                                      <div className={`item ${isHighlighted ? 'item-highlighted' : ''}`}>
                                        {item.title}
                                      </div>
                                  }
                              </div>
                            }
                            onChange={(event, val) => setFieldValue('address', val)}
                            onSelect={(val,item) => {
                              if(item.id!=='No' && item.id!=='Title'){
                              setFieldValue('addressKey', item.id)
                              setFieldValue('address', val)
                              if(item.type==2){
                                let searchData = '';
                                Object.keys(values).forEach(key=>{
                                  if(key !=='addressKey' && key !=='address' ){
                                    searchData  += searchData!=='' ?'&' + key+'='+values[key] : '?' + key+'='+values[key]
                                  }
                                });
                                let searchLink = '/venue-profile/'+item.slug.split('|')[0]+searchData
                                window.location.href= searchLink;     
                              }
                            }                            
                            }}
                            />
{/* <Autocomplete
fields={{ groupBy: 'value', value: 'menu' }}
value={ values.address }
inputProps={{id: 'states-autocomplete',placeholder:'Search by Venue, City or Region'}}
wrapperStyle={{ position: 'relative', display: 'inline-block' }}
items={ props.menuData }
getItemValue={ item => item.key }
//shouldItemRender={ matchSuggestion }
onChange={(e) => {props.onSearchChnage(e.target.value); setFieldValue('address', e.target.value)}}
onSelect={(val,item) => {if(item.id!=='No' && item.id!=='Title'){setFieldValue('address',item.key); setFieldValue('addressKey',item.id); }}}
renderMenu={ children => (
<div className = "suggestionDiv">
{ children }
</div>
)}
renderItem={ (item, isHighlighted) => (
<div
className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
key={ item.id } >
{ item.id ==='Title' ? <b>{item.key}</b>:item.key}
</div>
)}
/> */}

</div>
{props.errorCity===true?
<div className="error">
*Required
</div>
:
''}
{/* <Field className="address" align="center" type="text" name="address" placeholder="Search by Venue, City or Region"/> */}
</Grid>
<Grid item xs={10} className="dateTimeFieldJustification">
<Grid container alignItems="center">
<Grid item xs={5} className="start">
<Datepicker theme="blue" name="startDate" placeholder="Start Date" 
maxDate={moment(
values.endDate
).toDate()}
minDate={currentDate}
onKeyDown={e => e.preventDefault()}
dateFormat="MM/dd/yyyy" style={{"padding":"10px"}}/>
</Grid>
<Grid item xs={2}>
<div className="to">to</div>
</Grid>
<Grid item xs={5} className="end">
<Datepicker theme="blue" name="endDate" placeholder="End Date" 
onKeyDown={e => e.preventDefault()}

minDate={moment(
values.startDate
).toDate()}
dateFormat="MM/dd/yyyy" style={{"padding":"10px"}}/>
</Grid>
</Grid>
</Grid>
<Grid item xs={10} className="dateTimeFieldJustification">
<Grid container alignItems="center">
<Grid item xs={5}>
{/* <CustomTimePicker name="startTime" /> */}

<CustomTimeSelect type={"browseVenue"} placeholder="Start Time" styleClass="browseVenue" names={time} name="startTime" value={values.startTime} handleChange={handleChange} handleBlur={handleBlur} />
</Grid>
<Grid item xs={2}>
<div className="to"> to </div>
</Grid>
<Grid item xs={5}>
<CustomTimeSelect type={"browseVenue"} placeholder="End Time" styleClass="browseVenue" names={time} name="endTime" value={values.endTime} handleChange={handleChange} handleBlur={handleBlur} />
</Grid>
</Grid>
</Grid>
<Grid item xs={10} className="fieldJustification custom-select">
{/* <CategorySelect name="category" placeholder="Event Type" color="#62717a" width="100%" /> */}

<CustomSelect id="category" name="category" categoryData={props.categoryData} placeholder="EVENT TYPE" color="#62717a" width="100%" />
</Grid>
<Grid item xs={10} className="fieldJustification custom-select">
<CustomSelect id="seating" name="seating" seatingData={props.seatingData} placeholder="Seating" color="#62717a" width="100%" />
</Grid>
<Grid item xs={10} className="fieldJustification custom-select">
<CustomSelect id="guest_count" name="guest_count" placeholder="# Of Guests" color="#1397e1" width="100%" />
</Grid>
<Grid item xs={10} className="fieldJustification custom-select">
<CustomSelect id="budget" name="budget" placeholder="Venue Rental Budget" color="#1397e1" width="100%" />
</Grid>
<Grid item xs={10}>
<Grid container justify="center">
<Grid item className="save_search">
<CustomizedButton label={props.sendData ? "Searching..." : "Search"} disabled={props.sendData} type="submit" />
</Grid>
{values.address || values.startDate || values.endDate || values.startTime || values.endTime || values.seating || values.category || values.guest_count || values.budget ? (
<Grid item className="save_search clearfilter">
<CustomizedButton label="Clear Filter" onClickEvent={(e)=>props.reset(resetForm,props.initialValues)} type="button"/>
</Grid>
) : null}
</Grid>
</Grid>
</Grid>
</Form>
)}
</Formik>
</div>
);
}

export default Search;


import { fetchMe } from 'data/person/actions';
import {  removeToken, getTempToken } from 'shared/auth';
import { LOGGING_IN, LOGGED_IN, LOGGING_IN_FAILED } from 'shared/state/types';

function typeDispatcher(type) {
  return { type };
}

export function bootstrap() {
  return dispatch => {
    dispatch(typeDispatcher(LOGGING_IN));
    if (!getTempToken()) {
      dispatch(typeDispatcher(LOGGING_IN_FAILED));
      return;
    }

    return dispatch(fetchMe())
      .then(() => {
        dispatch(typeDispatcher(LOGGED_IN));
      })
      .catch(() => {
        removeToken();
        dispatch(typeDispatcher(LOGGING_IN_FAILED));
      });
  };
}

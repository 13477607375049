import axios from 'axios';
import { POST_GOOGLE_SIGNUP_REQUEST, POST_GOOGLE_SIGNUP_SUCCESS, POST_GOOGLE_SIGNUP_FAILURE } from 'shared/state/types';
import { loadProgressBar } from 'axios-progress-bar'

function googleApiRequested() {
  return {
    type: POST_GOOGLE_SIGNUP_REQUEST
  };
}


function googleApiSuccess(data) {
  return {
    type: POST_GOOGLE_SIGNUP_SUCCESS,
    googleUrlDetails: data,
  };
}

function googleApiFailure(error) {
  return {
    type: POST_GOOGLE_SIGNUP_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {

  const headers = {};
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance;  
};

export function googleSignUp() {
    return dispatch => {
     
      dispatch(googleApiRequested());
        return request().get('sso/google')
        .then(response =>
          dispatch (googleApiSuccess(response.data),
                )        )
        .catch(errors => {          
          dispatch(googleApiFailure(errors.message));
          throw errors;
        });
      


    
 };
}

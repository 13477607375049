import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import '../assets/styles/myHolds.scss';
// import { checkPropTypes } from 'prop-types';

export default function HoldTab(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    props.changeTabs(newValue);
    setValue(newValue);
  };

  return (
    <Paper square class="box-tab">
      <Tabs className="tabs"
        value={value}
        indicatorColor="primary"
        variant="scrollable"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab label="All" />
        <Tab label="Holds" />    
        <Tab label="Bookings" />
        <Tab label="Blocked" />
        <Tab label="Follows" />
      </Tabs>
    </Paper>
  );
}

import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import VenueFooter from '../../../shared/footer/venueFooter';
import AboutCompany from '../components/aboutCompany';
import '../assets/styles/aboutUs.scss';
import { object, func } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { pageDetails } from "shared/pageDetails/actions";
import { Helmet } from "react-helmet";

class AboutUs extends Component {
  state={
    apicall: false
  }
  componentWillMount() {
    this.props.pageDetails(6).then(() => {
      this.setState({apicall:true})
      })
 
  }
  render() {
    return (
      <div className="venue_page">
         <Helmet>
          <meta name="description" content="Giving you hours of your life back has been our priority since first-hold was created ⁠— for both planners and venue managers."/>
        </Helmet>
        <Grid xs={12} item>
        </Grid>
        <Grid xs={12} item className="midContainer">
          <Grid container justify="center">
            <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
              {this.state.apicall===true ? 
              <AboutCompany pageData={this.props.content}/>
              : null }
            </Grid>
          </Grid>
        </Grid>
        <VenueFooter className="fh-container" />
        {/* <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
          <Grid container justify="center">
            <VenueFooter />
          </Grid>
        </Grid> */}
      </div>

    );
  }
}

AboutUs.propTypes = {
  classes: object.isRequired,
  pageDetails: func.isRequired


};

const mapStateToProps = state => {

  return {
  content:state.data.pageDetails.content
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        pageDetails
      },
      dispatch
    )
  )
);
export default enhance(AboutUs);

import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import EventPlannerHeader from '../components/EventPlannerHeader';
import Header from '../components/Header';
import HomeHeader from '../components/HomeHeader';
import VenueHeader from '../components/VenueHeader';
import StickyHeader from '../components/StickyHeader';



class MainHeader extends React.Component {

    render() {
      
        const pathname = this.props.location.pathname.slice(1,this.props.location.pathname.length)
        const url = pathname.indexOf('/') !== -1 ? pathname.substring(0, pathname.indexOf('/')) : pathname.substring(0, pathname.length)
        return (           
            this.props.location.pathname !== '/pdfReader' && this.props.location.pathname !== '/webCalendar' && this.props.location.pathname !== '/searchForStatus' && this.props.location.pathname !== '/syncingCalendar' &&
            <div>
            {
                this.props.location.pathname != "/" && 
                <Helmet>
                <meta name="robots" content="noindex"></meta>
                </Helmet>
            }
                {/* <img id="imgProfile" height="200" src="https://image.shutterstock.com/image-vector/sample-stamp-square-grunge-sign-260nw-1474408826.jpg" width="200" alt="The Scream" crossOrigin="anonymous"/>
                <canvas style={{display:"none"}} id="myCanvas" width="240" height="297"></canvas> */}

            {localStorage.getItem('fhtoken') !== null ?
                <div>        
                    {
                        this.props.location.pathname.includes('/calendar/') !== true
                        && this.props.location.pathname.includes('/WidgetTypeTwo/') !== true 
                        && this.props.location.pathname.includes('/register-with-mobile') !== true
                        && this.props.location.pathname.includes('/register-with-email') !== true
                        ?
                        this.props.location.pathname === '/' || this.props.location.pathname == '/planners'?
                        <StickyHeader />
                        :['browseVenue','venueProfile','faq','aboutUs','contactUs','profileInfoConfirm','confirmEmail','confirmPhone'].includes(url) ?
                            <EventPlannerHeader/>
                            :['planners','listYourSpaces'].includes(url) ?
                            <StickyHeader />
                        : (localStorage.getItem('type') === '0') ?
                            <EventPlannerHeader />
                            : (localStorage.getItem('type') === '1')&&
                            <VenueHeader />
                            :
                            null
                            
                    }

                </div>
                :
                <div>
                       {this.props.location.pathname.includes('/calendar/') !== true
                        && this.props.location.pathname.includes('/WidgetTypeTwo/') !== true 
                        && this.props.location.pathname.includes('/register-with-mobile') !== true
                        && this.props.location.pathname.includes('/register-with-email') !== true
                    ?
                    this.props.location.pathname !== '/' && this.props.location.pathname !== '/planners' && this.props.location.pathname !== '/listYourSpaces' ?
                        <StickyHeader />
                        :this.props.location.pathname == '/' || this.props.location.pathname == '/planners' ?
                        <StickyHeader />
                        :
                        <HomeHeader />
                        
                    :
                    null
                    }
                </div>}
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        loggedIn: state.data.auth.loggedIn,
        id: state.data.auth.id,
        type: state.data.auth.type,
        auth: state.data.auth
    };
};

const enhance = compose(
    withRouter,
    connect(mapStateToProps)
)
export default enhance(MainHeader);

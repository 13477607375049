import React from 'react';
import PropTypes from 'prop-types';

import NotificationPopup from '../../components/NotificationPopup';
import RightSideDropMenu from 'shared/components/RightSideDropMenu';

import { AppBar, Toolbar, IconButton, MenuItem, Menu, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from "react-redux";
import { func } from "prop-types";
import Autocomplete from "react-autocomplete";
import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import { staticVenueOnLoad } from "shared/home/actions";
import SearchIcon from '@material-ui/icons/Search';
import { openListYourSpace } from 'data/master/actions';
import './../assets/styles/eventHeader.scss';
import { userDetails } from "shared/venueList/actions";
const logo = require("../../components/assets/images/user-default.png");

const styles = theme => ({

  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
    boxShadow: 'none',
  },
  signupDiv: {
    width: '100%',

  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    transition: 'none',
    paddingLeft: '45px',
    paddingRight: '8px',
    fontSize: '11px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appbar: {
    backgroundColor: '#ffffff',
    color: '#36434b',
    boxShadow: 'none'
  },

  selected: {
    borderBottom: '2px #ee2738 solid !important',
    backgroundColor: '#ffffff !important'
  },
  image: {
    height: '56px',
    borderRadius: '50px',
    border: '1px black solid'
  },
  profileImageIcon: {
    padding: '0px 0px 0px 20px'
  },
  notificationsIcon: {
    padding: '0px 0px 0px 0px'
  },
  MuiMenuItem: {
    height: 'auto',
    lineHeight: '0.5',
  }
});

let suggestions = [];
let default_address = [];
let addresses = [];
let venues = [];
class EventPlannerHeader extends React.Component {
  _isMounted = true;
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    selectedSeachkey: '',
    suggestions: [],
    open: false,

  };
  UNSAFE_componentWillMount() {

    this.props.userDetails().then((res) => {
    })



  }
  componentDidMount() {
    this._isMounted = true;
    let searchParams = {
      "search_key": "Conferences"
    }
    this.props.staticVenueOnLoad(searchParams).then(response => {
      if (response.venueSuggestions && Object.keys(response.venueSuggestions).length !== 0) {
        suggestions = [];
        // response.venueSuggestions.addresses.map(function (data, idx) {
        //     let obj={};
        //     obj.abbr= {};
        //     obj.abbr.id = idx;
        //     obj.abbr.type = 1;
        //     obj.abbr.text = data.city;
        //     obj.name=data.city;
        //     suggestions.push(obj);
        //     return suggestions;
        //   })
        // this.setState({ suggestions: suggestions });
        suggestions = [];

        //default address
        response.venueSuggestions.default_address.map(function (data, idx) {

          var obj_a = {};
          obj_a.title = data.city;
          obj_a.id = idx + '|1';
          obj_a.type = 1;
          obj_a.default = 1;
          suggestions.push(obj_a);
          // let obj={};
          // obj.key=data.city;
          // obj.id=idx+'|1';
          // suggestions.push(obj);
          // default_address=response.venueSuggestions.default_address;

        })

        response.venueSuggestions.addresses.map(function (data, idx) {
          var obj_a = {};
          obj_a.title = data.city;
          obj_a.id = idx + '|1';
          obj_a.type = 1;
          obj_a.default = 0;
          suggestions.push(obj_a);
        })

        response.venueSuggestions.venues.map(function (data, idx) {
          var obj_v = {};
          obj_v.title = data.name;
          obj_v.id = data.slug + '|2';
          obj_v.type = 2;
          obj_v.default = 0;
          suggestions.push(obj_v);
        })

        // address
        addresses = response.venueSuggestions.addresses;


        //venues
        venues = response.venueSuggestions.venues;

        this.setState({ suggestions: suggestions })

      }
    })
      .catch(error => this.setState({ errorValue: true }));

  }

  componentWillUnmount() {
    //this._isMounted = false;
  }

  onSearchChange = (value) => {
    let searchParams = {};
    if (value !== '') {
      searchParams = {
        search_key: value
      }
    }
    this.props.staticVenueOnLoad(searchParams).then(response => {
      if (response.venueSuggestions && Object.keys(response.venueSuggestions).length !== 0) {
        suggestions = [];
        response.venueSuggestions.addresses && response.venueSuggestions.addresses.map(function (data, idx) {
          let obj = {};
          obj.abbr = {};
          obj.abbr.id = idx;
          obj.abbr.type = 1;
          obj.abbr.text = data.city;
          obj.name = data.city;
          suggestions.push(obj);
          return suggestions;
        })
        response.venueSuggestions.venues && response.venueSuggestions.venues.map(function (data, idx) {
          let obj = {};
          obj.abbr = {};
          obj.abbr.id = data.id;
          obj.abbr.type = 2;
          obj.abbr.text = data.name;
          obj.name = data.name;
          suggestions.push(obj);
          return suggestions;
        })
        response.venueSuggestions.venue_types && response.venueSuggestions.venue_types.map(function (data, idx) {
          let obj = {};
          obj.abbr = {};
          obj.abbr.id = idx;
          obj.abbr.type = 3;
          obj.abbr.text = data.name;

          obj.name = data.name;
          suggestions.push(obj);
          return suggestions;
        })
        this.setState({ suggestions: suggestions });


      }
    })
      .catch(error => this.setState({ errorValue: true }));
  }

  submitToSearch = (item, value) => {
    let searchData = '/browseVenue';


    if (item.title) {
      let type = item.id.split('|')[1];
      let id = item.id.split('|')[0];
      if (parseInt(type) === 1) {
        searchData = '/browseVenue?address=' + encodeURIComponent(item.title);
      }
      if (parseInt(type) === 2) {
        searchData = '/venue-profile/' + id;
      }
      if (parseInt(type) === 3) {
        searchData = '/browseVenue/' + item.title + '/type';
      }
    }
    else if (item.address) {
      searchData = '/browseVenue?address=' + encodeURIComponent(item.address);

    }
    else {
      searchData = '/browseVenue?address=';

    }


    window.location.href = searchData;
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };
  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };
  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
    window.location.href="/browseVenue";
  };
  redirectToParent = event => {
    window.location.href="/browseVenue";
  }
  openListSpaceLink = () => {
    // this.props.openListYourSpace()
    this.handleMobileMenuClose()
  }
  searchIconClick = (event) => {
  }
  render() {
    // const param = this.props.match.param;
    let urlpatharr = this.props.location.pathname.trim('/').split('/')
    const { mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    // function matchSearchVal(state, value) {
    // return (
    //   state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
    //   state.abbr.toLowerCase().indexOf(value.toLowerCase()) !== -1
    // );
    // }
    let menuItems = this.state.suggestions;
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem component={Link}  onClick={this.handleMobileMenuClose}>
          <p>Browse Venues</p>
        </MenuItem>
        <MenuItem component={Link} to="/editProfile" onClick={this.handleMobileMenuClose}>
          <p>Account</p>
        </MenuItem>
        {parseInt(localStorage.getItem('type')) === 1 ?
          (localStorage.getItem('fhtoken') === null) ?
            <MenuItem component={Link} to="/" onClick={e => {
              this.handleMobileMenuClose();
              this.openListSpaceLink()
            }}
            ><p>List Your Space</p></MenuItem>
            :
            <MenuItem component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
              <p>My Venues</p>
            </MenuItem>
          :
          <MenuItem component={Link} to="/myEvents" onClick={this.handleMobileMenuClose}><p>My Events</p></MenuItem>
        }
        <MenuItem component={Link} to="/logout" onClick={this.handleMobileMenuClose}>
          <p>Logout</p>
        </MenuItem>
      </Menu>

    )
    return (
      <div className={`${classes.signupDiv} event-header`}>
        <AppBar className={classes.appbar}>
          {
            this.props.location.pathname!=('/blog/top-10-trending-event-themes-of-2024')&&(this.props.location.pathname === ('/help') || this.props.location.pathname.includes('/help/search') || this.props.location.pathname.includes('/help/article') || this.props.location.pathname === ('/help/articles') ||this.props.location.pathname.includes('/blogs') ||this.props.location.pathname.includes('/blog') ||this.props.location.pathname.includes('/blog_detail'))  ? null :

              <Toolbar>
                <Grid item xs={12}>
                  <Grid container alignContent="center" className="topEventNav Notify">
                    <Grid item md={6} xs={10} sm={11} xl={6} lg={6}>
                      <Grid container justify="center" className="logo_and_search">
                        <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                          <div className="toplogoDiv">
                            <Link to='/'>
                              <img className={classes.image} src={require('./../assets/images/LogoWithText.png')} alt="FIRST-HOLD" />
                            </Link>
                          </div>
                        </Grid>
                        <Grid item xs={9} sm={9} md={10} lg={10} xl={10} >
                          <div className="topSearchDiv">
                            <div className="headesearch_container">

                              <Formik
                                initialValues={{ value: "" }}
                                onSubmit={(values, { setSubmitting }) => {
                                  this.submitToSearch(values)
                                }}
                              >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                  setFieldValue
                                  /* and other goodies */
                                }) => (
                                  <Form method="GET">
                                    <SearchIcon onClick={() => this.submitToSearch(values)} className="header-search-icon"></SearchIcon>
                                    <Autocomplete
                                      fields={{ groupBy: 'value', value: 'menu' }}
                                      value={values.address}
                                      inputProps={{ id: 'states-autocomplete', placeholder: 'Search by Venue, City or Region' }}
                                      wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                                      items={menuItems}
                                      getItemValue={item => item.title}
                                      shouldItemRender={(item, val) => {

                                        if (val.toLowerCase() === "dallas") {
                                          let dallas = menuItems.filter(item => item.title === 'Saddle up! We’ll be your way shortly');
                                          if (dallas.length == 0) {
                                            menuItems.push({ title: "Saddle up! We’ll be your way shortly", id: "No", type: 10, default: 0 });
                                          }
                                          val = 'Saddle up! We’ll be your way shortly';
                                        } else {
                                          let dIndex = menuItems.findIndex(x => x.title === 'Saddle up! We’ll be your way shortly');
                                          if (dIndex > -1) { menuItems.splice(dIndex, 1) }
                                        }

                                        if (val.toLowerCase() === "chicago") {
                                          let chicago = menuItems.filter(item => item.title === 'Hold onto your hat! We’ll be there soon');
                                          if (chicago.length == 0) {
                                            menuItems.push({ title: "Hold onto your hat! We’ll be there soon", id: "No", type: 10, default: 0 });
                                          }
                                          val = 'Hold onto your hat! We’ll be there soon';
                                        } else {
                                          let cIndex = menuItems.findIndex(x => x.title === 'Hold onto your hat! We’ll be there soon');
                                          if (cIndex > -1) { menuItems.splice(cIndex, 1) }
                                        }

                                        if (item.title.toLowerCase().indexOf(val.toLowerCase()) !== -1 || ((val.toLowerCase() == "wine" || val.toLowerCase() == "wine country") && item.title.toLowerCase().indexOf("Napa & Sonoma".toLowerCase()) !== -1)) {
                                          return item;
                                        }
                                      }
                                      }
                                      header={<span style={{ marginLeft: '60px' }}>header</span>}
                                      renderMenu={item => (
                                        <div className="suggestionDiv">
                                          {item}
                                        </div>
                                      )}
                                      renderItem={(item, isHighlighted) =>
                                        <div className={`head`}>
                                          {values.address == '' || values.address == null ?
                                            item.default === 1 ?
                                              <div className={`item ${isHighlighted ? 'item-highlighted' : ''}`}>
                                                {item.title}
                                              </div>
                                              :
                                              ''
                                            :
                                            <div className={`item ${isHighlighted ? 'item-highlighted' : ''}`}>
                                              {item.title}
                                            </div>
                                          }
                                        </div>
                                      }
                                      onChange={(event, val) => setFieldValue('address', val)}
                                      onSelect={(val, item) => { if (item.id !== 'No' && item.id !== 'Title') { setFieldValue('address', val); this.setState({ 'selectedSeachkey': item.title }); this.submitToSearch(item, val) } }} />
                                    {/* <Autocomplete
                                 fields={{ groupBy: 'value', value: 'menu' }}
                                  value={values.address}
                                  inputProps={{ id: 'states-autocomplete', placeholder: 'Search by Venue, City or Region' }}
                                  wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                                  items={this.state.suggestions}
                                  getItemValue={item => item.name}
                                  //shouldItemRender={matchSearchVal}
                                  onChange={(e) => {this.onSearchChange(e.target.value); setFieldValue('address', e.target.value)}}
                                  onSelect={(val, item) => {setFieldValue('address', val); this.setState({'selectedSeachkey': item.abbr});this.submitToSearch(item,val)} }
                                  renderMenu={children => (
                                    <div className="suggestionDiv">
                                      {children}
                                    </div>
                                  )}
                                  renderItem={(item, isHighlighted) => (
                                    <div
                                      className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                      key={item.abbr}>
                                      {item.name}
                                    </div>
                                  )}
                                /> */}
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          </div>
                          <NotificationPopup />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={1} sm={1} xl={6} lg={6} className="header-mobile">
                      <div className={classes.sectionDesktop}>
                        <div className="topEventNav">
                          <MenuItem
                            className="topEventMenu"
                            classes={{ selected: classes.selected }}
                            disableGutters={true}
                            selected={[`browseVenue`, `venueProfile`].includes(urlpatharr[1]) ?
                              true
                              :
                              false
                            }
                            component={Link}
                            onClick={this.redirectToParent}
                          >
                            Browse Venues
                          </MenuItem>
                          {(parseInt(localStorage.getItem('type')) === parseInt('1')) ?
                            (localStorage.getItem('fhtoken') === null) ?
                              <MenuItem className="topEventMenu" classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/myEvents'} component={Link} to="/" onClick={this.openListSpaceLink}>List Your Space</MenuItem>
                              :
                              <MenuItem className="topEventMenu" classes={{ selected: classes.selected }} component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
                                <p>My Venues</p>
                              </MenuItem>
                            :
                            <MenuItem className="topEventMenu" classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/myEvents'} component={Link} to="/myEvents">My Events</MenuItem>
                          }

                        </div>
                        <NotificationPopup />
                        <IconButton color="inherit">

                          {this.props.userData.data && this.props.userData.data.profilePhoto ?
                            <RightSideDropMenu profilePhoto={this.props.userData.data && this.props.userData.data.profilePhoto.path} />
                            :
                            <RightSideDropMenu profilePhoto={logo} />
                          }
                        </IconButton>
                      </div>
                      <div className={classes.sectionMobile}>
                        <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                          <Icon>more_vert</Icon>
                        </IconButton>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>}
        </AppBar>
        {renderMobileMenu}

      </div>
    );
  }
}


EventPlannerHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  staticVenueOnLoad: func.isRequired,
};
const mapStateToProps = state => {

  return {
    suggestions: state.data.venueLoadSuggestion.suggestions,
    userData: state.data.usersVenue.userInfo,

  };
};

const enhance = compose(
  connect(
    mapStateToProps,

    dispatch =>
      bindActionCreators(
        {
          openListYourSpace,
          staticVenueOnLoad,
          userDetails
        },
        dispatch
      )
  ),
  withRouter,
  withStyles(styles)
)

export default enhance(EventPlannerHeader);

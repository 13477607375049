import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { object } from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators, compose } from 'redux';
import { openListYourSpace } from 'data/master/actions';
import RightSideDropMenu from 'shared/components/RightSideDropMenu';
import { userDetails } from "shared/venueList/actions";

import {
  AppBar,
  Icon,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import './../assets/styles/header.scss';
const logo = require("../../components/assets/images/user-default.png");

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  headercontainer: {
    width: '100%',
    maxWidth: '1500px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  browse: {
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '12px'
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  listYourSpaceButton: {
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '12px'
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      marginRight: '20px',
      width: 'auto',
    },
  },
  browseIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appbar: {
    background: 'transparent',
    color: '#fff',
    boxShadow: 'none',
    position: 'absolute'
  },
  list: {
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    border: 'none',
    margin: '0px 19px 0px 19px',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: '#ffffff',
    '&:focus': {
      background: "transparent",
    },
    '&:hover': {
      background: "transparent",
      color:'#ffffff',
      opacity:0.9
    }
  },
  signUp: {
    fontSize: '14px',
    fontWeight: 'bold',
    border: 'none',
    margin: '0px 10px 0px 10px',
    textTransform: 'uppercase',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: '#ffffff',
    '&:focus': {
      background: "transparent",
    },
    '&:hover': {
      background: "transparent",
      color:'#ffffff',
      opacity:0.9
    }
  },
  logIn: {
    fontSize: '14px',
    fontWeight: 'bold',
    border: 'none',
    margin: '0px 15px 0px 19px',
    textTransform: 'uppercase',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: '#ffffff',
    '&:focus': {
      background: "transparent",
    },
    '&:hover': {
      background: "transparent",
      color:'#ffffff',
      opacity:0.9
    }
  },
  button: {
    backgroundColor: '#1397e1',
    fontFamily: 'Raleway',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    color: '#ffffff',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#1397e1',
      fontSize: '11px',
      '&:hover': {
        background: '#0a85ca'
      },
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: '#1397e1',
      '&:hover': {
        background: '#0a85ca'
      },
      // width: '152px',
      height: '38px'

    },
  },
  selected: {
    // borderBottom: '2px #ee2738 solid',
    // backgroundColor: '#ffffff !important'
    backgroundColor: 'transparent !important'
  },
  overWriteLink: {
    textDecoration: 'none',
    color: '#fffff'
  }
});

class HomeHeader extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };

  UNSAFE_componentWillMount() {
    this.props.userDetails().then(() => {
    })
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = (linkUrl) => {
    if (linkUrl === 'register') {
      this.props.history.push('./register')
    }
    else if (linkUrl === 'login') {
      this.props.history.push('./login')
    } else if (linkUrl === 'logout') {
      this.props.history.push('./logout')
    }
    this.setState({ mobileMoreAnchorEl: null });
  };

  openListSpaceLink = () => {
    // this.props.openListYourSpace()
    this.handleMobileMenuClose()
  }
  render() {
    const { mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        {(localStorage.getItem('fhtoken') !== null) ?
         (localStorage.getItem('type') === '1') ?
         (localStorage.getItem('fhtoken') === null)?
        <MenuItem component={Link} to="/planners" onClick={this.openListSpaceLink}>
          <p>For Planners</p>
        </MenuItem>
        :
        <MenuItem component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
          <p>My Venues</p>
        </MenuItem>
        :
        <MenuItem component={Link} to="/myEvents">
          <p>My Events</p>
        </MenuItem>
        :
        (localStorage.getItem('fhtoken') === null)?
        <MenuItem component={Link} to="/planners" onClick={this.openListSpaceLink}>
          <p>For Planners</p>
        </MenuItem>
        :
        <MenuItem component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
          <p>My Venues</p>
        </MenuItem>
        }
        
        {(localStorage.getItem('fhtoken') !== null) ?
          ''
          :
          <MenuItem onClick={() => this.handleMobileMenuClose('register')}>
            <p>Sign up</p>
          </MenuItem>
        }
        {(localStorage.getItem('fhtoken') !== null) ?
          <MenuItem onClick={() => this.handleMobileMenuClose('logout')}>
            <p>Logout</p>
          </MenuItem>
          :
          <MenuItem onClick={() => this.handleMobileMenuClose('login')}>
            <p>Login</p>
          </MenuItem>
        }
      </Menu>
    )

    return (
          <div className={classes.root}>
              <AppBar className={classes.appbar}>
                <Toolbar className={classes.headercontainer}>
                  <Link className={classes.overWriteLink} to='/'>
                    <img className={classes.logo} src={require('./../assets/images/LogoWithText.png')} alt="FIRST-HOLD" title="FIRST-HOLD"/>
                  </Link>
                  <div className={classes.grow} />
                    <div className={classes.listYourSpaceButton}>
                      <Link className={classes.overWriteLink} to="/browseVenue">
                        <Button variant="contained" title=" Browse Venues" color="primary" align="left" className={classes.button}>
                          Browse Venues
                        </Button>
                      </Link>
                    </div>
                    {
                    /*this.props.location.pathname === '/listYourSpaces' ?
                    <div className={classes.browse}>
                      <Link className={classes.overWriteLink} to="/listYourSpaces">
                        <Button variant="contained" title=" List Your Space" color="primary" align="left" className={classes.button}>
                          List Your Space
                        </Button>
                      </Link>
                    </div>
                    :
                    <div className={classes.listYourSpaceButton}>
                      <Link className={classes.overWriteLink} to="/browseVenue">
                        <Button variant="contained" title=" Browse Venues" color="primary" align="left" className={classes.button}>
                          Browse Venues
                        </Button>
                      </Link>
                    </div>
                    */
                  }

                  <div className={classes.sectionDesktop}>
                    {/*(localStorage.getItem('fhtoken') !== null) ?
                      (localStorage.getItem('type') === '1') ?
                      (localStorage.getItem('fhtoken') === null) ?
                      this.props.location.pathname !== '/listYourSpaces' &&
                    <MenuItem className={classes.list} classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/listYourSpaces'} to="/listYourSpaces" component={Link} title="List Your Space" onClick={this.openListSpaceLink}>List Your Space1</MenuItem>
                    :
                    <MenuItem className={classes.list} component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
                      <p>My Venues</p>
                    </MenuItem>
                    :
                    <MenuItem className={classes.list} classes={{ selected: classes.selected }} disableGutters={true} component={Link} to="/myEvents" title="My Events">My Events</MenuItem>
                    :
                    (localStorage.getItem('fhtoken') === null) ?
                     this.props.location.pathname !== '/listYourSpaces' &&
                    <MenuItem className={classes.list} classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/addVenue'} to="/listYourSpaces" component={Link} title="List Your Space" onClick={this.openListSpaceLink}>List Your Space2</MenuItem>
                    :
                    <MenuItem className={classes.list} component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
                      
                      <p>My Venues</p>
                    </MenuItem>  
                  */}


                    {
                      (localStorage.getItem('fhtoken') !== null && localStorage.getItem('type') === '1') &&
                      <MenuItem className={classes.list} component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
                      <p>My Venues</p>
                      </MenuItem>
                    }

                    {
                      (localStorage.getItem('fhtoken') !== null && localStorage.getItem('type') === '0') &&
                      <MenuItem className={classes.list} classes={{ selected: classes.selected }} disableGutters={true} component={Link} to="/myEvents" title="My Events">My Events</MenuItem>
                    }

                    {(localStorage.getItem('fhtoken') === null) &&
                      <MenuItem className={classes.signUp} classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/planners'} component={Link} to="/planners" title="For Planners">For Planners</MenuItem>
                    }

                    {(localStorage.getItem('fhtoken') === null) &&
                      <MenuItem className={classes.logIn} classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/'} component={Link} to="/" title="For Venues">For Venues</MenuItem>
                    }

                    {(localStorage.getItem('fhtoken') === null) &&
                      <MenuItem className={classes.signUp} classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/register'} component={Link} to="/register" title="Sign up">Sign up</MenuItem>
                    }
                    {(localStorage.getItem('fhtoken') === null) &&
                      <MenuItem className={classes.logIn} classes={{ selected: classes.selected }} disableGutters={true} selected={this.props.location.pathname === '/login'} component={Link} to="/login" title=" Login">Login</MenuItem>
                    }
                    </div>
                    {(localStorage.getItem('fhtoken') !== null) &&
                      <IconButton color="inherit" className="homeheader-icon">

                    {this.props.userData.data && this.props.userData.data.profilePhoto ?
                                      <RightSideDropMenu profilePhoto={this.props.userData.data && this.props.userData.data.profilePhoto.path}/> 
                                      :                                      
                                       <RightSideDropMenu profilePhoto={logo}/> 
                                    }                      
                                    
                </IconButton>
                    }
                  <div className={classes.sectionMobile}>
                    <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                      <Icon>more_vert</Icon>
                    </IconButton>
                  </div>
                </Toolbar>
              </AppBar>
            {renderMobileMenu}
          </div>
    );
  }
}

HomeHeader.propTypes = {
  classes: object.isRequired,

};
const mapStateToProps = state => {
  return {
    userData: state.data.usersVenue.userInfo,
    
  };
};

const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        openListYourSpace,
        userDetails
        
      },
      dispatch
    )
  )
);
export default enhance(HomeHeader);


import { GET_VENUE } from 'shared/state/types';
import { request } from 'shared/auth';

function getVenueRequested() {
  return {
    type:GET_VENUE.REQUEST,
  };
}

function getVenueFailed(error) {
  return {
    type:GET_VENUE.FAILURE,
    error:error,
  };
}

function getVenueSuccess(data) {
  return {
    type:GET_VENUE.SUCCESS,
    venueDetails: data,
  };
}


export function getVenue(id,photoCount) {
  return dispatch => {
    dispatch(getVenueRequested());
    if(photoCount===1)
    {
    return request().get(`venues/${id}?photoCount=${photoCount}`)
      .then(response =>{
          dispatch(getVenueSuccess(response.data.data))
          return response.data;
        }
      )
      .catch(errors => {
        if(errors.response && errors.response.data.message){
          dispatch(getVenueFailed(errors.response.data.message));
        }
        else{
          window.location.href = './'
          dispatch(getVenueFailed("Not able to find this venue"));
        }
        throw errors;
      });
    }
    else{
      return request().get(`venues/${id}?photoCount=${photoCount}`)
      .then(response =>{
          dispatch(getVenueSuccess(response.data.data))
          return response.data;
        }
      )
      .catch(errors => {
        if(errors.response && errors.response.data.message){
          dispatch(getVenueFailed(errors.response.data.message));
        }
        else{
          window.location.href = './'
          dispatch(getVenueFailed("Not able to find this venue"));
        }
        throw errors;
      });
    }
  };
}

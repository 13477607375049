import axios from 'axios';
import { CITY_REQUEST, CITY_SUCCESS, CITY_FAILURE } from 'shared/state/types';

function cityRequested() {
  return {
    type: CITY_REQUEST
  };
}

function citySuccess(data) {
  return {
    type: CITY_SUCCESS,
    cityList: data,
  };
}

function cityFailure(error) {
  return {
    type: CITY_FAILURE,
    error:error,
  };
}

const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  if(localStorage.getItem('fhtoken'))
  {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('fhtoken');

  }

  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  return axiosInstance
};

export function city(params) {
    return dispatch => {
      dispatch(cityRequested());
        return request().get(`city`,params)
        .then(response =>
          dispatch (citySuccess(response.data),
                ))
        .catch(errors => {
          dispatch(cityFailure(errors.response));
  
          throw errors;
        });
    
   

    
 };
}

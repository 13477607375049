import React from 'react';

import { Field, ErrorMessage } from 'formik';
import { Grid } from '@material-ui/core';

import '../assets/styles/venueAddressEdit.scss';


class VenueAddressEdit extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    render() {
        return (
            <Grid container className="venue-address-edit">
                <Grid xs={12} item>
                    <Grid container style={{ width: '100%' }}>
                        <h3 className="black-heading">
                        {/* <a href={'/editVenue/' + this.props.venueId + '/2'}> */}
                        Venue Address</h3>
                        <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
                            <Field className="textarea-small" value={this.props.address} component="textarea" name={this.props.name} placeholder="Venue Address" />
                            <div className="error"><ErrorMessage name={this.props.name} /></div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


export default VenueAddressEdit;

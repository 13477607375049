import React, { Component } from 'react';
import "../assets/styles/helpabout.scss";
import {
    Icon,
    MenuItem,
    Menu,
    Button
} from '@material-ui/core';
import { getarticledetail } from '../../../src/data/venues/actions/getarticledetail';
import { bindActionCreators, compose } from "redux";
import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment";


function articleDetail(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [article, setarticle] = React.useState({});
    const [category, setcategory] = React.useState({});
    const [tag, settag] = React.useState();
    const [related, setrelated] = React.useState();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        props.getarticledetail(queryParams.get('article_id')).then((result) => {
            setarticle(result.article);
            setcategory(result.category);
            settag(result.article.tags);
            setrelated(result.articles);
        })
    }, []);
    return (
        <div className="help-ui-wrapper">
            <Helmet>
                <title>{article.title}</title>
                <meta name="description" content={article.meta_description} />
                <meta name="robots" content="index"></meta>
            </Helmet>
            <section class="header-section">
                <div class="header-body">
                    <div class="header-wrap">
                        <a href="/help" class="company-logo">
                            <img class="large" src={require('./../assets/images/LogoWithText.png')} alt="Logo" />
                        </a>
                        <div class="overlay" id="overlay">
                            <nav class="overlay-menu">
                                <ul class="header-nav-view">
                                    <li><Link to="/help/articles?keyword=venues" target="_blank">Venues</Link></li>

                                    <li><Link to="/help/articles?keyword=caterers" target="_blank">Caterers</Link></li>

                                    <li><Link to="/help/articles?keyword=cvb" target="_blank">CVBs</Link></li>

                                    <li><Link to="/help/articles?keyword=planners" target="_blank">Planners</Link></li>

                                    <li><Link to="/help/articles?keyword=partners" target="_blank">Partnering with FIRST-HOLD</Link></li>
                                </ul>
                                <div class="mobile-view-nav">
                                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                        <Icon>more_vert</Icon>
                                    </Button>
                                    <Menu keepMounted anchorEl={anchorEl} onClose={handleClose} open={Boolean(anchorEl)}>
                                        <MenuItem onClick={handleClose}>Venues</MenuItem>
                                        <MenuItem onClick={handleClose}>Caterers</MenuItem>
                                        <MenuItem onClick={handleClose}>CVBs</MenuItem>
                                        <MenuItem onClick={handleClose}>Planners</MenuItem>
                                        <MenuItem onClick={handleClose}>Partnering with FIRST-HOLD</MenuItem>
                                    </Menu>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div class="article-search">
                        <div class="article-search-container">
                            <form action="/help/articles" method="get" autocomplete="off">
                                <span class="icon-search">
                                    <img src={require('../assets/images/search.svg')} alt="Search" />
                                </span>
                                <input placeholder="Search for answers..." type="search" name="keyword" />
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section class="breadcrums">
                <ol class="breadcrums-items">
                    <li><a href="/help">Home</a></li>
                    <li>{category.title}</li>
                    <li>{article.title}</li>
                </ol>
            </section>
            <div class="page-body">
                <div class="page-container">
                    <section class="article-body">
                        <div class="articles">
                            <div class="article">
                                <h1>{article.title}</h1>
                                <div class="article-subheading">{article.description}</div>
                                <div class="authors">
                                    <ul>
                                        {article.body && <li><img src="https://s3.amazonaws.com/groovehq/uploaded/thumb/FpFTmdVAcy7k-se6BHqU?1619458087" alt="avatar" /></li>}
                                    </ul>
                                    {article.updated_at && article &&
                                        <div class="item-info"> Written by Jennifer Brown<br />
                                            Updated on {moment(new Date(article.updated_at.slice(0, 10))).format("LL")}
                                        </div>}
                                </div>
                                {ReactHtmlParser(article.body)}
                            </div>
                        </div>
                    </section>
                    <section class="tags-section">
                        <span>Tags: </span>
                        {tag && tag.map((data) => (
                            <Link to={`/help/articles?tag=${data}`} className="footer_links" >{data} </Link>
                        ))}
                    </section>
                    {/*<section class="rating-section">
                       <div class="rating emoji" data-type="emoji">
                             {//<div id="rating-thankyou">Thank you for leaving a rating!</div>}
                            article.body &&<div id="rating-actions">
                                <div class="rating-heading">Did you find this article helpful?</div>
                                <div class="rating-body">
                                    <a class="rating-item" href="#" data-value="10"></a>
                                    <a class="rating-item" href="#" data-value="7"></a>
                                    <a class="rating-item" href="#" data-value="3"></a>
                                    <a class="rating-item" href="#" data-value="0"></a>
                                </div>
                        </div>
                        </div>
                        </section>*/}
                    {/*article.body &&<div class="widget">
                        <img  src={require('../assets/images/email.svg')} alt="Email" />
                        Can't find what you're looking for? <a id="widget-link" href="#widget-link">Get in touch</a>
                        </div>*/}
                    <section class="article-overview-list">
                        {article.body && related && <h2> Related articles</h2>}
                        <div class="lists">
                            {related && related.map(({ id, title, description, slug }) => (
                                <a class="item" href={`/help/article/${slug}?article_id=${id}`}>
                                    <span class="title">{title}</span>
                                    <span class="description">{description}</span>
                                </a>
                            ))}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        userData: state.data.usersVenue.userInfo
    };
};
const enhance = compose(
    withRouter,
    connect(mapStateToProps, dispatch =>
        bindActionCreators(
            {

                getarticledetail

            },
            dispatch
        )
    )
);

export default enhance(withRouter(articleDetail));



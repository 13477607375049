import React from 'react';
import '../assets/styles/landing-page.scss';
import { Grid } from '@material-ui/core';
import { Link } from "react-router-dom";
import Footer from 'explore-landing-page/component/footer';
import { Helmet } from "react-helmet";
import Footers from '../../landingPage/components/footer';

function Wineries(props) {
    let searchData = encodeURIComponent("Napa & Sonoma")
    return (
        <div className="">
            <div className="container explore-ui-wrapper">
                <Helmet>
                    <title>Top Winery Event Venues | FIRST-HOLD</title>
                    <meta name="description" content="Looking for a winery event venue? We offer a variety of spaces and flexible packages to suit your events. Find your venue now!"></meta>
                    <meta name="robots" content="index"></meta>
                </Helmet>
                <Grid container className="topspace">                                        
                    <Grid className="banner-wrapper" item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className="search-wrapper">
                            <div className="heading-text main">
                                <h1>Find a winery for events near me</h1>
                                <p>Discover unique wineries for your next meeting, event, or wedding.</p>
                            </div>
                            <div className="search-btn">
                                <a href={`/browseVenue?address=${searchData}`} className="">Search</a>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div className="text-center-image winery-main">
                            {/* <img className="" src={require('../assets/images/Blog - Interesting venue 2.jpg')} alt="FIRST-HOLD"/> */}
                            <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                        </div>
                    </Grid>
                </Grid>
                {/* new block start */}
                <Grid container className="box-wrapper">
                    <Grid className="" item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div className="text-center-image winery-main-1 left-side">
                            {/* <img className="" src={require('../assets/images/Blog - Wedding - Photo2.jpg')} alt="FIRST-HOLD"/> */}
                            <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className="search-wrapper-block">
                            <div className="heading-text ">
                                <h1>How wineries work on FIRST-HOLD</h1>
                                <p>FIRST-HOLD matches people planning events to venues with available dates --that's it.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Search size, style and budget</h2>
                                <p>Search for available wineries with the right size, vibe, and budget.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Immediately check availability</h2>
                                <p>Finding a wineries that fits?  Know if your preferred date is
                                    available — even when you’re searching late at night! Place a hold or
                                    contact the venue to learn more.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Communicate directly</h2>
                                <p>FIRST-HOLD connects you directly with venue managers. They call you
                                    to learn more about your event — all while knowing your date is currently available.</p>
                            </div>
                            <div className="search-btn started">
                                <a href="/browseVenue?address=San Francisco Bay Area" className="">Get started</a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className="featured-venue-bay box-wrapper">
                    <div className="heading-text">
                        <h1>Featured wineries</h1>
                    </div>
                    <Grid container className="margin-10">
                        <Grid className="" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="search-wrapper-block two-box">
                                <div className="">
                                    <p>When you search for Bay Area venues on FIRST-HOLD, you  can search for many different characteristics that are important to your big event. </p>
                                    <p class="margin-top-5">Our meeting and event planners love to search by: </p>
                                    <ul>
                                        <li>event date</li>
                                        <li># of guests</li>
                                        <li>seating style (sit-down dinner or reception)</li>
                                        <li>venue rental budget</li>
                                    </ul>
                                </div>
                                <div className="search-field margin-top">
                                    <p><span className="bold">When you find a great venue, here’s the difference:</span>you’ll
                                        know immediately whether your preferred date is available
                                        (even when you’re searching late at night). </p>
                                </div>
                                <div className="search-field margin-top">
                                    <p>
                                        Also, your inquiries go directly to the venue manager and
                                        you’ll both get each other’s contact info. Both you and the
                                        venue communicate directly and avoid in-app chats.
                                    </p>
                                </div>
                                <div className="search-field margin-top">
                                    <p>All these features mean you’re <span className="bold">saving time in your day</span> and <span className="bold">reducing planning stress</span> when you use FIRST-HOLD
                                        to search for winery event.</p>
                                </div>
                                <div className="search-btn started">
                                    <a href="/browseVenue?address=San Francisco Bay Area" className="">Check them out</a>
                                </div>
                            </div>
                        </Grid>
                        <Grid className="" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="text-center-image third-section winery-main-2">
                                {/* <img className="" src={require('../assets/images/San Francisco - Bay Area1.jpg')} alt="FIRST-HOLD" /> */}
                                <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {/* Need More section start */}
                <div className="need-more-section box-wrapper">
                    <div className="need-more-wrapper">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className="heading-text">
                                    <h1>Need more tips on finding the perfect venue?</h1>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className="search-btn">
                                    <a href="/blog" className="">Read more on our blog</a>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Footer />
            </div>
            <Footers />
        </div>
    );
}
export default Wineries;

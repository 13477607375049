import React from 'react';
import { object } from 'prop-types';

import { Formik, ErrorMessage, Field, Form } from 'formik';
import { Grid, Button } from '@material-ui/core';
import * as Yup from 'yup';

import CustomizedButton from '../../../shared/components/customizedButton';

import '../../assets/styles/auth.scss';

const EmailEditSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email!')
});


function EmailEdit(props) {
  return (
    <Grid className="email_container" item md={6} lg={6} sm={6} xl={6} xs={12}>
      <Formik
        validationSchema={EmailEditSchema}
        initialValues={{ email: props.email }}
        onSubmit={(values, actions) => {
          props.handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container justify="center">
              <Field className="email_edit" type="text" name="email" placeholder="Edit Email" />
              <div className="email_phone_edit_error"><ErrorMessage name="email" /></div>
            </Grid>
            <Grid className="button_container" container justify="center">
              <CustomizedButton type="submit" label='Save' disabled={isSubmitting} />
            </Grid>
            <Grid align="center">
              <Button onClick={() => props.onClickBack('email')} classes={{
                root: props.classes.linkButton
              }}>
                back
            </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

EmailEdit.propTypes = {
  classes: object.isRequired,
};

export default EmailEdit;
import React from 'react';
import { Grid } from '@material-ui/core';
 import Typography from '@material-ui/core/Typography';
import '../assets/styles/aboutUs.scss';
// import PlaceHold from 'shared/components/placeHold';
import ReactHtmlParser from 'react-html-parser';
// const nl2br = require('react-nl2br');

function AboutUs(props) {

    return (
        <Grid container className="venue-available aboutUs">
            <Grid xs={12} item>
                        <Grid container>
                            <Grid item md={12} xs={12} sm={12} xl={12} lg={12} className="avilableDescriptionDiv">
                                <div className="avilableDescription abt-us">
                                        {/* <h3>Our Story</h3> */}
                                    <Typography color="textSecondary" className="matter aboutMatter">
                                        {/* {nl2br(props.pageData.data && props.pageData.data.description)} */}
                                        {(props.pageData.data && props.pageData.data.description) &&
                                            <div>{ ReactHtmlParser(props.pageData.data.description) }</div>
                                        }
                            	</Typography>
                                </div>
                            </Grid>
                        </Grid>
            </Grid>
        </Grid>
    );
}


export default AboutUs;

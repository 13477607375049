import React from 'react';
import { Grid, Button } from '@material-ui/core';
import '../assets/styles/spaceProfileEditComponent.scss';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {Field, ErrorMessage } from 'formik';
// import ExpansionSection from './expansionSection';
// import SeatingAndCapacity from '../../shared/components/seatingAndCapacity';
// import AddPdfComponent from './addPdf';
import CustomizedButton from '../../shared/components/customizedButton';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import '../../shared/components/assets/style/seatingAndCapacity.scss';

class SpaceProfileEditComponent extends React.Component {
    // state={
    //     pdfData:[],
    // }

    // UNSAFE_componentWillReceiveProps(nextProps){
    //     this.setState({pdfData:nextProps.pdfData})
    // }
    constructor(props) {
      super(props);
      this.handleAddPdfClick = this.handleAddPdfClick.bind(this);
    //   let arrangementDetails;
    //   if(props.spaceData) {
    //         arrangementDetails = props.spaceData.arrangements && props.spaceData.arrangements.map(function(data, idx) {
    //             return (
    //             <div style={{'float':'left','margin':'5px','width':'140px','height':'150px','backgroundColor':'#f7f7f7','borderRadius':'5px','border':'1px solid #c4d0d9'}}>
    //             <h6 style={{'margin': '5px 0px 4px 0px','textAlign':'center','fontSize':'13px','fontWeight':'600','fontFamily':'Raleway'}}>{data.name}</h6>
    //             <div style={{'margin': '7px 0px 0px 14px','width': '80%','height': '97px'}}>
    //                 <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={data.image} />
    //             </div>
    //             <h6 style={{'fontFamily':'Raleway' , 'margin': '5px 0px 4px 0px','textAlign':'center','fontSize':'13px','fontWeight':'600'}}>{data.pivot.minimum ? data.pivot.minimum +' - '+ data.pivot.maximum : data.pivot.maximum + ' MAX'}</h6>

    //         </div>
    //             )
    //         });
    //     }
    //     this.setState({galleryItems, arrangementDetails})
    }

    handleAddPdfClick() {
    }

    items = this.props.spaceData.arrangements
 
    state = {
        currentIndex: 0,
        itemsInSlide: 3,
        responsive: { 768 : { items: 3 } },
        galleryItems: this.galleryItems(),
    }

    slidePrevPage = () => {
        const currentIndex = this.state.currentIndex - this.state.itemsInSlide
        this.setState({ currentIndex })
      }
     
    slideNextPage = () => {
        const {
          itemsInSlide,
          galleryItems: { length },
        } = this.state
        let currentIndex = this.state.currentIndex + itemsInSlide
        if (currentIndex > length) currentIndex = length
     
        this.setState({ currentIndex })
      }

      handleOnSlideChange = (event) => {
        const { itemsInSlide, item } = event
        this.setState({ itemsInSlide, currentIndex: item })
      }
    
    
    // slideTo = (i) => this.setState({ currentIndex: i })
    
    // onSlideChanged = (e) => this.setState({ currentIndex: e.item })
    
    // slideNext = () => this.setState({ currentIndex: this.state.currentIndex + this.state.itemsInSlide })
    
    // slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - this.state.itemsInSlide })
    // handleOnSlideChange = (event) => {
    //     const { itemsInSlide, item } = event
    //     this.setState({ itemsInSlide, currentIndex: item })
    // }
    galleryItems() {
         return this.items ? this.items.map((data) => 


        <div style={{'backgroundColor':'#f7f7f7','borderRadius':'5px','border':'1px solid #c4d0d9','maxWidth': '100%' , 'margin':'0 0px'}} className={this.items.length===1?"single-img-max":"multiple-img"}>
            <h6 style={{'margin': '5px 0px 4px 0px','textAlign':'center','fontSize':'13px','fontWeight':'600','fontFamily':'Raleway'}}>{data.name}</h6>
                <div style={{'margin': '7px 0px 0px 14px','width': '80%','height': '114px'}}>
                {  data.name === 'Sit-Down' ?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('../venueSpace/assets/images/sitdown.png')} alt={data.name} />
                    :data.name === 'Classroom' ?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('../venueSpace/assets/images/classroom.png')} alt={data.name} />
                    :data.name === 'Boardroom'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('../venueSpace/assets/images/boardroom.png')} alt={data.name} />
                    :data.name === 'Theater'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('../venueSpace/assets/images/theater.png')} alt={data.name} />
                    :data.name === 'Reception'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('../venueSpace/assets/images/reception.png')} alt={data.name} />
                    :data.name === 'Tradeshow'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('../venueSpace/assets/images/tradeshow.png')} alt={data.name} />
                    :data.name === 'Pre-Existing Arrangement'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('../venueSpace/assets/images/pre-arranged.png')} alt={data.name} />
                    :data.name === 'Empty'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('../venueSpace/assets/images/empty.png')} alt={data.name} />
                    :data.name === 'Wedding'?
                    <img style={{'maxHeight':'100%','maxWidth':'100%'}} src={require('../venueSpace/assets/images/Wedding.png')} alt={data.name} />
                    :null
                }
                </div>
            <h6 style={{'fontFamily':'Raleway' , 'margin': '5px 0px 4px 0px','textAlign':'center','fontSize':'13px','fontWeight':'600'}}>{data.pivot.minimum ? data.pivot.minimum +' - '+ data.pivot.maximum : data.pivot.maximum} {data.name==='Empty'?' Max':''}</h6>
        </div>
     )
     :null
        // let arrangementDetail;
        // if(this.props.spaceData) {
        // return this.props.spaceData.arrangements && this.props.spaceData.arrangements.map(function(data, idx) {<h2>  This is test.This is test.This is test.This is test.</h2>});
        // }
    }

    render() {    
        const { galleryItems, responsive, currentIndex } = this.state

        return (
            <Grid container className="space-profile-edit">
                <Grid xs={12} item>

                    <Grid container>
                        <Grid container style={{width: '95%'}} className="wid-100">
                            <Grid style={{display: 'block',width:'100%'}}>
                                <div className="left-side">
                                    <h3 className="black-heading">Space Description</h3>
                                </div>
                                <div className="right-side">
                                {
                                    this.props.loader===true?
                                    <img style={{height:'34px',marginTop:'0px',paddingTop:'0px'}} src={require('../../shared/components/assets/images/loading.gif')} alt="Venue" /> 
                                    :
                                    <Button onClick={()=>this.props.editProfileSave(this.props.values)} class="white-button"><p style={{color:'#ee2738'}}>Edit Profile</p></Button>

                                }
                                      
                                </div>
                            </Grid>
                            <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
                            

                            <Field
                                  component='textarea'
                                  className="textarea"
                                  name="description"
                                  placeholder="Enter description (required)"
                                />
                                <div className="error"><ErrorMessage name="description" /></div>
                            </Grid>

                            {this.props.descriptionError ? <div className="error">*Required</div>:''}

                            <Grid container className={this.items.length===1?"alice-red single-images":"alice-red multiple-images"}>
                                <Grid item md={9} xs={12} sm={9} xl={9} lg={9} className="seatindAndCapacityGrid">
                                    <h4 className="black-heading" style={{margin: '10px auto'}}>
                                    {/* <a href={'/editSpace/'+this.props.spaceId+'/2'}> */}
                                    Arrangement</h4>  
                                    {this.items && this.items.length>0?
                                    <AliceCarousel
                                    dotsDisabled={true}
                                    // buttonsDisabled={false}
                                    items={galleryItems}
                                    // mouseTrackingEnabled={true}
                                    // autoPlayInterval={4000}
                                    // autoPlay={true}
                                    // slideToIndex={currentIndex}
                                    // onSlideChanged={this.onSlideChanged}
                                    infinite={true}
                                    // onInitialized={this.handleOnSlideChange}
                                    // onSlideChanged={this.handleOnSlideChange}
                                    // onResized={this.handleOnSlideChange}  
                                    slideToIndex={currentIndex}
                                    onInitialized={this.handleOnSlideChange}
                                    onSlideChanged={this.handleOnSlideChange}
                                    onResized={this.handleOnSlideChange}   
                                    responsive={responsive}                                                 
                                    />
                                    :
                                    <h6>No Arrangement </h6>
                                    }
                                
                                    {/* <SeatingAndCapacity spaceInfo={this.props.spaceData} sliderData={this.props.spaceData} /> */}
                                </Grid>
                            </Grid>

                            {/* <ExpansionSection /> */}

                            <h3 className="blue-heading">Other Info</h3>
                            <Grid item md={12} xs={12} sm={12} xl={12} lg={12}>
                                <div className="grid-section-vertical">
                                  <p className="other-info-label">Floor</p>

                                <Field
                                  type='text'
                                  className="input-field big"
                                  name="floor"
                                  placeholder="1st Floor"
                                />
                  <div className="error"><ErrorMessage name="floor" /></div>

                                </div>
                                <div className="grid-section-vertical">

                                    <div className="grid-section-small">
                                        <p className="other-info-label">Length</p>
                                    

                                <Field
                                  type='number'
                                  className="input-field small"
                                  name="length"
                                  placeholder={this.props.values.dimension_unit==='feet'?'Feet':this.props.values.dimension_unit==='meters'?"Meters":"Select"}
                                />
                             <div className="error"><ErrorMessage name="length" /></div>

                                    </div>
                                    
                                    <div className="grid-section-small">
                                        <p className="other-info-label">Width</p>
                                 
                                              <Field
                                                type='number'
                                                className="input-field small"
                                                name="width"
                                                placeholder={this.props.values.dimension_unit==='feet'?'Feet':this.props.values.dimension_unit==='meters'?"Meters":"Select"}
                                                />
                               <div className="error"><ErrorMessage name="width" /></div>

                                    </div>
                                    <div className="grid-section-small">
                                        <p className="other-info-label">Height</p>

                                <Field
                                  type='number'
                                  className="input-field small"
                                  name="height"
                                  placeholder={this.props.values.dimension_unit==='feet'?'Feet':this.props.values.dimension_unit==='meters'?"Meters":"Select"}
                                />
                       <div className="error"><ErrorMessage name="height" /></div>

                                    </div>
                                    <div className="grid-section-small">
                                        <p className="other-info-label">&nbsp;</p>

                                        <Field className="select-field small" component="select" name="dimension_unit">
                                        <option value="" selected>Select</option>

                                            <option value="feet">feet</option>
                                            <option value="meters">meters</option>
                              
                                        </Field>
                                        <p style={{color:'red',fontSize:'10px'}}>{this.props.dimension_error}</p>

                                    </div>

                                </div>
                                <p style={{color:'red',fontSize:'10px'}}>{this.props.allError}</p>

                                <div className="grid-section-vertical">
                                    <div className="grid-section-small">
                                        <p className="other-info-label">Area</p>
                                        <Field
                                  type='number'
                                  className="input-field small"
                                  name="area"
                                  placeholder="10,000"
                                />
                       <div className="error"><ErrorMessage name="area" /></div>

                                    </div>
                                    <div className="grid-section-small">
                                        <p className="other-info-label">&nbsp;</p>
                                        <Field className="select-field small" component="select" name="area_unit">

                                        {/* <select name="area_unit" className="select-field small"> */}
                                        <option value="" selected>Select</option>

                                            <option value="sq. ft.">sq. ft.</option>
                                            <option value="sq. m.">sq. m.</option>
                                        </Field>
                                        <p style={{color:'red',fontSize:'10px'}}>{this.props.area_error}</p>

                                    </div>
                                </div>
                                {/* <AddPdfComponent 
                                copyFalse={this.copyFalse} 
                                copy={this.props.copy} 
                                venueDetails={this.props.venueDetails} 
                                name="SPACE" 
                                pdfData={this.state.pdfData} 
                                type="SPACE" 
                                id={this.props.spaceId} 
                                handleDocumentUploaded={this.props.handleDocumentUploaded}/> */}
                                <CustomizedButton
                                        height='24px'
                                        width='80px'
                                        label="Done"
                                        type="submit"
                                        id="submitSave"
                                        visibility="hide"
                                         />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


export default SpaceProfileEditComponent;

export const EVENT_TYPE = "Event Type"
export const SEATING = 'Seating';
export const OF_GUESTS = '# Of Guests';
export const BUDGET = 'Venue Budget';
export const START_DATE = 'Start Date';
export const END_DATE = 'End Date';
export const selectOptions = [
    { value: '1', name: 'Conferences1' },
    { value: '2', name: 'Weddings' },
    { value: '3', name: 'Workshops' },
    { value: '4', name: 'Trade Shows' },
    { value: '5', name: 'Corporate Parties' },
    { value: '7', name: 'Fundraisers' }

];
export const selectEvents = [
    { value: '1', name: 'Conferences' },
    { value: '2', name: 'Weddings' },
    { value: '3', name: 'Workshops' },
    { value: '4', name: 'Trade Shows' },
    { value: '5', name: 'Corporate Parties' },
    { value: '7', name: 'Fundraisers' }

];

export const selectSeatings = [
    { value: 'Banquet', name: 'Banquet' },
    { value: 'Theater', name: 'Theater' },
    { value: 'Classroom', name: 'Classroom' },
    { value: 'Reception', name: 'Reception' },
    { value: 'Boardroom', name: 'Boardroom' },
    { value: 'Tradeshow', name: 'Tradeshow' }

];

export const selectBudgets = [
    { value: '1', name: '$0 - $500' },
    { value: '2', name: '$501 - $1000' },
    { value: '3', name: '$1,001 - $5000' },
    { value: '4', name: '$5,001 - $10,000' },
    { value: '5', name: '$10,001 - $50,000' },
    { value: '6', name: '$50,001 - $100,000' },
    { value: '7', name: '$100,000+' }
];

export const selectGuests = [
    { value: '0-50', name: '0-50' },
    { value: '50-100', name: '50-100' },
    { value: '100-150', name: '100-150' },
    { value: '150-200', name: '150-200' },
    { value: '200-300', name: '200-300' },
    { value: '300-400', name: '300-400' },
    { value: '500-1000', name: '500-1000' },
    { value: '1000', name: '1000+' }

];

export const selectPlaceHoldSeatings = [
    { value: '0-100', name: '0-100' },
    { value: '100-300', name: '100-300' },
    { value: '300-500', name: '300-500' }
];

export const selectEventTypes = [
    { value: 'Select', name: 'Select' },
    { value: 'corporate_event', name: 'Business Events' },
    { value: 'social_event', name: 'Social Events' },
    { value: 'non_profit_event', name: 'Weddings' }
];
export const selectStatus = [
    { value: '1', name: '1st Hold' },
    { value: '2', name: '2nd Hold' },
    { value: '3', name: '3rd Hold' },
    { value: '4', name: 'Booked' },
];

export const countryCode = [
    {
        "code": "1",
        "name": "+1"
    }
];

import { request } from 'shared/auth';
import { DELETE_FAV} from 'shared/state/types';

// function deleteFavRequest() {
//     return {
//         type: DELETE_FAV.REQUEST,

//     };
// }
function deleteFavSuccess(data) {
    return {
        type: DELETE_FAV.SUCCESS,
        message: data,
    };
}
function deleteFavFailure() {
    return {
        type: DELETE_FAV.FAILURE
    };
}

export function deleteFav(params) {
    return dispatch => {
        return request()
            .delete(`/favourites/${params}`)
            .then(response => {
                dispatch(deleteFavSuccess(response.data.data));
                return response
            })
            .catch(error => {
                dispatch(deleteFavFailure());
            })
    };
}

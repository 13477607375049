import React from 'react';
import { object } from 'prop-types';

import EditVenueImage from '../../venueProfile/components/editVenueImage';
import CustomizedCheckBox from '../../shared/components/CustomizedCheckbox';
import CustomizedButton from '../../shared/components/customizedButton';

import { Grid, Icon, Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Gallery from 'shared/components/gallery';
import { withStyles } from '@material-ui/core/styles';
import { ErrorMessage } from 'formik';
import MuiDialogActions from '@material-ui/core/DialogActions';

import '../assets/styles/spaceProfile.scss';
const DialogActions = withStyles(theme => ({
    root: {
    },
}))(MuiDialogActions);

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // width: '610px',
        height: '400px',
        borderRadius: '6px',
        // backgroundColor: ' #ffffff',
        outline: 'none',
        left: '50%',
        top: '50%',
        transform: "translate(-50%, -50%)"
    },
    root: {
        padding: '0px !important',
        overflowX: 'hidden'
    },
    closeButton: {
        textAlign: 'right',
        color: '#ffffff',
        fontSize: '18px',
        width: '100%'
    },
    buyout: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#36434b'
        // paddingLeft: '15px'
    },
    copyrights: {
        paddingBottom: '13px',
        display: 'inline-flex',
        paddingLeft: '6px',
        paddingTop: '11px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0px',
            paddingBottom: '15px',
            paddingTop: '0px',
        }
    },
    done: {
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '0px'
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0px',
            paddingBottom: '10px'
        }
    },
    header: {
        boxShadow: 'inset 0 -1px 0 0 #f0f0f0',
        backgroundColor: '#ffffff',
        padding: '10px 15px'
    },
    title: {
        padding: '0px'
    }


});

function VenueDetailsImage(props) {
    const { classes } = props;
    return (
        <div className="space_details_image">
            <Grid container className="image-div">
                {props.spaceImage ?
                    <img className="venue-image" alt='' src={props.spaceImage} />
                    :
                    <div className="no-image-div">

                    </div>
                }
            </Grid>

            <Grid container className="space_details_over_image">
                <div className="div1">
                    <div className="space_name_in_venue_details_image">
                        {props.venueDetails.name}
                    </div>

                    <div className="venue_name">
                        {props.venueDetails.venue}
                    </div>
                </div>
                <div className="space_address_details_in_venue_details_image">
                    <Icon className="place_icon_in_space_details_image">
                        place
                    </Icon>{props.venueDetails.address}
                </div>
                {props.editImage ?
                    (<div className="view_photos_container">
                        <Button class="white-button" onClick={(e) => { props.handleEditPhotosClick(e) }}>Edit Photos</Button>
                    </div>)
                    :
                    (<div className="view_photos_container">
                        <Gallery label="View Photos" photos={props.venueDetails.photos} />
                    </div>)
                }
                {props.formikProps ?
                    <Dialog
                        open={props.openImageUploadForm}
                        // onClose={(e) => { props.handleEditPhotosClick(e) }}
                        keepMounted
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        fullWidth={false}
                        maxWidth={`md`}
                        className="success_modal_container venueprofilepopupui"
                    >
                        <DialogTitle className={classes.title}>
                            <Grid container className={classes.header} alignItems="center">
                                <Grid className={classes.buyout} item xs={12} sm={4} md={4} lg={4} xl={5}>
                                    {props.venueDetails.name} Photos
                                </Grid>
                                <DialogActions className="dialogActions">
                                    <span
                                        className="material-icons close closeButton"
                                        onClick={(e) => { props.handleEditPhotosClick(e) }}
                                    >
                                        close
                                    </span>
                                </DialogActions>
                                <Grid className={classes.copyrights} item xs={12} sm={6} md={6} lg={6} xl={5}>
                                    <CustomizedCheckBox name='agree' value={props.formikProps.values.agree} label="" style={{ display: 'inline-block', marginTop: '5px' }} />
                                    <p style={{ fontSize: '14px', display: 'inline-block' }}>I have <a href="/terms#photos" target="_blank" style={{ color: '#1397e1' }}>copyright</a> to use these photos.</p>
                                    <div className="error error-copyright"><ErrorMessage name="agree" /></div>
                                </Grid>
                                <Grid className={classes.done} item xs={12} sm={2} md={2} lg={2} xl={2}>

                                    {props.images.length < 3
                                        ?

                                        <CustomizedButton
                                            height='24px'
                                            width='80px'
                                            label="Done"
                                            disabled={true}
                                            type="submit"
                                            onClickEvent={() => {
                                                props.formikProps.handleSubmit()
                                            }} />
                                        :
                                        <CustomizedButton
                                            height='24px'
                                            width='100%'
                                            label="Done"
                                            type="submit"
                                            onClickEvent={() => {
                                                props.formikProps.handleSubmit()
                                            }} />
                                    }
                                    <p className="crop-the-image">{props.errorCrop}</p>

                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent
                            classes={{
                                root: classes.root
                            }}
                        >

                            {props.isEditVenueImage &&
                                <EditVenueImage
                                    venueData={props.venueData}
                                    okButton={props.okButton}
                                    cancelButton={props.cancelButton}
                                    imageFileData={props.imageFileData}
                                    imageUrl={props.imageUrl}
                                    crop={props.crop}
                                    zoom={props.zoom}
                                    aspect={props.aspect}
                                    rotation={props.rotation}
                                    onRotationChange={props.onRotationChange}
                                    onCropChange={props.onCropChange}
                                    onCropComplete={props.onCropComplete}
                                    onZoomChange={props.onZoomChange}
                                    cropImage={props.cropImage}
                                    spaceId={props.spaceId ? props.spaceId : null}
                                    name="space"
                                    condition={props.formikProps.values.agree}
                                    images={props.images}
                                    updateImagesOrder={props.updateImagesOrder}
                                    updateName={props.updateName}
                                    updateWebsite={props.updateWebsite}
                                    deleteByName={props.deleteByName}
                                    imageFileName={props.imageFileName}
                                    formikProps={props.formikProps}
                                    handleEditPhotosClick={props.handleEditPhotosClick}
                                    imageFile={props.imageFile}
                                    imageUploaded={props.imageUploaded}
                                    imageUploading={props.imageUploading}
                                    handleImageUpload={props.handleImageUpload}
                                    handleImageChange={props.handleImageChange}
                                    deleteImage={props.deleteImage}
                                    error={props.error} venueImageError={props.venueImageError} />

                            }
                        </DialogContent>

                    </Dialog>
                    : null}
            </Grid>
        </div>
    );
}


VenueDetailsImage.propTypes = {
    classes: object.isRequired,
};

const enhance = withStyles(styles)

export default enhance(VenueDetailsImage);

import React from 'react';
import {object} from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Grid, Link, Button, IconButton } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '121px',
    height: '36px',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    marginTop: '13px',
    borderRadius: '6px',
    marginBottom: '22px',
    background: 'linear-gradient(to bottom, #f1435e, #ee2738)',
    fontFamily: 'Raleway',
    
    '&$disabled': {
      background: '#9aa7af',
      color: 'white',
      boxShadow: 'none',
    },
  },
  button: {
    backgroundColor:'#ee2738',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontStyle: 'normal',
    letterSpacing: '0.5px',
    textAlign: 'center',
    paddingLeft: '75px',
    paddingRight: '75px',
    marginTop: '30px',
    '&:hover': {
      background: "#d80e2a"
    },
  },
  iconImage:{
    height:"24px",
    width:"24px !important",
    marginLeft:"26px",
    '&:hover': {
      backgroundColor:'#d80e2a'
    }
  },
  imageIconButton:{
    padding: '0px 0px 0px 30px',
    '&:hover': {
      backgroundColor:'#d80e2a'
    }
  },
  disabled: {
    opacity:'0.5'
   },
});


function Banner(props) {
  const redirect = () =>{
    window.open("/schedule");
    // window.open("https://chrome.google.com/webstore/detail/first-hold/gbgllbpnnhldcnpkbfmiiikkbggjknke");
  }
    const { classes } = props;
    const bannerObject = props.bannerData.map(function(data, idx) {
        return(
                <div key={idx}>
                  <div className="banner-wrap">
                        <img className="banner_image" src={data.image} alt="Banner" />
                        <Grid className="text_over_image" container>
                        <Grid item md={6} sm={5} lg={7} xl={7} implementation="css"/>
                        <Grid className="banner_content" item md={6} sm={7} lg={5} xl={5} xs={12}>
                        {props.activeSlide === 1 ?
                          <>
                            <Grid className="banner_heading">Point. Click. Done.</Grid>
                            <Grid className="banner_sub_heading">Check availability at your next venue, instantly.</Grid>
                            <Grid className="banner_description">Our real-time, searchable selection of available meeting & event venues was made just for you.</Grid>
                          </>
                          :
                          <>
                            <Grid className="banner_heading">Point. Click. Done.</Grid>
                            <Grid className="banner_sub_heading">Check availability at your next venue, instantly.</Grid>
                            <Grid className="banner_description">Our real-time, searchable selection of available meeting & event venues was made just for you.</Grid>
                          </>
                        }
                            {/* <Grid className="download_link">
                                <Link className="link">Get the Chrome extension</Link>
                            </Grid> */}
                            <Grid className="banner_button" container justify="flex-start">
                                <Button onClick={()=>redirect()} variant="contained" color="secondary" className={classes.button}>
                                Book a Demo
                                {/* <img className={classes.iconImage} src={require('../assets/images/chrome-icon.png')} alt="banner"/> */}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                  </div>
                </div>
        );
    });

    return (
        <Grid container className="banner fh-container">
            <Carousel showArrows={false} selectedItem={props.activeSlide} showStatus={false} showIndicators={false} showThumbs={false}>
                {bannerObject}
            </Carousel>
                <Grid container
                  containerspacing={0}
                  alignItems="center"
                  justify="center"
                  className="mobile_view" item xs={12}
                >
                  {props.activeSlide === 1 ?
                    <>
                      <Grid item xs={12} className="banner_heading">Take Center Stage.</Grid>
                      <Grid item xs={12} className="banner_sub_heading">Receive and Manage Holds Seamlessly</Grid>
                      <Grid item xs={12} className="banner_description">One calendar synced across all your favorite platforms.Lead generation from qualified event planners Easy to manage holds, block your calendar, and adjust rental rates</Grid>
                    </>
                  :
                    <>
                      <Grid item xs={12} className="banner_heading">Point. Click. Done.</Grid>
                      <Grid item xs={12} className="banner_sub_heading">Check availability at your next venue, instantly.</Grid>
                      <Grid item xs={12} className="banner_description">Our real-time, searchable selection of available meeting & event venues was made just for you.</Grid>
                    </>
                  }
                    <Grid item xs={12} className="banner_button" container justify="center" style={{marginTop:'10px'}}>
                        <Button href="/register" variant="contained" color="secondary" className={classes.button}>
                             Join Now!
                         </Button>
                    </Grid>
                  </Grid>
            <Grid className="banner_button fh-container" container justify="flex-start">
              <IconButton className="previousButton" onClick={props.clickPrevious} disabled={props.activeSlide === 0}>
                  <img className={classes.iconImage} src={require('../assets/images/arrow-left-circle.svg')} alt="Left" />
              </IconButton>
            </Grid>
            <Grid className="banner_button fh-container" container justify="flex-end">
              <IconButton className="nextButton" onClick={props.clickNext} disabled={props.activeSlide === props.slideCount - 1}>
                  <img className={classes.iconImage} src={require('../assets/images/arrow-right-circle.svg')} alt="Right" />
              </IconButton>
            </Grid>
        </Grid>
    );
}

Banner.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(Banner);
